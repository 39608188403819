import styled from "styled-components";
import { media, lightenDarkenColor } from "utils/style-utils";

export const PageBody = styled.div.attrs({ id: 'page-body' })`
  min-height: calc(100vh - 64px);
  width: 100%;
  padding: 24px;
  padding-bottom: 180px;
  position: relative;
  ${media.handheld`
    overflow: hidden;
    min-height: ${() => window.innerHeight};

  `}
`;

export const TopBar = styled.div`
  width: 100%;
  height: 300px;
  margin-bottom: 15px;
`;

export const BottomBar = styled.div`
  width: 100%;
  border: 1px solid black;
`;

export const ContentPane = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 24px;
  max-width: 800px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  margin-bottom: 6px;
`;

export const Flair = styled.div`
  width: 30%;
  max-height: 150px;
  svg {
    width: 100%;
    height: 100%;

    .primary-lightened-2 {
      fill: ${props => lightenDarkenColor(props.theme.primary_color, 60)};
    }
    .primary-lightened-2-stroke {
      stroke: ${props => lightenDarkenColor(props.theme.primary_color, 60)};
    }
    .primary-lightened-1 {
      fill: ${props => lightenDarkenColor(props.theme.primary_color, 20)};
    }
    .primary-darkened-1 {
      fill: ${props => lightenDarkenColor(props.theme.primary_color, -20)};
    }
    .primary-darkened-1-stroke {
      stroke: ${props => lightenDarkenColor(props.theme.primary_color, -20)};
    }
    .primary-lightened-1-stroke {
      stroke: ${props => lightenDarkenColor(props.theme.primary_color, 20)};
    }
    .secondary {
      fill: ${props => lightenDarkenColor(props.theme.secondary_color, 0)};
    }
    .primary {
      fill: ${props => lightenDarkenColor(props.theme.primary_color, 0)};
    }
    .primary-stroke {
      stroke: ${props => lightenDarkenColor(props.theme.primary_color, 0)};
    }
    .secondary-lightened-1 {
      fill: ${props => lightenDarkenColor(props.theme.secondary_color, 20)};
    }
    .secondary-darkened-1 {
      fill: ${props => lightenDarkenColor(props.theme.secondary_color, -20)};
    }

    .secondary-darkened-1-stroke {
      stroke: ${props => lightenDarkenColor(props.theme.secondary_color, -20)};
    }
    .extra-color-2-darkened-1-stroke {
      stroke: ${props => lightenDarkenColor(props.theme.extra_color_2, -20)};
    }

    .extra-color-2 {
      fill: ${props => lightenDarkenColor(props.theme.extra_color_2, 0)};
    }
    .extra-color-2-stroke {
      stroke: ${props => lightenDarkenColor(props.theme.extra_color_2, 0)};
    }
    .extra-color-2-darkened-1 {
      fill: ${props => lightenDarkenColor(props.theme.extra_color_2, -20)};
    }
    .extra-color-2-lightened-1 {
      fill: ${props => lightenDarkenColor(props.theme.extra_color_2, 20)};
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;