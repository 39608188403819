import styled from "styled-components";
import { lightenDarkenColor } from "utils/style-utils";

export const ItemWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

export const Flair = styled.div`
  width: 30%;
  max-height: 150px;
  svg {
    width: 100%;
    height: 100%;

    .primary-lightened-2 {
      fill: ${props => lightenDarkenColor(props.theme.primary_color, 60)};
    }
    .primary-lightened-2-stroke {
      stroke: ${props => lightenDarkenColor(props.theme.primary_color, 60)};
    }
    .primary-lightened-1 {
      fill: ${props => lightenDarkenColor(props.theme.primary_color, 20)};
    }
    .primary-darkened-1 {
      fill: ${props => lightenDarkenColor(props.theme.primary_color, -20)};
    }
    .primary-lightened-1-stroke {
      stroke: ${props => lightenDarkenColor(props.theme.primary_color, 20)};
    }
    .secondary {
      fill: ${props => lightenDarkenColor(props.theme.secondary_color, 0)};
    }
    .primary {
      fill: ${props => lightenDarkenColor(props.theme.primary_color, 0)};
    }
    .primary-stroke {
      stroke: ${props => lightenDarkenColor(props.theme.primary_color, 0)};
    }
    .secondary-stroke {
      stroke: ${props => lightenDarkenColor(props.theme.secondary_color, 0)};
    }
    .secondary-lightened-1 {
      fill: ${props => lightenDarkenColor(props.theme.secondary_color, 20)};
    }
    .secondary-lightened-2 {
      fill: ${props => lightenDarkenColor(props.theme.secondary_color, 40)};
    }
    .secondary-darkened-1 {
      fill: ${props => lightenDarkenColor(props.theme.secondary_color, -20)};
    }

    .secondary-darkened-1-stroke {
      stroke: ${props => lightenDarkenColor(props.theme.secondary_color, -20)};
    }
    .extra-color-2-darkened-1-stroke {
      stroke: ${props => lightenDarkenColor(props.theme.extra_color_2, -20)};
    }

    .extra-color-2 {
      fill: ${props => lightenDarkenColor(props.theme.extra_color_2, 0)};
    }
    .extra-color-2-stroke {
      stroke: ${props => lightenDarkenColor(props.theme.extra_color_2, 0)};
    }
    .extra-color-2-darkened-1 {
      fill: ${props => lightenDarkenColor(props.theme.extra_color_2, -20)};
    }
    .extra-color-2-lightened-1 {
      fill: ${props => lightenDarkenColor(props.theme.extra_color_2, 20)};
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const Title = styled.h4``;

export const CarouselDot = styled.div`
  height: ${({
  active
}) => active ? '16px' : '10px'};
  width: ${({
  active
}) => active ? '16px' : '10px'};
  background: ${({
  active,
  theme
}) => active ? `${theme.primary_color}` : '#e8e8e8'};
  border-radius: 50%;
  z-index: 102;
  transition: 0.2s;
  cursor: pointer;
`;

export const DotWrapper = styled.div`
  margin: 0 12px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;