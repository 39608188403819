/* eslint-disable no-irregular-whitespace */

/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable no-unused-expressions */

/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";
import { Profile } from "services/api";
import useStore from "../../utils/useStores";

const PrivacyPolicy = ({ close, isRevokeView, initialConsent, logout }) => {
  const [isDecideView, setDecideView] = useState(initialConsent);
  const {
    profileStore,
    profileStore: { currentUser }
  } = useStore();
  return (
    <Modal
      visible={
        !profileStore.isSuperAdmin && initialConsent
          ? isDecideView
          : isRevokeView
      }
      title="Privacy Policy"
      bodyStyle={{ height: 500 }}
      cancelText={initialConsent ? "Decline" : "Cancel"}
      okText={initialConsent ? "I accept" : "Revoke"}
      okType={initialConsent ? "primary" : "danger"}
      maskClosable={!!isRevokeView}
      closable={!!isRevokeView}
      onOk={() => {
        isRevokeView
          ? Modal.confirm({
              cancelText: "Cancel",
              okText: "Ok",
              content:
                "You are about to revoke your consent.  Therefore, no further personal data will be captured and you will be logged out. You must grant consent in order to continue using the platform, which you can do by logging in to the platform again.",
              onOk: () => {
                Profile.privacyConsent(currentUser.id, {
                  consent: false
                }).then((res) => {
                  currentUser.consent = false;
                  logout();
                });
              }
            })
          : (setDecideView(false),
            Profile.privacyConsent(currentUser.id, {
              consent: !isRevokeView
            })).then(() => {
              currentUser.consent = true;
            });
      }}
      onCancel={() => {
        isRevokeView
          ? close()
          : Modal.confirm({
              cancelText: "Cancel",
              okText: "Ok",
              content:
                "If you decline to consent, no further personal data will be captured, and you will not be able to continue.",
              onOk: () => {
                logout();
                Profile.privacyConsent(currentUser.id, {
                  consent: !isRevokeView
                }).then((res) => {});
              }
            });
      }}
    >
      {!initialConsent && (
        <h4>
          <strong>You have consented to:</strong>
        </h4>
      )}
      {initialConsent && (
        <p>
          By choosing &ldquo;I accept&rdquo;, I consent to the policy below.
        </p>
      )}
      <div
        style={{
          height: "425px",
          overflow: "scroll",
          border: "solid #e8e8e8 1px"
        }}
      >
        <div style={{ padding: "10px" }}>
          <h2>
            <strong>Effective: 4/15/2019</strong>
          </h2>
          <p>
            Thank you for using Rhabit. We are committed to protecting your
            privacy and, for that reason, we have adopted this Privacy Policy to
            explain our data collection, use, and disclosure practices for the
            Rhabit services (including its website, and mobile and web-based
            applications, and any other tools, products, or services provided by
            Rhabit that link to or reference this Privacy Policy) (collectively,
            the “Services”). The Services are owned and operated by Rhabit
            Analytics, Inc., a Delaware corporation (“we”, “us” or “our”).
          </p>
          <p>
            This Privacy Policy applies to information Rhabit collects through
            the Services, as well as other information provided to us online or
            offline by third parties, when we associate that information with
            customers or users of the Services; however, it does not apply to
            information collected from our employees, contractors, or vendors.
            It also does not apply to information that you share with third
            parties or is collected by third parties through the Services
            (including web traffic or crash analytics tools). You acknowledge
            and agree that Rhabit is not responsible for the data collection or
            use practices of any other Services user or third party utilized in
            providing the Services. Consent
          </p>
          <p>
            By accessing or using the Services, you consent to this Privacy
            Policy. If you do not agree with this Privacy Policy, please do not
            access or use the Services. Information gathered through the
            Services may be transferred, used, and stored in the United States
            or in other countries where our service providers or we are located.
            If you use the Services, you agree to the transfer, use, and storage
            of your Personal Information (as defined below) in those countries.
            The data protection and other laws of the United States and other
            countries might not be as comprehensive as those in your country.
            You agree that all transactions relating to the Services are deemed
            to occur in the United States, where our servers are located.
          </p>
          <h2>
            <strong>1. Overview and Scope</strong>
          </h2>
          <p>
            Rhabit provides performance management and performance development
            instruments for businesses. These services are delivered through a
            web portal accessible in modern browsers on smart phones and
            computers. This Privacy Policy applies to any access to or use of
            the Rhabit website located at https://www.rhabit.co, related Rhabit
            websites and domains, Rhabit’s web and mobile applications, and any
            of the products or services owned, operated, or controlled by
            Rhabit. The Services Rhabit offers are available to clients at
            https://www.app.rhabitapp.com.
          </p>
          <p>
            The Services Rhabit offers serves the purpose to provide feedback to
            individual employees, inform their supervisors and HR about
            performance gaps (if permission by the employee is given) and helps
            the selection and design of remedial measures (training and
            development). In this Privacy Policy we describe how we handle the
            date necessary to provide these services. This includes what
            information we may collect, how we may collect this information, how
            we may use and disclose this information, who we might share this
            information with, what security measures are utilized to secure this
            information, and how to contact us with any inquiries related to
            your information.
          </p>
          <h2>
            <strong>2. Types of Information We Collect</strong>
          </h2>

          <h3>
            <strong>2.1 Account Information</strong>
          </h3>
          <p>
            When you create or establish an account for the Services, we collect
            and store information about you based on information you or your
            company may provide, which may include personally identifiable
            information, such as your name, date of birth, job title, work email
            address, office location, office phone number, your company
            department or organization, your manager’s name, a unique
            identifier, employment dates, profile picture, user name, password,
            and any other information that may identify you individually
            (collectively, your “Personal Information”). We use this information
            to set up your account, to give you the appropriate access and
            permissions, to communicate with you regarding your account, and
            otherwise for the provision of the Services. We may also use your
            email address to send you updates in connection with our Services.
            You may change your account information and notification settings by
            contacting us (see contact section below) or the administrator in
            your company who is responsible for Rhabit.
          </p>

          <h3>
            <strong>2.2 Hosted Data</strong>
          </h3>

          <p>
            When you log in to, use and interact with Rhabit, we may collect and
            store any information or content that you post, upload, store,
            display, transmit, or submit on or through the Services, as a
            function of providing the Services (collectively, “Hosted Data”).
            Rhabit is a passive recipient and takes no active part in collecting
            or storing Hosted Data. Except in extraordinary cases or to the
            extent necessary to render the Services to you, Rhabit does not
            purposefully access any Hosted Data. For example, if you submit a
            review of another User, the Services passively processes and stores
            such User review for the purpose of rendering the Services, and
            Rhabit only accesses such information to the extent necessary to
            provide the Services and any related support for the Services. You
            acknowledge and agree we may collect, access, and disclose Hosted
            Data, which may include Personal Information disclosed by you or
            another User, to facilitate the provision of Services and any
            related support for the Services. While in some cases you can make
            certain information private to specific Users, by default most
            information is public to other Users inside your company using the
            Services. You are solely responsible for all information you post,
            upload, store, display, transmit, or submit on the Services,
            including Personal Information, and the consequences thereof. Rhabit
            is not responsible and will not be liable for the information you
            disclose while using the Services.
          </p>

          <h3>
            <strong>2.3 Third Party Integrations</strong>
          </h3>

          <p>
            When you integrate the Services with third party applications (e.g.,
            Slack, Jira, etc.) (“Third Party Integrations”), we may receive,
            collect, and store information regarding your credentials for and
            use of the applicable third party application, such as your user
            name, your unique identifier, your information made available with
            permissions by such Third Party Integration (e.g., gender, age
            range, language, geographic region, etc.), and related metadata.
          </p>
          <h3>
            <strong>2.4 Third Party Authenticators</strong>
          </h3>
          <p>
            If you log in to the Services using a third party sign in provider
            to authenticate your account for the Services (e.g., Google Sign-In
            and OneLogin) (“Third Party Authenticators”), we may receive,
            collect, and store information regarding your credentials for the
            applicable Third Party Authenticator, such as your log-in, your user
            name, your email, your unique identifier, profile picture, and other
            information transmitted from or made available with permissions by
            such Third Party Authenticator.
          </p>

          <h3>
            <strong>2.6 Technical Data</strong>
          </h3>

          <p>
            Rhabit may collect and use technical data, such as information about
            your device, operating system, application software, and peripherals
            (collectively, “Technical Data”). We collect and use Technical Data
            to facilitate software updates, provide support, and continuously
            make improvements to the Services and our business. We do not
            collect Personal Information with any Technical Data or relate any
            Technical Data to an individual User. Rhabit may also aggregate the
            metadata and usage data of the Services, including Hosted Data, so
            that the resulting data and statistics are not personally
            identifiable to any individual User (“Aggregated Anonymous Data”).
            We may collect and use Aggregated Anonymous Data (i) for our own
            internal statistical analysis, (ii) to develop and improve the
            Services, (iii) to research trends and create predictive analysis;
            and (iv) to create and distribute reports and other materials
            regarding use of the Services.
          </p>

          <h3>
            <strong>2.7 Sensitive Personal Information</strong>
          </h3>

          <p>
            Rhabit does not intentionally collect or maintain, and we request
            that you do not post, upload, store, display, transmit, or submit
            Sensitive Personal Information on or through the Services.
            “Sensitive Personal Information” includes, but is not limited to,
            government-issued identification numbers, financial account numbers,
            credit or debit card numbers, consumer reports, background checks,
            biometric data, any code or password that could be used to gain
            access to personal accounts, or any information specifying medical
            and health conditions, racial or ethnic origin, political opinions,
            religious or philosophical beliefs, trade union membership, or the
            sex life of an individual. You acknowledge and agree that you will
            not post, upload, store, display, transmit, or submit any Sensitive
            Personal Information on or through the Services. You are solely
            responsible for all information you post, upload, store, display,
            transmit, or submit on the Services, including Sensitive Personal
            Information, and the consequences thereof. Rhabit is not responsible
            for and will not be liable to you or another individual for any loss
            or damages you or another individual may experience due to your
            disclosure of Sensitive Personal Information while using the
            Services.
          </p>
          <h2>
            <strong>3. How We Collect Information</strong>
          </h2>

          <h3>
            <strong>3.1 Usage</strong>
          </h3>
          <p>
            When you use and interact with the Services, any information that
            you post, upload, store, display, transmit, or submit on or through
            the Services will be collected and stored through your direct
            interactions with the Services as Hosted Data. For example, we
            collect information when you post online comments or feedback via
            the Services, and this information is available to other Users
            accessing the Services in your company. When you contact Rhabit, by
            email, written correspondence, telephone calls, web based forms, or
            otherwise, any information that you provide in such forum may also
            be collected and stored in the general business practices of Rhabit
            and to facilitate the provision of Services and any related support
            for the Services.
          </p>

          <h3>
            <strong>3.2 Cookies</strong>
          </h3>
          <p>
            When you use and interact with the Services, we may use “cookies” or
            other similar technologies to collect information related to your
            access to or use of the Services, which may be used to help with
            authentication/login, provide analytics, identify your preferences,
            and otherwise monitor the functionality of and improve the Services.
            A cookie is a small piece of information that a website can store on
            your device for later retrieval. We may place a cookie on your hard
            drive or in “local storage”, a feature of your browser, to retain
            information locally regarding your usage. If you do not wish to have
            cookies placed on your computer or in local storage, you may adjust
            your web browser settings accordingly. Most browsers are initially
            set to accept cookies. If you prefer, you can set your browser to
            block cookies or to alert you when cookies are sent. Please be aware
            that restricting cookies may impede your ability to use the Services
            or certain features of the Services.
          </p>

          <h3>
            <strong>3.3 Log Files</strong>
          </h3>
          <p>
            Like most web-based services, Rhabit uses log files on the server
            side. The data held in log files may include your IP address,
            browser type, e-mail application, Internet service provider (ISP),
            referring/exit websites, computer platform type, operating system,
            date/time stamp, and user activity. We use server log data to
            analyze trends and to administer and manage the Services. The
            software enabling the Services has associated log and temporary
            files that are stored on Rhabit controlled servers. These files may
            store your account information, preference settings, system
            notifications, and other data necessary to enable you to use the
            Services. Your information may also exist within regularly performed
            server backups.
          </p>

          <h2>
            <strong>4. How We Use and Disclose Information</strong>
          </h2>
          <h3>
            <strong>4.1 Limitations of Use and Disclosure</strong>
          </h3>
          <p>
            Rhabit will not sell or rent your Personal Information to any third
            party. Except as described in this Privacy Policy or the applicable
            Use Agreement, Rhabit will not use or disclose your Personal
            Information for any purpose, other than to the extent necessary to
            perform the Services, unless you expressly opt-in to any other use.
          </p>
          <p>
            Rhabit may, in our sole discretion, disclose your Personal
            Information: (1) to your company, (2) to our employees and
            contractors to the extent necessary to provide the Services; (3) to
            third parties for whom you have expressly consented to disclosure,
            (4) to ensure compliance with and enforce the Use Agreements and/or
            any other contractual or legal obligations with respect to use of
            the Services; (5) to ensure compliance with laws and enforce third
            party rights, including intellectual property rights; (6) to protect
            your safety and security; (7) to protect the safety and security of
            Rhabit, our employees, agents, and contractors, and our property;
            and (8) to those involved in business activities of Rhabit such as
            investments, mergers, acquisitions, debt financing, divestures,
            bankruptcy, sale or transfer of all or part of Rhabit’s assets, or
            any other transaction in which data could be transferred to third
            parties as part of Rhabit’s business assets. We may also disclose
            your Personal Information if required by law, such as to comply with
            a subpoena, court order, or other lawful process, or in response to
            a lawful request by public authorities to meet national security or
            law enforcement requirements.
          </p>

          <h3>
            <strong>4.2 International Transfer of Information Collected</strong>
          </h3>
          <p>
            Rhabit primarily stores data in connection with the Services in the
            United States. However, the Services are global and all data,
            including Personal Information, may be stored and processed in any
            country where we have operations or where we engage service
            providers. We may transfer data, including Personal Information, to
            countries outside of your country of residence, which may have data
            protection rules that are different from those of your country. We
            will take measures to ensure that any such transfers comply with
            applicable data protection laws and that your Personal Information
            remains protected to the standards described in this Privacy Policy.
            In certain circumstances, courts, law enforcement agencies,
            regulatory agencies or security authorities in those other countries
            may be entitled to access your Personal Information.
          </p>
          <h3>
            <strong>4.3 Onward Transfer of Personal Information</strong>
          </h3>
          <p>
            Rhabit may share your Personal Information with agents, contractors,
            or third party service providers (e.g., email services, cloud
            computing services, data storage and processing facilities) to the
            limited extent necessary to let them perform business functions and
            services for us or on our behalf in connection with the provision of
            the Services; provided that such agents, contractors, and service
            providers process information in a manner consistent with this
            Privacy Policy and are not permitted to use such Personal
            Information for any other purpose. 
          </p>

          <h3>
            <strong>4.4 Third Party Websites</strong>
          </h3>
          <p>
            While using the Services, you may be directed to a third party
            website, such as Third Party Integrations, Third Party
            Authenticators, or other links or references, which may take you to
            that third party website. Once you have left the Services, this
            Privacy Policy will no longer apply to you or any other data
            collected from or provided by you, including Personal Information,
            and you will be subject to that third party website’s privacy policy
            (and such other terms and conditions). You should review such third
            party website’s terms to determine how your data will be used.
          </p>

          <h3>
            <strong>4.5 Retention</strong>
          </h3>
          <p>
            Rhabit will retain your information, including Personal Information,
            for as long as your account is active, or to the extent necessary to
            provide your company with the Services, to comply with and enforce
            our agreements, or as otherwise required or permitted by law.
          </p>

          <h2>
            <strong>5. Security Measures</strong>
          </h2>
          <p>
            We take your privacy and data security very seriously and strive to
            maintain the security of all Personal Information. Rhabit maintains
            appropriate physical, technical, and administrative safeguards to
            protect against loss, misuse, and unauthorized access, use,
            disclosure, modification, or destruction of Personal Information and
            Hosted Data in our custody and control. However, no website or
            transmission of information over the internet or common carrier
            lines is guaranteed to be completely secure, and we cannot guarantee
            that unauthorized access, hacking, data losses, or other breaches
            will never occur. Rhabit servers are managed and located at third
            party Infrastructure-as-a-Service (IAAS) provider, and we have taken
            commercially reasonable steps to choose a qualified IAAS provider or
            providers who operate according to industry standard terms of
            service and data security protocols. Rhabit uses commercially
            reasonable efforts to store and encrypt Personal Information in a
            secure location, encrypt passwords, and utilize a minimum of 128-bit
            Secure Socket Layer (SSL) certificates to protect transactions to
            and from the Services.
          </p>
          <p>
            You will access and use the Services via a registered user account,
            which is also protected by a password for your privacy and security.
            You are responsible and liable for safeguarding your account and
            password that you use to access the Services, and for all activities
            or actions that occur under your account. You should take measures
            to prevent unauthorized access to your account and your information
            by selecting and protecting your password appropriately and limiting
            access to your computer (or other device) and browser by signing off
            after you have finished accessing your account. If you choose to
            authenticate your account through a Third Party Authenticator, you
            understand that the security and privacy of your account is subject
            to the policies of such Third Party Authenticator and we have no
            liability for the applicable Third Party Authenticator’s security
            and privacy practices.
          </p>

          <h2>
            <strong>6. Access to Personal Information</strong>
          </h2>
          <p>
            Rhabit is committed to maintain accurate information that you share
            with us and will use commercially reasonable efforts to allow you to
            access your Personal Information in order to correct or amend such
            data where inaccurate or incomplete. If you wish to access, modify,
            or delete the Personal Information you already provided, please
            contact us at the contact information provided below. Rhabit will
            endeavor to respond in a timely manner to all reasonable written
            requests to view, modify, or delete Personal Information. If you
            consent to receiving communications from a third party, such third
            party may have its own privacy policy which will apply to you and
            you will need to communicate with them directly if you wish to
            access, modify, or delete the Personal Information you provided to
            them, or later decide that you no longer wish to receive such third
            party’s communications.
          </p>

          <h2>
            <strong>
              7. Reviewing, correcting and removing your personal information
            </strong>
          </h2>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>
              Upon request, Rhabit Analytics will provide you with information
              about whether we hold any of your Personal Information. If you
              provide us with your Personal Information, you have the following
              rights with respect to that information:
              <ol style={{ listStyleType: "lower-roman" }}>
                <li>
                  To review the user information that you have supplied to us
                </li>
                <li>
                  To request that we correct any errors, outdated information,
                  or omissions in user information that you have supplied to us
                </li>
                <li>
                  To request that your user information not be used to contact
                  you
                </li>
                <li>
                  To request that your user information be deleted from our
                  records
                </li>
                <li>
                  To opt out of being solicited by Rhabit Analytics or third
                  parties
                </li>
              </ol>
            </li>
            <li>
              To exercise any of these rights, please contact us
              at support@rhabitapp.com by mail to: Rhabit Analytics, 6401 Penn
              Ave, 3 rd Floor, Pittsburgh, PA 15241, USA. We will respond to
              your request to change, correct, or delete your information within
              a reasonable timeframe and notify you of the action we have taken.
            </li>
          </ol>
          <h2>
            <strong>8. Children and COPPA Compliance</strong>
          </h2>
          <p>
            The Services are not directed to and/or intended for children under
            the age of 13. Rhabit does not intentionally collect Personal
            Information from children under the age of 13. In the event we
            discover we have inadvertently collected any Personal Information
            from a child under 13 years of age, Rhabit will take the appropriate
            steps to delete this Personal Information, or seek the necessary
            verifiable parental consent for that collection in compliance with
            the Children’s Online Privacy Protection Act (“COPPA”).
          </p>

          <h2>
            <strong>9. Your California Privacy Rights.</strong>
          </h2>
          <p>
            Rhabit does not disclose Personal Information to third parties for
            any third parties’ direct marketing purposes, unless the Customer or
            User affirmatively agrees to such disclosure. Since Rhabit provides
            its California Users with notice of its rights as described above,
            pursuant to Section 1798.83(c)(2) of the California Civil Code,
            Rhabit is in compliance with California’s “Shine the Light” law and
            is not obligated to provide California Users with the names and
            addresses of all the third parties that received Personal
            Information from Rhabit for the third parties’ direct marketing
            purposes during the preceding calendar year.
          </p>

          <h2>
            <strong>10. Enforcement</strong>
          </h2>
          <p>
            Rhabit will actively monitor its relevant privacy and security
            practices to verify adherence to this Privacy Policy. Any agents,
            contractors, service providers, or other third party’s subject to
            this Privacy Policy that Rhabit determines is in violation of this
            Privacy Policy will be subject to disciplinary action up to and
            including termination of such services.
          </p>

          <h2>
            <strong>11. Changes to this Privacy Policy</strong>
          </h2>
          <p>
            Rhabit may change, modify, or update this Privacy Policy from time
            to time, in whole or in part, in Rhabit’s sole discretion, at any
            time without prior notice by posting updated versions on the Rhabit
            website. When we do, we will revise the “last updated” date at the
            bottom of this page. If and when we make such changes, we will make
            commercially reasonable efforts to notify you by email, through the
            Services, or by posting a prominent notice on our Website. Any
            changes, modifications, or updates to this Privacy Policy will
            become effective immediately upon such posting. Your continued use
            of the Services constitutes your agreement to be bound by such
            changes to this Privacy Policy. Your only remedy, if you do not
            accept the terms of this Privacy Policy, is to discontinue use of
            the Services.
          </p>

          <h2>
            <strong>12. Contact Rhabit</strong>
          </h2>
          <p>
            We encourage you to contact us with any questions, complaints, or
            requests with respect to your Personal Information, this Privacy
            Policy, and/or our privacy practices. You can reach us at{" "}
            <a>support@rhabit.co</a> or
          </p>

          <p style={{ marginBottom: "0px" }}>Rhabit Analytics</p>
          <p style={{ marginBottom: "0px" }}>Attn: Privacy Policy</p>
          <p style={{ marginBottom: "0px" }}>6401 Penn Ave</p>
          <p style={{ marginBottom: "0px" }}>Pittsburgh, PA 15206</p>
          <p style={{ marginBottom: "0px" }}>USA</p>
        </div>
      </div>
    </Modal>
  );
};

PrivacyPolicy.propTypes = {
  close: PropTypes.isRequired,
  initialConsent: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  //   currentUserId: PropTypes.string.isRequired,
  isRevokeView: PropTypes.bool.isRequired
};

export default PrivacyPolicy;
