/* eslint-disable no-unused-vars */

/* eslint-disable no-debugger */
import React, { useEffect, useState } from "react";
import { Table, Card, Icon, Tooltip, Row, Col, Typography } from "antd";
import PropTypes from "prop-types";
import { PerformanceReview } from "services/api";
import { NineBox } from "./lil9box";
import ExpandableText from "./expandText";

const readinessMap = {
  0: "Ready Now",
  1: "Ready 3-6 Months",
  2: "Ready 6-12 Months",
  3: "Ready 1 Year Out",
  4: "Suitably Placed"
};

const lossMap = {
  0: "Low",
  1: "Medium",
  2: "High"
};

const coordinates = {
  "0,2": "Diamond in the Rough",
  "1,2": "Future Star",
  "2,2": "Consistent Star",
  "0,1": "Developing Performer",
  "1,1": "Key Performer",
  "2,1": "High Professional +",
  "0,0": "Lower Performer",
  "1,0": "Solid Professional",
  "2,0": "High Professional"
};

const PastReviewTable = ({ id }) => {
  const [reviews, setReviews] = useState([]);
  const fetchPastReviews = async () => {
    const res = (await PerformanceReview.pastReviews(id)).data;
    setReviews(res.reviews);
  };

  useEffect(() => {
    if (id) {
      fetchPastReviews();
    }
  }, [id]);

  if (reviews.length < 1) {
    return <></>;
  }

  const columns = [
    { title: "Label", key: "label", dataIndex: "label" },
    {
      title: "Risk Of Loss",
      key: "risk_of_loss",
      dataIndex: "risk_of_loss",
      render: (_r, { risk_of_loss, reason_for_leaving }) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: "10px" }}>{lossMap[risk_of_loss]}</span>
            {reason_for_leaving && reason_for_leaving.trim() && (
              <Tooltip title={reason_for_leaving}>
                <Icon type="message" />
              </Tooltip>
            )}
          </div>
        );
      }
    },
    {
      title: "Impact Of Loss",
      key: "impact_of_loss",
      dataIndex: "impact_of_loss",
      render: (_r, { impact_of_loss, assessment_of_impact }) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: "10px" }}>
              {lossMap[impact_of_loss]}
            </span>
            {assessment_of_impact && assessment_of_impact.trim() && (
              <Tooltip title={assessment_of_impact}>
                <Icon type="message" />
              </Tooltip>
            )}
          </div>
        );
      }
    },
    {
      title: "Readiness",
      key: "readiness",
      dataIndex: "readiness",
      render: (_r, { readiness, development_plan }) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: "10px" }}>{lossMap[readiness]}</span>
            {development_plan && development_plan.trim() && (
              <Tooltip title={development_plan}>
                <Icon type="message" />
              </Tooltip>
            )}
          </div>
        );
      }
    },
    {
      title: "9box",
      key: "x",
      dataIndex: "x",
      render: (_val, { x, y }) => {
        return (
          <Tooltip title={coordinates[`${x},${y}`]}>
            <div style={{ width: "100%" }}>
              <NineBox x={x} y={y} />
            </div>
          </Tooltip>
        );
      }
    },
    {
      title: "Comments",
      key: "comment",
      dataIndex: "comment",
      render: (record, obj) => {
        return <ExpandableText key={id} text={record} id={obj.id} />;
      }
    }
  ];

  return (
    <div style={{ padding: 24 }}>
      <Typography.Title level={4}>Past Reviews</Typography.Title>
      <Row gutter={24}>
        <Col>
          <Card bodyStyle={{ padding: 0 }}>
            <div>
              <Table
                columns={columns}
                dataSource={reviews}
                pagination={false}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

PastReviewTable.propTypes = {
  id: PropTypes.string.isRequired
};

export default PastReviewTable;
