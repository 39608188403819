import { ReactNode } from "react";
import useStores from "utils/useStores";

const Feature = ({
  children,
  feature,
  features,
  excludes
}: {
  children: ReactNode;
  feature?: string;
  features?: string[];
  excludes?: string[];
}): null | JSX.Element => {
  const { profileStore } = useStores();
  const userFeatures: string[] = profileStore.features();
  let featuresSatisfied = false;

  if ((!features || !features.length) && !feature) {
    featuresSatisfied = true;
  }

  if (features && features.every((feat) => userFeatures.includes(feat))) {
    featuresSatisfied = true;
  } else if (feature && userFeatures.includes(feature)) {
    featuresSatisfied = true;
  }

  if (excludes && userFeatures.some((f) => excludes.includes(f))) {
    featuresSatisfied = false;
  }

  if (featuresSatisfied) {
    return children as JSX.Element;
  }
  return null;
};

export default Feature;
