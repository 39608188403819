import React, { useState, useEffect, useRef } from "react";
import { Modal, Input, List } from "antd";
import PropTypes from "prop-types";
import { Objective } from "services/api";
import normalize from "json-api-normalizer";
import build from "redux-object";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";
import UserAvatar from "components/UserAvatar";
import merge from "lodash/merge";
import styled from "styled-components";

const StyledListItem = styled(List.Item)`
  cursor: pointer;
  background: ${({ selected }) => selected && "rgba(0, 0, 0, 0.1)"};
  padding: 6px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
`;

const ParentObjectivePicker = ({
  onSubmit,
  visible,
  onCancel,
  currentId,
  parent
}) => {
  const [selected, setSelected] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [objectives, setObjectives] = useState({ objective: {} });
  const [objectiveQuery, setObjectiveQuery] = useState("");
  const [timeoutId, setTimeoutId] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const isFirstRun = useRef(true);
  const mobile = window.innerWidth < 500;

  let dataSource;
  try {
    dataSource = Object.keys(objectives.objective)
      .filter((k) => (parent ? k.id !== parent.id : true))
      .map((k) => {
        const o = build(objectives, "objective", k);
        return {
          name: o.name,
          private: o.private,
          status: o.status,
          id: o.id,
          delivery_date: o.deliveryDate,
          owner: {
            id: o.owner.id,
            name: o.owner.name,
            avatar: o.owner.avatar
          }
        };
      })
      .filter((o) => {
        return o.status !== "COMPLETED" && o.status !== "ABANDONED";
      });
  } catch (err) {
    dataSource = [];
  }
  useEffect(() => {
    const fetchData = async () => {
      if (objectiveQuery.length > 0) {
        if (!fetching) {
          setPage(1);
          setHasMore(true);
          setFetching(true);
        }
        setObjectives({ objective: {} });
        clearTimeout(timeoutId);
        setTimeoutId(
          setTimeout(() => {
            if (currentId) {
              Objective.all(
                `search=${objectiveQuery}&current_id=${currentId}&page=${page}&per=10&status[]=IN_PROGRESS&status[]=PENDING&status[]=COMPLETED`
              ).then(({ data }) => {
                setHasMore(data.meta.has_more);
                setObjectives(normalize(data));

                setFetching(false);
              });
            } else {
              Objective.all(
                `search=${objectiveQuery}&page=${page}&per=10&&status[]=IN_PROGRESS&status[]=PENDING&status[]=COMPLETED`
              ).then(({ data }) => {
                setObjectives(normalize(data));
                setHasMore(data.meta.has_more);
                setFetching(false);
              });
            }
          }, 200)
        );
      } else {
        clearTimeout(timeoutId);
        setObjectives({ objective: {} });
        setFetching(false);
      }
    };

    fetchData();
  }, [objectiveQuery]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    }
  });

  const handleLoad = (nextPage) => {
    setFetching(true);
    setPage(nextPage);
    Objective.all(
      `status=IN_PROGRESS&search=${objectiveQuery}&page=${nextPage}&per=10${
        currentId ? `&current_id=${currentId}` : ""
      }`
    ).then(({ data }) => {
      setHasMore(data.meta.has_more);

      setObjectives(merge(objectives, normalize(data), {}));

      setFetching(false);
    });
  };

  const bodyStyle = {
    height: !mobile && 600
    // overflowX: 'hidden',
    // overflowY: 'hidden'
  };
  return (
    <Modal
      okButtonProps={{ disabled: !selected }}
      onOk={() => onSubmit(selected)}
      onCancel={onCancel}
      okText="Add"
      destroyOnClose
      width={window.innerWidth < 500 ? "98vw" : 700}
      visible={visible}
      bodyStyle={bodyStyle}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>Search Objectives:</span>
        <Input
          id="parent-objective-search"
          placeholder='Search by title and/or owner e.g. "David Johnson Analyze Engagement"'
          onChange={(e) => setObjectiveQuery(e.target.value)}
          style={{ marginBottom: 24 }}
        />
      </div>
      <div style={{ height: 500, overflow: "scroll" }}>
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          useWindow={false}
          loadMore={handleLoad}
          hasMore={!fetching && hasMore}
        >
          <List
            dataSource={dataSource}
            renderItem={(item) => (
              <StyledListItem
                key={item.id}
                onClick={() => setSelected(item)}
                selected={selected && item.id === selected.id}
              >
                <List.Item.Meta
                  avatar={
                    <UserAvatar
                      avatar={item.owner.avatar}
                      name={item.owner.name}
                    />
                  }
                  title={item.name}
                  description={`Owned By: ${item.owner.name}`}
                />
                <div>{`Due: ${moment(item.delivery_date).format(
                  "MMM Do YY"
                )}`}</div>
              </StyledListItem>
            )}
          />
        </InfiniteScroll>
      </div>
    </Modal>
  );
};

ParentObjectivePicker.defaultProps = {
  currentId: null,
  parent: null
};

ParentObjectivePicker.propTypes = {
  parent: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  currentId: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};
export default ParentObjectivePicker;
