/* eslint-disable import/prefer-default-export */
import styled, { keyframes, css } from "styled-components";
import theme from "assets/theme";
import { media } from "utils/style-utils";

const fadeOut = keyframes`
  from{
    opacity: 100%
  }

  to{
    opacity: 0%
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${media.handheld`
    flex-direction: column;
  `}
`;

export const AnswerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Highlighted = styled.div`
  line-height: 18px;
  color: ${props => props.theme.primary_color};
`;

export const TextAreaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${media.handheld`
    width: 100%;
    margin-top: 10px;
  `}
`;

export const Header = styled.div`
  font-size: ${theme.font.header}px;
  margin-bottom: 6px;
`;

export const TipList = styled.ul`
  padding: 15px 20px 0px 20px;
`;

export const Before = styled.div`
  display: ${({ show }) => show && "none"};
  animation: ${({ answered }) => {
    if (answered) {
      return css`
        ${fadeOut} 0.25s
      `;
    }
    return null;
  }};
`;

export const After = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
