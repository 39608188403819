import React, { useEffect } from "react";

import ObjectiveDisplay from "containers/HomePage/Objectives/ObjectiveDisplay";
import LoadingOverlay from "components/LoadingOverlay";
import Box from "layout/Box";
import { useFetchUserObjectivesQuery } from "./fetchUserObjectives.generated";

interface Props {
  userId: string;
  setShowObjectives: (show: boolean) => void;
}

const Objectives: React.FC<Props> = ({ userId, setShowObjectives }) => {
  const { data, loading, error } = useFetchUserObjectivesQuery({
    variables: { ownerId: userId }
  });
  useEffect(() => {
    if (data && data.objectives.nodes.length > 0) {
      setShowObjectives(true);
    } else {
      setShowObjectives(false);
    }
  }, [data]);
  if (!data || loading) {
    return <LoadingOverlay />;
  }

  if (data.objectives.nodes.length === 0) {
    return <div />;
  }

  return (
    <Box display="flex" width="100%" flexDirection="column">
      <Box
        border="1px solid #e8e8e8"
        borderRadius="6px"
        width="100%"
        margin="24px auto"
      >
        <Box height="400px" padding="24px">
          <ObjectiveDisplay
            objectives={data.objectives.nodes.map((o) => ({
              delivery_date: o.deliveryDate,
              latest_check_in: {
                response: o.latestResponse,
                date: o.latestResponseDate
              },
              ...o
            }))}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Objectives;
