import React from "react";
import { Card, Row, Col, Statistic } from "antd";

import PropTypes from "prop-types";
import moment from "moment";

const OneOnOneCard = ({ ooo }) => {
  return (
    <Card
      onClick={() => window.open(`/manager/one_on_ones/${ooo.id}`)}
      title={ooo.label}
      extra={[
        <span>
          {moment(ooo.start_date).format("L")} -{" "}
          {moment(ooo.end_date).format("L")}
        </span>
      ]}
    >
      {/* <Card.Meta title={ooo.label} description="12/12/12 to 12/12/12" /> */}
      <Row>
        <Col span={12} style={{ marginBottom: 12 }}>
          <Statistic
            title={
              <span style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                Average Habit Score
              </span>
            }
            value={
              ooo.overall_average
                ? Number(ooo.overall_average).toFixed(0)
                : "--"
            }
            suffix="%"
          />
        </Col>
        <Col span={12}>
          <Statistic
            title={
              <span style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                Average OKR Score
              </span>
            }
            value={
              ooo.average_review ? Number(ooo.average_review).toFixed(2) : "--"
            }
          />
        </Col>
        <Col span={24} style={{ marginBottom: 12 }}>
          <span style={{ color: "rgba(0, 0, 0, 0.85)" }}>Summary - Past: </span>
          {ooo.summary_past}
        </Col>
        <Col span={24}>
          <span style={{ color: "rgba(0, 0, 0, 0.85)" }}>
            Summary - Future:{" "}
          </span>
          {ooo.summary_future}
        </Col>
      </Row>
    </Card>
  );
};
OneOnOneCard.propTypes = {
  ooo: PropTypes.object.isRequired
};
export default OneOnOneCard;
