/* eslint-disable no-debugger */

/* eslint-disable no-nested-ternary */

/* global SyntheticEvent */
import * as React from "react";
import {
  Relationships as rapi,
  Profile,
  User,
  UserRelationships
} from "services/api";
import LoadingOverlay from "components/LoadingOverlay";
import Joyride, { ACTIONS, EVENTS, CallBackProps } from "react-joyride";
import moment from "moment";
import { UserLite, Relationship, HabitUserLite } from "types/shared";

import UserMultiSelect from "components/UserMultiSelect";

import {
  PageHeader,
  Button,
  Divider,
  Col,
  Row,
  Checkbox,
  Radio,
  Icon,
  message
} from "antd";
import { motion, AnimatePresence } from "framer-motion";
import useStores from "utils/useStores";
import { hexToRgb } from "utils/blackOrWhite";
import RelationshipCard from "./RelationshipCard";
import RelationshipTable from "./RelationshipTable";
import Wrapper from "../../layout/Wrapper";
import ChooseUserType from "./chooseUserType";
import { ListWrapper } from "./styles";
import { useTranslation } from "react-i18next";

const { useState, useEffect, useCallback } = React;
const CARD = "card";
const TABLE = "table";

type RelationshipsProps = {
  onboarding?: boolean;
  managedUser: UserLite;
  focusing?: string;
  embedded?: boolean;
};

const Relationships = ({
  onboarding,
  managedUser,
  focusing,
  embedded
}: RelationshipsProps) => {
  const [loading, setLoading] = useState(true);
  const [focusedUser, setFocusedUser] = useState(null);
  const [relationships, setRelationships] = useState<Relationship[]>([]);
  const [externalRelationships, setExternalRelationships] = useState([]);
  const [allHabits, setAllHabits] = useState<HabitUserLite[]>([]);
  const [choiceVisible, setChoiceVisible] = useState(false);
  const [relationshipsLoading, setRelationshipsLoading] = useState(false);
  const [userPickerOpen, setUserPickerOpen] = useState(false);
  const [withExternal, setWithExternal] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [view, setView] = useState(CARD);
  // eslint-disable-next-line no-unused-vars
  const [checked, setChecked] = useState([]);
  const [tutorialRunning, setTutorialRunning] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { t } = useTranslation();
  const {
    profileStore,
    tutorialStore,
    profileStore: { currentUser }
  } = useStores();
  const managingUser: UserLite | null =
    managedUser || currentUser.admin || currentUser.can_configure
      ? managedUser || currentUser
      : null;
  const api =
    managedUser || currentUser.admin || currentUser.can_configure
      ? UserRelationships
      : rapi;
  const ddi = profileStore.features().includes("DDI_CLIENT");

  const steps = ddi
    ? [
        {
          target: ".no-border-radius",
          content: t("tutorial.choose_feedback_providers"),
          disableBeacon: true,
          placement: "center",
          disableOverlayClose: true,

          hideCloseButton: true,

          showSkipButton: false,
          locale: { last: "Next", close: "Next" }
        },
        {
          target: document.querySelector("#add-someone"),
          placement: "left",
          disableBeacon: true,
          disableOverlayClose: true,
          hideCloseButton: true,
          hideFooter: true,
          spotlightClicks: true,
          spotlightPadding: 6,
          content: <div>{t("tutorial.click_here_to_get_started")}</div>,
          style: {}
        },
        {
          target: ".user-select",
          disableBeacon: true,
          disableOverlayClose: true,
          hideCloseButton: true,
          showSkipButton: false,
          hideFooter: true,
          spotlightClicks: true,
          spotlightPadding: 24,
          content: t("tutorial.search_for_users")
        }
      ]
    : [
        {
          target: ".no-border-radius",
          content: t("tutorial.welcome_message"),
          disableBeacon: true,
          placement: "center",
          disableOverlayClose: true,

          hideCloseButton: true,

          showSkipButton: false,
          locale: { last: "Next", close: "Next" }
        },
        {
          target: document.querySelector("#add-someone"),
          disableBeacon: true,
          disableOverlayClose: true,
          hideCloseButton: true,
          hideFooter: true,
          spotlightClicks: true,
          spotlightPadding: 6,
          content: t("tutorial.add_feedback_providers")
        },
        {
          target: ".choose-user-type",
          content: t("tutorial.choose_user_type"),
          disableBeacon: true,
          locale: { last: "Next", close: "Next" },
          disableOverlayClose: true,
          hideBackButton: true,

          hideCloseButton: true,

          showSkipButton: false
        },
        {
          target: "#internal",
          placementBeacon: "bottom",
          content: t("tutorial.lets_add_people"),
          disableBeacon: true,
          disableOverlayClose: true,
          hideCloseButton: true,
          hideFooter: true,
          spotlightClicks: true,
          spotlightPadding: 6,
          showSkipButton: false
        },
        {
          target: ".user-select",
          disableBeacon: true,
          disableOverlayClose: true,
          hideCloseButton: true,
          showSkipButton: false,
          hideFooter: true,
          spotlightClicks: true,
          spotlightPadding: 24,
          content: t("tutorial.search_explanation")
        },
        {
          target: relationships.length > 0 ? "#card-0" : ".choose-user-type",
          disableBeacon: true,
          hideBackButton: true,
          placement: relationships.length > 0 ? "top" : "center",
          disableOverlayClose: true,
          hideCloseButton: true,
          showSkipButton: false,
          locale: { last: "Next", close: "Next" },
          content: t("tutorial.customize_habits")
        },
        {
          target: relationships.length > 0 ? "#card-0" : ".choose-user-type",
          disableBeacon: true,
          hideBackButton: true,
          placement: relationships.length > 0 ? "top" : "center",
          disableOverlayClose: true,
          hideCloseButton: true,
          showSkipButton: false,
          locale: { last: "Close", close: "Close" },
          content: t("tutorial.remove_feedback_provider")
        },
        {
          target: ".no-border-radius",
          disableBeacon: true,
          hideBackButton: true,
          placement: "center",
          disableOverlayClose: true,
          hideCloseButton: true,
          showSkipButton: false,
          locale: { last: "Close", close: "Close" },
          content: t("tutorial.tutorial_completed")
        }
      ];

  useEffect(() => {
    if (managingUser) {
      User.habits(managingUser.id).then(({ data }) => {
        setAllHabits(
          data.habits.map((habit) => ({
            id: habit.habit_user_id,
            habit: {
              name: habit.name,
              id: habit.id,
              code: habit.code,
              type: habit.type,
              employee_type: habit.employee_type,
              description: habit.description,
              benchmark: habit.benchmark
            }
          }))
        );
      });
      api.external(managingUser.id).then(({ data }) => {
        setExternalRelationships(data);
      });
      UserRelationships.all(managingUser.id).then(({ data }) => {
        setRelationships(data);
        setLoading(false);
      });
    } else {
      Profile.fetchHabits().then(({ data }) => setAllHabits(data));
      api.external().then(({ data }) => {
        setExternalRelationships(data);
      });
      api.all().then(({ data }) => {
        setRelationships(data);

        setLoading(false);

        if (onboarding) {
          setTutorialRunning(true);
        }
      });
    }
  }, []);

  const displaySuccess = (text: string) => {
    message.success(text);
  };

  const persistChanges = (
    id: string,
    habitUserIds: string[],
    external: boolean
  ) => {
    const promise = managingUser
      ? api.update(managingUser.id, id, {
          habit_user_ids: habitUserIds,
          external
        })
      : api.update(id, { habit_user_ids: habitUserIds, external });
    promise.then(({ data }) => {
      if (external) {
        setExternalRelationships(
          externalRelationships.map((r) => (r.id === data.id ? data : r))
        );
      } else {
        setRelationships(
          relationships.map((r) => (r.id === data.id ? data : r))
        );
      }
      displaySuccess(t("changes_saved"));
    });
  };

  const updateInverse = (inverse: Relationship, relationship: Relationship) => {
    return {
      ...relationship,
      providing_habits: inverse.habits
    };
  };

  const persistInverseChanges = (
    userId: string,
    id: string,
    habitUserIds: string[]
  ) => {
    const promise = api.update(userId, id, {
      habit_user_ids: habitUserIds,
      external: false
    });

    promise.then(({ data }) => {
      setRelationships(
        relationships.map((r) =>
          r.inverse_relationship_id === data.id ? updateInverse(data, r) : r
        )
      );

      displaySuccess(t("changes_saved"));
    });
  };

  const openExternal = () => {
    setWithExternal(true);
    setUserPickerOpen(true);
    setChoiceVisible(false);
  };

  const openInternal = () => {
    setWithExternal(false);
    setUserPickerOpen(true);
    setChoiceVisible(false);

    if (tutorialRunning) {
      setTutorialRunning(false);
      setStepIndex(stepIndex + 1);
      setTimeout(() => {
        setTutorialRunning(true);
      }, 400);
    }
  };

  const addRelationships = (users: UserLite[], external = false) => {
    setButtonLoading(true);
    const promise = managingUser
      ? api.create(managingUser.id, {
          user_ids: users.map((u) => u.id),
          external
        })
      : api.create({ user_ids: users.map((u) => u.id), external });
    promise.then(({ data }) => {
      if (external) {
        setExternalRelationships([...data, ...externalRelationships]);
      } else {
        setRelationships([...data, ...relationships]);
      }
      setButtonLoading(false);
      setUserPickerOpen(false);
      if (!tutorialRunning) {
        displaySuccess(t("relationships.user_added_successfully"));
      }
      if (tutorialRunning) {
        setTutorialRunning(false);
        setStepIndex(stepIndex + 1);
        setTimeout(() => {
          setTutorialRunning(true);
        }, 400);
      }
    });
  };

  const handleOpenChoice = () => {
    if (profileStore.features().includes("DDI_CLIENT")) {
      openExternal();
    } else {
      setChoiceVisible(true);
    }
    if (tutorialRunning) {
      setTutorialRunning(false);
      setStepIndex(stepIndex + 1);
      setTimeout(() => {
        setTutorialRunning(true);
      }, 400);
    }
  };

  const handleFilter = (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      setChecked([...checked, e.currentTarget.value]);
    } else {
      setChecked(checked.filter((c) => c !== e.currentTarget.value));
    }
  };

  const isNew = (createdAt: string) => {
    const createdMoment = moment(createdAt).valueOf();
    const yesterday = moment()
      .subtract(1, "days")
      .valueOf();

    return createdMoment > yesterday;
  };

  const onRefSet = useCallback((ref) => {
    if (ref && ref.id === focusing) {
      setTimeout(() => {
        setFocusedUser(ref.id);
        ref.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });

        setTimeout(() => {
          setFocusedUser(null);
        }, 2000);
      }, 750);
    }
  });

  const selectAll = (habitUserId: string) => {
    setRelationshipsLoading(true);
    if (
      relationships
        .filter((r) => r.habits.length > 0)
        .every((r) => r.habits.find((h) => h.id === habitUserId))
    ) {
      const params = {
        relationship_ids: relationships
          .filter((r) => r.habits.length > 0)
          .map((r) => r.id),
        habit_user_id: habitUserId
      };
      const promise = managingUser
        ? api.bulkRemove(managingUser.id, params)
        : api.bulkRemove(params);
      promise.then(({ data }) => {
        setRelationshipsLoading(false);
        setRelationships(data);
      });
    } else {
      const params = {
        relationship_ids: relationships
          .filter((r) => r.habits.length > 0)
          .map((r) => r.id),
        habit_user_id: habitUserId
      };
      const promise = managingUser
        ? api.bulkAdd(managingUser.id, params)
        : api.bulkAdd(params);

      promise.then(({ data }) => {
        setRelationshipsLoading(false);
        setRelationships(data);
      });
    }
  };

  const onSelect = (relationship: Relationship, habitUserId: string) => {
    if (relationship.habits.find((h) => h.id === habitUserId)) {
      const promise = managedUser
        ? api.update(managedUser.id, relationship.id, {
            habit_user_ids: relationship.habits
              .filter((h) => h.id !== habitUserId)
              .map((h) => h.id)
          })
        : api.update(relationship.id, {
            habit_user_ids: relationship.habits
              .filter((h) => h.id !== habitUserId)
              .map((h) => h.id)
          });
      promise.then(({ data }) =>
        setRelationships(
          relationships.map((r) => (r.id === data.id ? data : r))
        )
      );
    } else {
      const promise = managedUser
        ? api.update(managedUser.id, relationship.id, {
            habit_user_ids: [
              ...relationship.habits.map((h) => h.id),
              habitUserId
            ]
          })
        : api.update(relationship.id, {
            habit_user_ids: [
              ...relationship.habits.map((h) => h.id),
              habitUserId
            ]
          });
      promise.then(({ data }) =>
        setRelationships(
          relationships.map((r) => (r.id === data.id ? data : r))
        )
      );
    }
  };

  const handleDelete = (id: string, external = false) => {
    const promise = managingUser
      ? api.delete(managingUser.id, id, external ? "external=true" : "")
      : api.delete(id, external ? "external=true" : "");
    promise.then(() => {
      if (external) {
        setExternalRelationships(
          externalRelationships.filter((r) => r.id !== id)
        );
      } else {
        setRelationships(relationships.filter((r) => r.id !== id));
      }
      displaySuccess(t("relationships.user_removed_successfully"));
    });
  };

  const callback = (tour: CallBackProps) => {
    const { action, type, index } = tour;
    if (type === EVENTS.TOUR_END) {
      tutorialStore.endCurrentTutorial();
      // Profile.update({
      //   onboarded: profileStore.currentUser.preferences.self_onboard,
      // }).then(({ data }) => {
      //   profileStore.updateInfo(data);
      // });
    }

    // if (type === EVENTS.TOUR_END || action === "skip") {
    //   // Update user preferences with completed tour flag
    //   this.props.userStore.endTour();
    // } else if (type === EVENTS.TOUR_START) {
    //   this.props.userStore.markOnboardingTutorialSeen();
    // } else if (type === EVENTS.STEP_AFTER && action !== ACTIONS.PREV && index === 4 && lifecycle === "complete") {
    //   this.props.history.push("/profile/demo");
    if (
      [EVENTS.STEP_AFTER, EVENTS.CLOSE, EVENTS.TARGET_NOT_FOUND].includes(type)
    ) {
      const nextStep = index + (action === ACTIONS.PREV ? -1 : 1);
      if (index !== 1 && index !== 3 && index !== 4) {
        setStepIndex(nextStep);
      }
    }
  };
  const rgb = hexToRgb(currentUser.organization.theme.secondary_color);
  const userName: string = managedUser ? managedUser.name.split(" ")[0] : "";
  return (
    <div style={{ height: "100%", paddingTop: 24 }} id="relationship-modal">
      <Joyride
        steps={steps}
        showSkipButton
        spotlightPadding={0}
        styles={{
          beaconInner: {
            backgroundColor: currentUser.organization.theme.secondary_color
          },
          beaconOuter: {
            backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.2)`,
            border: `2px solid ${currentUser.organization.theme.secondary_color}`
          },
          options: {
            primaryColor: currentUser.organization.theme.primary_color,
            zIndex: 1001
          }
        }}
        locale={{
          back: "Back",
          close: "Close",
          last: "Close",
          next: "Next",
          skip: "Skip"
        }}
        run={tutorialRunning}
        stepIndex={stepIndex}
        callback={callback}
      />
      {loading ? (
        <LoadingOverlay />
      ) : (
        <Wrapper
          style={!embedded ? { maxHeight: "100%", overflow: "hidden" } : {}}
        >
          <UserMultiSelect
            alreadySelectedMessage={t("relationships.already_selected")}
            hideList={
              managedUser
                ? [managedUser, ...relationships.map((r) => r.user)]
                : relationships.map((r) => r.user)
            }
            title="Add Feedback Providers"
            filterSelf={!managedUser}
            buttonLoading={buttonLoading}
            open={userPickerOpen}
            addExternalUser={() => null}
            handleCancel={() => !tutorialRunning && setUserPickerOpen(false)}
            handleSubmit={addRelationships}
            withExternal={withExternal}
          />
          <ChooseUserType
            logo={currentUser.organization.logo}
            visible={choiceVisible}
            openExternal={openExternal}
            openInternal={openInternal}
            onCancel={() => setChoiceVisible(false)}
          />
          <PageHeader
            extra={[
              <Button
                onClick={() => {
                  setStepIndex(0);
                  setTutorialRunning(true);
                }}
                icon="bulb"
              >
                {t("relationships.learn")}
              </Button>,
              <Button
                id="add-someone"
                type="primary"
                onClick={handleOpenChoice}
              >
                {t("relationships.add_someone")}
              </Button>
            ]}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            title={
              managedUser
                ? t("relationships.managed_feedback_circle", {
                    userName: managedUser.name
                  })
                : t("relationships.your_feedback_circle")
            }
            subTitle={
              <span>
                {managedUser
                  ? t("relationships.managed_users_feedback_providers", {
                      userName
                    })
                  : t("relationships.the_people_you_work_with")}
              </span>
            }
          />

          <div
            style={{
              paddingLeft: 6,
              paddingBottom: 12,
              display: "flex",
              alignItems: "flex-end",
              flexDirection: window.innerWidth < 500 && "column"
            }}
          >
            <div style={{ width: "100%" }}>
              <div style={{ marginBottom: 6 }}>
                {t("relationships.filter_users")}
              </div>
              <div style={{ width: "100%" }}>
                <Row>
                  {allHabits.map((h) => (
                    <Col
                      xxl={8}
                      xl={8}
                      lg={12}
                      sm={12}
                      xs={24}
                      onChange={handleFilter}
                    >
                      <Checkbox
                        style={{ display: "flex", alignItems: "center" }}
                        onMouseLeave={() => setHovering(null)}
                        onMouseEnter={() => setHovering(h.id)}
                        value={h.id}
                      >
                        <span
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                        >
                          {h.habit.name}
                        </span>
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  paddingRight: 6
                }}
              >
                <Radio.Group
                  value={view}
                  buttonStyle="solid"
                  onChange={(e) => setView(e.target.value)}
                >
                  <Radio.Button value={CARD}>
                    <Icon type="appstore" />
                  </Radio.Button>
                  <Radio.Button value={TABLE}>
                    <Icon type="unordered-list" />
                  </Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </div>
          {view === CARD ? (
            <ListWrapper id="user-cards" embedded={embedded}>
              <Divider style={{ fontSize: window.innerWidth < 500 && 12 }}>
                {t("relationships.people_who_give_feedback", {
                  name: managedUser ? userName : t("you")
                })}
              </Divider>

              <div
                style={{
                  justifyContent: window.innerWidth < 500 && "center",
                  display: "flex",
                  flexWrap: "wrap"
                }}
              >
                <AnimatePresence>
                  {relationships
                    .filter((r) =>
                      checked.length > 0
                        ? r.habits.some((h) => checked.includes(h.id))
                        : true
                    )
                    .filter((r) => r.habits.length > 0)
                    .sort((a, b) =>
                      isNew(a.created_at)
                        ? -1
                        : a.user.name > b.user.name
                        ? 1
                        : -1
                    )
                    .map((r, i) => (
                      <motion.div
                        positionTransition
                        key={r.id}
                        id={r.user.id}
                        ref={onRefSet}
                        exit={{ opacity: 0 }}
                      >
                        <div
                          span={6}
                          style={{
                            marginBottom: 12,
                            marginLeft: 6,
                            marginRight: 6,
                            position: "relative",
                            height: 275,
                            flex: 1
                          }}
                        >
                          <RelationshipCard
                            isNew={isNew(r.created_at)}
                            isAdmin={
                              currentUser.admin || currentUser.can_configure
                            }
                            otherUserHabits={r.other_user_habits}
                            inverseRelationshipId={r.inverse_relationship_id}
                            hovering={
                              hovering
                                ? r.habits.find((h) => hovering === h.id)
                                : focusedUser
                                ? r.user.id === focusedUser
                                : true
                            }
                            user={r.user}
                            index={i}
                            createdAt={r.created_at}
                            style={i === 0 ? { marginTop: 12 } : {}}
                            habits={r.habits}
                            providingHabits={r.providing_habits}
                            persistInverseChanges={persistInverseChanges}
                            handleDelete={handleDelete}
                            id={r.id}
                            persistChanges={persistChanges}
                            allHabits={allHabits}
                          />
                        </div>
                      </motion.div>
                    ))}
                </AnimatePresence>
              </div>
              {externalRelationships.length > 0 && (
                <>
                  <Divider style={{ fontSize: window.innerWidth < 500 && 12 }}>
                    {t("relationships.external_feedback_providers")}
                  </Divider>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: window.innerWidth < 500 && "center"
                    }}
                  >
                    <AnimatePresence>
                      {externalRelationships
                        .filter((r) =>
                          checked.length > 0
                            ? r.habits.some((h) => checked.includes(h.id))
                            : true
                        )
                        .sort((a, b) => (a.user.name > b.user.name ? 1 : -1))
                        .map((r, i) => (
                          <motion.div
                            positionTransition
                            key={r.id}
                            exit={{ opacity: 0 }}
                          >
                            <div
                              span={6}
                              style={{
                                marginBottom: 12,
                                marginLeft: 6,
                                marginRight: 6,
                                position: "relative",
                                height: 275,
                                flex: 1
                              }}
                            >
                              <RelationshipCard
                                external
                                acceptStatus={r.accept_status}
                                hovering={
                                  hovering
                                    ? r.habits.find((h) => hovering === h.id)
                                    : true
                                }
                                user={r.user}
                                handleDelete={handleDelete}
                                index={i}
                                style={i === 0 ? { marginTop: 12 } : {}}
                                habits={r.habits}
                                providingHabits={r.providing_habits}
                                id={r.id}
                                persistChanges={persistChanges}
                                allHabits={allHabits}
                              />
                            </div>
                          </motion.div>
                        ))}
                    </AnimatePresence>
                  </div>
                </>
              )}
              <Divider style={{ fontSize: window.innerWidth < 500 && 12 }}>
                {t("relationships.feedback_receivers")}
              </Divider>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: window.innerWidth < 500 && "center"
                }}
              >
                <AnimatePresence>
                  {relationships
                    .filter((r) => r.habits.length === 0)
                    .sort((a, b) =>
                      isNew(a.created_at)
                        ? -1
                        : a.user.name > b.user.name
                        ? 1
                        : -1
                    )
                    .map((r, i) => (
                      <motion.div
                        positionTransition
                        key={r.id}
                        exit={{ opacity: 0 }}
                      >
                        <div
                          span={6}
                          style={{
                            marginBottom: 12,
                            marginLeft: 6,
                            marginRight: 6,
                            position: "relative",
                            height: 275,
                            flex: 1
                          }}
                        >
                          <RelationshipCard
                            inverseRelationshipId={r.inverse_relationship_id}
                            hovering={
                              hovering
                                ? r.habits.find((h) => hovering === h.id)
                                : true
                            }
                            user={r.user}
                            isNew={isNew(r.created_at)}
                            createdAt={r.created_at}
                            handleDelete={handleDelete}
                            index={i}
                            isAdmin={
                              currentUser.admin || currentUser.can_configure
                            }
                            otherUserHabits={r.other_user_habits}
                            style={i === 0 ? { marginTop: 12 } : {}}
                            habits={r.habits}
                            providingHabits={r.providing_habits}
                            persistInverseChanges={persistInverseChanges}
                            id={r.id}
                            persistChanges={persistChanges}
                            allHabits={allHabits}
                          />
                        </div>
                      </motion.div>
                    ))}
                </AnimatePresence>
              </div>
            </ListWrapper>
          ) : (
            <RelationshipTable
              allHabits={allHabits}
              selectAll={selectAll}
              onSelect={onSelect}
              relationships={relationships.filter((r) => r.habits.length > 0)}
            />
          )}
          {relationshipsLoading && <LoadingOverlay />}
        </Wrapper>
      )}
    </div>
  );
};
export default Relationships;
