/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Popover, Tooltip } from "antd";
import { observer, inject } from "mobx-react";
import { observable } from "mobx";
import { ReactComponent as HighFiveImg } from "assets/high_five.svg";
import { ReactComponent as SuggestionImg } from "assets/suggestion.svg";
import SendHighfive from "components/SendHighfive";
import * as gaEvents from "services/ga_events";
import {
  Highfive,
  Announcement,
  Feed as FeedApi,
  Like,
  Suggestion,
  BadgeUnlock
} from "services/api";
import { HomeCard } from "../styles";
import {
  Actions,
  Body,
  Action,
  ActionImage,
  ActionImageWhiteLabeled,
  ActionText,
  FeedWrapper
  // MobileActions
} from "./styles";
import FeedList from "./FeedList";
import AnnouncementForm from "./AnnouncementForm";
import SuggestionForm from "./SuggestionForm";
import HighfiveHighlights from "./HighfiveHighlights";
import Acts from "../../Actions";
import Leaderboard from "./Leaderboard";
import BadgeCase from "./BadgeCase";
import { withTranslation } from "react-i18next";

function snake2Pascal(str) {
  str += "";
  str = str.split("_");
  for (let i = 0; i < str.length; i += 1) {
    str[i] = str[i].slice(0, 1).toUpperCase() + str[i].slice(1, str[i].length);
  }
  return str.join("");
}

@inject("feedStore")
@observer
class Feed extends React.Component {
  @observable announcementFormOpen = false;
  @observable sendHighfiveOpen = false;
  @observable suggestionOpen = false;
  @observable feed = [];
  @observable page = 1;
  @observable loading = true;
  @observable hasMore;
  @observable isHovering = false;
  @observable stats = {};

  constructor(props) {
    super(props);
    this.mobile = window.innerWidth < 500;
    const { feedStore } = this.props;
    feedStore.fetch();
    // FeedApi.fetch(this.page).then(({ data }) => {
    //   this.feed = data.feed;
    //   this.stats = data.stats;
    //   this.hasMore = data.has_more;
    //   this.loading = false;
    // });
    // this.handleDelete = this.handleDelete.bind(this);
    // this.createAnnouncement = this.createAnnouncement.bind(this);
  }
  static propTypes = {
    currentUserId: PropTypes.string.isRequired,
    orgName: PropTypes.string.isRequired,
    suggestionManagers: PropTypes.array.isRequired,
    feedStore: PropTypes.array.isRequired,
    demoData: PropTypes.array,
    inTutorial: PropTypes.bool,
    showLeaderboard: PropTypes.bool,
    showBadges: PropTypes.bool.isRequired
  };

  static defaultProps = {
    demoData: [],
    inTutorial: false
  };

  loadMore = () => {
    this.page += 1;
    this.loading = true;
    gaEvents.scrollFeed();
    FeedApi.fetch(this.page).then(({ data }) => {
      // eslint-disable-next-line no-debugger

      this.feed = this.feed.concat(data.feed);
      this.hasMore = data.has_more;
      this.loading = false;
    });
  };

  createAnnouncement = (values) => {
    gaEvents.makeAnnouncement();
    Announcement.create(values).then(({ data }) => {
      this.feed = [data].concat(this.feed);
      this.announcementFormOpen = false;
    });
  };

  createSuggestion = (values) => {
    gaEvents.sendSuggestion();
    Suggestion.create(values).then(() => (this.suggestionOpen = false));
  };

  createHighfive = (values) => {
    gaEvents.sendHighfive();
    const promise = Highfive.create(values).then(({ data }) => {
      this.feed = data.concat(this.feed);
      this.sendHighfiveOpen = false;
    });
    return promise;
  };
  /* eslint-disable */

  handleLike = (highfive) => {
    const { currentUserId, feedStore } = this.props;
    highfive.has_liked = !highfive.has_liked;

    if (highfive.has_liked) {
      Like.create({
        likeable_id: highfive.id,
        likeable_type: snake2Pascal(highfive.type)
      }).then(({ data }) => {
        highfive.likes = [...highfive.likes, data];

        feedStore.updateHighfive(highfive);
        this.forceUpdate();
      });
    } else {
      const like = highfive.likes.find((l) => l.user_id === currentUserId);
      Like.delete(highfive.id);
      highfive.likes = highfive.likes.filter((l) => l.id !== like.id);
      feedStore.updateHighfive(highfive);
      this.forceUpdate();
    }
  };

  addComment = (text, mentions, highfive) => {
    const { feedStore } = this.props;
    if (highfive.type === "highfive") {
      Highfive.createComment(highfive.id, { text, mentions }).then(
        ({ data }) => {
          feedStore.addComment(highfive.id, data);
        }
      );
    } else {
      BadgeUnlock.createComment(highfive.id, { text, mentions }).then(
        ({ data }) => {
          feedStore.addComment(highfive.id, data);
        }
      );
    }
  };

  handleDelete = (id, type) => {
    const { t } = this.props;
    Modal.confirm({
      title: t("homepage.feed.confirm_delete_title", { type }),
      content: t("homepage.feed.action_cannot_be_undone"),
      onOk: () => {
        if (type === "high-five") {
          this.feed = this.feed.filter((item) => item.id !== id);
          Highfive.delete(id);
        } else {
          this.feed = this.feed.filter((item) => item.id !== id);
          Announcement.delete(id);
        }
      },
      onCancel() {}
    });
  };

  render() {
    const {
      currentUserId,
      orgName,
      suggestionManagers,
      inTutorial,
      demoData,
      showBadges,
      showLeaderboard,
      t,
      feedStore: {
        feed,
        loading,
        hasMore,
        stats,
        loadMore,
        handleDelete,
        createAnnouncement,
        announcementFormOpen,
        sendHighfiveOpen,
        suggestionOpen,
        setAnnouncementFormOpen,
        setSuggestionOpen,
        setSendHighfiveOpen,
        createHighfive,
        createSuggestion
      }
    } = this.props;

    const Highlights = (
      <HighfiveHighlights onLike={this.handleLike} stats={stats} />
    );
    // let announcementImage = null;
    // if (canAnnounce) {
    //   announcementImage = (
    //     <Action onClick={() => setAnnouncementFormOpen(true)}>
    //       <ActionImage src={AnnouncementImg} />
    //       <ActionText>Make an Announcement</ActionText>
    //     </Action>
    //   );
    // }
    // if (window.innerWidth < 500) {
    //   height = '500px';
    // } else if (fullSize) {
    //   height = 'calc(100vh - 125px)';
    // } else {
    //   height = '55vh';
    // }
    const height = "100%";
    return (
      <div
        style={{
          display: "flex",
          height,
          minHeight: 414,
          flexDirection: window.innerWidth < 500 && "column"
        }}
      >
        <HomeCard
          key="HomeCard"
          id="feed"
          extra={
            <div style={{ display: "flex" }}>
              <Tooltip title={t("homepage.feed.send_a_highfive")}>
                <Button type="primary" shape="circle" data-cy="highfive-button">
                  <ActionImage style={{ height: 24, width: 24, marginLeft: 3 }}>
                    <HighFiveImg onClick={setSendHighfiveOpen} />
                  </ActionImage>
                </Button>
              </Tooltip>
              <Tooltip
                title={t("homepage.feed.send_orgname_suggestion", { orgName })}
              >
                <Button type="primary" shape="circle" style={{ marginLeft: 6 }}>
                  <ActionImageWhiteLabeled
                    style={{
                      height: 24,
                      width: 24,
                      marginLeft: 1,
                      marginTop: 4
                    }}
                  >
                    <SuggestionImg onClick={setSuggestionOpen} />
                  </ActionImageWhiteLabeled>
                </Button>
              </Tooltip>
            </div>
            // <Popover
            //   key="Popover"
            //   trigger="click"
            //   placement="left"
            //   content={Highlights}
            // >
            //   <Button>Highlights</Button>
            // </Popover>
          }
          style={{
            height: window.innerWidth < 500 ? 450 : height,
            minHeight: 414,
            width: !showLeaderboard || window.innerWidth < 500 ? "100%" : "75%"
          }}
          title={t("homepage.feed.title", { orgName })}
          bodyStyle={{ height: "calc(100% - 64px)", padding: 0 }}
        >
          {this.mobile && (
            <Acts
              icon="plus"
              direction="up"
              showDots={false}
              shade
              items={[
                {
                  id: 0,
                  color: "#F77911",
                  icon: "message",
                  text: t("homepage.feed.send_a_highfive"),
                  onClick: () => setSendHighfiveOpen(true)
                },
                {
                  id: 1,
                  color: "#349980",
                  icon: "bulb",
                  text: t("homepage.feed.make_a_suggestion"),
                  onClick: () => setSuggestionOpen(true)
                },
                {
                  id: 2,
                  color: "#349980",
                  icon: "message",
                  text: t("homepage.feed.chat_with_support"),
                  onClick: () => {
                    window.olark("api.box.expand");
                  }
                }
              ]}
              size="large"
              menuItemStyle={{
                width: "100vw",
                justifyContent: "start",
                padding: "0 24px",
                transform: "scale(1)",
                display: "flex",
                alignItems: "center",
                position: "absolute"
              }}
              menuStyle={{
                position: "fixed",
                display: "flex",
                bottom: 120,
                right: 0,
                left: 0,
                width: "100vw"
              }}
              style={{
                left: "calc(50% - 25px)",
                bottom: 24,
                top: null
              }}
            />
            // <MobileActions>
            //   <Actions>
            //     <Action onClick={() => (this.sendHighfiveOpen = true)}>
            //       <ActionImage src={HighFiveImg} />
            //     </Action>
            //     <Action onClick={() => (this.announcementFormOpen = true)}>
            //       <ActionImage src={AnnouncementImg} />
            //     </Action>
            //     <Action>
            //       <ActionImage
            //         onClick={() => (this.suggestionOpen = true)}
            //         src={SuggestionImg}
            //       />
            //     </Action>
            //   </Actions>
            // </MobileActions>
          )}

          <Body>
            <FeedWrapper>
              <FeedList
                feed={inTutorial ? demoData : feed}
                hasMore={hasMore}
                loadMore={loadMore}
                loading={loading}
                onLike={this.handleLike}
                addComment={this.addComment}
                currentUserId={currentUserId}
                handleDelete={handleDelete}
              />
            </FeedWrapper>
            <AnnouncementForm
              visible={announcementFormOpen}
              onCancel={() => {
                setAnnouncementFormOpen(false);
              }}
              onCreate={createAnnouncement}
            />
            <SuggestionForm
              visible={suggestionOpen}
              orgName={orgName}
              onCancel={() => setSuggestionOpen(false)}
              onCreate={createSuggestion}
              suggestionManagers={suggestionManagers}
            />
            {sendHighfiveOpen && (
              <SendHighfive
                visible={sendHighfiveOpen}
                onClose={() => setSendHighfiveOpen(false)}
                handleSubmit={createHighfive}
              />
            )}
          </Body>
        </HomeCard>
        {showBadges && (
          <Actions
            style={{
              marginLeft: window.innerWidth >= 500 && 24,
              marginTop: window.innerWidth < 500 && 24
            }}
          >
            <BadgeCase />
          </Actions>
        )}
        {showLeaderboard && (
          <Actions
            style={{
              marginLeft: window.innerWidth >= 500 && 24,
              marginTop: window.innerWidth < 500 && 24
            }}
          >
            <Leaderboard />
            {/* <Action onClick={() => setSendHighfiveOpen(true)}>
                <ActionImage ignoreWhiteLabel>
                  <HighFiveImg />
                </ActionImage>
                <ActionText>Give a High-Five</ActionText>
              </Action>
              {announcementImage}
              <Action>
                <ActionImageWhiteLabeled
                  onClick={() => setSuggestionOpen(true)}
                  ignoreWhiteLabel={false}
                >
                  <SuggestionImg />
                </ActionImageWhiteLabeled>
                <ActionText>Send {orgName} a Suggestion</ActionText>
              </Action> */}
          </Actions>
        )}
      </div>
    );
  }
}

export default withTranslation()(Feed);
