/* eslint-disable no-param-reassign */
export const getDom = w => {
  const bodyLine = 18;
  const parent = document.getElementById(w.id);
  const maxHeight = parent.offsetHeight;
  const titleHeight = parent.children[0].offsetHeight;
  const bodyHeight = parent.children[1].scrollHeight;
  const bodyLines = Math.round(parent.children[1].scrollHeight / bodyLine);
  return {
    bodyLine,
    parent,
    maxHeight,
    titleHeight,
    bodyHeight,
    titleMarginBottom: 10,
    bodyLines,
    verticalPadding: 15
  };
};

export const measureWidgetSpace = dom => {
  // const dom = getDom();
  // const spaceRemaining = Math.floor(
  //   (dom.maxHeight -
  //     (dom.titleHeight + dom.titleMarginBottom + dom.verticalPadding)) /
  //     dom.bodyLine
  // );
  return dom.bodyLines > 3;
};
export const transitionParent = dom => {
  // const dom = getDom();
  dom.parent.style.transition = 'height 0.5s';
  dom.parent.children[0].style.lineClamp = 'none';
  dom.parent.style.height = `${dom.titleHeight + dom.titleMarginBottom + dom.bodyHeight + dom.verticalPadding}px`;
};