import React, { useState, useEffect } from "react";
import { Form, Input, Icon, Row, Col, Button, Typography } from "antd";
import { Profile } from "services/api";

const EmailPasswordSignIn = ({ form, onSubmit, loading }) => {
  const { getFieldDecorator, get } = form;
  const [reset, setReset] = useState(false);
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const isFormValid = (): boolean => {
    return !Object.values(form.getFieldsValue()).some(
      (v: string): boolean => !v || !v.length
    );
  };

  // useEffect(() => {
  //   window.addEventListener(key);
  //   return () => {
  //     cleanup;
  //   };
  // }, [input]);

  const sendResetEmail = () => {
    Profile.sendResetEmail(email);
    setEmailSent(true);
  };

  if (reset) {
    return (
      <div>
        <Typography.Title level={4}>Reset Your Password</Typography.Title>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          size="large"
          placeholder="Enter your email address"
          style={{ marginBottom: 24 }}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button style={{ marginRight: 12 }} onClick={() => setReset(false)}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => sendResetEmail()}>
            Send Reset Email
          </Button>
        </div>
        {emailSent && (
          <span>
            Reset password email sent! If there is an account belonging to this
            email, you should receive a reset password link momentarily
          </span>
        )}
      </div>
    );
  }

  return (
    <Form>
      <Form.Item>
        {getFieldDecorator("email", {
          rules: [{ required: true, message: "Please a valid email" }]
        })(
          <Input
            size="large"
            prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Email"
          />
        )}
      </Form.Item>
      <Form.Item style={{ marginBottom: "0" }}>
        {getFieldDecorator("password", {
          rules: [{ required: true, message: "Please input your Password!" }]
        })(
          <Input
            size="large"
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder="Password"
          />
        )}
      </Form.Item>
      <Button
        style={{ paddingLeft: 0, marginTop: 4, marginBottom: 24 }}
        type="link"
        onClick={() => setReset(true)}
      >
        Forgot your Password?
      </Button>
      <Row style={{ display: "flex" }}>
        <Col span={24}>
          <Form.Item>
            <Button
              onClick={() => onSubmit(form.getFieldsValue())}
              size="large"
              style={{ width: "100%" }}
              disabled={!isFormValid()}
              loading={loading}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              data-testid="logIn"
            >
              Sign In
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create()(EmailPasswordSignIn);
