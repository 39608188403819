import React from "react";
import PropTypes from "prop-types";
import {
  HeaderContainer,
  IconContainer,
  Title,
  TitleContainer,
  Body
} from "./styles";

const WidgetHeader = ({ icon, title, desc, overflow, font }) => {
  return (
    <>
      <HeaderContainer>
        <IconContainer>{icon}</IconContainer>
        <TitleContainer>
          <Title font={font}>{title}</Title>
        </TitleContainer>
      </HeaderContainer>
      <Body font={font} overflow={overflow}>
        {desc}
      </Body>
    </>
  );
};

WidgetHeader.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  overflow: PropTypes.string.isRequired,
  font: PropTypes.object.isRequired
};

export default WidgetHeader;
