import styled from "styled-components";

export const EngagementContainer = styled.div`
  display: flex;
  justify-content: space-around;
  background: white;
  height: 34;
  width: 100%;
`;

export const BarGraphContainer = styled.div`
  height: 344px;
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  position: relative;
`;

export const Graph = styled.div`
  position: absolute;
  left: ${props => `${100 / props.count * props.index}%`};
  bottom: 0px;
  height: 344px;
  width: ${props => `${100 / props.count}%`};
`;

export const LineGraphContainer = styled.div`
  height: 250px;
  width: 50%;
  background: white;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const Tooltip = styled.div`
  width: 250px;
  background: white;
`;

export const Behavior = styled.div`
  display: flex;
  align-items: center;
`;

export const DateRow = styled.div`
  padding-left: 25%;
  border-bottom: 1px solid #e8e8e8;
  font-weight: bolder;
`;