/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Tooltip, Avatar } from "antd";
import nameToInitials from "utils/nameToInitials";
import HighFive from "assets/high_five.svg";
import Carrot from "assets/carrot.png";
import { observer } from "mobx-react";
import { withTheme } from "styled-components";

import viewModal from "components/FullScreenModal";

import Mention from "components/Mention";
import CommentItem from "components/Comments";

import {
  LikeIcon,
  Wrapper,
  Header,
  Left,
  HighFiveImg,
  Description,
  Time,
  Content,
  Footer,
  Likes,
  Delete,
  Private
} from "./styles";
import feedStore from "../../../../../stores/feedStore";
import { useTranslation } from "react-i18next";
// import useStores from '../../../../../utils/useStores';

export const receivers = (receiversArray, sender, currentUserId) => {
  // console.log(avatar);

  // const replaced = recipient.replace(name, 'you');
  // const arr = replaced.split(', ');
  const arr = receiversArray.sort((a) => (a.id === currentUserId ? -1 : 1));
  if (arr.length === 1) {
    return (
      <span
        style={{ cursor: "pointer" }}
        onClick={() => viewModal({ id: arr[0].id, type: "user" })}
      >
        {arr[0].name}
      </span>
    );
  }
  if (arr.length === 2) {
    return (
      <span>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => viewModal({ id: arr[0].id, type: "user" })}
        >
          {arr[0].name}
        </span>{" "}
        and{" "}
        <span
          style={{ cursor: "pointer" }}
          onClick={() => viewModal({ id: arr[1].id, type: "user" })}
        >
          {arr[1].name}
        </span>
      </span>
    );
  }
  return (
    <span>
      <span
        style={{ cursor: "pointer" }}
        onClick={() => viewModal({ id: receiversArray[0].id, type: "user" })}
      >
        {receiversArray[0].name}
      </span>
      ,{" "}
      <span
        style={{ cursor: "pointer" }}
        onClick={() => viewModal({ id: receiversArray[1].id, type: "user" })}
      >
        {receiversArray[1].name}
      </span>{" "}
      and{" "}
      <Tooltip
        title={receiversArray.slice(2).map((u) => (
          <li>{u.name}</li>
        ))}
      >
        {receiversArray.length - 2} others
      </Tooltip>
    </span>
  );
};

const HighFiveItem = ({
  highfive,
  onLike,
  currentUserId,
  handleDelete,
  noDelete,
  noBorder,
  noLike,
  addComment,
  deleteComment,
  afterCreateLike,
  afterDeleteLike,
  noComments,
  theme
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper noBorder={noBorder} private={highfive.private} data-cy="feed-item">
      <Header>
        <Left>
          {highfive.sender.id === "rhabit" ? (
            <div
              style={{
                minHeight: 25,
                minWidth: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                background: "rgba(0,167,79,0.25)"
              }}
            >
              <img alt="carrot" src={Carrot} height={15} />
            </div>
          ) : (
            <Avatar
              style={{ minWidth: "25px" }}
              size="small"
              src={highfive.sender.avatar}
            >
              {nameToInitials(highfive.sender.name)}
            </Avatar>
          )}
          <HighFiveImg src={HighFive} />
          <Avatar
            style={{ minWidth: "25px" }}
            size="small"
            src={highfive.receivers[0].avatar}
          >
            {nameToInitials(highfive.receivers[0].name)}
          </Avatar>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Description>
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  viewModal({ id: highfive.sender.id, type: "user" })
                }
              >
                {highfive.sender.name}
              </span>{" "}
              {t("homepage.feed.high_fived")}{" "}
              {receivers(
                highfive.receivers,
                highfive.sender,
                currentUserId,
                highfive.receiver
              )}{" "}
              {highfive.private && (
                <Private>({t("homepage.feed.private")})</Private>
              )}
            </Description>
            <Time>
              {moment(highfive.created_at).format("MM/DD/YY hh:mm a")}
            </Time>
          </div>
        </Left>
      </Header>
      <Content>{highfive.content}</Content>
      <Footer noDelete={noDelete} style={{ marginBottom: 6 }}>
        {!noLike && (
          <>
            <Tooltip
              title={
                highfive.has_liked
                  ? t("homepage.feed.unlike_highfive")
                  : t("homepage.feed.like_highfive")
              }
            >
              <LikeIcon
                data-cy="like-icon"
                type="like"
                fill={theme.secondary_color}
                color={theme.secondary_color}
                theme={highfive.has_liked && "filled"}
                hasLiked={highfive.has_liked}
                onClick={() => {
                  onLike(highfive.id, "Highfive");
                }}
                id={highfive.id}
              />
            </Tooltip>
            <Tooltip
              title={highfive.likes.map((l) => (
                <li>{`${l.first_name} ${l.last_name}`}</li>
              ))}
            >
              <Likes data-cy="likes-count">{highfive.likes.length}</Likes>
            </Tooltip>
          </>
        )}
        {highfive.sender.id === currentUserId && !noDelete && (
          <Delete onClick={() => handleDelete(highfive.id, "high-five")}>
            delete
          </Delete>
        )}
      </Footer>
      {!noComments && (
        <>
          {highfive.total_comments > 5 && highfive.comments.length <= 5 && (
            <a onClick={() => feedStore.fetchAllComments(highfive.id)}>
              {t("homepage.feed.view_more_comments", {
                count: highfive.total_comments - 5
              })}
            </a>
          )}
          {highfive.comments
            .sort((a, b) =>
              moment(a.created_at).valueOf() > moment(b.created_at).valueOf()
                ? 1
                : -1
            )
            .map((comment) => (
              <CommentItem
                comment={comment}
                key={comment.id}
                onDelete={deleteComment}
                afterCreateLike={afterCreateLike}
                afterDeleteLike={afterDeleteLike}
              />
            ))}
          <Footer style={{ marginTop: "12px", marginBottom: 6 }}>
            <Mention
              placeholder={t("homepage.feed.add_a_reply")}
              onSubmit={addComment}
              style={{ width: "100%" }}
            />
          </Footer>
        </>
      )}
    </Wrapper>
  );
};

HighFiveItem.propTypes = {
  noComments: PropTypes.bool,
  highfive: PropTypes.shape({
    sender: PropTypes.shape({
      name: PropTypes.string.isRequired,
      avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      id: PropTypes.string.isRequired
    }).isRequired,
    receiver: PropTypes.shape({
      name: PropTypes.string.isRequired,
      avatar: PropTypes.string
    }).isRequired,
    content: PropTypes.string.isRequired,
    likes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        user_id: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    created_at: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    has_liked: PropTypes.bool,
    private: PropTypes.any.isRequired
  }),
  onLike: PropTypes.func.isRequired,
  currentUserId: PropTypes.string,
  handleDelete: PropTypes.func,
  afterDeleteLike: PropTypes.func,
  afterCreateLike: PropTypes.func,
  noDelete: PropTypes.bool
}.isRequired;

HighFiveItem.defaultProps = {
  currentUserId: "",
  noDelete: false,
  handleDelete: null,
  noBorder: false,
  noComments: false
};

export default observer(withTheme(HighFiveItem));
