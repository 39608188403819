/* eslint-disable no-debugger */

/* eslint-disable no-unused-vars */

import React from "react";
import UserAvatar from "components/UserAvatar";
import { WidgetContainer } from "../styles";
import { OverflowContainer } from "../TipWidget/styles";
import WidgetHeader from "../WidgetHeader";
import { OverflowText } from "./styles";

type AFProps = {
  w: any;
  font: any;
  overflow: boolean;
  transition: () => void;
};

const mobile = window.innerWidth < 500;
// answer is text, feedback_answer is Answer class
const AdditionalFeedbackWidget = ({
  w,
  font,
  overflow,
  transition
}: AFProps) => {
  const statusMap = {
    yes: w.answer || "Preparing answer",
    sometimes: "Will follow up in person",
    no: "No response"
  };
  let text;
  if (!w.feedback_answer.response) {
    text = "Unanswered";
  } else {
    text = statusMap[w.feedback_answer.response];
  }

  const replaceText = (
    str: string,
    feedback // eslint-disable-next-line no-template-curly-in-string
  ) =>
    str
      .replace(/\${userName}/g, feedback.asker.name)
      .replace(/\${habitName}/g, feedback.habit_name);

  return (
    <WidgetContainer id={w.id}>
      <WidgetHeader
        font={font}
        overflow={overflow}
        desc={w.answer}
        title={replaceText(w.prompt.question_text, w)}
        icon={
          <UserAvatar
            style={{ zIndex: 1 }}
            size="large"
            avatar={w.feedback_giver.avatar}
            name={w.feedback_giver.name}
          />
        }
      />
      {!w.answer && (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <span>{text}</span>
        </div>
      )}
      {overflow && (
        <OverflowContainer font={font}>
          <OverflowText font={font} role="presentation" onClick={transition}>
            Read More
          </OverflowText>
        </OverflowContainer>
      )}
    </WidgetContainer>
  );
};

export default AdditionalFeedbackWidget;
