/* eslint-disable */
import * as Types from '../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type ICreateExtraFeedbackRequestMutationVariables = Types.Exact<{
  input: Types.ICreateExtraFeedbackRequestInput;
}>;


export type ICreateExtraFeedbackRequestMutation = (
  { __typename?: 'Mutation' }
  & { createExtraFeedbackRequest?: Types.Maybe<(
    { __typename?: 'WorkItem' }
    & Pick<Types.IWorkItem, 'id'>
  )> }
);


export const CreateExtraFeedbackRequestDocument = gql`
    mutation createExtraFeedbackRequest($input: CreateExtraFeedbackRequestInput!) {
  createExtraFeedbackRequest(input: $input) {
    id
  }
}
    `;
export type ICreateExtraFeedbackRequestMutationFn = ApolloReactCommon.MutationFunction<ICreateExtraFeedbackRequestMutation, ICreateExtraFeedbackRequestMutationVariables>;

/**
 * __useCreateExtraFeedbackRequestMutation__
 *
 * To run a mutation, you first call `useCreateExtraFeedbackRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExtraFeedbackRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExtraFeedbackRequestMutation, { data, loading, error }] = useCreateExtraFeedbackRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExtraFeedbackRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICreateExtraFeedbackRequestMutation, ICreateExtraFeedbackRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<ICreateExtraFeedbackRequestMutation, ICreateExtraFeedbackRequestMutationVariables>(CreateExtraFeedbackRequestDocument, baseOptions);
      }
export type CreateExtraFeedbackRequestMutationHookResult = ReturnType<typeof useCreateExtraFeedbackRequestMutation>;
export type CreateExtraFeedbackRequestMutationResult = ApolloReactCommon.MutationResult<ICreateExtraFeedbackRequestMutation>;
export type CreateExtraFeedbackRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<ICreateExtraFeedbackRequestMutation, ICreateExtraFeedbackRequestMutationVariables>;