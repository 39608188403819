import styled from "styled-components";
import { media } from "utils/style-utils";

export const Body = styled.div`
  height: ${props => (props.goalsActive ? "70%" : "97%")};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: ${props => (props.goalsActive ? "1px solid #e8e8e8" : "none")};
`;

export const HabitGrid = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 12px;
  position: relative;
  ${media.handheld`
  `}
`;

export const Requests = styled.div`
  width: 35%
  height: 100%;
  padding: 12px 24px 12px 24px;
`;

export const Goals = styled.div`
  width: 100%;
  height: 30%;
  ${media.handheld`
    padding-bottom: 24px;
  `}
`;

export const ChoiceButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  cursor: pointer;

  background: white;
  transition: 0.2s;
  &:hover {
    background: #e8e8e8;
  }
`;

export const RequestHeader = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
  font-weight: bold;
`;

export const CarrotWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  position: relative;
`;

export const RequestCount = styled.div`
  height: 45px;
  width: 45px;
  background-color: #00bc59;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 22.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  color: white;
  top: calc(50% - 22.5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

export const Explore = styled.div`
  width: 100%;
  position: absolute;
  text-align: center;
  color: #9b9b9b;
  bottom: 2px;
  font-size: 12px;

  ${media.handheld`
    position: unset;
    margin: 12px 0px;
  `}
`;
