/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Label as Api } from "services/api";
import { List, Input, Button, Form, Modal } from "antd";
import PropTypes from "prop-types";
import Label from "components/Label";
import useStores from "utils/useStores";
import LabelForm from "components/LabelForm";
import {
  Wrapper,
  Header,
  ItemWrapper,
  Actions,
  FormWrapper,
  HeaderText
} from "./styles";

function useForceUpdate() {
  const [value, set] = useState(true); //boolean state
  return () => set(!value); // toggle the state to force render
}

const ManageLabels = ({ toggleLoading }) => {
  const forceUpdate = useForceUpdate();

  const { objectiveStore } = useStores();
  const { labels } = objectiveStore;
  const [formOpen, setFormOpen] = useState(false);
  const [editing, setEditing] = useState(null);
  const [query, setQuery] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  useEffect(() => {
    toggleLoading();
  }, []);

  const addLabel = (params) => {
    setButtonLoading(true);
    Api.create(params).then(({ data }) => {
      objectiveStore.setData(data);
      setFormOpen(false);
      setButtonLoading(false);
    });
  };
  const editLabel = (params) => {
    setButtonLoading(true);
    Api.update(editing, params).then(({ data }) => {
      objectiveStore.setData(data);
      setEditing(null);
      setButtonLoading(false);
    });
  };

  const confirmDelete = (item) => {
    Modal.confirm({
      title: `Are you sure you want to delete ${item.text}?`,
      onOk() {
        Api.delete(item.id).then(({ data }) => {
          delete objectiveStore.data.label[item.id];
          objectiveStore.setData(data);
          forceUpdate();
        });
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };

  return (
    <Wrapper>
      <HeaderText>Manage Labels</HeaderText>
      <Header>
        <Input
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search labels"
          style={{ width: "300px" }}
        />
        <Button type="primary" onClick={() => setFormOpen(true)}>
          New Label
        </Button>
      </Header>
      {formOpen && (
        <FormWrapper>
          <LabelForm
            onSubmit={addLabel}
            buttonLoading={buttonLoading}
            onCancel={() => setFormOpen(false)}
          />
        </FormWrapper>
      )}
      <List
        header={`${labels.length} labels`}
        dataSource={labels.filter(
          (l) => l.text.toLowerCase().indexOf(query.toLowerCase()) === 0
        )}
        renderItem={(item) => (
          <List.Item style={{ display: "flex", flexDirection: "column" }}>
            <ItemWrapper>
              <Label label={item} />
              {item.objectives.length > 0 && (
                <span>Used by {item.objectives.length} objectives</span>
              )}
              <Actions>
                <a
                  style={{ marginRight: 6 }}
                  onClick={() => setEditing(item.id)}
                >
                  Edit
                </a>
                {" | "}

                <a
                  onClick={() => confirmDelete(item)}
                  style={{ color: "red", marginLeft: 6 }}
                >
                  Delete
                </a>
              </Actions>
            </ItemWrapper>
            {editing === item.id && (
              <FormWrapper>
                <LabelForm
                  editing={item}
                  onSubmit={editLabel}
                  buttonLoading={buttonLoading}
                  onCancel={() => setEditing(null)}
                />
              </FormWrapper>
            )}
          </List.Item>
        )}
      />
    </Wrapper>
  );
};

ManageLabels.propTypes = {
  toggleLoading: PropTypes.func.isRequired
};

export default ManageLabels;
