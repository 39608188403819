/* eslint-disable  */
import React from "react";
import { Form, Modal, Table, Button, Checkbox } from "antd";

const ReciprocitySetup = ({
  provider,
  habits,
  createProposals,
  form,
  close,
  title
}) => (
  <Modal
    centered
    title={title || `Add ${provider.name} as a Feedback Provider`}
    visible={Boolean(provider)}
    width="75vw"
    onCancel={close}
    footer={
      <Button
        type="primary"
        onClick={() => {
          form.validateFields((err, res) => {
            if (!err) {
              createProposals(res);
            }
          });
        }}
      >
        Save Changes
      </Button>
    }
  >
    <Table
      pagination={false}
      dataSource={[provider]}
      columns={[{ title: "Feedback Provider", dataIndex: "name" }].concat(
        habits
          .sort((a, b) => (a.name < b.name ? -1 : 1))
          .map((habit) => ({
            title: habit.name,
            key: habit.id,
            align: "center",
            render: () => {
              return (
                <div>
                  {form.getFieldDecorator(`${habit.id}`, {
                    getValueProps: () => "checked"
                  })(
                    <Checkbox
                      defaultChecked={habit.raters.find(
                        (r) => r.id === provider.id
                      )}
                    />
                  )}
                </div>
              );
            }
          }))
      )}
    />
  </Modal>
);

export default Form.create()(ReciprocitySetup);
