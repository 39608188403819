import React, { useEffect, useState } from "react";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { Button } from "antd";
import { Objective as api } from "services/api";
import { Flair } from "containers/WorkItems/styles";
import { ReactComponent as ObjectiveImg } from "assets/dashboard/newly_assigned_objective.svg";
import SummaryPie from "components/ObjectiveSummaryPie";
import { HomeCard } from "../styles";
import useStores from "../../../utils/useStores";
import LoadingOverlay from "../../../components/LoadingOverlay";
import ObjectiveDot from "../../../components/ObjectiveDot";
import { Wrapper, Side, Item, Name } from "./styles";
import ObjectiveDisplay from "./ObjectiveDisplay";

const Objectives = () => {
  const [loading, setLoading] = useState(true);
  const [objectives, setObjectives] = useState([]);

  useEffect(() => {
    api.summary().then(({ data }) => {
      setObjectives(data);
      setLoading(false);
    });
  }, []);

  const mobile = window.innerWidth < 500;
  return (
    <HomeCard
      bodyStyle={{
        height: !mobile && "calc(100% - 56px)",
        overflowX: !mobile && "hidden",
        padding: "12px 12px"
      }}
      style={
        mobile
          ? {}
          : {
              height: "100%",
              overflowX: "hidden",
              position: "relative"
            }
      }
      title="Objectives"
    >
      {loading ? (
        <LoadingOverlay />
      ) : (
        <Wrapper>
          {objectives.length > 0 ? (
            <ObjectiveDisplay objectives={objectives} />
          ) : (
            <Wrapper
              style={{ alignItems: window.innerWidth < 500 && "center" }}
            >
              <Flair>
                <ObjectiveImg />
              </Flair>
              <Side
                style={{ width: "70%", display: "flex", alignItems: "center" }}
              >
                <h3>Rhabit&apos;s Better With Objectives!</h3>
                <p style={{ textAlign: "center" }}>
                  Looks like you don&apos;t have any objectives defined.
                  Objectives are great ways of setting goals for yourself and
                  others. Click below to get started.
                </p>
                <NavLink to="/objectives">
                  <Button>Take me to Objectives</Button>
                </NavLink>
              </Side>
            </Wrapper>
          )}
        </Wrapper>
      )}
    </HomeCard>
  );
};

export default Objectives;
