import React, { useState } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";
import { HomeCard } from "../styles";
import Action from "./Action";
import {
  StyledIcon, // SubAnalytics,
  SubAnalyticsContainer,
  DontKnowPercent
} from "./styles";
import NetworkRaters from "./NetworkRaters";
import CoachModal from "./CoachModal";
import { useTranslation } from "react-i18next";
import Feature from "components/Feature";

const desktop = window.innerWidth > 1590;
const mobile = window.innerWidth < 500;

const ActionsAndInfo = ({
  loading,
  habit,
  currentUserId,
  isDashboardViewer,
  theme,
  profileStore,
  updateHabit
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const headStyle = {
    padding: "0 24px",
    color: "rgba(0, 0, 0, 0.85)",
    fontWeight: 500,
    fontSize: theme.font.header
  };

  const onClose = () => {
    setOpen(false);
  };
  const onOpen = () => {
    setOpen(true);
  };
  return (
    <HomeCard
      id="actions"
      title={t("deep_dive.actions_and_info.title")}
      style={{ minHeight: "432px" }}
      headStyle={headStyle}
      bodyStyle={{ paddingBottom: mobile && "40px" }}
    >
      <CoachModal visible={open} cancel={onClose}></CoachModal>
      <NetworkRaters
        updateHabit={updateHabit}
        profileStore={profileStore}
        isDashboardViewer={isDashboardViewer}
        habit={habit}
        loading={loading}
        currentUserId={currentUserId}
      />
      {!isDashboardViewer && (
        <>
          <div style={{ marginTop: desktop ? "16px" : "14px" }}>
            <span style={{ fontSize: theme.font.subheader }}>
              {t("deep_dive.actions_and_info.take_action")}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "space-between",
              flexDirection: desktop ? "row" : "column",
              height: mobile && "180px"
            }}
          >
            <Action
              link="https://help.rhabitapp.com/knowledge-base/knowledge-base/my-score-is-low-what-now/"
              title="Improve a Habit"
              desc="Read how to improve low scores using these simple habit boosters"
              icon={<StyledIcon type="stock" />}
            />
            <Feature excludes={["DDI_CLIENT"]}>
              <Action
                action={onOpen}
                title={t("deep_dive.actions_and_info.book_a_coach")}
                desc={t("deep_dive.actions_and_info.book_a_coach_description")}
                icon={<StyledIcon type="schedule" />}
              />
            </Feature>
          </div>
        </>
      )}
      <div style={{ marginTop: desktop ? "18px" : "14px" }}>
        <span style={{ fontSize: theme.font.subheader }}>
          {t("deep_dive.actions_and_info.dont_know_swipes")}
        </span>
      </div>
      <SubAnalyticsContainer>
        <DontKnowPercent desktop={desktop}>
          {typeof habit.dont_know_percentage === "number"
            ? `${habit.dont_know_percentage}%`
            : ""}
        </DontKnowPercent>
      </SubAnalyticsContainer>
    </HomeCard>
  );
};

ActionsAndInfo.propTypes = {
  updateHabit: PropTypes.func.isRequired,
  profileStore: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  habit: PropTypes.object.isRequired,
  currentUserId: PropTypes.string.isRequired,
  isDashboardViewer: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired
};

export default withTheme(ActionsAndInfo);
