import React, { useState } from "react";
import useStores from "utils/useStores";
import UserAvatar from "components/UserAvatar";
import Box from "layout/Box";
import { Button } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import { useApolloClient } from "@apollo/client";
import { ActionWrapper } from "./styles";
import ActionItem from "./ActionItem";
import {
  IFetchManagerDashboardQuery,
  FetchManagerDashboardDocument
} from "./fetchManagerDashboard.generated";
import { useDiscardManagerActionItemMutation } from "./discardManagerActionItem.generated";
import { useHistory } from "react-router-dom";

interface Props {
  actions: IFetchManagerDashboardQuery["managerActionItems"];
  id: string;
  managedTeam: null | IFetchManagerDashboardQuery["team"];
}

type DateString = "Morning" | "Afternoon" | "Evening";

const getTimeString = (): DateString => {
  const hour = new Date().getHours();
  if (hour < 12) {
    return "Morning";
  }
  if (hour < 17) {
    return "Afternoon";
  }
  return "Evening";
};

const ManagerActions: React.FC<Props> = ({ actions, id, managedTeam }) => {
  const [componentActions, setComponentActions] = useState(actions);
  const history = useHistory();

  const client = useApolloClient();

  const handleDiscard = (newData) => {
    const data = client.readQuery({ query: FetchManagerDashboardDocument });
    client.writeQuery({
      query: FetchManagerDashboardDocument,
      data: {
        managerActionItems: data.managerActionItems.filter(
          (d) => d.id !== newData.discardManagerActionItem.id
        )
      }
    });
  };

  const [
    discardManagerActionItemMutation
  ] = useDiscardManagerActionItemMutation({
    onCompleted: handleDiscard
  });
  const {
    profileStore: { currentUser }
  } = useStores();

  const dismissAction = (actionId: string): void => {
    discardManagerActionItemMutation({
      variables: { input: { managerActionItemId: actionId } }
    });
    setComponentActions(componentActions.filter((a) => a.id !== actionId));
  };

  if (managedTeam) {
    return (
      <ActionWrapper id={id} layoutTransition>
        <Box display="flex" alignItems="center" mb="6px">
          <UserAvatar
            name={managedTeam.manager.name}
            avatar={managedTeam.manager.avatar}
            bordered
            size="large"
          />
          <Box flexDirection="column" ml={24}>
            <Box fontWeight={500} fontSize="36px" color="white">
              You are now viewing {managedTeam.manager.name}&apos;s team
            </Box>
            <Button onClick={(): void => history.push("/manager_dashboard")}>
              Return To Your Team{" "}
            </Button>
          </Box>
        </Box>
        <motion.div
          layoutTransition
          style={{ display: "flex", flexDirection: "column" }}
        ></motion.div>
      </ActionWrapper>
    );
  }

  return (
    <motion.div layoutTransition>
      <ActionWrapper id={id} layoutTransition>
        <Box display="flex" alignItems="center" mb="6px">
          <UserAvatar
            name={currentUser.name}
            avatar={currentUser.avatar}
            bordered
            size="large"
          />
          <Box flexDirection="column" ml={24}>
            <Box fontWeight={500} fontSize="36px" color="white">
              Good {getTimeString()} {currentUser.first_name}!
            </Box>
            <Box color="white" fontSize="18px">
              {componentActions.length > 0
                ? "Be a fearless leader today by taking care of the following quick tasks:"
                : "You don't have any action items currently. Check out your team's data below to understand how things are going."}
            </Box>
          </Box>
        </Box>
        <motion.div
          layoutTransition
          style={{ display: "flex", flexDirection: "column" }}
        >
          <AnimatePresence>
            {componentActions.map((action) => (
              <motion.div
                positionTransition
                exit={{ opacity: 0 }}
                key={action.id}
              >
                <ActionItem
                  key={action.id}
                  action={action}
                  dismissAction={dismissAction}
                />
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      </ActionWrapper>
    </motion.div>
  );
};

export default ManagerActions;
