import styled from "styled-components";
import { media } from "utils/style-utils";

export const Wrapper = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;

  ${media.handheld`
    width: 95vw;
  `}
`;

export const MyStats = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e8e8e8;
  padding: 6px 0;
`;

export const AvatarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const SendReceiveStats = styled.div`
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
`;

export const SendStat = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  text-align: center;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
`;

export const Value = styled.span`
  font-size: 18px;
  color: ${props => props.theme.primary_color};
  font-weight: 500;
`;
export const Label = styled.span`
  font-size: 12px;
`;

export const TopHighfivers = styled.div`
  display: flex;
  height: 240px;
  width: 100%;
  margin-top: 12px;
`;

export const TopReceivers = styled.div`
  width: 50%;
  height: 100%;
  border-right: 1px solid #e8e8e8;
  padding: 0 8px;
`;

export const TopSenders = styled.div`
  width: 50%;
  height: 100%;
  padding: 0 8px;
`;

export const ListItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled.div`
  font-size: 14px;
`;

export const Number = styled.div`
  font-size: 16px;
  color: ${props => props.theme.primary_color};
`;

export const TopLiked = styled.div`
  width: 100%;
  margin-top: 12px;
  border-top: 1px solid #e8e8e8;
`;