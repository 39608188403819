import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";
import Trend from "./Trend";
import { HomeCard } from "../styles";

const HabitTrend = ({ loading, habit, theme }) => {
  const headStyle = {
    padding: "0 24px",
    color: "rgba(0, 0, 0, 0.85)",
    fontWeight: 500,
    fontSize: theme.font.header
  };
  return (
    <HomeCard
      id="habitTrend"
      title="Habit Trend"
      style={{ minHeight: "432px" }}
      headStyle={headStyle}
    >
      <Trend loading={loading} habit={habit} />
    </HomeCard>
  );
};

HabitTrend.propTypes = {
  loading: PropTypes.bool.isRequired,
  habit: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withTheme(HabitTrend);
