/* eslint-disable no-debugger */
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import posed, { PoseGroup } from "react-pose";
import moment from "moment";
import { WorkItems as Api } from "services/api";
import useStores from "utils/useStores";
import { HomeCard } from "containers/HomePage/styles";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon, Badge, Typography, Button } from "antd";
import { useTranslation } from "react-i18next";
import ObjectiveReviewItem from "./ObjectiveReviewItem";
import ObjectiveAcceptItem from "./ObjectiveAcceptItem";
import AddtionalFeedbackItem from "./AdditionalFeedbackItem";
import RequestAddtionalFeedbackItem from "./RequestAdditionalFeedbackItem";
import GroupAddItem from "./GroupAddItem";
import RaterReciprocityItem from "./RaterReciprocityItem";
import NetworkHealthItem from "./NetworkHealthItem";
import SMSPromptItem from "./SMSPromptItem";
import MentorPreviewItem from "./MentorPreviewItem";
import PicturePromptItem from "./PicturePromptItem";

const Wrapper = posed.div({
  initial: {
    x: ({ direction }) => (direction === "right" ? 400 : -300),
    opacity: 0
  },
  enter: {
    x: 0,
    opacity: 1,
    transition: { type: "spring", stiffness: 40 }
  },
  exit: {
    transition: {
      duration: 100,
      ease: "linear"
    },
    x: ({ direction }) => (direction === "right" ? -300 : 400),
    opacity: 0
  }
});

const WorkItems = () => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [direction, setDirection] = useState("right");
  const [itemIndex, setItemIndex] = useState(0);
  const { profileStore } = useStores();

  const nextPage = () => {
    setDirection("right");
    if (items.length) {
      setItemIndex((itemIndex + 1) % items.length);
    }
  };
  const prevPage = () => {
    setDirection("left");
    if (items.length) {
      setItemIndex(
        itemIndex - 1 === -1 ? items.length - 1 : (itemIndex - 1) % items.length
      );
    }
  };

  const completeItem = (id) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setItems(items.filter((i) => i.id !== id));
      setItemIndex(itemIndex % (items.length - 1));
    });
  };

  const fetchWorkItems = async () => {
    const { data } = await Api.all();
    setItems(
      data.sort((a, b) =>
        a.name === "give_additional_feedback"
          ? -1
          : moment(a.created_at).valueOf() - moment(b.created_at).valueOf()
      )
    );
  };

  useEffect(() => {
    fetchWorkItems();
  }, []);

  let content;
  if (items.length) {
    switch (items[itemIndex].name) {
      case "give_additional_feedback":
        content = (
          <Wrapper style={{ height: "100%" }} key={items[itemIndex].id}>
            <span style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              {items[itemIndex] &&
                moment(items[itemIndex].created_at).format("MM/DD/YY")}
            </span>

            <AddtionalFeedbackItem
              markCompleted={() => {
                Api.discard(items[itemIndex].id);
                completeItem(items[itemIndex].id);
              }}
              itemId={items[itemIndex].id}
              feedback={items[itemIndex].data}
            />
          </Wrapper>
        );
        break;
      case "request_additional_feedback":
        content = (
          <Wrapper style={{ height: "100%" }} key={items[itemIndex].id}>
            <span style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              {items[itemIndex] &&
                moment(items[itemIndex].created_at).format("MM/DD/YY")}
            </span>
            <RequestAddtionalFeedbackItem
              markCompleted={() => {
                Api.discard(items[itemIndex].id);
                completeItem(items[itemIndex].id);
              }}
              itemId={items[itemIndex].id}
              data={items[itemIndex].data}
            />
          </Wrapper>
        );
        break;
      case "review_objective":
        content = (
          <Wrapper style={{ height: "100%" }} key={items[itemIndex].id}>
            <span style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              {items[itemIndex] &&
                moment(items[itemIndex].created_at).format("MM/DD/YY")}
            </span>
            <ObjectiveReviewItem
              itemId={items[itemIndex].id}
              markCompleted={() => {
                Api.discard(items[itemIndex].id);
                completeItem(items[itemIndex].id);
              }}
              objective={items[itemIndex].data}
            />
          </Wrapper>
        );
        break;
      case "added_to_group":
        content = (
          <Wrapper style={{ height: "100%" }} key={items[itemIndex].id}>
            <span style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              {items[itemIndex] &&
                moment(items[itemIndex].created_at).format("MM/DD/YY")}
            </span>

            <GroupAddItem
              itemId={items[itemIndex].id}
              markCompleted={() => {
                Api.discard(items[itemIndex].id);
                completeItem(items[itemIndex].id);
              }}
              group={items[itemIndex].data}
            />
          </Wrapper>
        );
        break;
      case "accept_objective":
        content = (
          <Wrapper style={{ height: "100%" }} key={items[itemIndex].id}>
            <span style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              {items[itemIndex] &&
                moment(items[itemIndex].created_at).format("MM/DD/YY")}
            </span>

            <ObjectiveAcceptItem
              itemId={items[itemIndex].id}
              key={items[itemIndex].name}
              markCompleted={() => {
                Api.discard(items[itemIndex].id);
                completeItem(items[itemIndex].id);
              }}
              objective={items[itemIndex].data}
            />
          </Wrapper>
        );
        break;
      case "rater_reciprocity":
        content = (
          <Wrapper style={{ height: "100%" }} key={items[itemIndex].id}>
            <span style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              {items[itemIndex] &&
                moment(items[itemIndex].created_at).format("MM/DD/YY")}
            </span>

            <RaterReciprocityItem
              itemId={items[itemIndex].id}
              key={items[itemIndex].name}
              markCompleted={() => {
                Api.discard(items[itemIndex].id);
                completeItem(items[itemIndex].id);
              }}
              provider={items[itemIndex].data}
            />
          </Wrapper>
        );
        break;
      case "anti_reciprocity":
        content = (
          <Wrapper style={{ height: "100%" }} key={items[itemIndex].id}>
            <span style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              {items[itemIndex] &&
                moment(items[itemIndex].created_at).format("MM/DD/YY")}
            </span>

            <RaterReciprocityItem
              itemId={items[itemIndex].id}
              key={items[itemIndex].name}
              anti
              markCompleted={() => {
                Api.discard(items[itemIndex].id);
                completeItem(items[itemIndex].id);
              }}
              provider={items[itemIndex].data}
            />
          </Wrapper>
        );
        break;
      case "network_health":
        content = (
          <Wrapper style={{ height: "100%" }} key={items[itemIndex].id}>
            <span style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              {items[itemIndex] &&
                moment(items[itemIndex].created_at).format("MM/DD/YY")}
            </span>

            <NetworkHealthItem
              itemId={items[itemIndex].id}
              key={items[itemIndex].name}
              markCompleted={() => {
                Api.discard(items[itemIndex].id);
                completeItem(items[itemIndex].id);
              }}
            />
          </Wrapper>
        );
        break;
      case "sms_prompt":
        content = (
          <Wrapper style={{ height: "100%" }} key={items[itemIndex].id}>
            <span style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              {items[itemIndex] &&
                moment(items[itemIndex].created_at).format("MM/DD/YY")}
            </span>

            <SMSPromptItem
              itemId={items[itemIndex].id}
              key={items[itemIndex].name}
              markCompleted={() => {
                Api.discard(items[itemIndex].id);
                completeItem(items[itemIndex].id);
              }}
            />
          </Wrapper>
        );
        break;
      case "mentor_preview":
        content = (
          <Wrapper style={{ height: "100%" }} key={items[itemIndex].id}>
            <span style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              {items[itemIndex] &&
                moment(items[itemIndex].created_at).format("MM/DD/YY")}
            </span>

            <MentorPreviewItem
              itemId={items[itemIndex].id}
              key={items[itemIndex].name}
              markCompleted={() => {
                Api.discard(items[itemIndex].id);
                completeItem(items[itemIndex].id);
              }}
            />
          </Wrapper>
        );
        break;
      case "picture_prompt":
        content = (
          <Wrapper
            style={{ height: "100%", flexDirection: "column", display: "flex" }}
            key={items[itemIndex].id}
          >
            <span style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              {items[itemIndex] &&
                moment(items[itemIndex].created_at).format("MM/DD/YY")}
            </span>

            <PicturePromptItem
              itemId={items[itemIndex].id}
              key={items[itemIndex].id}
              markCompleted={() => {
                Api.discard(items[itemIndex].id);
                completeItem(items[itemIndex].id);
              }}
            />
          </Wrapper>
        );
        break;
      case "streak_penalty":
        content = (
          <Wrapper style={{ height: "100%" }} key={items[itemIndex].id}>
            <span style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              {items[itemIndex] &&
                moment(items[itemIndex].created_at).format("MM/DD/YY")}
            </span>

            <h4>{t("homepage.action_items.streak_penalty.prompt_text")} </h4>
            <p>{t("homepage.action_items.streak_penalty.prompt_subtext")}</p>
            <Button
              type="primary"
              onClick={() => {
                Api.discard(items[itemIndex].id);
                completeItem(items[itemIndex].id);
              }}
            >
              {t("homepage.action_items.streak_penalty.dismiss_text")}
            </Button>
          </Wrapper>
        );
        break;
      default:
        content = <div key={-1}>Something went wrong</div>;
    }
  } else {
    content = (
      <Wrapper style={{ height: "100%" }} key={-1}>
        <Typography.Title level={3} style={{ textAlign: "center" }}>
          {t("homepage.action_items.all_caught_up")}
          <span style={{ fontSize: 30 }} role="img" aria-label="celebration">
            🎉
          </span>
        </Typography.Title>
        <Typography style={{ textAlign: "center" }}>
          {t("homepage.action_items.all_caught_up_sub_text")}
        </Typography>
      </Wrapper>
    );
  }
  return (
    <HomeCard
      headStyle={{ height: 64, ".ant-card-head-wrapper": { height: "100%" } }}
      bodyStyle={{
        height: "calc(100% - 64px)",
        overflowX: "hidden",
        padding: "12px 42px 24px 42px"
      }}
      style={{
        height: window.innerWidth < 500 ? "unset" : "calc(100% - 2px)",
        overflowX: "hidden",
        // minHeight: 300,
        position: "relative"
      }}
      title={
        <span>
          {t("homepage.action_items.title")} <Badge count={items.length} />
        </span>
      }
    >
      {items.length > 0 && (
        <>
          <Icon
            onClick={prevPage}
            type="left"
            style={{
              cursor: "pointer",
              fontSize: 20,
              position: "absolute",
              left: 10,
              top: "50%"
            }}
          />
          <Icon
            onClick={nextPage}
            type="right"
            style={{
              cursor: "pointer",
              fontSize: 20,
              position: "absolute",
              right: 10,
              top: "50%"
            }}
          />
        </>
      )}
      <PoseGroup direction={direction} preEnterPose="initial">
        {content}
      </PoseGroup>
    </HomeCard>
  );
};

export default WorkItems;
