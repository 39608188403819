// @flow

import React from "react";
import styled, { withTheme } from "styled-components";
import moment from "moment";

import { UserLite } from "types/shared";
import UserAvatar from "components/UserAvatar";
import { Divider, Tag } from "antd";

const Container = styled.div`
  margin: 12px 0;
  display: flex;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
`;

interface IOwnProps {
  user: UserLite;
  createdAt: string;
  isNew: boolean;
  text: string | null;
  isLast: boolean;
  theme: Object;
}

const ResponseItem = ({
  user,
  createdAt,
  text,
  isLast,
  isNew,
  theme
}: IOwnProps) => {
  return (
    <>
      <Container>
        <UserAvatar avatar={user.avatar} name={user.name} />
        <Content>
          <div style={{ display: "flex" }}>
            <div style={{ fontWeight: 500 }}>{user.name}</div>
            <div style={{ color: "rgba(0, 0, 0, 0.5)", marginLeft: 6 }}>
              {moment(createdAt).format("MM/DD/YYYY")}
            </div>
            {isNew && (
              <Tag style={{ marginLeft: 6 }} color={theme.primary_color}>
                NEW
              </Tag>
            )}
          </div>
          <div>{text}</div>
        </Content>
      </Container>
      {!isLast && <Divider />}
    </>
  );
};

export default withTheme(ResponseItem);
