/* eslint-disable */
import * as Types from '../../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type IFetchCompletedLessonsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IFetchCompletedLessonsQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'User' }
    & { completedArticulateLessons: Array<(
      { __typename?: 'ArticulateLessonCompletion' }
      & Pick<Types.IArticulateLessonCompletion, 'courseId'>
    )> }
  ) }
);


export const FetchCompletedLessonsDocument = gql`
    query FetchCompletedLessons {
  profile {
    completedArticulateLessons {
      courseId
    }
  }
}
    `;

/**
 * __useFetchCompletedLessonsQuery__
 *
 * To run a query within a React component, call `useFetchCompletedLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompletedLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompletedLessonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCompletedLessonsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IFetchCompletedLessonsQuery, IFetchCompletedLessonsQueryVariables>) {
        return ApolloReactHooks.useQuery<IFetchCompletedLessonsQuery, IFetchCompletedLessonsQueryVariables>(FetchCompletedLessonsDocument, baseOptions);
      }
export function useFetchCompletedLessonsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IFetchCompletedLessonsQuery, IFetchCompletedLessonsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IFetchCompletedLessonsQuery, IFetchCompletedLessonsQueryVariables>(FetchCompletedLessonsDocument, baseOptions);
        }
export type FetchCompletedLessonsQueryHookResult = ReturnType<typeof useFetchCompletedLessonsQuery>;
export type FetchCompletedLessonsLazyQueryHookResult = ReturnType<typeof useFetchCompletedLessonsLazyQuery>;
export type FetchCompletedLessonsQueryResult = ApolloReactCommon.QueryResult<IFetchCompletedLessonsQuery, IFetchCompletedLessonsQueryVariables>;