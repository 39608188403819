import styled from "styled-components";
import { media } from "utils/style-utils";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  ${media.handheld`
    flex-direction: column;
    height: unset;
  `}
`;

export const Side = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${media.handheld`
    width: 100%;
    height: unset;
  `}
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Name = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: auto;
  cursor: pointer;
`;