/* eslint-disable */
import * as Types from '../../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type IUpdateUserHabitsMutationVariables = Types.Exact<{
  input: Types.IUpdateUserHabitsInput;
}>;


export type IUpdateUserHabitsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.IMutation, 'updateUserHabits'>
);


export const UpdateUserHabitsDocument = gql`
    mutation UpdateUserHabits($input: UpdateUserHabitsInput!) {
  updateUserHabits(input: $input)
}
    `;
export type IUpdateUserHabitsMutationFn = ApolloReactCommon.MutationFunction<IUpdateUserHabitsMutation, IUpdateUserHabitsMutationVariables>;

/**
 * __useUpdateUserHabitsMutation__
 *
 * To run a mutation, you first call `useUpdateUserHabitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserHabitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserHabitsMutation, { data, loading, error }] = useUpdateUserHabitsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserHabitsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateUserHabitsMutation, IUpdateUserHabitsMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateUserHabitsMutation, IUpdateUserHabitsMutationVariables>(UpdateUserHabitsDocument, baseOptions);
      }
export type UpdateUserHabitsMutationHookResult = ReturnType<typeof useUpdateUserHabitsMutation>;
export type UpdateUserHabitsMutationResult = ApolloReactCommon.MutationResult<IUpdateUserHabitsMutation>;
export type UpdateUserHabitsMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateUserHabitsMutation, IUpdateUserHabitsMutationVariables>;