/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import { Icon } from "antd";

export const StyledIcon = styled(Icon)`
  font-size: 35px;
  margin-right: 7px;
  color: #00a74f;
`;

export const SubAnalytics = styled.div`
  // text-align: center;
  line-height: 1.27
  font-size: ${({
  theme
}) => `${theme.font.subheader}px`};
  width: 33%;
`;

export const DontKnowPercent = styled.div`
  // text-align: center;
  line-height: ${props => props.desktop ? '1.27' : '0'};
  margin-top: ${props => !props.desktop && '10px'};
  font-size: ${({
  theme
}) => `${theme.font.pointOfInterest}px`};
  width: 33%;
`;

export const SubAnalyticsContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  // justify-content: space-between;
  align-items: center;
`;