import React from "react";
import { Avatar, Tooltip } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";
import nameToInitials from "../../utils/nameToInitials";

const StyledAvatar = styled(Avatar)`
  img {
    border-radius: 50%;
  }
`;

const UserAvatar = ({
  avatar,
  name,
  style,
  bordered,
  size,
  fontSize,
  lineHeight
}) => (
  <Tooltip title={name}>
    <StyledAvatar
      size={size}
      style={{
        "-webkit-transform": "translate3d(0,0,0)",
        borderRadius: "50%",
        minHeight: size === "large" ? 64 : 32,
        minWidth: size === "large" ? 64 : 32,
        border: bordered && "1px solid white",
        ...style
      }}
      src={avatar}
    >
      <span style={{ fontSize, lineHeight: `${lineHeight}px` }}>
        {nameToInitials(name)}
      </span>
    </StyledAvatar>
  </Tooltip>
);

UserAvatar.defaultProps = {
  avatar: null,
  style: {},
  bordered: false,
  size: "default",
  fontSize: null,
  lineHeight: null
};

UserAvatar.propTypes = {
  size: PropTypes.string,
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  bordered: PropTypes.bool,
  fontSize: PropTypes.number,
  lineHeight: PropTypes.number
};

export default UserAvatar;
