import React from "react";
import { Modal, Table, Form, Input, Icon, Button, Select } from "antd";
import { inject } from "mobx-react";
import MobileTable from "./MobileTable";

const { Option } = Select;
const ViewerList = inject("profileStore")(
  ({
    copyToClipboard,
    handleDelete,
    visible,
    loading,
    onCancel,
    viewers,
    createViewer,
    columns,
    form: { getFieldDecorator, getFieldsValue, validateFields },
    form
  }) => {
    const mobile = window.innerWidth < 550;
    let table = (
      <Table
        pagination={{ pageSize: 5 }}
        columns={columns}
        dataSource={viewers}
      />
    );

    if (mobile) {
      table = (
        <MobileTable
          copyToClipboard={copyToClipboard}
          handleDelete={handleDelete}
          columns={columns}
          dataSource={viewers}
        />
      );
    }

    const handleSubmit = () => {
      validateFields((err) => {
        if (!err) {
          createViewer(getFieldsValue(), form);
        }
      });
    };

    const handleUniqueEmail = (rule, value, callback) => {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value) {
        viewers.forEach((v) => {
          if (v.email === value) {
            callback([new Error("This email already exists.")]);
          }
        });
      }
      if (value && !re.test(value)) {
        callback([new Error("Invalid email.")]);
      } else {
        callback();
      }
    };
    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        footer={null}
        title="Share Your Rhabit Habits!"
        width={mobile ? "98%" : "750px"}
        centered={mobile}
        style={{ paddingTop: 0 }}
        bodyStyle={{ paddingTop: 0 }}
      >
        <p style={{ padding: "15px 0 6px 0", fontSize: 16 }}>
          Want to share how you&apos;re doing with a colleague, business coach,
          or someone outside of your organization (Hi, mom!)?
        </p>
        <p style={{ padding: "0px 0 15px 0", fontSize: 16 }}>
          Fill out the information below to create a temporary view of your
          habit dashboard. Just copy the link, and get sharing!
        </p>
        <Form
          layout="inline"
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: mobile && "column",
            padding: "0 0 30px 0"
          }}
        >
          <Form.Item label="Email">
            {getFieldDecorator("email", {
              rules: [
                { required: true, message: "Email is required." },
                { validator: handleUniqueEmail }
              ]
            })(
              <Input
                placeholder="Email"
                style={{
                  width: !mobile && "200px",
                  marginBottom: mobile && "20px"
                }}
              />
            )}
          </Form.Item>
          <Form.Item label="Expires In">
            {getFieldDecorator("expiration", {
              initialValue: 604800,
              rules: [{ required: true, message: "Invalid email" }]
            })(
              <Select
                style={{
                  width: !mobile && "150px",
                  marginBottom: mobile && "20px"
                }}
              >
                <Option value={86400}>1 Day</Option>
                <Option value={604800}>1 Week</Option>
                <Option value={2592000}>1 Month</Option>
                <Option value={31536000}>1 Year</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item style={{ marginRight: "0px" }}>
            <Button
              style={{}}
              onClick={handleSubmit}
              loading={loading}
              type="primary"
            >
              <Icon style={{ marginLeft: "0px" }} type="user-add" />
              <span>Add</span>
            </Button>
          </Form.Item>
        </Form>
        {table}
      </Modal>
    );
  }
);

export default Form.create()(ViewerList);
