import React from "react";
import PropTypes from "prop-types";
import { Modal, Menu, Icon } from "antd";
import { SuperAdmin } from "services/api";
import { useTranslation } from "react-i18next";

const api = new SuperAdmin();

function confirmLogout(onOk) {
  Modal.confirm({
    title: "Are you sure you want to log out?",
    onOk() {
      onOk();
    },
    onCancel() {}
  });
}

/* eslint no-shadow: ["error", { "allow": ["api"] }] */

/* eslint-env es6 */

export const menu = (
  api,
  logout,
  openSettings,
  editRecentFeedback,
  editPersonalInfo,
  features,
  startTutorial,
  openFeedback,
  t,
  openVacation
) => {
  return (
    <Menu>
      {features.includes("DEMO_ACCOUNT") && (
        <Menu.Item onClick={() => api.resetDemo()}>
          <Icon style={{ marginRight: 10 }} type="rocket" />
          {t("header_dropdown.reset_demo")}
        </Menu.Item>
      )}
      <Menu.Item onClick={editRecentFeedback}>
        <Icon style={{ marginRight: 10 }} type="edit" />
        {t("header_dropdown.edit_recent_feedback")}
      </Menu.Item>
      {/* <Menu.Item onClick={editPersonalInfo}>
     <Icon style={{ marginRight: 10}} type="user"/>
       Edit Personal Info
    </Menu.Item> */}
      <Menu.Item onClick={openSettings} id="account-settings-item">
        <Icon style={{ marginRight: 10 }} type="setting" />
        <span>{t("header_dropdown.account_settings")}</span>
      </Menu.Item>
      <Menu.Item onClick={startTutorial}>
        <Icon style={{ marginRight: 10 }} type="rocket" />
        {t("header_dropdown.tutorial")}
      </Menu.Item>
      <Menu.Item onClick={openVacation}>
        <Icon style={{ marginRight: 10 }} type="picture" />
        {t("header_dropdown.schedule_vacation")}
      </Menu.Item>
      <Menu.Item onClick={openFeedback}>
        <Icon style={{ marginRight: 10 }} type="mail" />
        {t("header_dropdown.give_rhabit_feedback")}
      </Menu.Item>
      <Menu.Item onClick={() => confirmLogout(logout)}>
        <Icon style={{ marginRight: 10 }} type="logout" />
        {t("header_dropdown.logout")}
      </Menu.Item>
    </Menu>
  );
};

export class CustomMenu extends React.Component {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    openSettings: PropTypes.func.isRequired,
    editRecentFeedback: PropTypes.func.isRequired,
    runTutorial: PropTypes.func.isRequired,
    openFeedback: PropTypes.func.isRequired,
    features: PropTypes.array.isRequired,
    logout: PropTypes.func.isRequired
  };

  myClickOutsideHandler() {
    const { handleClose } = this.props;
    handleClose();
  }

  render() {
    const {
      logout,
      openSettings,
      editRecentFeedback,
      features,
      handleClose,
      openFeedback,
      runTutorial
    } = this.props;
    return (
      <Menu style={{ borderRadius: 4 }}>
        {features.includes("DEMO_ACCOUNT") && (
          <Menu.Item
            onClick={() => {
              api.resetDemo();
              handleClose();
            }}
          >
            <Icon style={{ marginRight: 10 }} type="edit" />
            Reset Demo
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            editRecentFeedback();
            handleClose();
          }}
        >
          <Icon style={{ marginRight: 10 }} type="edit" />
          Edit Recent Feedback
        </Menu.Item>
        {/* <Menu.Item onClick={() => {editPersonalInfo(); handleClose()}}>
        <Icon style={{ marginRight: 10}} type="user"/>
         Edit Personal Info
        </Menu.Item> */}
        <Menu.Item
          onClick={() => {
            openSettings();
            handleClose();
          }}
        >
          <Icon style={{ marginRight: 10 }} type="setting" />
          Account Settings
        </Menu.Item>
        <Menu.Item onClick={runTutorial}>
          <Icon style={{ marginRight: 10 }} type="rocket" />
          Tutorial
        </Menu.Item>
        <Menu.Item onClick={openFeedback}>
          <Icon style={{ marginRight: 10 }} type="mail" />
          Give Rhabit Feedback
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            confirmLogout(logout);
            handleClose();
          }}
        >
          <Icon style={{ marginRight: 10 }} type="logout" />
          Log Out
        </Menu.Item>
      </Menu>
    );
  }
}
