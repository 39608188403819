/* eslint-disable */
import React from "react";
import { Button, Select, Icon, Avatar, Typography } from "antd";
import { Wrapper, UserInfo, Left, Right, Header, Description } from "./styles";
import nameToInitials from "../../../utils/nameToInitials";

const { Option } = Select;

const ReviewControls = ({
  user,
  onSave,
  onSelect,
  buttonDisabled,
  buttonLoading,
  reviewsLength,
  currentReview
}) => {
  return (
    <Wrapper>
      {/* <Select
       allowClear
       onChange={onSelect}
       placeholder="View Previous Review"
       style={{ width: 200 }}
      >
       <Option value={1}>Last Year</Option>
      </Select> */}
      <UserInfo>
        <Left>
          <Avatar size="large" src={user.avatar}>
            {nameToInitials(user.name)}
          </Avatar>
        </Left>
        <Right>
          <Typography.Title style={{ marginBottom: 0 }} level={4}>
            {user.name}
            {currentReview.rank &&
              ` - Rank ${currentReview.rank}/${reviewsLength}`}
          </Typography.Title>

          <Description>{user.title}</Description>
        </Right>
      </UserInfo>
      <Button
        type="primary"
        onClick={onSave}
        disabled={buttonDisabled}
        loading={buttonLoading}
      >
        <Icon type="cloud-upload" />
        Save Review
      </Button>
    </Wrapper>
  );
};

export default ReviewControls;
