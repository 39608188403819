/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import { Slider } from "antd";

export const Label = styled.span`
  position: absolute;
  font-size: 12px;
  width: 85px;
  text-align: center;
  transform: translateX(-50%);
`;

export const StyledSlider = styled(Slider)`
  &&& .ant-slider-track {
    background-color: #cccccc;
  }
  &&& .ant-slider-handle {
    background-color: #e8e8e8;
    border: solid 2px #cccccc;
  }

  &&& .ant-slider-dot-active {
    background-color: #e8e8e8;
    border: solid 2px #cccccc;
  }

  &:hover {
    &&& .ant-slider-rail {
      background-color: #efefef;
    }
    &&& .ant-slider-handle {
      background-color: #e8e8e8;
      border: solid 2px #cccccc;
    }
  }
  &&& .ant-slider-handle {
    &:focus {
      background-color: #e8e8e8;
      border: solid 2px #cccccc;
      box-shadow: 0 0 0 5px rgba(239, 239, 239, 0.5);
    }
  }
`;

export const ChartTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: ${({
  theme
}) => theme.font.subheader}px;
  top: -10px;
`;