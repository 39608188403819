/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer, inject } from "mobx-react";
import { observable } from "mobx";
import PropTypes from "prop-types";
import { Modal, List, Avatar, Popover, Button } from "antd";
import { Profile } from "services/api";
import nameToInitials from "utils/nameToInitials";
import { withTranslation } from "react-i18next";

const getColor = (response) => {
  if (response === "no") {
    return "red";
  }
  if (response === "yes") {
    return "#40cc75";
  }

  return "grey";
};

const getDescription = (item) => {
  let { description } = item;
  if (item.type === "Goal") {
    description = `Goal Confidence Check-In: ${item.subject.name}`;
  } else if (item.type === "ObjectiveQuestion") {
    description = `How confident are you that you will complete this ${item.subject.name} on time?`;
  }
  return description;
};
const getAvatar = (item) => {
  let avatar;
  if (item.type === "ObjectiveQuestion") {
    avatar = (
      <Avatar icon="flag" style={{ color: "#white", background: "#00a74a" }} />
    );
  } else if (item.type === "Goal") {
    avatar = null;
  } else {
    avatar = (
      <Avatar src={item.subject.avatar}>
        {nameToInitials(item.subject.name)}
      </Avatar>
    );
  }
  return avatar;
};

@inject("profileStore")
@observer
class EditFeedback extends React.Component {
  @observable editableFeedback = [];
  @observable popId = null;
  componentWillReceiveProps(nextProps) {
    const { visible } = this.props;
    if (!visible && nextProps.visible) {
      Profile.fetchEditableFeedback().then(
        ({ data }) => (this.editableFeedback = data)
      );
    }
  }

  getResponse = (item, t) => {
    // if (typeof item.response === 'string') {
    //   return item.response;
    // }
    /* eslint-disable react/prop-types */
    const {
      profileStore: {
        currentUser: {
          organization: { features }
        }
      }
    } = this.props;
    /* eslint-enable react/prop-types */

    let responseMapping = {
      no: t("responses.no"),
      yes: t("responses.yes"),
      "don't know": t("responses.dont_know")
    };

    if (features.includes("FOUR_OPTIONS") && item.type === "Feedback") {
      responseMapping = {
        no: t("responses.rarely"),
        sometimes: t("responses.sometimes"),
        yes: t("responses.often"),
        "don't know": t("responses.dont_know")
      };
    } else if (
      features.includes("FOUR_OPTIONS") &&
      item.type === "Engagement"
    ) {
      responseMapping = {
        no: t("responses.no"),
        sometimes: t("responses.somewhat"),
        yes: t("responses.yes"),
        "don't know": t("responses.dont_know")
      };
    } else if (
      item.type === "Feedback" ||
      item.type === "Engagement" ||
      item.type === "Goal"
    ) {
      responseMapping = {
        no: t("responses.no"),
        yes: t("responses.yes"),
        "don't know": t("responses.dont_know")
      };
    } else if (item.type === "ObjectiveQuestion") {
      responseMapping = {
        no: t("responses.not_confident"),
        "don't know": t("responses.somewhat_confident"),
        yes: t("responses.very_confident")
      };
    }
    return responseMapping[item.response];
  };

  buttons = (item, t) => {
    /* eslint-disable react/prop-types */
    const {
      profileStore: {
        currentUser: {
          organization: { features }
        }
      }
    } = this.props;
    /* eslint-enable react/prop-types */
    let leftText;
    let downText;
    let rightText;
    let upText;
    if (features.includes("FOUR_OPTIONS") && item.type === "Feedback") {
      leftText = t("responses.rarely");
      downText = t("responses.sometimes");
      rightText = t("responses.often");
      upText = t("responses.dont_know");
    } else if (
      features.includes("FOUR_OPTIONS") &&
      item.type === "Engagement"
    ) {
      leftText = t("responses.no");
      downText = t("responses.somewhat");
      rightText = t("responses.yes");
      upText = t("responses.dont_know");
    } else if (item.type === "ObjectiveQuestion") {
      leftText = t("responses.not_confident");
      downText = t("responses.somewhat_confident");
      rightText = t("responses.very_confident");
    } else if (item.type === "Feedback" || item.type === "Engagement") {
      leftText = t("responses.no");
      downText = t("responses.dont_know");
      rightText = t("responses.yes");
    }
    return (
      <div>
        <Button
          onClick={() => this.updateResponse(item, 0)}
          type="primary"
          style={{ margin: 16, backgroundColor: "red", border: "none" }}
        >
          {leftText}
        </Button>
        <Button onClick={() => this.updateResponse(item, upText ? 0.5 : 2)}>
          {downText}
        </Button>
        <Button
          onClick={() => this.updateResponse(item, 1)}
          type="primary"
          style={{ margin: 16, backgroundColor: "#40cc75" }}
        >
          {rightText}
        </Button>
        {upText && (
          <Button
            onClick={() => this.updateResponse(item, 2)}
            style={{ margin: 16 }}
          >
            {upText}
          </Button>
        )}
      </div>
    );
  };

  updateResponse = (item, response) => {
    /* eslint-disable react/prop-types */
    const {
      profileStore: {
        currentUser: {
          organization: { features }
        }
      }
    } = this.props;
    /* eslint-enable react/prop-types */
    const feedback = this.editableFeedback.find((f) => f.id === item.id);
    if (response === 0) {
      feedback.response = "no";
    } else if (response === 1) {
      feedback.response = "yes";
    } else if (
      (item.type === "Feedback" || item.type === "Engagement") &&
      features.includes("FOUR_OPTIONS")
    ) {
      if (response === 2) {
        feedback.response = "don't know";
      } else if (response === 0.5) {
        feedback.response = "sometimes";
      }
    } else {
      feedback.response = item.type === "Goal" ? "somewhat" : "don't know";
    }
    Profile.answerQuestion(item.id, response);
    this.popId = null;
    this.forceUpdate();
  };

  render() {
    const { visible, onCancel, t } = this.props;
    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button key="submit" type="primary" onClick={onCancel}>
            {t("close")}
          </Button>
        ]}
        title={t("edit_feedback.title")}
        bodyStyle={{ maxHeight: 500, overflowY: "auto" }}
      >
        <List
          itemLayout="horizontal"
          dataSource={this.editableFeedback.filter(
            (f) =>
              f.type !== "ObjectiveCompletionQuestion" &&
              f.type !== "AdditionalFeedback"
          )}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Popover
                  placement="left"
                  title={t("edit_feedback.change_response_to")}
                  content={this.buttons(item, t)}
                >
                  <a
                    onClick={() => {
                      this.popId = item.id;
                      this.forceUpdate();
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => e.code === "Enter" && {}}
                  >
                    {t("edit_feedback.edit")}
                  </a>
                </Popover>
              ]}
            >
              <List.Item.Meta
                style={{ maxWidth: "100%" }}
                avatar={getAvatar(item)}
                title={getDescription(item)}
                description={
                  <span>
                    {t("edit_feedback.you_answered")}{" "}
                    <span
                      style={{
                        color: getColor(item.response, t)
                      }}
                    >
                      {this.getResponse(item, t)}
                    </span>
                  </span>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    );
  }
}

EditFeedback.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default withTranslation()(EditFeedback);
