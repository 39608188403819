import styled from "styled-components";
import media from "utils/style-utils";

export const Pane = styled.div`
  height: 100vh;
  position: fixed;
  right: ${props => (props.open ? 0 : "-500px")};
  top: 0;
  width: 500px;
  background: white;
  z-index: 9999999;
  transition: 0.2s ease-in-out;
  display: flex;
  line-height: 1.5;
  padding: 24px 0px 0px 0px
	flex-direction: column;
	
	${media.handheld`
		width: 100vw;
  	right: ${props => (props.open ? 0 : "-100vw")};

	`}
`;

export const Mask = styled.div`
  z-index: 9999998;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: ${props => (props.open ? "0.45" : "0")};
  pointer-events: ${props => (props.open ? "auto" : "none")};
  background: black;
  transition: 0.2s;
`;

export const PostList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100vh - 48px;);
  padding: 24px;
`;
