import React, {useState} from 'react'
import { useTranslation } from 'react-i18next';
import {ItemWrapper, ButtonWrapper, Content, Flair} from "../styles"
import { Button } from "antd";
import { ReactComponent as ProfilePic } from "assets/dashboard/add_profile_pic.svg";
import AccountSettings from 'components/AccountSettings';
import useStores from 'utils/useStores';

type Props = {
    markCompleted: () => void;
    itemId: string;
  };

const PicturePromptItem = ({markCompleted}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const { profileStore } = useStores()
  const { currentUser, features } = profileStore

    return (
        <ItemWrapper>
             <AccountSettings
             //@ts-ignore
            logout={profileStore.logout}
            consent={currentUser.consent}
            imageUrl={currentUser.avatar}
            mailTime={currentUser.mail_time}
            feedbackDelivery={currentUser.preferences.feedback_delivery}
            phoneNumber={currentUser.phone_number}
            currentUserId={currentUser.id}
            editingUser={Object.assign({}, currentUser, {
              name: `${currentUser.first_name} ${currentUser.last_name}`
            })}
            linkedInCode={profileStore.linkedInCode}
            linkedInRedirectUri={currentUser.linked_in_redirect_uri}
            updateUser={profileStore.updateUser}
            updateAvatar={profileStore.updateAvatar}
            removeRedirect={profileStore.removeRedirect}
            visible={modalOpen}
            onCancel={() => {
              setModalOpen(false)
            }}
            features={features()}
            userName={`${currentUser.first_name} ${currentUser.last_name}`}
          />
        <Content>
        <h4>{t("homepage.action_items.picture_prompt.prompt_text")}</h4>
        <p>{t("homepage.action_items.picture_prompt.prompt_subtext")}</p>
        <ButtonWrapper>
        <Button
            style={{ marginRight: 24 }}
            onClick={() => {
            markCompleted();
            }}
        >
            {t("homepage.action_items.picture_prompt.dismiss_text")}
        </Button>
        <Button
            type="primary"
            onClick={() => (setModalOpen(true))}
        >
            {t("homepage.action_items.picture_prompt.action_text")}
        </Button>
        </ButtonWrapper>
        </Content>
        <Flair>
            <ProfilePic />
        </Flair>
        </ItemWrapper>
    );
};

export default PicturePromptItem
