/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { withTheme } from "styled-components";

import { motion } from "framer-motion";
import { Progress } from "antd";
import Confetti from "react-dom-confetti";
import * as Fireworks from "fireworks-canvas"; // mjs

import styled from "styled-components";

const AnimatedCircle = ({ shouldAnimate, score, name, theme }) => {
  const [currentPercent, setPercent] = useState(0);
  const [pop, setPop] = useState(false);
  const timer = useRef(false); //now you can pass timer to another component

  const animate = (percent, start = 0) => {
    if (percent !== null) {
      setTimeout(() => {
        const increment = 2000 / Math.abs(percent - start);
        const animationStart = start;

        const change = percent - start;

        let currentTime = 0;

        const animateScroll = () => {
          currentTime += increment;
          const val = Math.easeInOutQuad(
            currentTime,
            animationStart,
            change,
            2000
          );

          setPercent(val);
          if (currentTime < 2000) {
            timer.current = setTimeout(animateScroll, increment);
          } else {
            timer.current = setTimeout(() => {
              if (score > 70) {
                const area = document.createElement("DIV");
                area.setAttribute("id", "fireworks");
                area.style =
                  "position: fixed;top: 0;left: 0;right: 0;bottom: 0; z-index: 1001; pointer-events: none";
                document.body.appendChild(area);
                const container = document.getElementById("fireworks");
                const options = {
                  maxRockets: 5, // max # of rockets to spawn
                  rocketSpawnInterval: 125, // millisends to check if new rockets should spawn
                  numParticles: 100, // number of particles to spawn when rocket explodes (+0-10)
                  explosionMinHeight: 0.6, // percentage. min height at which rockets can explode
                  explosionMaxHeight: 0.9, // percentage. max height before a particle is exploded
                  explosionChance: 0.08 // chance in each tick the rocket will explode
                };
                // instantiate the class and call start
                // this returns a disposable - calling it will stop fireworks.
                const fireworks = new Fireworks(container, options);
                const stop = fireworks.start();
              } else if (score > 60) {
                setPop(true);
              }
            }, 500);
          }
        };
        animateScroll();
      }, 250);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, [timer]);

  useEffect(() => {
    if (shouldAnimate) {
      animate(score);
    }
    return () => {
      const container = document.getElementById("fireworks");
      if (container) {
        container.parentElement.removeChild(container);
      }
    };
  }, [shouldAnimate, score]);
  const size = "large";
  return (
    <Wrapper name={Boolean(name)} report={false} size={size}>
      <Confetti
        active={pop}
        style={{ zIndex: 102 }}
        config={{
          angle: 90,
          spread: 100,
          startVelocity: 40,
          elementCount: 300,
          decay: 0.95
        }}
      />
      <Progress
        strokeWidth={10}
        width={size === "small" ? 50 : 120}
        strokeColor={theme.primary_color}
        type="circle" // eslint-disable-next-line react/destructuring-assignment
        percent={currentPercent}
        format={() => (
          <motion.div
            style={{ position: "relative" }}
            animate={shouldAnimate ? "visible" : "hidden"}
            variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}
          >
            <Percent size={size}>{Math.round(currentPercent)}%</Percent>
          </motion.div>
        )}
      />

      <Name size={size}>{name}</Name>
    </Wrapper>
  );
};

const Wrapper = styled(({ name, size, report, ...props }) => (
  <div {...props} />
))`
  display: flex;
  flex-direction: ${(props) => props.name && "column"};
  align-items: center;
  position: relative;
  width: ${(props) =>
    props.name && (props.size === "small" ? "100px" : "150px")};
  margin-bottom: ${(props) => (props.report ? "-35px" : "0px")};
`;

const Name = styled.div`
  font-size: ${(props) => (props.size === "small" ? "12px" : "16px")};
  text-align: center;
  height: ${(props) => (props.size === "small" ? "40px" : "60px")};
  display: flex;
  align-items: flex-start;
  padding-top: 2px;
`;

const Percent = styled.div`
  font-size: ${(props) => (props.size === "small" ? "14px" : "22px")};
`;

export default withTheme(AnimatedCircle);
