
import styled from "styled-components";
import { Card as AntCard, Tag as AntTag } from "antd";

export const UserName = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: rgba(0; 0; 0; 0.8);
  text-align: center;
`;

export const Title = styled.div`
  text-align: center;
`;

export const MenuTrigger = styled.span`
  &:focus {
    outline: none;
  }
`;

export const TriggerText = styled.span`
  font-weight: 600;
  color: ${props => props.theme.primary_color};
  cursor: pointer;
`;

export const Banner = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${props => props.color};
  height: 60px;
`;

export const Card = styled(AntCard)`
  height: 275px;
  width: 200px;
  border: 1px solid #d9d9d9;
  opacity: ${({
  hovering
}: {hovering: boolean;}) => hovering ? '1' : '0.4'};
  position: relative;
  padding-top: 100px !important;
  transition: hovering 0.2;

  .ant-card-body {
    padding: 0 12px;
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`;

export const Tag = styled(AntTag)`
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 2;
  color: black !important;
`;