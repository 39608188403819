import React from "react";
import styled from "styled-components";
import { Modal, Icon } from "antd";
import PropTypes from "prop-types";

const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  align-items: center;
`;
const Button = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 24px 12px;
  &:hover {
    background: #e8e8e8;
  }
`;

const LargeText = styled.span`
  font-size: 18px;
  text-align: center;
`;

const SmallText = styled.div`
  font-size: 14px;
  text-align: center;
`;

const FormOptions = ({
  openExternal,
  openInternal,
  visible,
  onCancel,
  logo
}) => {
  return (
    <Modal
      footer={null}
      visible={visible}
      style={{ paddingBottom: 0 }}
      width={650}
      onCancel={onCancel}
      className="choose-user-type"
    >
      <Body className="choose-user-type">
        <ButtonWrapper>
          <Button onClick={openInternal} id="internal">
            {logo ? (
              <img
                alt="logo"
                src={logo}
                style={{ maxHeight: 48, width: "auto" }}
              />
            ) : (
              <Icon
                theme="filled"
                type="folder-open"
                style={{ fontSize: 48 }}
              />
            )}
            <LargeText>Add someone from your company</LargeText>
          </Button>
          <SmallText>
            Add someone already on the Rhabit platform (Recommended)
          </SmallText>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button onClick={openExternal}>
            <Icon theme="filled" type="bulb" style={{ fontSize: 48 }} />
            <LargeText>Add someone from outside your company</LargeText>
          </Button>
          <SmallText>
            Invite a user from outside your organization to provide you feedback
          </SmallText>
        </ButtonWrapper>
      </Body>
    </Modal>
  );
};

FormOptions.propTypes = {
  openExternal: PropTypes.func.isRequired,
  openInternal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  logo: PropTypes.string.isRequired
};

export default FormOptions;
