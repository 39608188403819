/* eslint-disable flowtype/no-types-missing-file-annotation */
/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

export const getTspanGroups = (value: string, maxLineLength: number = 30, maxLines: number = 2) => {
  const words = value.split(' ');

  type linesAcc = {
    lines: string[],
    currLine: string
  };

  // reduces the words into lines of maxLineLength
  const assembleLines: linesAcc = words.reduce(
    (acc: linesAcc, word: string) => {
      // if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
      if ((word + acc.currLine).length > maxLineLength && acc.currLine !== '') {
        return {
          lines: acc.lines.concat([acc.currLine]),
          currLine: word
        };
      }
      // otherwise add the word to the current line
      return {
        ...acc,
        currLine: `${acc.currLine} ${word}`
      };
    },
    { lines: [], currLine: '' }
  );

  // add the ending state of current line (the last line) to lines
  const allLines = assembleLines.lines.concat([assembleLines.currLine]);

  // for now, only take first 2 lines due to tick spacing and possible overflow
  const lines = allLines.slice(0, maxLines);
  const children = [];
  let dy = 0;

  lines.forEach((lineText, i) => {
    children.push(
      // eslint-disable-next-line react/no-array-index-key
      <tspan x={0} dy={dy} key={i}>
        {// if on the second line, and that line's length is within 3 of the max length, add ellipsis
        i === 1 && allLines.length > 2 ? `${lineText.slice(0, maxLineLength - 3)}...` : lineText}
      </tspan>
    );
    // increment dy to render next line text below
    dy += 15;
  });

  return children;
};

const getOpacity = (value, opacity) => {
  if (value) {
    return value;
  }
  if (value === 0) {
    return opacity;
  }

  if (value === null) {
    return 0.1;
  }
  return 0;
};

export const HeatMapCellRect = ({
  data,
  value,
  x,
  y,
  width,
  height,
  color,
  opacity,
  enableLabel,
  textColor,
  onHover,
  onLeave,
  onClick,
  // eslint-disable-next-line react/prop-types
  theme
}) => {
  return (
    <g
      transform={`translate(${x}, ${y})`}
      onMouseEnter={value !== undefined ? onHover : () => null}
      onMouseMove={value !== undefined ? onHover : () => null}
      onMouseLeave={onLeave}
      onClick={(e) => onClick(data, e)}
      style={{ cursor: 'pointer' }}
    >
      <rect
        x={width * -0.5}
        y={height * -0.5}
        width={width}
        height={height}
        fill={value || value === 0 ? color : 'black'}
        fillOpacity={getOpacity(value, opacity)}
        strokeWidth={1}
        stroke={value ? color : '#e8e8e8'}
        strokeOpacity={opacity}
      />
      {enableLabel && value !== undefined && (
        <text
          alignmentBaseline="central"
          textAnchor="middle"
          style={{ ...theme.labels.text, fill: textColor }}
          fillOpacity={opacity}
        >
          {value || value === 0 ? `${value}%` : '--'}
        </text>
      )}
    </g>
  );
};

HeatMapCellRect.propTypes = {
  data: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  opacity: PropTypes.number.isRequired,
  enableLabel: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  onHover: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export const HeatMapTooltip = (e) => (
  <span>
    <span>
      {e.yKey} - {e.xKey}:
    </span>
    <span style={{ fontWeight: 'bold' }}>
      {e.value === null ? ' Insufficient Data' : ` ${e.value}%`}
    </span>
  </span>
);

export const OrgHeatMapTooltip = (e) => {
  return (
    <span>
      <span>
        {e.yKey} - {e.xKey}:
      </span>
      <span style={{ fontWeight: 'bold' }}>
        {e.value === null ? ' Insufficient Data' : ` ${e.value}%`}
      </span>
    </span>
  );
};

export const LineGraphTicks = (lines) => {
  const allTicks = Object.keys(
    lines.reduce((ticks, line) => {
      line.data.forEach((datum) => {
        if (!ticks[datum.x]) {
          // eslint-disable-next-line no-param-reassign
          ticks[datum.x] = true;
        }
      });
      return ticks;
    }, {})
  );

  const oneFourth = Math.round(allTicks.length / 4);

  if (oneFourth > 3) {
    return [
      allTicks[0],
      allTicks[oneFourth],
      allTicks[oneFourth * 2],
      allTicks[oneFourth * 3],
      allTicks[allTicks.length - 1]
    ];
  }
  if (oneFourth >= 2) {
    return [
      allTicks[0],
      allTicks[oneFourth],
      allTicks[oneFourth * 2],
      allTicks[allTicks.length - 1]
    ];
  }
  return allTicks;
};

export const SingleLineGraphTooltip = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { data } = props.slice.points[0];
  return (
    <div
      style={{
        background: 'white',
        padding: 6,
        borderRadius: 4,
        border: '1px solid #e8e8e8'
      }}
    >
      <span>
        {moment(data.x).format('MMM Do YY')}:{' '}
        <span style={{ fontWeight: 'bolder' }}>{data.y}%</span>
      </span>
    </div>
  );
};

/* eslint-disable */
SingleLineGraphTooltip.propTypes = {
  data: PropTypes.array.isRequired
};

export const HorizontalBarGraphBar = ({
  data,
  x,
  y,
  width,
  borderRadius,
  color,
  borderWidth,
  borderColor,
  shouldRenderLabel,
  labelColor,
  showTooltip,
  hideTooltip,
  onClick,
  onMouseEnter,
  onMouseLeave,
  tooltip,
  theme
}) => {
  const handleTooltip = (e) => showTooltip(tooltip, e);
  const handleMouseEnter = (e) => {
    onMouseEnter(data, e);
    showTooltip(tooltip, e);
  };
  const handleMouseLeave = (e) => {
    onMouseLeave(data, e);
    hideTooltip(e);
  };
  const groupIndex = ['score', 'women', 'men', 'contributors', 'leaders'].indexOf(data.id);
  return (
    <g transform={`translate(${x}, ${y - groupIndex * 5})`}>
      <rect
        width={width}
        height={10}
        rx={borderRadius}
        ry={borderRadius}
        fill={data.fill ? data.fill : color}
        strokeWidth={borderWidth}
        stroke={borderColor}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleTooltip}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      />
      {shouldRenderLabel && (data.id === 'women' || color === '#349980') && (
        <text
          x={0}
          y={-5}
          style={{
            ...theme.labels.text,
            pointerEvents: 'none',
            fill: labelColor
          }}
        >
          {data.data.description}
        </text>
      )}
      {shouldRenderLabel && (
        <text x={width + 5} y={9} style={{ ...theme.labels.text, fontSize: 10, fill: labelColor }}>
          {data.value}%
        </text>
      )}
    </g>
  );
};

export const HorizontalBarGraphBar2 = ({
  data,
  x,
  y,
  width,
  borderRadius,
  color,
  borderWidth,
  borderColor,
  shouldRenderLabel,
  labelColor,
  showTooltip,
  hideTooltip,
  onClick,
  onMouseEnter,
  onMouseLeave,
  tooltip,
  theme
}) => {
  const handleTooltip = (e) => showTooltip(tooltip, e);
  const handleMouseEnter = (e) => {
    onMouseEnter(data, e);
    showTooltip(tooltip, e);
  };
  const handleMouseLeave = (e) => {
    onMouseLeave(data, e);
    hideTooltip(e);
  };
  const groupIndex = ['score', 'women', 'men', 'contributors', 'leaders'].indexOf(data.id);
  return (
    <g transform={`translate(${x}, ${y - groupIndex * 5})`}>
      <rect
        width={width}
        height={10}
        rx={borderRadius}
        ry={borderRadius}
        fill={data.fill ? data.fill : color}
        strokeWidth={borderWidth}
        stroke={borderColor}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleTooltip}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      />
      {shouldRenderLabel &&
        (data.id === 'women' || color === '#349980')(
          <text
            x={0 - (100 - data.value)}
            y={-5}
            style={{
              ...theme.labels.text,
              pointerEvents: 'none',
              fill: labelColor
            }}
          >
            {data.data.description}
          </text>
        )}
      {shouldRenderLabel && (
        <text x={-25} y={7} style={{ ...theme.labels.text, fontSize: 10, fill: labelColor }}>
          {data.value}%
        </text>
      )}
    </g>
  );
};

export const HorizontalBarGraphBar3 = ({
  data,
  x,
  y,
  width,
  borderRadius,
  color,
  borderWidth,
  borderColor,
  shouldRenderLabel,
  labelColor,
  showTooltip,
  hideTooltip,
  onClick,
  onMouseEnter,
  onMouseLeave,
  tooltip,
  theme
}) => {
  const handleTooltip = (e) => showTooltip(tooltip, e);
  const handleMouseEnter = (e) => {
    onMouseEnter(data, e);
    showTooltip(tooltip, e);
  };
  const handleMouseLeave = (e) => {
    onMouseLeave(data, e);
    hideTooltip(e);
  };
  const groupIndex = ['score', 'women', 'men', 'contributors', 'leaders'].indexOf(data.id);
  return (
    <g transform={`translate(${x}, ${y - groupIndex * 5})`}>
      <rect
        width={width}
        height={10}
        rx={borderRadius}
        ry={borderRadius}
        fill={data.fill ? data.fill : color}
        strokeWidth={borderWidth}
        stroke={borderColor}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleTooltip}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      />
      {shouldRenderLabel && data.id === 'score' && (
        <text
          x={0 - (100 - data.value)}
          y={-5}
          style={{
            ...theme.labels.text,
            pointerEvents: 'none',
            fill: labelColor
          }}
        >
          {data.data.description}
        </text>
      )}
      {shouldRenderLabel && (
        <text x={-25} y={7} style={{ ...theme.labels.text, fontSize: 10, fill: labelColor }}>
          {data.value}%
        </text>
      )}
    </g>
  );
};

export const VerticalGroupedBarGraphBar = ({
  data,
  x,
  y,
  width,
  height,
  borderRadius,
  color,
  borderWidth,
  borderColor,
  label,
  shouldRenderLabel,
  labelColor,
  showTooltip,
  hideTooltip,
  onClick,
  onMouseEnter,
  onMouseLeave,
  tooltip,
  theme
}) => {
  const handleTooltip = (e) => showTooltip(tooltip, e);
  const handleMouseEnter = (e) => {
    onMouseEnter(data, e);
    showTooltip(tooltip, e);
  };
  const handleMouseLeave = (e) => {
    onMouseLeave(data, e);
    hideTooltip(e);
  };

  return (
    <g transform={`translate(${x + (data.id === 'current' ? width / 2 - 2 : 2)}, ${y})`}>
      <rect
        width={width / 2}
        height={height}
        rx={borderRadius}
        ry={borderRadius}
        fill={data.fill ? data.fill : color}
        strokeWidth={borderWidth}
        stroke={borderColor}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleTooltip}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      />
      {shouldRenderLabel && (
        <text
          x={width / 2 - width / 4}
          y={height / 2}
          textAnchor="middle"
          alignmentBaseline="central"
          style={{
            ...theme.labels.text,
            pointerEvents: 'none',
            fill: labelColor
          }}
        >
          {label}
        </text>
      )}
    </g>
  );
};
