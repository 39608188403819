/* eslint-disable no-param-reassign */
import React from "react";
import { Table, Tooltip, Icon, Card } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";

const Score = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  background-color: ${(props) => props.color};
  cursor: pointer;
`;

const Star = styled(Icon)`
  font-size: 20px;
  color: #78d36f !important;
  margin-right: 5px;
  cursor: pointer;
`;

const color = (score) => {
  if (score < 67) {
    return "#FF9331";
  }
  if (score < 80) {
    return "#FDCE60";
  }
  return "#78D36F";
};

const data = (users) => {
  return users.reduce((array, user) => {
    user.habits.forEach((habit) => {
      const existingHabit = array.find((h) => h.id === habit.id);
      if (existingHabit) {
        if (!existingHabit.users.find((u) => u.id === user.id)) {
          existingHabit.users = [
            ...existingHabit.users,
            { id: user.id, name: user.name, score: habit.score }
          ];
          array = [
            ...array.filter((h) => h.id !== existingHabit.id),
            existingHabit
          ];
        }
      } else {
        array.push({
          id: habit.id,
          name: habit.name,
          users: [
            {
              id: user.id,
              name: user.name,
              score: habit.score
            }
          ]
        });
      }
    });
    return array;
  }, []);
};

const sort = (input) => {
  const habitsByPerformance = input.reduce((obj, habit) => {
    obj[habit.id] = habit.users.reduce(
      (count, user) => {
        if (typeof user.score === "number" && user.score < 65) {
          count.weak += 1;
        } else if (typeof user.score === "number" && user.score < 80) {
          count.average += 1;
        }
        return count;
      },
      { weak: 0, average: 0 }
    );
    return obj;
  }, {});

  return input.sort((a, b) => {
    if (habitsByPerformance[a.id].weak > habitsByPerformance[b.id].weak) {
      return -1;
    }
    if (habitsByPerformance[a.id].weak === habitsByPerformance[b.id].weak) {
      if (
        habitsByPerformance[a.id].average > habitsByPerformance[b.id].average
      ) {
        return -1;
      }
      return 1;
    }
    return 1;
  });
};

const TeamHabits = ({ users, smaller }) => {
  const columns = [
    {
      title: "Habit",
      key: "id",
      dataIndex: "name",
      width: 150
    },
    {
      title: "Users",
      width: 150,
      render: (habit) => (
        <div style={{ display: "flex" }}>
          {habit.users
            .filter((u) => u.score !== null)
            .sort((a, b) => (a.score < b.score ? -1 : 1))
            .map((u) => (
              <Tooltip key={u.id} title={`${u.name} (${u.score}%)`}>
                {u.score >= 95 ? (
                  <Star type="star" theme="filled" />
                ) : (
                  <Score color={color(u.score)} />
                )}
              </Tooltip>
            ))
            .concat(
              habit.users
                .filter((u) => u.score === null)
                .map((u) => (
                  <Tooltip title={`${u.name} (Pending)`}>
                    <Score>
                      <Icon type="lock" />
                    </Score>
                  </Tooltip>
                ))
            )}
        </div>
      ),
      sorter: (a, b) => {
        const validDataA = a.users.reduce(
          (obj, u) => {
            if (u.score !== null) {
              return { sum: obj.sum + u.score, count: obj.count + 1 };
            }
            return obj;
          },
          { sum: 0, count: 0 }
        );
        const validDataB = b.users.reduce(
          (obj, u) => {
            if (u.score !== null) {
              return { sum: obj.sum + u.score, count: obj.count + 1 };
            }
            return obj;
          },
          { sum: 0, count: 0 }
        );
        return (
          validDataA.sum / validDataA.count - validDataB.sum / validDataB.count
        );
      }
    }
  ];

  const height = smaller ? 300 : 400;

  return (
    <Card title="Team Habits" bodyStyle={{ height: height - 56, padding: 0 }}>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={sort(data(users))}
        pagination={false}
        size="small"
        scroll={{ y: height - (56 + 37) }}
        style={{ padding: 0, height: height - 56 }}
      />
    </Card>
  );
};
TeamHabits.propTypes = {
  users: PropTypes.array.isRequired,
  smaller: PropTypes.bool
};

TeamHabits.defaultProps = {
  smaller: false
};

export default TeamHabits;
