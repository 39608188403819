/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { Menu, Dropdown, Icon } from "antd";
import { withTheme } from "styled-components";
import { Wrapper, Text, Score, HabitDescription } from "./styles";
import { useTranslation } from "react-i18next";

const { SubMenu } = Menu;
const mobile = window.innerWidth < 500;

const HabitMenu = ({ habits, selectHabit, close, font }) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}
        onClick={close}
        role="presentation"
      />
      <Menu
        onSelect={selectHabit} // style={{ border: '1px solid #e8e8e8', zIndex: 2 }}
      >
        {habits
          .filter((h) => h.active)
          .map((h) => (
            <Menu.Item
              key={h.habit_user_id}
              onClick={close}
              style={{ fontSize: font.subheader }}
            >
              {h.name}
              <Score>{h.score || "--"}%</Score>
            </Menu.Item>
          ))}
        {habits.filter((h) => !h.active).length > 0 && (
          <SubMenu title={t("deep_dive.habit_overview.archived_habits")}>
            {habits
              .filter((h) => !h.active)
              .map((h) => (
                <Menu.Item key={h.habit_user_id} onClick={close}>
                  <span style={{ width: "100%" }}>{h.name}</span>
                  <Score>{h.score || "--"}%</Score>
                </Menu.Item>
              ))}
          </SubMenu>
        )}
      </Menu>
    </>
  );
};

HabitMenu.propTypes = {
  habits: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  selectHabit: PropTypes.func.isRequired,
  font: PropTypes.object.isRequired
};

const HabitSelector = ({
  habits,
  habit,
  selectHabit,
  name,
  close,
  visible,
  open,
  theme
}) => (
  <Wrapper>
    <Dropdown
      onClick={!visible ? open : close}
      trigger={["click"]}
      style={{ cursor: "pointer" }}
      visible={visible}
      overlay={
        <HabitMenu
          font={theme.font}
          close={close}
          selectHabit={selectHabit}
          habits={habits}
        />
      }
    >
      <Text>
        {name}
        <Icon
          style={{ color: "rgba(0, 0, 0, 0.5", fontSize: 14, marginLeft: 6 }}
          type="caret-down"
        />
      </Text>
    </Dropdown>
    {!mobile && <HabitDescription>{habit.description}</HabitDescription>}
  </Wrapper>
);

HabitSelector.propTypes = {
  habits: PropTypes.array.isRequired,
  selectHabit: PropTypes.func.isRequired,
  name: PropTypes.string,
  close: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  habit: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

HabitSelector.defaultProps = {
  name: ""
};

export default withTheme(HabitSelector);
