import React from "react";
import PropTypes from "prop-types";
import { List, Avatar, Icon } from "antd";
import moment from "moment";
import nameToInitials from "utils/nameToInitials";

const NoteItem = ({ creator, createdAt, content, likes }) => (
  <List.Item // extra={<Icon type="heart" />}
    style={{ flexDirection: "column", alignItems: "flex-start" }}
  >
    <List.Item.Meta
      avatar={
        <Avatar src={creator.avatar}>{nameToInitials(creator.name)}</Avatar>
      }
      title={`${creator.name} gave a high five`}
      description={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          {moment(createdAt).format("MMM Do YY")}
          <span>
            <Icon style={{ marginLeft: 15, color: "red" }} type="heart" />{" "}
            {likes}
          </span>
        </div>
      }
    />
    {content}
  </List.Item>
);

NoteItem.propTypes = {
  creator: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired
};

export default NoteItem;
