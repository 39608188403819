/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Form, Input, DatePicker, Select, Checkbox, Icon, Tooltip } from "antd";
import PropTypes from "prop-types";
import LabelPicker from "components/LabelPicker";
import Label from "components/Label";
import ParentObjectivePicker from "components/ParentObjectivePicker";
import moment from "moment";
// import StyledCB from './styles';

const { Item } = Form;
const { Option } = Select;
const Attributes = ({
  form,
  setParent,
  params,
  parent,
  labels,
  setLabels,
  libraryMode,
  editing,
  defaultCheckedAutoEndOkr
}) => {
  const [pickerVisible, setPickerVisible] = useState(false);
  const { getFieldDecorator } = form;
  const deliveryDateValidation = (rule, value, callback) => {
    const start = moment(form.getFieldValue("start_date"));
    if (
      !value ||
      (!editing && value.valueOf() <= start.add(2, "weeks").valueOf())
    ) {
      callback(["error!"]);
    } else {
      callback();
    }
  };

  const submitLabels = (payload) => {
    form.setFieldsValue({ label_ids: payload.map((l) => l.id) });
    setLabels(payload);
  };

  const mobile = window.innerWidth < 500;

  return (
    <Form formLayout="vertical">
      <ParentObjectivePicker
        parent={parent}
        visible={pickerVisible}
        currentId={params && params.id}
        onCancel={() => setPickerVisible(false)}
        onSubmit={(selected) => {
          setParent(selected);
          form.setFieldsValue({
            parent_id: selected.id,
            private: selected.private
          });
          setPickerVisible(false);
        }}
      />
      <Item style={{ marginBottom: 0 }} label="Name">
        {getFieldDecorator("name", {
          initialValue: params ? params.name : "",
          rules: [{ required: true, message: "Please input name" }]
        })(<Input style={{ width: mobile ? "100%" : "80%" }} />)}
      </Item>
      {!libraryMode && (
        <Item style={{ marginBottom: 0 }} label="Labels">
          <div style={{ display: "flex", flexDirection: "column" }}>
            {labels.map((l) => (
              <Label label={l} style={{ marginBottom: 4 }} />
            ))}
          </div>
          <LabelPicker
            onSubmit={submitLabels}
            currentlySelected={labels.map((l) => l.id)}
          />
        </Item>
      )}
      <Item style={{ marginBottom: 0 }} label="Description (optional)">
        {getFieldDecorator("description", {
          initialValue: params ? params.description : ""
        })(
          <Input.TextArea rows={2} style={{ width: mobile ? "100%" : "80%" }} />
        )}
      </Item>
      {!libraryMode && (
        <Item style={{ marginBottom: 0 }} label="Check in every:">
          {getFieldDecorator("check_in_interval", {
            initialValue: params ? params.check_in_interval : 7
          })(
            <Select style={{ width: mobile ? "100%" : "80%" }}>
              <Option value={7}>Week</Option>
              <Option value={14}>Two Weeks</Option>
              <Option value={28}>Month</Option>
            </Select>
          )}
        </Item>
      )}

      {!libraryMode && (
        <div
          style={{
            display: "flex",
            alignItems: window.innerWidth < 500 ? "baseline" : "center",
            flexDirection: window.innerWidth < 500 && "column"
          }}
        >
          <Item style={{ marginBottom: 0 }} label="Start Date">
            {getFieldDecorator("start_date", {
              initialValue: params ? moment(params.start_date) : moment(),
              rules: [{ required: true, message: "Please input start date" }]
            })(<DatePicker style={{ marginRight: 24 }} />)}
          </Item>
          <Item style={{ marginBottom: 0 }} label="Delivery Date">
            {getFieldDecorator("delivery_date", {
              initialValue:
                params && params.delivery_date
                  ? moment(params.delivery_date)
                  : null,
              rules: [
                { required: true, message: "Please input delivery date" },
                {
                  validator: deliveryDateValidation,
                  message:
                    "Delivery date cannot be within 2 weeks of start date"
                }
              ]
            })(
              <DatePicker
                disabledDate={(date) => {
                  return date.valueOf() < moment().valueOf();
                }}
              />
            )}
          </Item>
          <div
            style={
              window.innerWidth >= 500
                ? { paddingTop: 36, paddingLeft: 12 }
                : { paddingTop: 12 }
            }
          >
            {getFieldDecorator("auto_complete", {
              initialValue: params
                ? params.auto_complete
                : defaultCheckedAutoEndOkr,
              valuePropName: "checked"
            })(<Checkbox>Auto Complete</Checkbox>)}
            <Tooltip title="When checked, objectives will automatically be marked completed on their delivery date">
              <Icon type="info-circle" />
            </Tooltip>
          </div>
        </div>
      )}

      {!libraryMode && (
        <Form.Item style={{ margin: 0 }}>
          {getFieldDecorator("label_ids", {
            initialValue: (labels && labels.map((l) => l.id)) || []
          })(<Input style={{ display: "none" }} />)}
        </Form.Item>
      )}
    </Form>
  );
};

Attributes.defaultProps = {
  parent: null,
  labels: [],
  libraryMode: false,
  editing: false
};

Attributes.propTypes = {
  form: PropTypes.any.isRequired,
  params: PropTypes.object.isRequired,
  defaultCheckedAutoEndOkr: PropTypes.bool.isRequired,
  parent: PropTypes.object,
  setParent: PropTypes.func.isRequired,
  labels: PropTypes.array,
  setLabels: PropTypes.func.isRequired,
  libraryMode: PropTypes.bool,
  editing: PropTypes.bool
};

export default Form.create()(Attributes);
