import styled from "styled-components";
import { Card } from "antd";
import { PageBody } from "components/Layout/styles";
import { media } from "utils/style-utils";

export const HomeCard = styled(Card)`
  width: 100%;
  margin-bottom: 24px;
  height: 43vh;
  border-radius: 6px !important;
  ${media.handheld`
    height: auto !important;
    width: 100vw;
  `}
`;

export const Body = styled(PageBody)`
  background-size: cover;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 12px;

  ${media.handheld`
    padding: 20px 0 120px 0;
   `}
`;