/* eslint-disable react/prop-types */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { observer } from "mobx-react";
import { observable } from "mobx";
import Relationships from "containers/Relationships";
import history from "services/history";
import viewModal from "components/FullScreenModal";
import { withTheme } from "styled-components";
import NetworkSetup from "containers/NetworkSetup";
import { Header } from "./styles";
import RaterList from "./RaterList";
import { withTranslation } from "react-i18next";

const mobile = window.innerWidth < 500;

@observer
class FeedbackStats extends React.Component {
  @observable networkSetupOpen = false;
  @observable filteredRaters = null;
  @observable remainingRaters = 0;

  componentWillReceiveProps(newProps) {
    if (newProps.habit.raters) {
      this.filteredRaters = this.limitRaters(newProps.habit.raters);
    }
  }

  openModal = () => {
    const { profileStore, updateHabit, habit } = this.props;
    const that = this;
    viewModal(
      {
        width: 900,
        onClose: () => {
          profileStore.fetchUpdatedHabits();
          updateHabit(habit.habit_user_id);
        }
      },
      history,
      <Relationships />
    );
  };

  avatarLimit = () => {
    const { isDashboardViewer } = this.props;
    const container = document.getElementById("rater-container");
    const button = isDashboardViewer
      ? 0
      : document.getElementById("rater-button").offsetWidth;
    const avatarWidth = 40;
    const containerSpace = container.offsetWidth - button;
    const avatarCap = Math.floor(containerSpace / avatarWidth);
    return mobile ? avatarCap - 1 : avatarCap;
  };

  limitRaters = (raters) => {
    const r = raters;
    const limit = this.avatarLimit();
    const arr = r.slice(0, limit + 1);
    this.remainingRaters = r.slice(limit, r.length);
    return arr;
  };

  render() {
    const { habit, theme, currentUserId, isDashboardViewer, t } = this.props;
    const count = 2;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "0px",
          width: "100%"
        }}
      >
        {this.networkSetupOpen && (
          <NetworkSetup
            user={{ id: currentUserId }}
            onClose={() => (this.networkSetupOpen = false)}
            asUser
          />
        )}

        <Header>
          {t("deep_dive.network_raters.feedback_providers", {
            count: habit.raters && habit.raters.length
          })}
          {habit.raters && habit.raters.length < 3 && (
            <span style={{ color: "red", fontSize: theme.font.small }}>
              {t("deep_dive.network_raters.rater_count_warning")}
            </span>
          )}
          {habit.raters && habit.raters.length < 6 && habit.raters.length > 2 && (
            <span style={{ color: "red", fontSize: theme.font.small }}>
              {t("deep_dive.network_raters.add_more_raters", {
                count: 6 - habit.raters.length
              })}
            </span>
          )}
        </Header>
        <div
          id="rater-container"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "10px"
          }}
        >
          {habit.raters && habit.raters.length > 2 && (
            <RaterList
              filteredRaters={this.filteredRaters}
              remainingRaters={this.remainingRaters}
            />
          )}
          {!isDashboardViewer && (
            <Button id="rater-button" type="primary" onClick={this.openModal}>
              {t("deep_dive.network_raters.edit_providers")}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

FeedbackStats.propTypes = {
  profileStore: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isDashboardViewer: PropTypes.bool.isRequired,
  habit: PropTypes.shape({
    dont_know_percentage: PropTypes.number.isRequired,
    raters: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        avatar: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  }).isRequired,
  currentUserId: PropTypes.string.isRequired
};

export default withTranslation()(withTheme(FeedbackStats));
