import React from "react";
import { Card, Icon, Popconfirm, Modal } from "antd";
import { ActionButton, MobileCarousel } from "./styles";

const MobileTable = ({ dataSource, handleDelete, copyToClipboard }) => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <span style={{ fontSize: "20px" }}>Shared Links:</span>
      </div>
      <MobileCarousel arrows dots>
        {dataSource.map((v) => {
          return (
            <Card
              actions={[
                <ActionButton
                  onClick={() => {
                    Modal.info({
                      maskClosable: true,
                      okText: "Copy to Clipboard",
                      title: "Dashboard Invite Link",
                      content: v.url,
                      onOk: () => {
                        copyToClipboard(v.url);
                      }
                    });
                  }}
                >
                  <Icon type="eye" />
                </ActionButton>,
                <Popconfirm
                  overlayClassName="alert"
                  overlayStyle={{ padding: "24px !!important" }}
                  title="Are you sure？"
                  icon={
                    <Icon type="question-circle-o" style={{ color: "red" }} />
                  }
                  onConfirm={() => handleDelete(v.id)}
                  okText="Yes"
                >
                  <ActionButton>
                    <Icon type="stop" />
                  </ActionButton>
                </Popconfirm>
              ]}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ display: "flex", flexDirection: "column" }}>
                  <strong>Email:</strong>
                  <p>{v.email}</p>
                </span>
                <span>
                  <strong>Expires:</strong>
                  <p>{v.expires_at}</p>
                </span>
              </div>
            </Card>
          );
        })}
      </MobileCarousel>
    </>
  );
};

export default MobileTable;
