import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LoadingOverlay from "components/LoadingOverlay";
import { PageBody } from "components/Layout/styles";
import { AnchorContainer } from "antd/lib/anchor/Anchor";
import { Anchor, Divider, Alert } from "antd";
import Box from "layout/Box";
import useStores from "utils/useStores";
import FeedbackForm from "containers/Page/HeaderActions/FeedbackForm";
import { Profile } from "services/api";
import { useFetchManagerDashboardQuery } from "./fetchManagerDashboard.generated";
import ManagerActions from "./ManagerActions";
import ManagedUsers from "./ManagedUsers";
import EngagementTrend from "./EngagementTrend";
import HabitPerformance from "./HabitPerformance";

const { Link } = Anchor;

const ManagerDashboardNext: React.FC = () => {
  const [managedTeamId, setManagedTeamId] = useState<null | string>(null);
  const { loading, error, data } = useFetchManagerDashboardQuery({
    variables: { id: managedTeamId }
  });
  const { profileStore } = useStores();
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      setManagedTeamId(id);
    } else {
      setManagedTeamId(null);
    } 
  }, [id]);

  if (error) {
    return (
      <PageBody>
        <span>{error.message}</span>
      </PageBody>
    );
  }
  if (loading || !data) {
    return (
      <PageBody>
        <LoadingOverlay />
      </PageBody>
    );
  }

  const sendFeedback = (values: { feedback: string }): void => {
    const appendedValues = {
      ...values,
      feedback: `${values.feedback} - manager dashboard feedback`
    };
    Profile.sendFeedback(appendedValues);
    setFeedbackOpen(false);
  };

  const showOptOut =
    !profileStore.features().includes("NEXT_MANAGER_DASHBOARD") &&
    profileStore.features().includes("ALLOW_OPT_IN_NEXT_MANAGER_DASHBOARD");

  return (
    <Box display="flex" width="100%">
      <FeedbackForm
        visible={feedbackOpen}
        onCancel={() => setFeedbackOpen(false)}
        onSend={sendFeedback}
      />
      <div
        style={{
          width: "calc(100% - 165px)",
          height: "calc(100vh - 64px)",
          overflowY: "scroll",
          padding: "24px 24px 128px 24px"
        }}
        id="manager-container"
      >
        <div
          style={{
            maxWidth: "1400px",
            margin: "0 auto"
          }}
        >
          {showOptOut && (
            <Alert
              type="success"
              style={{ marginBottom: 24 }}
              message={
                <span>
                  Welcome to the new manager dashboard. We&apos;d love your
                  feedback!{" "}
                  <a onClick={() => setFeedbackOpen(true)}>Click Here</a> to
                  give us feedback about the new design.
                  <a onClick={profileStore.optOutOfManagerRedesign}>
                    {" "}
                    Click Here
                  </a>{" "}
                  to go back to the old design.
                </span>
              }
            />
          )}
          <ManagerActions
            actions={data.managerActionItems}
            id="manager-actions"
            managedTeam={managedTeamId ? data.team : null}
          />
          {data.team.users.length >= 5 && (
            <>
              <Divider style={{ marginTop: 48 }}>Team Sentiment</Divider>
              <EngagementTrend key={managedTeamId} managedTeamId={managedTeamId} />
            </>
          )}
          <Divider style={{ marginTop: 48 }}>Habit Performance</Divider>
          <HabitPerformance users={data.team.users} />
          <Divider style={{ marginTop: 48 }}>Your Team</Divider>
          <ManagedUsers users={data.team.users} id="managed-users" />
        </div>
      </div>
      <Box width="165px" pl="6px">
        <Anchor
          offsetTop={24}
          getContainer={(): AnchorContainer =>
            document.getElementById("manager-container") as AnchorContainer
          }
          style={{ position: "absolute", background: "transparent" }}
        >
          <Link href="#manager-actions" title="Action Items" />
          {data.team.users.length >= 5 && (
            <Link href="#engagement-trend" title="Team Sentiment" />
          )}
          <Link href="#habit-performance" title="Habit Performance" />
          <Link href="#managed-users" title="Team Members" />
        </Anchor>
      </Box>
    </Box>
  );
};

export default ManagerDashboardNext;
