import { observable, action, computed } from 'mobx';
import { Admin, Team } from 'services/api';

class ProposalStore {
  @observable proposals = [];
  @observable approvalModalOpen = false;
  @observable proposalsOpen = false;
  @observable managedMember;
  @observable loading = false;

  fetchAsAdmin() {
    Admin.fetchProposals(false).then(({ data }) => (this.proposals = data));
    this.api = Admin;
  }

  @action.bound
  getActive() {
    this.loading = true;
    Admin.fetchProposals(false).then(({ data }) => {
      this.proposals = data;
      this.loading = false;
    });
  }

  @action.bound
  getArchived(page = 0) {
    this.loading = true;
    Admin.fetchProposals(true, page).then(({ data }) => {
      this.proposals = data;
      this.loading = false;
    });
  }

  fetchAsTeam() {
    Team.fetchProposals().then(({ data }) => (this.proposals = data));
    this.api = Team;
  }

  approve = (managedMember, proposal) => {
    this.managedMember.proposals.find((p) => p.id === proposal.id).status =
      'approved';
    // this.managedMember = Object.assign(this.managedMember, {})
    // console.log(this.managedMember.proposals.find(p => p.id === proposal.id).status);
    // // this.proposals.find(p => p.user_id === managedMember.user_id).proposals.find(p => p.id === proposal.id).status = "approved";
    // // console.log(this.proposals.find(p => p.user_id === managedMember.user_id).proposals.find(p => p.id === proposal.id).status);
    // // this.proposals = Object.assign(this.proposals, {})
    // this.forceUpdate();
  };

  reject = (proposal) => {
    this.managedMember.proposals.find((p) => p.id === proposal.id).status =
      'rejected';
  };

  undo = (proposal) => {
    this.managedMember.proposals.find((p) => p.id === proposal.id).status =
      'pending';
  };

  approveAll = () => {
    // eslint-disable-next-line no-param-reassign
    this.managedMember.proposals.forEach((p) => (p.status = 'approved'));
  };

  @computed get pendingProposals() {
    const proposals = this.proposals.reduce((count, user) => {
      if (user.proposals.some((p) => p.status === 'pending')) {
        return count + 1;
      }
      return count;
    }, 0);
    return proposals;
  }
  handleSubmit = (newMember) => {
    const req = { rejected: [], approved: [], pending: [] };
    newMember.proposals.forEach((p) => {
      if (p.status === 'rejected') {
        req.rejected.push(p.id);
      } else if (p.status === 'approved') {
        req.approved.push(p.id);
      } else if (p.status === 'pending') {
        req.pending.push(p.id);
      }
    });
    this.api.updateProposals(req);
    this.managedMember.proposals = newMember.proposals;
  };

  @action
  openApprovalModal = (member) => {
    this.managedMember = member;
    this.approvalModalOpen = true;
  };
  openProposals = () => (this.proposalsOpen = true);
}

export default new ProposalStore();
