// @flow

import * as React from "react";
import { Row, Col } from "antd";
// import { ReactComponent as Medal } from 'assets/medal.svg';
import Carrot from "assets/carrot.png";
import { Flair } from "containers/WorkItems/styles";
import { Badge } from ".";
import {
  CarrotImg,
  BadgeItemContainer,
  BadgeItemBody,
  BadgeItemTitle,
  BadgeItemDescription,
  BadgeItemImage
} from "./styles";

type Props = {
  badges: Badge[];
  userBadges: Badge[];
};

type BadgeItemProps = {
  badge: Badge;
  userHasAchieved: boolean;
  nameOnly?: boolean;
};

export const BadgeItem = ({
  badge,
  userHasAchieved,
  nameOnly
}: BadgeItemProps) => (
  <BadgeItemContainer userHasAchieved={userHasAchieved}>
    <Flair style={{ width: "unset", display: "flex" }}>
      <div
        style={{
          fontSize: 36,
          marginRight: 12,
          paddingLeft: 6,
          color: "rgba(0, 0, 0, 1)",
          opacity: !userHasAchieved && "0.2"
        }}
      >
        {badge.custom_fields.img}
      </div>
    </Flair>
    <BadgeItemBody style={{ width: nameOnly && "100%" }}>
      <BadgeItemTitle>{badge.custom_fields.title}</BadgeItemTitle>
      {!nameOnly && (
        <BadgeItemDescription>
          {badge.custom_fields.description}
        </BadgeItemDescription>
      )}
    </BadgeItemBody>
    {!nameOnly && (
      <BadgeItemImage>
        + {badge.custom_fields.value}{" "}
        <CarrotImg
          src={Carrot}
          large
          style={{ marginLeft: 3, filter: !userHasAchieved && "grayscale(1)" }}
        />
      </BadgeItemImage>
    )}
  </BadgeItemContainer>
);

const BadgeList = ({ badges, userBadges }: Props) => (
  <Row>
    {badges.map((badge: Badge) => (
      <Col
        xl={12}
        lg={12}
        md={12}
        sm={24}
        xs={24}
        style={{
          margin: "12px 0",
          padding: window.innerWidth < 500 ? 0 : "0 6px"
        }}
      >
        <BadgeItem
          badge={badge}
          userHasAchieved={
            !!userBadges.find((userBadge: Badge) => userBadge.id === badge.id)
          }
        />
      </Col>
    ))}
  </Row>
);

export default BadgeList;
