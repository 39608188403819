import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "antd";

import styled from "styled-components";

const videoFrame = (link) => {
  if (link && link.includes("vimeo")) {
    const match = link.match(
      /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i
    );
    const src = `https://player.vimeo.com/video/${match[1]}`;
    return (
      <iframe
        allow="fullscreen"
        title="video"
        width="640"
        height="360"
        src={src}
        style={{ border: "none" }}
      />
    );
  }
  if (link && link.includes("youtube")) {
    const code = link.split("v=")[1];
    const src = `https://www.youtube.com/embed/${code}`;
    return (
      <iframe
        allow="fullscreen"
        title="video"
        width="640"
        height="360"
        src={src}
        style={{ border: "none" }}
      />
    );
  }
  return <div>Unable to get video source</div>;
};

const WidgetModal = ({ src, title, onCancel }) => {
  return (
    <Modal
      visible={!!src}
      title={title}
      footer={
        <Button type="primary" onClick={onCancel}>
          Close
        </Button>
      }
      onCancel={onCancel}
      bodyStyle={{}}
    >
      <VideoWrapper>{videoFrame(src)}</VideoWrapper>
    </Modal>
  );
};
WidgetModal.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  onCancel: PropTypes.func.isRequired
};

WidgetModal.defaultProps = {
  // PLEASE VERIFY
  src: "",
  title: ""
};

export default WidgetModal;
const VideoWrapper = styled.div`
  background-image: ${(props) =>
    props.backgroundImg
      ? `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${props.backgroundImg})`
      : null};
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
