import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";

const Review = ({ selectedHabits, changes }) => {
  const columns = [
    {
      title: "Habit",
      dataIndex: "name",
      key: "name",
      render: (name) => {
        const habit = Object.values(changes).find((h) => h.name === name);
        const habitAdded = habit.change && habit.change === "add";
        return (
          <span style={habitAdded ? { background: "#BAEFC1" } : {}}>
            {habitAdded ? "+  " : ""}
            {name}
          </span>
        );
      }
    }
  ];

  const data = selectedHabits.filter(
    (h) => !(h.proposal && h.proposal.change_type === "remove")
  );

  return (
    <div style={{ margin: "24px 0px" }}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 350 }}
      />
      <div id="setup-footer">
        Removed habits:{" "}
        {Object.values(changes)
          .filter((h) => h.change === "remove")
          .map((h) => h.name)
          .join(", ")}
      </div>
    </div>
  );
};

Review.propTypes = {
  /*eslint-disable */
  id: PropTypes.string.isRequired,
  raters: PropTypes.array.isRequired,

  /* eslint-enable */
  selectedHabits: PropTypes.array.isRequired,
  changes: PropTypes.object.isRequired
};

export default Review;
