import styled from "styled-components";
import { media } from "utils/style-utils";

export const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 20px;
  padding: 7.5px 20px;
  position: relative;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
  cursor: func && pointer;
  height: 127.5px;

  ${media.handheld`
    margin: 15px 0px;
  `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const IconContainer = styled.div`
  width: 75px;
  display: flex;
  align-items: center;
`;

export const Body = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.overflow && "2"};
  -webkit-box-orient: vertical;
  overflow: ${props => props.overflow && "hidden"};
  font-size: 12px;
`;
