import { css } from "styled-components";

export const media = {
  handheld: (...args) => css`
    @media (max-width: 420px) {
      ${css(...args)}
    }
  `,
  short: (...args) => css`
    @media (max-height: 600px) {
      ${css(...args)}
    }
  `,
  tablet: (...args) => css`
    @media (max-width: 1180px) {
      ${css(...args)}
    }
  `,
  widescreen: (...args) => css`
    @media (min-width: 2000px) {
      ${css(...args)}
    }
  `,
  laptop: (...args) => css`
    @media (max-width: 1500px) {
      ${css(...args)}
    }
  `,
  iphone5: (...args) => css`
    @media (max-width: 320px) {
      ${css(...args)}
    }
  `,
  netbook: (...args) => css`
    @media (max-height: 800px) and (min-width: 800px) {
      ${css(...args)}
    }
  `
};

export default media;

/* eslint-disable */
export function lightenDarkenColor(col, amt) {
  var usePound = false;

  if (col[0] == '#') {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;else if (r < 0) r = 0;

  var b = (num >> 8 & 0x00ff) + amt;

  if (b > 255) b = 255;else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;else if (g < 0) g = 0;

  return (usePound ? '#' : '') + String('000000' + (g | b << 8 | r << 16).toString(16)).slice(-6);
}
/* eslint-enable */