/* eslint-disable no-unused-vars */
// @flow

import React from "react";
import Display from "assets/WomenDisplay.svg";
import Widget from "components/WidgetV2";
import NoContent from "assets/Flavor5.svg";
import { NoWidgetContainer, WidgetContainer } from "./styles";
import CourseWidget from "./CourseWidget";
import { useTranslation } from "react-i18next";

type ImproveViewProps = {
  selectFlow: boolean;
  widgets: any[];
};

const ImproveView = ({ selectFlow, widgets }: ImproveViewProps) => {
  const { t } = useTranslation();
  return (
    <>
      {!selectFlow && (
        <NoWidgetContainer>
          <CourseWidget />

          <span style={{ marginBottom: "75px" }}>
            {t("deep_dive.improvement.click_behavior_to_see_tips")}
          </span>
          {/* <img
            alt="no-content"
            src={Display}
            style={{
              height: window.innerWidth < 500 ? '100px' : '50%'
            }}
          /> */}
        </NoWidgetContainer>
      )}
      {selectFlow && widgets.length === 0 && (
        <WidgetContainer>
          <CourseWidget />

          <span style={{ marginBottom: "65px" }}>
            {t("deep_dive.improvement.still_building_content")}
          </span>
          {/* <img
            style={{
              height: window.innerWidth < 500 ? '100px' : '200%',
              width: '300px',
              opacity: 0.85
            }}
            alt="no-content"
            src={NoContent}
          ></img> */}
        </WidgetContainer>
      )}
      {selectFlow && widgets.length > 0 && (
        <ul style={{ padding: "0px" }}>
          <CourseWidget />

          {widgets.map((w, _i) => {
            if (w.type === "DocumentWidget") {
              return null;
            }
            const body = <Widget key={w.id} w={w}></Widget>;
            if (w.link) {
              return (
                <a style={{ color: "rgba(0, 0, 0, 0.65)" }} href={w.link}>
                  {body}
                </a>
              );
            }
            return body;
          })}
        </ul>
      )}
    </>
  );
};

export default ImproveView;
