import React, { useState } from "react";
import { SamlSettings } from "types/shared";
import { Form, Popover, Button, Icon, Input } from "antd";
import axios from "axios";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { API_URL } from "../../env";

type Props = {
  saml: SamlSettings;
  tenant: string;
  form: WrappedFormUtils;
};

const SamlSignIn = ({ form }: Props) => {
  const [loading, setLoading] = useState(false);
  const [tenant, setTenant] = useState("");
  const [saml, setSaml] = useState(null);
  const [errors, setErrors] = useState(null);

  const fetchSSO = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${API_URL.replace("/api/v2", `/saml/${tenant}`)}`
      );
      setSaml(data);
    } catch (err) {
      setErrors(err.response.data);
    }
    setLoading(false);
  };

  if (saml) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <span style={{ textAlign: "center", fontWeight: 500, fontSize: 24 }}>
          {saml.idp_name}
        </span>
        <Button
          icon="key"
          size="large"
          href={`${API_URL.replace("/api/v2", `/saml?tenant=${tenant}`)}`}
          type="primary"
          style={{ width: "100%", marginTop: 12 }}
        >
          Sign In with{" "}
          {saml.idp_name === "custom" ? "Single Sign On" : saml.idp_name}
        </Button>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          fontSize: 18,
          width: "100%",
          textAlign: "center",
          marginBottom: 45
        }}
      >
        Enter your company domain to show single sign on options
        <Popover
          content={
            <div style={{ maxWidth: 250 }}>
              If you don&apos;t know your domain, try writing your company name
              in snake case (all lowercase with underscores instead of spaces).
              For example: &apos;Rhabit Analytics&apos; becomes
              &apos;rhabit_analytics&apos;
            </div>
          }
        >
          <Icon
            type="question-circle"
            style={{ fontSize: 16, marginLeft: 6 }}
          />
        </Popover>
      </div>
      <span style={{ color: "red" }}>{errors}</span>
      <div style={{ display: "flex" }}>
        <Form // layout={window.innerWidth > 500 && 'inline'}
          style={{ width: "100%" }}
        >
          <Form.Item>
            {form.getFieldDecorator("tenant")(
              <Input
                size="large"
                placeholder="dunder_mifflin"
                onChange={(e) => setTenant(e.target.value)}
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              disabled={tenant.length === 0}
              loading={loading}
              type="primary"
              onClick={fetchSSO}
              htmlType="submit"
              style={{
                width: "100%"
              }}
            >
              Next
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default Form.create()(SamlSignIn);
