/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Modal, Avatar, Typography } from "antd";
import { observer, inject } from "mobx-react";
import { observable } from "mobx";
import Joyride, { ACTIONS, EVENTS } from "react-joyride";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { withTheme } from "styled-components";

import { Profile, Highfive, Update } from "services/api";
import NetworkSetup from "containers/NetworkSetup";
import WorkItems from "containers/WorkItems";
// import PrivacyPolicy from 'components/PrivacyPolicyModal';
//
import Relationships from "containers/Relationships";

import viewModal from "components/FullScreenModal";

import UpdateModal from "components/UpdateModal";
import HabitUnlockModal from "components/HabitUnlockModal";
// import { Profile } from "services/api";
import Spring from "assets/spring.png";
import Carrot from "assets/carrot.png";
import nameToInitials from "utils/nameToInitials";
import { Body, Fader } from "./styles";
import Feed from "./Feed";
import Habits from "./Habits";
// import Improving from './Improving';

import { steps } from "./demoSteps";
import DemoData from "./demoData";
import Objectives from "./Objectives";
import Feature from "components/Feature";
import { withTranslation } from "react-i18next";

// const content = (
//   <div>
//     <iframe
//       src="https://player.vimeo.com/video/362804507"
//       style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
//       frameBorder="0" allow="autoplay; fullscreen"
//       allowFullscreen
//       />
//     <script src="https://player.vimeo.com/api/player.js"/>
//   </div>
// )

const videoStep = {
  target: "body",
  styles: { tooltip: { width: window.innerWidth < 500 ? "98vw" : "750px" } },
  content: (
    <div
      style={{
        padding: "56.25% 0 0 0",
        position: "relative"
      }}
    >
      <Typography.Title
        level={4}
        style={{ position: "absolute", top: 0, left: "5%" }}
      >
        Getting Started With Rhabit
      </Typography.Title>
      <iframe
        title="welcome-video"
        src="https://player.vimeo.com/video/362804507"
        style={{
          position: "absolute",
          bottom: 0,
          left: "5%",
          width: "90%",
          height: "90%"
        }}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullscreen
      />
      <script src="https://player.vimeo.com/api/player.js" />
    </div>
  ),
  disableBeacon: true,
  placement: "center"
};

@inject("profileStore", "tutorialStore")
@observer
class HomePage extends React.Component {
  @observable networkSetupOpen = false;
  @observable createGoalOpen = false;
  @observable canAnnounce = false;
  @observable updateModal = null;
  @observable unlockedHabits = [];
  @observable unlockModalOpen = false;
  @observable inNetworkSetup = false;

  static propTypes = {
    theme: PropTypes.object.isRequired,
    profileStore: PropTypes.shape({
      currentUser: PropTypes.shape({
        habits: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
        self_onboarded: PropTypes.bool,
        organization: PropTypes.shape({
          features: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
          suggestion_manager: PropTypes.arrayOf(PropTypes.string.isRequired)
            .isRequired
        }).isRequired,
        questions: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
        daily_content: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        organization_name: PropTypes.string.isRequired,
        goals: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
      }).isRequired,
      theme: PropTypes.shape({
        primary_color: PropTypes.string.isRequired
      }).isRequired,
      isSuperAdmin: PropTypes.bool.isRequired,
      updateInfo: PropTypes.func.isRequired
    }),
    history: PropTypes.object.isRequired
  };

  static defaultProps = {
    profileStore: {
      currentUser: {
        daily_content: {}
      }
    }
  };

  // componentWillReceiveProps(nextProps) {
  //   debugger;
  //   if (nextProps.tutorialStore.runningTutorial === "how_to_setup_feedback_network") {
  //     this.inNetworkSetup = true;
  //     viewModal(
  //       { width: 900, onClose: () => (this.inNetworkSetup = false) },
  //       nextProps.history,
  //       <Relationships onboarding />
  //     );
  //   }
  // }

  componentDidMount() {
    const {
      profileStore,
      tutorialStore,
      history,
      profileStore: { currentUser }
    } = this.props;
    if (profileStore.isSuperAdmin) {
      history.push("/superadmin/organizations");
      return;
    }
    const ddi = profileStore.features().includes("DDI_CLIENT");

    if (tutorialStore.shouldShowAccountSetupTutorial(currentUser)) {
      tutorialStore.runTutorial("how_to_setup_account");
    }

    if (tutorialStore.shouldShowProductTour(profileStore.currentUser)) {
      tutorialStore.runTutorial("product_tour");
    }

    this.unlockedHabits = profileStore.currentUser.habits.filter(
      (h) =>
        moment(h.date_unlocked).valueOf() >
        moment(profileStore.currentUser.last_login).valueOf()
    );
    if (this.unlockedHabits.length) {
      setTimeout(() => (this.unlockModalOpen = true), 2000);
    }
    // setTimeout(() => {
    //   this.unlockedHabits = profileStore.currentUser.habits.filter(
    //     (h) =>
    //       moment(h.date_unlocked) > moment(profileStore.currentUser.last_login)
    //   );
    // }, 2000);
    Update.fetchLatest().then(({ data }) => {
      if (data && data.id) {
        this.updateModal = data;
      }
    });

    if (profileStore.newHighfiveId) {
      Highfive.fetch(profileStore.newHighfiveId).then(({ data }) => {
        Modal.info({
          title: `${data.sender.name} sent you a highfive!`,
          content: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                {data.sender.id === "rhabit" ? (
                  <div
                    style={{
                      height: 48,
                      width: 48,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      background: "rgba(0,167,79,0.25)"
                    }}
                  >
                    <img alt="carrot" src={Carrot} height={30} />
                  </div>
                ) : (
                  <Avatar size={48} src={data.sender.avatar}>
                    <span style={{ fontSize: 25 }}>
                      {nameToInitials(data.sender.name)}
                    </span>
                  </Avatar>
                )}
              </div>
              <span style={{ marginLeft: 12 }}>{data.content}</span>
            </div>
          ),
          iconType: "like",
          onOk() {}
        });
      });
    }
  }

  openNotification = () => {};

  handleCloseUnlockModal = () => {
    if (this.unlockedHabits.length - 1 === 0) {
      this.unlockModalOpen = false;
      this.unlockedHabits = [];
    } else {
      this.unlockedHabits.shift();
    }
  };

  openNetworkSetup = () => {
    this.networkSetupOpen = true;
  };

  callback = (tour) => {
    const { profileStore, history, tutorialStore } = this.props;
    const ddi = profileStore.features().includes("DDI_CLIENT");
    const { action, index, type } = tour;
    if (type === EVENTS.TOUR_END) {
      tutorialStore.endCurrentTutorial();
      if (!profileStore.currentUser.preferences.onboarding_tutorial_viewed) {
        if (profileStore.currentUser.preferences.initial_config) {
          Profile.update({
            onboarded: true // profileStore.currentUser.preferences.self_onboard,
          }).then(({ data }) => {
            profileStore.updateInfo(data);
            if (ddi) {
              this.inNetworkSetup = true;
              viewModal(
                { width: 900, onClose: () => (this.inNetworkSetup = false) },
                history,
                <Relationships onboarding />
              );
            }
          });
        } else {
          Profile.update({ onboarding_tutorial_viewed: true }).then(
            ({ data }) => {
              profileStore.updateInfo(data);
            }
          );
        }
      }
    }
    // if (type === EVENTS.TOUR_END || action === "skip") {
    //   // Update user preferences with completed tour flag
    //   this.props.userStore.endTour();
    // } else if (type === EVENTS.TOUR_START) {
    //   this.props.userStore.markOnboardingTutorialSeen();
    // } else if (type === EVENTS.STEP_AFTER && action !== ACTIONS.PREV && index === 4 && lifecycle === "complete") {
    //   this.props.history.push("/profile/demo");
    if (
      [EVENTS.STEP_AFTER, EVENTS.CLOSE, EVENTS.TARGET_NOT_FOUND].includes(type)
    ) {
      // Since this is a controlled tour you'll need to update the state to advance the tour
      profileStore.stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
    }
  };

  render() {
    const {
      profileStore,
      t,
      theme,
      history,
      tutorialStore,
      profileStore: {
        currentUser: {
          organization: { features }
        }
      }
    } = this.props;
    const {
      currentUser,
      currentUser: { preferences }
    } = profileStore;
    if (profileStore.isSuperAdmin) {
      this.props.history.push("/superadmin/organizations");
      return <div />;
    }
    const mobile = window.innerWidth < 500;

    const filteredSteps = steps.filter((s) =>
      currentUser.habits.length > 0
        ? true
        : s.target !== "#habits" && s.target !== "#demo_habit"
    );

    const translatedSteps = filteredSteps.map((s) => ({
      ...s,
      content: t(s.content)
    }));
    const ddiSteps = [
      videoStep,
      ...translatedSteps.filter((s) => s.target !== "#feed")
    ];

    const inTutorial = tutorialStore.runningTutorial === "product_tour";

    const oneColumn =
      !features.includes("OBJECTIVES") && features.includes("NO_HIGHFIVES");
    if (tutorialStore.runningTutorial === "how_to_setup_feedback_network") {
      this.inNetworkSetup = true;
      viewModal(
        { width: 900, onClose: () => (this.inNetworkSetup = false) },
        history,
        <Relationships onboarding />
      );
    }

    return (
      <Body src={Spring}>
        {!!this.updateModal && (
          <UpdateModal
            visible
            update={this.updateModal}
            onClose={() => (this.updateModal = null)}
          />
        )}
        {this.networkSetupOpen && (
          <NetworkSetup
            user={currentUser}
            asUser
            step={0}
            onClose={() => (this.networkSetupOpen = false)}
          />
        )}
        <HabitUnlockModal
          visible={this.unlockModalOpen}
          habit={this.unlockedHabits[0]}
          onClose={this.handleCloseUnlockModal}
        />

        <Joyride
          continuous
          steps={features.includes("DDI_CLIENT") ? ddiSteps : translatedSteps}
          showSkipButton
          spotlightPadding={0}
          styles={{
            options: {
              primaryColor: currentUser.organization.theme.primary_color,
              zIndex: 999
            }
          }}
          locale={{
            back: "Back",
            close: "Close",
            last: "Close",
            next: "Next",
            skip: "Skip"
          }}
          run={inTutorial && !this.inNetworkSetup}
          stepIndex={profileStore.stepIndex}
          callback={this.callback}
        />

        <Row gutter={!mobile && 24}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={oneColumn ? { span: 16, offset: 4 } : 10}
            xxl={oneColumn ? { span: 12, offset: 6 } : 10}
            style={{ height: "100%" }}
          >
            <Row>
              {!features.includes("NO_ACTION_ITEMS") && (
                <Col
                  style={{
                    height: mobile ? "auto" : 300,
                    marginBottom: 24,
                    minHeight: !mobile && 300,
                    maxHeight: !mobile && 300,
                    overflowY: "hidden"
                  }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  {mobile && <Fader />}
                  <WorkItems
                    goalsActive={false}
                    inTutorial={inTutorial}
                    goals={currentUser.goals}
                    habits={
                      !inTutorial
                        ? currentUser.habits.filter((h) => h.active)
                        : DemoData.habits
                    }
                    color={theme.primary_color}
                    questions={
                      currentUser.questions && currentUser.questions.length
                    }
                    currentUserId={currentUser.id}
                  />
                </Col>
              )}
              <Col
                style={{
                  marginBottom: 24,
                  minHeight: !mobile && 429,
                  height: mobile
                    ? "auto"
                    : "calc((100vh - 300px - 24px - 24px - 24px - 64px)"
                }}
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <Habits
                  unlockedHabits={this.unlockedHabits}
                  goalsActive={false}
                  inTutorial={inTutorial}
                  goals={currentUser.goals}
                  habits={
                    !inTutorial
                      ? currentUser.habits.filter((h) => h.active)
                      : DemoData.habits
                  }
                  color={theme.menu_dark_item_active_bg}
                  questions={
                    currentUser.questions && currentUser.questions.length
                  }
                  currentUserId={currentUser.id}
                />
              </Col>
            </Row>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={14}
            style={{ height: "100%" }}
          >
            <Row>
              <Feature feature="OBJECTIVES">
                <Col
                  style={{
                    height: mobile ? "auto" : "315px",
                    marginBottom: 24,
                    minHeight: 315
                  }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  <Objectives />
                </Col>
              </Feature>
              {!features.includes("NO_HIGHFIVES") && (
                <Col
                  style={{
                    minHeight: features.includes("OBJECTIVES") && "414px",
                    height: mobile
                      ? "auto"
                      : features.includes("OBJECTIVES")
                      ? "calc((100vh - 315px - 24px - 24px - 24px - 64px)"
                      : "calc((100vh - 48px - 64px) "
                  }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  <Feed
                    showLeaderboard={features.includes("LEADERBOARD")}
                    showBadges={
                      features.includes("BADGES") &&
                      !features.includes("LEADERBOARD")
                    }
                    canAnnounce={features.find((u) => u === "ANNOUNCEMENT")}
                    inTutorial={inTutorial}
                    demoData={DemoData.highFives}
                    orgName={currentUser.organization_name}
                    currentUserId={currentUser.id}
                    suggestionManagers={
                      currentUser.organization.suggestion_manager
                    }
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        {/* <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Habits
              goalsActive={
                !inTutorial &&
                currentUser.organization.features.includes('GOALS')
              }
              inTutorial={inTutorial}
              goals={currentUser.goals}
              habits={!inTutorial ? currentUser.habits : DemoData.habits}
              color={theme.primary_color}
              questions={currentUser.questions && currentUser.questions.length}
              currentUserId={currentUser.id}
            />
          </Col>
          {!features.includes('NO_HIGHFIVES') && (
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <Feed
                canAnnounce={features.find((u) => u === 'ANNOUNCEMENT')}
                inTutorial={inTutorial}
                demoData={DemoData.highFives}
                orgName={currentUser.organization_name}
                currentUserId={currentUser.id}
                suggestionManagers={currentUser.organization.suggestion_manager}
              />
            </Col>
          )} */}
      </Body>
    );
  }
}

export default withTranslation()(withRouter(withTheme(HomePage)));
