/* eslint-disable no-debugger */
import React from "react";
import PropTypes from "prop-types";
import BlogPost from "assets/computer.jpg";
import WidgetHeader from "../WidgetHeader";
import { WidgetContainer } from "../styles";

const BlogPostWidget = ({ w, overflow, font }) => {
  const replaceImg = (e) => {
    e.target.src = BlogPost;
  };
  return (
    <WidgetContainer id={w.id}>
      <WidgetHeader
        font={font}
        overflow={overflow}
        desc={w.description}
        title={w.title}
        icon={
          <img
            onError={replaceImg}
            alt="Blog Post Img"
            style={{
              width: "50px",
              height: "auto"
            }}
            src={w.image_url}
          ></img>
        }
      />
    </WidgetContainer>
  );
};

BlogPostWidget.propTypes = {
  font: PropTypes.object.isRequired,
  w: PropTypes.object.isRequired,
  overflow: PropTypes.bool.isRequired
};

export default BlogPostWidget;
