import React, { useEffect } from "react";
import { Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";
import useStores from "utils/useStores";
import Box from "layout/Box";
import LessonCard from "./LessonCard";
import { useFetchCompletedLessonsQuery } from "./queries/fetchCompletedLessons.generated";

interface Props {
  visible: boolean;
  onCancel: () => void;
}

export type ArticulateLesson = {
  name: string;
  img: string;
  courseId: string;
  url: string;
  disabled: boolean;
};

const ArticulateLessonModal: React.FC<Props> = ({ visible, onCancel }) => {
  const { t } = useTranslation();
  const {
    profileStore: { currentUser }
  } = useStores();
  const { data, loading, error, refetch } = useFetchCompletedLessonsQuery();

  const lessons: ArticulateLesson[] = [
    {
      name: "Administrative tasks that you need to complete",
      courseId: "yqoMKedVmBmgWmbpAkUMCmllakmGhGFP",
      url: "https://learning.rhabitapp.com/admin-training/",
      disabled: false,
      img:
        "https://images.articulate.com/f:jpg,b:fff,s:cover,w:340/rise/courses/rOOPQfhjFTGPXYArl3QSMEV1YKFVGsxh/5PBleNz0_foeVpPX.jpg"
    },
    {
      disabled: data
        ? !data.profile.completedArticulateLessons.find(
            (a) => a.courseId === "yqoMKedVmBmgWmbpAkUMCmllakmGhGFP"
          )
        : true,
      name: "Things that you need to help others with: Manager Edition",
      img:
        "https://images.articulate.com/f:jpg,b:fff,s:cover,w:340/rise/courses/rOOPQfhjFTGPXYArl3QSMEV1YKFVGsxh/5PBleNz0_foeVpPX.jpg",
      courseId: "rOOPQfhjFTGPXYArl3QSMEV1YKFVGsxh",
      url: "https://learning.rhabitapp.com/manager-training/"
    },

    {
      disabled: data
        ? !data.profile.completedArticulateLessons.find(
            (a) => a.courseId === "rOOPQfhjFTGPXYArl3QSMEV1YKFVGsxh"
          )
        : true,
      name: "Things that you need to help others with: IC Edition",
      courseId: "sIKnWH2vIpRQq-ArW9uEkrpQS3YIoyn0",
      img:
        "https://images.articulate.com/f:jpg,b:fff,s:cover,w:340/rise/courses/rOOPQfhjFTGPXYArl3QSMEV1YKFVGsxh/5PBleNz0_foeVpPX.jpg",
      url: "https://learning.rhabitapp.com/ic-training/"
    }
  ];

  useEffect(() => {
    if (visible) {
      const i = setInterval(() => {
        refetch();
      }, 1000);
      return () => {
        clearInterval(i);
      };
    }
  }, [visible]);

  return (
    <Modal visible={visible} onCancel={onCancel} width="925px" onOk={onCancel}>
      <Typography.Title level={2}>
        {t("articulate.modal_title")}
      </Typography.Title>
      <Box
        width="100%"
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        {lessons.map((lesson) => (
          <LessonCard
            disabled={lesson.disabled}
            lesson={lesson}
            key={lesson.courseId}
            completed={
              data
                ? !!data.profile.completedArticulateLessons.find(
                    (a) => a.courseId === lesson.courseId
                  )
                : false
            }
          />
        ))}
      </Box>
    </Modal>
  );
};

export default ArticulateLessonModal;
