import React from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import { observer } from "mobx-react";
import { List, Spin } from "antd";
import LoadContainer from "./styles";
import HighFiveItem from "./HighFiveItem";
import BadgeUnlockItem from "./BadgeUnlockItem";

const FeedList = ({
  feed,
  loading,
  hasMore,
  loadMore,
  onLike,
  handleDelete,
  currentUserId,
  addComment
}) => {
  const getComponent = (item) => {
    switch (item.type) {
      case "highfive":
        return (
          <HighFiveItem
            highfive={item}
            addComment={(text, mentions) => addComment(text, mentions, item)}
            onLike={() => onLike(item)}
            currentUserId={currentUserId}
            handleDelete={handleDelete}
          />
        );
      case "badge_unlock":
        return (
          <BadgeUnlockItem
            badgeUnlock={item}
            addComment={(text, mentions) => addComment(text, mentions, item)}
            onLike={() => onLike(item)}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <InfiniteScroll
      initialLoad={false}
      pageStart={1}
      loadMore={loadMore}
      hasMore={!loading && hasMore}
      useWindow={false}
    >
      <List loading={loading} dataSource={feed} renderItem={getComponent}>
        {loading && hasMore && (
          <LoadContainer>
            <Spin />
          </LoadContainer>
        )}
      </List>
    </InfiniteScroll>
  );
};

FeedList.propTypes = {
  feed: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func.isRequired,
  onLike: PropTypes.func.isRequired,
  currentUserId: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  addComment: PropTypes.func.isRequired
};

FeedList.defaultProps = {
  hasMore: null
};

export default observer(FeedList);
