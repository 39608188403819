import { IHabitCategory } from "shared/types";
import i18n from "utils/i18n";

export const CATEGORIES: {
  name: IHabitCategory;
  color: string;
  description: string;
  style: React.CSSProperties;
}[] = [
  {
    name: IHabitCategory.Core,
    description: "habit_picker.core_description",
    color: "#2F7920",
    style: {
      top: 0,
      left: 0
    }
  },
  {
    name: IHabitCategory.People,
    description: "habit_picker.people_description",
    color: "#D36B30",
    style: {
      top: 0,
      right: 0
    }
  },
  {
    name: IHabitCategory.Technical,
    description: "habit_picker.technical_description",
    color: "#349980",
    style: {
      bottom: 0,
      left: 0
    }
  },
  {
    name: IHabitCategory.Results,
    description: "habit_picker.results_description",
    color: "#39473F",
    style: {
      bottom: 0,
      right: 0
    }
  }
];
