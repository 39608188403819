import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { NavLink } from "react-router-dom";
import MiniSteps from "components/MiniSteps";
import {
  Wrapper,
  Header,
  TableHeader,
  Description,
  Due,
  Progress,
  TableContent,
  GoalItem
} from "./styles";

const GoalContent = ({ goals }) => (
  <NavLink to="/goals" style={{ color: "inherit" }}>
    <Wrapper>
      <Header>Goals</Header>
      <TableHeader>
        <Description header>Description</Description>
        <Due header>Due</Due>
        <Progress header>Progress</Progress>
      </TableHeader>
      <TableContent>
        {goals.map((goal) => (
          <GoalItem key={goal.id}>
            <Description>{goal.name}</Description>
            <Due>{moment(goal.due_date).format("MM/DD")}</Due>
            <Progress>
              <MiniSteps checkIns={goal.check_ins} />
            </Progress>
          </GoalItem>
        ))}
      </TableContent>
    </Wrapper>
  </NavLink>
);

GoalContent.propTypes = {
  goals: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
};

export default GoalContent;
