/* eslint-disable no-useless-escape */

/* eslint-disable no-useless-return */

/* eslint-disable no-underscore-dangle */

/* eslint-disable func-names */

/* eslint-disable no-param-reassign */

/* eslint-disable no-unused-vars */

/* eslint-disable no-debugger */

/* eslint-disable no-plusplus */

/* eslint-disable import/prefer-default-export */
import { alphabet } from "../alphabet";

const getDomElements = () => {
  const tooltip = document.querySelector("#radarChart > div:nth-child(2) > div > div");
  const title = document.querySelector(
    "#radarChart > div:nth-child(2) > div > div > div > div > strong"
  );
  const scoreColor = document.querySelector(
    "#radarChart > div:nth-child(2) > div > div > div > div > table > tbody > tr > td:nth-child(1) > span"
  );
  const table = document.querySelector(
    "#radarChart > div:nth-child(2) > div > div > div > div > table > tbody"
  );
  const score = document.querySelector(
    "#radarChart > div:nth-child(2) > div > div > div > div > table > tbody > tr > td:nth-child(2)"
  );

  const percent = document.querySelector(
    "#radarChart > div:nth-child(2) > div > div > div > div > table > tbody > tr > td:nth-child(3)"
  );

  const row = document.querySelector(
    "#radarChart > div:nth-child(2) > div > div > div > div > table > tbody > tr"
  );

  return { tooltip, title, scoreColor, table, score, percent, row };
};

let gTooltipMap = {};

const setDomElements = (data, ttMap) => {
  const { tooltip, title, scoreColor, table, score, percent, row } = data;
  const index = title.innerText;
  title.innerText = ttMap[index].desc;
  scoreColor.style.background = ttMap[index].color;
  tooltip.style.width = "175px";
  table.style.display = "flex";
  score.style.paddingRight = `3px`;
  score.innerText = `score:`;
  percent.style.paddingLeft = `0px`;
  row.style.display = "flex";
  row.style.alignItems = "center";
};

const tooltipEventListener = () => {
  const data = getDomElements();
  const { title } = data;
  if (title) {
    if (alphabet.includes(title.innerText)) {
      console.log(gTooltipMap);
      setDomElements(data, gTooltipMap);
    }
  }
};

export const setTooltipListeners = tooltipMap => {
  const tooltipRanges = document.querySelector(
    "#radarChart > div:nth-child(2) > div > svg > g > g > g:nth-child(3)"
  );
  if (tooltipRanges && tooltipRanges.children && tooltipRanges.children[0]) {
    console.log("added event listeners");
    gTooltipMap = tooltipMap;
    Array.from(tooltipRanges.children).forEach(c => {
      c.addEventListener("mouseenter", () => {
        tooltipEventListener();
      });
    });
  }
};
const setNewEventListeners = (elements, i, getDot) => {
  const loc = elements.childElementCount - 1;
  const e = elements;
  e.children[loc - i].style.pointerEvents = "auto";
  e.children[loc - i].children[0].style.pointerEvents = "auto";
  e.children[loc - i].children[1].style.pointerEvents = "auto";
  e.children[loc - i].children[0].style.cursor = "pointer";
  e.children[loc - i].children[1].style.cursor = "pointer";
  e.children[loc - i].addEventListener("click", () => {
    getDot(loc - i);
  });
};

const setListenersOnMount = (elements, getDot) => {
  Array.from(elements.children).forEach((e, i) => {
    const func = () => {
      getDot(i);
    };
    e.style.pointerEvents = "auto";
    e.addEventListener("click", func);
    e.children[0].style.pointerEvents = "auto";
    e.children[1].style.pointerEvents = "auto";
    e.children[1].style.cursor = "pointer";
    e.children[0].style.cursor = "pointer";
  });
};

export const setEventListeners = (eventState, currentState, lastDotCount, getDot) => {
  const [eventsSet, setEventsSet] = eventState;
  const [currentDotCount, setCurrentDotCount] = currentState;
  const elements = document.querySelector(
    "#radarChart > div > div > svg > g > g > g:nth-child(4) "
  );

  setCurrentDotCount(elements ? elements.childElementCount : 0);
  if (!eventsSet && elements && elements.childElementCount > 0) {
    setEventsSet(true);
    setListenersOnMount(elements, getDot);
  }
  if (
    eventsSet &&
    elements &&
    elements.childElementCount > 0 &&
    lastDotCount > 0 &&
    lastDotCount < currentDotCount
  ) {
    for (let i = 0; i < currentDotCount - lastDotCount; i++) {
      setNewEventListeners(elements, i, getDot);
    }
  }
};
