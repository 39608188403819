import React from "react";
import groupBy from "lodash/groupBy";
import moment from "moment";
import { IScore } from "shared/types";
import { HabitUser } from "./UserHabits";
import ArrowUp from "./arrow-up.svg";
import ArrowFlat from "./arrow-flat.svg";
import ArrowDown from "./arrow-down.svg";
import useStores from "utils/useStores";

interface Props {
  habitUsers: HabitUser[];
  small: boolean;
}

const scoreReducer = (acc: IScore[], currentValue: HabitUser): IScore[] =>
  [...acc, ...currentValue.mostRecentScores] as IScore[];

const scoreAverageReducer = (acc: number, currentValue: IScore): number =>
  acc + currentValue.value;

const getScoreAverage = (scores: IScore[]): number => {
  return Math.round(scores.reduce(scoreAverageReducer, 0) / scores.length);
};

const buildTrendArrows = (
  habitUsers: HabitUser[],
  small: boolean
): JSX.Element[] => {
  const up = (
    <img
      src={ArrowUp}
      alt="arrow-up"
      style={{ width: small ? "26px" : "45px" }}
    />
  );
  const flat = (
    <img
      src={ArrowFlat}
      alt="arrow-flat"
      style={{ width: small ? "18px" : "32px", margin: "0 4px" }}
    />
  );
  const down = (
    <img
      src={ArrowDown}
      alt="arrow-down"
      style={{ width: small ? "26px" : "45px" }}
    />
  );

  const allScores = habitUsers.reduce(scoreReducer, [] as IScore[]);
  const groupedScores = groupBy(
    allScores,
    (score) =>
      `${moment(score.createdAt, "YYYY-MM-DD HH:mm:ss z").year()}-${moment(
        score.createdAt,
        "YYYY-MM-DD HH:mm:ss z"
      ).week()}`
  );
  const averages: { [key: string]: number } = {};
  Object.keys(groupedScores).forEach(
    (k) =>
      (averages[parseInt(k.replace("-", ""), 10)] = getScoreAverage(
        groupedScores[k]
      ))
  );

  const sortedKeys = Object.keys(averages).sort();
  let previousKey = sortedKeys[0];
  if (!previousKey) return [];
  const trend: JSX.Element[] = [];
  sortedKeys.slice(1).forEach((key) => {
    if (averages[key] > averages[previousKey]) {
      trend.push(up);
    } else if (averages[key] < averages[previousKey]) {
      trend.push(down);
    } else {
      trend.push(flat);
    }
    previousKey = key;
  });
  return trend;
};

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const fakeTrendArrow = () => {
  const small = false;
  const up = (
    <img
      src={ArrowUp}
      alt="arrow-up"
      style={{ width: small ? "26px" : "45px" }}
    />
  );
  const flat = (
    <img
      src={ArrowFlat}
      alt="arrow-flat"
      style={{ width: small ? "18px" : "32px", margin: "0 4px" }}
    />
  );
  const down = (
    <img
      src={ArrowDown}
      alt="arrow-down"
      style={{ width: small ? "26px" : "45px" }}
    />
  );
  const lookup = {
    1: up,
    2: flat,
    3: down
  };

  const arr = [];

  for (let i = 0; i < 4; i += 1) {
    arr.push(lookup[randomIntFromInterval(1, 3)]);
  }
  return arr;
};

const HabitTrend: React.FC<Props> = ({ habitUsers, small }) => {
  const {
    profileStore: { currentUser }
  } = useStores();

  const isDemo =
    currentUser.organization.name === "Meta Co" ||
    currentUser.organization.name === "Hopp Demo";

  const trendArrows = isDemo
    ? fakeTrendArrow()
    : buildTrendArrows(habitUsers, small);
  return (
    <div style={{ display: "flex", flexShrink: 0 }}>
      {trendArrows.length > 0 ? trendArrows : <span>Not Enough Data</span>}
    </div>
  );
};

export default HabitTrend;
