import React, { useState, useEffect } from "react";
import { Modal, Input, Form, Icon, Button } from "antd";
import ProfileStore from "stores/profileStore";
import PropTypes from "prop-types";

const SupportLogin = ({ form }) => {
  const [error, setError] = useState(false);
  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (!err) {
        try {
          ProfileStore.login(values);
        } catch {
          setError(true);
        }
      }
    });
  };
  useEffect(() => {
    const func = (e) => {
      if (e.keyCode === 13) {
        handleSubmit();
      }
    };

    window.addEventListener("keyup", func);
    return () => {
      window.removeEventListener("keyup", func);
    };
  }, []);
  const { getFieldDecorator } = form;

  return (
    <Modal
      visible
      title="Support Log-in"
      okText="Log In"
      footer={[
        <Button type="primary" onClick={handleSubmit}>
          Log In
        </Button>
      ]}
    >
      {error && <span style={{ color: "red" }}>Invalid email/password</span>}
      <Form>
        <Form.Item>
          {getFieldDecorator("email", {
            rules: [{ required: true, message: "Please input your email!" }]
          })(
            <Input
              autoFocus
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Email"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input your Password!" }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

SupportLogin.propTypes = {
  form: PropTypes.object.isRequired
};

export default Form.create()(SupportLogin);
