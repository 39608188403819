import React from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from "@nivo/bar";

const colors = {
  bad: "#FF9331",
  average: "#FDCE60",
  good: "#78D36F"
};

const generateToolTip = (params, data) => {
  let text;
  if (params.id === "bad") {
    text = `${data[params.id].users.length} users below average`;
  } else if (params.id === "average") {
    text = `${data[params.id].users.length} users average`;
  } else {
    text = `${data[params.id].users.length} users above average`;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", width: 200 }}>
      <span style={{ fontWeight: "bolder" }}>{text}</span>
      {data[params.id].users.map((u) => (
        <span>{u}</span>
      ))}
    </div>
  );
};

const HorizontalBar = (props) => {
  const { data, fullWidth } = props;
  const dataValid = !!data;
  let dataSource;
  if (dataValid) {
    dataSource = [
      Object.entries(data).reduce((o, e) => {
        // eslint-disable-next-line no-param-reassign
        o[e[0]] = e[1].score;
        return o;
      }, {})
    ];
  }

  return (
    <div style={{ height: 20, width: fullWidth ? "100%" : 150 }}>
      {dataValid ? (
        <ResponsiveBar
          layout="horizontal"
          height={20}
          data={dataSource}
          animate={false}
          colors={({ id }) => colors[id]}
          label={({ value }) => `${Math.round(value)}%`}
          keys={["bad", "average", "good"]}
          tooltip={(params) => generateToolTip(params, data)}
        />
      ) : (
        <div style={{ textAlign: "center", width: "100%" }}>No Data</div>
      )}
    </div>
  );
};

HorizontalBar.defaultProps = {
  fullWidth: false
};

HorizontalBar.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  fullWidth: PropTypes.bool
};

export default HorizontalBar;
