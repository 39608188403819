import React from "react";
import { Input } from "antd";
import PropTypes from "prop-types";

class PhoneNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = { code: Array(6).fill(""), hasSubmitted: false };
  }

  onChange = (e) => {
    const { submit, setCode } = this.props;
    const { code, hasSubmitted } = this.state;
    const key = e.target.id.split("-")[1];
    if ((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 8) {
      if (e.keyCode === 8 && key) {
        const doc = document.getElementById(`input-${parseInt(key, 10) - 1}`);

        doc.focus();
        doc.select();
      }
      code[key] = String.fromCharCode(e.keyCode);
      this.setState({ code });
      setCode(code.join(""));
      if (key !== "5" && e.keyCode !== 8) {
        const doc = document.getElementById(`input-${parseInt(key, 10) + 1}`);

        doc.focus();
        doc.select();
      } else if (!hasSubmitted && key === "5") {
        submit(code.join(""));
        this.setState({ hasSubmitted: true });
      }
    }
  };

  render() {
    const { code } = this.state;
    return (
      <div
        style={{
          display: "flex",
          width: "90%",
          margin: "0 auto",
          justifyContent: "space-between"
        }}
      >
        <Input
          size="large"
          onFocus={(e) => {
            e.target.select();
          }}
          style={{ textAlign: "center", width: 35 }}
          id="input-0"
          onKeyDown={this.onChange}
          key={0}
          value={code[0]}
        />
        <Input
          size="large"
          style={{ textAlign: "center", width: 35 }}
          id="input-1"
          onKeyDown={this.onChange}
          key={1}
          value={code[1]}
        />
        <Input
          size="large"
          style={{ textAlign: "center", width: 35 }}
          id="input-2"
          onKeyDown={this.onChange}
          key={2}
          value={code[2]}
        />
        <Input
          size="large"
          style={{ textAlign: "center", width: 35 }}
          id="input-3"
          onKeyDown={this.onChange}
          key={3}
          value={code[3]}
        />
        <Input
          size="large"
          style={{ textAlign: "center", width: 35 }}
          id="input-4"
          onKeyDown={this.onChange}
          key={4}
          value={code[4]}
        />
        <Input
          size="large"
          style={{ textAlign: "center", width: 35 }}
          id="input-5"
          onKeyDown={this.onChange}
          key={5}
          value={code[5]}
        />
      </div>
    );
  }
}

PhoneNumber.propTypes = {
  submit: PropTypes.func.isRequired,
  setCode: PropTypes.func.isRequired
};

export default PhoneNumber;
