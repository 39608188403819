import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const User = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  opacity: ${props => props.active ? 1 : 0.4};
  text-align: center;
  margin-bottom: 12px;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  cursor: ${props => props.clickable ? 'pointer' : 'default'};
  &:hover {
    opacity: ${props => props.clickable && '1'};
  }
`;

export const Name = styled.span`
  font-size: 14;
  color: rgba(0, 0, 0, 0.6);
`;