import { createGlobalStyle } from "styled-components";
import { media } from "utils/style-utils";

const Global = createGlobalStyle`
  .ant-card-actions > li {
    height: 36px;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .prompt-selector {
    .ant-select-dropdown-menu-item {
      overflow: unset;
      white-space: unset;
      text-overflow: unset;
    }
  }

  .objective-name-cell { 
    display: flex;
    height: 65px;
    align-items: center;
  }

  #content-body > div > div > div:nth-child(4) > div > div.ant-spin-nested-loading > div > div > div > div.ant-table-fixed-left > div > div > table > thead > tr {
        height: 67px !important;
  }

  .react-editor {
    border-bottom: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    height: calc(100% - 130px) !important;
    padding: 12px;
  }

  .react-editor-wrapper { 
    height: 100%;
  }
  .react-editor1 { 
    height: 100%;
    overflow-y: hidden !important;
  }
  .react-editor-toolbar { 
    margin-bottom: 0 !important;
  }

  .ant-card-actions > li > span{
    line-height: 54px !important;
    width: 100%;
    height: 100%;
  }

  .vis-network {
    background-color: #FFFFFF;
  }

  .selected-row {
    background-color: #cfe6d5;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-upload-list {
    display: none;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-carousel .slick-list .slick-slide {
    pointer-events: auto !important;
  }

  .slick-slide > div {
    ${media.handheld`
      display: flex;
      justify-content: center;
    `}
  }

  .ant-select-dropdown-menu-item-group-title {
    padding: 0;
    height: unset;
  }

  .ant-cascader-menu { 
   height: unset !important;
   font-size: 16px;
  }

  .no-border-radius > .ant-modal-content {
    border-radius: 0;
  }

  .ant-modal-content { 
    border-radius: 10px;
  }

  .ant-modal-header { 
    border-radius: 10px 10px 0 0;
  }
  .ant-modal-footer { 
    border-radius: 0 0 10px 10px;
  }

  .notification-dropdown {
      background-color: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
  border-radius: 4px;

  }

  .ant-card {
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
  }

  .ant-list-item-meta-content {
    max-width: 100%;
    ${media.handheld`
      max-width: calc(100% - 48px);
    `}
  }

  .ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
    -webkit-transition: margin-left 0.15s ease-in-out;
    -moz-transition: margin-left 0.15s ease-in-out;
    -o-transition: margin-left 0.15s ease-in-out;
    transition: margin-left 0.15s ease-in-out;
  }

  .ant-upload.ant-upload-select-picture-card {
    border-radius: 50% !important;
  }

  .image-uploader {
    .ant-upload.ant-upload-select-picture-card {
      border-radius: 4px !important;
      height: 140px;
      width: 140px;
    }
  }
  .expandable {
    transition: 0.2s;
  }

  .expandable > .ant-modal-body {
    transition: 0.2s;
  }

  .arrowless-dropdown > .ant-popover-content > .ant-popover-arrow {
    display: none;
  }
.no-padding-menu > div > div > div > ul {
  padding-left: 0;
  margin-bottom: 0;
}
  .arrowless-dropdown {
    padding-top: 2px !important;
  }

  

  .ant-menu-vertical {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  }

  

  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
  }

  .custom-filter-dropdown input {
    width: 130px;
    margin-right: 8px;
  }

  .custom-filter-dropdown button {
    margin-right: 8px;
  }

  .highlight {
    color: #f50;
  }

  html {
    scroll-behavior: smooth;
    overflow: hidden;
    background: #F0F2F5;
    height: 100%;
      ${media.handheld`
      overflow: unset;
      height: unset;
    `}
  }

  body {
    overscroll-behavior-y: contain;
		overflow-y: auto;
		overflow-x: hidden;
    width: 100% !important;
    height: 100%;
    ${media.handheld`
      overflow: unset;
      height: unset;
    `}

    @media print {
      height: auto;
    }
  }
  .ant-input-group-addon {
    padding:0 !important;
  }

  .tiny-progress-circle .ant-progress-text {
    padding:0 !important;
    font-size: 0.8em !important;
    color: rgba(0, 0, 0, 0.65) !important;
  }

  .overflow-scroll > .ant-select-selection {
    max-height:100px;
    overflow:scroll;
  }

  .widget-card .ant-card-head {
    padding: 0px !important;
  }

  .feedback-card .ant-card-head-title {
    padding: 0px !important;
    overflow:unset;
  }

  .feedback-card .ant-card-actions, .feedback-card li {
    border:none !important;
    background:white;
    height:75px;
  }
  .feedback-card .ant-card-actions, .feedback-card ul {
    position: absolute;
    bottom: 15px;
    width: 100%;
    ${media.iphone5`
      bottom:0px;
    `}
  }

  .feedback-progress .ant-progress-inner {
    background-color:rgba(0, 167, 79, .25);
  }

  .feedback-card-divider .ant-divider-inner-text {
    font-size: 12px;
    font-weight:lighter;
    background:rgba(0, 167, 79, .25);
    border-radius:10px;
  }

  .widget-card .ant-card-head-title {
    white-space: normal;
    padding: 0px 6px;
    text-align:left;
  }

  .ant-notification {
    z-index:1000 !important;
  }

  .unassigned-users .ant-list-item-content-single {
    justify-content:space-between !important;
  }

  .onboarding-input input {
    font-size:25px;
    margin-right:65px;
  }
  .onboarding-input button {
    width:65px;
    height:50px;
    font-size:25px;
  }

  .mailto .ant-modal-confirm-content {
    margin-left:0px !important;
  }
  .mailto .ant-collapse-content-box {
    display:flex;
    flex-direction:column;
  }

  .copy-to-clipboard button {
    border: none;
    box-shadow: none;
    width:50px;
  }
  .copy-to-clipboard .ant-input-search-button {
    padding:0px;
  }


  .card-no-footer .ant-card-actions, .card-no-footer ul {
    border:none !important;
  }
  .card-no-footer .ant-card-actions, .card-no-footer li {
    width:auto !important;
    background:white;
    float:right;
  }
  .card-no-footer .ant-card-actions > li > span {
    line-height:unset !important;
  }

  .post-rating .ant-radio-wrapper {
    white-space: unset !important;
    display: flex !important;
    align-items: center !important;
    margin: 5px 0px;
  }

  .tabbed-card .ant-tabs {
    position:absolute !important;
    right:24px !important;
  }
  .tabbed-card .ant-tabs-tab {
    cursor:pointer !important;
  }

  .bordered-table {
    
    width: 100%;
  }

  .bordered-table table {
    border-radius: 6px !important;
  }
  #improvement-badge {
    width:100% !important;
  }
  #improvement-badge .ant-badge-count {
    right:36px !important;
    top:12px !important;
  }
`;

export default Global;
