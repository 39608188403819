import * as React from "react";
import { withTheme } from "styled-components";
import { Button, Modal } from "antd";
import UserAvatar from "components/UserAvatar";
import { HabitUserLite, UserLite } from "types/shared";
import Wrapper from "../../../layout/Wrapper";
import Cascader from "./cascader";
import InverseHabitSelector from "./inverseHabitSelector";

import { UserName, Title, Banner, Card, Tag } from "./styles";
import { useTranslation } from "react-i18next";

type RelationshipCardProps = {
  user: UserLite;
  habits: HabitUserLite[];
  allHabits: HabitUserLite[];
  providingHabits: HabitUserLite[];
  otherUserHabits: HabitUserLite[];
  persistChanges: (
    id: string,
    habitUserIds: string[],
    external: boolean
  ) => void;
  persistInverseChanges: (
    userId: string,
    id: string,
    habitUserIds: string[]
  ) => void;
  inverseRelationshipId: string;
  id: string;
  index: number;
  hovering: boolean;
  handleDelete: (id: string, external: boolean) => void;
  theme: any;
  acceptStatus: null | boolean;
  external?: boolean;
  isAdmin?: boolean;
  isNew: boolean;
};

const { useState, useEffect } = React;
const RelationshipCard = React.memo(
  ({
    user,
    habits,
    allHabits,
    providingHabits,
    otherUserHabits,
    persistChanges,
    persistInverseChanges,
    inverseRelationshipId,
    id,
    index,
    hovering,
    handleDelete,
    theme,
    acceptStatus,
    external = false,
    isNew,
    isAdmin = false
  }: RelationshipCardProps) => {
    const [selected, setSelected] = useState([]);
    const [inverseSelected, setInverseSelected] = useState([]);
    const [cardHovering, setHovering] = useState(false);
    const [color, setColor] = useState(null);
    const { t } = useTranslation();
    const COLORS = [
      theme.secondary_color,
      theme.extra_color_0,
      theme.extra_color_1,
      theme.extra_color_2,
      theme.extra_color_3,
      theme.primary_color
    ];

    const setSelectionState = (
      currentHabits: HabitUserLite[],
      allPossibleHabits: HabitUserLite[],
      setSelectedHabits: (habitIds: string[]) => void
    ): void => {
      if (currentHabits.length === allPossibleHabits.length) {
        setSelectedHabits(allPossibleHabits.map((h) => h.id));
      } else if (currentHabits.length === 0) {
        setSelectedHabits([]);
      } else {
        setSelectedHabits(currentHabits.map((h) => h.id));
      }
    };

    useEffect(() => {
      setColor(COLORS[(index + 1) % COLORS.length]);
      setSelectionState(habits, allHabits, setSelected);

      if (isAdmin) {
        setSelectionState(providingHabits, otherUserHabits, setInverseSelected);
      }
    }, []);

    const saveChanges = (ids: string[]) => {
      persistChanges(id, ids, external);
      setSelected(ids);
    };

    const saveInverseChanges = (ids: string[]) => {
      persistInverseChanges(user.id, inverseRelationshipId, ids);
      setInverseSelected(ids);
    };

    const confirmDelete = () => {
      const name = user.name.split(" ")[0];
      let content;
      if (external) {
        content = t("relationships.name_give_you_feedback", { name });
      } else {
        content = t("relationships.name_give_feedback_eachother", { name });
      }

      Modal.confirm({
        title: t("relationships.confirm_remove", { userName: user.name }),
        onOk: () => handleDelete(id, external),
        content: t("relationships.will_no_longer_ask", { content })
      });
    };

    const getExternalStatus = (): React.ReactElement<"span"> => {
      if (!external) return <span />;
      const style = { textAlign: "center" };
      let options = { color: "red", label: t("rejected") };
      if (acceptStatus === null) {
        options = { color: "inherit", label: t("pending") };
      }
      if (acceptStatus) {
        options = { color: "00a74f", label: t("accepted") };
      }
      return (
        <span style={{ ...style, color: options.color }}>{options.label}</span>
      );
    };

    return (
      <Card
        id={`card-${index}`}
        data-cy="relationship-card"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        hovering={hovering}
      >
        {isNew && <Tag color="white">{t("new")}</Tag>}
        {cardHovering && (
          <Button
            data-cy="delete-relationship"
            onClick={confirmDelete}
            style={{ position: "absolute", top: 12, right: 12, zIndex: 2 }}
            shape="circle"
            icon="delete"
          ></Button>
        )}
        <Banner color={color} />
        <UserAvatar
          avatar={user.avatar}
          name={user.name}
          size="large"
          bordered
          fontSize={28}
          lineHeight={70}
          style={{
            marginRight: 12,
            position: "absolute",
            width: 75,
            height: 75,
            top: 22.5,
            left: "calc(50% - 37.5px)"
          }}
        />
        <Wrapper style={{ height: "100%", justifyContent: "space-between" }}>
          <Wrapper>
            <UserName>{user.name}</UserName>
            <Title>{user.title}</Title>
            {getExternalStatus()}
          </Wrapper>

          <InverseHabitSelector
            persistChanges={saveInverseChanges}
            providingHabits={providingHabits}
            isAdmin={isAdmin}
            external={external}
            allHabits={otherUserHabits}
            selected={inverseSelected}
          />
          <Wrapper style={{ alignItems: "center" }}>
            <div style={{ fontStyle: "italic" }}>
              {" "}
              {t("relationships.provides_you_feedback_on")}
            </div>
            <div
              id="relationship-modal"
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center"
              }}
            >
              <Cascader
                allHabits={allHabits}
                selected={selected}
                persistChanges={saveChanges}
              />
            </div>
          </Wrapper>
        </Wrapper>
      </Card>
    );
  }
);

export default withTheme(RelationshipCard);
