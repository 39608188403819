import styled from "styled-components";

export const ButtonWrapper = styled.div`
  width: 250px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  width: 100%;
`;

export const ObjectiveItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;

export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: center;
`;

export const Actions = styled.div`
  display: flex;
  width: 40%;
  justify-content: space-between;
  padding-right: 4px;
  margin: 0 auto;
`;

export const ObjectiveInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 6px;
`;

export const Owner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const OwnerName = styled.div`
  text-align: center;
`;

export const ContentWrapper = styled.div``;