import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  border: 1px solid #e8e8e8;
  border-radius: 4px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  width: 15%;
  border-bottom: 1px solid #e8e8e8;
`;

export const HeaderText = styled.span`
  font-size: ${props => props.large ? '24px' : '18px'};
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
`;

export const Body = styled.div`
  height: calc(100% - 75px);
  width: 85%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
`;

export const Left = styled.div`
  width: 70%;
  height: 100%;
  background: #f2f2f2;
  display: flex;
  flex-direction: ${props => props.row ? 'row' : 'column'};
`;

export const Right = styled.div`
  width: 15%;
  height: 100%;
  padding: 64px;
  padding-top: 100px;
  position: relative;
`;

export const HabitText = styled.div`
  position: absolute;
  transform: rotate(-90deg);
  left: -70px;
  top: 205px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6);
`;

export const GoalText = styled.div`
  position: absolute;
  left: calc(50% - 97px);
  top: 55px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6);
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  height: 50%;
  min-height: 412px;
`;