/* eslint-disable */
import * as Types from '../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type IFetchEngagementTrendDemoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IFetchEngagementTrendDemoQuery = (
  { __typename?: 'Query' }
  & { managerDashboard: (
    { __typename?: 'ManagerDashboardDemo' }
    & Pick<Types.IManagerDashboardDemo, 'engagementRecent' | 'engagementPast' | 'engagementOverall' | 'engagementMinMax' | 'highestLowestDeltas' | 'companyComparison'>
  ) }
);


export const FetchEngagementTrendDemoDocument = gql`
    query fetchEngagementTrendDemo {
  managerDashboard: managerDashboardDemo {
    engagementRecent
    engagementPast
    engagementOverall
    engagementMinMax
    highestLowestDeltas
    companyComparison
  }
}
    `;

/**
 * __useFetchEngagementTrendDemoQuery__
 *
 * To run a query within a React component, call `useFetchEngagementTrendDemoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEngagementTrendDemoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEngagementTrendDemoQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchEngagementTrendDemoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IFetchEngagementTrendDemoQuery, IFetchEngagementTrendDemoQueryVariables>) {
        return ApolloReactHooks.useQuery<IFetchEngagementTrendDemoQuery, IFetchEngagementTrendDemoQueryVariables>(FetchEngagementTrendDemoDocument, baseOptions);
      }
export function useFetchEngagementTrendDemoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IFetchEngagementTrendDemoQuery, IFetchEngagementTrendDemoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IFetchEngagementTrendDemoQuery, IFetchEngagementTrendDemoQueryVariables>(FetchEngagementTrendDemoDocument, baseOptions);
        }
export type FetchEngagementTrendDemoQueryHookResult = ReturnType<typeof useFetchEngagementTrendDemoQuery>;
export type FetchEngagementTrendDemoLazyQueryHookResult = ReturnType<typeof useFetchEngagementTrendDemoLazyQuery>;
export type FetchEngagementTrendDemoQueryResult = ApolloReactCommon.QueryResult<IFetchEngagementTrendDemoQuery, IFetchEngagementTrendDemoQueryVariables>;