/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import { media } from "utils/style-utils";
import { Progress, Icon, Tooltip } from "antd";
import styled, { withTheme } from "styled-components";
import { ReactComponent as Increase } from "assets/increase.svg";
import { ReactComponent as Decrease } from "assets/decrease.svg";
import UnlockProgressBar from "components/UnlockProgressBar";

Math.easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t -= 1;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

class PercentageCircle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { percent: 0 };
  }

  componentDidMount() {
    const { percent } = this.props;
    this.animate(percent);

    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentWillReceiveProps(nextProps) {
    const { percent, animate } = this.props;

    if (percent === null) {
      this.setState({ percent: 0 });
    }
    if (percent !== nextProps.percent) {
      if (percent) {
        if (animate) {
          this.animate(nextProps.percent, percent);
        } else {
          this.setState({ percent: nextProps.percent });
        }
      } else if (animate) {
        this.animate(nextProps.percent);
      } else {
        this.setState({ percent: nextProps.percent });
      }
    }
  }

  animate = (percent, start = 0) => {
    if (percent !== null) {
      setTimeout(() => {
        const that = this;
        const increment = 750 / Math.abs(percent - start);
        const animationStart = start;

        const change = percent - start;

        let currentTime = 0;

        const animateScroll = () => {
          currentTime += increment;
          const val = Math.easeInOutQuad(
            currentTime,
            animationStart,
            change,
            750
          );
          if (that.mounted) {
            that.setState({ percent: val });
            if (currentTime < 750) {
              setTimeout(animateScroll, increment);
            }
          }
        };
        animateScroll();
      }, 250);
    }
  };

  render() {
    const {
      size,
      percent,
      lastPercent,
      name,
      color: propColor,
      report,
      responses,
      showChange,
      raters,
      theme,
      hasFeedback,
      unlockThreshold,
      locked = false
    } = this.props;
    let iconSize;
    if (window.innerWidth < 500) {
      iconSize = !name ? 14 : 10;
    } else {
      iconSize = 14;
    }

    const color = propColor || theme.primary_color;
    const change = percent - lastPercent;

    const percentUnlocked = (responses / unlockThreshold) * 100;

    let lockedMessage = "";

    if (percentUnlocked >= 100) {
      lockedMessage = "We're still collecting data, check back soon!";
    } else {
      lockedMessage = `We've collected ${percentUnlocked.toFixed()}% of the responses needed to unlock this habit`;
    }

    return !locked ? (
      <Wrapper name={Boolean(name)} report={report} size={size}>
        {hasFeedback && (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: size === "small" ? 36 : 12
            }}
          >
            <Tooltip title="You have extra feedback!">
              <span role="img" aria-label="present">
                🎁
              </span>
            </Tooltip>
          </div>
        )}
        <Progress
          strokeWidth={10}
          width={size === "small" ? 50 : 80}
          strokeColor={color}
          type="circle"
          // eslint-disable-next-line react/destructuring-assignment
          percent={this.state.percent}
          format={() => (
            <div style={{ position: "relative" }}>
              {" "}
              {showChange && change !== 0 && (
                <ChangeWrapper
                  direction={change > 0 ? "up" : "down"}
                  name={Boolean(name)}
                >
                  {change > 0 && lastPercent ? (
                    <span style={{ color: theme.primary_color }}>
                      <Increase height={iconSize} width={iconSize} />
                    </span>
                  ) : (
                    <span style={{ color: theme.primary_color }}>
                      <Decrease height={iconSize} width={iconSize} />
                    </span>
                  )}
                </ChangeWrapper>
              )}
              <Percent size={size}>{percent}%</Percent>
            </div>
          )}
        />

        <Name
          size={size}
          style={
            size === "small"
              ? {}
              : {
                  fontSize: name.length > 24 ? 12 : 16,
                  paddingTop: name.length > 24 ? 4 : 0
                }
          }
        >
          {name}
        </Name>
      </Wrapper>
    ) : (
      <Tooltip
        title={
          typeof raters === "number" && raters < 3
            ? `${raters} of 3 feedback providers needed (responses will still be collected)`
            : typeof responses === "number" && lockedMessage
        }
      >
        <Wrapper name={Boolean(name)} report={report} size={size}>
          <UnlockProgressBar
            threshold={unlockThreshold}
            count={responses}
            ratersCount={raters}
          />
          <Progress
            strokeWidth={10}
            width={size === "small" ? 50 : 80}
            strokeColor={color}
            type="circle"
            percent={0}
            format={() => <div />}
          />
          <Name
            size={size}
            style={
              size === "small"
                ? {}
                : {
                    fontSize: name.length > 24 ? 12 : 16,
                    paddingTop: name.length > 24 ? 4 : 0
                  }
            }
          >
            {name}
          </Name>
        </Wrapper>
      </Tooltip>
    );
  }
}
export default withTheme(PercentageCircle);

PercentageCircle.defaultProps = {
  size: "medium",
  report: false,
  responses: 0,
  percent: 0,
  lastPercent: null,
  name: "",
  animate: true,
  hasFeedback: false,
  raters: 0,
  showChange: true
};

PercentageCircle.propTypes = {
  theme: PropTypes.object.isRequired,
  size: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  percent: PropTypes.number,
  lastPercent: PropTypes.number,
  showChange: PropTypes.bool,
  animate: PropTypes.bool,
  hasFeedback: PropTypes.bool,
  name: PropTypes.string,
  color: PropTypes.string.isRequired,
  raters: PropTypes.number,
  report: PropTypes.bool,
  responses: PropTypes.number
};

const Wrapper = styled(({ name, size, report, ...props }) => (
  <div {...props} />
))`
  display: flex;
  flex-direction: ${(props) => props.name && "column"};
  align-items: center;
  position: relative;
  width: ${(props) =>
    props.name && (props.size === "small" ? "100px" : "150px")};
  margin-bottom: ${(props) => (props.report ? "-35px" : "0px")};
  ${media.handheld`
    width: ${({ name }) => name && "100%"};
  `}
`;

const Name = styled.div`
  font-size: ${(props) => (props.size === "small" ? "12px" : "16px")};
  text-align: center;
  height: ${(props) => (props.size === "small" ? "40px" : "60px")};
  display: flex;
  align-items: flex-start;
  padding-top: 2px;
`;

const Percent = styled.div`
  font-size: ${(props) => (props.size === "small" ? "14px" : "22px")};
`;

const ChangeWrapper = styled.div`
  position: absolute;
  bottom: ${({ direction }) => (direction === "up" ? "24px" : "-18px")};
  right: 0;
  left: 0;
  font-size: 16px;
  ${media.handheld`
    font-size: ${(props) => (props.name ? "12px" : "16px")}
    
    bottom: ${({ direction, name }) =>
      // eslint-disable-next-line no-nested-ternary
      direction === "up" ? (name ? "16px" : "24px") : name ? "-12px" : "-18px"};
    right:  ${(props) => (props.name ? "0;" : "-0")}
  `}
  }
`;
