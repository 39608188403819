/* eslint-disable */
import * as Types from '../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type IFetchEngagementTrendQueryVariables = Types.Exact<{
  id?: Types.Maybe<Types.Scalars['ID']>;
}>;


export type IFetchEngagementTrendQuery = (
  { __typename?: 'Query' }
  & { managerDashboard: (
    { __typename?: 'ManagerDashboard' }
    & Pick<Types.IManagerDashboard, 'engagementRecent' | 'engagementPast' | 'engagementOverall' | 'engagementMinMax' | 'highestLowestDeltas' | 'companyComparison'>
  ) }
);


export const FetchEngagementTrendDocument = gql`
    query fetchEngagementTrend($id: ID) {
  managerDashboard(id: $id) {
    engagementRecent
    engagementPast
    engagementOverall
    engagementMinMax
    highestLowestDeltas
    companyComparison
  }
}
    `;

/**
 * __useFetchEngagementTrendQuery__
 *
 * To run a query within a React component, call `useFetchEngagementTrendQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEngagementTrendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEngagementTrendQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchEngagementTrendQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IFetchEngagementTrendQuery, IFetchEngagementTrendQueryVariables>) {
        return ApolloReactHooks.useQuery<IFetchEngagementTrendQuery, IFetchEngagementTrendQueryVariables>(FetchEngagementTrendDocument, baseOptions);
      }
export function useFetchEngagementTrendLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IFetchEngagementTrendQuery, IFetchEngagementTrendQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IFetchEngagementTrendQuery, IFetchEngagementTrendQueryVariables>(FetchEngagementTrendDocument, baseOptions);
        }
export type FetchEngagementTrendQueryHookResult = ReturnType<typeof useFetchEngagementTrendQuery>;
export type FetchEngagementTrendLazyQueryHookResult = ReturnType<typeof useFetchEngagementTrendLazyQuery>;
export type FetchEngagementTrendQueryResult = ApolloReactCommon.QueryResult<IFetchEngagementTrendQuery, IFetchEngagementTrendQueryVariables>;