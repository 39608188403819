/* eslint-disable one-var */

/* eslint-disable no-unused-expressions */
import React from "react";
import PropTypes from "prop-types";

const PercentChange = ({
  oldPercent,
  currentPercent,
  negPrefix,
  posPrefix,
  suffix
}) => {
  let change, prefix;
  if (oldPercent && currentPercent) {
    change = oldPercent - currentPercent;
    change > 0 ? (prefix = negPrefix) : (prefix = posPrefix);
  }

  return (
    <span>
      {prefix}
      {change ? Math.abs(change) : "--"}
      {suffix}
    </span>
  );
};

PercentChange.defaultProps = {
  negPrefix: "-",
  posPrefix: "+",
  suffix: "%"
};

PercentChange.propTypes = {
  oldPercent: PropTypes.number.isRequired,
  currentPercent: PropTypes.number.isRequired,
  negPrefix: PropTypes.element,
  posPrefix: PropTypes.element,
  suffix: PropTypes.string
};

export default PercentChange;
