/* eslint-disable */
import { observable, computed, action } from "mobx";
import { Objective, Review, Label } from "services/api";
import profileStore from "./profileStore";
import normalize from "json-api-normalizer";
import build from "redux-object";
import { Modal } from "antd";
import history from "services/history";
import moment, { relativeTimeRounding } from "moment";
import queryString from "query-string";
import _ from "lodash";

const mobile = window.innerWidth < 500;
const span = () => {
  console.log(window.innerWidth);
  if (window.innerWidth < 500) {
    return 12;
  }
  if (window.innerWidth < 800) {
    return 1;
  }
  if (window.innerWidth < 1100) {
    return 2;
  }
  if (window.innerWidth < 1430) {
    return 3;
  }
  if (window.innerWidth < 1730) {
    return 4;
  }
  if (window.innerWidth < 2500) {
    console.log("hit");
    return 5;
  }
  console.log(6);
  return 6;
};

const paginationOptions = {
  top_level: {
    max_pages: 0,
    pages: {},
    currentPage: 1
  },
  bottom_level: {
    max_pages: 0,
    pages: {},
    currentPage: 1
  }
};

const mobilePaginationOptions = {
  top_level: {
    currentPage: 2,
    hasMore: true,
    maxPages: 0
  },
  bottom_level: {
    currentPage: 2,
    hasMore: true,
    maxPages: 0
  }
};
const options = {
  filters: {
    top_level: {
      latest_check_ins: [],
      owner_ids: []
    },
    bottom_level: {
      latest_check_ins: [],
      owner_ids: []
    }
  },
  query: {
    owner: null,
    stakeholder: null
  },
  order: {
    top_level: {
      delivery_date: "ascend",
      start_date: undefined
    },
    bottom_level: {
      delivery_date: "ascend",
      start_date: undefined
    }
  },
  condition: {
    top_level: {
      delivery_date: "ascend",
      start_date: undefined
    },
    bottom_level: {
      delivery_date: "ascend",
      start_date: undefined
    }
  },
  owners: {
    top_level: [],
    bottom_level: []
  }
};

const categoryOptions = {
  IN_PROGRESS: options,
  COMPLETED: options,
  ARCHIVED: options,
  PENDING: options,
  AWAITING_REVIEW: options
};

const categoryPagination = {
  IN_PROGRESS: paginationOptions,
  COMPLETED: paginationOptions,
  ARCHIVED: paginationOptions,
  PENDING: paginationOptions,
  AWAITING_REVIEW: paginationOptions
};
const mobileCategoryPagination = {
  IN_PROGRESS: mobilePaginationOptions,
  COMPLETED: mobilePaginationOptions,
  ARCHIVED: mobilePaginationOptions,
  PENDING: mobilePaginationOptions,
  AWAITING_REVIEW: mobilePaginationOptions
};

const viewOptions = {
  table: categoryOptions,
  carousel: categoryOptions
};

const viewPagination = {
  table: categoryPagination,
  carousel: categoryPagination
};

class ObjectiveStore {
  @observable parentObj = null;
  @observable formVisible = false;
  @observable formOptionsVisible = false;
  @observable otherUser = null;
  @observable data = {
    objective: {},
    user: {},
    key_result: {},
    review: {},
    label: []
  };
  @observable deepDiveOrder = {
    delivery_date: "ascend",
    start_date: undefined
  };
  @observable hasMore = true;
  @observable forceUpdate = 0;
  @observable actualAwaitingReview = [];
  @observable mobilePagination = mobileCategoryPagination;
  @observable currentView = "carousel";
  @observable deepDiveFilter = { owner_ids: [], latest_check_ins: [] };
  @observable isLoading = true;
  @observable pending = 0;
  @observable userLense = profileStore.currentUser;
  @observable category = "IN_PROGRESS";
  @observable categoryOptions = viewOptions;
  @observable filters = {
    top_level: {
      latest_check_ins: [],
      owner_ids: []
    },
    bottom_level: {
      latest_check_ins: [],
      owner_ids: []
    }
  };
  @observable query = {
    owner: null,
    stakeholder: null
  };

  @observable order = {
    top_level: null,
    bottom_level: null
  };
  @observable objChangingCategory = false;
  @observable called = false;
  @observable direction = null;
  @observable condition = {
    top_level: null,
    bottom_level: null
  };
  @observable awaitingReviewCount = 0;
  @observable objectiveDeepDiveNewChild = false;
  @observable objectiveHistory = [];
  @observable keyResults = [""];
  @observable duplicatingArray = [];
  @observable stepCount = null;
  @observable defaultCheckedAutoEndOkr = false;
  @observable editingObjectiveId = null;
  @observable currentObjectiveId = null;
  @observable objectiveToComplete = {};
  @observable duplicatingObjectiveId = null;
  @observable acceptingObjectiveId = null;
  @observable confirmGenerateOneOnOnes = null;
  @observable loadingLevel = ["bottom_level", "top_level"];
  @observable historyIndex = -1;
  @observable libraryMode = false;
  @observable lastValues = {
    parent_id: null,
    owner_id: null,
    stakeholder_ids: [],
    check_in_interval: 7,
    label_id: null,
    start_date: null,
    delivery_date: null,
    label_ids: []
  };
  @observable pagination = viewPagination;

  constructor() {
    history.listen((location, action) => {
      if (location.pathname.split("/")[1] !== "objectives") {
        return;
      }
      const that = this;
      const id = location.pathname.split("/")[2];
      if (!id) {
        this.historyIndex = -1;
      } else {
        const indexOf = this.objectiveHistory.map(o => o.id).indexOf(id);
        if (indexOf >= 0) {
          if (indexOf > this.historyIndex) {
            this.historyIndex = indexOf;
          } else {
            this.historyIndex = indexOf;
            this.objectiveHistory = this.objectiveHistory.slice(0, indexOf + 1);
          }
        } else {
          this.objectiveHistory = [
            ...this.objectiveHistory.slice(0, this.historyIndex + 1),
            {
              id: id,
              name: this.data.objective[id].attributes.name
            }
          ];

          this.historyIndex += 1;
        }
      }
    });
  }

  getAncestors = id => {
    let list = [id];
    let key = id;
    while (!!this.data.objective[key].relationships.parent.data) {
      key = this.data.objective[key].relationships.parent.data.id;
      list.unshift(key);
    }
    return list;
  };

  @computed get currentStep() {
    if (!this.duplicatingArray.length) {
      return null;
    }
    return this.stepCount - this.duplicatingArray.length;
  }

  @action.bound
  clearCategories() {
    this.pagination = viewPagination;
    this.categoryOptions = viewOptions;
  }

  @action.bound
  clearPages(level) {
    this.pagination[this.currentView][this.category][level].pages = {};
  }

  @action.bound
  clearTablePages() {
    this.pagination.table = categoryPagination;
  }

  @action.bound
  changeView() {
    const that = this;
    this.loadingLevel = ["top_level", "bottom_level"];
    this.currentView === "table" ? (this.currentView = "carousel") : (this.currentView = "table");
    if (
      Object.keys(this.pagination[this.currentView][this.category].top_level.pages).length === 0 &&
      Object.keys(this.pagination[this.currentView][this.category].bottom_level.pages).length === 0
    ) {
      this.fetchObjectives(this.userLense, 1, span() * 2, ["top_level", "bottom_level"]);
    } else {
      this.loadingLevel = [];
    }
  }

  @action.bound
  changePage(page, size, level, type) {
    const promise = new Promise((resolve, reject) => {
      if (
        !Object.keys(this.pagination[this.currentView][this.category][level].pages).includes(
          page.toString()
        )
      ) {
        this.loadingLevel = level;
        this.fetchObjectives(this.userLense, page, size, level).then(res => {
          this.pagination[this.currentView][this.category][level].pages[page] = this.objectiveSort(
            res[0].data.data,
            size,
            { level }
          );
          this.pagination[this.currentView][this.category][level].currentPage = page;
          if (mobile && this.mobilePagination[this.category][level].hasMore) {
            this.mobilePagination[this.category][level].currentPage = page;
          }
          resolve();
        });
      } else {
        this.pagination[this.currentView][this.category][level].currentPage = page;
        resolve();
      }
    });
    return promise;
  }

  @action.bound
  generateOneOnOnes() {
    Objective.generateOneOnOnes(this.confirmGenerateOneOnOnes).then(() => {
      history.push("/manager/one_on_ones");
      this.confirmGenerateOneOnOnes = null;
    });
  }

  @action.bound
  setCondition(level, orderBy) {
    if (level) {
      return this.categoryOptions[this.currentView][this.category].order[level][orderBy];
    } else {
      return this.deepDiveOrder[orderBy];
    }
  }

  @action.bound
  findCondition(level) {
    if (!level) {
      if (this.deepDiveOrder.delivery_date) {
        return {
          order: "delivery_date",
          condition: this.deepDiveOrder.delivery_date
        };
      }
      return {
        order: "start_date",
        condition: this.deepDiveOrder.start_date
      };
    }
    let order, condition;
    if (this.categoryOptions[this.currentView][this.category].order[level].delivery_date) {
      order = this.category == "COMPLETED" ? "date_completed" : "delivery_date";
      condition = this.categoryOptions[this.currentView][this.category].order[level].delivery_date;
      if (this.category === "COMPLETED") {
        condition = condition === "ASC" ? "DESC" : "ASC";
      }
    } else {
      order = "start_date";
      condition = this.categoryOptions[this.currentView][this.category].order[level].start_date;
    }
    return { order, condition };
  }

  // this sets the "options" which forms the query
  @action.bound
  setOptions(page, size, col, order, filter, level) {
    let noChange = true;
    const that = this;
    if (filter) {
      if (
        Object.keys(filter).includes("latest_check_in") ||
        Object.keys(filter).includes("owner")
      ) {
        Object.keys(filter).forEach(f => {
          if (f === "owner") {
            noChange =
              this.categoryOptions[this.currentView][this.category].filters[level].owner_ids ==
              filter[f];
            this.categoryOptions[this.currentView][this.category].filters[level].owner_ids =
              filter[f];
          }
          if (f === "latest_check_in") {
            noChange =
              this.categoryOptions[this.currentView][this.category].filters[level]
                .latest_check_ins == filter[f];
            this.categoryOptions[this.currentView][this.category].filters[level].latest_check_ins =
              filter[f];
          }
        });
      }
    }
    if (level) {
      const cdDate =
        level.length > 2 &&
        noChange &&
        this.categoryOptions[this.currentView][this.category].order[level].delivery_date ==
          "descend" &&
        page < 2;
      const csDate =
        level.length > 2 &&
        noChange &&
        this.categoryOptions[this.currentView][this.category].order[level].start_date ==
          "descend" &&
        page < 2 &&
        this.categoryOptions[this.currentView][this.category].order[level].start_date == undefined;
      if (col === "delivery_date" || cdDate) {
        if (cdDate) {
          this.categoryOptions[this.currentView][this.category].order[level].start_date = undefined;
          this.categoryOptions[this.currentView][this.category].order[level].delivery_date =
            "ascend";
        } else {
          this.categoryOptions[this.currentView][this.category].order[level][col] = order;
          this.categoryOptions[this.currentView][this.category].order[level].start_date = undefined;
        }
      }
      if (col === "start_date" || csDate) {
        if (csDate) {
          this.categoryOptions[this.currentView][this.category].order[level].start_date = "ascend";
          this.categoryOptions[this.currentView][this.category].order[
            level
          ].delivery_date = undefined;
        } else {
          this.categoryOptions[this.currentView][this.category].order[level][col] = order;
          this.categoryOptions[this.currentView][this.category].order[
            level
          ].delivery_date = undefined;
        }
      }
    }
    let s, d, condition, formattedCondition, sortOrder, reOrder, owners, stakeholderOwners;
    if (level && level.length > 2) {
      s = this.categoryOptions[this.currentView][this.category].order[level].start_date;
      d = this.categoryOptions[this.currentView][this.category].order[level].delivery_date;
      condition = d ? d : s ? s : null;
      formattedCondition = condition ? (condition === "descend" ? "DESC" : "ASC") : null;
      sortOrder = d ? "delivery_date" : s ? "start_date" : null;
      reOrder = sortOrder + " " + formattedCondition;
      owners, stakeholderOwners;

      if (
        this.categoryOptions[this.currentView][this.category].filters[level].owner_ids.length > 0
      ) {
        owners = this.categoryOptions[this.currentView][this.category].filters[level].owner_ids;
        stakeholderOwners = Object.values(
          this.categoryOptions[this.currentView][this.category].filters[level].owner_ids
        );
      } else {
        owners = this.userLense.id;
        stakeholderOwners = null;
      }
    }

    if (typeof level === "object") {
      this.categoryOptions[this.currentView][this.category].query.owner = this.generateQueryString(
        this.userLense.id,
        null,
        null,
        null,
        page,
        size
      );
      this.categoryOptions[this.currentView][
        this.category
      ].query.stakeholder = this.generateQueryString(
        null,
        this.userLense.id,
        null,
        null,
        page,
        size
      );
    }

    if (level === "top_level") {
      this.categoryOptions[this.currentView][this.category].query.owner = this.generateQueryString(
        owners,
        null,
        level,
        reOrder,
        page,
        size
      );
    } else if (level === "bottom_level") {
      this.categoryOptions[this.currentView][
        this.category
      ].query.stakeholder = this.generateQueryString(
        stakeholderOwners,
        this.userLense.id,
        level,
        reOrder,
        page,
        size
      );
    }
  }

  generateQueryString = (owner, stakeholder_id, level, reOrder, page, size) => {
    const query = {};
    const map = {
      0: "owner_id[]",
      1: "latest_check_ins[]",
      2: "status[]",
      3: "reorder",
      4: "page",
      5: "per",
      6: "stakeholder_id",
      7: "public_view",
      8: "view",
      9: "size"
    };
    const arr = [
      owner,
      level &&
        this.categoryOptions[this.currentView][this.category].filters[level].latest_check_ins,
      [this.category, profileStore.currentUser.id],
      reOrder,
      page,
      size,
      stakeholder_id,
      profileStore.currentUser.id,
      this.currentView,
      span()
    ];
    arr.forEach((c, i) => {
      if (
        c &&
        (typeof c === "string" || typeof c === "number" || (typeof c === "object" && c.length > 0))
      ) {
        query[map[i]] = c;
      }
    });
    let test = queryString.stringify(query);
    return queryString.stringify(query);
  };

  sortDateComparison = (firstDate, secondDate, condition, order, amount) => {
    const flip = this.category === "COMPLETED";
    let date1, date2;
    if (amount === 2 && order) {
      date1 = firstDate[order];
      date2 = secondDate[order];
    } else if (amount === 1 && order) {
      date1 = secondDate[order];
      date2 = firstDate[order];
    }
    if (condition && order) {
      return condition === "descend" ? date2 : date1;
    } else {
      if (flip) {
        return secondDate.date_completed;
      }
      return firstDate.delivery_date;
    }
  };

  sortChain = (arr, amount, size, page, level) => {
    let orderSort, conditionSort, param1, param2;
    let newArr = arr;
    if (level) {
      const { condition, order } = this.findCondition(level);
      orderSort = condition;
      conditionSort = order;
      if (level === "top_level") {
        newArr = arr.filter(o => o.relationships.owner.data.id === this.userLense.id);
      }
    }
    page === 1 ? ((param1 = 0), (param2 = size / 2)) : ((param1 = size / 2), (param2 = size));
    if (amount === 1) {
      return newArr
        .sort(
          (a, b) =>
            moment(this.sortDateComparison(b, a, orderSort, conditionSort, amount)).valueOf() -
            moment(this.sortDateComparison(a, b, orderSort, conditionSort, amount)).valueOf()
        )
        .slice(0, size)
        .map(ob => ob.id);
    } else if (amount === 2) {
      newArr = newArr
        .sort((a, b) => {
          return (
            moment(this.sortDateComparison(a, b, orderSort, conditionSort, amount)).valueOf() -
            moment(this.sortDateComparison(b, a, orderSort, conditionSort, amount)).valueOf()
          );
        })
        .slice(param1, param2)
        .map(ob => ob.id);
      return newArr;
    }
  };

  @action.bound
  objectiveSort = (arr, size, extra) => {
    if (extra) {
      if (extra.pages) {
        return {
          1: this.sortChain(arr, 2, size, 1, extra.level),
          2: this.sortChain(arr, 2, size, 2, extra.level)
        };
      }
      return this.sortChain(arr, 1, size, null, extra.level);
    }

    return {
      1: this.sortChain(arr, 2, size, 1, null),
      2: this.sortChain(arr, 2, size, 2, null)
    };
  };

  @action.bound
  fetchObjectives(user = profileStore.currentUser, page, size, level, extra) {
    const that = this;
    const promise = new Promise((resolve, reject) => {
      if (user && profileStore.currentUser && user.id !== profileStore.currentUser.id) {
        this.otherUser = user;
      }
      let promiseArr = [];
      if (
        this.categoryOptions[this.currentView][this.category].query.owner === null ||
        user.id !== this.userLense.id ||
        (level && level.length == 2)
      ) {
        this.userLense = user;
        this.setOptions(page, size, null, null, null, level);
        console.log(
          this.categoryOptions[this.currentView][this.category].query.owner,
          this.categoryOptions[this.currentView][this.category].query.stakeholder
        );
        promiseArr = [
          Objective.all(this.categoryOptions[this.currentView][this.category].query.owner),
          Objective.all(this.categoryOptions[this.currentView][this.category].query.stakeholder)
        ];
      } else {
        if (page != 1) {
          this.setOptions(page, size, null, null, null, level);
        }
        if (level === "top_level") {
          promiseArr = [
            Objective.all(this.categoryOptions[this.currentView][this.category].query.owner)
          ];
        } else if (level === "bottom_level") {
          promiseArr = [
            Objective.all(this.categoryOptions[this.currentView][this.category].query.stakeholder)
          ];
        }
      }
      this.isLoading = true;
      Promise.all(promiseArr).then(res => {
        if (!res[1] && res[0]) {
          this.setData(res[0].data);
          if (mobile && this.mobilePagination[this.category][level].max_pages <= page) {
            this.mobilePagination[this.category][level].hasMore = false;
          }
          // this.pagination[this.category][level].pages = level === 'top_level' ? this.objectiveSort(this.userObjectives(profileStore.currentUser.id), size) : this.objectiveSort(this.stakeholderObjectives(profileStore.currentUser.id), size)
          this.pagination[this.currentView][this.category][level].max_pages =
            res[0].data.meta.current;
          this.defaultCheckedAutoEndOkr = res[0].data.meta.default_checked_auto_end_okr;
        }

        if (res[1]) {
          this.defaultCheckedAutoEndOkr = res[0].data.meta.default_checked_auto_end_okr;
          const that = this;
          this.setData(res[0].data);
          let stuff = build(
            this.data,
            "objective",
            res[0].data.data ? res[0].data.data.map(d => d.id) : []
          );
          this.setData(res[1].data);
          this.pagination[this.currentView][this.category].top_level.pages = this.objectiveSort(
            this.filter(
              build(this.data, "objective", res[0].data.data ? res[0].data.data.map(d => d.id) : [])
            ),
            size
          );
          this.pagination[this.currentView][this.category].bottom_level.pages = this.objectiveSort(
            this.filter(this.stakeholderObjectives(user.id)),
            size
          );
          this.pagination[this.currentView][this.category].top_level.max_pages =
            res[0].data.meta.current;
          this.mobilePagination[this.category].top_level.max_pages = res[0].data.meta.mobileCurrent;
          this.pagination[this.currentView][this.category].bottom_level.max_pages =
            res[1].data.meta.current;
          this.mobilePagination[this.category].bottom_level.max_pages =
            res[1].data.meta.mobileCurrent;
          if (
            mobile &&
            this.mobilePagination[this.category].top_level.max_pages <=
              this.mobilePagination[this.category].top_level.currentPage
          ) {
            this.mobilePagination[this.category].top_level.hasMore = false;
          }
          if (
            mobile &&
            this.mobilePagination[this.category].bottom_level.max_pages <=
              this.mobilePagination[this.category].bottom_level.currentPage
          ) {
            this.mobilePagination[this.category].bottom_level.hasMore = false;
          }
          this.categoryOptions[this.currentView][this.category].owners.bottom_level =
            res[1].data.meta.unique_owners;
          this.awaitingReviewCount = res[0].data.meta.awaiting_review;
          this.actualAwaitingReview = res[0].data.meta.actual_awaiting_review;
          this.pendingCount = res[0].data.meta.pending;
        }
        this.isLoading = false;
        this.loadingLevel = [];
        resolve(res);
      });
    });
    return promise;
  }

  @computed get viewedObjectives() {
    this.objectives.filter(o => o.status === this.category);
  }

  @action.bound
  setEditingObjectiveId(id) {
    this.editingObjectiveId = id;
  }

  @computed
  get awaitingReview() {
    const id = profileStore.currentUser.id;
    const d = this.stakeholderObjectives(profileStore.currentUser.id).filter(
      o =>
        o.status === "COMPLETED" &&
        o.stakeholders.find(s => s.id === profileStore.currentUser.id) &&
        o.reviews &&
        !o.reviews.find(r => r.user && r.user.id === profileStore.currentUser.id)
    );
    return d;
  }

  @action.bound
  changeCategory({ key }) {
    this.loadingLevel = ["bottom_level", "top_level"];
    this.category = key;
    const that = this;
    if (
      Object.keys(this.pagination[this.currentView][this.category]["top_level"].pages).length ==
        0 &&
      Object.keys(this.pagination[this.currentView][this.category]["bottom_level"].pages).length ==
        0
    ) {
      this.fetchObjectives(this.userLense, 1, span() * 2, ["top_level", "bottom_level"]).then(
        res => (this.loadingLevel = [])
      );
    } else {
      this.loadingLevel = [];
    }
  }

  @action.bound
  duplicateObjective(objective) {
    this.duplicatingObjectiveId = objective.id;
  }

  userObjectives(userId) {
    try {
      return build(this.data, "user", userId, {
        eager: true
      }).objectives.filter(o => o.resolved);
    } catch {
      return [];
    }
  }
  stakeholderObjectives(userId) {
    try {
      return build(this.data, "user", userId, {
        eager: true
      }).stakeholder_objectives.filter(o => o.resolved);
    } catch {
      return [];
    }
  }

  @action.bound
  openAcceptModal(id) {
    if (!this.data.objective[id]) {
      Objective.fetch(id).then(({ data }) => {
        //
        this.setData(data);
        this.acceptingObjectiveId = id;
      });
    } else {
      this.acceptingObjectiveId = id;
    }
  }

  @action.bound
  acceptObjective(id) {
    Objective.update(id, { status: "IN_PROGRESS" }).then(({ data }) => {
      this.setData(data);
    });
  }
  @action.bound
  rejectObjective(id) {
    Objective.update(id, { status: "REJECTED" }).then(({ data }) => {
      this.setData(data);
    });
  }

  @computed get objectiveToAccept() {
    if (!this.acceptingObjectiveId) {
      return null;
    }
    const o = this.getObjective(this.acceptingObjectiveId);
    return o;
  }

  filter = objectives => {
    if (this.category === "AWAITING_REVIEW") {
      const objs = objectives.filter(o => {
        return this.actualAwaitingReview.includes(o.id);
      });
      return objs;
    }
    if (this.category === "ARCHIVED") {
      return objectives.filter(o => o.status === "ABANDONED" || o.status === "REJECTED");
    }

    return objectives.filter(o => o.status === this.category);
  };

  @action.bound
  getObjectiveChildren(arr) {
    const children = arr.map(o => build(this.data, "objective", o.id, { eager: true }));
    return children;
  }

  @action.bound
  getObjective(id) {
    const o = build(this.data, "objective", id, { eager: true });
    return o;
  }

  @action.bound
  clearHistory() {
    this.objectiveHistory = [];
  }

  @action.bound
  getReviews(id) {
    return build(this.data, "objective", id, { eager: true });
  }

  @action.bound
  createObjective(params) {
    const promise = new Promise((resolve, reject) => {
      Objective.create(params).then(({ data }) => {
        const that = this;
        this.setData(data);
        Object.keys(this.lastValues).forEach(k => {
          this.lastValues[k] = params[k];
        });
        // const that = this;
        this.duplicatingArray = this.duplicatingArray.slice(1, this.duplicatingArray.length);

        if (this.duplicatingArray.length > 0) {
          this.libraryMode = true;
          this.duplicatingObjectiveId = this.duplicatingArray[0].id;
        } else {
          this.formVisible = false;
        }
        resolve([data, this.libraryMode, this.duplicatingArray.length]);
      });
    });
    return promise;
  }

  @action.bound
  cancelImport() {
    this.duplicatingObjectiveId = null;
    this.duplicatingArray = [];
    this.stepCount = null;
    this.lastValues = {
      parent_id: null,
      owner_id: null,
      stakeholder_ids: [],
      check_in_interval: 7,
      label_id: null,
      start_date: null,
      delivery_date: null,
      label_ids: []
    };
  }

  @action.bound
  editObjective(params, id = this.editingObjectiveId) {
    const that = this;
    console.log(params);
    const promise = new Promise((resolve, reject) => {
      Objective.update(id, params).then(({ data }) => {
        this.setData(data);
        if (params.status === "COMPLETED") {
          this.reCategorizeObjective(this.objectiveToComplete, "COMPLETED");
        }
        const edited = this.getObjective(id);
        //
        if (this.objectiveHistory.find(h => h.id === id)) {
          //
          this.objectiveHistory = this.objectiveHistory.map(h =>
            h.id === edited.id ? { name: edited.name, id: edited.id } : h
          );
        }
        resolve(data);
      });
    });
    return promise;
  }

  @action.bound
  setData(data) {
    const res = normalize(data, {
      camelizeKeys: false,
      camelizeTypeValues: false
    });
    const { user, objective, key_result, review, label } = res;
    // this.data.objective = _.merge(this.data.objective, objective);
    // this.data.label = _.merge(this.data.label, label);
    // this.data.user = _.merge(this.data.user, user);
    // this.data.key_result = _.merge(this.data.key_result, key_result);
    // this.data.review = _.merge(this.data.review, review);

    this.data.objective = { ...this.data.objective, ...objective };
    this.data.label = { ...this.data.label, ...label };
    this.data.user = { ...this.data.user, ...user };
    this.data.key_result = { ...this.data.key_result, ...key_result };
    this.data.review = { ...this.data.review, ...review };
  }

  @action.bound
  async fetchObjective(id) {
    this.isLoading = true;

    const promise = new Promise((resolve, reject) => {
      Objective.fetch(id)
        .then(({ data }) => {
          this.setData(data);
          this.isLoading = false;
          resolve();
        })
        .catch(err => reject(err));
    });
    return promise;
  }

  @action.bound
  async fetchLabels() {
    const promise = new Promise((resolve, reject) => {
      Label.all()
        .then(({ data }) => {
          this.setData(data);
          resolve();
        })
        .catch(err => reject(err));
    });
    return promise;
  }

  @action.bound
  destroyObj(id, level) {
    const data = this.data;
    const setData = this.setData;
    const { reCategorizeObjective } = this;
    const that = this;
    const promise = new Promise((resolve, reject) => {
      Objective.destroy(id).then(({ data }) => {
        const that = this;
        this.setData(data);
        this.reCategorizeObjective({ id: id, level: level }, "ARCHIVED");
        resolve();
      });
    });
    return promise;
  }

  @action.bound
  abandonObjective(id, level) {
    const data = this.data;
    const setData = this.setData;
    const { reCategorizeObjective } = this;
    const that = this;

    // const promise = new Promise((resolve, reject) => {
    //   Objective.destroy(id).then(({ data }) => {
    //   const that = this;
    //   this.setData(data);
    //   this.reCategorizeObjective({ id: id, level: level }, 'ARCHIVED');
    // });
    // })
    // return promise

    // this.destroyObj(id, level)
    Modal.confirm({
      maskClosable: true,
      okType: "danger",
      title: "Are you sure you want to abandon this objective?",
      onOk: () =>
        this.destroyObj(id, level).then(res => {
          this.forceUpdate = this.forceUpdate + 1;
        }),
      cancelText: "Cancel",
      okText: "Abandon"
    });
  }

  @action.bound
  addNewChild(parent) {
    this.formOptionsVisible = true;
    this.parentObj = parent;
  }

  // @action.bound
  copyKeyResults(krs) {
    this.formVisible = true;
    this.keyResults = krs.map(kr => kr.name);
  }

  @action.bound
  openForm() {
    this.formVisible = true;
  }

  @action.bound
  closeForm() {
    this.formVisible = false;
    this.parentObj = null;
    this.editingObjectiveId = null;
    this.duplicatingObjectiveId = null;
    this.keyResults = [""];
    this.editing = false;
  }

  @computed
  get currentObjective() {
    if (this.historyIndex >= 0) {
      const b = build(this.data, "objective", this.objectiveHistory[this.historyIndex].id);
      return b;
    }
    return null;
  }
  @computed
  get labels() {
    return build(this.data, "label", Object.keys(this.data.label), {
      eager: true
    });
  }
  // @computed
  // get pendingCount() {
  //   return build(this.data, "objective").filter(
  //     o => o.owner_id === profileStore.currentUser.id && o.status === "PENDING"
  //   ).length;
  // }

  @action.bound
  reviewObjective = params => {
    const that = this;
    Review.create(params).then(({ data }) => {
      this.setData(data);
      this.objectiveToComplete = {};
      this.data.objective[params.reviewable_id].relationships.reviews.data.push(data.data);
      console.log(this.data);
      this.currentObjectiveId = params.reviewable_id;
    });
  };

  greaterThan = (a, b) => {
    return moment(a).valueOf() > moment(b).valueOf();
  };

  lessThan = (a, b) => {
    return moment(a).valueOf() < moment(b).valueOf();
  };
  sortGreaterThan = (arr, attr) => {
    return arr.sort((a, b) => a.attributes[attr] - b.attributes[attr]);
  };

  sortLessThan = (arr, attr) => {
    return arr.sort((a, b) => b.attributes[attr] - a.attributes[attr]);
  };

  offsetPages = (pages, direction, condition, objective, pageLoc, objsTotal, level) => {
    const maxPages = Math.ceil(objsTotal / span());

    if (direction === "left") {
      let reducedPage = pageLoc;
      const s =
        condition.order === "ascend"
          ? (arr, attr) => {
              return this.sortLessThan(arr, attr);
            }
          : (arr, attr) => {
              return this.sortGreaterThan(arr, attr);
            };
      Object.entries(pages).forEach(([pageNum, page], i) => {
        if (parseInt(pageNum) >= reducedPage) {
          if (pages[parseInt(pageNum) + 1] && pages[parseInt(pageNum) + 1].length > 0) {
            if (parseInt(pageNum) > reducedPage) {
              page.shift();
            }
            page.push(pages[parseInt(pageNum) + 1][0]);
            pages[parseInt(pageNum) + 1].splice(0, 1);
            reducedPage = parseInt(pageNum) + 1;
          } else if (parseInt(pageNum) <= maxPages && !pages[(parseInt(pageNum) + 1).toString()]) {
            if (
              parseInt(pageNum) === maxPages &&
              !pages[parseInt(pageNum) - 1] &&
              pageLoc !== parseInt(pageNum)
            ) {
              page.shift();
            } else if (parseInt(pageNum) + 1 <= maxPages && !pages[parseInt(pageNum) + 1]) {
              this.fetchObjectives(profileStore.currentUser, parseInt(pageNum), span(), level).then(
                res => {
                  this.objChangingCategory = true;
                  const arr = s(res[0].data.data, condition.order);
                  page.push(arr[arr.length - 1].id);
                  if (page.length > span()) {
                    page.shift();
                  }
                  this.objChangingCategory = false;
                }
              );
            }
          }
        }
      });
    } else if (direction === "right") {
      let tempArr = [];
      let placed;
      const obj = this.getObjective(objective[0]);
      const c =
        condition.order === "ascend"
          ? (a, b) => {
              return this.lessThan(a, b);
            }
          : (a, b) => {
              return this.greaterThan(a, b);
            };
      Object.entries(pages).forEach(([pageNum, page]) => {
        // first two pages edge case
        if (pageNum === "1" && page.length === 0) {
          page.push(objective[0]);
          placed = true;
          return;
        } else if (
          !placed &&
          pageNum === "2" &&
          pages["1"].length === span() &&
          page.length === 0 &&
          tempArr.length === 0
        ) {
          page.push(objective[0]);
          placed = true;
          return;
        }
        //

        // checking if the last value in the page indicates whether the objective should belong in the page
        if (
          c(obj[condition.type], this.getObjective(page[page.length - 1])[condition.type]) &&
          !placed
        ) {
          let index, found;
          // finding the exact location in the page the objective should be placed
          page.forEach((o, i) => {
            if (c(obj[condition.type], this.getObjective(o)[condition.type]) && !found) {
              index = i;
              found = true;
            }
          });
          page.splice(index, 0, objective[0]);

          // if the page is overflowing remove last element
          if (page.length > span()) {
            tempArr = page.splice(page.length - 1, 1);
          }
          placed = true;
        }

        //check the first value of the page to see if the removed element should be placed there
        if (
          tempArr.length > 0 &&
          c(
            this.getObjective(tempArr[0])[condition.type],
            this.getObjective(page[0])[condition.type]
          ) &&
          placed
        ) {
          let index, found;
          if (page.length === span()) {
            page.unshift(tempArr[0]);
            tempArr = [page.pop()];
          } else {
            page.forEach((o, i) => {
              if (c(obj[condition.type], this.getObjective(o)[condition.type]) && !found) {
                index = i;
                found = true;
              }
            });
            tempArr = page.splice(index, 0, tempArr[0]);
            return;
          }
        }
        if (
          [1, 2, Object.entries(pages).length].includes(parseInt(pageNum)) &&
          page.length < span() &&
          !placed
        ) {
          let index;
          page.forEach((o, i) => {
            if (c(obj[condition.type], this.getObjective(o)[condition.type])) {
              index = i;
            }
          });
          index ? page.splice(index, 0, obj.id) : page.push(obj.id);
        }
        if (
          ([1, 2, Object.entries(pages).length].includes(parseInt(pageNum)) &&
            (!pages[(parseInt(pageNum) + 1).toString()] ||
              pages[(parseInt(pageNum) + 1).toString()].length === 0) &&
            !placed &&
            pages[parseInt(pageNum)].length === span()) ||
          (pages[parseInt(pageNum)].length === 0 && tempArr.length > 0)
        ) {
          if (pages[parseInt(pageNum)].length === 0 && tempArr.length > 0) {
            pages[2].push(tempArr[0]);
          } else if (
            parseInt(pageNum) === maxPages ||
            (pages[parseInt(pageNum) + 1] && pages[parseInt(pageNum) + 1].length === 0)
          ) {
            if (parseInt(pageNum) === maxPages) {
              pages[parseInt(pageNum)].push(obj.id);
            } else {
              pages[parseInt(pageNum) + 1].push(obj.id);
            }
          }
        }
      });
    }
  };

  reCategorizeObjective = (objective, category) => {
    const that = this;
    let temp, index, condition;
    if (!objective.level) {
      return;
    }
    temp = this.pagination.carousel[this.category][objective.level].pages[
      this.pagination.carousel[this.category][objective.level].currentPage
    ];
    if (!temp) {
      return;
    }
    temp.forEach((o, i) => {
      if (o === objective.id) {
        index = i;
      }
    });
    temp = temp.splice(index, 1);
    const subRemainder =
      this.pagination.carousel[this.category][objective.level].max_pages % span();
    const addRemainder = this.pagination.carousel[category][objective.level].max_pages % span();
    const subPages = this.pagination.carousel[this.category][objective.level].pages;
    const addPages = this.pagination.carousel[category][objective.level].pages;
    this.pagination.carousel[this.category][objective.level].max_pages -= 1;
    this.pagination.carousel[category][objective.level].max_pages += 1;
    if (subRemainder === 1) {
      delete subPages[subPages.length];
    }
    if (addRemainder === 1) {
      delete addPages[addPages.length];
    }
    condition = this.categoryOptions.carousel[this.category].order[objective.level].delivery_date
      ? {
          type: "delivery_date",
          order: this.categoryOptions.carousel[this.category].order[objective.level].delivery_date
        }
      : {
          type: "start_date",
          order: this.categoryOptions.carousel[category].order[objective.level].start_date
        };
    this.offsetPages(
      this.pagination.carousel[this.category][objective.level].pages,
      "left",
      condition,
      null,
      this.pagination.carousel[this.category][objective.level].currentPage,
      this.pagination.carousel[this.category][objective.level].max_pages,
      objective.level
    );
    condition = this.categoryOptions.carousel[category].order[objective.level].delivery_date
      ? {
          type: "delivery_date",
          order: this.categoryOptions.carousel[category].order[objective.level].delivery_date
        }
      : {
          type: "start_date",
          order: this.categoryOptions.carousel[category].order[objective.level].start_date
        };
    this.offsetPages(
      this.pagination.carousel[category][objective.level].pages,
      "right",
      condition,
      temp,
      null,
      this.pagination.carousel[this.category][objective.level].max_pages,
      null
    );
    this.clearTablePages();
    this.objChangingCategory = false;
  };

  markAsComplete = (objective, level) => {
    this.objectiveToComplete.id = objective.id;
    this.objectiveToComplete.level = level;
  };

  addAwaitingReview = (objective, level) => {
    if (!level) {
      return;
    }
    const condition = this.categoryOptions.carousel.AWAITING_REVIEW.order[level].delivery_date
      ? {
          type: "delivery_date",
          order: this.categoryOptions.carousel.AWAITING_REVIEW.order[level].delivery_date
        }
      : {
          type: "start_date",
          order: this.categoryOptions.carousel.AWAITING_REVIEW.order[level].start_date
        };
    this.offsetPages(
      this.pagination.carousel.AWAITING_REVIEW[level].pages,
      "right",
      condition,
      [objective.id],
      null,
      this.pagination.carousel.AWAITING_REVIEW[level].max_pages
    );
    this.awaitingReviewCount = this.awaitingReviewCount + 1;
  };

  removeAwaitingReview(objective, level) {
    if (!level) {
      return;
    }
    let removed;
    Object.values(this.pagination.carousel.AWAITING_REVIEW[level].pages).forEach((p, i) => {
      if (p.includes(objective.id)) {
        removed = p.splice(i, 1);
      }
    });
    if (removed) {
      const condition = this.categoryOptions.carousel.AWAITING_REVIEW.order[level].delivery_date
        ? {
            type: "delivery_date",
            order: this.categoryOptions.carousel.AWAITING_REVIEW.order[level].delivery_date
          }
        : {
            type: "start_date",
            order: this.categoryOptions.carousel.AWAITING_REVIEW.order[level].start_date
          };
      this.offsetPages(
        this.pagination.carousel.AWAITING_REVIEW[level].pages,
        "left",
        condition,
        null,
        this.pagination.carousel.AWAITING_REVIEW[level].currentPage,
        this.pagination.carousel.AWAITING_REVIEW[level].max_pages,
        level
      );
      this.awaitingReviewCount = this.awaitingReviewCount - 1;
    }
    this.clearTablePages();
  }

  @action.bound
  changeLocation = objective => {
    const navigating = this.getObjective(objective.id);
    if (this.currentObjective && objective.id === this.currentObjective.id) {
      return new Promise(resolve => {
        resolve();
      });
    }

    // if (
    //   navigating &&
    //   navigating.children.every(
    //     (c) =>
    //       this.data.objective[c.id] &&
    //       c.children.every((c2) => this.data.objective[c2.id])
    //   )
    // ) {
    //   history.push(`/objectives/${navigating.id}`);
    // } else {
    const promise = new Promise((resolve, reject) => {
      // if (this.currentObjective && objective.id === this.currentObjective.id) {
      //   resolve();
      // }
      this.isLoading = true;
      this.fetchObjective(objective.id).then(() => {
        this.isLoading = false;
        history.push(`/objectives/${objective.id}`);
        resolve();
      });
    });
    // }
    return promise;
  };
}

export default new ObjectiveStore();
