/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { Radio, Typography, List, Checkbox } from "antd";
import { Teams } from "services/api";
import PropTypes from "prop-types";
import moment from "moment";
import UserAvatar from "components/UserAvatar";

const SelectCohorts = ({ onSelect, selected, setAll }) => {
  const [specific, setSpecific] = useState(false);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    Teams.all().then(({ data }) => {
      setTeams(data.groups);
    });
  }, []);

  const allChecked = selected.length === teams.length;

  return (
    <>
      <Typography.Title level={4}>
        Who should be required to do a one-on-one?
      </Typography.Title>
      <Radio.Group
        value={specific}
        onChange={({ target: { value } }) => setSpecific(value)}
      >
        <Radio value={false}>All Teams</Radio>
        <Radio value>Specific Teams</Radio>
      </Radio.Group>
      {specific && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 20
            }}
          >
            <Typography>{selected.length} selected</Typography>
            <Checkbox
              onClick={() => (allChecked ? setAll([]) : setAll(teams))}
              checked={allChecked}
            />
          </div>
          <List
            style={{
              width: "100%",
              maxHeight: "370px",
              overflowY: "auto",
              borderTop: "1px solid #e8e8e8"
            }}
            itemLayout="horizontal"
            dataSource={teams}
            renderItem={(item) => (
              <List.Item
                style={{ paddingRight: 12 }}
                actions={[
                  <Checkbox
                    onClick={() => onSelect(item)}
                    checked={selected.find((s) => s.id === item.id)}
                  />
                ]}
              >
                <List.Item.Meta
                  avatar={<UserAvatar avatar={item.avatar} name={item.name} />}
                  title={item.name}
                  description={`last one-on-one: ${
                    item.last_one_on_one
                      ? moment(item.last_one_on_one).format("MM/DD/YY")
                      : "never"
                  }`}
                />
              </List.Item>
            )}
          />
        </>
      )}
    </>
  );
};

export default SelectCohorts;
