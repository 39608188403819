import React, { useState } from "react";
import { Modal, Select } from "antd";
import { HabitUser } from "containers/ManagerDashboardNext/ManagedUsers/UserHabits";

interface Props {
  visible: boolean;
  habitUsers: HabitUser[];
  name: string;
  sendFeedbackRequest: (id: string) => void;
  onClose: () => void;
}

const ExtraFeedback: React.FC<Props> = ({
  visible,
  habitUsers,
  name,
  onClose,
  sendFeedbackRequest
}) => {
  const [selectedHabit, setSelectedHabit] = useState<null | string>(null);

  const createFeedbackRequest = (): void => {
    if (selectedHabit) {
      sendFeedbackRequest(selectedHabit);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Select Habit for Extra Feedback"
      onCancel={onClose}
      okButtonProps={{ disabled: !selectedHabit }}
      onOk={createFeedbackRequest}
      okText="Send"
    >
      <Select
        style={{ width: "100%" }}
        placeholder="Select a Habit"
        onSelect={(id: string): void => setSelectedHabit(id)}
      >
        {habitUsers.map((hu) => (
          <Select.Option key={hu.id}>
            {hu.habit.name}{" "}
            {hu.currentScore ? `(${hu.currentScore.value}%)` : ""}
          </Select.Option>
        ))}
      </Select>
      <p style={{ marginTop: 12 }}>
        {name} will receive an action item to initiate an extra feedback request
        for the selected habit.
      </p>
    </Modal>
  );
};

export default ExtraFeedback;
