import React from "react";
import PropTypes from "prop-types";
import { Modal, Form, Input, Select } from "antd";

const SupportForm = ({
  visible,
  onCancel,
  onSend,
  form,
  form: { getFieldDecorator }
}) => (
  <Modal
    visible={visible}
    destroyOnClose
    title="Support Request"
    okText="Send"
    onCancel={onCancel}
    onOk={() => onSend(form.getFieldsValue())}
  >
    <Form layout="vertical">
      <Form.Item label="Issue Type">
        {getFieldDecorator("type")(
          <Select placeholder="Select">
            <Select.Option value="General Question">
              General Question
            </Select.Option>
            <Select.Option value="Technical Issue">
              Technical Issue
            </Select.Option>
            <Select.Option value="Other">Other</Select.Option>
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Message to support">
        {getFieldDecorator("support_text", {
          rules: [{ required: true, message: "Text is required" }]
        })(<Input.TextArea />)}
      </Form.Item>
    </Form>
  </Modal>
);

SupportForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired
  }).isRequired
};

export default Form.create()(SupportForm);
