import React from "react";
import { Popover } from "antd";
import UserAvatar from "components/UserAvatar";
import { AvatarListContainer } from "./styles";
import { LeaderboardUser } from "./index";

type Props = {
  users: LeaderboardUser[];
};

const PopoverContent = ({ users }: Props) => {
  return (
    <AvatarListContainer style={{ maxWidth: 300 }}>
      {users.map((u) => (
        <UserAvatar
          style={{ margin: "2px" }}
          key={u.user.id}
          size="small"
          name={u.user.name}
          lineHeight={32}
        />
      ))}
    </AvatarListContainer>
  );
};

const AvatarList = ({ users }: Props) => (
  <AvatarListContainer>
    {users.slice(0, 40).map((u) => (
      <UserAvatar
        style={{ margin: "2px" }}
        key={u.user.id}
        size="small"
        name={u.user.name}
        lineHeight={32}
      />
    ))}
    <Popover content={<PopoverContent users={users.slice(40, users.length)} />}>
      {users.length > 40 && (
        <div style={{ marginLeft: "auto", marginRight: 24 }}>
          ...{users.length - 40} others
        </div>
      )}
    </Popover>
  </AvatarListContainer>
);

export default AvatarList;
