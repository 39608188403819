import React from "react";
import PropTypes from "prop-types";
import {
  Form,
  Modal,
  Button,
  Select,
  Input,
  Row,
  Col,
  InputNumber,
  Switch,
  TreeSelect
} from "antd";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { omit } from "lodash";

const FormItem = Form.Item;
const { Option } = Select;

const getUserLevel = (user) => {
  if (!user) {
    return "user";
  }
  if (user.admin) {
    return "admin";
  }
  if (user.manager) {
    return "manager";
  }
  return "user";
};

const nameFilter = (input, { props: { children } }) =>
  children.toLowerCase().includes(input.toLowerCase());

@inject("profileStore")
@observer
class UserForm extends React.Component {
  @observable loading = false;
  @observable openMail = false;
  @observable roleRequired = false;

  /* eslint-disable */
  handleUniqueEmail = (rule, value, callback) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (value && !re.test(value)) {
      callback([new Error("Invalid email.")]);
    } else {
      callback();
    }
  };

  submitForm = () => {
    const { form, onOk, editingUser } = this.props;

    form.validateFields((err, values) => {
      console.log(values);
      if (!err) {
        this.loading = true;
        if (!editingUser) {
          this.loading = true;
          onOk(
            { ...values, self_onboard: values.self_onboard === "true" },
            this.openMail
          );
        } else {
          this.loading = true;
          onOk(omit(values, "self_onboard"), this.openMail);
        }
      }
    });
  };
  /* eslint-disable */
  handleSubmit = (e) => {
    const { form, onOk, editingUser } = this.props;
    e.preventDefault();

    if (editingUser && form.getFieldValue("role_id") != editingUser.role_id) {
      Modal.confirm({
        title: "Confirm Role Change",
        content:
          "By changing this user's role, you will also set their habits to the new role's habits.  Continue?",
        onOk: this.submitForm
      });
    } else {
      this.submitForm();
    }
  };

  roleIsRequired = () => {
    const { form } = this.props;
    try {
      return form.getFieldValue("should_receive_feedback");
    } catch {
      return false;
    }
  };
  teamRequired = () => {
    const { form } = this.props;
    try {
      return form.getFieldValue("self_onboard") === "false";
    } catch {
      return false;
    }
  };

  componentWillReceiveProps(newProps) {
    if (!newProps.open) {
      this.loading = false;
    }
  }

  render() {
    const {
      open,
      asSuperAdmin,
      onCancel,
      editingUser,
      form,
      roles,
      organization,
      teams,
      profileStore,
      locations
    } = this.props;
    const { getFieldDecorator } = form;
    const showConfig = organization.features.includes("HIDE_CONFIG_FROM_ADMINS")
      ? profileStore.currentUser.can_configure
      : true;
    return (
      <Modal
        visible={open}
        destroyOnClose
        title={editingUser ? "Edit User" : "Create User"}
        onCancel={onCancel}
        onOk={this.handleSubmit}
        width={500}
        footer={[
          <Button key="cancel" onClick={onCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            loading={this.loading}
            type="primary"
            onClick={this.handleSubmit}
          >
            Submit
          </Button>
        ]}
      >
        <Row gutter={24}>
          <Col span={12}>
            <FormItem label="First Name">
              {getFieldDecorator("first_name", {
                rules: [
                  {
                    required: true,
                    message: "First name is required"
                  }
                ],
                initialValue: editingUser ? editingUser.first_name : null
              })(<Input />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Last Name">
              {getFieldDecorator("last_name", {
                rules: [
                  {
                    required: true,
                    message: "Last name is required"
                  }
                ],
                initialValue: editingUser ? editingUser.last_name : null
              })(<Input />)}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem label="Email">
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    message: "Email is required"
                  },
                  { validator: this.handleUniqueEmail }
                ],
                initialValue: editingUser ? editingUser.email : null
              })(<Input />)}
            </FormItem>
          </Col>
        </Row>
        {editingUser && (
          <Row>
            <Col span={24}>
              <FormItem label="Carrots">
                {getFieldDecorator("current_quarter_carrots", {
                  initialValue: editingUser
                    ? editingUser.current_quarter_carrots
                    : 0
                })(<InputNumber />)}
              </FormItem>
            </Col>
          </Row>
        )}

        <Row gutter={24}>
          <Col span={!(editingUser && editingUser.onboarded) ? 12 : 24}>
            <FormItem label="Title">
              {getFieldDecorator("title", {
                rules: [
                  {
                    required: true,
                    message: "Title is required"
                  }
                ],
                initialValue: editingUser ? editingUser.title : null
              })(<Input />)}
            </FormItem>
          </Col>
          <Col span={12}>
            {organization.features.includes("PRONOUNS") ? (
              <FormItem label="Pronouns">
                {getFieldDecorator("gender", {
                  rules: [
                    {
                      required: true,
                      message: "Pronouns are required"
                    }
                  ],
                  initialValue: editingUser ? editingUser.gender : "male"
                })(
                  <Select style={{ width: "100%" }}>
                    <Option value="male">He/Him</Option>
                    <Option value="female">She/Her</Option>
                    <Option value="neutral">They/Them</Option>
                  </Select>
                )}
              </FormItem>
            ) : (
              <FormItem label="Gender">
                {getFieldDecorator("gender", {
                  rules: [
                    {
                      required: true,
                      message: "Gender is required"
                    }
                  ],
                  initialValue: editingUser ? editingUser.gender : "male"
                })(
                  <Select style={{ width: "100%" }}>
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                  </Select>
                )}
              </FormItem>
            )}
          </Col>

          <Col span={12}>
            <FormItem label="User Level">
              {getFieldDecorator("level", {
                rules: [
                  {
                    required: true,
                    message: "Level is required"
                  }
                ],
                initialValue: getUserLevel(editingUser)
              })(
                <Select style={{ width: "100%" }}>
                  <Option key="user">User</Option>
                  <Option key="manager">Manager</Option>
                  {showConfig && <Option key="admin">Admin</Option>}
                </Select>
              )}
            </FormItem>
          </Col>
          {!editingUser && (
            // <Row>
            <Col span={24}>
              <FormItem label="Should Receive Feedback?">
                {getFieldDecorator("should_receive_feedback", {
                  valuePropName: "checked",
                  initialValue: true
                })(<Switch />)}
              </FormItem>
            </Col>
            // </Row>
          )}

          {(editingUser && editingUser.onboarded) || (
            <Col span={12}>
              <FormItem label="Onboarding">
                {getFieldDecorator("self_onboard", {
                  rules: [{ required: true, message: "Field is required" }],
                  initialValue: editingUser
                    ? editingUser.onboard_self_preference.toString()
                    : organization.settings.users_self_onboard.toString()
                })(
                  <Select style={{ width: "100%" }}>
                    <Option value="true">
                      Self Onboard{" "}
                      {organization.settings.users_self_onboard && "(Default)"}
                    </Option>
                    <Option key={false}>
                      Manager Onboard{" "}
                      {!organization.settings.users_self_onboard && "(Default)"}
                    </Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          )}
          <Col span={12}>
            <FormItem label="Role">
              {getFieldDecorator("role_id", {
                rules: [
                  {
                    required: this.roleIsRequired(),
                    message: "Role is required if user is receiving feedback"
                  }
                ],
                initialValue: editingUser
                  ? editingUser.role_id &&
                    roles.find((r) => r.id === editingUser.role_id).id
                  : null
              })(
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={nameFilter}
                >
                  {roles &&
                    roles.map((r) => <Option key={r.id}>{r.name}</Option>)}
                </Select>
              )}
            </FormItem>
          </Col>
          {showConfig && (
            <Col span={12}>
              <FormItem label="Can Configure Organization?">
                {getFieldDecorator("can_configure", {
                  rules: [
                    {
                      required: true,
                      message: "Field is required"
                    }
                  ],
                  initialValue: editingUser
                    ? editingUser.can_configure.toString()
                    : "false"
                })(
                  <Select style={{ width: "100%" }}>
                    <Option value={"false"}>No</Option>
                    <Option value={"true"}>Yes</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          )}
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <FormItem label="Team">
              {getFieldDecorator("team_id", {
                rules: [
                  {
                    required: this.teamRequired(),
                    message:
                      "Team is required if Onboarding is 'Manager Onboard'"
                  }
                ],
                initialValue: editingUser
                  ? editingUser.team_id &&
                    teams.find((t) => t.id === editingUser.team_id).id
                  : null
              })(
                <Select
                  allowClear
                  showSearch
                  style={{ width: "100%" }}
                  filterOption={(input, { props }) => {
                    return props.children
                      .join("")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {teams &&
                    teams
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((t) => (
                        <Option key={t.id}>
                          {t.name} ({t.manager_name})
                        </Option>
                      ))}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        {locations && locations.length > 0 && (
          <Row gutter={24}>
            <Col span={24}>
              <FormItem label="Location">
                {getFieldDecorator("location_id", {
                  initialValue: editingUser ? editingUser.location_id : null
                })(
                  <TreeSelect
                    style={{ width: 250, height: 34, marginRight: 12 }}
                    placeholder="Set User Location"
                    treeData={locations}
                    allowClear
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        )}
      </Modal>
    );
  }
}

UserForm.defaultProps = {
  open: false,
  features: [],
  editingUser: null
};

UserForm.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  editingUser: PropTypes.object,
  features: PropTypes.array,
  form: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  organization: PropTypes.object.isRequired,
  teams: PropTypes.array
};

const WrappedUserForm = Form.create()(UserForm);
export default WrappedUserForm;
