/* eslint-disable react/prop-types */
import React from "react";
import { inject, observer } from "mobx-react";
import { Alert, Layout, Modal, Input } from "antd";
import queryString from "query-string";

import { observable } from "mobx";
import Logo from "assets/text_black.png";
import Art from "assets/Pittsburgh_end.svg";
import Carrot from "assets/mark_white.png";
import PropTypes from "prop-types";

import SignInForm from "./SignInForm";
import ResetPassForm from "./ResetPassForm";
import { AvatarWrapper, SuperSecretButton, StyledTabs as Tabs } from "./styles";
import SAMLSignIn from "./samlSignIn";
import EmailPasswordSignIn from "./EmailPasswordSignIn";

@inject("profileStore")
@observer
class SignIn extends React.Component {
  @observable resetPass = this.reset;
  @observable loading = false;
  @observable sent = false;
  @observable email = null;
  @observable tenant = "";
  @observable samlError = null;
  @observable supportPass = "";
  @observable saml = null;
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.reset = props.resetPass;
  }

  static propTypes = {
    resetPass: PropTypes.bool.isRequired,
    profileStore: PropTypes.object.isRequired,
    code: PropTypes.string.isRequired
  };

  openSupportPass = () => {
    const that = this;
    const { profileStore } = this.props;
    Modal.info({
      title: "Enter support password",
      onOk: () =>
        profileStore.login({
          email: "support@rhabitapp.com",
          password: this.supportPass
        }),
      content: (
        <Input
          type="password"
          onChange={(e) => {
            that.supportPass = e.target.value;
          }}
        />
      )
    });
  };

  loginWithEmailPassword = ({ email, password }) => {
    const { profileStore } = this.props;

    profileStore.login({ email, password });
  };

  submit = (params) => {
    const { profileStore } = this.props;
    const withSlack = window.location.pathname === "/slack";
    let nextParams = Object.assign({}, params);
    if (withSlack) {
      const { ref, slackUserId } = queryString.parse(window.location.search);
      nextParams = { ...nextParams, ref, slackUserId };
    }
    this.loading = true;

    this.errors = false;
    try {
      profileStore.sendMagicLink(nextParams);
      this.sent = true;
      this.email = params.email;
    } finally {
      this.loading = false;
    }
  };

  render() {
    const withSlack = window.location.pathname === "/slack";
    const { profileStore, code } = this.props;
    const width = window.innerWidth < 500 ? 300 : 425;
    const height = window.innerWidth < 500 ? "50vw" : 100;
    return (
      <Layout
        style={{
          minHeight: window.innerWidth > 500 && "100vh",
          position: "relative",
          zIndex: 1,
          display: "flex",
          alignItems: "center"
        }}
      >
        <SuperSecretButton onClick={this.openSupportPass} />
        <img
          alt="pittsburgh"
          src={Art}
          style={{
            position: "fixed",
            bottom: this.mobile && window.location.pathname === "/" ? 64 : 0,
            right: !this.mobile && 0,
            width: this.mobile ? "100%" : 800,
            height: "auto",
            zIndex: 1
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 100,
            zIndex: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <img
            alt="logo"
            // width=""
            height={height}
            src={Logo}
            style={{ marginBottom: 75 }}
          />
          {this.sent ? (
            <div
              style={{
                width,
                background: "white",
                borderRadius: 4,
                padding: 25,
                paddingTop: 60,
                position: "relative",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.18)"
              }}
            >
              <AvatarWrapper>
                <img
                  src={Carrot}
                  style={{ width: 35 }}
                  alt="here lies a carrot"
                />
              </AvatarWrapper>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 18,
                  width: "100%",
                  textAlign: "center"
                }}
              >
                Check Your Email
              </div>
              <div
                style={{
                  fontSize: 18,
                  width: "100%",
                  textAlign: "center",
                  marginBottom: 20
                }}
              >
                {`We've sent a login link to ${this.email}`}
              </div>

              <div
                style={{
                  fontSize: 14,
                  width: "100%",
                  textAlign: "center",
                  marginTop: 45
                }}
              >
                Didn&apos;t receive your email? Check your spam folder. Still
                can&apos;t find it?
                <a href="mailto:support@rhabitapp.com?subject=Support%20Request">
                  Contact support
                </a>
              </div>
            </div>
          ) : (
            <div
              style={{
                width,
                background: "white",
                borderRadius: 4,
                padding: 25,
                margin: "0 auto",
                paddingTop: 60,
                position: "relative",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.18)"
              }}
            >
              <AvatarWrapper>
                <img
                  src={Carrot}
                  style={{ width: 58, marginLeft: 10 }}
                  alt="here lies a carrot"
                />
              </AvatarWrapper>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 18,
                  width: "100%",
                  textAlign: "center"
                }}
              >
                Welcome to Rhabit
              </div>
              <Tabs>
                <Tabs.TabPane
                  key="1"
                  tab="Magic Link"
                  style={{ padding: 0, border: "none" }}
                >
                  <div
                    style={{
                      fontSize: 18,
                      width: "100%",
                      textAlign: "center",
                      marginBottom: 45
                    }}
                  >
                    {withSlack
                      ? "You need to be signed in to link your account to Slack, enter your company email below to receive a login link and complete the flow."
                      : "Enter your company email below to receive a login link"}
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      bottom: "105%",
                      left: 0,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {profileStore.error && (
                      <Alert
                        style={{ width: "100%" }}
                        showIcon
                        message="Invalid Email and/or Password"
                        type="error"
                      />
                    )}
                  </div>
                  {this.resetPass ? (
                    <ResetPassForm
                      changeToSignIn={() => (this.resetPass = false)}
                      code={code}
                      loading={this.loading}
                      submit={this.resetPassword}
                    />
                  ) : (
                    <SignInForm loading={this.loading} submit={this.submit} />
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane
                  key="2"
                  tab="Password"
                  style={{ padding: 0, border: "none" }}
                >
                  <EmailPasswordSignIn onSubmit={this.loginWithEmailPassword} />
                </Tabs.TabPane>
                <Tabs.TabPane
                  key="3"
                  tab="Single Sign On"
                  style={{ padding: 0, border: "none" }}
                >
                  <SAMLSignIn />
                </Tabs.TabPane>
              </Tabs>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}

export default SignIn;
