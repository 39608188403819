export function hexToRgb(hex, alpha) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (alpha && result) {
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: alpha
    };
  }

  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}
const blackOrWhite = param => {
  if (param) {
    const {
      r,
      g,
      b
    } = param;
    if (r * 0.299 + g * 0.587 + b * 0.114 > 186) {
      return '#000000';
    }
    return '#ffffff';
  }
  return '#000000';
};

export default (color => {
  return blackOrWhite(hexToRgb(color));
});