/* eslint-disable */

import React from "react";
import PropTypes from "prop-types";

import { Modal, Form, Input, Checkbox } from "antd";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;
const FormItem = Form.Item;
const SuggestionForm = ({
  visible,
  onCancel,
  onCreate,
  form,
  orgName,
  title,
  suggestionManagers,
  label,
  cancelText,
  form: { getFieldDecorator }
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      title={t("suggestion_form.title")}
      okText={t("send")}
      cancelText={t("cancel")}
      onCancel={onCancel}
      destroyOnClose
      onOk={() => {
        onCreate(form.getFieldsValue());
      }}
    >
      <Form layout="vertical">
        <FormItem label={t("suggestion_form.label")}>
          {getFieldDecorator("content", {
            rules: [
              { required: true, message: t("suggestion_form.content_required") }
            ]
          })(
            <TextArea
              autoSize={{ minRows: 4 }}
              placeholder={t("suggestion_form.title", { orgName })}
            />
          )}
        </FormItem>
        <FormItem
          style={{ display: "flex" }}
          label={t("suggestion_form.i_want_a_follow_up")}
        >
          {getFieldDecorator("follow_up")(
            <Checkbox style={{ marginLeft: 8 }} />
          )}
        </FormItem>
      </Form>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ marginTop: -35, marginBottom: 35, fontStyle: "italic" }}>
          {t("suggestion_form.suggestions_anonymous")}
        </span>
        {suggestionManagers.length > 0 && (
          <span>
            {suggestionManagers.join(", ")}{" "}
            {t("suggestion_form.will_receive_your_suggestion")}
          </span>
        )}
      </div>
    </Modal>
  );
};

SuggestionForm.defaultProps = {
  title: "What's Your Suggestion?",
  label: "Suggestion Text",
  cancelText: "Cancel"
};

SuggestionForm.propTypes = {
  suggestionManagers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  orgName: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
  title: PropTypes.string,
  cancelText: PropTypes.string,
  label: PropTypes.string,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired
  }).isRequired
};

export default Form.create()(SuggestionForm);
