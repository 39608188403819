import styled from "styled-components";
import { media } from "utils/style-utils";

export const Header = styled.span`
  font-size: ${({
  theme
}) => theme.font.subheader}px;
  // font-weight: 500;
  ${media.handheld`
    // text-align: center
    // paddding: 10px 0px 5px 0px
  `}
`;

export const Raters = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 8px;
  margin-right: 25px;
  ${media.handheld`
    justify-content: center
  `};
`;