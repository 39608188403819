/* eslint-disable */
import * as Types from '../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type IFetchUserObjectivesQueryVariables = Types.Exact<{
  ownerId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type IFetchUserObjectivesQuery = (
  { __typename?: 'Query' }
  & { objectives: (
    { __typename?: 'ObjectiveConnection' }
    & { nodes: Array<(
      { __typename?: 'Objective' }
      & Pick<Types.IObjective, 'id' | 'name' | 'latestResponse' | 'latestResponseDate' | 'deliveryDate'>
    )> }
  ) }
);


export const FetchUserObjectivesDocument = gql`
    query fetchUserObjectives($ownerId: String) {
  objectives(filter: {owner_id_eq: $ownerId, status_eq: "IN_PROGRESS"}) {
    nodes {
      id
      name
      latestResponse
      latestResponseDate
      deliveryDate
    }
  }
}
    `;

/**
 * __useFetchUserObjectivesQuery__
 *
 * To run a query within a React component, call `useFetchUserObjectivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserObjectivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserObjectivesQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useFetchUserObjectivesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IFetchUserObjectivesQuery, IFetchUserObjectivesQueryVariables>) {
        return ApolloReactHooks.useQuery<IFetchUserObjectivesQuery, IFetchUserObjectivesQueryVariables>(FetchUserObjectivesDocument, baseOptions);
      }
export function useFetchUserObjectivesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IFetchUserObjectivesQuery, IFetchUserObjectivesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IFetchUserObjectivesQuery, IFetchUserObjectivesQueryVariables>(FetchUserObjectivesDocument, baseOptions);
        }
export type FetchUserObjectivesQueryHookResult = ReturnType<typeof useFetchUserObjectivesQuery>;
export type FetchUserObjectivesLazyQueryHookResult = ReturnType<typeof useFetchUserObjectivesLazyQuery>;
export type FetchUserObjectivesQueryResult = ApolloReactCommon.QueryResult<IFetchUserObjectivesQuery, IFetchUserObjectivesQueryVariables>;