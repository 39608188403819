/* eslint-disable */
import * as Types from '../../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type ICancelVacationMutationVariables = Types.Exact<{
  input: Types.ICancelVacationInput;
}>;


export type ICancelVacationMutation = (
  { __typename?: 'Mutation' }
  & { cancelVacation?: Types.Maybe<(
    { __typename?: 'Vacation' }
    & Pick<Types.IVacation, 'id'>
  )> }
);


export const CancelVacationDocument = gql`
    mutation cancelVacation($input: CancelVacationInput!) {
  cancelVacation(input: $input) {
    id
  }
}
    `;
export type ICancelVacationMutationFn = ApolloReactCommon.MutationFunction<ICancelVacationMutation, ICancelVacationMutationVariables>;

/**
 * __useCancelVacationMutation__
 *
 * To run a mutation, you first call `useCancelVacationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelVacationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelVacationMutation, { data, loading, error }] = useCancelVacationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelVacationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICancelVacationMutation, ICancelVacationMutationVariables>) {
        return ApolloReactHooks.useMutation<ICancelVacationMutation, ICancelVacationMutationVariables>(CancelVacationDocument, baseOptions);
      }
export type CancelVacationMutationHookResult = ReturnType<typeof useCancelVacationMutation>;
export type CancelVacationMutationResult = ApolloReactCommon.MutationResult<ICancelVacationMutation>;
export type CancelVacationMutationOptions = ApolloReactCommon.BaseMutationOptions<ICancelVacationMutation, ICancelVacationMutationVariables>;