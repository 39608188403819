import React from "react";
import { ReactComponent as First } from "assets/dashboard/suggested_employee_feedback_provider.svg";

import { Flair } from "components/Layout/styles";
import { useTranslation } from "react-i18next";
import { ContentContainer, DescContainer, Header } from "../styles";

const ExplainOverall = () => {
  const { t } = useTranslation();
  return (
    <ContentContainer>
      <DescContainer>
        <Header data-cy="step1title">
          {t("deep_dive.more_feedback_wizard.overall_header")}
        </Header>
        <p>{t("deep_dive.more_feedback_wizard.overall_paragraph_1")}</p>
        <p>{t("deep_dive.more_feedback_wizard.overall_paragraph_2")}</p>
        <Header>{t("deep_dive.more_feedback_wizard.how_it_works")}</Header>
        <p>{t("deep_dive.more_feedback_wizard.overall_paragraph_3")}</p>
        <p>{t("deep_dive.more_feedback_wizard.overall_paragraph_4")}</p>
      </DescContainer>

      <Flair style={{ width: "25%", maxHeight: "unset" }}>
        <First style={{ width: "100%", height: "auto" }} />
      </Flair>
    </ContentContainer>
  );
};

export default ExplainOverall;
