import { get, post, put, destroy } from "./base";
import { paramsIntercept } from "../utils/objectiveQueryIntercept";
import { API_URL } from "../env";
import { AxiosPromise } from "axios";

const trueBase = `${API_URL.replace("/api/v2", ``)}`;
// export const SuperAdmin = () => {
//   constructor(id) {
//     this.id = id;
//   }
//   fetch: () =>
//     get('/super_admin'),
//   fetchOrganizations: () =>
//     get('/super_admin/organizations')
// }

export class SuperAdmin {
  constructor(id) {
    this.id = id;
  }
  getPresignedUrl = (options) => get("/super_admin/presigned_url", options);
  fetch = () => get("/super_admin");
  create = (params) => post("/super_admin", { super_admin: params });
  importStatus = (jid) => get(`/super_admin/import_status?id=${jid}`);
  userImportStatus = () => get(`/super_admin/import_status?user=true`);
  fetchLogs = (page) =>
    get(`/super_admin/organizations/${this.id}/logs?page=${page}`);
  fetchUserLogs = (userId, page) =>
    get(
      `/super_admin/organizations/${this.id}/logs?page=${page}&user_id=${userId}`
    );
  fetchOrganizations = (query) => get("/super_admin/organizations" + query);
  fetchOrganization = (id) => get(`/super_admin/organizations/${id}`);
  fetchHabits = () => get(`/super_admin/organizations/${this.id}/habits`);
  uploadLogo = (data) =>
    post(`/super_admin/organizations/${this.id}/upload_logo`, data, {
      headers: { "content-type": "multipart/form-data" }
    });
  deleteLogo = (data) => {
    return put(`/super_admin/organizations/${this.id}/delete_logo`, data, {
      headers: { "content-type": "multipart/form-data" }
    });
  };
  setColor = (data) =>
    put(`/super_admin/organizations/${this.id}/set_color`, data);
  createHabit = (params) =>
    post(`/super_admin/organizations/${this.id}/habits`, {
      habit: params
    });
  bulkCreateHabit = (formData) =>
    post(`/super_admin/organizations/${this.id}/habits`, formData, {
      headers: { "content-type": "multipart/form-data" }
    });
  updateHabit = (id, params) =>
    put(`/super_admin/organizations/${this.id}/habits/${id}`, {
      habit: params
    });
  fetchBehaviors = () => get(`/super_admin/organizations/${this.id}/behaviors`);
  fetchTeams = () => get(`/super_admin/organizations/${this.id}/teams`);
  createBehavior = (params) =>
    post(`/super_admin/organizations/${this.id}/behaviors`, {
      behavior: params
    });
  updateBehavior = (id, params) =>
    put(`/super_admin/organizations/${this.id}/behaviors/${id}`, {
      behavior: params
    });
  fetchUsers = (page, per, query) =>
    get(
      `/super_admin/organizations/${this.id}/users?page=${page}&per=${per}&${query}`
    );
  createUser = (params) =>
    post(`/super_admin/organizations/${this.id}/users`, {
      user: params
    });
  fetchUsersNotOnboarded = () =>
    get(`/super_admin/organizations/${this.id}/users?onboarded`);
  fetchUser = (id) => get(`/super_admin/organizations/${this.id}/users/${id}`);
  bulkCreateUser = (formData) =>
    post(`/super_admin/organizations/${this.id}/users`, formData, {
      headers: { "content-type": "multipart/form-data" }
    });
  updateUser = (id, params) =>
    put(`/super_admin/organizations/${this.id}/users/${id}`, {
      user: params
    });
  sendMagicLink = (id, email) =>
    post(`/super_admin/organizations/${this.id}/users/${id}/magic_link`, {
      email
    });
  generateLoginLink = (id) =>
    post(`/super_admin/organizations/${this.id}/users/${id}/login_link`);
  sendTextMessage = (id, number) =>
    post(`/super_admin/organizations/${this.id}/users/${id}/send_text`, {
      number
    });
  fetchRoles = () => get(`/super_admin/organizations/${this.id}/roles`);
  createRole = (params) =>
    post(`/super_admin/organizations/${this.id}/roles`, {
      role: params
    });
  fetchWidgets = () => get("/super_admin/widgets");
  resetDemo = () => post("/super_admin/reset_demo");
  updateWidget = (id, type, params) =>
    put(`/super_admin/widgets/${id}?type=${type}`, { widget: params });
  updateOrganization = (params, id = this.id) =>
    put(`/super_admin/organizations/${id}`, params);
  enableFeature = (params, id = this.id) =>
    put(`/super_admin/organizations/${id}/enable_feature`, params);
  disableFeature = (params, id = this.id) =>
    put(`/super_admin/organizations/${id}/disable_feature`, params);
  fetchFeatures = (id = this.id) =>
    get(`/super_admin/organizations/${id}/features`);
  updateOrganization = (params, id = this.id) =>
    put(`/super_admin/organizations/${id}`, params);
  createOrganization = (params) =>
    post("/super_admin/organizations", { organization: params });

  regenerateDemo = () => post(`/super_admin/regenerate_demo`);
  demoStatus = (id) => get(`/super_admin/regenerate_demo_status/${id}`);
  // updateRole: (id, params) => put(`/admin/roles/${id}`, { role: params })
  // deleteRole: id => destroy(`/admin/roles/${id}`)
}

export const AdditionalFeedback = {
  answer: (id, params) => put(`additional_feedback/${id}`, params),
  create: (params) => post("/additional_feedback", params),
  destroy: (habit_id) => destroy(`/additional_feedback/${habit_id}`),
  updateFeedback: (id, params) =>
    put(`additional_feedback/${id}/feedback_answer`, params)
};

export const Prompt = {
  fetch: (query) => get(`/prompts?${query || ""}`)
};

export const TutorialView = {
  all: (): AxiosPromise<any> => get("/profile/tutorial_views"),
  create: (params): AxiosPromise<any> => post("/profile/tutorial_views", params)
};

export const Profile = {
  fetch: () => get("/profile?initial=true"),
  claimLoginBonus: () => post("profile/login_bonus"),
  exchangeToken: () => post("/exchange_token"),
  privacyConsent: (id, params) => put(`/profile/${id}/privacy_consent`, params),
  fetchNotifications: (page, offset) =>
    get(`/profile/notifications?page=${page}&offset=${offset}`),
  markNotificationsSeen: () => put("/profile/notifications"),
  fetchHabit: (id, timeRange) =>
    get(`/profile/habits/${id}${timeRange ? `?time=${timeRange}` : ""}`),
  fetchHabits: (query) => get(`/profile/habits${query ? `?${query}` : ""}`),
  update: (params) =>
    put("/profile", params, {
      headers: { "content-type": "multipart/form-data" }
    }),
  avatarFromLinkedIn: (access_token) =>
    post("/profile/avatar_from_linkedin", { access_token }),
  login: (params) => post("/account/sign_in", params),
  magicLink: (params) => post("/magic_link", params),
  sendOnboardingEmail: (email) => {
    return post("/send_test_email", { email });
  },
  sendPhoneVerification: (params) =>
    post("/profile/send_phone_verification", params),
  checkPhoneVerification: (params) =>
    post("/profile/check_phone_verification", params),
  sendResetEmail: (email) => post("/send_reset_password", { email }),
  setLinkedInDismissed: () => post("/profile/dismissed_linked_in"),
  sendFeedback: (params) => post("/profile/send_feedback", params),
  requestSupport: (params) => post("/profile/request_support", params),
  resetPassword: (params) => post("/reset_password", params),
  createProposals: (params, notificationId) =>
    notificationId
      ? post(`/profile/proposals?notification_id=${notificationId}`, params)
      : post(`/profile/proposals`, params),
  markProposalsAsSeen: () => post("profile/proposals/mark_as_seen"),
  fetchQuestions: () => get("/profile/answers"),
  answerQuestion: (questionId, response) =>
    put(`/profile/answers/${questionId}`, { answer: { response } }),
  reports: () => get("profile/reports"),
  fetchReport: (id) => get(`profile/reports/${id}`),
  fetchEditableFeedback: () => get("/profile/answers?scope=editable"),
  submitNPS: (id, params) => put(`/profile/nps_questions/${id}`, params),
  fetchDashboardViewers: () => get("/profile/dashboard_tokens"),
  revokeDashboardViewer: (id) => destroy(`/profile/dashboard_tokens/${id}`),
  createDashboardViewer: (params) =>
    post(`/profile/dashboard_tokens`, { dashboard_token: params })
};

export const ManagedLocations = {
  all: () => get("admin/locations"),
  single: (id) => get(`admin/locations/${id}`),
  update: (id, params) => put(`admin/locations/${id}`, params)
};

export const Organization = {
  fetch: () => get("/admin/organization"),
  update: (params) => put("admin/organization", params),
  enableFeature: (params) => put("admin/organization/enable_feature", params),
  disableFeature: (params) => put("admin/organization/disable_feature", params),
  fetchFeatures: () => get(`/admin/organization/features`),
  fetchNetworkHealth: () => get("/admin/organization/network_health"),
  fetchONA: (type) => get(`/admin/organization/network?type=${type}`)
};

export const SuggestionBoxPrompt = {
  all: () => get("/profile/suggestion_box_prompts"),
  discard: (id) => put(`/profile/suggestion_box_prompts/${id}/discard`)
};

export const Update = {
  create: (params) => post("/update_modals", params),
  all: () => get("/update_modals"),
  delete: (id) => destroy(`/update_modals/${id}`),
  update: (id, params) => put(`/update_modals/${id}`, params),
  fetchLatest: () => get("/update_modals/latest"),
  markSeen: (id) => post(`/update_modals/${id}/mark_seen`)
};

export const QuickPoll = {
  create: (params) => post("/quick_polls", params),
  update: (id, params) => put(`/quick_polls/${id}`, params),
  fetchActive: () => get("/quick_polls"),
  fetch: (id) => get(`/quick_polls/${id}`)
};

export const Highfive = {
  create: (params) => post("/highfives", { highfive: params }),
  createComment: (id, params) =>
    post(`/highfives/${id}/comments`, { comment: params }),
  deleteComment: (id, commentId) =>
    destroy(`/highfives/${id}/comments/${commentId}`),
  delete: (id) => destroy(`/highfives/${id}`),
  fetch: (id) => get(`/highfives/${id}`),
  fetchComments: (id) => get(`/highfives/${id}/comments`)
};

export const BadgeUnlock = {
  createComment: (id, params) =>
    post(`/badge_unlocks/${id}/comments`, { comment: params })
};

export const Announcement = {
  create: (params) => post("/announcements", { announcement: params }),
  delete: (id) => destroy(`/announcements/${id}`)
};

export const Slack = {
  create: (params) => post(`${trueBase}/slack/users`, params),
  oauth: (params) => post(`${trueBase}/slack/auth`, params)
};
export const MSTeams = {
  create: (params) => post(`${trueBase}/msteams/users`, params),
  oauth: (params) => post(`${trueBase}/msteams/auth`, params)
};

export const Widget = {
  create: (params) => post("/widgets", { widget: params }),
  update: (id, type, params) =>
    put(`/widgets/${id}?type=${type}`, { widget: params }),
  delete: (id, type) => destroy(`/widgets/${id}?type=${type}`)
};

export const Feed = {
  fetch: (page) => get(`/feed?page=${page}`),
  current: () => get(`/feed/current`)
};

export const RatingRequest = {
  accept: (id) => put(`/rating_requests/${id}`, { accepted: true }),
  reject: (id) => put(`/rating_requests/${id}`, { accepted: false })
};

export const Suggestion = {
  update: (id, params) => put(`/suggestions/${id}`, { suggestion: params }),
  create: (params) => post("/suggestions", { suggestion: params })
};

export const Team = {
  fetch: () => get("/manager/team"),
  fetchDashboard: () => get("/manager/dashboard"),
  fetchNetworkHealth: () => get("/manager/team/network_health"),
  habits: () => get("/manager/team/habits"),
  users: () => get("/manager/team/users"),
  usersNotOnboarded: () => get("/manager/team/users?onboarded"),
  update: (params) => put("/manager/team/", params),
  fetchProposals: () => get("/manager/team/proposals"),
  updateProposals: (params) =>
    put("manager/team/proposals", { proposal: params })
};

export const Teams = {
  all: (page, per, search) =>
    get(`/admin/teams?page=${page}&per=${per}${search || ""}`),
  create: (params) => {
    return post("/admin/teams", { team: params });
  },
  update: (id, params) => put(`/admin/teams/${id}`, { team: params }),
  delete: (id) => destroy(`/admin/teams/${id}`),
  single: (id) => get(`/teams/${id}`)
};

export const RemovalPrompt = {
  create: (answerIds) =>
    post(`/removal_prompts`, { removal_prompt: { answer_ids: answerIds } }),
  update: (id, response) =>
    put(`/removal_prompts/${id}`, { removal_prompt: { response } })
};

export const AdminProducts = {
  all: (query) => get(`/admin/products?${query || ""}`),
  create: (formData) =>
    post("/admin/products", formData, {
      headers: { "content-type": "multipart/form-data" }
    }),
  update: (id, params) =>
    put(`/admin/products/${id}`, params, {
      headers: { "content-type": "multipart/form-data" }
    }),
  delete: (id) => destroy(`/admin/products/${id}`)
};

export const AdminOrders = {
  all: (query) => get(`/admin/orders?${query || ""}`),
  update: (id, params) => put(`/admin/orders/${id}`, { order: params })
};

export const Leaderboard = {
  fetch: () => get("/leaderboard"),
  full: () => get("/leaderboard/full")
};

export const Relationships = {
  all: () => get("/profile/relationships"),
  external: () => get("/profile/relationships/external"),
  update: (id, params) =>
    put(`/profile/relationships/${id}`, { relationship: params }),
  delete: (id, query) => destroy(`/profile/relationships/${id}?${query}`),
  create: (params) => post(`/profile/relationships/`, { relationship: params }),
  bulkAdd: (params) => put(`/profile/relationships/bulk_add`, params),
  bulkRemove: (params) => put(`/profile/relationships/bulk_remove`, params)
};

export const UserRelationships = {
  all: (userId) => get(`/users/${userId}/relationships`),
  external: (userId) => get(`/users/${userId}/relationships/external`),
  update: (userId, id, params) =>
    put(`/users/${userId}/relationships/${id}`, {
      relationship: params
    }),
  delete: (userId, id) => destroy(`/users/${userId}/relationships/${id}`),
  create: (userId, params) =>
    post(`/users/${userId}/relationships/`, { relationship: params }),
  bulkAdd: (userId, params) =>
    put(`/users/${userId}/relationships/bulk_add`, params),
  bulkRemove: (userId, params) =>
    put(`/users/${userId}/relationships/bulk_remove`, params)
};

export const ManagerGroups = {
  all: () => get("/manager/groups"),
  single: (id) => get(`/manager/groups/${id}`)
};
export const AdminGroups = {
  all: (page, per, search) =>
    get(`/admin/groups?page=${page}&per=${per}${search || ""}`),
  single: (id) => get(`/admin/groups/${id}`),
  create: (params) => post(`/admin/groups/`, { group: params }),
  update: (id, params) => put(`/admin/groups/${id}`, { group: params }),
  delete: (id) => destroy(`/admin/groups/${id}`)
};

export const Habit = {
  all: () => get("/habits"),
  show: (id) => get(`/habits/${id}`)
};

export const Like = {
  create: (params) => post("/likes", { like: params }),
  delete: (id) => destroy(`/likes/${id}`)
};

export const AdminOneOnOnes = {
  create: (params) => post("/admin/one_on_ones", { one_on_one: params }),
  all: () => get("/admin/one_on_ones"),
  single: (id) => get(`/admin/one_on_ones/${id}`),
  delete: (id) => destroy(`/admin/one_on_ones/${id}`),
  status: (jid) => get(`/admin/one_on_ones/status?jid=${jid}`)
};

export const ManagerOneOnOnes = {
  create: (params) => post("/manager/one_on_ones", { one_on_one: params }),
  update: (id, params) =>
    put(`/manager/one_on_ones/${id}`, { one_on_one: params }),
  delete: (id) => destroy(`/manager/one_on_ones/${id}`),
  all: (query) => get(`/manager/one_on_ones?${query}`),
  single: (id, query = "") => get(`/manager/one_on_ones/${id}?${query}`),
  addTalkingPoint: (id, params) =>
    post(`/manager/one_on_ones/${id}/add_talking_point`, {
      talking_point: params
    })
};

export const TalkingPoints = {
  addComment: (id, params) =>
    post(`/talking_points/${id}/comments`, { comment: params }),
  create: (params) => post("/talking_points", { talking_point: params }),
  delete: (id) => destroy(`/talking_points/${id}`),
  all: (oooId) => get(`/talking_points?one_on_one_id=${oooId}`),
  update: (id, params) =>
    put(`/talking_points/${id}`, { talking_point: params }),
  deleteComment: (id, commentId) =>
    destroy(`/talking_points/${id}/comments/${commentId}`),
  updateComment: (id, commentId, params) =>
    put(`/talking_points/${id}/comments/${commentId}`, params)
};

export const Product = {
  all: (query) => get(`/products${query || ""}`)
};

export const UserOneOnOnes = {
  all: (query) => get(`/profile/one_on_ones?${query}`),
  single: (id) => get(`/profile/one_on_ones/${id}`)
};

export const Label = {
  create: (params) => post("/labels", { label: params }),
  all: () => get(`/labels`),
  delete: (id) => destroy(`/labels/${id}`),
  update: (id, params) => put(`/labels/${id}`, { label: params })
};

export const Category = {
  create: (params) => post("/categories", { category: params }),
  all: () => get(`/categories`),
  delete: (id) => destroy(`/categories/${id}`),
  update: (id, params) => put(`/categories/${id}`, { category: params })
};

export const Note = {
  create: (userId, params) => post(`/users/${userId}/notes`, params)
};

export const PerformanceReview = {
  generate: (cohortId, userId) =>
    get(
      `/admin/performance_reviews/generate?cohort_id=${cohortId}&user_id=${userId}`
    ),
  update: (id, params) =>
    put(`/admin/performance_reviews/${id}`, { performance_review: params }),
  fetch: (id) => get(`/admin/performance_reviews/${id}`),
  pastReviews: (id) => get(`/admin/performance_reviews/${id}/past`),
  create: (cohortId, userId, params) =>
    post(
      `/admin/performance_reviews?cohort_id=${cohortId}&user_id=${userId}`,
      params
    )
};

export const ReviewCohort = {
  create: (params) => post(`/admin/review_cohorts`, { review_cohort: params }),
  all: (page) => get(`/admin/review_cohorts?page=${page}`),
  single: (id) => get(`/admin/review_cohorts/${id}`)
};
export const PowerBI = {
  generateToken: ({ asManager }) =>
    get(`/admin/power_bi_token?as_manager=${asManager}`)
};

export const Objective = {
  orgData: () => get(`/admin/objectives/organization`),
  teamData: (query) => get(`/manager/objectives/team?${query}`),
  fetchXLSX: () => get(`/admin/objectives/xlsx`),
  fetchXLSXStatus: (id) => get(`/admin/objectives/xlsx_status/${id}`),
  adminData: (query) => get(`/admin/objectives/data?${query}`),
  summary: () => get("/objectives/summary"),
  tree: (id) => get(`/objectives/${id}/tree`),
  children: (id) => get(`objectives/${id}/children`),
  comments: (id) => get(`/objectives/${id}/comments`),
  generateOneOnOnes: (id) => post(`/objectives/${id}/generate_one_on_ones`),
  createComment: (id, params) =>
    post(`/objectives/${id}/comments`, { comment: params }),
  deleteComment: (objectiveId, commentId) =>
    destroy(`/objectives/${objectiveId}/comments/${commentId}`),
  all: (query) => get(`/objectives?${paramsIntercept(query)}`),
  fetch: (id) =>
    Array.isArray(id)
      ? get(`objectives?${id.map((t) => `objective_ids[]=${t}`).join("&")}`)
      : get(`objectives/${id}`),
  create: (params) => post(`/objectives`, { objective: params }),
  update: (id, params) => put(`/objectives/${id}`, { objective: params }),
  destroy: (id) => destroy(`/objectives/${id}`)
};

export const Review = {
  create: (params) => post(`/reviews`, { review: params }),
  createStandalone: (params) => post(`/reviews/standalone`, { review: params })
};

export const User = {
  all: (query) => (query ? get(`/users?name_search=${query}`) : get(`/users`)),
  query: (queryString) => get(`/admin/users/filtered?${queryString}`),
  byTeam: (teamId) => get(`/users?team[]=${teamId}`),
  single: (id) => get(`/users/${id}`),
  networkHealth: (id) => get(`admin/users/${id}/network_health`),
  habits: (id) => get(`/users/${id}/habits`),
  update: (id, params) => put(`/users/${id}`, { user: params }),
  privacyConsent: (id, params) =>
    put(`/users/${id}/privacy_consent`, { user: params }),
  networks: (id, giving) => get(`/users/${id}/networks?giving=${giving}`),
  managers: () => get(`/users?user_level=manager`),
  availableUsers: () => get("/users?team"),
  externalFeedbackUsers: () => get("/users?habits_users"),
  reports: (userId) => get(`/users/${userId}/reports`),
  requestImpersonation: (userId) => get(`/users/${userId}/impersonation`),
  singleReport: (userId, reportId) =>
    get(`/users/${userId}/reports/${reportId}`)
};

export const Admin = {
  fetchUsers: (page, pageSize, query) =>
    get(`/admin/users?page=${page}&page_size=${pageSize}&${query}`),
  exportUsers: () => get("/admin/users/export", { responseType: "blob" }),
  inviteUsers: (ids) => post("/admin/users/invite", { ids }),
  fetchUsersNotOnboarded: () => get("/admin/users?onboarded"),
  fetchUser: (id) => get(`/admin/users/${id}`),
  setAllEngagement: (enabled) =>
    put("/admin/organization/set_all_engagement", {
      engagement_enabled: enabled
    }),
  setAllAnnouncement: (enabled) =>
    put("/admin/organization/set_all_announcement", {
      announcement_enabled: enabled
    }),
  toggleAnnouncement: (user_id) =>
    put("/admin/organization/toggle_announcement", {
      user_id
    }),
  updateProposals: (params) => put("/admin/proposals", { proposal: params }),
  fetchProposals: (archived, page) =>
    archived
      ? get(`/admin/proposals?archived=1&page=${page}`)
      : get("/admin/proposals"),
  analyzeUsers: (query) => get(`admin/users/analytics?${query}`),
  createUser: (params) => post("/admin/users", { user: params }),
  updateUser: (id, params) => put(`/admin/users/${id}`, { user: params }),
  fetchRoles: () => get("/admin/roles"),
  createRole: (params) => post("/admin/roles", { role: params }),
  updateRole: (id, params) => put(`/admin/roles/${id}`, { role: params }),
  deleteRole: (id) => destroy(`/admin/roles/${id}`),
  fetchDashboard: () => get("admin/dashboard"),
  fetchNPSData: () => get("admin/nps_questions"),
  analytics: () => get("admin/users/other_analytics"),
  fetchInsightReports: () => get("admin/insight_reports"),
  fetchInsightReport: (id) => get(`admin/insight_reports/${id}`),
  createInsightReport: (params) => post(`admin/insight_reports`, params),
  newReportStatus: () => get("admin/insight_reports/status")
};

export const WorkItems = {
  all: () => get("/profile/work_items"),
  discard: (id) => put(`/profile/work_items/${id}/discard`)
};

export const ExternalUsers = {
  all: (query) =>
    query
      ? get(`/external_users?name_search=${query}`)
      : get(`/external_users`),
  create: (params) => post("/external_users", { external_user: params }),
  update: (id, params) =>
    put(`/external_users/${id}`, { external_user: params }),
  updateAll: (ids, user_ids, params) =>
    post("external_users/update_batch", {
      ids,
      user_ids,
      external_user: params
    })
};

export const NPSQuestions = {
  create: (params) => post("/nps_questions", { nps_question: params }),
  createAll: (ids) => post("/nps_questions/create_batch", { ids })
};

export const Widgets = {
  fetch: (behaviorId) => get(`/widgets?behavior=${behaviorId}`),
  fetchAll: () => get("/all_widgets")
};

export const Roles = {
  all: () => get("/roles"),
  single: (id) => get(`/roles/${id}`)
};

export const Onboarding = {
  invite: (emails) => post("/invite", { emails })
};

export const Feedback = {
  create: (params) => post("profile/feedbacks", params)
};

export const Order = {
  create: (params) => post("profile/orders", params),
  all: (query) => get(`profile/orders${query || ""}`),
  cancel: (order_id, params) => put(`profile/orders/${order_id}`, params)
};

export const Goals = {
  static: (page) => get(`/goals?static=1&page=${page}`),
  update: (id, params) => put(`/goals/${id}`, { goal: params }),
  updateTimeFrame: (id, params) =>
    put(`/goals/${id}/timeframe`, { goal: params }),
  delete: (id) => destroy(`/goals/${id}`),
  fetchByStakeholder: (id, status, page, cancel) =>
    get(`/goals?stakeholder=${id}&status=${status}&static=0&page=${page}`, {
      cancelToken: cancel
    }),
  fetchByAssignee: (id, status, page, cancel) =>
    get(`/goals?assignee=${id}&status=${status}&static=0&page=${page}`, {
      cancelToken: cancel
    }),
  fetchByTeam: (id, status, page, cancel) =>
    get(`/goals?team=${id}&status=${status}&static=0&page=${page}`, {
      cancelToken: cancel
    }),
  fetchActionRequired: () => get(`/goals?action_required=1&static=0`),
  fetch: (id) => get(`/goals/${id}`),
  create: (params, uniqueTag) =>
    post(`/goals`, { goal: params, tag: uniqueTag }),
  createStatic: (params) => post(`/goals/static`, { goal: params }),
  updateStatic: (id, params) => put(`/goals/static/${id}`, { goal: params }),
  fetchByTag: (tagIds, isStatic = 1, status = "pending", page) =>
    get(
      `/goals?status=${status}&page=${page}&static=${isStatic}&${tagIds
        .map((t) => `tag_ids[]=${t}`)
        .join("&")}`
    ),
  reviewDeliverables: (params) => post(`/goals/deliverables`, { goal: params })
};

export const Tags = {
  all: (query) => (query ? get(`/tags?query=${query}`) : get(`/tags`)),
  static: (query) =>
    query ? get(`/tags?query=${query}&static=1`) : get(`/tags?static=1`),
  create: (params) => post("/tags", { tag: params }),
  update: (id, params) => put(`/tags/${id}`, { tag: params }),
  delete: (id) => destroy(`/tags/${id}`)
};
