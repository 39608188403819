import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Progress, Icon } from "antd";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50;
  cursor: pointer;
`;

const getColor = (score) => {
  if (score < 65) {
    return "#FF9331";
  }
  if (score < 80) {
    return "#FDCE60";
  }
  return "#78D36F";
};

const PercentageCircle = ({ color, percent }) =>
  percent || percent === 0 ? (
    <Wrapper className="tiny-progress-circle">
      <Progress
        width={35}
        strokeColor={getColor(percent)}
        strokeWidth={9}
        type="circle"
        percent={percent}
        format={(val) => `${val}%`}
      />
    </Wrapper>
  ) : (
    <Wrapper>
      <Progress
        width={35}
        strokeColor={color}
        type="circle"
        percent={0}
        format={() => (
          <span style={{ fontSize: 14, marginBottom: 5 }}>
            <Icon type="lock" />
          </span>
        )}
      />
    </Wrapper>
  );

PercentageCircle.propTypes = {
  color: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired
};

export default PercentageCircle;
