/* eslint-disable */
import * as Types from '../../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type IUpdateRatersMutationVariables = Types.Exact<{
  input: Types.IUpdateRatersInput;
}>;


export type IUpdateRatersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.IMutation, 'updateRaters'>
);


export const UpdateRatersDocument = gql`
    mutation UpdateRaters($input: UpdateRatersInput!) {
  updateRaters(input: $input)
}
    `;
export type IUpdateRatersMutationFn = ApolloReactCommon.MutationFunction<IUpdateRatersMutation, IUpdateRatersMutationVariables>;

/**
 * __useUpdateRatersMutation__
 *
 * To run a mutation, you first call `useUpdateRatersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRatersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRatersMutation, { data, loading, error }] = useUpdateRatersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRatersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateRatersMutation, IUpdateRatersMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateRatersMutation, IUpdateRatersMutationVariables>(UpdateRatersDocument, baseOptions);
      }
export type UpdateRatersMutationHookResult = ReturnType<typeof useUpdateRatersMutation>;
export type UpdateRatersMutationResult = ApolloReactCommon.MutationResult<IUpdateRatersMutation>;
export type UpdateRatersMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateRatersMutation, IUpdateRatersMutationVariables>;