/* eslint-disable */
import React, { useState } from "react";
import { Row, Col, Avatar } from "antd";
import {
  Wrapper,
  LegendKey,
  Legend,
  UserWrapper,
  Mask,
  AxisLabel
} from "./styles";
import Box, { DisplayBox } from "./box";
import nameToInitials from "../../utils/nameToInitials";

const COORDINATES = {
  "0,2": "Diamond in the Rough",
  "1,2": "Future Star",
  "2,2": "Consistent Star",
  "0,1": "Developing Performer",
  "1,1": "Key Performer",
  "2,1": "High Professional +",
  "0,0": "Lower Performer",
  "1,0": "Solid Professional",
  "2,0": "High Professional"
};

const filterReviews = (coordinates, reviews) =>
  reviews.filter((review) => [review.x, review.y].toString() === coordinates);

const NineBox = ({ onSelect, review, summary, reviews, updateReview }) => {
  let current;
  let hide = false;
  let userCoordinates;
  const [moving, setMoving] = useState(null);
  if (!summary) {
    current = [review.original_x, review.original_y].toString();
    if (review.original_x === null || review.original_y === null) {
      hide = true;
    }
    userCoordinates =
      review.x !== null && review.x !== undefined
        ? [review.x, review.y].toString()
        : null;
  }

  const moveUser = (coordinate) => {
    updateReview(moving, coordinate);
    setMoving(null);
  };

  if (summary) {
    return (
      <div style={{ position: "relative", padding: 48, height: "100%" }}>
        <AxisLabel style={{ fontSize: 20, bottom: "12px" }} axis="x">
          Objectives
        </AxisLabel>
        <AxisLabel style={{ fontSize: 20, left: "-24px" }} axis="y">
          Habits
        </AxisLabel>
        <Wrapper style={{ height: "100%" }}>
          <Mask visible={!!moving} />
          {Object.keys(COORDINATES).map((coordinate) => (
            <DisplayBox
              id={coordinate.split(",").join("")}
              coordinates={coordinate}
              hide={hide}
              label={COORDINATES[coordinate]}
              onSelect={() => (moving ? moveUser(coordinate) : null)}
            >
              <Row gutter={12}>
                {filterReviews(coordinate, reviews).map((review) => (
                  <Col span={12} style={{ marginBottom: 6 }}>
                    <UserWrapper
                      onClick={() => setMoving(review.id)}
                      selected={moving === review.id}
                    >
                      <span style={{ fontSize: 14 }}>{review.user.name}</span>
                    </UserWrapper>
                  </Col>
                ))}
              </Row>
            </DisplayBox>
          ))}
        </Wrapper>
      </div>
    );
  }

  return (
    <Wrapper style={{ height: "100%" }}>
      <AxisLabel axis="x">Objectives</AxisLabel>
      <AxisLabel axis="y">Habits</AxisLabel>
      <Legend>
        {hide ? (
          "Insufficient data for suggestion"
        ) : (
          <>
            <LegendKey />
            Rhabit Suggested
          </>
        )}
      </Legend>

      {Object.keys(COORDINATES).map((coordinate) => (
        <Box
          id={coordinate.split(",").join("")}
          coordinates={coordinate}
          onSelect={onSelect}
          current={current}
          userCoordinates={userCoordinates}
          user={review.user}
          label={COORDINATES[coordinate]}
        />
      ))}
    </Wrapper>
  );
};

export default NineBox;
