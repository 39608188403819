/* eslint-disable */
import * as Types from '../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type IFetchManagedUserProfileQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type IFetchManagedUserProfileQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<Types.IUser, 'name' | 'id' | 'avatar' | 'title' | 'feedbackStreak' | 'latestResponse' | 'responseRate'>
    & { managedActionItems: Array<(
      { __typename?: 'ManagerActionItem' }
      & Pick<Types.IManagerActionItem, 'id' | 'name' | 'text' | 'completed'>
      & { subject: (
        { __typename?: 'HabitUser' }
        & Pick<Types.IHabitUser, 'id'>
      ) | { __typename?: 'User' } }
    )>, group?: Types.Maybe<(
      { __typename?: 'Team' }
      & Pick<Types.ITeam, 'id' | 'name'>
    )>, habitUsers: Array<(
      { __typename?: 'HabitUser' }
      & Pick<Types.IHabitUser, 'id' | 'validResponses' | 'unlockThreshold' | 'locked' | 'companyAverage'>
      & { raters: Array<(
        { __typename?: 'User' }
        & Pick<Types.IUser, 'id'>
      )>, currentScore?: Types.Maybe<(
        { __typename?: 'Score' }
        & Pick<Types.IScore, 'id' | 'value' | 'createdAt'>
      )>, lastScore?: Types.Maybe<(
        { __typename?: 'Score' }
        & Pick<Types.IScore, 'id' | 'value' | 'createdAt'>
      )>, mostRecentScores: Array<(
        { __typename?: 'Score' }
        & Pick<Types.IScore, 'value' | 'id' | 'createdAt'>
      )>, feedbackQuestions: Array<(
        { __typename?: 'FeedbackQuestion' }
        & Pick<Types.IFeedbackQuestion, 'behaviorId'>
        & { currentScore?: Types.Maybe<(
          { __typename?: 'Score' }
          & Pick<Types.IScore, 'value' | 'id'>
        )> }
      )>, habit: (
        { __typename?: 'Habit' }
        & Pick<Types.IHabit, 'id' | 'name' | 'description'>
        & { behaviors: Array<(
          { __typename?: 'Behavior' }
          & Pick<Types.IBehavior, 'anchor' | 'id'>
        )> }
      ) }
    )> }
  ) }
);


export const FetchManagedUserProfileDocument = gql`
    query fetchManagedUserProfile($id: ID!) {
  user(id: $id) {
    managedActionItems {
      id
      name
      text
      completed
      subject {
        ... on HabitUser {
          id
        }
      }
    }
    name
    id
    avatar
    group {
      id
      name
    }
    title
    feedbackStreak
    latestResponse
    responseRate
    habitUsers {
      id
      raters {
        id
      }
      validResponses
      unlockThreshold
      locked
      companyAverage
      currentScore {
        id
        value
        createdAt
      }
      lastScore {
        id
        value
        createdAt
      }
      mostRecentScores {
        value
        id
        createdAt
      }
      feedbackQuestions {
        behaviorId
        currentScore {
          value
          id
        }
      }
      habit {
        id
        name
        description
        behaviors {
          anchor
          id
        }
      }
    }
  }
}
    `;

/**
 * __useFetchManagedUserProfileQuery__
 *
 * To run a query within a React component, call `useFetchManagedUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchManagedUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchManagedUserProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchManagedUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IFetchManagedUserProfileQuery, IFetchManagedUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<IFetchManagedUserProfileQuery, IFetchManagedUserProfileQueryVariables>(FetchManagedUserProfileDocument, baseOptions);
      }
export function useFetchManagedUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IFetchManagedUserProfileQuery, IFetchManagedUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IFetchManagedUserProfileQuery, IFetchManagedUserProfileQueryVariables>(FetchManagedUserProfileDocument, baseOptions);
        }
export type FetchManagedUserProfileQueryHookResult = ReturnType<typeof useFetchManagedUserProfileQuery>;
export type FetchManagedUserProfileLazyQueryHookResult = ReturnType<typeof useFetchManagedUserProfileLazyQuery>;
export type FetchManagedUserProfileQueryResult = ApolloReactCommon.QueryResult<IFetchManagedUserProfileQuery, IFetchManagedUserProfileQueryVariables>;