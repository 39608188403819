import React from "react";
import styled from "styled-components";
import { media } from "utils/style-utils";
import { Flair } from "components/Layout/styles";
import { ReactComponent as First } from "assets/dashboard/suggested_employee_feedback_provider.svg";
import { ReactComponent as Second } from "assets/dashboard/removal_alert.svg";
import { ReactComponent as Third } from "assets/dashboard/objective_review_due.svg";
import { ReactComponent as Fourth } from "assets/dashboard/text_alert.svg";
import { useTranslation } from "react-i18next";
import { Header, Item } from "../styles";

const Container = styled.div`
  display: flex;
  margin-top: 0;
  font-size: 16px;

  ${media.handheld`
    flex-direction: column;
  `}
`;

const ExplainPrivacy = () => {
  const { t } = useTranslation();
  return (
    <div style={{ width: "100%", marginTop: "50px" }}>
      <Header data-cy="step3title" style={{ justifySelf: "start" }}>
        {t("deep_dive.more_feedback_wizard.privacy_title")}
      </Header>
      <Container>
        <Item>
          <Flair style={{ width: 150, height: 150 }}>
            <First />
          </Flair>
          <span>
            {t("deep_dive.more_feedback_wizard.privacy_paragraph_1")}{" "}
          </span>
        </Item>
        <Item>
          <Flair style={{ width: 150, height: 150 }}>
            <Second />
          </Flair>
          <span>{t("deep_dive.more_feedback_wizard.privacy_paragraph_2")}</span>
        </Item>
        <Item>
          <Flair style={{ width: 150, height: 150 }}>
            <Third />
          </Flair>
          <span>{t("deep_dive.more_feedback_wizard.privacy_paragraph_3")}</span>
        </Item>
        <Item>
          <Flair style={{ width: 150, height: 150 }}>
            <Fourth />
          </Flair>
          <span>{t("deep_dive.more_feedback_wizard.privacy_paragraph_4")}</span>
        </Item>
      </Container>
    </div>
  );
};

export default ExplainPrivacy;
