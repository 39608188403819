/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import React from "react";
import PropTypes from "prop-types";
import { Input, Divider, Button, Tabs, List, Radio } from "antd";
import { observer, inject } from "mobx-react";
import { observable } from "mobx";
import moment from "moment";
import { Note, Like, Highfive } from "services/api";
import ItemCarousel from "../ItemCarousel";
import HighFiveItem from "../../../../containers/HomePage/Feed/FeedList/HighFiveItem";

const { TextArea } = Input;
const { TabPane } = Tabs;
@inject("feedStore")
@observer
class Notes extends React.Component {
  @observable input = "";
  @observable notes = [];
  @observable filter = "received";
  @observable highfives = [];
  @observable slide = 1;

  constructor(props) {
    super(props);
    this.notes = props.notes || null;
    this.highfives = props.highfives;
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.highfives.length !== this.highfives.length) {
    this.highfives = nextProps.highfives;
    // }
  }

  handleSubmit = () => {
    const { currentUser, userId } = this.props;
    this.notes.unshift({
      creator: {
        id: currentUser.id,
        name: `${currentUser.first_name} ${currentUser.last_name}`,
        avatar: currentUser.avatar
      },
      content: this.input,
      subject_id: userId
    });
    this.slide = 1;
    Note.create(userId, { note: { content: this.input } });
    this.input = "";
  };

  handleNext = () => {
    this.slider.next();
    // this.slide = (this.slide % this.notes.length) + 1;
  };
  handlePrev = () => {
    this.slider.prev();
    // if(this.slide - 1 === 0){
    //   this.slide = this.notes.length;
    // } else {
    //   this.slide -= 1;
    // }
  };

  handleLike = (highfive) => {
    const { currentUser, feedStore } = this.props;
    highfive.has_liked = !highfive.has_liked;
    if (highfive.has_liked) {
      Like.create({
        likeable_id: highfive.id,
        likeable_type: "Highfive"
      }).then(({ data }) => highfive.likes.push(data));
    } else {
      const like = highfive.likes.find((l) => l.user_id === currentUser.id);
      Like.delete(highfive.id);
      highfive.likes = highfive.likes.filter((l) => l.id !== like.id);
    }
    feedStore.updateHighfive(highfive);
  };

  addComment = (text, mentions, highfive) => {
    const { feedStore, addCommentToHighfive } = this.props;
    Highfive.createComment(highfive.id, { text, mentions }).then(({ data }) => {
      feedStore.addComment(highfive.id, data);
      addCommentToHighfive(data, highfive);
    });
  };

  render() {
    const { notes } = this;
    let content;
    const {
      currentUser,
      userId,
      removeCommentFromHighfive,
      addLikeToComment,
      removeLikeFromComment
    } = this.props;

    if (notes) {
      content = (
        <Tabs width="100%" tabBarStyle={{ marginBottom: 0 }}>
          <TabPane
            tab="Notes"
            key="1"
            style={{
              border: "1px solid #e8e8e8",
              borderTop: "none",
              padding: "24px",
              borderRadius: "0 0 4px 4px"
            }}
          >
            <ItemCarousel items={this.notes} type="note" />

            <Divider />
            <TextArea
              value={this.input}
              placeholder="Enter a new Note"
              onChange={(e) => (this.input = e.target.value)}
            />
            <Button
              style={{ float: "right", marginTop: 24 }}
              type="primary"
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </TabPane>
          <TabPane
            tab="High Fives"
            key="2"
            style={{
              border: "1px solid #e8e8e8",
              borderTop: "none",
              padding: "24px",
              borderRadius: "0 0 4px 4px"
            }}
          >
            <ItemCarousel items={this.highfives} type="highfive" />
          </TabPane>
        </Tabs>
      );
    } else {
      content = (
        <List
          style={{ width: "100%" }}
          bordered
          header={
            <div
              style={{
                display: "flex",
                alignItems: window.innerWidth >= 500 && "center",
                flexDirection: window.innerWidth < 500 ? "column" : "row"
              }}
            >
              <span
                style={{
                  color: "rgba(0, 0, 0, 0.85)",
                  fontSize: "16px",
                  fontWeight: 400
                }}
              >
                High-fives
              </span>
              <div style={{ marginLeft: window.innerWidth >= 500 && 24 }}>
                <Radio.Group
                  value={this.filter}
                  buttonStyle="solid"
                  onChange={(e) => (this.filter = e.target.value)}
                >
                  <Radio.Button value="received">Received</Radio.Button>
                  <Radio.Button value="sent">Sent</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          }
          dataSource={this.highfives
            .filter((h) =>
              this.filter === "sent"
                ? h.sender.id === userId
                : h.sender.id !== userId
            )
            .sort(
              (a, b) =>
                moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
            )}
          renderItem={(item) => (
            <HighFiveItem
              highfive={item}
              addComment={(text, mentions) =>
                this.addComment(text, mentions, item)
              }
              curentUserId={currentUser.id}
              onLike={() => this.handleLike(item)}
              deleteComment={(id) => removeCommentFromHighfive(id, item)}
              afterCreateLike={(commentId, like) =>
                addLikeToComment(commentId, like, item.id)
              }
              afterDeleteLike={(commentId, likeId) =>
                removeLikeFromComment(commentId, likeId, item.id)
              }
            />
          )}
        />
      );
    }
    return content;
  }
}

Notes.propTypes = {
  currentUser: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  notes: PropTypes.array.isRequired,
  addCommentToHighfive: PropTypes.func.isRequired,
  removeCommentFromHighfive: PropTypes.func.isRequired,
  addLikeToComment: PropTypes.func.isRequired,
  removeLikeFromComment: PropTypes.func.isRequired,
  highfives: PropTypes.array.isRequired,
  feedStore: PropTypes.object.isRequired
};

export default Notes;
