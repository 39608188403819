import React from "react";
import styled from "styled-components";
import Box from "layout/Box";
import groupBy from "lodash/groupBy";
import { Divider, Tooltip } from "antd";

const Outer = styled.div`
  height: 50px;
  width: 12px;
  border-radius: 4px;
  border: 1px solid black;
  z-index: 100;
  position: relative;
`;
const Inner = styled.div`
  height: ${(props) => props.height}%;
  border-radius: ${(props) => (props.height === 100 ? "4px" : "0 0 4px 4px")};
  z-index: 0 !important;
  background-color: ${(props) => props.color};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Percentage = styled(Box)`
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.8);
  width: 85px;
  display: flex;
  justify-content: flex-end;
`;

const TableHeader = styled(Box)`
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
`;

const Anchor = styled(Box)`
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 24px;
`;

const getColor = (teamAverage: number, companyAverage: number): string => {
  if (teamAverage > companyAverage) {
    return "#22B063";
  }
  if (companyAverage > teamAverage) {
    return "#DC943D";
  }
  return "#3185B4";
};

const Measurement: React.FC<{
  teamAverage?: number;
  companyAverage?: number;
  locked?: boolean;
  isTeam?: boolean;
}> = ({ teamAverage, companyAverage, isTeam, locked }) => {
  if (locked) {
    return (
      <Box position="relative" width="12px" style={{ filter: "blur(2px)" }}>
        <Outer />
        <Inner color={"#e8e8e8"} height={50} />
      </Box>
    );
  }
  return (
    <Box position="relative" width="12px">
      <Outer />
      <Inner
        color={isTeam ? getColor(teamAverage, companyAverage) : "#3185B4"}
        height={isTeam ? teamAverage : companyAverage}
      />
    </Box>
  );
};

const ComparisonRow: React.FC<{
  teamaverage: number;
  teamresponds: number;
  companyaverage: number;
  anchor: string;
}> = ({ anchor, teamaverage, companyaverage, teamresponds }) => (
  <Box
    width="100%"
    display="grid"
    gridTemplateColumns="175px 175px 1fr"
    mb={24}
  >
    {teamaverage && teamresponds >= 3 ? (
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Percentage>{Math.round(teamaverage * 100)}%</Percentage>
        <Measurement
          teamAverage={teamaverage * 100}
          companyAverage={companyaverage * 100}
          isTeam
        />
      </Box>
    ) : (
      <Tooltip title="We're still collecting data, check back soon!">
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Percentage>--%</Percentage>
          <Measurement locked />
        </Box>
      </Tooltip>
    )}

    <Box display="flex" alignItems="center" justifyContent="flex-start">
      <Percentage>{Math.round(companyaverage * 100)}%</Percentage>
      <Measurement
        teamAverage={teamaverage * 100}
        companyAverage={companyaverage * 100}
      />
    </Box>
    <Anchor>{anchor}</Anchor>
  </Box>
);

type Comparison = {
  anchor: string;
  teamaverage: number;
  companyaverage: number;
};

const CompanyComparison: React.FC<{ comparisons: Comparison[] }> = ({
  comparisons
}) => {
  const grouped = groupBy(comparisons, (c) => c.habitname);

  return (
    <Box width={[1, 1, 1, 1, 1, "80%"]} pt="24px">
      <Box display="grid" gridTemplateColumns="175px 175px 1fr" mb="24px">
        <TableHeader>Your Team</TableHeader>
        <TableHeader>Other Teams (Avg)</TableHeader>
      </Box>
      {Object.keys(grouped).map((k) => (
        <>
          <Divider>{k}</Divider>
          {grouped[k]
            .slice()
            .sort((a, b) => a.teamaverage - b.teamaverage)
            .map((c) => (
              <ComparisonRow {...c} />
            ))}
        </>
      ))}
    </Box>
  );
};

export default CompanyComparison;
