import styled from "styled-components";
import { Icon, Col } from "antd";
import media from "utils/style-utils";

export const UserHabitList = styled.ul`
  list-style: none;
  padding: 0;
  margin-left: 0;
  padding-left: 1em;
  text-indent: -1em;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 12px;
  }
`;

export const GridItem = styled.div`
  min-height: ${(props) => !props.expanded && "400px"};
  flex-basis: 20%;
  -ms-flex: auto;
  z-index: ${(props) => (props.expanded ? "101" : "100")};
  width: ${(props) => !props.expanded && "259px"};
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  position: ${(props) => props.expanded && "fixed"};
  top: ${(props) => props.expanded && "0 !important"};
  left: ${(props) => props.expanded && "0 !important"};
  bottom: ${(props) => props.expanded && "0 !important"};
  right: ${(props) => props.expanded && "0 !important"};
  @media (max-width: 1599px) {
    flex-basis: 50%;
  }
  @media (max-width: 1073px) {
    flex-basis: 50%;
  }
  @media (max-width: 815px) {
    flex-basis: 100%;
  }
  @media (max-width: 555px) {
    flex-basis: 100%;
  }
`;

export const StyledCol = styled(Col)`
  height: ${(props) => (props.expanded ? "100%" : "50%")};
  position: absolute;
  cursor: pointer;

  @media (min-width: 1600px) {
    transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
  }

  @media (max-width: 1600px) {
    position: ${(props) => (props.expanded ? "fixed" : "absolute")};
    top: ${(props) => props.expanded && "0 !important"};
    left: ${(props) => props.expanded && "0 !important"};
    bottom: ${(props) => props.expanded && "0 !important"};
    right: ${(props) => props.expanded && "0 !important"};
    height: ${(props) => !props.expanded && "400px"};
  }
`;

export const UserHabitListItem = styled.li`
  font-size: 16px;
  position: relative;
  margin-bottom: 10px;
  padding-right: 48px;
  color: ${(props): string => {
    if (props.removed) {
      return "red";
    }

    if (props.added) {
      return "#50cc75";
    }
    return "";
  }};
  &:before {
    content: ${(props): string => {
      if (props.added) {
        return `"+"`;
      }
      if (props.removed) {
        return `"-"`;
      }
      return `"•"`;
    }};
    margin-right: 4px;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const RemoveIcon = styled(Icon)`
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    color: red;
  }
`;
export const UndoIcon = styled(Icon)`
  cursor: pointer;
	transition: 0.2s;
	color: rgba(0, 0, 0, 0.65) !important;
  &:hover {
    color: #40cc75 !important;
`;
