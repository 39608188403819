import styled, { keyframes } from "styled-components";
// import Joyride, { BeaconRenderProps, TooltipRenderProps } from 'react-joyride';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    background-color: rgba(255, 100, 100, 0.9);
    transform: scale(1.6);
  }
`;

export const Beacon = styled.span`
  animation: ${pulse} 1s ease-in-out infinite;
  background-color: ${props => props.theme.secondary_color};
  border-radius: 50%;
  display: inline-block;
  height: 3rem;
  width: 3rem;
`;

export const ListWrapper = styled.div`
  max-height: ${props => !props.embedded && `calc(100vh - 110px)`};
  overflow-y: auto;
`;
