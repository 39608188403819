import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import LessonTile from "containers/OneOnOnes/lessonTile";
import CoachModal from "containers/DeepDiveV2/ActionsAndInfo/CoachModal";
import { media } from "utils/style-utils";
import { useTranslation } from "react-i18next";
import Feature from "components/Feature";
import useStores from "utils/useStores";

const Container = styled.div`
  display: flex;

  margin: 15px 20px;
  padding: 7.5px 14px;
  position: relative;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
  cursor: func && pointer;
  overflow: hidden;
  align-items: center;

  ${media.handheld`
    margin: 12px 0;
  `}
`;

const ContainerOuter = styled.div`
  width: 100%;
`;

const LeftSide = styled.div``;

const RightSide = styled.div`
  display: flex;
  padding: 0px 24px;
  min-height: 100px;
  flex-direction: column;
  justify-content: space-between;
`;

const CourseWidget = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const { profileStore } = useStores();
  return (
    <ContainerOuter>
      <CoachModal visible={modalOpen} cancel={() => setModalOpen(false)} />
      <Container>
        <LeftSide>
          <LessonTile
            noMask
            style={{ height: 100, width: 100 }}
            link="https://learning.rhabitapp.com/low-score#/"
            img="https://rhabit.s3.us-east-2.amazonaws.com/young-woman-working-at-home-office-PWUQG3G.jpg"
          />
        </LeftSide>
        <RightSide>
          <div style={{ fontSize: 14 }}>
            {t("deep_dive.improvement.looking_to_raise_your_score")}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
              marginTop: -6
            }}
          >
            <a
              href="https://learning.rhabitapp.com/low-score#/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={{ marginTop: 6 }}>
                {" "}
                {t("deep_dive.improvement.try_our_microcourse")}
              </Button>
            </a>
            {!profileStore.isDashboardViewer && (
              <Feature excludes={["DDI_CLIENT"]}>
                <Button
                  style={{ marginTop: 6 }}
                  onClick={() => setModalOpen(true)}
                >
                  {t("deep_dive.improvement.get_a_coach")}
                </Button>
              </Feature>
            )}
          </div>
        </RightSide>
      </Container>
    </ContainerOuter>
  );
};

export default CourseWidget;
