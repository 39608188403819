/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Radio, Button, Checkbox } from "antd";
import PropTypes from "prop-types";
import ParentObjectivePicker from "components/ParentObjectivePicker";

import {
  ButtonWrapper,
  Wrapper,
  ContentWrapper,
  ObjectiveItem,
  Owner,
  OwnerName,
  TopWrapper,
  Actions,
  ObjectiveInfo
} from "./styles";
import UserAvatar from "../../UserAvatar";

// eslint-disable-next-line no-unused-vars
const AddToParent = ({
  parent,
  setParent,
  currentId,
  isPrivate,
  setIsPrivate
}) => {
  const [hasParent, setHasParent] = useState(!!parent);
  const [pickerVisible, setPickerVisible] = useState(false);

  const handleChange = (e) => {
    setHasParent(e.target.value);
    if (!e.target.value) {
      setParent(null);
    }
  };

  return (
    <Wrapper>
      <ParentObjectivePicker
        parent={parent}
        visible={pickerVisible}
        currentId={currentId}
        onCancel={() => setPickerVisible(false)}
        onSubmit={(selected) => {
          setParent(selected);
          setPickerVisible(false);
        }}
      />
      <ButtonWrapper>
        <h3>
          Do you want to connect this objective to a higher-level objective?
        </h3>
        <Radio.Group
          onChange={handleChange}
          value={hasParent}
          buttonStyle="solid"
        >
          <Radio.Button value={false}>No</Radio.Button>
          <Radio.Button value>Yes</Radio.Button>
        </Radio.Group>
      </ButtonWrapper>
      {hasParent ? (
        <ContentWrapper>
          {parent ? (
            <div>
              <TopWrapper>
                <h4>Your objective will be a sub-objective of: </h4>
              </TopWrapper>
              <ObjectiveItem>
                <Owner>
                  <ObjectiveInfo>
                    <span style={{ fontWeight: 500, fontSize: 18 }}>
                      {parent.name}
                    </span>
                    <span>{parent.description}</span>
                  </ObjectiveInfo>
                  <h4>Owner: </h4>
                  <UserAvatar
                    avatar={parent.owner.avatar}
                    name={parent.owner.name}
                  />
                  <OwnerName>{parent.owner.name}</OwnerName>
                </Owner>
              </ObjectiveItem>
              <Actions>
                <Button
                  role="presentation"
                  type="danger"
                  onClick={() => setParent(null)}
                >
                  Remove
                </Button>
                <Button onClick={() => setPickerVisible(true)}>Change</Button>
              </Actions>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: 150,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Button
                onClick={() => setPickerVisible(true)}
                type="primary"
                style={{ margin: "auto" }}
                size="large"
              >
                Choose an Objective
              </Button>
            </div>
          )}
        </ContentWrapper>
      ) : (
        <Checkbox
          checked={isPrivate}
          onClick={() => setIsPrivate(!isPrivate)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 24
          }}
        >
          Make This Objective Private
        </Checkbox>
      )}
    </Wrapper>
  );
};

AddToParent.defaultProps = {
  parent: null,
  currentId: null
};

AddToParent.propTypes = {
  parent: PropTypes.object,
  setParent: PropTypes.func.isRequired,
  currentId: PropTypes.string,
  isPrivate: PropTypes.bool.isRequired,
  setIsPrivate: PropTypes.func.isRequired
};

export default AddToParent;

// {
// !libraryMode && (
//   <Item style={{ marginBottom: '0px' }}>
//     {getFieldDecorator('private', {
//       initialValue: params ? params.private : false,
//       valuePropName: 'checked'
//     })(<StyledCB disabled={!!parent}>Make Objective Private</StyledCB>)}
//   </Item>
// )
// }
// {
// !libraryMode && (
//   <Item style={{ marginBottom: 0 }} label="Add Objective To:">
//     {parent ? (
//       <div>
//         <span style={{ marginRight: 6 }}>{parent.name}</span>
//         <a
//           style={{ marginRight: 6 }}
//           role="presentation"
//           onClick={() => setPickerVisible(true)}
//         >
//           Change
//               </a>
//         <a
//           style={{ color: 'red' }}
//           role="presentation"
//           onClick={() => {
//             // form.setFieldsValue('parent_id', null);
//             setParent(null);
//           }}
//         >
//           Remove
//               </a>
//       </div>
//     ) : (
//         <a onClick={() => setPickerVisible(true)} role="presentation">
//           Add To...
//             </a>
//       )}
//   </Item>
// )
// }
// {
// !libraryMode && (
//   <Form.Item style={{ margin: 0 }}>
//     {getFieldDecorator('parent_id', {
//       initialValue: (parent && parent.id) || null
//     })(<Input style={{ display: 'none' }} />)}
//   </Form.Item>
// )
// }
