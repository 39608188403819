import React from "react";
import * as ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { Modal, Skeleton, Divider } from "antd";
import { observer, inject, Provider } from "mobx-react";
import PropTypes from "prop-types";
import { observable } from "mobx";
import theme from "assets/theme";
import stores from "stores";
import client from "services/graphql";

import PerformanceReview from "containers/PerformanceReview";
import ManageLabels from "components/ManageLabels";
import OKRLibrary from "components/OKRLibrary";
import UserProfile from "./UserProfile";
import TeamProfile from "./TeamProfile";
import { ApolloProvider } from "@apollo/client";

export function destroy(id) {
  const div = document.getElementById(id || "user-modal");
  if (div) {
    const unmountResult = ReactDOM.unmountComponentAtNode(div);
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }
}

export default function view(props, history, component) {
  if (!props.skipDestroy) {
    destroy();
  }
  const div = document.createElement("div");
  div.setAttribute("id", props.domId || "user-modal");
  document.body.appendChild(div);
  const {
    profileStore: {
      currentUser: { organization }
    }
  } = stores;

  function render() {
    ReactDOM.render(
      <Provider {...stores}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={{ ...theme, ...organization.theme }}>
            <FullScreenModal
              {...props}
              history={history}
              component={component}
            />
          </ThemeProvider>
        </ApolloProvider>
      </Provider>,
      div
    );
  }
  render();
}

@inject("profileStore")
@observer
class FullScreenModal extends React.Component {
  @observable loading = true;
  @observable currentUser = {};

  constructor(props) {
    super(props);
    const { profileStore } = props;
    this.currentUser = profileStore.currentUser;
  }

  toggleLoading = () => (this.loading = !this.loading);

  goToReports = () => {
    const { history, id } = this.props;
    if (!this.currentUser.admin) {
      history.push(`/team/analytics/${id}`);
    } else {
      history.push(`/analytics/${id}`);
    }
    destroy();
  };

  goToTeam = (teamId) => {
    const { history } = this.props;
    destroy();
    view({ id: teamId, type: "team" }, history);
  };
  goToUser = (userId) => {
    const { history } = this.props;

    destroy();
    view({ id: userId, type: "user" }, history);
  };

  render() {
    const {
      type,
      id,
      history,
      onClose,
      width,
      component,
      domId,
      bodyStyle,
      maskClosable,
      closable
    } = this.props;
    let content;
    if (component) {
      content = React.cloneElement(component, {
        closeModal: () => destroy(domId)
      });
      this.toggleLoading();
    } else {
      switch (type) {
        case "team":
          content = (
            <TeamProfile
              id={id}
              goToUser={this.goToUser}
              loading={this.loading}
              toggleLoading={this.toggleLoading}
              currentUserManagerOnlyId={
                this.currentUser.manager &&
                !this.currentUser.admin &&
                this.currentUser.id
              }
            />
          );
          break;
        case "user":
          content = (
            <UserProfile
              history={history}
              goToReports={this.goToReports}
              goToTeam={this.goToTeam}
              goToUser={this.goToUser}
              id={id}
              loading={this.loading}
              toggleLoading={this.toggleLoading}
              currentUserManagerOnlyId={
                this.currentUser.manager &&
                !this.currentUser.admin &&
                this.currentUser.id
              }
              currentUserManagerOnlyManagedTeamId={
                this.currentUser.manager &&
                !this.currentUser.admin &&
                this.currentUser.managed_team_id
              }
            />
          );
          break;
        case "labels":
          content = <ManageLabels toggleLoading={this.toggleLoading} />;
          break;
        case "review":
          content = (
            <PerformanceReview
              id={id}
              onClose={(reviewCohort) => {
                onClose(reviewCohort);
                destroy();
              }}
              toggleLoading={this.toggleLoading}
              loading={this.loading}
            />
          );
          break;
        case "okr_library":
          content = (
            <OKRLibrary
              toggleLoading={this.toggleLoading}
              {...this.props}
              closeModal={destroy}
            />
          );
          break;
        default:
          content = <div />;
      }
    }
    let modalWidth = "92vw";
    if (type === "labels" && !window.innerWidth < 500) {
      modalWidth = "50vw";
    }
    if (width) {
      modalWidth = width;
    }
    return (
      <Modal
        maskClosable={maskClosable}
        closable={closable}
        width={modalWidth}
        onCancel={() => {
          if (onClose) {
            onClose();
          }
          destroy(domId);
        }}
        visible
        className="no-border-radius"
        style={{ paddingBottom: 0, borderRadius: 0 }}
        bodyStyle={{
          height: "100vh",
          borderRadius: 0,
          overflowY: "auto",
          padding: window.innerWidth < 500 && "10px",
          ...bodyStyle
        }}
        centered
        footer={false}
      >
        {this.loading && (
          <div>
            <Skeleton active avatar />
            <Divider />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </div>
        )}
        {content}
      </Modal>
    );
  }
}

FullScreenModal.defaultProps = {
  onClose: () => {},
  width: null,
  maskClosable: true,
  closeable: true
};

FullScreenModal.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  history: PropTypes.any.isRequired,
  width: PropTypes.string,
  onClose: PropTypes.func,
  maskClosable: PropTypes.bool,
  closeable: PropTypes.bool,
  profileStore: PropTypes.oneOf([undefined, {}]).isRequired
};

FullScreenModal.defaultProps = {};
