import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "antd";
import { ReactComponent as FlairImg } from "assets/dashboard/newly_assigned_objective.svg";
import { ItemWrapper, Title, ButtonWrapper, Flair, Content } from "../styles";
import useStores from "../../../utils/useStores";
import { useTranslation } from "react-i18next";

const ObjectiveReviewItem = ({ objective, markCompleted }) => {
  const { objectiveStore } = useStores();

  const { t } = useTranslation();

  const acceptObjective = () => {
    objectiveStore.acceptObjective(objective.id);
    markCompleted();
  };

  const rejectObjective = () => {
    Modal.confirm({
      title: t("homepage.action_items.accept_objective.confirm_reject"),
      okText: t("homepage.action_items.accept_objective.dismiss_text"),
      onOk: () => {
        objectiveStore.rejectObjective(objective.id);
        markCompleted();
      },
      onCancel() {}
    });
  };

  return (
    <ItemWrapper>
      <Content>
        <Title>
          {t("homepage.action_items.accept_objective.prompt_text", {
            creatorName: objective.creator.name
          })}
        </Title>
        <span style={{ fontWeight: 500 }}>{objective.name}</span>
        <p>{objective.description}</p>
        <ButtonWrapper>
          <Button style={{ marginRight: 24 }} onClick={rejectObjective}>
            {t("homepage.action_items.accept_objective.dismiss_text")}
          </Button>
          <Button onClick={acceptObjective} type="primary">
            {t("homepage.action_items.accept_objective.action_text")}
          </Button>
        </ButtonWrapper>
      </Content>
      <Flair>
        <FlairImg />
      </Flair>
    </ItemWrapper>
  );
};

ObjectiveReviewItem.propTypes = {
  objective: PropTypes.object.isRequired,
  markCompleted: PropTypes.func.isRequired
};

export default ObjectiveReviewItem;
