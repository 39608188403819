import React from "react";
import { Card, Layout, Button } from "antd";
import Art from "assets/Pittsburgh_end.svg";
import logo from "assets/logo_black.png";
import { inject } from "mobx-react";
import profileStore from "../../stores/profileStore";

const HabitsNotFound = () => {
  const mobile = window.innerWidth < 500;

  return (
    <div>
      <Layout
        style={{
          minHeight: window.innerWidth > 500 && "100vh",
          position: "relative",
          zIndex: 1
        }}
      >
        <img
          src={logo}
          alt="rhabit"
          style={{
            left: "4%",
            width: "125px",
            height: "40px",
            position: "absolute",
            top: "11.25px"
          }}
        />
        <div style={{ top: "30%", position: "absolute", width: "100%" }}>
          <Card style={{ width: "50%", margin: "0 auto" }} title="No Habits">
            <p>This user currently has no habits</p>
            <div style={{ width: "100%" }}>
              <Button
                onClick={profileStore.logout}
                type="primary"
                style={{ margin: "0 auto" }}
              >
                Return Home
              </Button>
            </div>
          </Card>
        </div>
        <img
          alt="pittsburgh"
          src={Art}
          style={{
            position: "fixed",
            bottom: 0,
            right: !mobile && 0,
            width: mobile ? "100%" : 800,
            height: "auto",
            zIndex: -1
          }}
        />
      </Layout>
    </div>
  );
};

export default inject("profileStore")(HabitsNotFound);
