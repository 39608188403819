/* eslint-disable */
import * as Types from '../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type IFetchManagerDashboardQueryVariables = Types.Exact<{
  id?: Types.Maybe<Types.Scalars['ID']>;
}>;


export type IFetchManagerDashboardQuery = (
  { __typename?: 'Query' }
  & { managerActionItems: Array<(
    { __typename?: 'ManagerActionItem' }
    & Pick<Types.IManagerActionItem, 'id' | 'name' | 'text' | 'completed'>
    & { subject: (
      { __typename?: 'HabitUser' }
      & Pick<Types.IHabitUser, 'id'>
    ) | { __typename?: 'User' }, user: (
      { __typename?: 'User' }
      & Pick<Types.IUser, 'id' | 'name' | 'avatar' | 'email'>
    ) }
  )>, team: (
    { __typename?: 'Team' }
    & Pick<Types.ITeam, 'name' | 'id'>
    & { manager: (
      { __typename?: 'User' }
      & Pick<Types.IUser, 'name' | 'avatar'>
    ), users: Array<(
      { __typename?: 'User' }
      & Pick<Types.IUser, 'name' | 'id' | 'avatar' | 'latestResponse'>
      & { raters: Array<(
        { __typename?: 'User' }
        & Pick<Types.IUser, 'id'>
      )>, habitUsers: Array<(
        { __typename?: 'HabitUser' }
        & Pick<Types.IHabitUser, 'id' | 'validResponses'>
        & { currentScore?: Types.Maybe<(
          { __typename?: 'Score' }
          & Pick<Types.IScore, 'id' | 'value' | 'createdAt'>
        )>, mostRecentScores: Array<(
          { __typename?: 'Score' }
          & Pick<Types.IScore, 'value' | 'id' | 'createdAt'>
        )>, habit: (
          { __typename?: 'Habit' }
          & Pick<Types.IHabit, 'id' | 'name'>
        ) }
      )> }
    )> }
  ) }
);


export const FetchManagerDashboardDocument = gql`
    query fetchManagerDashboard($id: ID) {
  managerActionItems {
    id
    name
    text
    completed
    subject {
      ... on HabitUser {
        id
      }
    }
    user {
      id
      name
      avatar
      email
    }
  }
  team(id: $id) {
    name
    id
    manager {
      name
      avatar
    }
    users {
      name
      id
      avatar
      latestResponse
      raters {
        id
      }
      habitUsers {
        id
        currentScore {
          id
          value
          createdAt
        }
        validResponses
        mostRecentScores {
          value
          id
          createdAt
        }
        habit {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useFetchManagerDashboardQuery__
 *
 * To run a query within a React component, call `useFetchManagerDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchManagerDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchManagerDashboardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchManagerDashboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IFetchManagerDashboardQuery, IFetchManagerDashboardQueryVariables>) {
        return ApolloReactHooks.useQuery<IFetchManagerDashboardQuery, IFetchManagerDashboardQueryVariables>(FetchManagerDashboardDocument, baseOptions);
      }
export function useFetchManagerDashboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IFetchManagerDashboardQuery, IFetchManagerDashboardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IFetchManagerDashboardQuery, IFetchManagerDashboardQueryVariables>(FetchManagerDashboardDocument, baseOptions);
        }
export type FetchManagerDashboardQueryHookResult = ReturnType<typeof useFetchManagerDashboardQuery>;
export type FetchManagerDashboardLazyQueryHookResult = ReturnType<typeof useFetchManagerDashboardLazyQuery>;
export type FetchManagerDashboardQueryResult = ApolloReactCommon.QueryResult<IFetchManagerDashboardQuery, IFetchManagerDashboardQueryVariables>;