import React from "react";
import PropTypes from "prop-types";

import { Modal, Form, Input, Checkbox } from "antd";
// import console = require('console');
// import console = require('console');

const { TextArea } = Input;
const FormItem = Form.Item;

const AnnouncementForm = ({
  visible,
  onCancel,
  onCreate,
  form,
  form: { getFieldDecorator, validateFields }
}) => {
  const handlesubmit = () => {
    validateFields((err) => {
      if (!err) {
        onCreate(form.getFieldsValue());
      }
    });
  };

  // const handleChange = () = {

  // }

  return (
    <Modal
      visible={visible}
      title="Make an Announcement"
      okText="Create"
      onCancel={onCancel}
      destroyOnClose
      onOk={() => {
        handlesubmit();
      }}
    >
      <Form layout="vertical">
        <FormItem label="Announcement Text">
          {getFieldDecorator("content", {
            rules: [
              {
                required: true,
                min: 10,
                message: "Please add announcement text"
              }
            ]
          })(<TextArea />)}
        </FormItem>
        <FormItem label="Also Send Email">
          {getFieldDecorator("send_email")(<Checkbox data-cy="email" />)}
        </FormItem>
      </Form>
    </Modal>
  );
};

AnnouncementForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired
  }).isRequired
};

export default Form.create()(AnnouncementForm);
