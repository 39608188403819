/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from "react";
import Box from "layout/Box";
import useStores from "utils/useStores";
import {
  Modal,
  Input,
  Form,
  Select,
  Typography,
  Upload,
  Button,
  Icon,
  Spin,
  TimePicker,
  message
} from "antd";
import { useTranslation } from "react-i18next";
import UserAvatar from "components/UserAvatar";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { Profile, Relationships } from "services/api";
import CodeInput from "components/CodeInput";
import moment from "moment";
import codes from "./codes";
import queryString from "query-string";

import UserSearch from "components/UserSearch";

const nameFilter = (input, { props: { children } }) =>
  children.toLowerCase().includes(input.toLowerCase());

interface Props {
  visible: boolean;
  onClose: () => void;
}

const lngs = ["en", "de", "fr", "es", "zh-CN"];

const genderMap: { [key: string]: number } = {
  male: 0,
  female: 1,
  neutral: 2
};
const genderMapInverse: { [key: number]: string } = {
  0: "male",
  1: "female",
  2: "neutral"
};

const VERIFIED = "verified";
const NOT_VERIFIED = "not_verified";
const PENDING = "pending";
const VERIFICATION_ERROR = "verification_error";

type VerificationStates =
  | typeof VERIFIED
  | typeof PENDING
  | typeof NOT_VERIFIED
  | typeof VERIFICATION_ERROR;

const AccountWizard: React.FC<Props> = ({ visible, onClose }) => {
  const {
    profileStore: { currentUser, updateUser }
  } = useStores();

  const { features } = currentUser.organization;
  const { t, i18n } = useTranslation();

  const [step, setStep] = useState(0);
  const [firstName, setFirstName] = useState(currentUser.first_name);
  const [lastName, setLastName] = useState(currentUser.last_name);
  const [gender, setGender] = useState(genderMap[currentUser.gender]);
  const [avatarUrl, setAvatarUrl] = useState(currentUser.avatar);
  const [avatar, setAvatar] = useState<null | blob>(null);
  const [countryCode, setCountryCode] = useState("US");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [verificationStatus, setVerificationStatus] = useState<
    VerificationStates
  >(NOT_VERIFIED);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [mailTime, setMailTime] = useState(currentUser.mail_time);
  const phoneNumberWithCountryCode = `+${codes[countryCode]}${phoneNumber}`;
  const [feedbackProviders, setFeedbackProviders] = useState([]);

  const addRelationships = (): Promise<void> => {
    return Relationships.create({
      user_ids: feedbackProviders.map((s) => s.key),
      external: false
    });
  };

  const handleSubmit = (): void => {
    setButtonLoading(true);
    const formData = new FormData();
    if (avatar) {
      formData.append("avatar", avatar);
    }

    formData.append("mail_time", mailTime);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("gender", genderMapInverse[gender]);
    Profile.update({ onboarded: true });
    addRelationships().then(() => {
      Profile.update(formData).then(({ data }) => {
        localStorage.removeItem("accountWizard");
        updateUser(data);
        setButtonLoading(false);
        setStep(6);
      });
    });
  };

  const previousStep = (): void => {
    if (step === 4) {
      setStep(step - 2);
    } else {
      setStep(step - 1);
    }
  };

  const nextButtonActive = (): boolean => {
    if (step === 1) {
      return !!avatarUrl;
    }
    if (step === 2) {
      const phoneno = /^[0-9]+$/;
      return !!phoneNumber.match(phoneno);
    }

    if (step === 3) {
      return verificationStatus === VERIFIED;
    }
    return true;
  };

  const checkVerification = async (verification: string): Promise<void> => {
    setVerificationStatus(PENDING);
    const { data } = await Profile.checkPhoneVerification({
      code: verification,
      phone_number: phoneNumberWithCountryCode
    });

    if (data.status === "approved") {
      setVerificationStatus(VERIFIED);
    } else {
      setVerificationStatus(VERIFICATION_ERROR);
    }
  };

  useEffect(() => {
    if (code.length === 6) {
      checkVerification(code);
    }
  }, [code]);

  const setAvatarFromLinkedIn = async (code: string) => {
    const { data } = await Profile.avatarFromLinkedIn(code);
    setAvatarUrl(data.avatar);
  };

  useEffect(() => {
    let state = localStorage.getItem("accountWizard");
    if (state) {
      state = JSON.parse(state);
      console.log("on Mount", state.gender);
      setFirstName(state.firstName || "");
      setLastName(state.lastName || "");
      setGender(parseInt(state.gender));
      setStep(state.step || 0);
      setPhoneNumber(state.phoneNumber || "");
      setCountryCode(state.countryCode || "US");
      setVerificationStatus(state.verificationStatus || NOT_VERIFIED);
      setMailTime(state.mailTime || currentUser.mail_time);
      setFeedbackProviders(state.feedbackProviders || []);
    }

    const { code } = queryString.parse(window.location.search);
    if (code) {
      setAvatarFromLinkedIn(code);
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem("accountWizard");
    const state = {
      step,
      firstName,
      lastName,
      gender: gender ? gender.toString() : "2",
      phoneNumber,
      countryCode,
      verificationStatus,
      mailTime,
      feedbackProviders
    };
    localStorage.setItem("accountWizard", JSON.stringify(state));
  }, [step]);

  const uploadProfileAvatar = (
    newAvatar: UploadChangeParam<UploadFile<any>>
  ): void => {
    const av = newAvatar.file;
    // Generating fake form to send via API
    const fileReader = new FileReader();
    const image = new Image();
    fileReader.onload = (e) => {
      if (e.target && e.target.result) {
        setAvatarUrl(e.target.result);
        image.src = e.target.result as string;
      }
    };
    setAvatar(av);

    if (av) {
      fileReader.readAsDataURL(av);
    }
  };
  const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&scope=r_liteprofile&client_id=77l9sg7qlym5m9&redirect_uri=${currentUser.linked_in_redirect_uri}&state=${currentUser.id}`;
  const firstStep = (
    <>
      <Typography.Paragraph style={{ fontSize: 18 }}>
        {t("account_wizard.step_0_content")}
      </Typography.Paragraph>
      <Form.Item
        style={{ marginBottom: 12 }}
        label={t("account_wizard.first_name")}
      >
        <Input
          onChange={(e): void => setFirstName(e.target.value)}
          value={firstName}
          size="large"
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: 12 }}
        label={t("account_wizard.last_name")}
      >
        <Input
          onChange={(e): void => setLastName(e.target.value)}
          value={lastName}
          size="large"
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: 12 }}
        label={t("account_wizard.preferred_pronouns")}
      >
        <Select
          onChange={(e): void => setGender(e)}
          value={gender}
          size="large"
          style={{ width: 150 }}
        >
          <Select.Option value={0}>He/Him</Select.Option>
          <Select.Option value={1}>She/Her</Select.Option>
          {features.includes("PRONOUNS") && (
            <Select.Option value={2}>They/Them</Select.Option>
          )}
        </Select>
      </Form.Item>
      <Form.Item
        style={{ marginBottom: 12 }}
        label={t("account_wizard.language")}
      >
        <Select
          size="large"
          style={{ width: 100 }}
          onChange={(val) => i18n.changeLanguage(val)}
          value={
            lngs.includes(i18n.language)
              ? i18n.language
              : i18n.language.split("-")[0]
          }
        >
          <Select.Option value="en">English</Select.Option>
          <Select.Option value="de">German</Select.Option>
          <Select.Option value="zh-CN">Chinese</Select.Option>
          <Select.Option value="fr">French</Select.Option>
          <Select.Option value="es">Spanish</Select.Option>
        </Select>
      </Form.Item>
    </>
  );

  const resendCode = async () => {
    try {
      await Profile.sendPhoneVerification({
        phone_number: phoneNumberWithCountryCode
      });
      message.success(t("account_wizard.code_sent"));
    } catch {
      message.error(t("account_wizard.code_send_error"));
    }
  };

  const handleNextStep = async (): Promise<void> => {
    if (step === 6) {
      onClose();
    } else if (step === 2) {
      setButtonLoading(true);
      await Profile.sendPhoneVerification({
        phone_number: phoneNumberWithCountryCode
      });
      setButtonLoading(false);
      setStep(step + 1);
    } else if (step === 4) {
      if (currentUser.habits.length > 0) {
        setStep(step + 1);
      } else {
        handleSubmit();
      }
    } else if (step === 5) {
      if (feedbackProviders.length < 3) {
        Modal.confirm({
          title: t("are_you_sure"),
          content: t("account_wizard.less_than_3_providers_warning"),
          okText: t("account_wizard.im_sure"),
          cancelText: t("account_wizard.take_me_back"),
          okButtonProps: { type: "danger" },
          onOk: handleSubmit
        });
      } else {
        handleSubmit();
      }
    } else {
      setStep(step + 1);
    }
  };

  const secondStep = (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography.Text style={{ fontSize: 18 }}>
        {t("account_wizard.step_1_content")}
      </Typography.Text>
      <Box margin="24px 0">
        <UserAvatar avatar={avatarUrl} name={currentUser.name} size={86} />
      </Box>
      <Upload
        name="avatar"
        listType="picture"
        showUploadList={false}
        action="null"
        beforeUpload={() => false}
        onChange={uploadProfileAvatar}
      >
        <Button
          size="large"
          style={{
            marginBottom: 12,
            display: "flex",
            alignItems: "center"
          }}
        >
          <Icon style={{ fontSize: 26, display: "flex" }} type="inbox" />
          {t("account_settings.upload_new_avatar")}
        </Button>
      </Upload>
      <a href={url}>
        <Button
          size="large"
          style={{
            backgroundColor: "#0077B5",
            color: "white",
            display: "flex",
            alignItems: "center"
          }}
        >
          <Icon
            style={{ color: "white", fontSize: 26, display: "flex" }}
            type="linkedin"
          />
          {t("account_settings.add_from_linkedin")}
        </Button>
      </a>
      <Button type="link" style={{ marginTop: 24 }} onClick={() => setStep(2)}>
        Skip adding an Avatar
      </Button>
    </Box>
  );

  const thirdStep = (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography.Text style={{ fontSize: 18 }}>
        {t("account_wizard.step_2_content")}
      </Typography.Text>

      <Form.Item style={{ width: "90%", margin: "12px auto" }}>
        <Input.Group compact>
          <Select
            size="large"
            style={{ width: "125px" }}
            showSearch
            onChange={(value: string): void => setCountryCode(value)}
            value={countryCode}
          >
            {Object.entries(codes).map(([country, phoneCode]) => (
              <Select.Option value={country}>
                {country} (+{phoneCode})
              </Select.Option>
            ))}
          </Select>
          <Input
            size="large"
            placeholder="1234567890"
            value={phoneNumber}
            style={{ width: "calc(100% - 125px)" }}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </Input.Group>
      </Form.Item>

      <Button type="link" style={{ marginTop: 12 }} onClick={() => setStep(4)}>
        Skip adding a phone number
      </Button>
    </Box>
  );

  const fourthStep = (
    <Box
      width={350}
      margin="12px auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography.Text style={{ fontSize: 18, marginBottom: 24 }}>
        {t("account_wizard.step_3_content")}
      </Typography.Text>
      <CodeInput setCode={setCode} />
      {verificationStatus === PENDING && (
        <Spin size="large" style={{ marginTop: 12 }} />
      )}
      {verificationStatus === VERIFIED && (
        <Box
          margin="12px auto"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Icon
            type="check-circle"
            theme="filled"
            style={{
              color: "#40cc75",
              fontSize: 36,
              marginTop: 12,
              marginBottom: 12
            }}
          />
          <Typography.Text style={{ fontSize: 18 }}>
            {t("account_wizard.phone_number_verified")}
          </Typography.Text>
        </Box>
      )}
      {verificationStatus === NOT_VERIFIED && (
        <Box
          margin="12px auto"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography.Text style={{ fontSize: 18 }}>
            {t("account_wizard.havent_received_code")}
          </Typography.Text>
          <Button style={{ fontSize: 18 }} type="link" onClick={resendCode}>
            {t("account_wizard.resend_verification_code")}
          </Button>
          <Button
            style={{ fontSize: 14, marginTop: 12 }}
            type="link"
            onClick={() => setStep(4)}
          >
            {t("account_wizard.skip_and_setup_later")}
          </Button>
        </Box>
      )}
      {verificationStatus === VERIFICATION_ERROR && (
        <Box
          margin="12px auto"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Icon
            type="close-circle"
            theme="filled"
            style={{
              color: "red",
              fontSize: 36,
              marginTop: 12,
              marginBottom: 12
            }}
          />
          <Typography.Text style={{ fontSize: 18 }}>
            {t("account_wizard.verification_failed")}
          </Typography.Text>
        </Box>
      )}
    </Box>
  );

  const fifthStep = (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography.Text style={{ fontSize: 18, marginBottom: 24 }}>
        {t("account_wizard.step_4_content")}
      </Typography.Text>
      <TimePicker
        onChange={(e) => setMailTime(e)}
        use12Hours
        defaultValue={moment(mailTime, "hh:mm A")}
        disabledHours={() => [0]}
        format="hh:mm a"
        size="large"
        style={{ width: 200, margin: "0 auto" }}
      />
    </Box>
  );

  const sixthStep = (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography.Text style={{ fontSize: 18, marginBottom: 12 }}>
        {t("account_wizard.step_5_content")}
      </Typography.Text>
      <Typography.Text style={{ fontSize: 18, marginBottom: 24 }}>
        {t("account_wizard.step_5_content_2")}
        <strong>{t("account_wizard.step_5_content_3")}</strong>
        {t("account_wizard.step_5_content_4")}
      </Typography.Text>
      <UserSearch
        mode="multiple"
        labelInValue
        external={false}
        filterSelf
        hideList={[]}
        filterMethod={(u) => u.active}
        style={{ width: "calc(100% - 150px)" }}
        value={feedbackProviders}
        onSelect={(values) => setFeedbackProviders(values)}
        placeholder={`Search Users`}
        filterOption={nameFilter}
        id="user-multiselect"
      ></UserSearch>
      <Typography.Text
        style={{
          width: "100%",
          marginTop: 12,
          color:
            feedbackProviders.length < 3
              ? "red"
              : feedbackProviders.length < 5
              ? "orange"
              : "#40cc75"
        }}
      >
        {feedbackProviders.length} selected
      </Typography.Text>
    </Box>
  );
  const seventhStep = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingTop={24}
    >
      <Typography.Text
        style={{ fontSize: 18, marginBottom: 12, textAlign: "center" }}
      >
        {t("account_wizard.step_6_content")}
      </Typography.Text>
      <Icon
        type="check-circle"
        theme="filled"
        style={{ color: "#40cc75", fontSize: 64 }}
      ></Icon>
    </Box>
  );

  let okText = t("next");

  if (step === 5) {
    okText = t("save");
  } else if (step === 4 && currentUser.habits.length === 0) {
    okText = t("save");
  }

  if (step === 6) {
    okText = t("close");
  }

  const showBack = step !== 0 && step !== 6;

  return (
    <Modal
      visible={visible}
      title={t(`account_wizard.step_${step}_title`)}
      width={500}
      okText={okText}
      onOk={handleNextStep}
      maskClosable={false}
      closable={false}
      onCancel={previousStep}
      cancelText={t("back")}
      okButtonProps={{ disabled: !nextButtonActive(), loading: buttonLoading }}
      cancelButtonProps={{
        style: { display: showBack ? "inline-block" : "none" }
      }}
    >
      {step === 0 && firstStep}
      {step === 1 && secondStep}
      {step === 2 && thirdStep}
      {step === 3 && fourthStep}
      {step === 4 && fifthStep}
      {step === 5 && sixthStep}
      {step === 6 && seventhStep}
    </Modal>
  );
};

export default AccountWizard;
