import React, { useState, useEffect } from "react";
import { IHabitCategory } from "shared/types";
import Box from "layout/Box";
import { Typography, Divider, Icon, Radio, Tooltip } from "antd";
import capitalize from "utils/capitalize";
import styled from "styled-components";
import media, { lightenDarkenColor } from "utils/style-utils";
import { IFetchUserHabitsQuery } from "../queries/fetchUserHabits.generated";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { IFetchAllHabitsQuery } from "../queries/fetchAllHabits.generated";
import HabitListItem from "../HabitListItem";
import CategoryIcon from "../CategoryIcon";
import { useMediaQuery } from "react-responsive";
import { NotMobile } from "containers/Responsive";
import { useTranslation } from "react-i18next";

interface Props {
  category: IHabitCategory;
  closeCategory: () => void;
  color: string;
  habits: IFetchAllHabitsQuery["habits"]["nodes"];
  description: string;
  selectedHabits: IFetchUserHabitsQuery["profile"]["habits"];
  addHabit: (id: string) => void;
  removeHabit: (id: string) => void;
  expanded: boolean;
}

const Container = styled.div`
	display: flex;
	background: ${(props) => props.color};
	height: 100%;
	width: 100%;
	border-radius: 6px
	color: white;
	padding-bottom: 24px;
	position: relative;
	flex-direction: column;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	transition: 0.2s ease-in-out;
	&:hover {
		background: ${(props) =>
      !props.expanded ? lightenDarkenColor(props.color, 20) : props.color}
	}

		
	${media.handheld`
		overflow-y: ${(props) => props.expanded && "auto"};
	`}
`;

const StyledRadio = styled(Radio)`
  .ant-radio-inner {
    background-color: transparent;
    height: 18px;
    width: 18px;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: white !important;
    height: 18px;
    width: 18px;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: white;
  }
  .ant-radio-inner:after {
    background-color: white;
    height: 10px;
    width: 10px;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: white;
  }
`;

const ListContainer = styled.div`
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  padding: 24px;
  flex: 1;
  opacity: ${(props) => (props.visible ? "1" : 0)};
  transition: opacity 0.2s;
  margin-top: 12px;
  width: calc(100% - 48px);
  margin-left: 24px;
  margin-right: 24px;
  max-width: 1400px;
  margin-right: auto;
  margin-left: 24px;
  border-radius: 6px 6px;
  border-left: ${(props) => `1px solid ${props.color}`};
  border-right: ${(props) => `1px solid ${props.color}`};
  border-bottom: ${(props) => `1px solid ${props.color}`};
  background: white;

  ${media.handheld`
		max-height: unset;
		overflow-y: unset;
	`}
`;

const ExpandIcon = styled(Icon)`
  opacity: 0.7;
  color: white;
  font-size: 28px;
  position: absolute;
  top: 12px;
  right: 12px;
  transition: 0.2s;
  &:hover {
    opacity: 1;
  }
`;

const Category: React.FC<Props> = ({
  category,
  color,
  habits,
  selectedHabits,
  addHabit,
  removeHabit,
  description,
  expanded,
  closeCategory
}) => {
  const [visible, setVisible] = useState(false);
  const [habitType, setHabitType] = useState<"IC" | "L">("IC");
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { t } = useTranslation();
  useEffect(() => {
    if (expanded) {
      setTimeout(() => {
        setVisible(true);
      }, 400);
    } else {
      setVisible(false);
    }
  }, [expanded]);

  return (
    <Container color={color} expanded={expanded}>
      <Tooltip title={expanded ? "Collapse Category" : "Expand Category"}>
        <ExpandIcon
          type={expanded ? "shrink" : "arrows-alt"}
          onClick={() => expanded && closeCategory()}
        />
      </Tooltip>
      <Box padding={24}>
        {/* {expanded && (
          <Icon
            type="close"
            style={{ color: "white", fontSize: 32, position: "absolute", top: 24, right: 24 }}
            onClick={closeCategory}
          />
        )} */}
        <Typography.Title level={2} style={{ color: "white" }}>
          {t(`habit_picker.${category}`)}
        </Typography.Title>
        <Typography.Text style={{ color: "white", fontSize: 18 }}>
          {t(description)}
        </Typography.Text>
        <Box
          marginTop={24}
          display="flex"
          color="white"
          alignItems={isMobile ? "flex-start" : "center"}
          flexDirection={isMobile ? "column" : "row"}
        >
          <Box display="flex" marginBottom={isMobile ? 12 : 0}>
            <Box
              borderRadius="50%"
              background="white"
              display="flex"
              alignItems="center"
              color="rgba(0, 0, 0, 0.85)"
              justifyContent="center"
              width={24}
              lineHeight={24}
              fontSize={18}
              height={24}
              minHeight={24}
              minWidth={24}
            >
              {selectedHabits.length}
            </Box>
            <Box marginLeft={12} fontSize={14} marginRight="6px">
              {t("habit_picker.habits_selected_from_category")}
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexWrap="wrap">
          {selectedHabits.map((habit) => (
            <Box
              borderRadius={15}
              background="white"
              padding="8px 12px"
              color="rgba(0, 0, 0, 0.85)"
              fontSize={14}
              lineHeight="14px"
              display="flex"
              alignItems="center"
              margin="6px"
            >
              <Icon
                type="close"
                style={{ marginRight: 6 }}
                onClick={(e) => {
                  e.stopPropagation();
                  removeHabit(habit.id);
                }}
              />
              {habit.name}
            </Box>
          ))}
        </Box>
      </Box>
      {expanded && (
        <Box paddingLeft="24px">
          <Radio.Group
            onChange={(e): void => setHabitType(e.target.value)}
            size="large"
            value={habitType}
          >
            <StyledRadio style={{ color: "white", fontSize: 16 }} value="IC">
              {t("habit_picker.ic_habits")}
            </StyledRadio>
            <StyledRadio style={{ color: "white", fontSize: 16 }} value="L">
              {t("habit_picker.l_habits")}
            </StyledRadio>
          </Radio.Group>
        </Box>
      )}
      {expanded && (
        <ListContainer visible={visible}>
          <NotMobile>
            <Box display="flex" width="100%">
              <Box
                width="60%"
                display="flex"
                justifyContent="center"
                marginBottom={12}
              >
                <Typography.Title
                  style={{ textDecoration: "underline" }}
                  level={4}
                >
                  {t("habits_singular")}
                </Typography.Title>
              </Box>
              <Box width="40%" display="flex" justifyContent="center">
                <Typography.Title
                  style={{ textDecoration: "underline" }}
                  level={4}
                >
                  {t("behaviors_plural")}
                </Typography.Title>
              </Box>
            </Box>
          </NotMobile>
          {habits
            .filter((h) => h.employeeType === habitType)
            .map((habit, i) => (
              <>
                <HabitListItem
                  addHabit={addHabit}
                  showBehaviors={!isMobile}
                  removeHabit={removeHabit}
                  habit={habit}
                  selected={!!selectedHabits.find((h) => habit.id === h.id)}
                />

                <Divider style={{ minHeight: 1 }} />
              </>
            ))}
        </ListContainer>
      )}
      <NotMobile>
        <CategoryIcon color={color} />
      </NotMobile>
      {/* <Box
        background="white"
        height={48}
        width={48}
        display="flex"
        justifyContent="center"
        borderRadius="50%"
        alignItems="center"
        position="absolute"
        bottom={24}
        right={24}
      >
        <Image style={{ color, width: 32, height: 32 }} />
      </Box> */}
    </Container>
  );
};

export default Category;
