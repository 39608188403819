/* eslint-disable radix */

/* eslint-disable no-debugger */

/* eslint-disable no-unused-vars */
import React, { useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";
import VideoWidget from "./VideoWidget";
import TipWidget from "./TipWidget";
import BlogPostWidget from "./BlogPostWidget";
import { getDom, transitionParent, measureWidgetSpace } from "./measure";
import AdditionalFeedbackWidget from "./AdditionalFeedbackWidget";

const Widget = ({ w, behavior, habit, theme }) => {
  const [widgetOverflow, setWidgetOverflow] = useState(false);

  const transition = () => {
    setWidgetOverflow(false);
    transitionParent(getDom(w));
  };

  useLayoutEffect(() => {
    if (measureWidgetSpace(getDom(w))) {
      setWidgetOverflow(true);
    }
  }, [w, behavior, habit]);

  switch (w.type) {
    case "VideoWidget":
      return <VideoWidget font={theme.font} w={w} overflow={widgetOverflow} />;
    case "BlogPostWidget":
      return (
        <BlogPostWidget font={theme.font} w={w} overflow={widgetOverflow} />
      );
    case "TipWidget":
      return (
        <TipWidget
          font={theme.font}
          transition={transition}
          w={w}
          overflow={widgetOverflow}
        />
      );
    default:
      return (
        <AdditionalFeedbackWidget
          transition={transition}
          w={w}
          font={theme.font}
          overflow={widgetOverflow}
        />
      );
  }
};

Widget.propTypes = {
  w: PropTypes.object.isRequired,
  behavior: PropTypes.number.isRequired,
  habit: PropTypes.object.isRequired,
  font: PropTypes.object.isRequired
};

export default withTheme(Widget);
