import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal, Icon, Button } from "antd";
import queryString from "query-string";
import { MSTeams as Api } from "services/api";
import Logo from "assets/logo_color.png";

type Props = {
  onClose: () => void;
};

const Slack = ({ onClose }: Props) => {
  const { ref, msTeamsId } = queryString.parse(useLocation().search);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const bindAccount = async () => {
    try {
      await Api.create({ msTeamsId, ref });
      setSuccess(true);
    } catch (err) {
      setError(true);
    }
  };

  return (
    <Modal
      visible
      title="Link your Rhabit account to Teams!"
      footer={null}
      maskClosable={false}
      closable={false}
    >
      <div
        style={{
          width: "75%",
          display: "flex",
          margin: "0 auto",
          alignItems: "center",
          justifyContent: "space-around"
        }}
      >
        <img
          src={Logo}
          alt="rhabit-logo"
          style={{ height: 75, width: "auto" }}
        />
        <Icon type="plus" style={{ fontSize: 36 }} />
        <img
          style={{ height: 75, width: "auto" }}
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Microsoft_Office_Teams_%282018%E2%80%93present%29.svg/1200px-Microsoft_Office_Teams_%282018%E2%80%93present%29.svg.png"
          alt="teams-logo"
        />
      </div>
      <div
        style={{
          width: "100%",
          padding: 24,
          fontSize: 16,
          textAlign: "center"
        }}
      >
        Connect your account to get started using the Teams integration for
        Rhabit!
      </div>
      {error && (
        <div
          style={{
            width: "100%",
            padding: 24,
            fontSize: 16,
            textAlign: "center",
            color: "red"
          }}
        >
          Whoops! Something went wrong. Try starting the account linking process
          over again in Teams.
        </div>
      )}
      {success ? (
        <div
          style={{
            width: "100%",
            padding: 12,
            marginTop: -12,
            fontSize: 16,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <span style={{ fontWeight: 500 }}>
            <Icon
              type="check-circle"
              style={{ color: "#00a74f" }}
              theme="filled"
            />{" "}
            Account connected!{" "}
          </span>
          You should have received a message from the Rhabit Teams bot
          confirming that your accounts were linked succesfully.
          <Button style={{ marginTop: 12 }} onClick={onClose}>
            Close
          </Button>
        </div>
      ) : (
        <div
          style={{
            width: "75%",
            display: "flex",
            margin: "0 auto",
            alignItems: "center",
            justifyContent: "space-around"
          }}
        >
          <Button size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button size="large" type="primary" onClick={bindAccount}>
            Connect
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default Slack;
