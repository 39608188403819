/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Modal, Select, Button } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Update } from "services/api";
import { EditorWrapper } from "./styles";

const { Option } = Select;

const UpdateModal = ({ visible, onOk, update, onClose }) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(convertFromRaw(JSON.parse(update.content)))
  );

  useEffect(() => {
    Update.markSeen(update.id);
  }, []);
  return (
    <Modal
      onOk={onClose}
      onCancel={onClose}
      centered={window.innerWidth < 500}
      title={`What's new - ${moment(update.created_at).format("LL")}`}
      visible={visible}
      width={window.innerWidth < 500 ? "98vw" : 600}
      bodyStyle={{
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: 500,
        overflowY: "auto"
      }}
      footer={[
        <Button onClick={onClose} type="primary">
          Dismiss
        </Button>
      ]}
    >
      <EditorWrapper>
        <Editor
          toolbarHidden
          readOnly
          editorState={editorState}
          toolbarClassName="react-editor-toolbar1"
          wrapperClassName="react-editor-wrapper1"
          editorClassName="react-editor1"
          onEditorStateChange={setEditorState}
        />
      </EditorWrapper>
    </Modal>
  );
};

UpdateModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired
};

export default UpdateModal;
