/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import { Icon } from "antd";
import clientTheme from "assets/theme";
import { media } from "utils/style-utils";

export const StyledIcon = styled(Icon)`
  font-size: 35px;
  color: ${({
  step,
  theme
}) => step && theme.primaryColor} !important;
  ${media.handheld`
    font-size: 20px;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  ${media.handheld`
    flex-direction: column-reverse;
    margin-top: 25px;
  `}
`;

export const DescContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  ${media.handheld`
    width: 100%;
  `}
`;

export const Header = styled.span`
  font-size: ${clientTheme.font.header}px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const ImageContainer = styled.div`
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.8);
  ${media.handheld`
    padding: 0px;
    overflow: scroll;
  `}
`;

export const Item = styled.div`
  padding: 0 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;