import React from "react";
import styled from "styled-components";
import Box from "layout/Box";
import moment from "moment";
import UserAvatar from "components/UserAvatar";
import { IFetchManagerDashboardQuery } from "../fetchManagerDashboard.generated";
import UserHabits from "./UserHabits";
import HabitTrend from "./HabitTrend";
import useStores from "utils/useStores";
import { User } from "containers/HomePage/Feed/HighfiveHighlights/styles";

type Unpacked<T> = T extends (infer U)[] ? U : T;

type User = Unpacked<IFetchManagerDashboardQuery["team"]["users"]>;

interface Props {
  user: User;
  profile: boolean | undefined;
  openUserModal?: (id: string) => void;
}

const StyledCell = styled.td`
  padding: 12px 0;

  &:first-child {
    border-right: 2px solid #e8e8e8;
  }
`;

const StyledRow = styled.tr`
  cursor: pointer;
  &:hover {
    transition: 0.2s;
    background-color: #e8e8e8;
  }
`;

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const getStrongestHabit = (
  user: User,
  isDemo: boolean
): { name: string; score: number } | null => {
  if (isDemo) {
    return {
      name:
        user.habitUsers[randomIntFromInterval(0, user.habitUsers.length - 1)]
          .habit.name,
      score: randomIntFromInterval(85, 100)
    };
  }

  let strongest = user.habitUsers.filter((h) => !!h.currentScore)[0];
  if (!strongest || !strongest.currentScore) {
    return null;
  }
  if (strongest && strongest.currentScore) {
    user.habitUsers.forEach((habitUser) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (
        habitUser.currentScore &&
        habitUser.currentScore.value > strongest.currentScore.value
      ) {
        strongest = habitUser;
      }
    });
    return { name: strongest.habit.name, score: strongest.currentScore.value };
  }
  return null;
};
const getWeakestHabit = (
  user: User,
  isDemo
): { name: string; score: number } | null => {
  if (isDemo) {
    return {
      name:
        user.habitUsers[randomIntFromInterval(0, user.habitUsers.length - 1)]
          .habit.name,
      score: randomIntFromInterval(45, 75)
    };
  }

  let strongest = user.habitUsers.filter((h) => !!h.currentScore)[0];
  if (!strongest) {
    return null;
  }
  user.habitUsers.forEach((habitUser) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (
      habitUser.currentScore &&
      habitUser.currentScore.value < strongest.currentScore.value
    ) {
      strongest = habitUser;
    }
  });
  return {
    name: strongest.habit.name,
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    score: strongest.currentScore.value
  };
};

const getLatestResponse = (user: User): string => {
  if (!user.latestResponse) {
    return "Never";
  }
  const diff = moment().diff(
    moment(user.latestResponse, "YYYY-MM-DD HH:mm:ss z"),
    "days"
  );
  if (diff === 0) {
    return "Today";
  }
  if (diff === 1) {
    return "Yesterday";
  }
  return `${diff} Days Ago`;
};

const UserRow: React.FC<Props> = ({ user, profile, openUserModal }) => {
  const {
    profileStore: { currentUser }
  } = useStores();

  const isDemo =
    currentUser.organization.name === "Meta Co" ||
    currentUser.organization.name === "Hopp Demo";

  const strongestHabit = getStrongestHabit(user, isDemo);
  const weakestHabit = getWeakestHabit(user, isDemo);
  const latestResponse = getLatestResponse(user, isDemo);

  return (
    <StyledRow
      key={user.id}
      style={{ borderBottom: "1px solid #e8e8e8" }}
      onClick={(): void => openUserModal && openUserModal(user.id)}
    >
      <StyledCell style={{ padding: profile && "0" }}>
        <Box
          paddingLeft="12px"
          display="flex"
          flexDirection={profile ? "row" : "column"}
          alignItems="center"
          mb={profile ? "12px" : "0px"}
        >
          <UserAvatar
            avatar={user.avatar}
            name={user.name}
            size="large"
            lineHeight={64}
            style={{ fontSize: 24 }}
          />
          <div style={{ textAlign: "center", padding: "0 12px", fontSize: 16 }}>
            {user.name}
          </div>
        </Box>
      </StyledCell>
      {!profile && (
        <StyledCell style={{ paddingLeft: 12 }}>
          <UserHabits habitUsers={user.habitUsers} />
        </StyledCell>
      )}
      <StyledCell style={{ padding: profile && "0" }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          flexShrink={0}
        >
          <HabitTrend habitUsers={user.habitUsers} />
        </Box>
      </StyledCell>
      {!profile && (
        <StyledCell style={{ padding: profile && "0" }}>
          {strongestHabit ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              fontSize="16px"
            >
              <span style={{ textAlign: "center" }}>{strongestHabit.name}</span>
              <span style={{ textAlign: "center" }}>
                {strongestHabit.score}%
              </span>
            </Box>
          ) : (
            <span style={{ textAlign: "center" }}>Not Enough Data</span>
          )}
        </StyledCell>
      )}
      {!profile && (
        <StyledCell style={{ padding: profile && "0" }}>
          {weakestHabit ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              fontSize="16px"
            >
              <span style={{ textAlign: "center" }}>{weakestHabit.name}</span>
              <span style={{ textAlign: "center" }}>{weakestHabit.score}%</span>
            </Box>
          ) : (
            <span style={{ textAlign: "center" }}>Not Enough Data</span>
          )}
        </StyledCell>
      )}
      <StyledCell style={{ padding: profile && "0" }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          fontSize="16px"
        >
          {latestResponse}
        </Box>
      </StyledCell>
    </StyledRow>
  );
};

export default UserRow;
