import React, { useState } from "react";
import LoadingOverlay from "components/LoadingOverlay";
import Box from "layout/Box";
import { Typography, Button, Icon, Modal } from "antd";
import { NotMobile } from "containers/Responsive";
import UserMultiSelect from "components/UserMultiSelect";
import useStores from "utils/useStores";
import { useFetchUserRelationshipsQuery } from "../queries/fetchUserRelationships.generated";
import { IFetchUserHabitsQuery } from "../queries/fetchUserHabits.generated";
import RaterCard from "./RaterCard";
import HabitListItem from "../HabitListItem";
import { useCreateRelationshipMutation } from "../mutations/createRelationship.generated";
import { useUpdateRatersMutation } from "../mutations/updateRaters.generated";
import { motion, useAnimation } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { UserLite } from "types/habit";

interface Props {
  newHabits: IFetchUserHabitsQuery["user"]["habits"];
  onClose: () => void;
  managedUser: UserLite;
}

const UpdateRelationships: React.FC<Props> = ({
  newHabits,
  onClose,
  managedUser
}) => {
  const controls = useAnimation();
  const { profileStore } = useStores();

  const id = managedUser ? managedUser.id : profileStore.currentUser.id;

  const { data, loading, error, refetch } = useFetchUserRelationshipsQuery({
    variables: { id }
  });
  const [createRelationship] = useCreateRelationshipMutation();
  const [updateRaters] = useUpdateRatersMutation();
  const [index, setIndex] = useState(0);
  const [userSelectOpen, setUserSelectOpen] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { t } = useTranslation();

  if (loading || !data || error) {
    return <LoadingOverlay />;
  }

  const handleCreateRelationships = async (
    users: { id: string }[]
  ): Promise<void> => {
    const { currentUser } = profileStore;
    const results = [];
    for (let i = 0; i < users.length; i += 1) {
      results.push(
        createRelationship({
          variables: {
            input: { relatingUserId: id, relatedUserId: users[i].id }
          }
        })
      );
    }

    await Promise.all(results);
    setSelected([...selected, ...users.map((u) => u.id)]);
    await refetch();
    setUserSelectOpen(false);
  };

  const saveRaters = async (): Promise<void> => {
    const habit = newHabits[index];

    await updateRaters({
      variables: {
        input: { userId: id, habitId: habit.id, raterIds: selected }
      }
    });
    controls.start({ opacity: 0 });

    setTimeout(() => {
      setSelected([]);
      controls.start({ opacity: 1 });
      setIndex(index + 1);
    }, 300);
  };

  const handleSave = (): void => {
    if (selected.length < 3) {
      Modal.confirm({
        title: t("habit_picker.relationships.confirm_save_title"),
        content: t("habit_picker.relationships.confirm_save_content"),
        okText: t("habit_picker.relationships.confirm_save_ok_text"),
        onOk: saveRaters
      });
    } else {
      saveRaters();
    }
  };

  const habit = newHabits[index];
  if (!habit) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop="20%"
        justifyContent="center"
        padding="0 24px"
      >
        <Icon
          type="check-circle"
          theme="filled"
          style={{ color: "#40cc75", fontSize: 64, marginBottom: 12 }}
        />
        <Typography.Title level={2}>
          {t("habit_picker.relationships.done_title")}
        </Typography.Title>
        <Typography.Title level={4}>
          {t("habit_picker.relationships.done_text")}
        </Typography.Title>
        <Button
          type="primary"
          size="large"
          onClick={onClose}
          style={{ marginTop: 24 }}
        >
          {t("habit_picker.relationships.done_button")}
        </Button>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" padding={24}>
      <Modal
        visible={userSelectOpen}
        title={t("habit_picker.relationships.title")}
      >
        <UserMultiSelect
          alreadySelectedMessage={t(
            "habit_picker.relationships.already_selected"
          )}
          hideList={
            data ? data.user.relationships.map((r) => r.relatedUser) : []
          }
          title={t("habit_picker.relationships.add_feedback_providers")}
          filterSelf
          buttonLoading={false}
          open={userSelectOpen}
          addExternalUser={() => null}
          handleCancel={() => setUserSelectOpen(false)}
          handleSubmit={handleCreateRelationships}
          withExternal={false}
        />
      </Modal>
      <Typography.Title level={2}>
        {t("habit_picker.relationships.update_subjects_feedback_circle", {
          subject: managedUser ? `${managedUser.name}'s` : t("your")
        })}
      </Typography.Title>
      <Box display="flex" justifyContent="space-between">
        <Typography.Title level={3}>
          {t("habit_picker.relationships.add_providers_for")}
        </Typography.Title>
      </Box>
      <motion.div animate={controls}>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          padding={24}
          background="#e8e8e8"
          borderRadius={6}
        >
          <Box width="60%" display="flex" alignItems="center">
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography.Title level={4}>{habit.name}</Typography.Title>

              <Typography style={{ fontSize: 16 }}>
                {habit.description}
              </Typography>
            </Box>
          </Box>
          <NotMobile>
            <Box display="flex" flexDirection="column" width="40%">
              <ul>
                {habit.behaviors.map((behavior) => (
                  <li>
                    <Typography style={{ fontSize: 16 }}>
                      {behavior.anchor}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Box>
          </NotMobile>
        </Box>
        {selected.length < 3 && (
          <span style={{ color: "red", position: "absolute" }}>
            {t("habit_picker.relationships.warning")}
          </span>
        )}
        <Box
          display="flex"
          alignItems="center"
          flexDirection={isMobile ? "column" : "row"}
          margin={isMobile ? "12px 0" : 0}
        >
          <Box display="flex" alignItems="center">
            <Box
              fontSize={32}
              color={selected.length >= 3 ? "rgba(0, 0, 0, 0.85)" : "red"}
              marginRight="6px"
            >
              {selected.length}
            </Box>
            {t("habit_picker.relationships.selected")}
          </Box>
          <Button
            style={{ margin: "24px 12px", width: "fit-content" }}
            onClick={() =>
              setSelected(
                data.user.relationships.map(({ relatedUser }) => relatedUser.id)
              )
            }
          >
            {t("habit_picker.relationships.select_all")}
          </Button>
          <Button type="primary" onClick={handleSave}>
            {t("habit_picker.relationships.save_changes_and_continue")}
          </Button>
        </Box>
        <span style={{ fontStyle: "italic" }}>
          {t("habit_picker.relationships.use_plus_button")}
        </span>
        <Box
          width="100%"
          display="flex"
          flexWrap="wrap"
          marginTop="6px"
          justifyContent={isMobile && "center"}
        >
          <Box
            onClick={() => setUserSelectOpen(true)}
            padding={24}
            height="275px"
            width="200px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ cursor: "pointer " }}
            position="relative"
            marginRight={isMobile ? 0 : 24}
            marginBottom={24}
            border="1px dashed #e8e8e8"
            borderRadius={6}
          >
            <Icon type="plus" fontSize={36} />
            <span> {t("habit_picker.relationships.add_someone_new")}</span>
          </Box>
          {data.user.relationships.map(({ relatedUser }) => (
            <RaterCard
              user={relatedUser}
              selected={selected.includes(relatedUser.id)}
              addRater={() => setSelected([...selected, relatedUser.id])}
              removeRater={() =>
                setSelected(selected.filter((s) => s !== relatedUser.id))
              }
            />
          ))}
        </Box>
      </motion.div>
    </Box>
  );
};

export default UpdateRelationships;
