// @flow
import React from "react";
import moment from "moment";
import { UserLite } from "types/shared";
import NoContent from "assets/Flavor5.svg";

import { Container, NoContentContainer } from "./styles";
import Tile from "./Tile";

export type IAdditionalFeedback = {
  answer: string | null;
  created_at: string;
  id: string;
  seen: boolean;
  prompt: {
    question_text: string;
  };
  feedback_giver: UserLite;
  asker: UserLite;
  habit: string;
  feedback_answer: { response: string; created_at: string };
};

export type AdditionalFeedbackGroup = {
  id: string;
  created_at: string;
  status: string;
  prompt: {
    question_text: string;
    asker: UserLite;
    habit_name: string;
  };
  additional_feedbacks: IAdditionalFeedback[];
};

interface IOwnProps {
  feedback: AdditionalFeedbackGroup[];
  cancelRequest: (id: string) => void;
}

const AdditionalFeedback = ({ feedback, cancelRequest }: IOwnProps) => {
  if (!feedback.length) {
    return (
      <NoContentContainer>
        <span style={{ marginBottom: 65 }}>
          No additional feedback out at this time.
        </span>
        <img src={NoContent} alt="no-feedback" style={{ width: "40%" }} />
      </NoContentContainer>
    );
  }
  return (
    <Container>
      {feedback
        .sort((a, b) =>
          moment(a.created_at).valueOf() > moment(b.created_at).valueOf()
            ? 1
            : -1
        )
        .map((f) => (
          <Tile
            feedbackGroup={f}
            id={f.id}
            cancelRequest={() => cancelRequest(f.id)}
          />
        ))}
    </Container>
  );
};

export default AdditionalFeedback;
