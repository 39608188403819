import { ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

export const Desktop = ({
  children
}: {
  children: ReactNode;
}): null | JSX.Element => {
  const isDesktop = useMediaQuery({ minWidth: 1600 });
  return isDesktop ? (children as JSX.Element) : null;
};
export const Laptop = ({
  children
}: {
  children: ReactNode;
}): null | JSX.Element => {
  const isLaptop = useMediaQuery({ minWidth: 768, maxWidth: 1599 });
  return isLaptop ? (children as JSX.Element) : null;
};
export const LaptopOrMobile = ({
  children
}: {
  children: ReactNode;
}): null | JSX.Element => {
  const isLaptop = useMediaQuery({ maxWidth: 1599 });
  return isLaptop ? (children as JSX.Element) : null;
};
export const Tablet = ({
  children
}: {
  children: ReactNode;
}): null | JSX.Element => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? (children as JSX.Element) : null;
};
export const Mobile = ({
  children
}: {
  children: ReactNode;
}): null | JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? (children as JSX.Element) : null;
};
export const Default = ({
  children
}: {
  children: ReactNode;
}): null | JSX.Element => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? (children as JSX.Element) : null;
};
export const NotMobile = ({
  children
}: {
  children: ReactNode;
}): null | JSX.Element => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? (children as JSX.Element) : null;
};
