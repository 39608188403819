import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  // border: 1px solid black;
  border-radius: 5px;
  padding: 0px 5px;
`;

export const CarrotImg = styled.img`
  height: 75px;
`;

export const Text = styled.span`
  font-size: ${({
  theme
}) => `${theme.font.header}px`};
  white-space: nowrap;
  cursor: pointer;
  margin-right: 15px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
`;

export const Score = styled.span`
  color: #00a74f;
  float: right;
  margin-left: 10px;
`;

export const HabitDescription = styled.span`
  font-size: ${({
  theme
}) => theme.font.subheader};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;