import React from "react";
import { useTranslation } from "react-i18next";

const laptop = window.innerWidth < 1350;
const desktop = window.innerWidth > 1350;

const CustomLegend = () => {
  const { t } = useTranslation();
  const sizing = () => {
    if (laptop) {
      return { bottom: -20 };
    }
    if (desktop) {
      return { bottom: -40 };
    }
    return { bottom: -40 };
  };
  return (
    <div
      id="legend"
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        left: 10,
        bottom: sizing().bottom
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <span
          style={{
            backgroundColor: "#1DB35E",
            width: "8px",
            height: "8px",
            borderRadius: "50%",
            marginRight: "4px"
          }}
        />
        <span style={{ fontSize: "11px" }}>
          {t("deep_dive.habit_overview.excellent")}
        </span>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <span
          style={{
            backgroundColor: "#77D36E",
            width: "8px",
            height: "8px",
            borderRadius: "50%",
            marginRight: "4px"
          }}
        />

        <span style={{ fontSize: "11px" }}>
          {t("deep_dive.habit_overview.on_track")}
        </span>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <span
          style={{
            backgroundColor: "#FF9231",
            width: "8px",
            height: "8px",
            borderRadius: "50%",
            marginRight: "4px"
          }}
        />

        <span style={{ fontSize: "11px" }}>
          {t("deep_dive.habit_overview.needs_work")}
        </span>
      </div>
    </div>
  );
};

export default CustomLegend;
