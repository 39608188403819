/* eslint-disable no-debugger */
import styled from "styled-components";
import { media } from "utils/style-utils";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  height: 100%;
  ${props => {
    return props.view
      ? "justify-content: none; margin-right: 12px; margin-left: 12px;"
      : "justify-content: flex-start;";
  }}
  position: relative;
  flex-direction: row;

  ${media.handheld`
    flex-direction: ${props => (props.view ? "row" : "column")};
height: ${props => (props.view ? "150px" : "255px")};
    margin-bottom: 75px;

  `}
`;

export const ChartLabel = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #676767;
  font-size: 20px;
  pointer-events: none;
`;

export const PieWrapper = styled.div`
  ${props => {
    return props.view ? "width:60%; margin-right: 12px;" : "width: 50%; margin-right: 12px;";
  }}
  height: 100%;
  position: relative;
  ${media.handheld`
  width: 100%;
  `}
`;

export const BigNumber = styled.div`
  font-size: 24px;
  margin-top: -4px;
`;

export const Label = styled.h4`
  margin-bottom: 0px;
  margin-top: 2px;
`;
export const Number = styled.h3`
  color: ${props => props.color};
  margin-bottom: 0;
  margin-right: 6px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ${media.handheld`
    flex-direction: ${props => {
      //
      return props.view ? "column" : "row";
    }};
    margin-bottom: 12px;
  `}
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
`;
