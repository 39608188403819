import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Modal, Icon, Button } from "antd";
import queryString from "query-string";
import { Slack as Api } from "services/api";

import LoadingOverlay from "components/LoadingOverlay";

type Props = {
  onClose: () => void;
};

const Slack = ({ onClose }: Props) => {
  const { code } = queryString.parse(useLocation().search);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const bindAccount = async () => {
    try {
      await Api.oauth({ code });
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    bindAccount();
  }, []);

  return (
    <Modal
      visible
      title="Link your Rhabit account to Slack!"
      footer={null}
      maskClosable={false}
      closable={false}
    >
      {loading ? (
        <LoadingOverlay />
      ) : (
        <>
          {error && (
            <div
              style={{
                width: "100%",
                padding: 24,
                fontSize: 16,
                textAlign: "center",
                color: "red"
              }}
            >
              Whoops! Something went wrong. Try starting the process over again
              or contact support.
            </div>
          )}
          {success && (
            <div
              style={{
                width: "100%",
                padding: 12,
                marginTop: -12,
                fontSize: 16,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              <span style={{ fontWeight: 500 }}>
                <Icon
                  type="check-circle"
                  style={{ color: "#00a74f" }}
                  theme="filled"
                />{" "}
                Success!
              </span>
              The Rhabit Integration has been added to your Slack workspace!
              <Button style={{ marginTop: 12 }} onClick={onClose}>
                Close
              </Button>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default Slack;
