import styled from "styled-components";

const COLORS = {
  no: 'red',
  yes: '#40cc75',
  "don't know": 'orange',
  null: '#BFBFBF',
  unanswered: '#BFBFBF'
};

export const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${props => COLORS[props.response]};
`;

export const Line = styled.div`
  background: #e8e8e8;
  height: 2px;
  width: ${props => `calc((100% - ${12 * props.checkInLength}px) / ${props.checkInLength - 1}) `};
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const PopoverWrapper = styled.div`
  padding: 12px;
`;