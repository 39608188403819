const nameToInitials = name => {
  try {
    const parts = name.split(' ');
    if (parts.length === 1) {
      return parts[0][0];
    }
    return `${parts[0][0]} ${parts[parts.length - 1][0]}`;
  } catch {
    return '';
  }
};

export default nameToInitials;