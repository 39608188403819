/* eslint-disable no-debugger */
import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import {
  BehaviorContainer,
  BehaviorDotContainer,
  BehaviorDot,
  BehaviorDesc
} from "./styles";
import { alphabet } from "../alphabet";
import { useTranslation } from "react-i18next";

const Behavior = ({
  getDot,
  colorDot,
  selected,
  i,
  behavior,
  open,
  demoRef,
  count, // unchangedIndex,
  locked
}) => {
  const { t } = useTranslation();
  const setRef = () => {
    if (i === 4) {
      return demoRef;
    }
    return null;
  };

  let body;
  if (locked) {
    body = (
      <BehaviorContainer
        score={true}
        ref={setRef()}
        id={`demo_${i}`}
        role="presentation"
        onClick={() => {
          getDot(count);
          open();
        }}
      >
        <BehaviorDotContainer color={"#ccc"} selected={selected === count}>
          <BehaviorDot></BehaviorDot>
        </BehaviorDotContainer>
        <BehaviorDesc selected={selected === count}>
          {behavior.description}
        </BehaviorDesc>
      </BehaviorContainer>
    );
  } else {
    body = (
      <BehaviorContainer
        score={true}
        ref={setRef()}
        id={`demo_${i}`}
        role="presentation"
        onClick={() => {
          getDot(count);
          open();
        }}
      >
        <BehaviorDotContainer
          color={colorDot(behavior.score)}
          selected={selected === count}
        >
          <BehaviorDot>{alphabet[count]}</BehaviorDot>
        </BehaviorDotContainer>
        <BehaviorDesc selected={selected === count}>
          {behavior.description}
        </BehaviorDesc>
      </BehaviorContainer>
    );
  }

  // score will be null if not typeof number

  if (!locked) {
    return <Tooltip title={behavior.description}>{body}</Tooltip>;
  }
  return (
    <Tooltip title={t("deep_dive.habit_overview.behavior_has_no_score")}>
      {body}
    </Tooltip>
  );
};

Behavior.propTypes = {
  demoRef: PropTypes.object.isRequired,
  getDot: PropTypes.func.isRequired,
  colorDot: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  i: PropTypes.number.isRequired,
  behavior: PropTypes.object.isRequired,
  open: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired
  // unchangedIndex: PropTypes.number.isRequired
};

export default Behavior;
