import React from "react";
import { VictoryGroup, VictoryBar, VictoryChart, VictoryAxis } from "victory";
import { ResponsiveBar } from "@nivo/bar";
import styled from "styled-components";
import { Tooltip } from "antd";
import { Unpacked } from "shared/util";
import { IFetchManagerDashboardQuery } from "../fetchManagerDashboard.generated";
import useStores from "utils/useStores";

type User = Unpacked<IFetchManagerDashboardQuery["team"]["users"]>;
export type HabitUser = Unpacked<User["habitUsers"]>;
interface Props {
  habitUsers: User["habitUsers"];
}
const getColor = (score: number): string => {
  if (score === null) return "#A9A9A9";
  if (score < 65) {
    return "#F4A085";
  }
  if (score < 80) {
    return "#9BD199";
  }
  return "#68cd67";
};

const LabelComponent = styled.div`
  color: white;
  line-height: 22px;
`;

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const BarComponent = styled.div`
  width: ${(props) => props.width || "100"}%;
  height: 22px;
  margin-top: 4px;
  padding-right: 6px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  position: relative;
  background: ${(props) => getColor(props.width)};
  border-radius: 0 25px 25px 0;
`;
interface HabitBarProps {
  habit: HabitUser;
}
const HabitBar: React.FC<HabitBarProps> = ({ habit }) => {
  const {
    profileStore: { currentUser }
  } = useStores();

  const isDemo =
    currentUser.organization.name === "Meta Co" ||
    currentUser.organization.name === "Hopp Demo";

  if (!habit.locked && habit.currentScore) {
    return (
      <Tooltip title={habit.habit.name}>
        <BarComponent width={habit.currentScore.value}>
          <LabelComponent>{habit.currentScore.value}%</LabelComponent>
        </BarComponent>
      </Tooltip>
    );
  }

  if (isDemo && !habit.currentScore) {
    const randScore = randomIntFromInterval(55, 100);
    return (
      <Tooltip title={habit.habit.name}>
        <BarComponent width={randScore}>
          <LabelComponent>{randScore}%</LabelComponent>
        </BarComponent>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={habit.habit.name}>
      <BarComponent width={null}>
        <LabelComponent>Not Enough Data</LabelComponent>
      </BarComponent>
    </Tooltip>
  );
};

const UserHabits: React.FC<Props> = ({ habitUsers }) => {
  return (
    <div style={{ width: 150 }}>
      {habitUsers.map((h) => (
        <HabitBar habit={h} />
      ))}
    </div>
  );
};

export default UserHabits;
