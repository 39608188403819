/* eslint-disable no-nested-ternary */
import React from "react";
import { Form, Input, Select, Checkbox, DatePicker, Button } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import {
  NameWrapper,
  MoreOptions,
  OptionsContainer,
  ButtonWrapper
} from "./styles";

const ExternalUserForm = ({
  form,
  user,
  noNps,
  closeForm,
  buttonLoading,
  handleSubmit,
  preText
}) => {
  const { getFieldDecorator } = form;
  return (
    <Form
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <NameWrapper>
        <Form.Item
          label="First Name"
          style={{ width: "45%", marginBottom: 12 }}
        >
          {getFieldDecorator("first_name", {
            rules: [{ required: true }],
            initialValue: user ? user.first_name : ""
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Last Name" style={{ width: "45%", marginBottom: 12 }}>
          {getFieldDecorator("last_name", {
            rules: [{ required: true }],
            initialValue: user ? user.last_name : ""
          })(<Input />)}
        </Form.Item>
      </NameWrapper>
      <Form.Item label="Email" style={{ width: "100%", marginBottom: 12 }}>
        {getFieldDecorator("email", {
          rules: [{ required: true }],
          initialValue: user ? user.email : preText
        })(<Input />)}
      </Form.Item>

      <MoreOptions>
        {
          // <Form.Item label="Frequency" style={{marginBottom:12}}>
          // {
          //   getFieldDecorator('frequency', {
          //     initialValue: user ? user.frequency : 28
          //   })(
          //     <Select placeholder="Frequency">{
          //       [
          //         { label: "2 Weeks", days: 14 },
          //         { label: "1 Month", days: 28 },
          //         { label: "6 Weeks", days: 42 },
          //         { label: "2 Months", days: 56 },
          //         { label: "10 Weeks", days: 70 },
          //         { label: "3 Months", days: 84 }
          //       ].map(i => <Select.Option key={i.days}>{i.label}</Select.Option>)
          //     }</Select>
          //   )
          // }
          // </Form.Item>
        }
        {
          // <Form.Item label="Ratees">
          // <Select
          // mode='multiple'
          // onFocus={loadFeedbackUsers}
          // defaultValue={user.ratees ? user.ratees.map(u => u.id) : []}
          // >{
          //   feedbackUsers.map(u => (
          //     <Select.Option key={u.id}>{u.name}</Select.Option>
          //   ))
          // }</Select>
          // </Form.Item>
        }
        {!noNps && (
          <OptionsContainer>
            <Form.Item style={{ marginBottom: 4 }}>
              {getFieldDecorator("active", {
                initialValue: user ? user.active : false
              })(
                <Checkbox defaultChecked={user ? user.active : false}>
                  Active
                </Checkbox>
              )}
            </Form.Item>
            <Form.Item label="Next Survey" style={{ marginBottom: 4 }}>
              {getFieldDecorator("next_survey", {
                initialValue: user
                  ? moment(user.next_survey || undefined)
                  : moment()
              })(<DatePicker />)}
            </Form.Item>
            <Form.Item label="Recurring" style={{ marginBottom: 4 }}>
              {getFieldDecorator("recurring", {
                initialValue: user ? user.recurring : 90
              })(
                <Select placeholder="Recurring">
                  {[
                    { label: "30 Days", days: 30 },
                    { label: "60 Days", days: 60 },
                    { label: "90 Days", days: 90 }
                  ].map((i) => (
                    <Select.Option key={i.days}>{i.label}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </OptionsContainer>
        )}
      </MoreOptions>
      <ButtonWrapper>
        <Button onClick={closeForm}>Cancel</Button>
        <Button
          type="primary"
          loading={buttonLoading}
          onClick={() => handleSubmit(form)}
        >
          Save
        </Button>
      </ButtonWrapper>
    </Form>
  );
};

ExternalUserForm.propTypes = {
  form: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  noNps: PropTypes.bool.isRequired,
  closeForm: PropTypes.func.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  preText: PropTypes.string
};

ExternalUserForm.defaultProps = {
  preText: ""
};

export default Form.create()(ExternalUserForm);
