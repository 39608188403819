/* eslint-disable no-debugger */
import React from "react";
import { Tooltip, Icon } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import withObjective from "utils/withObjective";
import moment from "moment";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const responseMap = {
  no: "Not Confident",
  yes: "Very Confident",
  "don't know": "Somewhat Confident"
};

const pickColor = (response) => {
  if (response === "no") {
    return "#FF4A4B";
  }
  if (response === "don't know") {
    return "#FFBB01";
  }
  if (response === "yes") {
    return "#40cc75";
  }
  return "lightgrey";
};

const Dot = styled.div`
  width: ${(props) => (props.small ? "16px" : "25px")};
  height: ${(props) => (props.small ? "16px" : "25px")};
  min-width: ${(props) => (props.small ? "16px" : "25px")};
  min-height: ${(props) => (props.small ? "16px" : "25px")};
  background-color: ${({ response }) => pickColor(response)}
  border-radius: 50%;
  margin-right: 6px;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const ObjectiveDot = ({ objective, small, show }) => {
  if (objective) {
    const completed = objective.status === "COMPLETED";
    const lastCheckIn =
      objective.latest_check_in && objective.latest_check_in.response
        ? `Last Check-in: ${moment(objective.latest_check_in.date).format(
            "MMM Do, YYYY"
          )}`
        : null;
    const checkInText = lastCheckIn
      ? responseMap[objective.latest_check_in.response]
      : "Awaiting check-in";
    const completedText = (
      <span>
        Completed: {moment(objective.date_completed).format("MMM Do, YYYY")}
      </span>
    );
    const icon = completed ? (
      <Icon
        type="check-circle"
        theme="filled"
        style={{
          color: "#00a74f",
          marginRight: 6,
          fontSize: small ? 16 : 25
        }}
      />
    ) : (
      <Dot
        small={small}
        response={lastCheckIn ? objective.latest_check_in.response : null}
        style={{ marginRight: 6 }}
      />
    );
    const label = show.includes("label") ? (
      <span>{completed ? completedText : checkInText}</span>
    ) : null;

    const contentText = completed ? (
      completedText
    ) : (
      <TooltipContent>
        <span> {checkInText}</span>
        <span>{lastCheckIn}</span>
      </TooltipContent>
    );

    const tipContent = (
      <TooltipContent>
        {show.includes("name") && <span>{objective.name}</span>}
        {show.includes("owner") && <span>Owner: {objective.owner.name}</span>}
        {contentText}
      </TooltipContent>
    );
    return (
      <Tooltip title={tipContent}>
        <Wrapper>
          {icon}
          {label}
        </Wrapper>
      </Tooltip>
    );
  }
  return <div />;
};

ObjectiveDot.defaultProps = {
  small: false,
  show: ["name", "owner", "latest_check_in"]
};

ObjectiveDot.propTypes = {
  show: PropTypes.array,
  small: PropTypes.bool,
  objective: PropTypes.object.isRequired
};

// eslint-disable-next-line no-unused-vars
const WrappedDot = withObjective(ObjectiveDot);

export default ObjectiveDot;
