/* eslint-disable no-unused-vars */

/* eslint-disable no-plusplus */

/* eslint-disable no-debugger */

/* eslint-disable import/prefer-default-export */

/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import PropTypes from "prop-types";
import { BoxContainer } from "./styles";

const coord = {
  "0,2": 1,
  "1,2": 2,
  "2,2": 3,
  "0,1": 4,
  "1,1": 5,
  "2,1": 6,
  "0,0": 7,
  "1,0": 8,
  "2,0": 9
};

export const NineBox = ({ x, y }) => {
  const getStyle = (index) => {
    const none = [1, 3, 7, 9];
    const style = {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      height: "33.3%",
      width: "33.3%",
      position: "relative"
    };
    if (none.includes(index)) {
      style.border = "none";
    }
    if (index === 2 || index === 8) {
      style.borderLeft = "solid black 1px";
      style.borderRight = "solid black 1px";
    }
    if (index === 4 || index === 6) {
      style.borderBottom = "solid black 1px";
      style.borderTop = "solid black 1px";
    }
    if (index === 5) {
      style.border = "solid black 1px";
    }

    return style;
  };

  const content = (i) => {
    return (
      <div style={getStyle(i)}>
        {coord[`${x},${y}`] === i && (
          <span
            style={{
              position: "absolute",
              width: "8px",
              height: "8px",
              backgroundColor: "black",
              borderRadius: "50%"
            }}
          ></span>
        )}
      </div>
    );
  };

  const buildBox = () => {
    const boxContents = [];
    let i = 1;
    do {
      boxContents.push(content(i));
      i++;
    } while (i <= 9);
    return boxContents;
  };

  return <BoxContainer>{buildBox()}</BoxContainer>;
};

NineBox.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired
};
