/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import Box from "layout/Box";
import { Button, Modal, Icon } from "antd";
import UserAvatar from "components/UserAvatar";
import SendHighfive from "components/SendHighfive";
import ManagerUserProfile from "containers/ManagerUserProfile";
import viewModal from "components/FullScreenModal";
import { IFetchManagerDashboardQuery } from "./fetchManagerDashboard.generated";

const toCamel = (s: string): string => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1
      .toUpperCase()
      .replace("-", "")
      .replace("_", "");
  });
};
interface Props {
  action: IFetchManagerDashboardQuery["managerActionItems"][0];
  dismissAction: (id: string) => void;
}

const buttonText: { [key: string]: string } = {
  scoreIncrease: "Send a Highfive",
  scoreDecrease: "Go to Profile",
  lowScore: "Go to Profile",
  lowParticipation: "Send a Reminder",
  missedTwoWeeks: "Send a Reminder"
};

const ActionItem: React.FC<Props> = ({ action, dismissAction }) => {
  const [actionTaken, setActionTaken] = useState(false);
  const [highfiveUser, setHighfiveUser] = useState<
    null | IFetchManagerDashboardQuery["managerActionItems"][0]["user"]
  >(null);

  const closeSendHighfive = (): void => setHighfiveUser(null);

  const showHighfiveSuccess = (): void => {
    Modal.success({
      title: "High-five succesfully sent!"
    });
    setActionTaken(true);
  };

  const openEmailForm = (
    user: IFetchManagerDashboardQuery["managerActionItems"][0]["user"]
  ): void => {
    window.open(`mailto:${user.email}?subject=${"Rhabit Participation"}`);
  };

  const openUserModal = (userId: string, habitUserId?: string): void => {
    setActionTaken(true);

    viewModal(
      {
        domId: "user-modal-wrapper",
        skipDestroy: true,
        width: "90vw",
        bodyStyle: { padding: 0 }
        // onClose: () => (this.inNetworkSetup = false)
      },
      null,
      <ManagerUserProfile id={userId} lightbox={habitUserId} />
    );
  };

  const routeAction = (): void => {
    if (action.name === "score_increase") setHighfiveUser(action.user);
    if (
      action.name === "missed_two_weeks" ||
      action.name === "low_participation"
    )
      openEmailForm(action.user);
    if (action.name === "score_decrease" || action.name === "low_score")
      openUserModal(
        action.user.id,
        action.subject.__typename === "HabitUser"
          ? action.subject.id
          : undefined
      );
  };

  return (
    <Box
      backgroundColor="white"
      borderRadius="6px"
      width="100%"
      display="flex"
      alignItems="center"
      mb="12px"
      boxShadow="rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;"
      padding="12px"
      justifyContent="space-between"
      fontSize="16px"
    >
      {!!highfiveUser && (
        <SendHighfive
          visible={!!highfiveUser}
          onClose={closeSendHighfive}
          onSuccess={showHighfiveSuccess}
          selectedUsers={highfiveUser ? [highfiveUser] : []}
        />
      )}
      <Box display="flex" alignItems="center" paddingRight="48px">
        <UserAvatar
          name={action.user.name as string}
          avatar={action.user.avatar}
          style={{ minHeight: 48, minWidth: 48, lineHeight: "48px" }}
        />
        <Box ml="24px">{action.text}</Box>
      </Box>
      <Box display="flex" alignItems="center" width="fit-content">
        <Button
          style={{ marginLeft: "auto" }}
          type="primary"
          onClick={routeAction}
        >
          {buttonText[toCamel(action.name)]}
          {actionTaken && <Icon type="check" />}
        </Button>
        <Button type="link" onClick={() => dismissAction(action.id)}>
          Dismiss
        </Button>
      </Box>
    </Box>
  );
};

export default ActionItem;
