import React from "react";
import { Popover } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { Line, Dot, Wrapper, PopoverWrapper } from "./styles";

const RESPONSE_TO_STRING = {
  no: "Blocked",
  yes: "On Track",
  "don't know": "At Risk",
  null: "Not Answered",
  unanswered: "Not Answered"
};

const popoverContent = (checkIn) => (
  <PopoverWrapper>
    <span>{`${checkIn.date} Check-in: ${
      RESPONSE_TO_STRING[checkIn.response]
    }`}</span>
  </PopoverWrapper>
);

const MiniSteps = ({ checkIns }) => (
  <Wrapper id="mini-step-wrapper">
    {checkIns
      .sort((a, b) =>
        moment(a.date).valueOf() > moment(b.date).valueOf() ? 1 : -1
      )
      .map((checkIn, i) => (
        <React.Fragment key={checkIn.date}>
          <Popover content={popoverContent(checkIn)}>
            <Dot response={checkIn.response} />
          </Popover>
          {i !== checkIns.length - 1 && (
            <Line checkInLength={checkIns.length} />
          )}
        </React.Fragment>
      ))}
  </Wrapper>
);

MiniSteps.propTypes = {
  checkIns: PropTypes.array.isRequired
};

export default MiniSteps;
