import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "antd";
import { observer, inject } from "mobx-react";
import { observable } from "mobx";
import moment from "moment";
import LoadingOverlay from "components/LoadingOverlay";
import { Objective } from "services/api";

@inject("objectiveStore")
@observer
class ObjectiveModal extends React.Component {
  @observable loading = false;
  @observable goal = null;
  @observable users = [];
  @observable selectedUser = null;
  // constructor(props) {
  //   super(props);
  //   const { goal, managedTeamId } = this.props;
  //   Goals.fetch(goal.target_id).then(({ data }) => {
  //     this.goal = data;
  //     if (managedTeamId) {
  //       User.byTeam(managedTeamId).then((res) => {
  //         this.users = res.data;
  //         this.loading = false;
  //       });
  //     } else {
  //       this.loading = false;
  //     }
  //   });
  // }

  // componentWillReceiveProps(nextProps) {
  //   const { goal } = this.props;
  //   if (nextProps.goal && nextProps.goal !== goal) {
  //     this.loading = true;
  //     Goals.fetch(nextProps.goal.target_id).then(({ data }) => {
  //       this.goal = data;
  //       if (nextProps.managedTeamId) {
  //         User.byTeam(nextProps.managedTeamId).then((res) => {
  //           this.users = res.data;
  //           this.loading = false;
  //         });
  //       } else {
  //         this.loading = false;
  //       }
  //     });
  //   }
  // }

  confirmReject = () => {
    Modal.confirm({
      title: "Are you sure you want to reject this goal?",
      onOk: () => this.handleReject(),
      onCancel: () => null,
      okText: "Confirm"
    });
  };

  handleSubmit = () => {
    const { onCancel, objectiveStore, objective } = this.props;
    const status =
      moment(objective.start_date).valueOf() > moment().valueOf()
        ? "IN_PROGRESS"
        : "IN_PROGRESS";

    Objective.update(objective.id, { status }).then(({ data }) => {
      objectiveStore.setData(data);
      onCancel();
    });
  };

  handleReject = () => {
    const { onCancel, objectiveStore, objective } = this.props;

    Objective.update(objective.id, { status: "REJECTED" }).then(({ data }) => {
      onCancel();
      objectiveStore.setData(data);
    });
  };

  render() {
    const { objective, onCancel } = this.props;
    return (
      <Modal
        visible={!!objective}
        onCancel={() => onCancel()}
        onOk={this.handleSubmit}
        title={objective.name}
        footer={[
          <Button onClick={this.confirmReject}>Reject</Button>,
          <Button onClick={this.handleSubmit} type="primary">
            {this.selectedUser ? "Send" : "Accept"}
          </Button>
        ]}
      >
        {this.loading ? (
          <LoadingOverlay />
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <span style={{ fontSize: 14, fontWeight: 500 }}>Name: </span>
              {objective.name}
            </span>
            <span>
              <span style={{ fontSize: 14, fontWeight: 500 }}>Due: </span>
              {moment(objective.delivery_date).format("LL")}
            </span>
            <span>
              <span style={{ fontSize: 14, fontWeight: 500 }}>
                Key Results:{" "}
              </span>
              <ul>
                {objective.key_results.map((d) => (
                  <li>{d.name}</li>
                ))}
              </ul>
            </span>

            {/* {managedTeamId && (
              <span style={{ fontSize: 14, fontWeight: 500 }}>
                Delegate to Team Member (Optional):{' '}
              </span>
            )} */}
          </div>
        )}
        {/* {managedTeamId && (
          <Select
            onChange={(e) => (this.selectedUser = e)}
            allowClear
            placeholder="Select Team Member"
            style={{ width: 400 }}
          >
            {this.users.map((u) => (
              <Select.Option value={u.id}>{u.name}</Select.Option>
            ))}
          </Select>
        )} */}
      </Modal>
    );
  }
}

ObjectiveModal.propTypes = {
  objective: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  objectiveStore: PropTypes.object.isRequired
};

export default ObjectiveModal;
