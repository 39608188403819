/* eslint-disable no-debugger */

/* eslint-disable no-unused-expressions */

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Select, Spin, Avatar, Empty } from "antd";
import { Objective, User } from "services/api";
import styled from "styled-components";
import nameToInitials from "utils/nameToInitials";
import PropTypes from "prop-types";
import normalize from "json-api-normalizer";
import build from "redux-object";

const UserItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  background: rgb(240, 242, 245);
  color: rgb(144, 148, 156);
  font-size: 14px;
  border-style: solid;
  border-width: 0px 0px 0.25px 0px
  padding-left: 6px;
  border-color: #e8e8e8;
`;

const ObjectiveSearch = ({
  navigate,
  placeholder,
  width,
  setUser,
  size,
  include,
  currentUser
}) => {
  // const [selected, setSelected] = useState(null);
  const [fetching, setFetching] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [objectives, setObjectives] = useState([]);
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState("");
  const [timeoutId, setTimeoutId] = useState("");
  const searchObjectives = include.includes("objectives");
  const searchUsers = include.includes("users");

  useEffect(() => {
    const fetchData = async () => {
      if (query.length > 0) {
        if (!fetching) {
          setFetching(true);
        }
        setObjectives([]);
        clearTimeout(timeoutId);
        setTimeoutId(
          setTimeout(() => {
            const arr = [];
            if (searchObjectives) {
              arr.push(
                Objective.all(
                  `search=${query}&status[]=IN_PROGRESS&status[]=PENDING&status[]=COMPLETED`
                )
              );
            }
            if (searchUsers) {
              arr.push(User.all(query));
            }
            Promise.all(arr).then((resArray) => {
              if (arr.length === 2) {
                const [res1, res2] = resArray;
                setObjectives(build(normalize(res1.data), "objective"));
                setUsers(res2.data.filter((i) => i.name !== currentUser.name));
              } else {
                const [res] = resArray;
                searchObjectives
                  ? setObjectives(build(normalize(res.data), "objective"))
                  : setUsers(
                      res.data.filter((i) => i.name !== currentUser.name)
                    );
              }
              setFetching(false);
            });
          }, 250)
        );
      } else {
        clearTimeout(timeoutId);
        setObjectives([]);
        setUsers([]);
        setFetching(false);
      }
    };

    fetchData();
  }, [query]);

  const { Option, OptGroup } = Select;
  let notFoundContent;
  if (query.length > 0 && fetching) {
    notFoundContent = <Spin size="small" />;
  } else if (query.length > 0) {
    notFoundContent = "Not Found";
  } else {
    notFoundContent = null;
  }

  const handleMenu = (menu) => {
    if (query.length === 0) {
      return <div />;
    }
    if (query.length > 0 && fetching) {
      return (
        <div
          style={{
            width: "100%",
            height: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Spin size="large" />
        </div>
      );
    }
    if (query.length > 0 && !objectives && !users && !fetching) {
      return <Empty />;
    }
    return <div style={{ maxHeight: "465px" }}>{menu}</div>;
  };

  const options = users.slice(0, 5).map((u) => (
    <Option
      value={u.id}
      onClick={() => {
        setQuery("");
        setUser(u);
      }}
    >
      <UserItem>
        {" "}
        <Avatar style={{ marginRight: 12 }} src={u.avatar}>
          {nameToInitials(u.name)}
        </Avatar>
        {u.name}
      </UserItem>
    </Option>
  ));
  const objectiveOptions = objectives
    ? objectives.slice(0, 5).map((o) => (
        <Option
          onClick={() => {
            setQuery("");
            navigate(o);
          }}
          value={o.id}
        >
          {o.name}
        </Option>
      ))
    : [];

  let content;
  if (searchObjectives && searchUsers) {
    content = [
      <OptGroup label={<Label>Objectives</Label>}>{objectiveOptions}</OptGroup>,
      <OptGroup label={<Label>Users</Label>}>{options}</OptGroup>
    ];
  } else if (searchObjectives) {
    content = objectiveOptions;
  } else if (searchUsers) {
    content = options;
  }
  return (
    <Select
      data-cy="search-field"
      showSearch
      placeholder={placeholder}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      size={size}
      onSearch={(val) => {
        setQuery(val);
      }}
      dropdownMenuStyle={{ maxHeight: "unset" }}
      loading={fetching}
      value={[]}
      dropdownRender={handleMenu}
      dropdownClassName="objective-search"
      className="user-search"
      notFoundContent={notFoundContent}
      style={{
        marginBottom: 10,
        marginRight: window.innerWidth > 500 && 24,
        width,
        padding: 0
      }}
    >
      {content}
    </Select>
  );
};

ObjectiveSearch.defaultProps = {
  placeholder: "Search by user or objective",
  include: ["objectives", "users"],
  setUser: () => null,
  size: "large"
};

ObjectiveSearch.propTypes = {
  navigate: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  width: PropTypes.string.isRequired,
  include: PropTypes.array,
  setUser: PropTypes.func,
  size: PropTypes.string,
  currentUser: PropTypes.object.isRequired
};
export default ObjectiveSearch;
