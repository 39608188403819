/* eslint-disable */
import * as Types from '../../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type ICreateRelationshipMutationVariables = Types.Exact<{
  input: Types.ICreateRelationshipInput;
}>;


export type ICreateRelationshipMutation = (
  { __typename?: 'Mutation' }
  & { createRelationship?: Types.Maybe<(
    { __typename?: 'Relationship' }
    & Pick<Types.IRelationship, 'id'>
  )> }
);


export const CreateRelationshipDocument = gql`
    mutation CreateRelationship($input: CreateRelationshipInput!) {
  createRelationship(input: $input) {
    id
  }
}
    `;
export type ICreateRelationshipMutationFn = ApolloReactCommon.MutationFunction<ICreateRelationshipMutation, ICreateRelationshipMutationVariables>;

/**
 * __useCreateRelationshipMutation__
 *
 * To run a mutation, you first call `useCreateRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRelationshipMutation, { data, loading, error }] = useCreateRelationshipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRelationshipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICreateRelationshipMutation, ICreateRelationshipMutationVariables>) {
        return ApolloReactHooks.useMutation<ICreateRelationshipMutation, ICreateRelationshipMutationVariables>(CreateRelationshipDocument, baseOptions);
      }
export type CreateRelationshipMutationHookResult = ReturnType<typeof useCreateRelationshipMutation>;
export type CreateRelationshipMutationResult = ApolloReactCommon.MutationResult<ICreateRelationshipMutation>;
export type CreateRelationshipMutationOptions = ApolloReactCommon.BaseMutationOptions<ICreateRelationshipMutation, ICreateRelationshipMutationVariables>;