import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}
const blackOrWhite = (param) => {
  if (param) {
    const { r, g, b } = param;
    if (r * 0.299 + g * 0.587 + b * 0.114 > 186) {
      return "#000000";
    }
    return "#ffffff";
  }
  return "#000000";
};

const Label = ({ label, style }) => {
  const { color, text } = label;
  return (
    <Tooltip title={`Label: ${text}`}>
      <StyledLabel style={style} color={color}>
        {text}
      </StyledLabel>
    </Tooltip>
  );
};

Label.defaultProps = {
  style: {}
};

Label.propTypes = {
  label: PropTypes.object.isRequired,
  style: PropTypes.object
};
const StyledLabel = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0px 6px;
  width: fit-content;
  color: ${(props) => blackOrWhite(hexToRgb(props.color))};
`;
export default Label;
