import React from "react";
import PropTypes from "prop-types";
import { Carousel, Button, Icon } from "antd";
import { observable } from "mobx";
import { observer } from "mobx-react";
import NoteItem from "./NoteItem";
import HighfiveItem from "./HighfiveItem";

const ITEMS = {
  note: (n) => (
    <NoteItem
      creator={n.creator}
      content={n.content}
      createdAt={n.created_at}
    />
  ),
  highfive: (h) => (
    <HighfiveItem
      creator={h.creator}
      content={h.content}
      createdAt={h.created_at}
      likes={h.likes}
    />
  )
};

@observer
class ItemCarousel extends React.Component {
  @observable items = [];
  @observable slide = 1;
  constructor(props) {
    super(props);
    this.items = props.items;
  }
  handleNext = () => {
    this.slider.next();
    // this.slide = (this.slide % this.notes.length) + 1;
  };
  handlePrev = () => {
    this.slider.prev();
  };

  componentWillReceiveProps(nextProps) {
    const items = { nextProps };
    if (items.length !== this.items.length) {
      this.slide = 1;
      this.slider.goTo(0);
    }
  }

  render() {
    const { type } = this.props;
    return (
      <div>
        <Carousel
          dots={false}
          ref={(node) => (this.slider = node)}
          afterChange={(current) => (this.slide = current + 1)}
        >
          {this.items.length > 0 ? (
            this.items.map((i) => ITEMS[type](i))
          ) : (
            <span>
              No notes yet. You can use the text area below to add notes for
              this employee.
            </span>
          )}
        </Carousel>

        {this.items.length > 0 && (
          <div style={{ float: "right", marginBottom: 15 }}>
            <Button
              style={{ marginTop: 15, marginRight: 8 }}
              onClick={this.handlePrev}
            >
              <Icon type="arrow-left" />
            </Button>
            <span
              style={{ marginRight: 8 }}
            >{`${this.slide}/${this.items.length}`}</span>
            <Button style={{ marginTop: 15 }} onClick={this.handleNext}>
              <Icon type="arrow-right" />
            </Button>
          </div>
        )}
      </div>
    );
  }
}

ItemCarousel.propTypes = {
  items: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired
};

export default ItemCarousel;
