import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  width: 100%;
  height: 50%;
  margin: auto 0;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  border-left: 1px solid black;
  border-top: 1px solid black;
`;

export const Legend = styled.div`
  right: 0;
  bottom: -48px;
  position: absolute;
  align-items: center;
  display: flex;
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  font-size: 12px;
  transition: 0.2s;
  background-color: ${props => props.selected ? '#BAEFC1' : 'white'};
  &:hover {
    background-color: ${props => props.selected ? '#BAEFC1' : '#e8e8e8'};
  }
`;

export const LegendKey = styled.div`
  height: 15px;
  width: 15px;
  background: #cdffd8;
  border-radius: 4px;
  margin-right: 6px;
  border: 1px solid black;
`;

export const BoxWrapper = styled.div`
  width: 33.3%;
  height: 33.3%;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  position: relative;
  background-color: ${props => props.selected && '#CDFFD8'};
  cursor: pointer;
`;

export const Mask = styled.div`
  cursor: pointer;
  pointer-events: none;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  mix-blend-mode: hard-light;
  opacity: ${props => props.visible ? '1' : '0'};
  transition: 0.25s;
`;

export const Label = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
`;

export const AxisLabel = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  transform: ${props => props.axis === 'y' && 'rotate(-90deg)'};
  left: ${props => props.axis === 'y' ? '-48px' : '50%'};
  bottom: ${props => props.axis === 'y' ? '50%' : '-24px'};
`;

export const AvatarWrapper = styled.div`
  position: absolute;
  left: calc(50% - 16px);
  top: calc(50%);
  display: flex;
  justify-content: center;
  align-items: center;
`;