import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Icon } from "antd";
import { motion, AnimatePresence } from "framer-motion";
import useStores from "utils/useStores";

// let y;
// if (window.innerWidth < 321) {
//   y = 0;
// } else if (window.innerWidth < 500) {
//   y = 25;
// } else {
//   y = 64;
// }

// const TutorialModal = posed.div({
//   enter: {
//     y: 0,
//     opacity: 1,
//     delay: 300,
//     transition: {
//       y: { type: 'spring', stiffness: 1000, damping: 25 },
//       default: { duration: 300 }
//     }
//   },
//   exit: {
//     y: 100,
//     opacity: 0
//   }
// });

const shadeStyles = {
  position: "fixed",
  background: "rgba(0, 0, 0, 0.8)",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 999
};

const StyledModalBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  position: relative;
  padding-top: 36px;
  position: relative;
`;

const MotionModal = ({ visible, onClose, children, top, width }) => {
  const modalStyles = {
    position: "fixed",
    width,
    background: "white",
    borderRadius: 10,
    zIndex: 1000,
    left: `calc(50vw - (${width}px * 0.5))`,
    top
  };

  const { profileStore } = useStores();
  const timerToClear = useRef(false);
  useEffect(() => {
    const header = document.getElementsByClassName("ant-layout-header")[0];
    const sider = document.getElementsByClassName("ant-layout-sider")[0];
    if (visible) {
      clearTimeout(timerToClear.current);
      if (header) {
        header.style.zIndex = 0;
      }
      if (window.innerWidth > 500 && !profileStore.isExternalUser && sider) {
        sider.style.zIndex = 0;
      }
    } else {
      timerToClear.current = setTimeout(() => {
        if (header && sider) {
          header.style.zIndex = 100;
        }
        if (window.innerWidth > 500 && !profileStore.isExternalUser && sider) {
          sider.style.zIndex = 100;
        }
      }, 1000);
    }
  }, [visible]);
  return (
    <AnimatePresence initial={false}>
      {visible && [
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="shade"
          id="modal-shade"
          style={shadeStyles}
          onClick={onClose}
        />, // If animating more than one child, each needs a `key`
        // <StyledShade onClick={onClose} key="shade" className="shade" />,
        <motion.div
          initial={{ opacity: 0, y: -400 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: "100vh" }}
          key="modal"
          className="modal"
          style={modalStyles}
        >
          <StyledModalBody>
            <Icon
              type="close"
              style={{ position: "absolute", top: 12, right: 12, fontSize: 18 }}
              onClick={onClose}
            />
            {children}
          </StyledModalBody>
        </motion.div>
      ]}
    </AnimatePresence>
  );
};

MotionModal.defaultProps = {
  children: [],
  top: 200,
  width: window.innerWidth < 500 ? "98vw" : 500
};

MotionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  top: PropTypes.number,
  width: PropTypes.oneOf([PropTypes.string, PropTypes.number])
};

export default MotionModal;
