import styled from "styled-components";

export const BehaviorContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 45px;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;
  align-items: center;
  cursor: ${({
  score
}) => score ? 'pointer' : 'not-allowed'};
`;

export const BehaviorDotContainer = styled.div`
  background-color: ${props => props.color};
  height: 25px;
  width: 25px;
  border-radius: 50%;
  transform: ${props => props.selected ? 'scale(1.5)' : 'scale(1)'};
  transition: transform 0.25s;
  text-align: center;
  vertical-align: middle;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
`;

export const BehaviorDot = styled.span`
  height: 25px;
  width: 25px;
  color: white;
  vertical-align: middle;
`;

export const BehaviorDesc = styled.span`
  width: 90%;
  font-weight: ${props => props.selected ? 'bold' : 'normal'};
  font-size: ${({
  theme
}) => `${theme.font.body}px`};
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;