import React from "react";
import PropTypes from "prop-types";
import { Icon, Avatar, Dropdown, Modal, Button, Badge, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import { observable, computed } from "mobx";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import nameToInitials from "utils/nameToInitials";
import AccountSettings from "components/AccountSettings";
import EditFeedback from "components/EditFeedback";
import UserForm from "components/UserForm/Lite";
import { Profile, SuperAdmin, Goals, User, Review } from "services/api";

import ObjectiveModal from "components/ObjectiveModal";
import RateObjective from "components/RateObjective";
import viewModal from "components/FullScreenModal";
import DailyLogin from "components/DailyLogin";
import Carrot from "assets/carrot.png";
import ReciprocitySetup from "./ReciprocitySetup";
import FeedbackForm from "./FeedbackForm";
import SupportForm from "./SupportForm";
import { menu } from "./Menu";

import UserSearch from "../../../components/ObjectiveSearch";
import profileStore from "stores/profileStore";
import UpdatePane from "containers/UpdatePane";
import { withTranslation } from "react-i18next";
import VacationModal from "containers/VacationModal";
import tutorialStore from "stores/tutorialStore";
import ArticulateLessonModal from "components/ArticulateLessonModal";

const api = new SuperAdmin();

const ProposalModalBody = styled.div``;

const Status = styled.span`
  font-weight: bolder;
  text-transform: uppercase;
`;
const ChangeIcon = styled(Icon)`
  color: ${(props) => (props.add ? "green" : "red")};
  margin-right: 5px;
`;

const Proposal = ({ proposal }) => (
  <div>
    <ChangeIcon
      type={proposal.change_type === "add" ? "plus" : "minus"}
      add={proposal.change_type === "add"}
    />
    {proposal.rater && (
      <span>
        {proposal.rater}
        {proposal.change_type === "add" ? " to " : " from "}
      </span>
    )}
    <span>{proposal.habit}</span>
  </div>
);

Proposal.propTypes = {
  proposal: PropTypes.shape({
    change_type: PropTypes.string.isRequired,
    rater: PropTypes.string.isRequired,
    habit: PropTypes.string.isRequired
  }).isRequired
};

// const clickOutsideConfig = {
//   handleClickOutside(instance) {
//     return instance.myClickOutsideHandler;
//   }
// };
// const FixedMenu = onClickOutside(CustomMenu, clickOutsideConfig);

@inject("profileStore", "proposalStore", "objectiveStore", "tutorialStore")
@observer
class HeaderActions extends React.Component {
  @observable settingsOpen = false;
  @observable supportOpen = false;
  @observable feedbackOpen = false;
  @observable editFeedbackOpen = false;
  @observable editInfoOpen = false;
  @observable popOpen = false;
  @observable goalToAssign = null;
  @observable manageProposalsOpen = false;
  @observable approvalModalOpen = false;
  @observable reviewingId = null;
  @observable reciprocityHabitUsers = [];
  @observable reciprocityOpen = false;
  @observable notificationId = null;
  @observable type = null;
  @observable searchExpanded = false;
  @observable vacationModalOpen = false;
  @observable articulateModalOpen = false;

  static propTypes = {
    history: PropTypes.object.isRequired,
    objectiveStore: PropTypes.object.isRequired,
    noCallToAction: PropTypes.bool.isRequired,
    proposalStore: PropTypes.shape({
      openProposals: PropTypes.func.isRequired
    }).isRequired,
    profileStore: PropTypes.shape({
      logout: PropTypes.func.isRequired,
      removeRedirect: PropTypes.func.isRequired,
      updateAvatar: PropTypes.func.isRequired,
      linkedInCode: PropTypes.string,
      unreadNotificationCount: PropTypes.number.isRequired,
      hasMoreNotifications: PropTypes.bool.isRequired,
      markNotificationsSeen: PropTypes.func.isRequired,
      isLoadingNotifications: PropTypes.bool.isRequired,
      loadMoreNotifications: PropTypes.func.isRequired,
      redirectToLinkedIn: PropTypes.bool.isRequired,
      isOnboarding: PropTypes.bool.isRequired,
      updateUser: PropTypes.func.isRequired,
      notifications: PropTypes.array.isRequired,
      updateNotification: PropTypes.func.isRequired,
      currentUser: PropTypes.shape({
        linkedInRedirectUri: PropTypes.string,
        phone_number: PropTypes.string,
        mail_time: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        organization: PropTypes.shape({
          features: PropTypes.array.isRequired
        }).isRequired,
        preferences: PropTypes.shape({
          feedback_delivery: PropTypes.string.isRequired
        }).isRequired,
        questions: PropTypes.array.isRequired,
        proposals: PropTypes.array.isRequired,
        managed_team_id: PropTypes.isRequired,
        avatar: PropTypes.string.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        feedback_streak: PropTypes.number.isRequired
      }).isRequired,
      features: PropTypes.func,
      isSuperAdmin: PropTypes.bool.isRequired
    }).isRequired
  };

  constructor(props) {
    super(props);

    if (props.profileStore.redirectToLinkedIn) {
      this.settingsOpen = true;
    }
    // if (props.profileStore.isOnboarding) {
    //   this.settingsOpen = true;
    //   props.profileStore.isOnboarding = false;
    // }
  }

  submitGoals = (params) => {
    Goals.reviewDeliverables(params).then(() => {
      this.reviewingId = null;
    });
  };

  submitObjective = (params) => {
    Review.create(params).then(() => {
      this.reviewingId = null;
    });
  };
  closeSettings = () => {
    const { profileStore } = this.props;
    profileStore.isOnboarding = false;
    this.settingsOpen = false;
  };
  openSettings = () => (this.settingsOpen = true);
  openFeedback = () => (this.feedbackOpen = true);
  openArticulateModal = () => (this.articulateModalOpen = true);
  openSupport = () => (this.supportOpen = true);
  openReciprocity = (actor, notificationId) => {
    const { profileStore } = this.props;

    User.habits(profileStore.currentUser.id).then((res) => {
      this.reciprocityHabitUsers = res.data.habits;
      this.reciprocityOpen = actor;
      this.notificationId = notificationId;
    });
  };
  saveFeedbackRef = (formRef) => {
    this.feedbackRef = formRef;
  };
  saveSupportRef = (formRef) => {
    this.supportRef = formRef;
  };

  editRecentFeedback = () => (this.editFeedbackOpen = true);
  editPersonalInfo = () => (this.editInfoOpen = true);

  handleEditProfile = (values) => {
    const {
      profileStore: { currentUser, updateInfo }
    } = this.props;
    if (Object.entries(values).some((e) => currentUser[e[0]] !== e[1])) {
      Profile.update(values).then(({ data }) => {
        updateInfo(data);
      });
    }
    this.editInfoOpen = false;
  };

  openVacationModal = () => (this.vacationModalOpen = true);

  createReciprocityProposals = (habitIds) => {
    const req = {};

    Object.keys(habitIds).forEach((id) => {
      if (this.habits.find((h) => h.id === id).raters.includes(habitIds[id])) {
        req[id] = {
          raters: { remove: [{ id: this.reciprocityOpen.id }] },
          trigger_reciprocity: false
        };
      } else {
        req[id] = {
          raters: { add: [{ id: this.reciprocityOpen.id }] },
          trigger_reciprocity: false
        };
      }
    });
    Profile.createProposals(req, this.notificationId).then(() => {
      this.reciprocityOpen = false;
    });
  };

  @computed get habits() {
    const { profileStore } = this.props;
    if (this.reciprocityHabitUsers.length) {
      return profileStore.currentUser.habits
        .filter((f) => f.active)
        .map((habit) =>
          Object.assign({}, habit, {
            raters: this.reciprocityHabitUsers.find((h) => h.id === habit.id)
              .raters
          })
        );
    }
    return profileStore.currentUser.habits;
  }

  sendForm = (type, values) => {
    // const form = this.type === "feedback" ? this.feedbackRef.props.form : this.supportRef.props.form

    if (type === "feedback") {
      Profile.sendFeedback(values);
      this.feedbackOpen = false;
      Modal.success({
        title: "Feedback Received",
        content:
          "Thanks for your feedback!  We take the time to read and address all feedback we receive."
      });
    } else {
      Profile.requestSupport(values);
      this.supportOpen = false;
      Modal.success({
        title: "Support Request Received",
        content:
          "Your support request has been received.  Our team will look in to your request and follow up if necessary."
      });
    }
  };

  openGoal = (goal) => {
    this.goalToAssign = goal;
  };

  openObjective = (notification) => {
    const { objectiveStore } = this.props;
    objectiveStore.openAcceptModal(notification.target_id);
  };

  handleCancel = (addressed = false) => {
    const { profileStore } = this.props;
    const otherGoals = profileStore.notifications.filter(
      (n) =>
        n.type === "Goal" &&
        n.action &&
        n.target_attributes.status === "pending" &&
        n.target_attributes.id !== this.goalToAssign.target_attributes.id
    );
    if (otherGoals.length > 0 && addressed) {
      [this.goalToAssign] = otherGoals;
    } else {
      this.goalToAssign = null;
    }
  };

  reviewGoal = (id, nid, type) => {
    this.type = type;
    this.reviewingId = id;
    this.editingNotificationId = nid;
  };

  openUpdates = () => {
    const { profileStore } = this.props;
    profileStore.updatesOpen = true;
  };

  openUpdatedProposals = () => {
    const {
      profileStore: {
        currentUser: { proposals }
      }
    } = this.props;
    const approved = proposals
      .filter((p) => p.status === "approved")
      .sort((a, b) => (a.habit < b.habit && a.rater < b.rater ? -1 : 1));
    const rejected = proposals
      .filter((p) => p.status === "rejected")
      .sort((a, b) => (a.habit < b.habit && a.rater < b.rater ? -1 : 1));
    Profile.markProposalsAsSeen();
    Modal.info({
      title: "Your Proposed Network Changes",
      content: (
        <ProposalModalBody>
          {!!approved.length && <Status>Approved</Status>}
          {approved.map((proposal) => (
            <Proposal proposal={proposal} />
          ))}
          {!!rejected.length && <Status>Rejected</Status>}
          {rejected.map((proposal) => (
            <Proposal proposal={proposal} />
          ))}
        </ProposalModalBody>
      ),
      onOk() {}
    });
  };

  render() {
    let { settingsOpen } = this;
    const {
      profileStore: { currentUser, features },
      profileStore,
      noCallToAction,
      history,
      objectiveStore,
      tutorialStore,
      t
    } = this.props;
    const adminWithNewUi =
      features().includes("NEXT_UI") &&
      (profileStore.currentUser.admin ||
        profileStore.currentUser.can_configure);
    // if (
    //   profileStore.settingsOpen ||
    //   tutorialStore.runningTutorial === "how_to_setup_account" ||
    //   (!profileStore.isSuperAdmin &&
    //     currentUser.preferences.onboarding_tutorial_viewed &&
    //     profileStore.isOnboarding &&
    //     profileStore.networkSetupComplete)
    // ) {
    //   settingsOpen = true;
    // }
    const isMobile = window.innerWidth < 500;
    return (
      <div
        style={{
          float: "right",
          height: "100%",
          display: "flex",
          alignItems: "center"
        }}
      >
        <ArticulateLessonModal
          visible={this.articulateModalOpen}
          onCancel={() => (this.articulateModalOpen = false)}
        />
        <UpdatePane
          open={profileStore.updatesOpen}
          onClose={() => (profileStore.updatesOpen = false)}
        />
        {isMobile ? (
          <div style={{ marginRight: 12 }}>
            <Icon type="search" onClick={() => (this.searchExpanded = true)} />
            {this.searchExpanded && (
              <Icon
                onClick={() => (this.searchExpanded = false)}
                theme="filled"
                size="large"
                type="close-circle"
                style={{
                  position: "absolute",
                  fontSize: 24,
                  color: "#E8E8E8",
                  zIndex: 25,
                  top: "calc(50% - 12px)",
                  left: "calc(50% - 72px)"
                }}
              />
            )}
            <div
              style={{
                position: "absolute",
                top: 0,
                zIndex: 24,
                overflow: "hidden",
                width: this.searchExpanded ? 200 : 0,
                transition: "0.2s",
                transitionTimingFunction: "ease-in-out"
              }}
            >
              {!features().includes("NO_USER_SEARCH") && (
                <UserSearch
                  size="large"
                  currentUser={currentUser}
                  placeholder="Search Users"
                  width={200}
                  showArrow={false}
                  include={["users"]}
                  mode="default"
                  setUser={(user) =>
                    viewModal({ id: user.id, type: "user" }, history)
                  }
                />
              )}
            </div>
          </div>
        ) : (
          <div style={{ marginRight: 12 }}>
            {!features().includes("NO_USER_SEARCH") && (
              <UserSearch
                currentUser={currentUser}
                size="default"
                placeholder="Search Users"
                width={200}
                showArrow={false}
                include={["users"]}
                mode="default"
                setUser={(user) =>
                  viewModal({ id: user.id, type: "user" }, history)
                }
              />
            )}
          </div>
        )}
        <a
          style={{ marginRight: 12 }}
          href="https://help.rhabitapp.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("help")}
        </a>
        {!!objectiveStore.objectiveToAccept && (
          <ObjectiveModal
            managedTeamId={currentUser.managed_team_id}
            objective={objectiveStore.objectiveToAccept}
            onCancel={() => (objectiveStore.acceptingObjectiveId = null)}
          />
        )}

        <DailyLogin />

        {Boolean(
          !profileStore.isSuperAdmin &&
            !adminWithNewUi &&
            currentUser.questions.filter((q) => !q.status).length
        ) && !noCallToAction ? (
          <Badge count={currentUser.questions.filter((q) => !q.status).length}>
            {/* <NavLink to="/rate"> */}
            {isMobile ? (
              <Button onClick={() => history.push("/feedback")} icon="fire" />
            ) : (
              <Button onMouseUp={() => history.push("/feedback")}>
                {t("header.give_feedback")}
              </Button>
            )}
            {/* </NavLink> */}
          </Badge>
        ) : (
          <React.Fragment>
            {window.location.pathname === "/feedback" || (
              <Tooltip
                title={`${currentUser.current_quarter_carrots} ${t(
                  "header.carrots_earned"
                )}`}
              >
                <span data-cy="feedback-streak">
                  <img
                    alt="carrot"
                    style={{ width: 15, height: "auto", marginRight: 3 }}
                    src={Carrot}
                  />
                  {currentUser.current_quarter_carrots}
                </span>
              </Tooltip>
            )}
          </React.Fragment>
        )}
        <Modal
          visible={!!this.reviewingId}
          onCancel={() => (this.reviewingId = null)}
          destroyOnClose
          width={isMobile ? "98vw" : "620px"}
          footer={false}
        >
          {this.reviewingId && this.type === "Objective" && (
            <RateObjective
              withDate
              onSubmit={this.submitObjective}
              objectiveId={this.reviewingId}
            />
          )}
        </Modal>

        {/* <Icon style={{
        height: "100%",
        lineHeight: "unset",
        display: "inline-block",
        padding: "0 12px"
      }} type="search" /> */}
        {/* <Dropdown overlay={infoMenu(this.openFeedback, this.openSupport)}>
          <Icon
            style={{
              cursor: 'pointer',
              height: '100%',
              lineHeight: 'unset',
              display: 'inline-block',
              padding: '0 12px'
            }}
            type="info-circle"
          />
        </Dropdown> */}

        {/* <Icon style={{
        height: "100%",
        lineHeight: "unset",
        display: "inline-block",
        padding: "0 12px"
      }} type="bell" /> */}
        <VacationModal
          visible={this.vacationModalOpen}
          onCancel={() => (this.vacationModalOpen = false)}
        />
        <FeedbackForm
          visible={this.feedbackOpen}
          onCancel={() => (this.feedbackOpen = false)}
          onSend={(values) => this.sendForm("feedback", values)}
        />
        <SupportForm
          visible={this.supportOpen}
          onCancel={() => (this.supportOpen = false)}
          onSend={(values) => this.sendForm("support", values)}
        />
        <EditFeedback
          visible={this.editFeedbackOpen}
          onCancel={() => (this.editFeedbackOpen = false)}
        />
        <UserForm
          organization={{}}
          open={this.editInfoOpen}
          features={features()}
          onCancel={() => (this.editInfoOpen = false)}
          editingUser={Object.assign({}, currentUser, {
            name: `${currentUser.first_name} ${currentUser.last_name}`
          })}
          onOk={this.handleEditProfile}
        />
        {!profileStore.isSuperAdmin && (
          <ReciprocitySetup
            provider={this.reciprocityOpen}
            habits={this.habits}
            createProposals={this.createReciprocityProposals}
            close={() => (this.reciprocityOpen = false)}
          />
        )}
        {!isMobile &&
          features().includes("ADMIN_TRAINING") &&
          currentUser.admin && (
            <Badge count={"!"}>
              <Icon
                type="book"
                style={{
                  marginLeft: 24,
                  marginRight: 6,
                  fontSize: 20,
                  cursor: "pointer"
                }}
                onClick={this.openArticulateModal}
              />
            </Badge>
          )}
        {!isMobile && profileStore.hasNewUpdate ? (
          <Badge count={"!"}>
            <Icon
              type="notification"
              style={{
                marginLeft: 24,
                marginRight: 6,
                fontSize: 20,
                cursor: "pointer"
              }}
              onClick={this.openUpdates}
            />
          </Badge>
        ) : !isMobile ? (
          <Icon
            type="notification"
            style={{
              marginLeft: isMobile ? 12 : 24,
              marginRight: 6,
              fontSize: isMobile ? 16 : 20,
              cursor: "pointer"
            }}
            onClick={this.openUpdates}
          />
        ) : (
          <div />
        )}

        {}
        {settingsOpen && (
          <AccountSettings
            logout={profileStore.logout}
            consent={currentUser.consent}
            imageUrl={currentUser.avatar}
            mailTime={currentUser.mail_time}
            feedbackDelivery={currentUser.preferences.feedback_delivery}
            phoneNumber={currentUser.phone_number}
            currentUserId={currentUser.id}
            editingUser={Object.assign({}, currentUser, {
              name: `${currentUser.first_name} ${currentUser.last_name}`
            })}
            linkedInCode={profileStore.linkedInCode}
            linkedInRedirectUri={currentUser.linked_in_redirect_uri}
            updateUser={profileStore.updateUser}
            updateAvatar={profileStore.updateAvatar}
            removeRedirect={profileStore.removeRedirect}
            visible={this.settingsOpen || settingsOpen}
            onCancel={() => {
              this.closeSettings();
              profileStore.settingsOpen = false;
              tutorialStore.endCurrentTutorial();
            }}
            features={features()}
            userName={`${currentUser.first_name} ${currentUser.last_name}`}
          />
        )}
        {isMobile ? (
          <Dropdown
            overlay={menu(
              api,
              profileStore.logout,
              this.openSettings,
              this.editRecentFeedback,
              this.editPersonalInfo,
              profileStore.isSuperAdmin
                ? []
                : currentUser.organization.features,
              () => tutorialStore.runTutorial("product_tour"),
              this.openFeedback,
              t,
              this.openVacationModal
            )}
          >
            <span
              onBlur={() => (this.popOpen = false)}
              style={{
                cursor: "pointer",
                height: "100%",
                lineHeight: "unset",
                display: "inline-block"
              }}
            >
              <Avatar
                style={{
                  marginRight: 8,
                  marginLeft: 20
                }}
                src={profileStore.currentUser.avatar}
              >
                {nameToInitials(
                  `${profileStore.currentUser.first_name} ${profileStore.currentUser.last_name}`
                )}
              </Avatar>
            </span>
          </Dropdown>
        ) : (
          <Dropdown
            overlay={menu(
              api,
              profileStore.logout,
              this.openSettings,
              this.editRecentFeedback,
              this.editPersonalInfo,
              profileStore.isSuperAdmin
                ? []
                : currentUser.organization.features,
              () => tutorialStore.runTutorial("product_tour"),
              this.openFeedback,
              t,
              this.openVacationModal
            )}
          >
            <span
              style={{
                cursor: "pointer",
                height: "100%",
                lineHeight: "unset",
                display: "inline-block"
              }}
            >
              <Avatar
                style={{
                  marginRight: 8,
                  marginLeft: 20
                }}
                src={profileStore.currentUser.avatar}
              >
                {nameToInitials(
                  `${profileStore.currentUser.first_name} ${profileStore.currentUser.last_name}`
                )}
              </Avatar>
              <span id="current-user-name">
                {`${profileStore.currentUser.first_name} ${profileStore.currentUser.last_name}`}{" "}
                {profileStore.impersonating && <span>(impersonating)</span>}
              </span>
            </span>
          </Dropdown>
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(HeaderActions));
