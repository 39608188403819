/* eslint-disable no-unused-vars */
import React from "react";
import { DatePicker, Input, Form, Checkbox, Select } from "antd";

const { RangePicker } = DatePicker;
const { Item } = Form;
const Attributes = ({
  dateRange,
  label,
  includeHabits,
  includeObjectives,
  setValue,
  scheduleDaysBefore,
  asAdmin
}) => {
  return (
    <>
      <Item label="Label" style={{ marginBottom: 12 }}>
        <Input
          value={label}
          onChange={(e) => setValue("label", e.target.value)}
        />
      </Item>
      <Item label="Date Range" style={{ marginTop: 0 }}>
        <RangePicker
          value={dateRange}
          onChange={(newDateRange) => {
            setValue("dateRange", newDateRange);
          }}
        />
      </Item>
      {asAdmin && (
        <Item label="Become Available to Managers" style={{ marginTop: 0 }}>
          <Select
            style={{ width: "70%" }}
            value={scheduleDaysBefore}
            onChange={(val) => setValue("scheduleDaysBefore", val)}
          >
            <Select.Option value={0}>On End Date</Select.Option>
            <Select.Option value={7}>One Week Before End Date</Select.Option>
            <Select.Option value={14}>Two Weeks Before End Date</Select.Option>
          </Select>
        </Item>
      )}
      <Checkbox
        checked={includeHabits}
        onChange={(e) => setValue("includeHabits", e.target.checked)}
      >
        Include Habit Data
      </Checkbox>
      <Checkbox
        checked={includeObjectives}
        onChange={(e) => setValue("includeObjectives", e.target.checked)}
      >
        Include Objective Data
      </Checkbox>
    </>
  );
};

export default Attributes;
