// @flow

import React from "react";
import useStores from "utils/useStores";
import { HomeCard } from "containers/HomePage/styles";
import { BadgeItem } from "containers/FullLeaderboard/badgeList";
import { Empty, Button } from "antd";
import viewModal from "components/FullScreenModal";
import FullLeaderboard from "containers/FullLeaderboard";

const BadgeCase = () => {
  const {
    profileStore: { currentUser }
  } = useStores();

  const openModal = () => {
    viewModal(
      {
        domId: "full-leaderboard",
        skipDestroy: true,
        width: window.innerWidth < 900 ? "100vw" : 1200
      },
      null,
      <FullLeaderboard hideLeaderboard />
    );
  };

  return (
    <HomeCard
      style={{ height: "auto", maxHeight: 350, overflow: "auto" }}
      bodyStyle={{ padding: 6 }}
      title="Achievements"
      footer={<span>Full List</span>}
    >
      <div style={{ maxHeight: 250, overflow: "hidden", height: 250 }}>
        {currentUser.badges.length > 0 ? (
          currentUser.badges.map((b) => (
            <BadgeItem key={b.id} badge={b} userHasAchieved nameOnly />
          ))
        ) : (
          <Empty description="You haven't earned any achievements yet" />
        )}
      </div>
      <div
        style={{
          width: "100%",
          height: 30,
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          paddingBottom: 6
        }}
      >
        <Button onClick={openModal} type="link">
          View Full List
        </Button>
      </div>
    </HomeCard>
  );
};

export default BadgeCase;
