/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow
import React from "react";
import styled from "styled-components";
import { Empty, Modal } from "antd";
import moment from "moment";

import { media } from "utils/style-utils";
import { useTranslation } from "react-i18next";
import { AdditionalFeedbackGroup } from "./index";
import RatersList from "../DeepDiveV2/ActionsAndInfo/NetworkRaters/RaterList";
import ResponseItem from "./ResponseItem";
import useStores from "utils/useStores";

const Container = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  width: 100%;
  padding: 12px;
  margin: 12px 0px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 6;

  ${media.handheld`
    flex-direction: column;
  `}
`;

const LeftSide = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: flex-start;
  ${media.handheld`
    width: 100%;
  `}
`;
const RightSide = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-end;
  ${media.handheld`
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin: 12px 0px;
    justify-content: space-around;
  `}
`;

interface IOwnProps {
  feedbackGroup: AdditionalFeedbackGroup;
  cancelRequest: () => void;
}
const Tile = ({ feedbackGroup, cancelRequest }: IOwnProps) => {
  const { profileStore } = useStores();
  const { t } = useTranslation();
  const measureContainer = () => {
    const img = 32;
    const button = 134;
    const container = document.getElementById("improvement-badge");
    if (container) {
      const containerWidth = container.offsetWidth - 48;
      return Math.floor((containerWidth - button) / img);
    }
    return 10;
  };

  const handleFilterAdditionalFeedback = () => {
    const availableSpace = measureContainer();

    let filtered = feedbackGroup.additional_feedbacks.map(
      (af) => af.feedback_giver
    );

    const remaining = filtered.slice(availableSpace - 1, filtered.length);
    filtered = filtered.slice(0, availableSpace);

    return { filtered, remaining };
  };

  const replaceText = (prompt) =>
    // eslint-disable-next-line no-template-curly-in-string
    prompt.question_text
      .replace(/\${userName}/g, prompt.asker.name)
      .replace(/\${habitName}/g, prompt.habit_name);

  const openDetails = () => {
    Modal.info({
      width: 600,
      title: t("additional_feedback.prompt"),
      content: replaceText(feedbackGroup.prompt)
    });
  };

  const { filtered, remaining } = handleFilterAdditionalFeedback();

  const confirmCancel = () => {
    Modal.confirm({
      content: t("additional_feedback.confirm_cancel"),
      onOk: cancelRequest
    });
  };

  let statusString;

  if (feedbackGroup.status === "canceled") {
    statusString = t("additional_feedback.request_canceled");
  } else if (feedbackGroup.status === "completed") {
    statusString = t("additional_feedback.all_feedback_collected");
  } else if (profileStore.isDashboardViewer) {
    statusString = "";
  } else {
    statusString = (
      <a
        style={{ color: "red", marginBottom: window.innerWidth > 500 && 6 }}
        onClick={confirmCancel}
      >
        {t("additional_feedback.cancel_request")}
      </a>
    );
  }

  return (
    <Container>
      <Header>
        <LeftSide>
          <span style={{ fontSize: 16 }}>
            {t("additional_feedback.additional_feedback_request")}
          </span>
          <div style={{ fontSize: 14, marginBottom: 6 }}>
            {t("additional_feedback.you_requested_feedback_on", {
              dateString: moment(feedbackGroup.created_at).format("MM/DD/YYYY")
            })}
          </div>
          <RatersList filteredRaters={filtered} remainingRaters={remaining} />
        </LeftSide>
        <RightSide>
          {statusString}
          <a onClick={openDetails}>{t("additional_feedback.more_details")}</a>
        </RightSide>
      </Header>
      {feedbackGroup.additional_feedbacks.filter((f) => f.answer).length > 0 ? (
        feedbackGroup.additional_feedbacks
          .filter((f) => f.answer)
          .map((f, index) => (
            <ResponseItem
              text={f.answer}
              isNew={!f.seen}
              user={f.feedback_giver}
              createdAt={f.created_at}
              isLast={
                index ===
                feedbackGroup.additional_feedbacks.filter((fe) => fe.answer)
                  .length -
                  1
              }
            />
          ))
      ) : (
        <Empty description={t("additional_feedback.no_responses_yet")} />
      )}
    </Container>
  );
};

export default Tile;
