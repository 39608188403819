import styled from "styled-components";
import { media } from "utils/style-utils";
import { Tabs } from "antd";

export const Wrapper = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 10px 24px;
`;

export const TabPane = styled(Tabs.TabPane)`
  border: 1px solid #e8e8e8;
  border-top: none;
  border-radius: 0 0 4px 4px;
  padding: 24px;
`;

export const Content = styled.div`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const CircleWrapper = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  ${media.handheld`
    width: 33.3%
  `}
`;

export const Title = styled.span`
  display: flex;
  justify-content: center;
`;