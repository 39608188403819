import React from "react";
import PropTypes from "prop-types";
import { Menu, Icon } from "antd";
import { withRouter, NavLink } from "react-router-dom";
import Logo from "assets/logo.png";
import Carrot from "assets/logo_image.png";
import { withTranslation } from "react-i18next";
import Feature from "components/Feature";
import Mentor from "./icons/mentor";
import useStores from "utils/useStores";

const MenuItemGroup = Menu.ItemGroup;

const locationToMenuKey = {
  "/admin/one_on_ones": "/admin",
  "/admin_dashboard": "/admin_dashboard",
  "/manager_dashboard": "/manager_dashboard",
  "/admin/config": "/admin/config",
  "/": "/",
  "/objectives": "/objectives",
  "/admin/reports": "/admin",
  "/carrot_shop": "/carrot_shop",
  "/admin/carrot_shop": "/admin/carrot_shop",
  "/admin/carrot_shop/orders": "/admin/carrot_shop",
  "/admin/career_planner": "/admin",
  "/admin/network": "/admin/network",
  "/analytics": "/analytics",
  "/profile/one_on_ones": "/profile/one_on_ones",
  "/profile/reports": "/profile/one_on_ones",
  "/manager/one_on_ones": "/manager",
  "/manager/reports": "/manager",
  "/team/config": "/team/config",
  "/team/analytics": "/team/analytics",
  "/mentoring": "/mentoring"
};

const keyFromLocation = (location) => {
  if (location.includes("habits")) {
    return "/";
  }

  if (location.includes("objectives")) {
    return "/objectives";
  }

  return locationToMenuKey[location];
};

const SiderMenu = ({
  collapsed,
  includeTeam,
  teamName,
  includeOrg,
  orgName,
  mobile,
  isSuperAdmin,
  features,
  location,
  closeMenu,
  canConfigure,
  isManager,
  hasTeam,
  orgLogoLarge,
  orgLogoSmall,
  t
}) => {
  const { profileStore } = useStores();

  const adminWithNewUi =
    features.includes("NEXT_UI") &&
    (profileStore.currentUser.admin || profileStore.currentUser.can_configure);

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          height: 64,
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {collapsed && !mobile ? (
          <div>
            <img alt="carrot" width={45} src={orgLogoSmall || Carrot} />
          </div>
        ) : (
          <img
            style={{ marginLeft: "-24px" }}
            alt="logo"
            width={160}
            src={orgLogoLarge || Logo}
          />
        )}
      </div>
      <Menu
        selectedKeys={[keyFromLocation(location.pathname)]}
        mode="inline"
        onClick={window.innerWidth < 500 ? () => closeMenu() : null}
        theme="dark"
        inlineCollapsed={collapsed}
        style={{
          paddingTop: "20px",
          height: "calc(100% - 64px)",
          position: "relative",
          color: "#39473F !important"
        }}
      >
        {!isSuperAdmin && (
          <Menu.Item key="/" style={{ width: "100%", zIndex: 101 }}>
            {adminWithNewUi ? (
              <div
                onClick={() =>
                  window.open(process.env.REACT_APP_NEXT_URL, "_blank")
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: collapsed && "center"
                }}
              >
                <Icon
                  type="user"
                  style={{
                    fontSize: "24px",
                    minWidth: collapsed && "unset",
                    lineHeight: "0px"
                  }}
                />
                <span style={{ fontSize: "16px" }}>
                  {t("sider_menu.my_dashboard")}
                </span>
              </div>
            ) : (
              <NavLink
                to="/"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: collapsed && "center"
                }}
              >
                <Icon
                  type="user"
                  style={{
                    fontSize: "24px",
                    minWidth: collapsed && "unset",
                    lineHeight: "0px"
                  }}
                />
                <span style={{ fontSize: "16px" }}>
                  {t("sider_menu.my_dashboard")}
                </span>
              </NavLink>
            )}
          </Menu.Item>
        )}
        {features.includes("OBJECTIVES") && !adminWithNewUi && (
          <Menu.Item
            key="/objectives"
            style={{
              width: "100%",
              zIndex: 101,
              marginLeft: !collapsed && "-15px"
            }}
          >
            <NavLink
              to="/objectives"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: collapsed && "center",
                paddingLeft: !collapsed && "15px"
              }}
            >
              <Icon
                type="flag"
                theme="filled"
                style={{
                  fontSize: "24px",
                  minWidth: collapsed && "unset",
                  lineHeight: "0px"
                }}
              />
              <span style={{ fontSize: "16px" }}>
                {t("sider_menu.objectives")}
              </span>
            </NavLink>
          </Menu.Item>
        )}
        {features.includes("MENTORING") && !adminWithNewUi && (
          <Menu.Item
            key="/mentoring"
            style={{
              width: "100%",
              zIndex: 101,
              marginLeft: !collapsed && "-15px"
            }}
          >
            <NavLink
              to="/mentoring"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: collapsed && "center",
                paddingLeft: !collapsed && "15px"
              }}
            >
              <Icon
                component={Mentor}
                theme="filled"
                style={{
                  fontSize: "42px",

                  minWidth: collapsed && "unset",
                  lineHeight: "0px"
                }}
              />
              <span style={{ fontSize: "16px" }}>
                {t("sider_menu.mentoring")}
              </span>
            </NavLink>
          </Menu.Item>
        )}
        {features.includes("CARROT_SHOP") && (
          <Menu.Item
            key="/carrot_shop"
            style={{
              width: "100%",
              zIndex: 101,
              marginLeft: !collapsed && "-15px"
            }}
          >
            <NavLink
              to="/carrot_shop"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: collapsed && "center",
                paddingLeft: !collapsed && "15px"
              }}
            >
              <Icon
                type="shop" // theme="filled"
                style={{
                  fontSize: "28px",
                  minWidth: collapsed && "unset",
                  lineHeight: "0px"
                }}
              />
              <span style={{ fontSize: "16px" }}>
                {t("sider_menu.carrot_shop")}
              </span>
            </NavLink>
          </Menu.Item>
        )}
        {!isSuperAdmin && features.includes("ONE_ON_ONES") && !adminWithNewUi && (
          <Menu.Item
            key="/profile/one_on_ones"
            title="One-on-Ones and Reports"
            style={{ width: "100%", zIndex: 101 }}
          >
            <NavLink
              to="/profile/one_on_ones"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: collapsed && "center"
              }}
            >
              <div
                style={{
                  fontSize: 20,
                  marginLeft: !collapsed && -6,
                  marginRight: !collapsed && 6,
                  fontWeight: "bolder"
                }}
              >
                1:1
              </div>
              {!collapsed && (
                <span style={{ fontSize: "16px" }}>
                  {t("sider_menu.one_on_ones_and_reports")}
                </span>
              )}
            </NavLink>
          </Menu.Item>
        )}

        {/* {!isSuperAdmin && !includeOrg && features.includes('MY_REPORTS') && (
         <Menu.Item key="/my-reports" style={{ width: '100%', zIndex: 101 }}>
           <NavLink
             to="/my-reports"
             style={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: collapsed && 'center'
             }}
           >
             <Icon
               type="solution"
               style={{
                 fontSize: '24px',
                 minWidth: collapsed && 'unset',
                 lineHeight: '0px'
               }}
             />
             <span style={{ fontSize: '16px' }}>My Reports</span>
           </NavLink>
         </Menu.Item>
        )} */}
        {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
             <div style={{ height: 1, width: 12, borderBottom: "1px solid rgba(255, 255, 255, 0.65)", marginRight: 4}} />
              </div> */}
        {features.includes("GOALS") && (
          <Menu.Item
            key="/goals"
            style={{
              width: "100%",
              zIndex: 101,
              marginLeft: !collapsed && "-15px"
            }}
          >
            <NavLink
              to="/goals"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: collapsed && "center",
                paddingLeft: !collapsed && "15px"
              }}
            >
              <Icon
                type="flag"
                style={{
                  fontSize: "24px",
                  minWidth: collapsed && "unset",
                  lineHeight: "0px"
                }}
              />
              <span style={{ fontSize: "16px" }}>{t("sider_menu.goals")}</span>
            </NavLink>
          </Menu.Item>
        )}

        {isSuperAdmin && (
          <MenuItemGroup
            style={{ marginTop: collapsed && -24 }}
            title={
              !collapsed && <span style={{ fontSize: "18px" }}>{orgName}</span>
            }
          >
            <Menu.Item key="/superadmin/organizations">
              <NavLink
                to="/superadmin/organizations"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: collapsed && "center",
                  paddingLeft: !collapsed && "15px"
                }}
              >
                <Icon
                  type="deployment-unit"
                  style={{
                    fontSize: "20px",
                    minWidth: collapsed && "unset",
                    lineHeight: "0px"
                  }}
                />
                <span style={{ fontSize: "14px" }}>
                  {t("sider_menu.organizations")}
                </span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="/superadmin/tools">
              <NavLink
                to="/superadmin/tools"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: collapsed && "center",
                  paddingLeft: !collapsed && "15px"
                }}
              >
                <Icon
                  type="tool"
                  style={{
                    fontSize: "20px",
                    minWidth: collapsed && "unset",
                    lineHeight: "0px"
                  }}
                />
                <span style={{ fontSize: "14px" }}>
                  {t("sider_menu.tools")}
                </span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="/superadmin/widgets">
              <NavLink
                to="/superadmin/widgets"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: collapsed && "center",
                  paddingLeft: !collapsed && "15px"
                }}
              >
                <Icon
                  type="bulb"
                  style={{
                    fontSize: "20px",
                    minWidth: collapsed && "unset",
                    lineHeight: "0px"
                  }}
                />
                <span style={{ fontSize: "14px" }}>
                  {t("sider_menu.widgets")}
                </span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="/superadmin/cms">
              <NavLink
                to="/superadmin/cms"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: collapsed && "center",
                  paddingLeft: !collapsed && "15px"
                }}
              >
                <Icon
                  type="thunderbolt"
                  style={{
                    fontSize: "20px",
                    minWidth: collapsed && "unset",
                    lineHeight: "0px"
                  }}
                />
                <span style={{ fontSize: "14px" }}>{t("sider_menu.cms")}</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="/superadmin/logs">
              <NavLink
                to="/superadmin/logs"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: collapsed && "center",
                  paddingLeft: !collapsed && "15px"
                }}
              >
                <Icon
                  type="hdd"
                  style={{
                    fontSize: "20px",
                    minWidth: collapsed && "unset",
                    lineHeight: "0px"
                  }}
                />
                <span style={{ fontSize: "14px" }}>{t("sider_menu.logs")}</span>
              </NavLink>
            </Menu.Item>
          </MenuItemGroup>
        )}
        {(includeOrg || canConfigure) && (
          <MenuItemGroup
            title={
              collapsed ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <div
                    style={{
                      height: 1,
                      width: 12,
                      borderBottom: "1px solid rgba(255, 255, 255, 0.65)",
                      marginRight: 4
                    }}
                  />
                  {t("sider_menu.admin")}
                  <div
                    style={{
                      height: 1,
                      width: 12,
                      borderBottom: "1px solid rgba(255, 255, 255, 0.65)",
                      marginLeft: 4
                    }}
                  />
                </div>
              ) : (
                <span style={{ fontSize: "18px" }}>{orgName}</span>
              )
            }
          >
            {/* <Tooltip title="Coming Soon!"> */}
            {includeOrg && (
              <Menu.Item key="/admin_dashboard">
                <NavLink
                  to="/admin_dashboard"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: collapsed && "center",
                    paddingLeft: !collapsed && "15px"
                  }}
                >
                  <Icon
                    type="appstore"
                    style={{
                      fontSize: "20px",
                      minWidth: collapsed && "unset",
                      lineHeight: "0px"
                    }}
                  />
                  <span style={{ fontSize: "14px" }}>
                    {t("sider_menu.dashboard")}
                  </span>
                </NavLink>
              </Menu.Item>
            )}
            {includeOrg && (
              <Menu.Item key="/analytics">
                <NavLink
                  to="/analytics"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: collapsed && "center",
                    paddingLeft: !collapsed && "15px"
                  }}
                >
                  <Icon
                    type="bar-chart"
                    style={{
                      fontSize: "20px",
                      minWidth: collapsed && "unset",
                      lineHeight: "0px"
                    }}
                  />
                  <span style={{ fontSize: "14px" }}>
                    {t("sider_menu.analytics")}
                  </span>
                </NavLink>
              </Menu.Item>
            )}
            {includeOrg && !features.includes("DDI_CLIENT") && (
              <Menu.Item key="/admin">
                <NavLink
                  to="/admin/one_on_ones"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: collapsed && "center",
                    paddingLeft: !collapsed && "15px"
                  }}
                >
                  <Icon
                    type="tool"
                    style={{
                      fontSize: "20px",
                      minWidth: collapsed && "unset",
                      lineHeight: "0px"
                    }}
                  />
                  <span style={{ fontSize: "14px" }}>
                    {t("sider_menu.tools")}
                  </span>
                </NavLink>
              </Menu.Item>
            )}
            {/* remove network tab to prevent location gated users from viewing all users. */}
            {/* {includeOrg && !features.includes("DDI_CLIENT") && (
              <Menu.Item key="/admin/network">
                <NavLink
                  to="/admin/network"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: collapsed && "center",
                    paddingLeft: !collapsed && "15px"
                  }}
                >
                  <Icon
                    type="deployment-unit"
                    style={{
                      fontSize: "20px",
                      minWidth: collapsed && "unset",
                      lineHeight: "0px"
                    }}
                  />
                  <span style={{ fontSize: "14px" }}>
                    {t("sider_menu.network")}
                  </span>
                </NavLink>
              </Menu.Item>
            )} */}
            {features.includes("CARROT_SHOP") && (
              <Menu.Item
                key="/admin/carrot_shop"
                style={{
                  width: "100%",
                  zIndex: 101,
                  marginLeft: !collapsed && "-15px"
                }}
              >
                <NavLink
                  to="/admin/carrot_shop"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: collapsed && "center",
                    paddingLeft: !collapsed && "15px"
                  }}
                >
                  <Icon
                    type="shop" // theme="filled"
                    style={{
                      fontSize: "28px",
                      minWidth: collapsed && "unset",
                      lineHeight: "0px"
                    }}
                  />
                  <span style={{ fontSize: "16px" }}>
                    {t("sider_menu.carrot_shop")}
                  </span>
                </NavLink>
              </Menu.Item>
            )}

            {/* </Tooltip> */}
            <Menu.Item key="/admin/config" data-cy="admin-config">
              <NavLink
                to="/admin/config"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: collapsed && "center",
                  paddingLeft: !collapsed && "15px"
                }}
              >
                <Icon
                  type="setting"
                  style={{
                    fontSize: "20px",
                    minWidth: collapsed && "unset",
                    lineHeight: "0px"
                  }}
                />
                <span style={{ fontSize: "14px" }}>
                  {t("sider_menu.configure")}
                </span>
              </NavLink>
            </Menu.Item>
            {/* <Menu.Item key="/admin/teams">
           <NavLink
             to="/admin/teams"
             style={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: collapsed && 'center',
               paddingLeft: !collapsed && '15px'
             }}
           >
             <Icon
               type="team"
               style={{
                 fontSize: '20px',
                 minWidth: collapsed && 'unset',
                 lineHeight: '0px'
               }}
             />
             <span style={{ fontSize: '14px' }}>Teams</span>
           </NavLink>
          </Menu.Item> */}
          </MenuItemGroup>
        )}

        {isManager && !adminWithNewUi && (
          <MenuItemGroup
            title={
              collapsed ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <div
                    style={{
                      height: 1,
                      width: 12,
                      borderBottom: "1px solid rgba(255, 255, 255, 0.65)",
                      marginRight: 4
                    }}
                  />
                  Team
                  <div
                    style={{
                      height: 1,
                      width: 12,
                      borderBottom: "1px solid rgba(255, 255, 255, 0.65)",
                      marginLeft: 4
                    }}
                  />
                </div>
              ) : (
                <span style={{ fontSize: "18px" }}>
                  {includeTeam ? teamName : t("sider_menu.manager_tools")}
                </span>
              )
            }
          >
            {includeTeam && !adminWithNewUi && (
              <Menu.Item key="/manager_dashboard">
                <NavLink
                  to="/manager_dashboard"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: collapsed && "center",
                    paddingLeft: !collapsed && "15px"
                  }}
                >
                  <Icon
                    type="appstore"
                    style={{
                      fontSize: "20px",
                      minWidth: collapsed && "unset",
                      lineHeight: "0px"
                    }}
                  />
                  <span style={{ fontSize: "14px" }}>
                    {t("sider_menu.dashboard")}
                  </span>
                </NavLink>
              </Menu.Item>
            )}
            {!features.includes("DDI_CLIENT") && (
              <Menu.Item key="/manager">
                <NavLink
                  to="/manager/one_on_ones"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: collapsed && "center",
                    paddingLeft: !collapsed && "15px"
                  }}
                >
                  <Icon
                    type="tool"
                    style={{
                      fontSize: "20px",
                      minWidth: collapsed && "unset",
                      lineHeight: "0px"
                    }}
                  />
                  <span style={{ fontSize: "14px" }}>
                    {t("sider_menu.team_tools")}
                  </span>
                </NavLink>
              </Menu.Item>
            )}
            {hasTeam && !adminWithNewUi && (
              <Menu.Item key="/team/config">
                <NavLink
                  to="/team/config"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: collapsed && "center",
                    paddingLeft: !collapsed && "15px"
                  }}
                >
                  <Icon
                    type="contacts"
                    style={{
                      fontSize: "20px",
                      minWidth: collapsed && "unset",
                      lineHeight: "0px"
                    }}
                  />
                  <span style={{ fontSize: "14px" }}>
                    {t("sider_menu.manage_members")}
                  </span>
                </NavLink>
              </Menu.Item>
            )}
            {includeTeam &&
              !features.includes("DDI_CLIENT") &&
              !adminWithNewUi && (
                <Menu.Item key="/team/analytics">
                  <NavLink
                    to="/team/analytics"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: collapsed && "center",
                      paddingLeft: !collapsed && "15px"
                    }}
                  >
                    <Icon
                      type="bar-chart"
                      style={{
                        fontSize: "20px",
                        minWidth: collapsed && "unset",
                        lineHeight: "0px"
                      }}
                    />
                    <span style={{ fontSize: "14px" }}>
                      {t("sider_menu.analytics")}
                    </span>
                  </NavLink>
                </Menu.Item>
              )}
          </MenuItemGroup>
        )}

        {
          // <MenuItemGroup title='Onboarding (ignore me)'>
          // <Menu.Item key="/onboarding/teams">
          //   <NavLink to="/onboarding/teams" style={{display:'flex', alignItems:'center', justifyContent: collapsed && "center",paddingLeft: !collapsed && '15px' }}>
          //     <Icon type="team" style={{fontSize:"20px", minWidth: collapsed && "unset", lineHeight:"0px" }}/>
          //     <span style={{fontSize:"14px"}}>1: Teams</span>
          //   </NavLink>
          // </Menu.Item>
          // <Menu.Item key="/onboarding/managers">
          //   <NavLink to="/onboarding/managers" style={{display:'flex', alignItems:'center', justifyContent: collapsed && "center",paddingLeft: !collapsed && '15px' }}>
          //     <Icon type="solution" style={{fontSize:"20px", minWidth: collapsed && "unset", lineHeight:"0px" }}/>
          //     <span style={{fontSize:"14px"}}>2: Managers</span>
          //   </NavLink>
          // </Menu.Item>
          // <Menu.Item key="/onboarding/users">
          //   <NavLink to="/onboarding/users" style={{display:'flex', alignItems:'center', justifyContent: collapsed && "center",paddingLeft: !collapsed && '15px' }}>
          //     <Icon type="user" style={{fontSize:"20px", minWidth: collapsed && "unset", lineHeight:"0px" }}/>
          //     <span style={{fontSize:"14px"}}>3: Users</span>
          //   </NavLink>
          // </Menu.Item>
          // </MenuItemGroup>
        }
      </Menu>
    </div>
  );
};

SiderMenu.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  includeTeam: PropTypes.bool.isRequired,
  teamName: PropTypes.string.isRequired,
  includeOrg: PropTypes.bool.isRequired,
  orgName: PropTypes.string.isRequired,
  orgLogoLarge: PropTypes.string.isRequired,
  orgLogoSmall: PropTypes.string.isRequired,
  mobile: PropTypes.bool.isRequired,
  canConfigure: PropTypes.bool.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  features: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  closeMenu: PropTypes.func,
  hasTeam: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired
};

SiderMenu.defaultProps = {
  closeMenu: null
};

export default withTranslation()(withRouter(SiderMenu));
