/* eslint-disable no-unused-vars */

/* eslint-disable no-nested-ternary */

/* eslint-disable no-debugger */

/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveRadar } from "@nivo/radar";
import styled, { withTheme } from "styled-components";
import { Row, Col, Typography, Card, Button } from "antd";
import { getTspanGroups } from "utils/nivo";
import moment from "moment";
import PercentageCircle from "components/PercentageCircle";

const HabitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 250px;
  text-align: center;
  top: 25px;
  right: 0px;
`;

const LabelComponent = (props) => {
  const { anchor, id } = props;
  return (
    <g
      transform={`translate(${
        anchor === "end" ? -140 : anchor === "middle" ? -45 : 0
      }, -15)`}
    >
      <text style={{ fontSize: 12 }}>{getTspanGroups(id)}</text>
    </g>
  );
};

const HabitData = ({
  habits,
  raters,
  startDate,
  endDate,
  overallAvg,
  theme
}) => {
  const [habit, setHabit] = useState(null);
  const [hovering, setHovering] = useState(null);
  const [date, setDate] = useState(null);
  const [score, setScore] = useState(null);
  const [radarData, setRadarData] = useState([]);
  const COLORS = [
    "#4E6055",
    "#5a9b39",
    "#f6c449",
    "#349980",
    "#97c17e",
    "#f77911",
    "#997562"
  ];
  const data = habits.map((h, i) => ({
    ...h,
    id: h.name,
    color: COLORS[i % COLORS.length]
  }));

  const handleTooltip = (slice) => {
    const { points } = slice.slice;
    // const hovered = slice.data.fin
    const point = points.find((p) => p.serieId === habit);
    if (point) {
      setDate(point.data.x);
      setScore(point.data.y);
    }

    return <div />;
  };

  const getScore = (trend, d) => {
    const sorted = trend.sort((a, b) => {
      const firstDiff = Math.abs(moment(a.created_at).diff(moment(d), "days"));
      const secondDiff = Math.abs(moment(b.created_at).diff(moment(d), "days"));
      if (firstDiff > secondDiff) {
        return 1;
      }
      return -1;
    });
    return sorted[0] ? sorted[0].value : null;
  };

  useEffect(() => {
    const nextRadarData = [];
    if (date && habit) {
      const selected = habits.find((h) => h.name === habit);
      selected.behaviors.forEach((b) => {
        nextRadarData.push({
          behavior: b.name,
          [habit]: getScore(b.data, date)
        });
      });

      setRadarData(nextRadarData);
    } else if (habit) {
      const selected = habits.find((h) => h.name === habit);
      selected.behaviors.forEach((b) => {
        nextRadarData.push({
          behavior: b.name,
          [habit]: b.score
        });
      });
      const avg =
        selected.data.length > 0
          ? selected.data.map((d) => d.y).reduce((a, b) => a + b) /
            selected.data.length
          : null;
      setScore(avg ? avg.toFixed() : null);
      setRadarData(nextRadarData);
    } else {
      setRadarData([]);
    }
  }, [habit, date]);

  const selectedHabit = habits.find((h) => h.name === habit);

  const y =
    selectedHabit && selectedHabit.data.length > 0
      ? selectedHabit.data[selectedHabit.data.length - 1].y
      : null;

  return (
    <Row style={{ marginBottom: 24 }}>
      <Typography.Title level={4}>Habit Summary</Typography.Title>
      <Card>
        <Col xxl={12} xl={12} lg={24}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Typography.Title level={4}>Change Over Time</Typography.Title>
          </div>
          <div style={{ height: 400, position: "relative" }}>
            {habit ? (
              <HabitWrapper>
                {habit}
                <PercentageCircle
                  animate={false}
                  showChange={false}
                  raters={10}
                  percent={score || y}
                  color={theme.primary_color}
                  responses={100}
                />
                {date ? (
                  moment(date).format("LL")
                ) : (
                  <>
                    <span>Average over range</span>
                    <span>
                      {moment(startDate).format("LL")} -{" "}
                      {moment(endDate).format("LL")}
                    </span>
                  </>
                )}
              </HabitWrapper>
            ) : (
              <HabitWrapper>
                Overall Average
                <PercentageCircle
                  animate={false}
                  showChange={false}
                  raters={10}
                  percent={overallAvg}
                  color={theme.primary_color}
                  responses={100}
                />
                {date ? (
                  moment(date).format("LL")
                ) : (
                  <span>
                    {moment(startDate).format("LL")} -{" "}
                    {moment(endDate).format("LL")}
                  </span>
                )}
              </HabitWrapper>
            )}
            <ResponsiveLine
              isInteractive
              lineWidth={2}
              sliceTooltip={(slice) => handleTooltip(slice)}
              enableSlices="x"
              curve="linear"
              colors={(d) => {
                return hovering || habit
                  ? hovering === d.id || habit === d.id
                    ? d.color
                    : "rgba(0, 0, 0, 0.1)"
                  : d.color;
              }}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  onMouseEnter: ({ id }) => setHovering(id),
                  onMouseLeave: () => setHovering(null),
                  onClick: ({ id }) => {
                    if (habit && habit === id) {
                      setHabit(null);
                    } else {
                      setHabit(id);
                    }
                    setDate(null);
                  },
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
              data={data}
              enablePoints={false}
              margin={{ top: 30, right: 250, bottom: 50, left: 50 }}
              xScale={{
                type: "time",
                format: "%Y-%m-%d",
                precision: "day"
              }}
              xFormat="time:%Y-%m-%d"
              axisBottom={{
                format: "%b %d",
                tickValues: 5,
                legendOffset: -12
              }}
              yScale={{
                type: "linear",
                stacked: false
              }}
            />
          </div>
          Feedback Providers: {raters.map((r) => r.name).join(", ")}
        </Col>
        <Col xxl={12} xl={12} lg={24}>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <Typography.Title level={4}>
              {habit || "Select a Habit to see breakdown"}
            </Typography.Title>
            {habit && (
              <Button style={{ marginLeft: 12 }} onClick={() => setHabit(null)}>
                Clear Selected
              </Button>
            )}
          </div>
          <div style={{ height: 400 }}>
            <ResponsiveRadar
              colors={habit ? ["#5a9b39"] : ["#fff"]}
              data={radarData}
              keys={[habit]}
              indexBy="behavior"
              maxValue="auto"
              margin={{ top: 100, right: 100, bottom: 100, left: 100 }}
              curve="cardinalClosed"
              borderWidth={2}
              borderColor={{ from: "color" }}
              gridLevels={5}
              gridLabel={LabelComponent}
              gridShape="circular"
              gridLabelOffset={36}
              enableDots
              dotSize={10}
              dotColor={{ theme: "background" }}
              dotBorderWidth={2}
              dotBorderColor={{ from: "color" }}
              enableDotLabel
              dotLabel="value"
              dotLabelYOffset={-12}
              fillOpacity={0.25}
              blendMode="multiply"
              animate
              motionStiffness={90}
              motionDamping={15}
              isInteractive
            />
          </div>
        </Col>
      </Card>
    </Row>
  );
};

export default withTheme(HabitData);
