// import { inject, observer } from 'mobx-react';
import React, { useState, useEffect } from "react";
import { Select, Avatar, Spin } from "antd";
import styled from "styled-components";
import { User } from "services/api";
import nameToInitials from "utils/nameToInitials";
import PropTypes from "prop-types";

const UserItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Search = ({ onSelect, currentlySelected, placeholder, owner }) => {
  const [fetching, setFetching] = useState(false);
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState("");
  const [timeoutId, setTimeoutId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (query.length > 0) {
        if (!fetching) {
          setFetching(true);
        }
        setUsers([]);
        clearTimeout(timeoutId);
        setTimeoutId(
          setTimeout(() => {
            User.all(query).then(({ data }) => {
              setUsers(data);
              setFetching(false);
            });
          }, 200)
        );
      } else {
        clearTimeout(timeoutId);
        setUsers([]);
      }
    };

    fetchData();
  }, [query]);
  const { Option } = Select;
  let notFoundContent;
  if (query.length > 0 && fetching) {
    notFoundContent = <Spin size="small" />;
  } else if (query.length > 0) {
    notFoundContent = "Not Found";
  } else {
    notFoundContent = null;
  }
  const options = users
    .filter(
      (u) => !currentlySelected.find((s) => s.id === u.id) && u.id !== owner.id
    )
    .map((u) => (
      <Option value={u.id}>
        <UserItem>
          {" "}
          <Avatar style={{ marginRight: 12 }} src={u.avatar}>
            {nameToInitials(u.name)}
          </Avatar>
          {u.name}
        </UserItem>
      </Option>
    ));
  return (
    <Select
      showSearch
      placeholder={placeholder} // defaultActiveFirstOption
      showArrow={false}
      filterOption={false}
      size="large"
      onSearch={(val) => setQuery(val)}
      loading={fetching}
      value={[]}
      className="user-search"
      onSelect={(val) => {
        setQuery("");
        onSelect(users.find((u) => u.id === val));
      }}
      notFoundContent={notFoundContent}
      style={{ marginBottom: 24, width: "75%" }}
    >
      {options}
    </Select>
  );
};

Search.defaultProps = {
  currentlySelected: [],
  placeholder: "Search Users"
};

Search.propTypes = {
  onSelect: PropTypes.func.isRequired,
  currentlySelected: PropTypes.array,
  placeholder: PropTypes.string,
  owner: PropTypes.object.isRequired
};

export default Search;
