/* eslint-disable */

import React from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Button, Typography, Row, Col, message, Statistic, Modal, Card } from "antd";
import { observable, computed } from "mobx";
import posed from "react-pose";
import NineBox from "components/NineBox";
import {
  Wrapper,
  Header,
  HeaderText,
  Body,
  Left,
  Right,
  HabitText,
  GoalText,
  Top
} from "./styles";
import LoadingOverlay from "../../components/LoadingOverlay";
import OneOnOneCard from "../../components/OneOnOneCard";
import HabitData from "containers/OneOnOne/Data/HabitData";
import { ReviewCohort, PerformanceReview as api } from "../../services/api";
import UserControls from "./UserControls";
import ReviewInfo from "./ReviewInfo";
import ReviewForm from "./ReviewForm";
import ReviewControls from "./ReviewControls";
import PastTable from "./PastReviewTable";

const PosedBody = posed(Body)({
  visible: { opacity: 1, duration: 300 },
  hidden: { opacity: 0, duration: 300 }
});

@observer
class PerformanceReview extends React.Component {
  @observable cohort = null;
  @observable loading = true;
  @observable buttonLoading = false;
  @observable currentReview = null;
  @observable editing = false;
  @observable hasChanges = false;
  @observable currentUser = null;
  @observable pendingReviews = {};
  @observable currentReviewCompleted = false;
  @observable summarizing = false;
  @observable review = null;
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.isReviewComplete = this.isReviewComplete.bind(this);

    const { match } = this.props;
    ReviewCohort.single(match.params.id).then(({ data }) => {
      this.cohort = data;
      this.loading = false;
      const unfinishedUsers = this.cohort.users.filter(
        (u) => !this.cohort.reviews.find((r) => r.user_id === u.id)
      );
      if (unfinishedUsers.length) {
        this.setUser(unfinishedUsers[0].id);
      } else {
        this.setUser(this.cohort.users[0].id);
      }
    });
  }

  setUser = (id) => {
    this.loading = true;
    // if (this.pendingReviews[id]) {
    //   this.currentReview = this.pendingReviews[id];
    //   this.currentUser = id;
    //   this.loading = false;
    //   return;
    // }
    // if (this.currentReview) {
    //   this.pendingReviews[this.currentUser] = this.currentReview;
    // }
    this.currentUser = id;
    // // api.fetch(this.cohort.id, id).then(({ data }) => {
    // //   this.review =
    // // })
    this.review = this.cohort.reviews.find((r) => r.user_id === id);
    if (this.review) {
      api.fetch(this.review.id).then(({ data }) => {
        this.currentReview = data;
        this.loading = false;
        this.pose = "visible";
        this.editing = true;
        this.currentReviewCompleted = true;
        const form = this.formRef;
        if (form) {
          form.setFieldsValue(data);
        }
      });
    } else {
      api.generate(this.cohort.id, id).then(({ data }) => {
        const form = this.formRef;
        if (form) {
          form.resetFields();
        }
        this.currentReview = data;
        this.editing = false;
        this.loading = false;
        this.pose = "visible";
        this.currentReviewCompleted = false;
      });
    }
  };

  selectBox = (coordinates) => {
    this.hasChanges = true;

    [this.currentReview.x, this.currentReview.y] = coordinates.split(",");
    this.forceUpdate();
  };

  isReviewComplete = (values) => {
    this.currentReviewCompleted = !Object.values(values).some((val) =>
      typeof val === "string" ? !val.length : val === null
    );
  };

  handleSave = () => {
    const form = this.formRef;
    this.buttonLoading = true;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const params = {
        habit_data: JSON.stringify(this.currentReview.habit_data),
        objective_data: JSON.stringify(this.currentReview.objective_data),
        original_x: this.currentReview.original_x,
        original_y: this.currentReview.original_y,
        overall_habit_score: this.currentReview.overall_habit_score,
        overall_objective_score: this.currentReview.overall_objective_score,
        original_y: this.currentReview.original_y,
        x: this.currentReview.x,
        y: this.currentReview.y,
        rank: this.currentReview.rank
      };
      console.log(this.cohort.reviews);
      if (this.currentReview.id) {
        api
          .update(this.currentReview.id, Object.assign(values, params))
          .then(({ data }) => {
            console.log(this.cohort.reviews);
            this.cohort.reviews = this.cohort.reviews.map((r) =>
              r.id === data.id ? data : r
            );
            message.success("Review Updated");
            this.buttonLoading = false;
          });
      } else {
        api
          .create(
            this.cohort.id,
            this.currentReview.user.id,
            Object.assign(values, params)
          )
          .then(({ data }) => {
            console.log(this.cohort.reviews);
            this.cohort.reviews = [...this.cohort.reviews, data];
            this.currentReview.id = data.id;
            message.success("Review Saved");
            this.buttonLoading = false;
          });
      }
      this.hasChanges = false;
    });
  };

  handleNext = () => {
    if (this.hasChanges) {
      Modal.confirm({
        title: "You Have Unsaved Changes",
        content:
          'If you would like to continue without saving, click "Discard Changes", otherwise, when you are finished, click "Save Review" before continuing',
        okText: "Discard Changes",
        cancelText: "Go Back",
        onOk: () => {
          const index = this.cohort.users
            .map((u) => u.id)
            .indexOf(this.currentUser);
          this.setUser(this.cohort.users[index + 1].id);
        }
      });
    } else {
      const index = this.cohort.users
        .map((u) => u.id)
        .indexOf(this.currentUser);
      this.setUser(this.cohort.users[index + 1].id);
    }
  };

  handlePrevious = () => {
    if (this.hasChanges) {
      Modal.confirm({
        title: "You Have Unsaved Changes",
        content:
          'If you would like to continue without saving, click "Discard Changes", otherwise, when you are finished, click "Save Review" before continuing',
        okText: "Discard Changes",
        cancelText: "Go Back",
        onOk: () => {
          const index = this.cohort.users
            .map((u) => u.id)
            .indexOf(this.currentUser);
          this.setUser(this.cohort.users[index - 1].id);
        }
      });
    } else {
      const index = this.cohort.users
        .map((u) => u.id)
        .indexOf(this.currentUser);
      this.setUser(this.cohort.users[index - 1].id);
    }
  };

  updateReview = (reviewId, coordinates) => {
    const review = this.cohort.reviews.find((r) => r.id === reviewId);
    [review.x, review.y] = coordinates.split(",");
    this.hasChanges = true;
  };

  handleSubmit = () => {
    this.cohort.reviews.forEach(({ id, x, y }) => {
      api.update(id, { x, y });
    });
    this.props.history.push("/admin/career_planner/");
  };

  setSummarizing = () => {
    if (this.hasChanges) {
      Modal.confirm({
        title: "You Have Unsaved Changes",
        content:
          'If you would like to continue without saving, click "Discard Changes", otherwise, when you are finished, click "Save Review" before continuing',
        okText: "Discard Changes",
        cancelText: "Go Back",
        onOk: () => {
          this.summarizing = true;
        }
      });
    } else {
      this.summarizing = true;
    }
  };

  

  render() {
    const spans = { xxl: 8, xl: 8, lg: 12, md: 12, sm: 12, xs: 12 };
    if (this.loading) {
      return (
        <Wrapper>
          <LoadingOverlay />
        </Wrapper>
      );
    }
    if (this.summarizing) {
      return (
        <Wrapper
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
            position: "relative"
          }}
        >
          <Button
            onClick={() => (this.summarizing = false)}
            style={{ position: "absolute", left: -12, top: 120 }}
            type="link"
          >
            Go Back
          </Button>
          <HeaderText large>{this.cohort.label}</HeaderText>
          <HeaderText>
            {this.cohort.start_date} - {this.cohort.end_date}
          </HeaderText>
          <HeaderText>
            Here is a summary of where you placed each employee through the
            review process. At this time, you can make changes by selecting a
            user and then selecting a box to move them to.
          </HeaderText>
          <Button
            style={{ margin: "12px 0", width: "fit-content" }}
            size="large"
            type="primary"
            onClick={this.handleSubmit}
          >
            Finish Review
          </Button>
          <Wrapper>
            <Header style={{ background: "white", padding: 24 }}>
              <Typography.Title style={{ textAlign: "center" }} level={4}>
                Rank Order:
              </Typography.Title>
              {this.cohort.users
                .filter(
                  (u) =>
                    this.cohort.reviews.find((r) => r.user.id === u.id).rank
                )
                .sort(
                  (a, b) =>
                    this.cohort.reviews.find((r) => r.user.id === a.id).rank -
                    this.cohort.reviews.find((r) => r.user.id === b.id).rank
                )
                .map((c) => (
                  <span>
                    {c.name} {c.rank}
                  </span>
                ))}
              {this.cohort.users.filter(
                (u) => !this.cohort.reviews.find((r) => r.user.id === u.id).rank
              ).length > 0 && (
                <>
                  <Typography.Title
                    level={4}
                    style={{ marginTop: 12, textAlign: "center" }}
                  >
                    The following users could not be ranked:
                  </Typography.Title>
                  {this.cohort.users
                    .filter(
                      (u) =>
                        !this.cohort.reviews.find((r) => r.user.id === u.id)
                          .rank
                    )
                    .map((u) => (
                      <span>{u.name}</span>
                    ))}
                </>
              )}
            </Header>
            <Body style={{ height: 600 }}>
              <NineBox
                summary
                reviews={this.cohort.reviews}
                updateReview={this.updateReview}
              />
            </Body>
          </Wrapper>
        </Wrapper>
      );
    }
    return (
      <Wrapper
        style={{
          flexDirection: "column",
          justifyContent: "center",
          border: "none"
        }}
      >
        <HeaderText large>
          <Typography.Title level={4}>{this.cohort.label}</Typography.Title>
        </HeaderText>
        <HeaderText>
          <Typography.Text>
            {this.cohort.start_date} - {this.cohort.end_date}
          </Typography.Text>
        </HeaderText>
        <HeaderText>
          {this.cohort.reviews.length >= this.cohort.users.length && (
            <Button
              style={{ marginTop: 24 }}
              type="primary"
              onClick={this.setSummarizing}
            >
              Finish Review Group
            </Button>
          )}
        </HeaderText>
        <Wrapper style={{ background: "white", marginTop: 12 }}>
          <Header>
            <UserControls
              users={this.cohort.users}
              reviews={this.cohort.reviews}
              setUser={this.setUser}
              setSummarizing={() => (this.summarizing = true)}
              currentUser={this.currentUser}
              buttonDisabled={
                this.cohort.users.map((u) => u.id).indexOf(this.currentUser) ===
                this.cohort.users.length - 1
              }
              handleNext={this.handleNext}
              handlePrevious={this.handlePrevious}
              previousDisabled={
                this.cohort.users.map((u) => u.id).indexOf(this.currentUser) ===
                0
              }
            />
          </Header>
          {this.currentReview && this.currentUser && (
            <Body>
              <ReviewControls
                user={this.currentReview.user}
                onSave={this.handleSave}
                reviewsLength={this.cohort.users.length}
                currentReview={this.currentReview}
                editing={this.editing}
                buttonLoading={this.buttonLoading}
                buttonDisabled={
                  !this.currentReviewCompleted ||
                  this.currentReview.x === undefined ||
                  this.currentReview.y === undefined
                }
              />
              <Row gutter={24}>
                <Col span={16}>
                  <ReviewForm
                    setChanged={() => (this.hasChanges = true)}
                    ref={(ref) => (this.formRef = ref)}
                    currentReview={this.currentReview}
                    editing={this.editing}
                    checkCompleted={this.isReviewComplete}
                  />
                </Col>
                <Col span={8} style={{ height: 300, padding: 24 }}>
                  <NineBox
                    review={this.currentReview}
                    onSelect={this.selectBox}
                  />
                </Col>
              </Row>
              <div style={{ padding: 24, display: "flex" }}>
                <Statistic
                  suffix="%"
                  style={{ marginRight: 24 }}
                  value={
                    this.currentReview.overall_habit_score
                      ? Number(this.currentReview.overall_habit_score).toFixed(
                          0
                        )
                      : "--"
                  }
                  title="Average Habit Score"
                />
                <Statistic
                  value={
                    this.currentReview.overall_objective_score
                      ? Number(
                          this.currentReview.overall_objective_score
                        ).toFixed(2)
                      : "--"
                  }
                  title="Average Objective Score"
                />
              </div>
              <PastTable id={this.review && this.review.id} />
              <div style={{ padding: 24 }}>
                <Typography.Title level={4}>One-on-Ones</Typography.Title>
                <Row gutter={24}>
                  {this.currentReview.one_on_ones.map((o) => (
                    <Col {...spans}>
                      <OneOnOneCard ooo={o} key={o.id} />
                    </Col>
                  ))}
                </Row>
              </div>
              <div style={{ padding: 24 }}>
                {this.currentReview.ooo_habit_data.overall_average === null ? (
                  <Row>
                    <Typography.Title level={4}>Habit Summary</Typography.Title>
                    <Card style={{display: "flex", justifyContent: "center", alignItems: "center", height: "300px"}}>
                      <p style={{color: "lightgray"}}>Not enough habit data for the supplied date range</p>
                    </Card>
                  </Row>
                  
                ) : (
                  <HabitData
                  endDate={this.cohort.end_date}
                  startDate={this.cohort.start_date}
                  habits={this.currentReview.ooo_habit_data.habits}
                  overallAvg={this.currentReview.ooo_habit_data.overall_average}
                  raters={this.currentReview.ooo_habit_data.raters}
                />
                )}
                
              </div>
              {/* <ReviewFormBottom /> */}

              {/* <Right>
                <HabitText>Habit Performance</HabitText>
                <GoalText>Goal Performance</GoalText>
                <NineBox
                  review={this.currentReview}
                  onSelect={this.selectBox}
                />
              </Right> */}
            </Body>
          )}
        </Wrapper>
      </Wrapper>
    );
  }
}

export default withRouter(PerformanceReview);
