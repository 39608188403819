import axios from "axios";
import { notification } from "antd";
import profileStore from "stores/profileStore";
import React from "react";
import history from "services/history";
import CarrotIcon from "components/CarrotIcon";
import applyAppTokenRefreshInterceptor from "./interceptor";
import { API_URL } from "../env";
import i18n from "utils/i18n";

const apiClient = axios.create({
  baseURL: API_URL
});

applyAppTokenRefreshInterceptor(apiClient);

function getAccessToken() {
  const token = localStorage.getItem("token");
  if (token) {
    return `Bearer ${token}`;
  }
  return "";
}

const emojiMapping = {
  tutorial_completed: "🎓",
  first_highfive: "🤚",
  set_avatar: "😎",
  add_phone_number: "📞",
  send_feedback: "💡",
  first_deck: "🐣",
  first_deep_dive: "🏊‍♂️",
  streak_3_weeks: "🏃‍♂️",
  streak_12_weeks: "🔥"
};

let isRefreshing = false;
let failedQueue = [];
// redeploy
const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

apiClient.interceptors.response.use(
  response => {
    if (response.headers.badge_awarded) {
      const badge = JSON.parse(response.headers.badge_awarded);
      const { new_user_streak, name } = badge;
      const { title, value, description } = badge.custom_fields;

      notification.open({
        duration: 10,
        icon: (
          <div style={{ paddingTop: 12 }}>
            <span style={{ fontSize: 48 }}>{emojiMapping[name]}</span>
            <CarrotIcon value={value} />
          </div>
        ),
        message: <div style={{ marginLeft: 60 }}>Achievement Unlocked!</div>,
        description: (
          <div style={{ marginLeft: 60 }}>
            <span style={{ fontWeight: "bold" }}>{title}: </span>
            <span>{description}</span>
          </div>
        )
      });
      profileStore.currentUser.feedback_streak = new_user_streak;
      profileStore.currentUser.current_quarter_carrots = new_user_streak;
    }
    return response;
  },
  error => Promise.reject(error)
);

// apiClient.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     const originalRequest = error.config;
//
//     if (
//       error &&
//       error.response.status === 401 &&
//       !originalRequest.retry &&
//       originalRequest.url.indexOf("/account/tokens") == -1
//     ) {
//       if (isRefreshing) {
//         return new Promise((resolve, reject) => {
//           failedQueue.push({ resolve, reject });
//         })
//           .then(token => {
//             originalRequest.headers.Authorization = `Bearer ${token}`;
//             if (originalRequest.method === "post" || originalRequest.method === "put") {
//               if (originalRequest.data) {
//                 originalRequest.data = JSON.parse(originalRequest.data);
//               }
//             }

//             return apiClient(originalRequest);
//           })
//           .catch(err => {
//             return err;
//           });
//       }

//       originalRequest.retry = true;
//       isRefreshing = true;

//       const refresh_token = window.localStorage.getItem("refresh_token");
//       return new Promise((resolve, reject) => {
//         apiClient
//           .post("/account/tokens")
//           .then(({ data, headers }) => {
//             window.localStorage.setItem("token", data.access_token || headers["access-token"]);
//             window.localStorage.setItem(
//               "refresh_token",
//               data.refresh_token || headers["refresh-token"]
//             );
//             apiClient.defaults.headers.common.Authorization = `Bearer ${data.access_token ||
//               headers["access-token"]}`;
//             originalRequest.headers.Authorization = `Bearer ${data.access_token ||
//               headers["access-token"]}`;
//             if (originalRequest.method === "post" || originalRequest.method === "put") {
//               if (originalRequest.data) {
//                 originalRequest.data = JSON.parse(originalRequest.data);
//               }
//             }

//             processQueue(null, data.access_token || headers["access-token"]);
//             resolve(apiClient(originalRequest));
//           })
//           .catch(err => {
//
//             processQueue(err, null);
//             reject(err);
//           })
//           .then(() => {
//             isRefreshing = false;
//           });
//       });
//     }
//
//     if (error.response.status === 401 && originalRequest.url === "/account/tokens") {
//
//       processQueue(error, null);
//       localStorage.removeItem("token");
//       localStorage.removeItem("refresh_token");
//       // window.location.assign('/');
//       profileStore.removeToken();
//       history.push("/");
//       notification.error({
//         key: 1,
//         message: "Session Expired",
//         description: "Please log in again"
//       });
//     }
//     return Promise.reject(error);
//   }
// );

const getLanguage = () => {
  const i = i18n;
  const lng = localStorage.getItem("i18nextLng") || "en";
  const lngs = ["en", "de", "fr", "es", "zh-CN"];
  const val = lngs.includes(lng) ? lng : "en";

  return val;
};

apiClient.interceptors.request.use(config => ({
  ...config,
  headers: {
    "Accept-Encoding": "br",
    "Accept-Language": getLanguage(),
    Authorization: getAccessToken(),
    "Access-Token": window.localStorage.getItem("token"),
    "Refresh-Token": window.localStorage.getItem("refresh_token")
  }
}));

const { get, post, put } = apiClient;
const destroy = apiClient.delete;
export { get, post, put, destroy };
