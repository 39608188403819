import React, { useState, useEffect } from "react";
import Box from "layout/Box";
import LoadingOverlay from "components/LoadingOverlay";
import { AnchorContainer } from "antd/lib/anchor/Anchor";
import { Anchor, Divider, Button, message, Modal } from "antd";
import Relationships from "containers/Relationships";
import { MessageType } from "antd/lib/message";
import SendHighfive from "components/SendHighfive";
import OneOnOneWizard from "containers/OneOnOneWizard";
import useStores from "utils/useStores";
import ReactJoyride, { Step, EVENTS, STATUS, ACTIONS } from "react-joyride";
import { withTheme } from "styled-components";
import openHabitPicker from "containers/HabitPicker";
import { useFetchManagedUserProfileQuery } from "./fetchManagerUserProfile.generated";
import Habits from "./Habits";
import Objectives from "./Objectives";
import UserHeader from "./UserHeader";
import ExtraFeedback from "./ExtraFeedback";
import { useCreateExtraFeedbackRequestMutation } from "./createExtraFeedbackRequest.generated";
import history from "services/history";
import { destroy } from "components/FullScreenModal";

interface Props {
  id: string;
  lightbox?: string;
  theme: any;
}

const ManagerUserProfile: React.FC<Props> = ({ id, lightbox, theme }) => {
  const {
    profileStore: { currentUser }
  } = useStores();
  const { loading, error, data, refetch } = useFetchManagedUserProfileQuery({
    variables: { id }
  });
  const [showObjectives, setShowObjectives] = useState(false);
  const [sendHighfiveOpen, setSendHighfiveOpen] = useState(false);
  const [extraFeedbackOpen, setExtraFeedbackOpen] = useState(false);
  const [oneOnOneWizardOpen, setOneOnOneWizardOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (lightbox) {
        setPopup(true);
      }
    }, 2000);
  }, []);

  const openSendHighfive = (): void => setSendHighfiveOpen(true);
  const closeSendHighfive = (): void => setSendHighfiveOpen(false);
  const showHighfiveSuccess = (): MessageType =>
    message.success("High-five sent!");
  const showOneOnOneSuccess = (): MessageType =>
    message.success("One-on-one scheduled!");
  const closeOneOnOneWizard = (): void => setOneOnOneWizardOpen(false);

  const closeExtraFeedback = (): void => setExtraFeedbackOpen(false);
  const openExtraFeedback = (): void => setExtraFeedbackOpen(true);

  const showFeedbackSuccess = (): void => {
    closeExtraFeedback();
    Modal.success({ title: "Action Item sent successfully!" });
  };

  const [
    createExtraFeedbackRequestMutation
  ] = useCreateExtraFeedbackRequestMutation({
    onCompleted: showFeedbackSuccess
  });

  if (loading || !data) {
    return (
      <Box>
        <LoadingOverlay />
      </Box>
    );
  }

  if (error) {
    return <Box>Something went wrong;</Box>;
  }

  const sendFeedbackRequest = (habitUserId: string): void => {
    createExtraFeedbackRequestMutation({
      variables: { input: { habitUserId, userId: id } }
    });
  };

  const lightboxHabit = data.user.habitUsers.find((h) => h.id === lightbox);
  const steps: Step[] = [];
  if (lightboxHabit) {
    steps.push({
      disableBeacon: true,
      placement: "left-start",
      target: document.getElementById(lightboxHabit.id) as HTMLElement,
      content: `${data.user.name} is struggling with ${lightboxHabit.habit.name}.  Click here to view a detailed breakdown of the behaviors that make up this habit.`
    });
  }

  const onCloseHabitPicker = (): void => {
    refetch();
    setRefreshKey(refreshKey + 1);
  };

  const goToTeam = (teamId: string): void => {
    history.push(`/manager_dashboard/${teamId}`);
    destroy("user-modal-wrapper");
  };

  const handleJoyrideCallback = (joyrideData: any): void => {
    const { action, index, status, type } = joyrideData;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setPopup(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <ExtraFeedback
        visible={extraFeedbackOpen}
        onClose={closeExtraFeedback}
        name={data.user.name as string}
        habitUsers={data.user.habitUsers}
        sendFeedbackRequest={sendFeedbackRequest}
      />
      <ReactJoyride
        stepIndex={stepIndex}
        disableScrolling
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: theme.primary_color,
            zIndex: 1000
          }
        }}
        spotlightClicks
        steps={steps}
        run={popup}
        spotlightPadding={10}
      />
      {sendHighfiveOpen && (
        <SendHighfive
          visible={sendHighfiveOpen}
          onClose={closeSendHighfive}
          onSuccess={showHighfiveSuccess}
          selectedUsers={[data.user]}
        />
      )}
      <OneOnOneWizard
        visible={oneOnOneWizardOpen}
        onClose={closeOneOnOneWizard}
        currentUser={currentUser}
        onSubmit={showOneOnOneSuccess}
        addOneOnOnes={(): null => null}
        preSelectedUser={data.user}
      />
      <UserHeader user={data.user} goToTeam={goToTeam} />
      <Box display="flex">
        <Box
          width={150}
          height="calc(100vh - 126px)"
          padding="24px"
          position="relative"
        >
          <Anchor
            targetOffset={200}
            style={{ position: "fixed", top: "0 important!" }}
            getContainer={(): AnchorContainer =>
              document.getElementById("managed-user-body") as AnchorContainer
            }
          >
            {/* <Anchor.Link href="#action-items" title="Action Items" /> */}
            <Anchor.Link href="#habits" title="Habits" />
            {showObjectives && (
              <Anchor.Link href="#objectives" title="Objectives" />
            )}
            <Anchor.Link href="#feedback-circle" title="Feedback Circle" />
          </Anchor>
        </Box>
        <Box
          id="managed-user-body"
          width="calc(100% - 400px)"
          display="flex"
          flexDirection="column"
          padding="24px 24px 128px 24px"
          height="calc(100vh - 126px)"
          overflow="auto"
          flexShrink={0}
        >
          {/* <ActionWrapper id="action-items" style={{ minHeight: "unset", marginBottom: 24 }}>
            {data.user.managedActionItems.map(action => (
              <ActionItem key={action.id} action={{ ...action, user: data.user }} />
            ))}
          </ActionWrapper> */}
          <Divider style={{ marginTop: 0 }}>Habits</Divider>
          <Habits
            habitUsers={data.user.habitUsers}
            setStepIndex={(): void => setStepIndex(1)}
          />
          {showObjectives && (
            <div id="objectives">
              <Divider>Objectives</Divider>
            </div>
          )}
          <Objectives userId={id} setShowObjectives={setShowObjectives} />
          <div id="feedback-circle">
            <Divider>Feedback Circle</Divider>
          </div>
          <Relationships managedUser={data.user} embedded key={refreshKey} />
        </Box>
        <Box
          width={250}
          height="calc(100vh - 126px)"
          display="flex"
          flexDirection="column"
          // id="user-help-items"
          paddingRight="12px"
          paddingTop="8px"
        >
          <Divider style={{ marginBottom: 36 }}>Take Action</Divider>
          <div id="user-help-items">
            {/* <Button type="primary" style={{ width: "100%", marginBottom: 12 }}>
              Help {data.user.name.split(" ")[0]} Improve
            </Button> */}
            <Button
              type="primary"
              style={{ width: "100%", marginBottom: 12 }}
              onClick={() => setOneOnOneWizardOpen(true)}
            >
              Set Up 1:1
            </Button>
            <Button
              type="primary"
              style={{ width: "100%", marginBottom: 12 }}
              onClick={openSendHighfive}
            >
              Give them a High-five
            </Button>
            <Button
              type="primary"
              style={{ width: "100%", marginBottom: 12 }}
              onClick={openExtraFeedback}
            >
              Get them Extra Feedback
            </Button>
            <Button
              type="primary"
              style={{ width: "100%", marginBottom: 12 }}
              onClick={() => openHabitPicker(data.user, onCloseHabitPicker)}
            >
              Manage their Habits
            </Button>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default withTheme(ManagerUserProfile);
