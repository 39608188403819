import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import FlairImg from "assets/dashboard/participation_alert.svg";
import { useTranslation } from "react-i18next";
import { ItemWrapper, Title, ButtonWrapper, Flair, Content } from "../styles";

const names = (managers, t) => {
  if (managers.length === 1) {
    return managers[0].name;
  }
  if (managers.length === 2) {
    return `${managers[0].name} ${t(
      "homepage.action_items.added_to_group.and"
    )} ${managers[1].name}.`;
  }
  return managers.map((m, i) =>
    i === managers.length - 1
      ? `${t("homepage.action_items.added_to_group.and")} ${m.name}.`
      : `${m.name}, `
  );
};

const GroupAddItem = ({ group, markCompleted }) => {
  const { t } = useTranslation();
  return (
    <ItemWrapper>
      <Content>
        <Title>
          {t("homepage.action_items.added_to_group.prompt_text", {
            groupName: group.name
          })}
        </Title>
        <p>
          {t("homepage.action_items.added_to_group.prompt_subtext", {
            groupName: group.name,
            names: names(group.managers, t)
          })}
        </p>
        <ButtonWrapper>
          <Button onClick={markCompleted} type="primary">
            {t("homepage.action_items.added_to_group.action_text")}
          </Button>
        </ButtonWrapper>
      </Content>
      <Flair src={FlairImg} />
    </ItemWrapper>
  );
};

GroupAddItem.propTypes = {
  group: PropTypes.object.isRequired,
  markCompleted: PropTypes.func.isRequired
};

export default GroupAddItem;
