/* eslint-disable no-unused-vars */

/* eslint-disable no-debugger */
import React, { useState, useEffect } from "react";
import { ResponsivePie } from "@nivo/pie";
import PropTypes from "prop-types";
import history from "services/history";

import {
  Wrapper,
  ChartLabel,
  BigNumber,
  LabelWrapper,
  Label,
  Item,
  Number,
  PieWrapper
} from "./styles";

function useForceUpdate() {
  const [value, set] = useState(true);
  return () => set(!value);
}

const getResponse = (data) => {
  if (typeof data === "string") {
    return data;
  }
  if (typeof data === "object" && data !== null) {
    return data.response;
  }
  return null;
};

const generateData = (objectives) => {
  if (!objectives) {
    return [];
  }
  const data = [
    {
      id: "not_confident",
      label: "Not Confident",
      value: 0,
      color: "#FF494B"
    },
    {
      id: "very_confident",
      label: "Very Confident",
      value: 0,
      color: "#40CC75"
    },
    {
      id: "somewhat_confident",
      label: "Somewhat Confident",
      value: 0,
      color: "#FFBB03"
    },
    {
      id: "awaiting_check_in",
      label: "Awaiting check-in",
      value: 0,
      color: "lightgrey"
    }
  ];
  objectives.forEach((child) => {
    //
    if (
      child.status !== "COMPLETED" &&
      getResponse(child.latest_check_in) === "no"
    ) {
      data[0].value += 1;
    } else if (
      child.status !== "COMPLETED" &&
      getResponse(child.latest_check_in) === "yes"
    ) {
      data[1].value += 1;
    } else if (
      child.status !== "COMPLETED" &&
      getResponse(child.latest_check_in) === "don't know"
    ) {
      data[2].value += 1;
    } else {
      data[3].value += 1;
    }
  });
  if (objectives.length === 0) {
    data[3].value += 1;
  }
  return data;
};

const ActiveCompletePie = ({ data, view, mobile }) => {
  // const pieData = generateData(data);
  const [info, setInfo] = useState([]);
  const [objs, setObjs] = useState(data);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const d = generateData(data);
    setInfo(d);
    setObjs(data);
    forceUpdate();
  }, [data]);

  return (
    <Wrapper view={view}>
      <PieWrapper
        view={view}
        teamDashboard={view === "teamDashboard"}
        style={{ cursor: !view && "pointer" }}
        onClick={() => {
          if (view) {
            return null;
          }
          return history.push("/objectives");
        }}
      >
        <ResponsivePie
          data={info}
          colorBy={(d) => d.color}
          isInteractive={objs && objs.length > 0}
          innerRadius={0.6}
          enableRadialLabels={false}
          enableSlicesLabels={false}
          radialLabel={(d) => `${d.id} ${d.value}`}
          margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        />
        <ChartLabel>
          Total <BigNumber>{objs && objs.length}</BigNumber>
        </ChartLabel>
      </PieWrapper>
      <LabelWrapper view={view} bottomLeft>
        <Item>
          <Number color="#37DB82">{info.length > 0 && info[1].value}</Number>
          <Label>Very Confident</Label>
        </Item>
        <Item>
          <Number color="orange">{info.length > 0 && info[2].value}</Number>
          <Label>Somewhat Confident</Label>
        </Item>
        <Item>
          <Number color="#EC594E">{info.length > 0 && info[0].value}</Number>
          <Label>Not Confident</Label>
        </Item>
      </LabelWrapper>
    </Wrapper>
  );
};

ActiveCompletePie.propTypes = {
  data: PropTypes.array.isRequired,
  view: PropTypes.string.isRequired,
  mobile: PropTypes.bool.isRequired
};

export default ActiveCompletePie;
