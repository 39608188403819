import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  border: ${props => props.bordered && '1px solid #e8e8e8'};
  border-radius: 6px;
  flex-direction: ${props => props.row ? 'row' : 'column'};
  padding: ${props => {
  if (props.padded) {
    if (props.padding) {
      return `${props.padding}px`;
    }
    if (props.small) {
      return '12px';
    }

    return '24px';
  }
  return 0;
}};
`;

export default Wrapper;