import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  overflow-x: auto;
  padding: 0 16px;
`;

export const Header = styled.div`
  width: 100%;
  text-align: center;
`;

export const Legend = styled.div`
  width: 100%:
  display: flex;
  flex-direction: column;
`;

export const LegendItem = styled.div`
  width: 100%;
  display: flex;
`;

export const Stars = styled.div`
  width: 75px;
  display: flex;
`;

export const Header1 = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 12px;
  font-size: 14;
  font-weight: 500;
`;