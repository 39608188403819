import { Highfive, Announcement, Feed as FeedApi, Like, Suggestion } from "services/api";
import { Modal } from "antd";
import { observable, action } from "mobx";
import * as gaEvents from "services/ga_events";
import profileStore from "./profileStore";

class FeedStore {
  @observable feed = [];
  @observable page = 1;
  @observable loading = true;
  @observable hasMore;
  @observable stats = {};
  @observable announcementFormOpen = false;
  @observable sendHighfiveOpen = false;
  @observable suggestionOpen = false;
  @action.bound
  fetch = () => {
    FeedApi.fetch(this.page).then(({ data }) => {
      this.feed = data.feed;
      this.stats = data.stats;
      this.hasMore = data.has_more;
      this.loading = false;
    });
  };

  @action.bound
  setAnnouncementFormOpen = open => (this.announcementFormOpen = open);

  @action.bound
  setSendHighfiveOpen = open => (this.sendHighfiveOpen = open);

  @action.bound
  setSuggestionOpen = open => (this.suggestionOpen = open);

  @action.bound
  addComment = (id, comment) => {
    this.feed = this.feed.map(f => {
      if (f.id === id) {
        return { ...f, comments: [...f.comments, comment] };
      }
      return f;
    });
  };

  @action.bound
  fetchAllComments = id => {
    Highfive.fetchComments(id).then(({ data }) => {
      this.feed = this.feed.map(f => {
        if (f.id === id) {
          return { ...f, comments: data };
        }
        return f;
      });
    });
  };

  @action.bound
  loadMore = () => {
    this.page += 1;
    this.loading = true;
    gaEvents.scrollFeed();
    FeedApi.fetch(this.page).then(({ data }) => {
      this.feed = this.feed.concat(data.feed);
      this.hasMore = data.has_more;
      this.loading = false;
    });
  };

  @action.bound
  createHighfive = values => {
    gaEvents.sendHighfive();
    Highfive.create(values).then(({ data }) => {
      this.feed = data.concat(this.feed);
      this.sendHighfiveOpen = false;
    });
  };

  @action.bound
  createSuggestion = values => {
    Suggestion.create(values).then(() => (this.suggestionOpen = false));
  };

  @action.bound
  createAnnouncement = values => {
    gaEvents.makeAnnouncement();
    Announcement.create(values).then(({ data }) => {
      this.feed = [data].concat(this.feed);
      this.announcementFormOpen = false;
    });
  };

  @action.bound
  updateHighfive = highfive => {
    this.feed = this.feed.map(f => {
      if (f.id === highfive.id) {
        return highfive;
      }
      return f;
    });
  };

  @action.bound
  onLike = (id, type, item = null) => {
    let highFive;
    const { currentUser } = profileStore;
    if (item) {
      highFive = item;
    } else {
      highFive = this.feed.find(feedItem => feedItem.id === id);
    }

    if (highFive && highFive.has_liked) {
      // highFive.has_liked = false;
      highFive.likes = highFive.likes.filter(l => l.user_id !== currentUser.id);
      Like.delete(id);
    } else if (highFive) {
      gaEvents.likePost(type);
      // highFive.has_liked = true;
      Like.create({ likeable_id: id, likeable_type: type }).then(({ data }) => {
        highFive.likes.unshift(data);
      });
    } else {
      Like.create({ likeable_id: id, likeable_type: type });
    }
  };

  @action.bound
  addLikeToComment = (commentId, commentableId, data) => {
    this.feed = this.feed.map(f => {
      if (f.id === commentableId) {
        return {
          ...f,
          comments: f.comments.map(c =>
            c.id === commentId ? { ...c, likes: [...c.likes, data] } : c
          )
        };
      }
      return f;
    });
  };
  @action.bound
  removeLikeFromComment = (commentId, commentableId, likeId) => {
    this.feed = this.feed.map(f => {
      if (f.id === commentableId) {
        return {
          ...f,
          comments: f.comments.map(c =>
            c.id === commentId ? { ...c, likes: c.likes.filter(l => l.id !== likeId) } : c
          )
        };
      }
      return f;
    });
  };

  @action.bound
  deleteComment = (id, commentableId) => {
    this.feed = this.feed.map(f => {
      if (f.id === commentableId) {
        return { ...f, comments: f.comments.filter(c => c.id !== id) };
      }
      return f;
    });
  };

  @action.bound
  handleDelete = (id, type) => {
    Modal.confirm({
      title: `Are you sure you want to delete this ${type}?`,
      content: "This action cannot be undone",
      onOk: () => {
        if (type === "high-five") {
          this.feed = this.feed.filter(item => item.id !== id);
          Highfive.delete(id);
        } else {
          this.feed = this.feed.filter(item => item.id !== id);
          Announcement.delete(id);
        }
      },
      onCancel() {}
    });
  };
}

export default new FeedStore();
