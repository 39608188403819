import React from "react";
import Box from "layout/Box";
import ManagerUserProfile from "containers/ManagerUserProfile";
import viewModal from "components/FullScreenModal";
import { IFetchManagerDashboardQuery } from "../fetchManagerDashboard.generated";
import UserRow from "./UserRow";

interface Props {
  users: IFetchManagerDashboardQuery["team"]["users"];
  id: string;
}

const ManagedUsers: React.FC<Props> = ({ users, id }) => {
  const openUserModal = (userId: string): void => {
    viewModal(
      {
        domId: "user-modal-wrapper",
        skipDestroy: true,
        width: "90vw",
        bodyStyle: { padding: 0 }
        // onClose: () => (this.inNetworkSetup = false)
      },
      null,
      <ManagerUserProfile id={userId} />
    );
  };

  return (
    <Box
      id={id}
      display="flex"
      flexDirection="column"
      backgroundColor="white"
      borderRadius="6px"
      padding="24px 12px"
      boxShadow="rgba(0,0,0,0.06) 0px 2px 4px 0px"
      border="1px solid #e8e8e8"
    >
      <table style={{ width: "100%" }}>
        <tr>
          <th
            style={{
              textAlign: "center",
              paddingBottom: 12,
              color: "rgba(0, 0, 0, 0.8)",
              fontWeight: "normal",
              fontSize: 18
            }}
          ></th>
          <th
            style={{
              textAlign: "center",
              paddingBottom: 12,
              color: "rgba(0, 0, 0, 0.8)",
              paddingLeft: 12,
              fontWeight: "normal",
              fontSize: 18
            }}
          >
            Habits
          </th>
          <th
            style={{
              textAlign: "center",
              paddingBottom: 12,
              color: "rgba(0, 0, 0, 0.8)",
              fontWeight: "normal",
              fontSize: 18
            }}
          >
            4 Week Trend
          </th>
          <th
            style={{
              textAlign: "center",
              paddingBottom: 12,
              color: "rgba(0, 0, 0, 0.8)",
              fontWeight: "normal",
              fontSize: 18
            }}
          >
            Strongest Habit
          </th>
          <th
            style={{
              textAlign: "center",
              paddingBottom: 12,
              color: "rgba(0, 0, 0, 0.8)",
              fontWeight: "normal",
              fontSize: 18
            }}
          >
            Weakest Habit
          </th>
          <th
            style={{
              textAlign: "center",
              paddingBottom: 12,
              color: "rgba(0, 0, 0, 0.8)",
              fontWeight: "normal",
              fontSize: 18
            }}
          >
            Last Response
          </th>
        </tr>
        {users.map((user) => (
          <UserRow
            key={user.id}
            user={user}
            profile={false}
            openUserModal={openUserModal}
          />
        ))}
      </table>
    </Box>
  );
};

export default ManagedUsers;
