import React from "react";
import PropTypes from "prop-types";
import Knowledge from "assets/dashboard/knowledge_base.svg";
import WidgetHeader from "../WidgetHeader";

import { WidgetContainer } from "../styles";
import { OverflowContainer } from "./styles";

const TipWidget = ({ w, overflow, transition, font }) => {
  return (
    <WidgetContainer id={w.id}>
      <WidgetHeader
        font={font}
        overflow={overflow}
        desc={w.content}
        title={w.title}
        icon={
          <img
            alt="Knowledge Img"
            src={Knowledge}
            style={{ width: "55px", height: "auto" }}
          />
        }
      />
      {overflow && (
        <OverflowContainer font={font}>
          <span
            role="presentation"
            onClick={() => {
              transition();
            }}
            style={{
              color: "#00a74f",
              cursor: "pointer",
              fontSize: font.small
            }}
          >
            Read More
          </span>
        </OverflowContainer>
      )}
    </WidgetContainer>
  );
};

TipWidget.propTypes = {
  font: PropTypes.object.isRequired,

  w: PropTypes.object.isRequired,
  overflow: PropTypes.bool.isRequired,
  transition: PropTypes.func.isRequired
};

export default TipWidget;
