import styled, { css } from "styled-components";
import { Col } from "antd";

export const AvatarWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;

export const AvatarImg = styled.img`
  position: absolute;
  ${props => props.widthIsHigher ? css`
          width: auto;
          height: 60px;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        ` : css`
          width: 60px;
          height: auto;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        `}
`;

export const Column = styled(Col).attrs({ span: 24 })`
  display:flex;
  justify-content:center;
  margin-bottom:12px;
`;