import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Select, Spin, Empty } from "antd";
import { User, ExternalUsers, SuperAdmin, Admin } from "services/api";
import useStores from "utils/useStores";
import UserAvatar from "components/UserAvatar";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const UserSearch = ({
  onSelect,
  currentUserId,
  mode,
  showArrow,
  initialValue,
  id,
  exclusionList = [],
  hideList,
  external,
  filterMethod,
  labelInValue,
  filterSelf,
  value,
  superAdmin,
  disabled,
  orgId,
  width,
  alreadySelectedMessage,
  asAdmin
}) => {
  const { profileStore } = useStores();
  const [fetching, setFetching] = useState(false);
  const [users, setUsers] = useState(
    Array.isArray(initialValue) ? initialValue : [initialValue]
  );
  const [query, setQuery] = useState("");
  const [timeoutId, setTimeoutId] = useState("");
  const currentUser = currentUserId || profileStore.currentUser.id;
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      if (query.length > 0) {
        if (!fetching) {
          setFetching(true);
        }
        setUsers([]);
        clearTimeout(timeoutId);
        setTimeoutId(
          setTimeout(() => {
            if (superAdmin) {
              const api = new SuperAdmin(orgId);
              api.fetchUsers(1, 10, `name_search=${query}`).then(({ data }) => {
                setUsers(data.users);
                setFetching(false);
              });
            } else if (asAdmin) {
              const api = Admin;
              api.fetchUsers(1, 10, `name_search=${query}`).then(({ data }) => {
                setUsers(data.users);
                setFetching(false);
              });
            } else {
              const api = external ? ExternalUsers : User;
              api.all(query).then(({ data }) => {
                setUsers(data);
                setFetching(false);
              });
            }
          }, 200)
        );
      } else {
        clearTimeout(timeoutId);
        setUsers([]);
        setFetching(false);
      }
    };

    fetchData();
  }, [query]);

  useEffect(() => {
    return (): void => {
      setUsers([]);
    };
  }, []);

  let notFoundContent;
  if (fetching && query) {
    notFoundContent = <Spin size="small" />;
  } else if (query) {
    notFoundContent = <Empty />;
  } else {
    notFoundContent = null;
  }
  if (mode === "multiple") {
    const options = users
      .filter(filterMethod)
      .filter((u) => !(filterSelf && u.id === currentUser))
      .map((u) => (
        <Option
          disabled={hideList.includes(u.id)}
          key={u.id || u.key}
          label={u.name || u.label}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <UserAvatar avatar={u.avatar} name={u.name} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: 12
              }}
            >
              <span>
                {" "}
                {u.name || u.label}{" "}
                {hideList.includes(u.id) && alreadySelectedMessage}
              </span>
              <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>{u.email}</span>
            </div>
          </div>
        </Option>
      ));

    console.log(options);

    return (
      <Select
        labelInValue={labelInValue}
        id={id}
        mode={mode}
        value={value}
        optionLabelProp="label"
        placeholder={t("global.search_users")}
        notFoundContent={notFoundContent}
        filterOption={false}
        onSearch={setQuery} // defaultValue={initialValue.map((u) => u.id)}
        showArrow={showArrow}
        onChange={onSelect}
        style={{ width: "100%" }}
      >
        {options}
      </Select>
    );
  }
  const options = users
    .filter(
      (u) => !(filterSelf && u.id === currentUser) && !hideList.includes(u.id)
    )
    .filter(filterMethod)
    .map((u) => <Option key={u.id || u.key}>{u.name || u.label}</Option>);
  return (
    <Select
      size="large"
      showSearch
      value={value || initialValue}
      disabled={disabled}
      id={id}
      labelInValue={labelInValue}
      placeholder={t("global.search_users")}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      autoClearSearchValue
      onSearch={setQuery}
      onChange={onSelect}
      style={{ width }}
      notFoundContent={notFoundContent}
    >
      {options}
    </Select>
  );
};

UserSearch.propTypes = {
  onSelect: PropTypes.func.isRequired,
  mode: PropTypes.string,
  currentUserId: PropTypes.string,
  alreadySelectedMessage: PropTypes.string,
  showArrow: PropTypes.bool,
  value: PropTypes.array,
  initialValue: PropTypes.array,
  hideList: PropTypes.array,
  external: PropTypes.bool,
  filterMethod: PropTypes.func,
  filterSelf: PropTypes.bool,
  labelInValue: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.number
};

UserSearch.defaultProps = {
  filterSelf: true,
  width: 200,
  alreadySelectedMessage: "(Already Selected)",
  currentUserId: "",
  mode: "multiple",
  showArrow: true,
  disabled: false,
  labelInValue: false,
  filterMethod: (u) => u,
  initialValue: [],
  value: [],
  hideList: [],
  external: false
};

export default UserSearch;
