import React, { useState, useEffect } from "react";
import { Modal, Input } from "antd";
import PropTypes from "prop-types";

const CategoryForm = ({ handleSubmit, handleCancel, editing, visible }) => {
  const [name, setName] = useState(editing ? editing.name : "");

  useEffect(() => {
    if (editing) {
      setName(editing.name);
    } else {
      setName("");
    }
  }, [editing]);
  useEffect(() => {
    if (!visible) {
      setName("");
    }
  }, [visible]);
  return (
    <Modal
      title={editing ? "Edit Category" : "Create a Category"}
      visible={visible}
      onCancel={handleCancel}
      okText={editing ? "Save" : "Create"}
      onOk={() => handleSubmit(name)}
    >
      <Input
        value={name}
        placeholder="Enter Category Name..."
        onChange={(e) => setName(e.target.value)}
      />
    </Modal>
  );
};

CategoryForm.defaultProps = {
  editing: null,
  visible: false
};

CategoryForm.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  editing: PropTypes.object,
  visible: PropTypes.bool
};

export default CategoryForm;
