import React from "react";

import { observer, inject } from "mobx-react";
import { observable, action } from "mobx";
import PropTypes from "prop-types";
import { User, Admin } from "services/api";
import history from "services/history";

import UserForm from "components/UserForm";
import NetworkSetup from "containers/NetworkSetup";
import Relationships from "containers/Relationships";
import SendHighfive from "components/SendHighfive";
import viewModal from "components/FullScreenModal";
import LoadingOverlay from "components/LoadingOverlay";
import UserHeader from "./UserHeader";
import Actions from "./Actions";
import Habits from "./HabitsCopy";

import Notes from "./Notes";
import { Body, RightSide } from "./styles";

const NetworkModal = React.lazy(() => import("components/NetworkModal"));

@inject("profileStore", "organizationStore", "feedStore")
@observer
class UserModal extends React.Component {
  @observable user = null;
  @observable users = [];
  // @observable loading = true
  @observable userFormOpen = false;
  @observable networkModalOpen = false;
  @observable sendHighfiveOpen = false;
  @observable feedbackNetworkOpen = false;
  @observable assignGoalOpen = false;
  @observable adminView = false;

  @action async fetchUser() {
    const { id, toggleLoading } = this.props;
    User.single(id).then(({ data }) => {
      this.user = data;
      this.adminView = data.admin_view;
      toggleLoading();
    });
  }

  componentWillMount() {
    this.fetchUser();
  }

  closeNetworkSetup = () => {
    this.networkModalOpen = false;
  };

  openSendHighfive = () => {
    this.sendHighfiveOpen = true;
  };

  viewFeedbackNetwork = () => {
    this.feedbackNetworkOpen = true;
  };

  openAssignGoal = () => {
    this.assignGoalOpen = true;
  };

  closeSendHighfive = (data) => {
    const { feedStore } = this.props;
    this.sendHighfiveOpen = false;
    if (data) {
      this.user.highfives.highfives = [
        ...data,
        ...this.user.highfives.highfives
      ];

      feedStore.feed = [...data, ...feedStore.feed];
    }
  };

  addCommentToHighfive = (comment, highfive) => {
    this.user.highfives.highfives = this.user.highfives.highfives.map((h) => {
      if (h.id === highfive.id) {
        return { ...h, comments: [...h.comments, comment] };
      }
      return h;
    });
  };

  removeLikeFromComment = (commentId, likeId, highfiveId) => {
    this.user.highfives.highfives = this.user.highfives.highfives.map((h) => {
      if (h.id === highfiveId) {
        return {
          ...h,
          comments: h.comments.map((c) => {
            if (c.id === commentId) {
              return { ...c, likes: c.likes.filter((l) => l.id !== likeId) };
            }
            return c;
          })
        };
      }
      return h;
    });
  };
  addLikeToComment = (commentId, like, highfiveId) => {
    this.user.highfives.highfives = this.user.highfives.highfives.map((h) => {
      if (h.id === highfiveId) {
        return {
          ...h,
          comments: h.comments.map((c) => {
            if (c.id === commentId) {
              return { ...c, likes: [...c.likes, like] };
            }
            return c;
          })
        };
      }
      return h;
    });
  };

  removeCommentFromHighfive = (commentId, highfive) => {
    this.user.highfives.highfives = this.user.highfives.highfives.map((h) => {
      if (h.id === highfive.id) {
        return {
          ...h,
          comments: h.comments.filter((c) => c.id !== commentId)
        };
      }
      return h;
    });
  };

  submitChanges = () => {
    const { toggleLoading } = this.props;
    toggleLoading();
    this.fetchUser();
    this.closeNetworkSetup();
  };

  updateUserActivity = () => {
    const { toggleLoading } = this.props;
    Admin.updateUser(this.user.id, { active: !this.user.active }).then(() => {
      toggleLoading();
      this.fetchUser();
    });
  };

  openUserForm = () => {
    Admin.fetchUser(this.user.id).then((res) => {
      this.editingUser = res.data;
      this.userFormOpen = true;
    });
  };

  openRelationships = () => {
    viewModal(
      {
        domId: "relationship-modal-wrapper",
        skipDestroy: true,
        width: 900,
        onClose: () => (this.inNetworkSetup = false)
      },
      history,
      <Relationships managedUser={this.user} />
    );
  };

  handleEditUser = (values) => {
    const fixedValues = values;
    const admin = values.level === "admin";
    const { toggleLoading } = this.props;
    const manager = values.level === "manager";
    delete fixedValues.level;
    fixedValues.admin = admin;
    fixedValues.manager = manager;

    Admin.updateUser(this.editingUser.id, fixedValues).then(() => {
      this.editingUser = null;
      this.userFormOpen = false;
      toggleLoading();
      this.fetchUser();
    });
  };

  render() {
    const {
      loading,
      goToReports,
      goToUser,
      goToTeam,
      currentUserManagerOnlyId,
      currentUserManagerOnlyManagedTeamId,
      profileStore,
      organizationStore
    } = this.props;

    // const adminView =
    //   this.user &&
    //   (profileStore.currentUser.admin ||
    //     profileStore.currentUser.id === this.user.manager_id);
    let actions = [];
    if (this.user && this.adminView) {
      actions = [
        "reports",
        "networkHealth",
        "feedbackNetwork",
        "highfive",
        "assignGoal",
        "networkHealth",
        "updateUser"
      ];
    } else {
      actions = ["feedbackNetwork", "highfive"];
    }
    if (this.user && this.user.id === profileStore.currentUser.id) {
      actions = actions.filter((a) => a !== "highfive");
    }
    let modalBody;
    if (this.user && this.adminView) {
      modalBody = (
        <div>
          <UserHeader
            adminView
            user={this.user}
            goToTeam={goToTeam}
            goToUser={goToUser}
            currentUser={profileStore.currentUser}
            currentUserManagerOnlyId={currentUserManagerOnlyId}
            currentUserManagerOnlyManagedTeamId={
              currentUserManagerOnlyManagedTeamId
            }
          />
          <Actions
            actions={actions}
            goToReports={goToReports}
            networkHealth={this.user.network_health}
            viewFeedbackNetwork={this.viewFeedbackNetwork}
            openSendHighfive={this.openSendHighfive}
            openAssignGoal={this.openAssignGoal}
            openNetworkSetup={this.openRelationships}
            userIsActive={this.user.active}
            updateUserActivity={this.updateUserActivity}
            openEditUser={this.openUserForm}
            currentUserIsAdmin={profileStore.currentUser.admin}
          />
          <Body>
            {profileStore.currentUser.organization_name !== "brandseven" && (
              <Habits
                habits={this.user.habits}
                userId={this.user.id}
                goalsActive={profileStore.currentUser.organization.features.includes(
                  "GOALS"
                )}
                archivedHabits={this.user.archived_habits}
                color={profileStore.theme.primary_color}
              />
            )}
            <RightSide>
              <Notes
                userId={this.user.id}
                notes={this.user.notes}
                highfives={this.user.highfives.highfives}
                currentUser={profileStore.currentUser}
              />
            </RightSide>
          </Body>

          {this.networkModalOpen && (
            <NetworkSetup
              user={this.user}
              networkHealth={this.user.network_health}
              onClose={this.closeNetworkSetup}
              submitChanges={this.submitChanges}
              teamHabits={this.user.team_habits}
            />
          )}
          {this.sendHighfiveOpen && (
            <SendHighfive
              onCancel={() => (this.sendHighfiveOpen = false)}
              visible={this.sendHighfiveOpen}
              selectedUsers={[this.user]}
              users={this.users}
              onClose={this.closeSendHighfive}
            />
          )}
          {this.feedbackNetworkOpen && (
            <NetworkModal
              open
              userName={this.user.name}
              userId={this.user.id}
              onOk={() => (this.feedbackNetworkOpen = false)}
              onCancel={() => (this.feedbackNetworkOpen = false)}
            />
          )}
          {profileStore.currentUser.admin && (
            <UserForm
              organization={organizationStore.organization}
              roles={organizationStore.organization.roles}
              open={this.userFormOpen}
              editingUser={this.editingUser}
              onCancel={() => {
                this.userFormOpen = false;
                this.editingUser = null;
              }}
              onOk={this.handleEditUser}
            />
          )}
        </div>
      );
    } else if (this.user) {
      modalBody = (
        <div>
          <UserHeader
            user={this.user}
            goToTeam={goToTeam}
            goToUser={goToUser}
            currentUserManagerOnlyId={currentUserManagerOnlyId}
            currentUserManagerOnlyManagedTeamId={
              currentUserManagerOnlyManagedTeamId
            }
          />
          <Actions
            actions={actions}
            goToReports={goToReports}
            viewFeedbackNetwork={this.viewFeedbackNetwork}
            openSendHighfive={this.openSendHighfive}
            openAssignGoal={this.openAssignGoal}
            userIsActive={this.user.active}
            updateUserActivity={this.updateUserActivity}
            openEditUser={this.openUserForm}
            currentUserIsAdmin={profileStore.currentUser.admin}
          />
          <Body>
            <Notes
              userId={this.user.id}
              addCommentToHighfive={this.addCommentToHighfive}
              removeCommentFromHighfive={this.removeCommentFromHighfive}
              addLikeToComment={this.addLikeToComment}
              removeLikeFromComment={this.removeLikeFromComment}
              highfives={this.user.highfives.highfives}
              currentUser={profileStore.currentUser}
            />
          </Body>

          {this.networkModalOpen && (
            <NetworkSetup
              user={this.user}
              networkHealth={this.user.network_health}
              onClose={this.closeNetworkSetup}
              submitChanges={this.submitChanges}
              teamHabits={this.user.team_habits}
            />
          )}
          {this.sendHighfiveOpen && (
            <SendHighfive
              onCancel={() => (this.sendHighfiveOpen = false)}
              visible={this.sendHighfiveOpen}
              selectedUsers={[this.user]}
              users={this.users}
              onClose={this.closeSendHighfive}
            />
          )}
          {this.feedbackNetworkOpen && (
            <NetworkModal
              open
              userName={this.user.name}
              userId={this.user.id}
              onOk={() => (this.feedbackNetworkOpen = false)}
              onCancel={() => (this.feedbackNetworkOpen = false)}
            />
          )}
          {profileStore.currentUser.admin && (
            <UserForm
              organization={organizationStore.organization}
              roles={organizationStore.organization.roles}
              open={this.userFormOpen}
              editingUser={this.editingUser}
              onCancel={() => {
                this.userFormOpen = false;
                this.editingUser = null;
              }}
              onOk={this.handleEditUser}
            />
          )}
        </div>
      );
    }

    return (
      <div>
        {loading || (
          <React.Suspense fallback={<LoadingOverlay />}>
            {modalBody}
          </React.Suspense>
        )}
      </div>
    );
  }
}

UserModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  goToReports: PropTypes.func.isRequired,
  goToUser: PropTypes.func.isRequired,
  goToTeam: PropTypes.func.isRequired,
  toggleLoading: PropTypes.func.isRequired,
  currentUserManagerOnlyId: PropTypes.bool.isRequired,
  currentUserManagerOnlyManagedTeamId: PropTypes.bool.isRequired,
  profileStore: PropTypes.any.isRequired,
  feedStore: PropTypes.any.isRequired,
  organizationStore: PropTypes.any.isRequired
};

export default UserModal;
