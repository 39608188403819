import React from "react";
import Proptypes from "prop-types";
import { Layout, Avatar, Icon } from "antd";
import Art from "assets/Pittsburgh_end.svg";
import logo from "assets/logo_black.png";
import nameToInitials from "utils/nameToInitials";
import { Route, Switch } from "react-router-dom";
import DeepDive from "containers/DeepDiveV2";

const { Header } = Layout;
const DashboardViewer = ({ profileStore }) => {
  const mobile = window.innerWidth < 500;
  const name =
    window.innerWidth < 650
      ? profileStore.currentUser.user.first_name
      : profileStore.currentUser.user.name;
  const logout = mobile ? (
    <Icon
      onClick={profileStore.logout}
      type="logout"
      style={{ color: "rgb(1, 167, 80)", fontSize: "24px" }}
      height={10}
    />
  ) : (
    /* eslint-disable */
    <a
      style={{
        fontSize: "20px",
        height: "auto",
        display: "inline-block"
      }}
      role="presentation"
      onClick={profileStore.logout}
    >
      logout
    </a>
  );
  /* eslint-enable */
  return (
    <div>
      <Layout
        style={{
          // minHeight: window.innerWidth > 500 && '100vh',
          position: "relative",
          zIndex: 1
        }}
      >
        <img
          alt="pittsburgh"
          src={Art}
          style={{
            position: "fixed",
            bottom: 0,
            right: !mobile && 0,
            width: mobile ? "100%" : 800,
            height: "auto",
            zIndex: -1
          }}
        />
        <Header
          style={{
            boxShadow: "0 1px 4px rgba(0,21,41,0.08)",
            padding: mobile && "0"
          }}
        >
          <div
            style={{
              width: "100%",
              padding: mobile ? "0% 5%" : "0% 1.75%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <img
              src={logo}
              alt="rhabit"
              style={{
                width: "auto",
                height: mobile ? "20px" : "40px"
              }}
            />
            <div style={{ height: "auto", dipslay: "flex" }}>
              <Avatar size="medium" src={profileStore.currentUser.user.avatar}>
                {nameToInitials(profileStore.currentUser.user.name)}
              </Avatar>
              <span
                style={{
                  height: "100%",
                  marginLeft: "10px",
                  fontSize: "20px"
                }}
              >
                {name}&apos;s Dashboard
              </span>
            </div>
            {logout}
          </div>
        </Header>
        <Switch>
          <Route exact path="/habits/:id" component={DeepDive} />
          <Route path="/*" component={DeepDive} />
        </Switch>
      </Layout>
    </div>
  );
};

DashboardViewer.propTypes = {
  profileStore: Proptypes.object.isRequired
};

export default DashboardViewer;
