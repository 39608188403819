import React from "react";
import styled from "styled-components";

type Props = {
  img: string;
  link: string;
  text: string;
  style?: Record<string, any>;
  noMask?: boolean;
};

const Container = styled.div`
  height: 175px;
  padding: 12px;
  width: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  border-radius: 6px;
  background-image: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.img
      ? !props.noMask
        ? `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${props.img})`
        : `linear-gradient( rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) ), url(${props.img})`
      : null};
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: ${(props) =>
      !props.noMask &&
      `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));`};
    z-index: 2;
    transition: opacity 0.2s linear;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }
`;

const Text = styled.span`
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
`;

const LessonTile = ({ img, link, text, style, noMask }: Props) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <Container img={img} style={style} noMask={noMask !== undefined}>
      <Text>{text}</Text>
    </Container>
  </a>
);

export default LessonTile;
