import React, { useEffect, useRef, useState } from "react";
import Box from "layout/Box";
import LoadingOverlay from "components/LoadingOverlay";
import * as d3 from "d3";
import { ResponsiveBar } from "@nivo/bar";
import { Divider, Button } from "antd";
import { ResponsivePie } from "@nivo/pie";
import { withTheme } from "styled-components";
import {
  motion,
  AnimatePresence,
  useInvertedScale,
  useMotionValue
} from "framer-motion";
import { renderChart, destroyChart } from "./gauge";
import CompanyComparison from "./CompanyComparison";
import { useFetchEngagementTrendQuery } from "./fetchEngagementTrend.generated";
import useStores from "utils/useStores";
import { useFetchEngagementTrendDemoQuery } from "./fetchEngagementTrendDemo.generated";

type Datum = {
  x: string;
  y: string;
};

const EngagementTrend: React.FC<{ theme: any, managedTeamId: string | null }> = ({ theme, managedTeamId }) => {
  const {
    profileStore: { currentUser }
  } = useStores();

  const [expanded, setExpanded] = useState(false);
  const result = useFetchEngagementTrendQuery({
    variables: {
      id: managedTeamId
    },
    skip:
      currentUser.organization.name === "Meta Co" ||
      currentUser.organization.name === "Hopp Demo"
  });
  const demoResult = useFetchEngagementTrendDemoQuery({
    skip:
      currentUser.organization.name !== "Meta Co" &&
      currentUser.organization.name !== "Hopp Demo"
  });
  const scaleX = useMotionValue(1);
  const scaleY = useMotionValue(1);
  const inverted = useInvertedScale({ scaleX, scaleY });

  let data;
  let loading;
  let error;
  if (
    currentUser.organization.name === "Meta Co" ||
    currentUser.organization.name === "Hopp Demo"
  ) {
    data = demoResult.data;
    loading = demoResult.loading;
    error = demoResult.error;
  } else {
    data = result.data;
    loading = result.loading;
    error = result.error;
  }

  useEffect(() => {
    if (!loading && !error && data) {
      const fixedData: {
        name: string;
        recent: any;
        past: any;
        overall: any;
      }[] = [];
      Object.keys(data.managerDashboard.engagementRecent).map((key) => {
        fixedData.push({
          name: key,
          recent: data.managerDashboard.engagementRecent[key],
          past: data.managerDashboard.engagementPast[key],
          overall: data.managerDashboard.engagementOverall[key]
        });
      });
      fixedData.forEach((fixed) => {
        renderChart(document.getElementById(fixed.name), {
          primaryColor: theme.primary_color,
          secondaryColor: theme.secondary_color,
          minValue: 0,
          maxValue: 100,
          curValue: Math.round(fixed.recent * 100),
          pastValue: Math.round(fixed.past * 100),
          overallValue: Math.round(fixed.overall * 100),
          height: `${100 / fixedData.length}%`
        });
      });

      d3.select("#symbol-triangle")
        .append("path")
        .attr("fill", theme.secondary_color)
        .attr("stroke", "black")
        .attr(
          "d",
          d3
            .symbol()
            .type(d3.symbolTriangle)
            .size(100)
        );
      d3.select("#symbol-diamond")
        .append("path")
        .attr("fill", theme.primary_color)
        .attr("stroke", "black")
        .attr(
          "d",
          d3
            .symbol()
            .type(d3.symbolDiamond)
            .size(100)
        );

      // renderChart(document.getElementById("chart-gauge"), {
      //   minValue: 0,
      //   maxValue: 100,
      // 	curValue: data.managerDashboard.engagementPast,
      // });
    }
  }, [loading, error, managedTeamId]);

  debugger;

  if (loading || !data) {
    return (
      <Box height="250px" width="100%">
        <LoadingOverlay />
      </Box>
    );
  }
  if (error) {
    return (
      <Box height="250px" width="100%">
        Error loading engagement trend.
      </Box>
    );
  }

  const graphData = [];
    if (data.managerDashboard.engagementPast) {
    graphData.push({
      id: "Last 90 Days Ending Today",
      Low: Math.round(data.managerDashboard.engagementPast.weak * 100),
      Average: Math.round(data.managerDashboard.engagementPast.average * 100),
      High: Math.round(data.managerDashboard.engagementPast.strong * 100)
    });
  }
  if (data.managerDashboard.engagementRecent) {
    graphData.push({
      id: "Previous 90 Days",
      Low: Math.round(data.managerDashboard.engagementRecent.weak * 100),
      Average: Math.round(data.managerDashboard.engagementRecent.average * 100),
      High: Math.round(data.managerDashboard.engagementRecent.strong * 100)
    });
  }
  if (data.managerDashboard.engagementOverall) {
    graphData.push({
      id: "Company Sentiment",
      Low: Math.round(data.managerDashboard.engagementOverall.weak * 100),
      Average: Math.round(
        data.managerDashboard.engagementOverall.average * 100
      ),
      High: Math.round(data.managerDashboard.engagementOverall.strong * 100)
    });
  
  }
  return (
    // <motion.div layoutTransition>
    <Box
      id="engagement-trend"
      display="flex"
      flexWrap="wrap"
      padding="24px"
      justifyContent="center"
      margin="0 auto"
      width="100%"
      backgroundColor="white"
      borderRadius="6px"
      overflow="hidden"
      boxShadow="rgba(0,0,0,0.06) 0px 2px 4px 0px"
      border="1px solid #e8e8e8"
    >
      <Box
        color="rgba(0, 0, 0, 0.8)"
        fontSize="18px"
        width="100%"
        textAlign="start"
      >
        How your employees feel overall about the team
      </Box>
      <motion.div style={{ width: "100%", ...inverted }}>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-around"
          mb="24px"
          flexWrap="wrap"
        >
          {data.managerDashboard &&
            Object.keys(data.managerDashboard.engagementRecent).map((key) => (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box>
                  <div
                    id={key}
                    style={{
                      width: `${100 /
                        Object.keys(data.managerDashboard.engagementRecent)
                          .length}%`
                    }}
                  />
                </Box>
                <Box
                  fontSize="18px"
                  color="rgba(0, 0, 0, 0.8)"
                  textAlign="center"
                >
                  {key} (
                  {Math.round(
                    data.managerDashboard.engagementRecent[key] * 100
                  )}
                  %)
                </Box>
              </Box>
            ))}
        </Box>
        <Box display="flex" width="100%" mt="-12px" mb="12px">
          <Box display="flex" alignItems="center" lineHeight="50px" mr="36px">
            <svg height="32" width="32">
              <g
                style={{ transform: "translate(50%, 56%)" }}
                id="symbol-triangle"
              ></g>
            </svg>
            Your Team 90 Days Ago
          </Box>
          <Box display="flex" alignItems="center">
            <svg height="32" width="32">
              <g
                style={{ transform: "translate(50%, 50%)" }}
                id="symbol-diamond"
              ></g>
            </svg>
            Company Average
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          textAlign="start"
          width="100%"
          mb="24px"
          border="1px solid #e8e8e8"
          borderRadius="6px"
          backgroundColor="#e8e8e8"
          padding="12px 24px"
        >
          <Box fontSize="18px" color="rgba(0, 0, 0, 0.8)" mb="12px">
            What your team is telling you:
          </Box>
          <Box display="flex" flexDirection="column" fontSize="16px">
            <Box display="flex" alignItems="center" mb="12px">
              <span
                role="img"
                aria-label="celebration"
                style={{ fontSize: 36, color: "rgba(0, 0, 0, 1)" }}
              >
                🎉
              </span>
              <div style={{ marginLeft: 12, color: "rgba(0, 0, 0, 0.8)" }}>
                {data.managerDashboard.engagementMinMax.max ||
                  "Not enough data yet!"}
              </div>
            </Box>
            <Box display="flex" alignItems="center" mb="12px">
              <span
                role="img"
                aria-label="worry"
                style={{ fontSize: 36, color: "rgba(0, 0, 0, 1)" }}
              >
                😨
              </span>
              <div style={{ marginLeft: 12, color: "rgba(0, 0, 0, 0.8)" }}>
                {data.managerDashboard.engagementMinMax.min ||
                  "Not enough data yet!"}
              </div>
            </Box>
            {data.managerDashboard.highestLowestDeltas.decrease && (
              <Box display="flex" alignItems="center" mb="12px">
                <span
                  role="img"
                  aria-label="thumbs-down"
                  style={{ fontSize: 36, color: "rgba(0, 0, 0, 1)" }}
                >
                  👎
                </span>
                <div style={{ marginLeft: 12, color: "rgba(0, 0, 0, 0.8)" }}>
                  You&apos;re getting more negative feedback regarding &quot;
                  {data.managerDashboard.highestLowestDeltas.decrease.name}
                  &quot; than usual (vs last 90 days)
                </div>
              </Box>
            )}
            {data.managerDashboard.highestLowestDeltas.increase && (
              <Box display="flex" alignItems="center">
                <span
                  role="img"
                  aria-label="thumbs-up"
                  style={{ fontSize: 36, color: "rgba(0, 0, 0, 1)" }}
                >
                  👍
                </span>
                <div style={{ marginLeft: 12, color: "rgba(0, 0, 0, 0.8)" }}>
                  You&apos;re getting more positive feedback regarding &quot;
                  {data.managerDashboard.highestLowestDeltas.increase.name}
                  &quot; than usual (vs last 90 days)
                </div>
              </Box>
            )}
          </Box>
        </Box>
      </motion.div>

      <Box width="100%" display="flex" justifyContent="center">
        <Button size="large" onClick={(): void => setExpanded(!expanded)}>
          {!expanded ? "View Detailed Breakdown" : "Hide Breakdown"}
        </Button>
      </Box>
      <AnimatePresence initial={false}>
        {expanded && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            style={{ width: "100%" }}
            exit="collapsed"
            variants={{
              open: {
                opacity: 1,
                height: "auto",
                transition: { duration: 1.5, ease: [0.04, 0.04, 0.23, 0.98] }
              },
              collapsed: {
                opacity: 0,
                height: 0,
                transition: { duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }
              }
            }}
          >
            <Box
              fontSize="18px"
              color="rgba(0, 0, 0, 0.8)"
              textAlign="start"
              width="100%"
              mt={24}
            >
              How Your Team Sentiment Compares to Other Teams at Your Company
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              textAlign="start"
              width="100%"
              mt="24px"
            >
              <CompanyComparison
                comparisons={data.managerDashboard.companyComparison}
              />
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
    // </motion.div>
  );
};

export default withTheme(EngagementTrend);
