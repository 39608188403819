import React, { useState } from "react";
import PropTypes from "prop-types";
import VideoPlayer from "components/VideoPlayer";
import PlayButton from "assets/play_button_spring.svg";
import WidgetHeader from "../WidgetHeader";

import { WidgetContainer } from "../styles";

const TipWidget = ({ w, overflow, font }) => {
  const [videoOpen, setVideoOpen] = useState(false);
  const openVideo = () => {
    if (w.type === "VideoWidget") {
      setVideoOpen(true);
    }
  };

  const closeVideo = () => {
    setVideoOpen(false);
  };
  return (
    <>
      {videoOpen && (
        <VideoPlayer title={w.title} onCancel={closeVideo} src={w.video_link} />
      )}
      <WidgetContainer
        id={w.id}
        onClick={() => {
          openVideo();
        }}
      >
        <WidgetHeader
          font={font}
          overflow={overflow}
          desc={w.description}
          title={w.title}
          icon={
            <img
              alt="Play Button"
              src={PlayButton}
              style={{ width: "45px", height: "auto" }}
            />
          }
        />
      </WidgetContainer>
    </>
  );
};

TipWidget.propTypes = {
  font: PropTypes.object.isRequired,
  w: PropTypes.object.isRequired,
  overflow: PropTypes.bool.isRequired
};

export default TipWidget;
