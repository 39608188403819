import styled from "styled-components";
import { Select } from "antd";
import { media } from "utils/style-utils";

const { Option } = Select;

export const HeaderContainer = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: absolute;
  color: rgba(0, 0, 0, 0.85);
  padding: 16px 15px 16px 25px;
  font-weight: 500;
  // font-size: 22px;
  border-bottom: 1px solid rgb(232, 232, 232);
  background-color: white;
  width: 99.7%;
  left: 0.1%;
  line-height: 1.5;
  z-index: 100;
  top: 1px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  height: 58px;
`;

export const BehaviorDot = styled.div`
  height: 37.25px;
  width: 37.25px;
  border-radius: 50%;
  background-color: ${props => props.color()};
  margin-right: 15px;
  margin-left: 15px;
  text-align: center;
  vertical-align: middle;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BehaviorDesc = styled.div`
  font-size: ${({ theme }) => `${theme.font.subheader}px`};
  color: rgba(0, 0, 0, 0.85);
  background-color: none;
  height: auto;
  width: ${props => props.width};
  display: -webkit-box;
  -webkit-line-clamp: ${props => (props.mobile ? "5" : "2")};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const NoWidgetContainer = styled.div`
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${({ theme }) => `${theme.font.header}px`};
  padding: 0px 20px;
  text-align: center;

  ${media.handheld`
    padding: 0;
  `}
`;

export const WidgetContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${({ theme }) => `${theme.font.header}px`};
  height: 75%;
  padding: 0px 20px;
  text-align: center;
`;

export const HeaderTitle = styled.span`
  font-size: ${({ theme }) => theme.font.header}px;
`;

export const RemainingFeedbackContainer = styled.div`
  z-index: 2;
  position: absolute;
  height: 125px;
  width: 100%;
  bottom: 0px;
  background: white;
  border-radius: 6px;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 24px;
`;

export const StyledOption = styled(Option)`
  width: 100px
  white-space: normal;
  .ant-select-dropdown-menu-item {
    white-space: normal;
  }
`;
