import React from "react";
import UserAvatar from "components/UserAvatar";
import Carrot from "assets/carrot.png";
import { Container, TopContainer, UserName, CarrotImg } from "./styles";
import { LeaderboardUser } from ".";

type Props = {
  user: LeaderboardUser;
  total: number;
  hideLeaderboard?: boolean;
};

const marginTop = window.innerWidth < 500 ? 0 : "50px";

const getUserLevel = (user: LeaderboardUser) => {
  if (user.position <= 25) {
    return (
      <div style={{ fontSize: 20, marginTop, color: "rgba(0, 0, 0.8)" }}>
        <span role="img" aria-label="trophy">
          🏆
        </span>{" "}
        You&apos;re a culture champion!
      </div>
    );
  }

  if (user.response_rate >= 0.5) {
    return (
      <div style={{ fontSize: 20, marginTop, color: "rgba(0, 0, 0.8)" }}>
        <span role="img" aria-label="trophy">
          🚀
        </span>{" "}
        You&apos;re a culture supporter!
      </div>
    );
  }
  return (
    <div style={{ fontSize: 20, marginTop, color: "rgba(0, 0, 0.8)" }}>
      <span role="img" aria-label="trophy">
        😔
      </span>{" "}
      You need to step up!
    </div>
  );
};

const UserInfo = ({ user, total, hideLeaderboard }: Props) => (
  <Container style={{ padding: window.innerWidth < 500 && "12px" }}>
    <TopContainer>
      <Container style={{ flexDirection: "row" }}>
        <UserAvatar
          style={{ marginRight: 12 }}
          size={64}
          avatar={user.user.avatar}
          name={user.user.name}
        />
        <TopContainer style={{ flexDirection: "column" }}>
          <UserName>{user.user.name}</UserName>
          <UserName style={{ fontSize: 28 }}>
            {user.feedback_streak}{" "}
            <CarrotImg src={Carrot} large style={{ marginTop: -12 }} />
          </UserName>
        </TopContainer>
      </Container>
      <Container>
        {!hideLeaderboard && (
          <TopContainer style={{ flexDirection: "column" }}>
            <UserName>Rank</UserName>
            <UserName>
              {user.position}/{total}
            </UserName>
          </TopContainer>
        )}
      </Container>
    </TopContainer>
    {!hideLeaderboard && <TopContainer>{getUserLevel(user)}</TopContainer>}
  </Container>
);

export default UserInfo;
