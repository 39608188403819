import styled from "styled-components";
import { Card } from "antd";
import { PageBody } from "components/Layout/styles";
import { media } from "utils/style-utils";

export const HomeCard = styled(Card)`
  width: 100%;
  border-radius: 6px !important;
  position: relative;
  .ant-card-head-wrapper {
    height: 100%;
  }
  ${media.handheld`
    height: auto;
    height: unset;
    min-height: unset;

  `}
`;

export const Fader = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 25px 0;
  z-index: 100;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

  ${media.handheld`
    display: none;
  `}
`;

export const Body = styled(PageBody)`
  background-size: cover;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  ${media.handheld`
    padding: 20px 0 120px 0;
   `}
`;

export const SubTitle = styled.span`
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
  font-weight: bold;
`;