/* eslint-disable no-debugger */
import React from "react";
import PropTypes from "prop-types";
import { Popover } from "antd";
import UserAvatar from "components/UserAvatar";
import { Raters, Mask } from "./styles";

const RatersList = ({ filteredRaters, remainingRaters }) => {
  const mobile = window.linnerWidth < 500;
  return (
    <Raters>
      {filteredRaters &&
        filteredRaters.map((rater, i) => {
          if (i === filteredRaters.length - 1 && remainingRaters.length > 1) {
            return (
              <Popover
                content={remainingRaters.map((rRater, index) => (
                  <UserAvatar
                    style={{ zIndex: index + 1, marginLeft: -8 }}
                    size={mobile ? "small" : "default"}
                    avatar={rRater.avatar}
                    name={rRater.name}
                  />
                ))}
              >
                <div style={{ position: "relative" }}>
                  <Mask>+{remainingRaters.length}</Mask>
                  <UserAvatar
                    style={{ zIndex: i + 1, marginLeft: -8 }}
                    size={mobile ? "small" : "default"}
                    avatar={rater.avatar}
                    name={rater.name}
                  />
                </div>
              </Popover>
            );
          }
          return (
            <UserAvatar
              style={{ zIndex: i + 1, marginLeft: -8 }}
              size={mobile ? "small" : "default"}
              avatar={rater.avatar}
              name={rater.name}
            />
          );
        })}
    </Raters>
  );
};

RatersList.propTypes = {
  filteredRaters: PropTypes.array.isRequired,
  remainingRaters: PropTypes.array.isRequired
};

export default RatersList;
