import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";
import { Overlay } from "./styles";

const LoadingOverlay = ({ shift, subText }) => (
  <Overlay>
    <Spin
      style={{
        marginLeft: shift && "-200px",
        marginTop: shift && "-100px"
      }}
      size="large"
    />
    {subText && <span>{subText}</span>}
  </Overlay>
);

LoadingOverlay.defaultProps = {
  shift: false,
  subText: null
};

LoadingOverlay.propTypes = {
  shift: PropTypes.bool,
  subText: PropTypes.string
};

export default LoadingOverlay;
