/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import { Teams } from "services/api";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { Col, Row } from "antd";
import Engagement from "containers/DashboardOrg/Engagement";
import TeamHabits from "containers/DashboardTeam/TeamHabits";
import { TeamMembers } from "containers/DashboardTeam/TeamMembers";
import TeamHeader from "./TeamHeader";

@observer
class TeamProfile extends React.Component {
  @observable loading = true;
  @observable team = null;

  @action async fetchTeam() {
    const { id, toggleLoading } = this.props;
    Teams.single(id).then(({ data }) => {
      this.team = data;
      const colors = ["#015db0", "#a01e0d", "#fdc53f", "#5ecb53"];

      this.engagement = data.engagement.scores.map((s, i) => ({
        behaviors: s.behaviors,
        habit_id: s.habit_id,
        habit_name: s.habit_name,
        habit_score: s.habit_score,
        color: colors[i],
        history: Object.keys(s.history).map((k) => ({
          created_at: k,
          value: Math.trunc(s.history[k] * 100)
        }))
      }));
      this.habitsByMember = data.team_habits;
      this.team_habits = data.team_habits.map((u) =>
        Object.assign({}, u, {
          response_rate: data.members.find((uu) => uu.id === u.id).response_rate
        })
      );
      this.response_rates = data.response_rate;
      this.performanceDistribution = data.team_habits
        .reduce(
          (nestedArray, u) => {
            if (!u.habits.length) {
              return nestedArray;
            }
            if (u.habits.some((h) => h.score && h.score < 60)) {
              nestedArray[0].push(u);
            } else if (u.habits.some((h) => h.score && h.score < 80)) {
              nestedArray[1].push(u);
            } else if (u.habits.every((h) => h.score && h.score >= 80)) {
              nestedArray[2].push(u);
            }
            return nestedArray;
          },
          [[], [], []]
        )
        .reduce(
          (obj, arr, idx) => {
            const newObj = {};
            if (idx === 0) {
              newObj.score = Math.round(
                (arr.length /
                  data.team_habits.filter((u) => u.habits.length).length) *
                  100
              );
              newObj.users = arr.map((u) => u.name);
              obj.bad = newObj;
            } else if (idx === 1) {
              newObj.score = Math.round(
                (arr.length /
                  data.team_habits.filter((u) => u.habits.length).length) *
                  100
              );
              newObj.users = arr.map((u) => u.name);
              obj.average = newObj;
            } else {
              newObj.score = Math.round(
                (arr.length /
                  data.team_habits.filter((u) => u.habits.length).length) *
                  100
              );
              newObj.users = arr.map((u) => u.name);
              obj.good = newObj;
            }
            return obj;
          },
          { bad: {}, average: {}, good: {} }
        );
      setTimeout(() => toggleLoading(), 300);
    });
  }

  componentWillMount() {
    this.fetchTeam();
  }

  render() {
    const { loading, goToUser } = this.props;
    return (
      <div>
        {loading || (
          <div>
            <TeamHeader
              goToUser={goToUser}
              team={this.team}
              response_rates={this.response_rates}
              habit_dist={
                Object.values(this.performanceDistribution).some(
                  (val) => val.users.length > 0
                )
                  ? this.performanceDistribution
                  : null
              }
            />

            {!!this.engagement.length && this.team.members.length >= 3 && (
              <Row style={{ marginBottom: 24 }}>
                <Col span={24}>
                  <Engagement engagement={this.engagement} smaller />
                </Col>
              </Row>
            )}

            <Row gutter={24} style={{ marginBottom: 24 }}>
              <Col span={12}>
                <TeamMembers
                  goToUser={goToUser}
                  users={this.team_habits}
                  smaller
                />
              </Col>
              <Col span={12}>
                <TeamHabits users={this.team_habits} smaller />
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

TeamProfile.propTypes = {
  loading: PropTypes.bool.isRequired,
  goToUser: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

export default TeamProfile;
