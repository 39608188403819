import React, { useRef, useEffect, useState, useCallback } from "react";
import { Container, StyledInput, InputWrapper } from "./styles";
import { Input } from "antd";

interface Props {
  setCode: (code: string) => void;
}

const CodeInput: React.FC<Props> = ({ setCode }) => {
  const input1 = useRef<Input>(null);
  const input2 = useRef<Input>(null);
  const input3 = useRef<Input>(null);
  const input4 = useRef<Input>(null);
  const input5 = useRef<Input>(null);
  const input6 = useRef<Input>(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [codeMap, setCodeMap] = useState<{ [key: number]: string }>({
    0: "",
    1: "",
    2: "",
    3: "",
    4: "",
    5: ""
  });
  const inputRefs = [input1, input2, input3, input4, input5, input6];

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;
      if (event.metaKey || event.ctrlKey) {
        return;
      }
      event.preventDefault();
      if (keyCode === 8) {
        const nextObject: { [key: number]: string } = Object.assign(
          {},
          codeMap
        );
        if (codeMap[currentIndex].length > 0) {
          nextObject[currentIndex] = "";
          setCodeMap(nextObject);
        } else {
          nextObject[currentIndex - 1] = "";
          setCodeMap(nextObject);
          if (currentIndex !== 0) {
            setCurrentIndex(currentIndex - 1);
            inputRefs[currentIndex - 1].current &&
              inputRefs[currentIndex - 1].current.focus();
          }
        }
      }
      if (
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105)
      ) {
        if (
          inputRefs[currentIndex].current &&
          document.activeElement &&
          document.activeElement.id !== inputRefs[currentIndex].current.props.id
        ) {
          return;
        }

        const nextObject: { [key: number]: string } = Object.assign(
          {},
          codeMap
        );
        nextObject[currentIndex] = event.key.toUpperCase();
        setCodeMap(nextObject);
        if (inputRefs[currentIndex + 1]) {
          inputRefs[currentIndex + 1].current &&
            inputRefs[currentIndex + 1].current.focus();
          setCurrentIndex(currentIndex + 1);
        }
      }
    },
    [codeMap, currentIndex, inputRefs]
  );

  useEffect(() => {
    const { current } = input1;
    if (!current) return;
    if (current !== null) {
      current.focus();
    }
  }, []);

  useEffect(() => {
    const str = Object.keys(codeMap)
      .map((key) => codeMap[parseInt(key, 10)])
      .join("");
    setCode(str);
  }, [codeMap, setCode]);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);

    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const handlePaste = (e: React.ClipboardEvent) => {
    const { clipboardData } = e;
    if (!clipboardData) {
      return;
    }
    const pastedText = clipboardData.getData("text");
    const nextCodemap: { [key: string]: string } = {};
    pastedText.split("").forEach((character, index) => {
      if (index < 6) {
        nextCodemap[index] = character.toUpperCase();
      }
    });
    setCodeMap(nextCodemap);
  };

  return (
    <Container>
      {inputRefs.map((ref, idx) => (
        <InputWrapper key={idx}>
          <label
            htmlFor={`input-${idx}`}
            style={{
              border: "0",
              clip: "rect(0 0 0 0)",
              height: "1px",
              margin: "-1px",
              overflow: "hidden",
              padding: "0",
              position: "absolute",
              width: "1px"
            }}
          >{`input-${idx}`}</label>
          <StyledInput
            // @ts-ignore
            index={idx}
            onPaste={handlePaste}
            onChange={(e) => e.preventDefault()}
            key={idx}
            ref={ref}
            id={`input-${idx}`}
            size="large"
            onFocus={(e) => {
              setCurrentIndex(idx);
            }}
            value={codeMap[idx]}
          />
        </InputWrapper>
      ))}
    </Container>
  );
};

export default CodeInput;
