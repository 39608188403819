/* eslint-disable no-param-reassign */
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import queryString from "query-string";
import { ThemeProvider } from "styled-components";
import { Base64 } from "js-base64";
import Page from "containers/Page";
import ProtectedRoute from "components/ProtectedRoute";
import theme from "assets/theme";
import LoadingOverlay from "components/LoadingOverlay";
import SignIn from "containers/SignIn";
import HabitsNotFound from "containers/HabitsNotFound";
import DashboardViewer from "containers/DasboardViewer";
import PropTypes from "prop-types";
import SupportLogin from "./components/SupportLogin";
import PasswordReset from "containers/PasswordReset";

function getAccessToken() {
  const token = localStorage.getItem("token");
  if (token) {
    return `Bearer ${token}`;
  }
  return "";
}

export const Shell = ({ profileStore, tutorialStore }) => {
  const values = queryString.parse(window.location.search);
  if (values.token) {
    let { path, mentoringPath } = values;
    const { token } = values;
    if (path) {
      path = Base64.decode(path);
    }
    if (mentoringPath) {
      profileStore.mentoringPath = mentoringPath;
    }
    profileStore.setLoggedInUser(token, path);
  }

  if (profileStore.authenticated && !profileStore.currentUser) {
    if (profileStore.isSuperAdmin) {
      profileStore.fetchSuperAdmin();
    } else {
      profileStore.fetchProfile();
    }
  }

  useEffect(() => {
    if (profileStore.authenticated) {
      tutorialStore.fetchViewedTutorials();
    }
  }, [profileStore.authenticated]);

  if (profileStore.isLoading) {
    return (
      <div style={{ position: "relative", height: "100vH", width: "100vW" }}>
        <LoadingOverlay />
      </div>
    );
  }

  if (window.location.pathname.split("/")[2] === "linkedin") {
    const val = queryString.parse(window.location.search);
    if (val.code) {
      profileStore.isRedirecting(val.code);
    }
  }

  if (window.location.pathname.split("/")[1] === "new_password") {
    const code = queryString.parse(window.location.search);
    return <PasswordReset code={code.reset_token} />;
  }
  if (window.location.pathname.split("/")[1] === "support") {
    return <SupportLogin />;
  }

  if (window.location.pathname.split("/").includes("highfive")) {
    [, , profileStore.newHighfiveId] = window.location.pathname.split("/");
  }

  if (
    window.location.pathname.split("/").includes("onboarding") ||
    (profileStore.authenticated &&
      !profileStore.currentUser.onboarded &&
      !profileStore.isSuperAdmin)
  ) {
    profileStore.isOnboarding = true;
  }

  if (!profileStore.authenticated) {
    return <SignIn />;
  }
  if (profileStore.features().includes("NEXT_UI")) {
    if (
      !profileStore.currentUser.admin &&
      !profileStore.currentUser.can_configure
    ) {
      const token = localStorage.getItem("token");
      window.location.assign(
        `${process.env.REACT_APP_NEXT_URL}${token ? `?token=${token}` : ""}`
      );
      return <div />;
    }
  }

  console.log(tutorialStore.viewedTutorials);

  const orgTheme = profileStore.currentUser.organization.theme;
  if (profileStore.isDashboardViewer) {
    if (!profileStore.currentUser.habits.length) {
      return <HabitsNotFound />;
    }
    return (
      <ThemeProvider theme={{ ...theme, ...orgTheme }}>
        <DashboardViewer profileStore={profileStore} />
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={{ ...theme, ...orgTheme }}>
      <ProtectedRoute
        isAllowed={profileStore.authenticated}
        redirectTo="/signin"
        path="/"
        component={Page}
      />
    </ThemeProvider>
  );
  // return(
  // <Switch>
  //   <ProtectedRoute
  //     isAllowed={!profileStore.authenticated}
  //     exact
  //     path="/signin"
  //     component={SignIn}
  //     redirectTo="/"
  //   />
  // </Switch>
  // )
};

Shell.propTypes = {
  profileStore: PropTypes.object.isRequired
};

export default withRouter(
  inject("profileStore", "proposalStore", "tutorialStore")(observer(Shell))
);
