import styled from "styled-components";
import { media } from "utils/style-utils";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 24px;
  ${media.handheld`
    padding: 8px;
  `}
`;

export const Header = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
`;

export const Description = styled.div`
  width: 60%;
  font-size: ${props => props.header ? '14px' : '12px'}
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
`;
export const Due = styled.div`
  width: 10%;
  font-size: ${props => props.header ? '14px' : '12px'};
`;
export const Progress = styled.div`
  width: 30%;
  margin-left: ${props => !props.header && '12px'}
  font-size: ${props => props.header ? '14px' : '12px'};
`;
export const TableContent = styled.div`
  width: 100%;
`;

export const GoalItem = styled.div`
  width: 100%;
  display: flex;
`;