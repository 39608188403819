/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Button,
  Badge,
  Tooltip,
  Popconfirm,
  Icon,
  Modal,
  Typography
} from "antd";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { observer, inject } from "mobx-react";
import { withTheme } from "styled-components";
import { observable } from "mobx";
import PercentageCircle from "components/PercentageCircle";
import { ReactComponent as Increase } from "assets/increase.svg";
import { ReactComponent as Decrease } from "assets/decrease.svg";
import Relationships from "containers/Relationships";
import { ReactComponent as FlairImg } from "assets/dashboard/participation_alert.svg";
import { ReactComponent as FlairImg2 } from "assets/dashboard/objective_review_due.svg";
// import FlairImg from "assets/dashboard/participation_alert.svg";

import { Profile } from "services/api";
import history from "services/history";
import viewModal from "components/FullScreenModal";
import { HomeCard } from "../styles";
import GoalContent from "./GoalContent";

import { Body, HabitGrid, Goals, Explore, ChoiceButton } from "./styles";
import ViewerList from "./DashboardViewers";
import openHabitPicker from "containers/HabitPicker";
import { Flair } from "components/Layout/styles";
import { withTranslation } from "react-i18next";

@inject("profileStore")
@observer
class Habits extends React.Component {
  @observable networkModalOpen = false;
  @observable viewersModalOpen = false;
  @observable viewers = [];
  @observable loading = false;
  @observable choiceOpen = false;

  static propTypes = {
    habits: PropTypes.array.isRequired,
    goalsActive: PropTypes.bool.isRequired,
    currentUserId: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    goals: PropTypes.array.isRequired,
    profileStore: PropTypes.object.isRequired,
    inTutorial: PropTypes.bool.isRequired
  };

  componentDidMount() {
    this.getDashboardViewers();
  }

  copyToClipboard = (link) => {
    const textarea = document.createElement("textarea");
    textarea.style.position = "fixed";
    textarea.style.top = 0;
    textarea.style.left = 0;
    textarea.style.width = "1px";
    textarea.style.height = "1px";
    textarea.style.background = "transparent";
    document.querySelector("body").appendChild(textarea);
    let text = "";
    text += link;
    textarea.value = text;
    textarea.select();
    document.execCommand("copy");
  };

  setDates = (data) => {
    this.viewers = data
      .map((d) => ({
        ...d,
        expires_at: moment(d.expires_at).format("MMM Do YYYY")
      }))
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  };

  openRelationships = () => {
    const { profileStore } = this.props;
    viewModal(
      { width: 900, onClose: () => profileStore.fetchUpdatedHabits() },
      history,
      <Relationships />
    );
  };

  getColumns = () => {
    const columns = [
      {
        title: "Email",
        dataIndex: "email",
        key: "email"
      },
      {
        title: "Expires At",
        dataIndex: "expires_at",
        key: "expires_at"
      },
      {
        title: "Actions",
        key: "action",
        render: (text, record) => (
          // eslint-disable-next-line react/jsx-no-comment-textnodes
          <>
            <a
              style={{ marginRight: "10px" }}
              role="presentation"
              onClick={() => {
                Modal.info({
                  maskClosable: true,
                  okText: "Copy to Clipboard",
                  title: "Dashboard Invite Link",
                  content: record.url,
                  onOk: () => {
                    this.copyToClipboard(record.url);
                  }
                });
              }}
            >
              View Link
            </a>
            <Popconfirm
              overlayClassName="alert"
              overlayStyle={{ padding: "24px !!important" }}
              title="Are you sure？"
              icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
              onConfirm={() => this.handleDelete(record.id)}
              okText="Yes"
            >
              <a style={{ color: "red" }} role="presentation">
                Revoke
              </a>
            </Popconfirm>
          </>
        )
      }
    ];
    return columns;
  };

  getDashboardViewers = () => {
    Profile.fetchDashboardViewers().then((res) => {
      this.setDates(res.data);
    });
  };

  handleDelete = (id) => {
    Profile.revokeDashboardViewer(id).then(() => {
      this.getDashboardViewers();
    });
  };

  handleCancel = () => {
    this.viewersModalOpen = false;
  };

  createViewer = (formData, { resetFields }) => {
    this.loading = true;
    Profile.createDashboardViewer(formData).then((res) => {
      this.loading = false;
      const link = res.data.url;
      this.getDashboardViewers();
      Modal.info({
        okText: "Copy to Clipboard",
        title: "Dashboard Invite Link",
        content: link,
        onOk: () => {
          this.copyToClipboard(link);
          resetFields();
        }
      });
    });
  };

  openChoice = () => {
    this.choiceOpen = true;
  };

  render() {
    const {
      habits,
      unlockedHabits,
      goalsActive,
      color,
      goals,
      inTutorial,
      theme,
      t,
      profileStore
    } = this.props;

    const columns = this.getColumns();
    let share = "";
    if (habits) {
      share = (
        <Button
          onClick={() => (this.viewersModalOpen = true)}
          style={{ marginRight: "12.5px" }}
        >
          {t("homepage.habits.share")}
        </Button>
      );
    }
    const spans = { xxl: 6, xl: 6, lg: 6, m: 6, sm: 12, xs: 12 };
    let iconSize;
    if (window.innerWidth < 500) {
      iconSize = 10;
    } else {
      iconSize = 14;
    }

    const features = profileStore.features();
    let button;

    if (features.includes("HABIT_PICKER")) {
      button = (
        <Button id="manage-button" onClick={() => (this.choiceOpen = true)}>
          {window.innerWidth < 500
            ? t("homepage.habits.manage_circle")
            : t("homepage.habits.manage_habits_and_feedback")}
        </Button>
      );
    } else {
      button = (
        <Button id="manage-button" onClick={this.openRelationships}>
          {window.innerWidth < 500
            ? t("homepage.habits.manage_circle")
            : t("homepage.habits.manage_my_feedback_circle")}
        </Button>
      );
    }
    return (
      <HomeCard
        id="habits"
        extra={
          <div style={{ display: "flex", alignItems: "center" }}>
            {window.innerWidth > 500 && (
              <div style={{ marginRight: 24 }}>
                <span>
                  <span style={{ color: theme.primary_color }}>
                    <Increase
                      style={{
                        marginRight: 2,
                        height: iconSize,
                        width: iconSize
                      }}
                    />
                    /
                    <Decrease
                      style={{
                        marginRight: 6,
                        marginLeft: 2,
                        height: iconSize,
                        width: iconSize
                      }}
                    />
                  </span>
                  {t("homepage.habits.weekly_trend")}
                </span>
              </div>
            )}
            {habits.some((h) => h.raters_count < 3) ? (
              <>
                {share}

                <Tooltip title={t("homepage.habits.networks_need_attention")}>
                  <Badge count="!">{button}</Badge>
                </Tooltip>
              </>
            ) : (
              <>
                {share}
                {button}
              </>
            )}
          </div>
        }
        title={t("homepage.habits.title")}
        style={{
          height: window.innerWidth < 500 ? "auto" : "100%",
          minHeight: window.innerWidth > 500 && 429
        }}
        bodyStyle={{
          height: window.innerWidth > 500 && "calc(100% - 57px)",
          padding: 0
        }}
      >
        <ViewerList
          handleDelete={this.handleDelete}
          copyToClipboard={this.copyToClipboard}
          visible={this.viewersModalOpen}
          viewers={this.viewers}
          onCancel={this.handleCancel}
          columns={columns}
          loading={this.loading}
          createViewer={this.createViewer}
          getTokenLink={this.getTokenLink}
        />
        <Body goalsActive={goalsActive}>
          <Modal
            visible={this.choiceOpen}
            footer={null}
            onCancel={() => (this.choiceOpen = false)}
            width={750}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: window.innerWidth < 500 ? "column" : "row"
              }}
            >
              <ChoiceButton
                onClick={() => {
                  this.choiceOpen = false;
                  openHabitPicker();
                }}
              >
                <Typography.Title level={4} style={{ textAlign: "center" }}>
                  {t("homepage.habits.manage_my_habits")}
                </Typography.Title>
                <Flair style={{ width: 150, marginBottom: 12 }}>
                  <FlairImg2 />
                </Flair>
                <span style={{ textAlign: "center" }}>
                  {t("homepage.habits.manage_habits_description")}
                </span>
              </ChoiceButton>
              <ChoiceButton
                onClick={() => {
                  this.choiceOpen = false;
                  this.openRelationships();
                }}
              >
                <Typography.Title level={4} style={{ textAlign: "center" }}>
                  {t("homepage.habits.manage_my_feedback_circle")}{" "}
                </Typography.Title>
                <Flair style={{ width: 150, marginBottom: 12 }}>
                  <FlairImg />
                </Flair>
                <span style={{ textAlign: "center" }}>
                  {t("homepage.habits.manage_my_feedback_circle_description")}
                </span>
              </ChoiceButton>
              {/* <Button
                style={{ marginBottom: 24 }}
                onClick={() => {
                  this.choiceOpen = false;
                  openHabitPicker();
                }}
              >
                Manage My Habits
              </Button> */}
            </div>
          </Modal>
          <HabitGrid>
            <Row gutter={12} style={{ width: "100%" }} id="habit-row">
              {habits
                .sort((a, b) => (a.name.length > b.name.length ? -1 : 0))
                .map((h) => (
                  <NavLink
                    key={h.habit_user_id}
                    to={`/habits/${h.habit_user_id}`}
                    style={{ color: "inherit" }}
                  >
                    <Col
                      id={inTutorial ? "demo_habit" : h.name}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: 12
                      }}
                      {...spans}
                    >
                      <PercentageCircle
                        hasFeedback={h.has_unseen_feedback}
                        percent={!h.locked ? h.score : null}
                        lastPercent={h.last_score}
                        locked={h.locked}
                        raters={h.raters_count}
                        name={h.name}
                        unlockThreshold={h.unlock_threshold}
                        color={color}
                        size={window.innerWidth < 500 ? "small" : null}
                        responses={h.response_count}
                      />
                    </Col>
                  </NavLink>
                ))}
            </Row>
          </HabitGrid>
          {habits.some((h) => h.has_unseen_feedback) && (
            <div
              style={{
                position: window.innerWidth > 500 && "absolute",
                bottom: 28,
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <NavLink
                to={`/habits/${
                  habits.filter((h) => h.has_unseen_feedback)[0].habit_user_id
                }?view=additional`}
              >
                <Button
                  type="primary"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div style={{ marginRight: 12 }}>🎁</div>{" "}
                  {t("homepage.habits.unread_feedback")}
                </Button>
              </NavLink>
            </div>
          )}
          <Explore>{t("homepage.habits.click_to_explore")}</Explore>
        </Body>
        {goalsActive && (
          <Goals>
            <GoalContent goals={goals} />
            <Explore>{t("homepage.habits.click_to_explore")}</Explore>
          </Goals>
        )}
      </HomeCard>
    );
  }
}

export default withTheme(withTranslation()(Habits));
