import { Button } from "antd";
import Box from "layout/Box";
import React from "react";
import { withTheme } from "styled-components";

interface Props {
  threshold: number;
  count: number;
  theme: any;
  ratersCount: number;
}

const UnlockProgressBar: React.FC<Props> = ({
  threshold,
  count,
  theme,
  ratersCount
}) => {
  const secondary = theme.secondary_color;

  let widthPercentage;
  if (ratersCount < 3) {
    widthPercentage = (ratersCount / 3) * 100;
  } else {
    widthPercentage = (count / threshold) * 100;
  }

  return (
    <Box
      width="110px"
      position="absolute"
      top="25px"
      display="flex"
      zIndex={999}
      alignItems="center"
    >
      <Button
        type="primary"
        shape="circle"
        icon="lock"
        style={{ marginRight: -16 }}
      />
      <Box height="10px" width="16px" backgroundColor={secondary} />
      <Box height="10px" width="50px" display="flex">
        <Box
          height="10px"
          width={`${widthPercentage}%`}
          backgroundColor={secondary}
        />
        <Box
          height="10px"
          width={`${100 - widthPercentage}%`}
          backgroundColor="#CCCCCC"
        />
      </Box>
      <Box height="10px" width="16px" backgroundColor="#CCCCCC" />
      <Button
        type="primary"
        shape="circle"
        icon="unlock"
        style={{ marginLeft: -16 }}
      />
    </Box>
  );
};

export default withTheme(UnlockProgressBar);
