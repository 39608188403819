import React from "react";
import PropTypes from "prop-types";
import { Modal, Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const FeedbackForm = ({
  visible,
  onCancel,
  onSend,
  form,
  form: { getFieldDecorator }
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      title={t("feedback_form.title")}
      okText={t("feedback_form.ok_text")}
      destroyOnClose
      onCancel={onCancel}
      onOk={() => onSend(form.getFieldsValue())}
    >
      <Form layout="vertical">
        <Form.Item label={t("feedback_form.input_label")}>
          {getFieldDecorator("feedback", {
            rules: [
              { required: true, message: t("feedback_form.rule_message") }
            ]
          })(
            <Input.TextArea
              id="feedback_input"
              placeholder={t("feedback_form.placeholder")}
              rows={6}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

FeedbackForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired
  }).isRequired
};

export default Form.create()(FeedbackForm);
