import styled from "styled-components";
import { Input } from "antd";

export const Container = styled.div`
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const StyledInput = styled(Input)`
  text-align: center;
  font-size: 24px;
`;

export const InputWrapper = styled.div`
  margin: 0 12px;
  &:first-child {
    margin: 0 12px 0 0;
  }
  &:last-child {
    margin: 0 0 0 12px;
  }
  @media (max-width: 420px) {
    margin: 0 4px;
    font-size: 18px;

    &:first-child {
      margin: 0 4px 0 0;
    }
    &:last-child {
      margin: 0 0 0 4px;
    }
  }
`;
