/* eslint-disable no-debugger */

import React, { useState } from "react";
import { Button, Modal } from "antd";
import { WorkItems, AdditionalFeedback } from "services/api";

import GiveMoreFeedbackModal from "components/GiveMoreFeedbackModal";
import { UserLite } from "types/user";

import { ReactComponent as FlairImg } from "assets/dashboard/suggested_employee_feedback_provider.svg";
import { useTranslation } from "react-i18next";
import { ItemWrapper, Title, ButtonWrapper, Flair, Content } from "../styles";

type AdditionalFeedbackItemProps = {
  itemId: string;
  markCompleted: () => void;
  feedback: {
    asker: UserLite;
    id: string;
    name: string;
    prompt: {
      answer_text: string;
      question_text: string;
    };
    answer_id: string;
  };
};

const AdditionalFeedbackItem = ({
  feedback: { asker },
  feedback,
  itemId,
  markCompleted
}: AdditionalFeedbackItemProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const discardItem = () => {
    WorkItems.discard(itemId);
    markCompleted();
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseAfterComplete = () => {
    discardItem();
    setOpen(false);
  };

  const confirmDiscard = () => {
    Modal.confirm({
      title: t(
        "homepage.action_items.give_additional_feedback.confirm_discard"
      ),
      onOk: discardItem
    });
  };

  const handleUpdateFeedback = (response: number) => {
    const params = { additional_feedback: { response } };
    AdditionalFeedback.updateFeedback(feedback.id, params);
    discardItem();
  };

  return (
    <>
      <GiveMoreFeedbackModal
        updateFeedback={handleUpdateFeedback}
        open={open}
        close={handleCloseModal}
        complete={handleCloseAfterComplete}
        feedback={feedback}
      />
      <ItemWrapper>
        <Content>
          <Title>
            {t("homepage.action_items.give_additional_feedback.prompt_text", {
              askerName: asker.name
            })}
          </Title>
          <p>
            {t("homepage.action_items.give_additional_feedback.prompt_subtext")}
          </p>
          <ButtonWrapper>
            <Button style={{ marginRight: 24 }} onClick={confirmDiscard}>
              {t("homepage.action_items.give_additional_feedback.dismiss_text")}
            </Button>
            <Button
              data-cy="xtra feedback"
              onClick={handleOpenModal}
              type="primary"
            >
              {t("homepage.action_items.give_additional_feedback.action_text")}
            </Button>
          </ButtonWrapper>
        </Content>
        <Flair>
          <FlairImg style={{ width: "100%" }} />
        </Flair>
      </ItemWrapper>
    </>
  );
};

export default AdditionalFeedbackItem;
