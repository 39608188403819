/* eslint-disable */
import React, { useState } from "react";
import { Avatar } from "antd";
import { BoxWrapper, Label, AvatarWrapper } from "./styles";
import nameToInitials from "../../utils/nameToInitials";

const InteractiveBox = ({
  coordinates,
  userCoordinates,
  current,
  onSelect,
  label,
  user,
  hasSelected,
  hide,
  id
}) => {
  const [hovering, setHovering] = useState(false);

  return (
    <BoxWrapper
      id={id}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      selected={!hide && coordinates === current}
      onClick={() => onSelect(coordinates)}
    >
      <Label>{label}</Label>
      {((userCoordinates && userCoordinates === coordinates) ||
        (hovering && !userCoordinates)) && (
        <AvatarWrapper>
          <Avatar size={32} src={user.avatar}>
            {nameToInitials(user.name)}
          </Avatar>
        </AvatarWrapper>
      )}
    </BoxWrapper>
  );
};

export const DisplayBox = ({ label, children, onSelect, id }) => {
  return (
    <BoxWrapper
      id={id}
      style={{ padding: "0 24px", zIndex: 1000, backgroundColor: "white" }}
      onClick={onSelect}
    >
      <Label>{label}</Label>
      {children}
    </BoxWrapper>
  );
};

export default InteractiveBox;
