import React, { useState } from "react";
import { Modal, Input, Select, TimePicker, Button } from "antd";
import moment from "moment";
import { Profile } from "services/api";
import useStores from "utils/useStores";
import PropTypes from "prop-types";
import LoadingOverlay from "components/LoadingOverlay";
import PhoneNumber from "./phone_number";
import codes from "./phone";

const PhoneVerification = ({
  visible,
  onClose,
  showVerification,
  saveNumber
}) => {
  const { profileStore } = useStores();
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasSent, setHasSent] = useState(false);
  const [verified, setVerified] = useState(false);
  const [mailTime, setMailTime] = useState(profileStore.currentUser.mail_time);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [countryCode, setCountryCode] = useState("US");

  const phoneNumberWithCountryCode = `+${codes[countryCode]}${phoneNumber}`;
  const extraProps = verified ? { footer: null } : {};
  return (
    <Modal
      okText={hasSent ? "Submit Code" : "Send Verification"}
      visible={visible}
      onCancel={() => onClose(false)}
      onOk={
        hasSent
          ? () => {
              setLoading(true);
              Profile.checkPhoneVerification({
                code,
                phone_number: phoneNumber
              }).then(({ data }) => {
                if (data.status === "approved") {
                  onClose(true, phoneNumber);
                } else {
                  setHasError(true);
                  setLoading(false);
                }
              });
            }
          : () =>
              Profile.sendPhoneVerification({
                phone_number: phoneNumberWithCountryCode
              }).then(() => {
                setPhoneNumber(phoneNumber);
                setHasSent(true);
              })
      }
      title="Verify Phone Number"
      {...extraProps}
    >
      {loading && <LoadingOverlay />}
      {hasSent ? (
        <div
          style={{ width: "75%", justifyContent: "center", margin: "0 auto" }}
        >
          {!verified && (
            <div style={{ fontSize: 24, textAlign: "center" }}>
              Enter Verification Code
            </div>
          )}
          {verified ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <div style={{ fontSize: 24, textAlign: "center" }}>
                You&apos;re all set!
              </div>
              <span
                style={{
                  textAlign: "center"
                }}
              >
                You&apos;ll receive your first text message on the next time
                we&apos;re ready for you to give feedback
              </span>
              <Button
                type="primary"
                onClick={onClose}
                style={{ marginTop: 12 }}
              >
                Close
              </Button>
            </div>
          ) : (
            <PhoneNumber
              setCode={setCode}
              submit={(verification) => {
                setLoading(true);
                setCode(verification);
                Profile.checkPhoneVerification({
                  code: verification,
                  phone_number: phoneNumberWithCountryCode
                }).then(({ data }) => {
                  if (data.status === "approved") {
                    // eslint-disable-next-line no-unused-expressions
                    if (showVerification) {
                      setVerified(true);
                      setLoading(false);
                      saveNumber(phoneNumberWithCountryCode, mailTime);
                    } else {
                      onClose(true, phoneNumberWithCountryCode);
                    }
                  } else {
                    setHasError(true);
                    setLoading(false);
                  }
                });
              }}
              loading={loading}
            />
          )}
          {hasError && (
            <span style={{ color: "red" }}>
              Incorrect code, please try again
            </span>
          )}
        </div>
      ) : (
        <div
          style={{ width: "75%", justifyContent: "center", margin: "0 auto" }}
        >
          <div style={{ fontSize: 24, textAlign: "center" }}>
            Enter Your Phone Number
          </div>

          <Input.Group compact>
            <Select
              style={{ width: "35%" }}
              showSearch
              onChange={(value) => setCountryCode(value)}
              value={countryCode}
            >
              {Object.entries(codes).map(([country, phoneCode]) => (
                <Select.Option value={country}>
                  {country} (+{phoneCode})
                </Select.Option>
              ))}
            </Select>
            <Input
              style={{ width: "65%" }}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Input.Group>
          {showVerification && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 12
              }}
            >
              <span>What time would you like to receive messages?</span>
              <TimePicker
                onChange={(_, stringTime) => setMailTime(stringTime)}
                use12Hours
                defaultValue={moment(mailTime, "hh:mm A")}
                disabledHours={() => [0]}
                format="hh:mm a"
                style={{ width: 200 }}
              />
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

PhoneVerification.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  saveNumber: PropTypes.func.isRequired,
  showVerification: PropTypes.bool
};

PhoneVerification.defaultProps = {
  showVerification: false
};

export default PhoneVerification;
