/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Avatar, Icon, Button } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import nameToInitials from "../../../utils/nameToInitials";
import Search from "./search";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  width: 100%;
`;

const Label = styled.span`
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin-bottom: 8px;
`;

const TinyButton = styled(Button)`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  border: 1px solid #e2e2e2;
  background-color: #e2e2e2;
`;

const UserItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Stakeholders = ({
  stakeholders,
  addStakeholder,
  removeStakeholder,
  owner,
  setOwner
}) => {
  return (
    <Wrapper>
      <Label>Owner: </Label>
      <UserItem key={owner.id} style={{ marginBottom: 12 }}>
        <Avatar style={{ marginRight: 12 }} src={owner.avatar}>
          {nameToInitials(owner.name)}
        </Avatar>
        {owner.name}
      </UserItem>
      <Search
        owner={owner} // currentUser={currentUser}
        onSelect={setOwner}
        currentlySelected={[...stakeholders, owner]}
        placeholder="Choose a different owner"
      />
      <Label>Stakeholders: </Label>
      <Search
        owner={owner}
        onSelect={addStakeholder}
        currentlySelected={stakeholders}
      />

      {stakeholders.map((stakeholder) => (
        <UserItem key={stakeholder.id} style={{ marginBottom: 12 }}>
          <TinyButton
            onClick={() => removeStakeholder(stakeholder.id)}
            shape="circle"
          >
            <Icon style={{ fontSize: 8, color: "grey" }} type="close" />
          </TinyButton>
          <Avatar style={{ marginRight: 12 }} src={stakeholder.avatar}>
            {nameToInitials(stakeholder.name)}
          </Avatar>
          {stakeholder.name}
        </UserItem>
      ))}
    </Wrapper>
  );
};

Stakeholders.propTypes = {
  // currentUser: PropTypes.object.isRequired,
  stakeholders: PropTypes.array.isRequired,
  owner: PropTypes.object.isRequired,
  addStakeholder: PropTypes.func.isRequired,
  setOwner: PropTypes.func.isRequired,
  removeStakeholder: PropTypes.func.isRequired
};

export default Stakeholders;
