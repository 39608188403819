import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { WorkItems } from "services/api";
import viewModal from "components/FullScreenModal";
import Relationships from "containers/Relationships";

import { ReactComponent as FlairImg } from "assets/dashboard/suggested_employee_feedback_provider.svg";
import { ReactComponent as FlairImgRemoved } from "assets/dashboard/removal_alert.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ItemWrapper, Title, ButtonWrapper, Flair, Content } from "../styles";

interface Props {
  markCompleted: () => void;
  itemId: string;
}

const MentorPreviewItem: React.FC<Props> = ({ markCompleted, itemId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const discardItem = (): void => {
    WorkItems.discard(itemId);
    markCompleted();
  };

  return (
    <ItemWrapper>
      <Content>
        <Title> {t("homepage.action_items.mentor_prompt.prompt_text")}</Title>
        <p> {t("homepage.action_items.mentor_prompt.prompt_subtext")}</p>
        <ButtonWrapper>
          <Button style={{ marginRight: 24 }} onClick={discardItem}>
            {t("homepage.action_items.mentor_prompt.dismiss_text")}
          </Button>
          <Button
            onClick={(): void => history.push("/mentoring")}
            type="primary"
          >
            {t("homepage.action_items.mentor_prompt.action_text")}
          </Button>
        </ButtonWrapper>
      </Content>
      <Flair>
        <FlairImg style={{ width: "100%" }} />
      </Flair>
    </ItemWrapper>
  );
};

export default MentorPreviewItem;
