/* eslint-disable react/prop-types */

/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Carrot from "assets/carrot.png";

export default ({ value }) => (
  <div style={{ marginTop: 6 }}>
    <span style={{ fontSize: 16 }}>+ {value}</span>
    <img
      src={Carrot}
      style={{ width: 12, height: "auto", marginLeft: 2, marginRight: 6 }}
    />
  </div>
);
