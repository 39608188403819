/* eslint-disable */
import * as Types from '../../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type IFetchUserHabitsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type IFetchUserHabitsQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & { habits: Array<(
      { __typename?: 'Habit' }
      & Pick<Types.IHabit, 'id' | 'category' | 'name' | 'description' | 'selectable'>
      & { behaviors: Array<(
        { __typename?: 'Behavior' }
        & Pick<Types.IBehavior, 'id' | 'anchor'>
      )> }
    )> }
  ) }
);


export const FetchUserHabitsDocument = gql`
    query FetchUserHabits($id: ID!) {
  user(id: $id) {
    habits {
      id
      category
      name
      description
      selectable
      behaviors {
        id
        anchor
      }
    }
  }
}
    `;

/**
 * __useFetchUserHabitsQuery__
 *
 * To run a query within a React component, call `useFetchUserHabitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserHabitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserHabitsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchUserHabitsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IFetchUserHabitsQuery, IFetchUserHabitsQueryVariables>) {
        return ApolloReactHooks.useQuery<IFetchUserHabitsQuery, IFetchUserHabitsQueryVariables>(FetchUserHabitsDocument, baseOptions);
      }
export function useFetchUserHabitsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IFetchUserHabitsQuery, IFetchUserHabitsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IFetchUserHabitsQuery, IFetchUserHabitsQueryVariables>(FetchUserHabitsDocument, baseOptions);
        }
export type FetchUserHabitsQueryHookResult = ReturnType<typeof useFetchUserHabitsQuery>;
export type FetchUserHabitsLazyQueryHookResult = ReturnType<typeof useFetchUserHabitsLazyQuery>;
export type FetchUserHabitsQueryResult = ApolloReactCommon.QueryResult<IFetchUserHabitsQuery, IFetchUserHabitsQueryVariables>;