/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
// eslint-disable-next-line no-unused-vars
import {
  Button,
  Slider,
  Divider,
  Input,
  DatePicker,
  List,
  Form,
  Icon,
  Checkbox
} from "antd";
import { observable } from "mobx";
import moment from "moment";
import { observer, inject } from "mobx-react";
import LoadingOverlay from "components/LoadingOverlay";
import { Objective } from "services/api";
import PropTypes from "prop-types";
import Column from "antd/lib/table/Column";
import { Wrapper, Header, Header1 } from "./styles";

const { Item } = List;
const { TextArea } = Input;
const mobile = window.innerWidth < 500;
const marks = {
  1: mobile
    ? ""
    : {
        style: { width: 90, textAlign: "center" },
        label: "Below Expectations"
      },
  2: mobile
    ? ""
    : {
        style: { width: 90, textAlign: "center" },
        label: "Somewhat Satisfactory"
      },
  3: mobile
    ? ""
    : {
        style: { width: 90, textAlign: "center" },
        label: "Meets Expectations"
      },
  4: mobile
    ? ""
    : {
        style: { width: 90, textAlign: "center" },
        label: "Exceeds Expectations"
      },
  5: mobile
    ? ""
    : {
        style: { width: 90, textAlign: "center" },
        label: "Exceptional (Rare)"
      }
};

function formatter(value) {
  if (value === 1) {
    return (
      <div style={{ textAlign: "center" }}>
        Incomplete key result or key result was far below expectations.
      </div>
    );
  }
  if (value === 2) {
    return (
      <div style={{ textAlign: "center" }}>
        Somewhat satisfactory key result, but does not fully meet expectations.
      </div>
    );
  }
  if (value === 3) {
    return (
      <div style={{ textAlign: "center" }}>
        Key result fully meets expectations.
      </div>
    );
  }
  if (value === 4) {
    return (
      <div style={{ textAlign: "center" }}>
        Key result exceeded expectations in a significant way.
      </div>
    );
  }
  return (
    <div style={{ textAlign: "center" }}>
      Rare and exceptional value was achieved through this key result.
    </div>
  );
}

@inject("profileStore", "objectiveStore")
@observer
class RateObjective extends React.Component {
  static propTypes = {
    objectives: PropTypes.array.isRequired,
    objectiveId: PropTypes.string.isRequired,
    profileStore: PropTypes.object.isRequired,
    withDate: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    noDate: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { context: "" };
    this.completionDate = moment();

    this.state = {
      currentObjective: null,
      loading: true,
      listData: [],
      score: props.score === undefined ? 3 : props.score,
      context: "",
      error: false
    };
    props.objectiveStore.fetchObjective(props.objectiveId).then(() => {
      const objective = props.objectiveStore.getObjective(props.objectiveId);
      if (objective.date_completed) {
        this.completionDate = moment(objective.date_completed);
      }
      this.setState({
        score: objective.date_completed ? objective.score : 3,
        loading: false,
        currentObjective: objective,
        listData: objective.key_results
      });
    });
  }

  updateObjective = (value) => {
    this.setState({ score: value });
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { score, context, currentObjective } = this.state;
    if (score === 5 && context.length < 50) {
      this.setState({ error: true });
    } else {
      onSubmit({
        reviewable_id: currentObjective.id,
        score,
        context,
        reviewable_type: "Objective",
        date_completed: this.completionDate
      });
      // this.setState({ error: false, context: '', score: 3 });
    }
  };

  // componentWillReceiveProps(nextProps) {
  //   const { objectiveId } = this.props;
  //   if (nextProps.objectiveId !== objectiveId) {
  //     this.loading = true;
  //     Objective.fetch(nextProps.objectiveId).then(({ data }) => {
  //       currentObjective = data;
  //       this.listData = data.key_results.map((kr) => ({
  //         title: kr.name
  //       }));
  //       score = 3;
  //       this.loading = false;
  //       this.setState({
  //         context: ''
  //       });
  //     });
  //   }
  // }

  setCompletionDate = (date) => {
    this.completionDate = date;
  };

  handleChange = (e) => {
    this.setState({ context: e.target.value });
  };

  render() {
    const {
      profileStore,
      objectiveId,
      withDate,
      onSubmit,
      noDate,
      objectives
    } = this.props;
    let asStakeholder = true;

    const {
      context,
      currentObjective,
      listData,
      score,
      loading,
      error
    } = this.state;

    if (currentObjective) {
      asStakeholder = profileStore.currentUser.id !== currentObjective.owner.id;
    }
    const needsContext = score === 5;

    return (
      <Wrapper>
        {loading ? (
          <LoadingOverlay />
        ) : (
          <>
            <Header1>Objective Completed: {currentObjective.name}</Header1>
            {asStakeholder ? (
              <Header>Completed By: {currentObjective.owner.name}</Header>
            ) : (
              <Header>
                Nice job completing a objective! Now it&apos;s time to self
                review. Drag the slider to the response that best represents
                this objective&apos;s level of attainment.
              </Header>
            )}
            {withDate && !asStakeholder && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                Completed On:
                <DatePicker
                  style={{ marginLeft: 4 }}
                  disabledDate={(date) =>
                    moment(date).valueOf() > moment().valueOf()
                  }
                  onChange={this.setCompletionDate}
                  defaultValue={this.completionDate}
                />
              </div>
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingTop: "25px"
              }}
            >
              <Checkbox
                onChange={() =>
                  score ? this.updateObjective(null) : this.updateObjective(3)
                }
                checked={!this.state.score}
              >
                Can&apos;t Review
              </Checkbox>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingBottom: "10px"
              }}
            >
              <div style={{ width: "90%" }}>
                <Slider
                  tipFormatter={formatter}
                  onChange={(val) => this.updateObjective(val)}
                  min={1}
                  max={5}
                  marks={marks}
                  step={1}
                  disabled={!score}
                  value={score}
                />
              </div>
            </div>
            <div
              style={{
                margin: "24px auto",
                width: window.innerWidth < 500 ? "90%" : "60%"
              }}
            >
              {needsContext ? (
                <div style={{ textAlign: "center", marginBottom: 6 }}>
                  Giving a very high rating on an objective requires a brief
                  explanation. Please describe in one or two sentences why you
                  feel this high rating is deserved.
                </div>
              ) : (
                <div style={{ textAlign: "center", marginBottom: 6 }}>
                  Optional context:
                </div>
              )}
              <Form.Item
                style={{ marginBottom: 6 }}
                hasFeedback
                validateStatus={error && "error"}
              >
                <TextArea value={context} onChange={this.handleChange} />
              </Form.Item>
              <span>{context.length}</span>
              {error && (
                <span style={{ color: "red" }}>
                  Content must be at least 50 characters long
                </span>
              )}
            </div>
            <Divider />
            <span style={{ padding: "10px 0px" }}>Key Results:</span>
            <ul style={{ display: "flex", flexDirection: "column" }}>
              {listData.map((l) => (
                <li>{l.name}</li>
              ))}
            </ul>
            <Divider />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                padding: "0px 0px"
              }}
            >
              <Button
                data-cy="submit"
                onClick={this.handleSubmit}
                type="primary"
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </Wrapper>
    );
  }
}

export default RateObjective;
