import React, { useEffect, useState } from "react";
import { Leaderboard as api } from "services/api";
import { Tooltip, Button } from "antd";
import UserAvatar from "components/UserAvatar";
import useStores from "utils/useStores";
import history from "services/history";
import { HomeCard } from "containers/HomePage/styles";
import viewModal from "components/FullScreenModal";
import LoadingOverlay from "components/LoadingOverlay";
import FullLeaderboard from "containers/FullLeaderboard";
import { LeaderboardUser } from "containers/FullLeaderboard/index";
import { Container, Item, Count, Position } from "./styles";
import { useTranslation } from "react-i18next";

const getColor = (score) => {
  // if the user has no responses, show them a positive color
  if (typeof score !== "number") {
    return "#78D36F";
  }
  if (score < 0.65) {
    return "#FF9331";
  }
  if (score < 0.8) {
    return "#FDCE60";
  }
  return "#78D36F";
};

const getTitle = (score) => {
  // if the user has no responses, show them a positive string
  if (typeof score !== "number") {
    return "You've got a rock-solid response rate, nice job!";
  }

  if (score < 0.65) {
    return "Your response rate is low and that's hurting your score! Be sure to complete feedback requests regularly to improve your standing.";
  }
  if (score < 0.8) {
    return "You've got an okay response rate, but there's room for improvement.  Make sure to complete your feedback requests every week!";
  }
  return "You've got a rock-solid response rate, nice job!";
};

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState<LeaderboardUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const {
    profileStore: { currentUser, features }
  } = useStores();

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    api
      .fetch()
      .then(({ data: { ranks, total } }) => {
        setLeaderboard(ranks);
        setUserCount(total);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  const activeFeatures = features();
  const sliceAmount = activeFeatures.includes("OBJECTIVES") ? 3 : 3;

  const openModal = () => {
    viewModal(
      {
        domId: "full-leaderboard",
        skipDestroy: true,
        width: window.innerWidth < 900 ? "100vw" : 1200
      },
      history,
      <FullLeaderboard />
    );
  };

  let foundUser;
  const sortedLeaderboard = leaderboard.sort((a, b) => a.position - b.position);

  if (
    sortedLeaderboard.length > 3 &&
    sortedLeaderboard[3].user.id === currentUser.id
  ) {
    foundUser = sortedLeaderboard[3];
  }

  return (
    <HomeCard
      style={{ height: "auto" }}
      bodyStyle={{ padding: 6 }}
      title={t("homepage.leaderboard.carrots_earned")}
    >
      {loading ? (
        <Container style={{ minHeight: 200 }}>
          <LoadingOverlay />
        </Container>
      ) : (
        <Container>
          {sortedLeaderboard
            .slice(0, 3)
            .sort((a, b) => {
              return a.user.id === currentUser.id &&
                Number(a.feedback_streak) >= Number(b.feedback_streak)
                ? -1
                : 1;
            })
            .map((l) => (
              <Item>
                <Position>{l.position}.</Position>
                <UserAvatar
                  lineHeight={32}
                  size="small"
                  avatar={l.user.avatar}
                  name={l.user.name}
                  style={{ marginRight: 6 }}
                />
                <span
                  style={{ fontWeight: l.user.id === currentUser.id && "600" }}
                >
                  {l.user.name}
                </span>
                {currentUser.id === l.user.id ? (
                  <Tooltip title={getTitle(l.response_rate)}>
                    <Count style={{ color: getColor(l.response_rate) }}>
                      {l.feedback_streak}
                    </Count>
                  </Tooltip>
                ) : (
                  <Count>{l.feedback_streak}</Count>
                )}
              </Item>
            ))}
          {foundUser && (
            <Container style={{ marginTop: -12 }}>
              <div style={{ fontSize: 20, marginLeft: 10 }}>. . . </div>
              <Item>
                <Position>{foundUser.position}.</Position>
                <UserAvatar
                  lineHeight={32}
                  size="small"
                  avatar={foundUser.user.avatar}
                  name={foundUser.user.name}
                  style={{ marginRight: 6 }}
                />
                <span style={{ fontWeight: 600 }}>{foundUser.user.name}</span>
                <Tooltip title={getTitle(foundUser.response_rate)}>
                  <Count style={{ color: getColor(foundUser.response_rate) }}>
                    {foundUser.feedback_streak}
                  </Count>
                </Tooltip>
              </Item>
            </Container>
          )}
        </Container>
      )}
      <Container>
        <div style={{ textAlign: "center" }}>Out of {userCount} users</div>
        <Button style={{ margin: "0 auto" }} type="link" onClick={openModal}>
          {t("homepage.leaderboard.see_full_leaderboard")}
        </Button>
      </Container>
    </HomeCard>
  );
};

export default Leaderboard;
