import React from "react";
import styled from "styled-components";
import { Icon } from "antd";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-bottom: ${({ noBorder }) => !noBorder && "1px solid #e8e8e8"};
  background-color: ${(props) => props.private && "#F8F8F8"};
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HighFiveImg = styled.img`
  height: 25px;
  width: auto;
  margin: 0 4px;
`;

export const Description = styled.div`
  color: ${(props) => props.theme.primary_color};
  font-weight: 500;
  margin-left: 12px;
  line-height: 25px;
  margin-bottom: -6px;
`;

export const Time = styled.div`
  color: rgba(0, 0, 0, 0.3);
  margin-left: 12px;
`;

export const Content = styled.div`
  width: 100%;
  margin: 8px 0;
`;

export const Footer = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  color: rgba(0, 0, 0, 0.5);
  align-items: center;
  position: relative;
  justify-content: ${(props) => props.noDelete && "center"}
  font-size: ${(props) => props.noDelete && "24px"};

`;

export const Left = styled.div`
  display: flex;
  align-items: center;
`;

export const Likes = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 1px;
  margin-left: 4px;
`;

export const LikeIcon = styled(({ hasLiked, ...props }) => <Icon {...props} />)`
  color: ${(props) => props.hasLiked && props.color}!important;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: ${(props) => (props.hasLiked ? "red" : props.color)}!important;
    transition: 0.2s;
  }
`;

export const Delete = styled.span`
  float: right;
  color: red;
  line-height: 25px;
  position: absolute;
  right: 10px;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Private = styled.span`
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
`;
