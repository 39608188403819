import React from "react";
import { Tabs, Popover } from "antd";
import { observer } from "mobx-react";
import { observable } from "mobx";
import PropTypes from "prop-types";
import PercentageCircle from "components/PercentageCircle";

import { TabPane, Content, CircleWrapper, Title } from "./styles";
import TrendGraph from "./TrendGraph";

@observer
class Habits extends React.Component {
  @observable page = 1;
  @observable total = 0;
  @observable goals = [];
  @observable loading = false;

  render() {
    const { habits, color, archivedHabits } = this.props;
    debugger;
    return (
      <Tabs
        style={{
          width: window.innerWidth < 500 ? "100%" : "70%"
        }}
        tabBarStyle={{
          marginBottom: 0
        }}
      >
        <TabPane tab="Habits" key="1">
          <Content>
            {habits.map((h, i) => (
              <Popover
                key={h.id}
                title={
                  !h.locked && h.raters_count >= 3 && <Title>{h.name}</Title>
                }
                content={
                  !h.locked &&
                  h.raters_count >= 3 && <TrendGraph habit={h} i={i} />
                }
              >
                <CircleWrapper>
                  <PercentageCircle
                    name={h.name}
                    percent={h.locked ? null : h.score}
                    locked={h.locked}
                    unlockThreshold={h.unlock_threshold}
                    lastPercent={h.last_score}
                    color={color}
                    size={window.innerWidth < 500 ? "small" : null}
                    raters={h.raters_count}
                    responses={h.response_count}
                  />
                </CircleWrapper>
              </Popover>
            ))}
          </Content>
        </TabPane>
        <TabPane tab="Archived Habits" key="2">
          <Content>
            {archivedHabits.map((h) => (
              <CircleWrapper key={h.id}>
                <PercentageCircle
                  name={h.name}
                  percent={h.locked ? null : h.score}
                  color={color}
                  raters={10}
                  responses={h.response_count}
                />
              </CircleWrapper>
            ))}
          </Content>
        </TabPane>
      </Tabs>
    );
  }
}

Habits.propTypes = {
  habits: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  archivedHabits: PropTypes.array.isRequired
};

export default Habits;
