/* eslint-disable class-methods-use-this */
import { observable, action, computed } from "mobx";
import { TutorialView } from "services/api";

import profileStore from "stores/profileStore";

class TutorialStore {
  @observable viewedTutorials: string[] = [];
  @observable errors = [];
  @observable loading = false;
  @observable tutorialQueue: string[] = [];

  @action
  async fetchViewedTutorials() {
    this.loading = true;
    const tutorialViews = await TutorialView.all();
    this.viewedTutorials = tutorialViews.data;
    this.loading = false;
  }

  showShowDeepDiveTutorial(): boolean {
    const {
      currentUser: { viewed_tutorials }
    } = profileStore;
    if (!viewed_tutorials) return false;
    return !viewed_tutorials.includes("how_to_interpret_feedback");
  }
  shouldShowFeedbackTutorial(): boolean {
    const { currentUser } = profileStore;
    if (!currentUser.viewed_tutorials) return false;

    return (
      !currentUser.has_responded &&
      !currentUser.viewed_tutorials.includes("how_to_give_feedback")
    );
  }
  shouldShowNetworkTutorial(): boolean {
    return false;
    // return (
    //   currentUser.habits.length > 0 &&
    //   !this.viewedTutorials.includes("how_to_setup_feedback_network")
    // );
  }
  shouldShowAccountSetupTutorial(): boolean {
    const { currentUser } = profileStore;
    if (!currentUser.viewed_tutorials) return false;

    return !currentUser.viewed_tutorials.includes("how_to_setup_account");
  }
  shouldShowProductTour(): boolean {
    const { currentUser } = profileStore;
    if (!currentUser.viewed_tutorials) return false;

    return !currentUser.viewed_tutorials.includes("product_tour");
  }
  shouldShowLockedTutorial(habit): boolean {
    const { currentUser } = profileStore;
    if (!currentUser.viewed_tutorials) return false;
    return (
      !habit.date_unlocked &&
      !currentUser.viewed_tutorials.includes("feedback_locked")
    );
  }

  runTutorial(name: string): void {
    const { currentUser } = profileStore;
    this.tutorialQueue = [...this.tutorialQueue, name];
    if (!this.viewedTutorials.includes(name)) {
      currentUser.viewed_tutorials = [...currentUser.viewed_tutorials, name];
    }
  }

  @action.bound
  endCurrentTutorial(): void {
    const name = this.tutorialQueue[0];
    TutorialView.create({ name });

    this.tutorialQueue = this.tutorialQueue.slice(1);
  }

  @computed get feedbackTutorialRunning(): boolean {
    return this.runningTutorial === "how_to_give_feedback";
  }

  @computed get runningTutorial(): string | undefined {
    return this.tutorialQueue[0];
  }
}

export default new TutorialStore();
