import React, { useState } from "react";
import useStores from "utils/useStores";
import { Icon, Popover, Typography } from "antd";
import styled from "styled-components";
import media, { lightenDarkenColor } from "utils/style-utils";
import blackOrWhite from "utils/blackOrWhite";
import Carrot from "assets/carrot.png";
import LoadingOverlay from "components/LoadingOverlay";
import { Profile } from "services/api";

const Container = styled.div`
  background: ${({ theme }) => lightenDarkenColor(theme.primary_color, 80)};
  display: flex;
  padding: 12px;
  border-radius: 8px;
  margin: 0 24px;
  position: relative;
  cursor: pointer;

  ${media.handheld`
		margin: 0 12px;
	`}
`;

const Content = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  max-width: 250px;
  flex-direction: column;
`;

const CarrotImg = styled.img`
  width: 28px;
  height: auto;
  margin-left: 8px;
`;

const CarrotValue = styled.div`
  display: flex;
  font-size: 24px;
  margin: 12px 0;
`;

const OverlayContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) =>
    blackOrWhite(lightenDarkenColor(theme.primary_color, 80))} !important;
  font-size: 18px;
`;

const ClaimedOverlay = () => (
  <OverlayContainer>
    <Icon type="check" style={{ fontSize: 20, color: "white" }} />
  </OverlayContainer>
);

const DailyLogin = () => {
  /* eslint-disable no-unused-vars */
  const { profileStore } = useStores();
  const [visible, setVisible] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [claimed, setClaimed] = useState(false);
  const [loading, setLoading] = useState(false);

  if (!profileStore.currentUser.daily_login_elligible) {
    return null;
  }

  const onMouseEnter = () => {
    setVisible(true);
  };

  const onMouseLeave = () => {
    if (!clicked) {
      setVisible(false);
    }
  };

  const setLoginBonusClaimed = async () => {
    Profile.claimLoginBonus();
    profileStore.currentUser.feedback_streak += 3;
  };

  const claimLoginBonus = async () => {
    if (!claimed) {
      setClicked(true);
      setLoading(true);
      await setLoginBonusClaimed();

      setClaimed(true);
      setLoading(false);
    }
  };

  const handleVisibleChange = () => {
    if (clicked) {
      setClicked(false);
      setVisible(false);
    }
  };

  let title = "Click to claim your off-day login bonus!";

  if (claimed) {
    title = "Claimed!";
  }

  return (
    <Popover
      trigger="click"
      onVisibleChange={handleVisibleChange}
      visible={visible}
      content={
        <Content>
          <Typography.Title style={{ textAlign: "center" }} level={4}>
            {title}
            {loading && <LoadingOverlay />}
          </Typography.Title>
          <CarrotValue>
            +3 <CarrotImg src={Carrot} style={{ marginTop: -6 }} />
          </CarrotValue>
          <Typography.Paragraph style={{ textAlign: "center" }}>
            Visit Rhabit on days where you aren&apos;t prompted to give feedback
            to earn bonus carrots.
          </Typography.Paragraph>
        </Content>
      }
    >
      <Container
        onClick={claimLoginBonus}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {claimed && <ClaimedOverlay />}
        <StyledIcon type="gift" />
      </Container>
    </Popover>
  );
};

export default DailyLogin;
