import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { motion, useAnimation } from "framer-motion";
import { withTheme } from "styled-components";

import MotionModal from "components/MotionModal";
import LockShackle from "assets/lockshackle.svg";
import LockBase from "assets/lockbase.svg";
import AnimatedCircle from "./animatedCircle";

/* eslint-disable */

const HabitUnlockModal = ({ visible, onClose, habit, theme }) => {
  const controls = useAnimation();
  const baseControls = useAnimation();
  const textControls = useAnimation();
  const [shouldAnimate, setShouldAnimate] = useState(false);

  let scoreContent;
  if (habit && habit.score < 60) {
    scoreContent =
      "We all have to start somewhere. Your score isn't great - but where you go from here matters much more than your score today. Head on over to the dashboard for some help and support to improve. Remember: We're here to help any way we can.";
  } else if (habit && habit.score > 60 && habit.score < 80) {
    scoreContent =
      "Hurray for feedback! Your score is solid! There's always room for improvement, but you're doing great! Head over to the habit dashboard for to check out more details!";
  } else if (habit) {
    scoreContent =
      "Awesome! Your score for this habit has unlocked at the highest tier. That's great! Just remember everyone has bad days, and the only constant is change. Try to stay humble and hungry to improve!";
  }

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        baseControls.start({
          transition: { duration: 0.5 },
          transform: [
            "translate3d(1px, 0, 0)",
            "translate3d(-1px, 0, 0)",
            "translate3d(1px, 0, 0)",
            "translate3d(-1px, 0, 0)",
            "translate3d(1px, 0, 0)",
            "translate3d(-1px, 0, 0)",
            "translate3d(1px, 0, 0)",
            "translate3d(-1px, 0, 0)"
          ]
        });
      }, 1500);
      setTimeout(() => {
        controls.start({
          rotateY: "180deg",
          transformOrigin: "bottom left",
          duration: 0.2
        });
        setTimeout(() => {
          controls.start({ opacity: 0, transition: { duration: 0.2 } });
          baseControls.start({ opacity: 0, transition: { duration: 0.2 } });
          textControls.start({ opacity: 0, transition: { duration: 0.2 } });
          setShouldAnimate(true);
        }, 1000);
      }, 2500);
    }
  }, [visible]);

  return (
    <MotionModal visible={visible} onClose={onClose} top>
      <h3 style={{ margin: "12px auto" }}>
        You&apos;ve unlocked a new habit score!
      </h3>
      <div
        style={{
          position: "relative",
          height: 300,
          width: "100%",
          paddingTop: 60
        }}
      >
        {habit && (
          <div style={{ position: "absolute", left: "calc(50% - 75px)" }}>
            <AnimatedCircle
              score={habit.score}
              shouldAnimate={shouldAnimate}
              lastPercent={habit.last_score}
              raters={habit.raters_count}
              name={habit.name}
              color={theme.primary_color}
              size={window.innerWidth < 500 ? "small" : null}
              responses={habit.response_count}
            />
          </div>
        )}
        <motion.img
          style={{
            width: 25,
            height: "auto",
            position: "absolute",
            top: 94,
            left: "calc(50% - 12.5px)"
          }}
          src={LockShackle}
          animate={controls}
        />
        <motion.img
          style={{
            width: 40,
            height: "auto",
            position: "absolute",
            top: 111,
            left: "calc(50% - 20px)"
          }}
          src={LockBase}
          animate={baseControls}
          transition={{ duration: 1 }}
        />
      </div>
      <motion.p
        initial="hidden"
        animate={shouldAnimate ? "visible" : "hidden"}
        variants={{
          visible: {
            opacity: 1,
            transition: {
              when: "beforeChildren",
              staggerChildren: 0.3
            }
          },
          hidden: {
            opacity: 0,
            transition: {
              when: "afterChildren"
            }
          }
        }}
        style={{ textAlign: "center" }}
      >
        {scoreContent}
      </motion.p>
    </MotionModal>
  );
};

HabitUnlockModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  habit: PropTypes.object.isRequired
};

export default withTheme(HabitUnlockModal);
