/* eslint-disable no-debugger */

/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import Okay from "assets/OKAY.svg";
import { Button } from "antd";
import { HabitUser } from "types/shared";
import { AdditionalFeedback } from "services/api";
import { Flair } from "components/Layout/styles";
import { ReactComponent as Third } from "assets/dashboard/high_five.svg";
import { ContentContainer, Header } from "../styles";
import { useTranslation } from "react-i18next";

type SendRequestsProps = {
  habit: HabitUser;
  promptId: string;
  close: () => void;
};

const SendRequests = ({ habit, promptId, close }: SendRequestsProps) => {
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const handleSend = async () => {
    const params = {
      additional_feedback: {
        habit_user_id: habit.habit_user_id,
        prompt_id: promptId
      }
    };
    let res;
    try {
      res = await AdditionalFeedback.create(params);
      close();
    } catch (err) {
      const msg = JSON.parse(JSON.stringify(err)).response.data.message;
      if (msg) {
        setError(msg);
      }
    }
  };

  return (
    <ContentContainer style={{ flexDirection: "column" }}>
      <Header data-cy="step4title">
        {t("deep_dive.more_feedback_wizard.good_to_go")}
      </Header>
      <Flair style={{ maxHeight: "unset" }}>
        <Third />
      </Flair>
      <Button
        onClick={handleSend}
        type="primary"
        style={{ marginBottom: "12px" }}
      >
        {t("deep_dive.more_feedback_wizard.send_requests")}
      </Button>
      <div
        style={{
          fontSize: window.innerWidth < 500 && 14,
          width: window.innerWidth < 500 ? "100%" : "50%",
          textAlign: "center"
        }}
      >
        <Header>
          {t("deep_dive.more_feedback_wizard.send_requests_header")}
        </Header>
      </div>
      {error && <span style={{ color: "red" }}>{error}</span>}
    </ContentContainer>
  );
};

export default SendRequests;
