import styled from "styled-components";
import { media } from "utils/style-utils";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid #e8e8e8;
  justify-content: space-between;

  ${media.handheld`
    flex-direction: column;
  `}
`;

export const LeftSide = styled.div`
  display: flex;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.span`
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
`;

export const Description = styled.a``;

export const Descriptor = styled.span.attrs({ className: "colored-tag" })`
  cursor: ${props => props.noLink || "pointer"};
  color: ${props => props.noLink && "rgba(0, 0, 0, 0.45) !important"};
  &:after {
    content: "  |  ";
    white-space: pre;
  }

  &:last-child:after {
    content: "";
  }
`;

export const RightSide = styled.div`
  display: flex;
  ${media.handheld`
    margin-top: 12px;
  `}
`;

export const RightItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 40px;
  ${media.handheld`
  padding-left: 10px;
  margin-right: 0;
  `}
`;

export const ItemHeader = styled.span`
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 400;
  ${media.handheld`
  text-align: center;
  `}
`;

export const ItemValue = styled.span`
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  ${media.handheld`
  text-align: center;
  `}
`;
