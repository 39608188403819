import styled from "styled-components";
import { lightenDarkenColor } from "utils/style-utils";

// eslint-disable-next-line import/prefer-default-export
export const Body = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const ActionWrapper = styled.div`
  width: 100%;
  padding: 12px 24px;
  border-radius: 6px;
  background-image: ${props =>
    `linear-gradient(to bottom,${lightenDarkenColor(
      props.theme.primary_color,
      20
    )}, ${lightenDarkenColor(props.theme.primary_color, 0)})`};
`;

export const Title = styled.span`
  font-size: 36px;
  color: white;
  font-weight: 500;
`;
