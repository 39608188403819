/* eslint-disable */
import * as Types from '../../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type IFetchHabitDeltasQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IFetchHabitDeltasQuery = (
  { __typename?: 'Query' }
  & { managerDashboard: (
    { __typename?: 'ManagerDashboard' }
    & Pick<Types.IManagerDashboard, 'habitDeltas'>
  ) }
);


export const FetchHabitDeltasDocument = gql`
    query fetchHabitDeltas {
  managerDashboard {
    habitDeltas
  }
}
    `;

/**
 * __useFetchHabitDeltasQuery__
 *
 * To run a query within a React component, call `useFetchHabitDeltasQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchHabitDeltasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchHabitDeltasQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchHabitDeltasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IFetchHabitDeltasQuery, IFetchHabitDeltasQueryVariables>) {
        return ApolloReactHooks.useQuery<IFetchHabitDeltasQuery, IFetchHabitDeltasQueryVariables>(FetchHabitDeltasDocument, baseOptions);
      }
export function useFetchHabitDeltasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IFetchHabitDeltasQuery, IFetchHabitDeltasQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IFetchHabitDeltasQuery, IFetchHabitDeltasQueryVariables>(FetchHabitDeltasDocument, baseOptions);
        }
export type FetchHabitDeltasQueryHookResult = ReturnType<typeof useFetchHabitDeltasQuery>;
export type FetchHabitDeltasLazyQueryHookResult = ReturnType<typeof useFetchHabitDeltasLazyQuery>;
export type FetchHabitDeltasQueryResult = ApolloReactCommon.QueryResult<IFetchHabitDeltasQuery, IFetchHabitDeltasQueryVariables>;