import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "antd";
import nameToInitials from "utils/nameToInitials";
import HighfiveItem from "../FeedList/HighFiveItem";
import {
  Wrapper,
  MyStats,
  AvatarWrapper,
  SendReceiveStats,
  SendStat,
  Value,
  Label,
  TopHighfivers,
  TopReceivers,
  ListItem,
  Name,
  Number,
  TopSenders,
  TopLiked,
  User,
  Title
} from "./styles";

const generateKey = (index) => {
  return `${index}_${new Date().getTime()}`;
};

class HighfiveHighlights extends React.Component {
  static propTypes = {
    stats: PropTypes.shape({
      user_stats: PropTypes.shape({
        avatar: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        sent: PropTypes.number.isRequired,
        recieved: PropTypes.number
      }),
      top_receivers: PropTypes.array,
      top_senders: PropTypes.array,
      most_liked: PropTypes.object
    }),
    onLike: PropTypes.func.isRequired
  };

  static defaultProps = {
    stats: {
      user_stats: {
        recieved: null,
        avatar: ""
      },
      top_receivers: null,
      top_senders: null,
      most_liked: {}
    }
  };

  componentDidMount() {
    setTimeout(() => {
      this.forceUpdate();
    }, 300);
  }

  render() {
    const { stats, onLike } = this.props;
    return (
      <Wrapper>
        <MyStats>
          <Title>Your Stats</Title>
          <AvatarWrapper>
            <Avatar
              style={{
                minWidth: "32px !important"
              }}
              src={stats.user_stats.avatar}
            >
              <span
                style={{
                  fontSize: "25px !important"
                }}
              >
                {nameToInitials(stats.user_stats.name)}
              </span>
            </Avatar>
          </AvatarWrapper>
          <SendReceiveStats>
            <SendStat>
              <Value>{stats.user_stats.sent}</Value>
              <Label>Sent</Label>
            </SendStat>
            <SendStat>
              <Value>{stats.user_stats.received}</Value>
              <Label>Received</Label>
            </SendStat>
          </SendReceiveStats>
        </MyStats>
        <Title>High-five Stats (Last 30 Days)</Title>
        <TopHighfivers>
          <TopReceivers>
            <Title>Top Receivers</Title>
            {stats.top_receivers
              .sort((a, b) => (a.received > b.received ? -1 : 1))
              .map((receiver) => (
                <ListItem key={generateKey(receiver.name)}>
                  <User>
                    <Avatar
                      style={{
                        marginRight: 6
                      }}
                      src={receiver.avatar}
                    >
                      <span
                        style={{
                          fontSize: "25px !important"
                        }}
                      >
                        {nameToInitials(receiver.name)}
                      </span>
                    </Avatar>
                    <Name>{receiver.name}</Name>
                  </User>
                  <Number>{receiver.received}</Number>
                </ListItem>
              ))}
          </TopReceivers>
          <TopSenders>
            <Title>Top Senders</Title>
            {stats.top_senders
              .sort((a, b) => (a.sent > b.sent ? -1 : 1))
              .map((sender) => (
                <ListItem key={generateKey(sender.name)}>
                  <User>
                    <Avatar
                      style={{
                        marginRight: 6
                      }}
                      src={sender.avatar}
                    >
                      <span
                        style={{
                          fontSize: "25px !important"
                        }}
                      >
                        {nameToInitials(sender.name)}
                      </span>
                    </Avatar>
                    <Name>{sender.name}</Name>
                  </User>
                  <Number>{sender.sent}</Number>
                </ListItem>
              ))}
          </TopSenders>
        </TopHighfivers>
        {stats.most_liked && (
          <TopLiked>
            <Title>Most Liked High-five</Title>
            <HighfiveItem
              noComments
              onLike={() => onLike(stats.most_liked)}
              highfive={stats.most_liked}
              noDelete
            />
          </TopLiked>
        )}
      </Wrapper>
    );
  }
}

export default HighfiveHighlights;
