import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding-top: 24px;
  flex-direction: column;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const FormWrapper = styled.div`
  width: 100%;
  padding: 12px 24px;
  background: #f2f2f2;
  border: 1px solid #eee;
  border-radius: 4px;
  margin-top: 12px;
`;

export const HeaderText = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
`;