import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  z-index: 2;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border-bottom: 1px solid #e8e8e8;
`;

export const UserInfo = styled.div`
  display: flex;
  height: 100%
  padding: 6px 0;
`;

export const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 12px;
`;

export const Header = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const Description = styled.span`
  font-size: 16px;
`;