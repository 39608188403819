import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      // const body = document.getElementById("main-body")
      if (document.getElementById("main-body")) {
        document.getElementById("main-body").scrollTop = 0;
      }
      setTimeout(() => {}, 100);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ScrollToTop.defaultProps = {
  children: null
};

ScrollToTop.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default withRouter(ScrollToTop);
