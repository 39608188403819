import React from "react";
import { inject, observer } from "mobx-react";
import LoadingOverlay from "components/LoadingOverlay";

const withObjective = (WrappedComponent) => {
  class HOC extends React.Component {
    constructor(props) {
      super(props);
      const { objectiveStore, objective } = props;

      this.ob = null;
      if (!objective) {
        this.state = { loading: true };
      } else if (objectiveStore.data.objective[objective.id]) {
        this.state = {
          loading: false,
          objective: objectiveStore.getObjective(objective.id)
        };
      } else {
        this.state = { loading: true, objective: null };
        objectiveStore.fetchObjective(objective.id).then(() => {
          this.setState({
            objective: objectiveStore.getObjective(objective.id),
            loading: false
          });
        });
      }
    }

    componentWillReceiveProps(nextProps) {
      const { objective, objectiveStore } = this.props;

      if (nextProps.objective && objective !== nextProps.objective) {
        if (objectiveStore.data.objective[nextProps.objective.id]) {
          this.setState({
            loading: false,
            objective: objectiveStore.getObjective(nextProps.objective.id)
          });
        } else {
          this.setState({ loading: true, objective: null });
          objectiveStore.fetchObjective(objective.id).then(() => {
            this.setState({
              objective: objectiveStore.getObjective(nextProps.objective.id),
              loading: false
            });
          });
        }
      }
    }

    render() {
      const { loading, objective } = this.state;
      if (loading) {
        return <LoadingOverlay />;
      }
      return <WrappedComponent {...this.props} objective={objective} />;
    }
  }

  return inject("objectiveStore")(observer(HOC));
};

export default withObjective;
