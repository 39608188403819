import React from "react";
import { Divider } from "antd";
import moment from "moment";
import styled from "styled-components";
import { Post } from "./types";

interface Props {
  post: Post;
}

const Container = styled.div`
	width: 100%:
	display: flex;
	flex-direction: column;
	line-height: 1.5;
`;

const Image = styled.img`
  width: 100%;
  height: calc(width);
  border-radius: 6px;
  margin: 12px 0;
`;

const Title = styled.div`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
`;

const CreatedAt = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
`;
const PostView: React.FC<Props> = ({
  post: { hero_image: heroImage, title, content, created_at: createdAt }
}) => (
  <>
    <Container>
      <Title>{title}</Title>
      <CreatedAt>{moment(createdAt).format("MMM Do, YYYY")}</CreatedAt>
      <Image src={heroImage.url} />
      <div>{content}</div>
    </Container>
    <Divider />
  </>
);

export default PostView;
