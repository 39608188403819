/* eslint-disable no-param-reassign */
import React from "react";
import { Table, Tooltip, Icon, Card } from "antd";
import { withRouter } from "react-router-dom";
import viewModal from "components/FullScreenModal";
import styled from "styled-components";
import PropTypes from "prop-types";

const Score = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  background-color: ${(props) => props.color};
  cursor: pointer;
`;

const Star = styled(Icon)`
  font-size: 20px;
  color: #78d36f !important;
  margin-right: 5px;
  cursor: pointer;
`;

const color = (score) => {
  if (score < 67) {
    return "#FF9331";
  }
  if (score < 80) {
    return "#FDCE60";
  }
  return "#78D36F";
};

const sort = (users) => {
  const usersByPerformance = users.reduce((obj, u) => {
    obj[u.id] = u.habits.reduce(
      (count, habit) => {
        if (typeof habit.score === "number" && habit.score < 65) {
          count.weak += 1;
        } else if (typeof habit.score === "number" && habit.score < 80) {
          count.average += 1;
        }
        return count;
      },
      { weak: 0, average: 0 }
    );
    return obj;
  }, {});

  return users.sort((a, b) => {
    if (usersByPerformance[a.id].weak > usersByPerformance[b.id].weak) {
      return -1;
    }
    if (usersByPerformance[a.id].weak === usersByPerformance[b.id].weak) {
      if (usersByPerformance[a.id].average > usersByPerformance[b.id].average) {
        return -1;
      }
      return 1;
    }
    return 1;
  });
};

const TeamMembers = ({ users, smaller, history, goToUser }) => {
  const columns = [
    {
      title: "User",
      key: "id",
      dataIndex: "name",
      width: 150,
      render: (name, user) => {
        if (smaller) {
          return (
            <span
              role="presentation"
              style={{ cursor: "pointer" }}
              className="colored-tag"
              onClick={() => goToUser(user.id)}
            >
              {name}
            </span>
          );
        }
        return (
          <span
            role="presentation"
            style={{ cursor: "pointer" }}
            className="colored-tag"
            onClick={() => viewModal({ id: user.id, type: "user" }, history)}
          >
            {name}
          </span>
        );
      }
    },
    {
      title: "Habits",
      width: 150,
      // eslint-disable-next-line react/prop-types
      render: ({ habits }) => (
        <div style={{ display: "flex" }}>
          {habits
            .filter((h) => h.score !== null)
            .sort((a, b) => (a.score < b.score ? -1 : 1))
            .map((h) => (
              <Tooltip key={h.id} title={`${h.name} (${h.score}%)`}>
                {h.score >= 95 ? (
                  <Star type="star" theme="filled" />
                ) : (
                  <Score color={color(h.score)} />
                )}
              </Tooltip>
            ))
            .concat(
              habits
                .filter((h) => h.score === null || h.responses < 6)
                .map((h) => (
                  <Tooltip title={`${h.name} (Pending)`}>
                    <Score>
                      <Icon type="lock" />
                    </Score>
                  </Tooltip>
                ))
            )}
        </div>
      ),
      sorter: (a, b) => {
        const validDataA = a.habits.reduce(
          (obj, h) => {
            if (h.score !== null) {
              return { sum: obj.sum + h.score, count: obj.count + 1 };
            }
            return obj;
          },
          { sum: 0, count: 0 }
        );
        const validDataB = b.habits.reduce(
          (obj, h) => {
            if (h.score !== null) {
              return { sum: obj.sum + h.score, count: obj.count + 1 };
            }
            return obj;
          },
          { sum: 0, count: 0 }
        );
        return (
          validDataA.sum / validDataA.count - validDataB.sum / validDataB.count
        );
      }
    },
    {
      title: "Response Rate",
      key: "response_rate",
      dataIndex: "response_rate",
      width: 150,
      render: (response_rate) =>
        response_rate !== null
          ? `${Math.round(response_rate * 100)}%`
          : "No Data",
      sorter: (a, b) => a.response_rate - b.response_rate
    }
  ];

  const height = smaller ? 300 : 400;

  return (
    <Card title="Team Members" bodyStyle={{ height: height - 56, padding: 0 }}>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={sort(users)}
        pagination={false}
        size="small"
        scroll={{ y: height - (56 + 37) }}
        style={{ padding: 0, height: height - 56 }}
      />
    </Card>
  );
};

TeamMembers.propTypes = {
  users: PropTypes.array.isRequired,
  smaller: PropTypes.bool,
  history: PropTypes.object,
  goToUser: PropTypes.func
};

TeamMembers.defaultProps = {
  smaller: false,
  goToUser: () => {},
  history: {}
};

export default withRouter(TeamMembers);

export { TeamMembers };
