import React from "react";
import { Popover, Icon } from "antd";
import styled from "styled-components";
import { HabitUserLite } from "types/shared";
import Cascader from "./cascader";
import Wrapper from "../../../layout/Wrapper";
import { TriggerText } from "./styles";
import { useTranslation } from "react-i18next";

type inverseHabitSelectorProps = {
  isAdmin: boolean;
  external: boolean;
  allHabits: HabitUserLite[];
  providingHabits: HabitUserLite[];
  selected: string[];
  persistChanges: (ids: string[]) => void;
};

const Italic = styled.span`
  font-style: italic;
`;

const CascaderWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const HabitList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.div`
  padding: 5px 12px;
  line-height: 22px;
  font-size: 16px;
`;

const inverseHabitSelector = ({
  isAdmin,
  external,
  allHabits,
  selected,
  persistChanges,
  providingHabits
}: inverseHabitSelectorProps) => {
  const { t } = useTranslation();
  if (external) return null;

  if (isAdmin) {
    return (
      <Wrapper style={{ alignItems: "center" }}>
        <Italic>{t("relationships.you_provide_feedback_on")}: </Italic>
        <CascaderWrapper id="relationship-modal">
          <Cascader
            dataCy="admin-habit-select"
            allHabits={allHabits}
            selected={selected}
            persistChanges={persistChanges}
          />
        </CascaderWrapper>
      </Wrapper>
    );
  }

  const habitList = (
    <HabitList>
      {providingHabits.map((p) => (
        <ListItem>{p.habit.name}</ListItem>
      ))}
    </HabitList>
  );

  return (
    <Wrapper style={{ alignItems: "center", textAlign: "center" }}>
      <span style={{ fontStyle: "italic" }}>
        {t("relationships.you_provide_feedback_on")}{" "}
      </span>
      <Popover trigger={["click"]} content={habitList} placement="bottom">
        <TriggerText>
          {providingHabits.length} {t("habits")} <Icon type="down" />
        </TriggerText>
      </Popover>
    </Wrapper>
  );
};

const memoized = React.memo<inverseHabitSelectorProps>(inverseHabitSelector);

export default memoized;
