import React, { useState, useEffect } from "react";
import { Modal, DatePicker, Typography, Button, Icon } from "antd";
import Box from "layout/Box";
import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment";
import { useCreateVacationMutation } from "./mutations/createVacation.generated";
import { useFetchUserVacationsQuery } from "./queries/fetchUserVacations.generated";
import { useCancelVacationMutation } from "./mutations/cancelVacation.generated";

interface Props {
  visible: boolean;
  onCancel: () => void;
}

const VacationModal: React.FC<Props> = ({ visible, onCancel }) => {
  const [startDate, setStartDate] = useState<Moment | undefined>(undefined);
  const [endDate, setEndDate] = useState<Moment | undefined>(undefined);
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation();

  const [createVacation] = useCreateVacationMutation();
  const [cancelVacation] = useCancelVacationMutation();
  const { data, loading, error, refetch } = useFetchUserVacationsQuery();

  const validateDates = (): { valid: boolean; message: string } => {
    if (!startDate || !endDate) {
      return { valid: false, message: "" };
    }
    if (startDate.isAfter(endDate)) {
      return { valid: false, message: "Start date cannot be after end date" };
    }
    if (Math.abs(startDate.diff(endDate, "days")) > 28) {
      return {
        valid: false,
        message: "Vacation cannot be longer than 4 weeks"
      };
    }

    if (Math.abs(startDate.diff(endDate, "days")) === 0) {
      return {
        valid: false,
        message: "Vacation cannot start and end on the same day"
      };
    }
    return { valid: true, message: "" };
  };

  const disabledStartDate = (date: Moment | undefined): boolean => {
    if (!date) return false;
    return date.isBefore(moment(), "day");
  };

  useEffect(() => {
    if (!visible) {
      setSubmitted(false);
    }
  }, [visible]);

  const handleSubmit = async (): Promise<void> => {
    if (!startDate || !endDate) return;

    await createVacation({
      variables: {
        input: { startDate: startDate.toString(), endDate: endDate.toString() }
      }
    });
    refetch();
    setSubmitted(true);
  };
  const currentVacation = data && data.profile.upcomingVacations[0];
  const handleCancelVacation = async (): Promise<void> => {
    if (!currentVacation) return;
    await cancelVacation({ variables: { input: { id: currentVacation.id } } });
    refetch();
  };

  const hasVacations = data && data.profile.upcomingVacations.length > 0;

  let content;
  if (submitted) {
    content = (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding="0 24px"
      >
        <Icon
          type="check-circle"
          theme="filled"
          style={{ color: "#40cc75", fontSize: 64, marginBottom: 12 }}
        />
        <Typography.Title level={4}>
          {t("vacation_form.vacation_created")}
        </Typography.Title>
        <Button
          type="primary"
          size="large"
          onClick={onCancel}
          style={{ marginTop: 24 }}
        >
          {t("vacation_form.close_button")}
        </Button>
      </Box>
    );
  } else if (hasVacations) {
    content = (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography>
          {t("vacation_form.already_on_vacation", {
            startDate:
              currentVacation &&
              moment(currentVacation.startDate).format("MM/DD/YYYY"),
            endDate:
              currentVacation &&
              moment(currentVacation.endDate).format("MM/DD/YYYY")
          })}
        </Typography>
        <Button
          type="danger"
          size="large"
          style={{ marginTop: 24 }}
          onClick={handleCancelVacation}
        >
          {t("vacation_form.cancel_vacation")}
        </Button>
      </Box>
    );
  } else {
    content = (
      <>
        <Typography>{t("vacation_form.description")}</Typography>
        <Typography style={{ fontStyle: "italic", margin: "12px 0" }}>
          {t("vacation_form.note")}
        </Typography>
        <Box>
          <Box display="flex" alignItems="center" marginBottom={12}>
            <Box width={100}>{t("vacation_form.start_date")}</Box>
            <DatePicker
              value={startDate}
              onChange={(date): void => setStartDate(date as Moment)}
              disabledDate={disabledStartDate}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Box width={100}>{t("vacation_form.end_date")} </Box>
            <DatePicker
              value={endDate}
              onChange={(date): void => setEndDate(date as Moment)}
            />
          </Box>
        </Box>
        <span style={{ color: "red" }}>{validateDates().message}</span>
      </>
    );
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      cancelText={t("cancel")}
      okText={t("vacation_form.schedule_vacation")}
      title={t("vacation_form.title")}
      okButtonProps={{ disabled: !validateDates().valid }}
      onOk={handleSubmit}
      footer={
        !hasVacations &&
        !submitted && [
          <Button onClick={onCancel}>{t("cancel")}</Button>,
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={!validateDates().valid}
          >
            {t("vacation_form.schedule_vacation")}
          </Button>
        ]
      }
    >
      {content}
    </Modal>
  );
};

export default VacationModal;
