import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { WorkItems } from "services/api";
import history from "services/history";
import Relationships from "containers/Relationships";
import viewModal from "components/FullScreenModal";
import { ReactComponent as FlairImg } from "assets/dashboard/check_current_feedback_providers.svg";
import { ItemWrapper, Title, ButtonWrapper, Content, Flair } from "../styles";
import { useTranslation } from "react-i18next";

const NetworkHealthItem = ({ markCompleted, itemId }) => {
  const { t } = useTranslation();

  const discardItem = () => {
    WorkItems.discard(itemId);
    markCompleted();
  };

  const openRelationships = () => {
    viewModal(
      { width: 900, onClose: () => discardItem() },
      history,
      <Relationships />
    );
  };

  return (
    <ItemWrapper>
      <Content>
        <Title>{t("homepage.action_items.network_health.prompt_text")}</Title>
        <p>{t("homepage.action_items.network_health.prompt_subtext")}</p>
        <ButtonWrapper>
          <Button style={{ marginRight: 24 }} onClick={discardItem}>
            {t("homepage.action_items.network_health.dismiss_text")}
          </Button>
          <Button onClick={openRelationships} type="primary">
            {t("homepage.action_items.network_health.action_text")}
          </Button>
        </ButtonWrapper>
      </Content>
      <Flair>
        <FlairImg />
      </Flair>
    </ItemWrapper>
  );
};

NetworkHealthItem.propTypes = {
  markCompleted: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired
};

export default NetworkHealthItem;
