import styled from "styled-components";
import { media } from "utils/style-utils";

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 400px;

  ${media.handheld`
    flex-direction: column;
    min-height: unset;
  `}
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(30% - 24px);
  ${media.handheld`
    width: 100%;
  `}
`;