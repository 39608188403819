import React, { useEffect, useState } from "react";
import { Button, Icon } from "antd";
import PropTypes from "prop-types";
import { motion, useAnimation } from "framer-motion";

const sizes = {
  default: 40,
  large: 50
};

const Launcher = ({ onOpen, isOpen, size, icon }) => {
  const controls = useAnimation();
  const [currentIcon, setCurrentIcon] = useState(icon);
  useEffect(() => {
    setTimeout(() => {
      setCurrentIcon(isOpen ? "close" : icon);
    }, 250);
    controls.start({
      rotate: isOpen ? 360 : -360,
      transition: { duration: 0.5 }
    });
  }, [isOpen]);
  return (
    <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
      <Button
        style={{
          zIndex: 103,
          height: sizes[size],
          width: sizes[size],
          boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"
        }}
        shape="circle"
        type="primary"
        size={size}
        onClick={onOpen}
      >
        <motion.div animate={controls}>
          <Icon type={currentIcon} />
        </motion.div>
      </Button>
    </motion.div>
  );
};

Launcher.propTypes = {
  onOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

export default Launcher;
