import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 24px;
  border-bottom: 1px solid #e8e8e8;
  justify-content: space-between;
`;

export const LeftSide = styled.div`
  display: flex;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.span`
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
`;

export const Description = styled.span`
  color: rgba(0, 0, 0, 0.45);
`;

export const RightSide = styled.div`
  display: flex;
`;

export const RightItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
`;

export const ItemHeader = styled.span`
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 400;
`;

export const ItemValue = styled.span`
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
`;