import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Row, Col, Icon, Tooltip, Select } from "antd";

function suggestedBy(arr, id) {
  return arr.includes(id) ? (
    <Tooltip title="Suggested by Manager">
      <Icon type="star" />
    </Tooltip>
  ) : null;
}

const HabitList = (
  {
    habits,
    proposals,
    requiredHabits,
    selectedHabits,
    onSelect,
    suggestedHabits
  },
  type
) => {
  const filtered = habits
    .filter((h) => h.employee_type === type)
    .map((h) => {
      const proposal = proposals.find((p) => p.habit_id === h.id);
      // const isFalse = suggestedHabits.includes(h.id) ? (
      //   <Tooltip title="Suggested by Manager">
      //     <Icon type="star" />
      //   </Tooltip>
      // ) : null;
      if (proposal) {
        return Object.assign({}, h, {
          proposal: Object.assign({}, proposal, {
            checked:
              (proposal.change_type === "add" &&
                ["pending", "approved"].includes(proposal.status)) ||
              (proposal.change_type === "remove" &&
                proposal.status === "rejected")
          })
        });
      }
      return h;
    });
  return (
    <Row style={{ marginTop: 3, marginBottom: 24 }}>
      {filtered.map((h) => (
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ diplay: "flex" }}>
              <Tooltip
                title={
                  <ul>
                    {h.behaviors.map((b) => (
                      <li>{b.description}</li>
                    ))}
                  </ul>
                }
              >
                <Icon
                  type="info-circle"
                  style={{ marginRight: 8, cursor: "pointer" }}
                />
              </Tooltip>
            </div>
            <Checkbox
              disabled={requiredHabits.includes(h.id) || h.proposal}
              checked={
                selectedHabits.find((habit) => habit.id === h.id) &&
                (!h.proposal || (h.proposal && h.proposal.checked))
              }
              value={h.id}
              onChange={onSelect}
            >
              <span style={{ color: "rgba(0, 0, 0, 0.65)" }}>{h.name}</span>
            </Checkbox>
            {requiredHabits.includes(h.id) ? (
              <Tooltip title="Required by Manager">
                <Icon type="pushpin" />
              </Tooltip>
            ) : (
              suggestedBy(suggestedHabits, h.id)
            )}
          </div>
        </Col>
      ))}
    </Row>
  );
};

HabitList.propTypes = {
  suggestedHabits: PropTypes.array.isRequired,
  habits: PropTypes.array.isRequired,
  proposals: PropTypes.array.isRequired,
  requiredHabits: PropTypes.array.isRequired,
  selectedHabits: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired
};

const Habits = (props) => {
  const { selectRole, roleId, asUser, roles, selectUser, users } = props;
  return (
    <div style={{ padding: 15 }}>
      <div style={{ display: "flex" }}>
        {!asUser && (
          <Select
            placeholder="Select A Role"
            onChange={selectRole}
            allowClear
            value={roleId || undefined}
            style={{ width: 200, marginBottom: 15 }}
          >
            {roles
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((role) => (
                <Select.Option key={role.id}>{role.name}</Select.Option>
              ))}
          </Select>
        )}
        {!asUser && (
          <Select
            placeholder="Copy from a user"
            onChange={selectUser}
            allowClear
            showSearch
            style={{ width: 200, marginBottom: 15, marginLeft: 15 }}
          >
            {users
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((user) => (
                <Select.Option key={user.id}>{user.name}</Select.Option>
              ))}
          </Select>
        )}
      </div>
      <div>
        <span style={{ fontWeight: 600 }}>Individual Habits</span>
        {HabitList(props, "IC")}
      </div>
      <span style={{ fontWeight: 600 }}>Leadership Habits</span>
      {HabitList(props, "L")}
      <span style={{ fontWeight: 600, marginBottom: 24 }}>
        Executive Habits
      </span>
      {HabitList(props, "EX")}
      <div style={{ marginTop: "20px" }}>
        <span style={{ fontStyle: "italic" }}>
          Between 4 and 8 habits are recommended
        </span>
      </div>
    </div>
  );
};

Habits.propTypes = {
  selectRole: PropTypes.func.isRequired,
  roleId: PropTypes.string.isRequired,
  asUser: PropTypes.bool.isRequired,
  roles: PropTypes.array.isRequired,
  selectUser: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired
};

export default Habits;
