import React, { useState } from "react";
import { Layout, Form, Input, Button, Typography } from "antd";
import Art from "assets/Pittsburgh_end.svg";
import Logo from "assets/text_black.png";
import { validate } from "graphql";
import { Profile } from "services/api";
import { useHistory } from "react-router-dom";

interface Props {
  code: string;
}

const PasswordReset: React.FC<Props> = ({ code, form }) => {
  const mobile = window.innerWidth < 500;
  const height = window.innerWidth < 500 ? "50vw" : 100;
  const width = window.innerWidth < 500 ? 300 : 425;
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();
  const handleSubmit = async () => {
    const { password, password_confirmation } = form.getFieldsValue();

    try {
      await Profile.resetPassword({
        password,
        password_confirmation,
        reset_token: code
      });
      setSuccess(true);
    } catch (e) {
      setError(true);
    }
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue("password")) {
      callback("Passwords must match");
    } else {
      callback();
    }
  };

  const buttonDisabled = () => {
    const { password, password_confirmation } = form.getFieldsValue();

    if (password !== password_confirmation || password.length < 8) {
      return true;
    }
    return false;
  };

  return (
    <Layout
      style={{
        minHeight: window.innerWidth > 500 && "100vh",
        position: "relative",
        zIndex: 1,
        display: "flex",
        alignItems: "center"
      }}
    >
      <img
        alt="pittsburgh"
        src={Art}
        style={{
          position: "fixed",
          bottom: mobile && window.location.pathname === "/" ? 64 : 0,
          right: !mobile && 0,
          width: mobile ? "100%" : 800,
          height: "auto",
          zIndex: 1
        }}
      />
      <div
        style={{
          marginTop: 100,
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <img
          alt="logo"
          // width=""
          width={200}
          src={Logo}
          style={{ marginBottom: 75 }}
        />
      </div>
      <div
        style={{
          width,
          background: "white",
          borderRadius: 4,
          padding: 25,
          margin: "0 auto",
          position: "relative",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.18)"
        }}
      >
        {success && (
          <div>
            <Typography.Title level={4}>
              Password reset successfully
            </Typography.Title>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={() => history.push("/")}
              disabled={buttonDisabled()}
            >
              Login
            </Button>
          </div>
        )}
        {!success && (
          <>
            <div>Reset Your Password:</div>
            <Form>
              <Form.Item label="New Password">
                {form.getFieldDecorator("password", {
                  initialValue: "",
                  rules: [
                    {
                      min: 8,
                      message: "New password must be at least 8 characters"
                    }
                  ]
                })(<Input size="large" type="password" />)}
              </Form.Item>
              <Form.Item label="Confirm  New Password">
                {form.getFieldDecorator("password_confirmation", {
                  initialValue: "",
                  rules: [{ validator: compareToFirstPassword }]
                })(<Input size="large" type="password" />)}
              </Form.Item>
            </Form>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              onClick={handleSubmit}
              disabled={buttonDisabled()}
            >
              Submit
            </Button>
            {error && (
              <Typography.Text type="danger">
                Something went wrong, please try resetting your password again
              </Typography.Text>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Form.create()(PasswordReset);
