/* eslint-disable */
import * as Types from '../../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type IFetchAllHabitsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IFetchAllHabitsQuery = (
  { __typename?: 'Query' }
  & { habits: (
    { __typename?: 'HabitConnection' }
    & { nodes: Array<(
      { __typename?: 'Habit' }
      & Pick<Types.IHabit, 'id' | 'name' | 'description' | 'category' | 'employeeType' | 'selectable'>
      & { behaviors: Array<(
        { __typename?: 'Behavior' }
        & Pick<Types.IBehavior, 'id' | 'anchor'>
      )> }
    )> }
  ) }
);


export const FetchAllHabitsDocument = gql`
    query FetchAllHabits {
  habits(filter: {habit_type_eq: "0"}) {
    nodes {
      id
      name
      description
      category
      employeeType
      selectable
      behaviors {
        id
        anchor
      }
    }
  }
}
    `;

/**
 * __useFetchAllHabitsQuery__
 *
 * To run a query within a React component, call `useFetchAllHabitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllHabitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllHabitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllHabitsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IFetchAllHabitsQuery, IFetchAllHabitsQueryVariables>) {
        return ApolloReactHooks.useQuery<IFetchAllHabitsQuery, IFetchAllHabitsQueryVariables>(FetchAllHabitsDocument, baseOptions);
      }
export function useFetchAllHabitsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IFetchAllHabitsQuery, IFetchAllHabitsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IFetchAllHabitsQuery, IFetchAllHabitsQueryVariables>(FetchAllHabitsDocument, baseOptions);
        }
export type FetchAllHabitsQueryHookResult = ReturnType<typeof useFetchAllHabitsQuery>;
export type FetchAllHabitsLazyQueryHookResult = ReturnType<typeof useFetchAllHabitsLazyQuery>;
export type FetchAllHabitsQueryResult = ApolloReactCommon.QueryResult<IFetchAllHabitsQuery, IFetchAllHabitsQueryVariables>;