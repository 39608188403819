/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Mentions, Button } from "antd";
import { User, Habit } from "services/api";
import UserAvatar from "components/UserAvatar";
import useStores from "utils/useStores";
import { Wrapper } from "./styles";
import { useTranslation } from "react-i18next";

const { Option } = Mentions;

const nameFilter = (input, option) =>
  option.value.toLowerCase().includes(input.toLowerCase());

// eslint-disable-next-line react/prop-types
const Mention = ({ style, onSubmit, placeholder }) => {
  const [mentionText, setMentionText] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const [mentions, setMentions] = useState([]);
  const { t } = useTranslation();

  const {
    profileStore: { currentUser }
  } = useStores();

  const getUsers = (query) => {
    setLoading(true);
    setType("User");
    User.all(query).then(({ data }) => {
      if (data.length >= 10) {
        setOptions(data.slice(0, 10));
      } else {
        setOptions(data);
      }
      setLoading(false);
    });
  };

  const onChange = (text) => {
    setMentionText(text);
  };

  const getHabits = () => {
    setLoading(true);
    setType("Habit");
    Habit.all().then((res) => {
      setOptions(res.data);
      setLoading(false);
    });
  };

  const onSelect = (option) => {
    if (loading || option.value === "ANTD_SEARCHING") {
      return;
    }
    if (!mentions.find((o) => o.id === option.id)) {
      //
      const mention = {
        id: option.id,
        name: option.value,
        type: option.type
      };
      setMentions([...mentions, mention]);
    }
  };

  const enterPressed = (event) => {
    if (event.charCode && event.charCode === 13 && mentionText.length > 0) {
      event.preventDefault();
      onSubmit(mentionText, mentions);
      setMentionText("");
      setMentions([]);
    } else if (event.charCode && event.charCode === 13) {
      event.preventDefault();
    }
  };
  const onSearch = (search, prefix) => {
    if (prefix === "@") {
      getUsers(search);
    } else if (prefix === "#") {
      getHabits(search);
    }
  };

  const handleSubmit = () => {
    onSubmit(mentionText, mentions);
    setMentionText("");
    setMentions([]);
  };

  return (
    <Wrapper>
      <UserAvatar
        avatar={currentUser.avatar}
        name={currentUser.name}
        style={{ marginRight: 12 }}
      />
      <Mentions
        filterOption={nameFilter}
        loading={loading}
        onChange={onChange}
        prefix={["@", "#"]}
        value={mentionText}
        onSelect={onSelect}
        className="mention-box"
        onSearch={onSearch}
        onKeyPress={enterPressed}
        style={style}
        placeholder={placeholder}
      >
        {options.map((u) => {
          return (
            <Option
              id={u.id}
              value={u.name}
              type={type}
              className="antd-demo-dynamic-option"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {type === "User" && (
                  <div style={{ marginRight: "5%" }}>
                    <UserAvatar avatar={u.avatar} name={u.name} />
                  </div>
                )}
                <span>{u.name}</span>
              </div>
            </Option>
          );
        })}
      </Mentions>
      <Button
        data-cy="comment_button"
        style={{ marginLeft: 12 }}
        onClick={handleSubmit}
      >
        {t("mentions.comment")}
      </Button>
    </Wrapper>
  );
};

export default Mention;
