/* eslint-disable  */

import React, { useState, useReducer, useEffect } from "react";
import { Modal, Steps, Icon, Button } from "antd";
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import posed, { PoseGroup } from "react-pose";
import { Objective } from "services/api";
import build from "redux-object";
import moment from "moment";
import Attributes from "./Attributes";
import KeyResults from "./KeyResults";
import Stakeholders from "./Stakeholders";
import AddToParent from "./AddToParent";
import Review from "./Review";
import useStores from "../../utils/useStores";

const span = () => {
  console.log(window.innerWidth);
  if (window.innerWidth < 500) {
    return 12;
  }
  if (window.innerWidth < 800) {
    return 1;
  }
  if (window.innerWidth < 1100) {
    return 2;
  }
  if (window.innerWidth < 1430) {
    return 3;
  }
  if (window.innerWidth < 1730) {
    return 4;
  }
  if (window.innerWidth < 2500) {
    console.log("hit");
    return 5;
  }
  console.log(6);
  return 6;
};

const { Step } = Steps;

const Box = posed.div({
  initial: { x: 300, opacity: 0 },
  enter: { x: 0, opacity: 1 },
  exit: { x: -200, opacity: 0 }
});

const OKRForm = ({
  navigate,
  closeForm,
  parent,
  visible,
  editingObjective,
  duplicatingObjective,
  currentUser,
  handleSubmit,
  incomingKeyResults,
  libraryMode,
  importing,
  stepCount,
  currentStep,
  sholders,
  lastValues,
  defaultCheckedAutoEndOkr
}) => {
  const { objectiveStore } = useStores();
  const [localParent, setParent] = useState(parent);
  const [owner, setOwner] = useState(currentUser);
  const [labels, setLabels] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [editing, setEditing] = useState(null);
  const [valuesChanged, setValuesChanged] = useState(false);
  const [duplicating, setDuplicating] = useState(null);
  const [attrRef, setRef] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const [buttonLoading, setbuttonLoading] = useState(false);

  const handleClose = () => {
    objectiveStore.deepDiveNewChild = false;
    setAttributes(null);
    closeForm();
    updateKeyResults({ type: "clear" });
    updateStakeholders({ type: "clear" });
    updateStep({ type: "clear" });
    setParent(null);
    setIsPrivate(false);
    setOwner(currentUser);
    setLabels([]);
    setEditing(null);
    setDuplicating(null);
    setValuesChanged(false);
    setbuttonLoading(false);
  };

  const setInheriting = (objective, options = {}) => {
    if (options.parent && objective.parent) {
      setParent(objective.parent);
      setIsPrivate(objective.parent.private);
    } else {
      setIsPrivate(objective.private);
    }

    if (options.lastValues) {
      setAttributes({ ...objective, ...lastValues });
      if (lastValues.parent_id) {
        setParent(
          build(objectiveStore.data, "objective", lastValues.parent_id)
        );
      }
      setOwner(build(objectiveStore.data, "user", lastValues.owner_id));
      setLabels(build(objectiveStore.data, "label", lastValues.label_ids));

      updateKeyResults({
        type: "replace",
        keyResults: objective.key_results.map((k) => k.name)
      });
      updateStakeholders({
        type: "replace",
        stakeholders: build(
          objectiveStore.data,
          "user",
          lastValues.stakeholder_ids
        )
      });
      return;
    } else if (options.importing) {
      setAttributes({
        ...objective,
        start_date: moment(),
        check_in_interval: 7,
        delivery_date: null
      });
      updateKeyResults({
        type: "replace",
        keyResults:
          objective.key_results.length === 0
            ? [""]
            : objective.key_results.map((k) => k.name)
      });
      return;
    } else {
      setAttributes(objective);
      setLabels(objective.labels);
      updateKeyResults({
        type: "replace",
        keyResults:
          objective.key_results.length === 0
            ? [""]
            : objective.key_results.map((k) => k.name)
      });
      updateStakeholders({
        type: "replace",
        stakeholders: objective.stakeholders
      });
      setOwner(objective.owner);
    }
  };

  useEffect(() => {
    if (valuesChanged) {
      return;
    }
    if (editingObjective && editing !== editingObjective) {
      setEditing(editingObjective);
      setInheriting(editingObjective, { parent: true });
    }
    if (duplicatingObjective && duplicating !== duplicatingObjective) {
      setDuplicating(duplicatingObjective);
      currentStep > 0
        ? setInheriting(duplicatingObjective, { lastValues, importing })
        : setInheriting(duplicatingObjective, { importing });
    }
    if (parent && localParent !== parent) {
      setParent(parent);
      setIsPrivate(parent.private);
    }
    if (
      incomingKeyResults &&
      incomingKeyResults[0] !== "" &&
      incomingKeyResults.some((kr) => !keyResults.find((k) => k === kr))
    ) {
      updateKeyResults({
        type: "replace",
        keyResults: incomingKeyResults
      });
    }
  }, [editingObjective, parent, incomingKeyResults, duplicatingObjective]);

  const mobile = window.innerWidth < 500;
  const preparePayload = () => {
    const payload = { ...attributes };
    if (parent) {
      payload.private = parent.private;
    } else {
      payload.private = isPrivate;
    }

    payload.key_results = keyResults.filter((kr) => kr.length);
    payload.stakeholder_ids = stakeholders.map((stakeholder) => stakeholder.id);
    payload.owner_id = owner.id;
    payload.label_ids = labels.map((l) => l.id);
    payload.parent_id = localParent ? localParent.id : null;

    return payload;
  };

  // Step logic
  const stepReducer = (state, action) => {
    switch (action.type) {
      case "increment":
        return state + 1;
      case "decrement":
        return state - 1;
      case "clear":
        return 0;
      default:
        throw new Error();
    }
  };
  const [step, updateStep] = useReducer(stepReducer, 0);

  const validateAttributes = () => {
    attrRef.validateFields((err, values) => {
      if (err) {
        return;
      }
      //
      setAttributes(values);
      setValuesChanged(true);
      updateStep({ type: "increment" });
    });
  };

  // Stakeholder logic
  const stakeholderReducer = (state, action) => {
    switch (action.type) {
      case "remove":
        return state.filter((s) => s.id !== action.stakeholder);
      case "add":
        return [...state, action.stakeholder];
      case "replace":
        return action.stakeholders;
      case "clear":
        return [];
      default:
        throw new Error();
    }
  };

  const [stakeholders, updateStakeholders] = useReducer(
    stakeholderReducer,
    sholders || []
  );

  // Key Result logic
  const keyResultReducer = (state, action) => {
    switch (action.type) {
      case "remove":
        return state.filter((s, i) => i !== action.index);
      case "add":
        return [...state, ""];
      case "replace":
        return action.keyResults;
      case "edit":
        return state.map((s, i) => (i === action.index ? action.keyResult : s));
      case "clear":
        return [""];
      default:
        throw new Error();
    }
  };
  const [keyResults, updateKeyResults] = useReducer(keyResultReducer, [""]);

  const getFooter = () => {
    const {
      currentView,
      pagination,
      category,
      categoryOptions
    } = objectiveStore;
    const resetObj = {
      top_level: {
        max_pages: 0,
        pages: {},
        currentPage: 1
      },
      bottom_level: {
        max_pages: 0,
        pages: {},
        currentPage: 1
      }
    };
    switch (step) {
      case 0:
        return [
          <Button
            onClick={() => {
              handleClose();
              objectiveStore.cancelImport();
            }}
          >
            Cancel
          </Button>,
          <Button type="primary" onClick={validateAttributes} key={"next-0"}>
            Next
          </Button>
        ];
      case 2:
        if (libraryMode) {
          return [
            <Button onClick={() => updateStep({ type: "decrement" })}>
              Back
            </Button>,
            <Button
              type="primary"
              onClick={() => {
                handleSubmit(preparePayload());
                handleClose();
              }}
              key={"submit-0"}
            >
              {editing ? "Save Changes" : "Create"}
            </Button>
          ];
        } else {
          return [
            <Button onClick={() => updateStep({ type: "decrement" })}>
              Back
            </Button>,
            <Button
              type="primary"
              onClick={() => updateStep({ type: "increment" })}
              key={"next-2"}
            >
              Next
            </Button>
          ];
        }

      case 4:
        return [
          <Button onClick={() => updateStep({ type: "decrement" })}>
            Back
          </Button>,
          <Button
            key={"submit-1"}
            type="primary"
            onClick={(e) => {
              e.target.disabled = true;
              if (editing || (!objectiveStore.deepDiveNewChild && navigate)) {
                handleSubmit(preparePayload()).then((res) => {
                  let libCreation, libLen, resdata;
                  if (res && res.length > 0) {
                    [resdata, libCreation, libLen] = res;
                  }
                  if (navigate && !libCreation) {
                    navigate(editing ? editing : resdata.data).then((res) => {
                      objectiveStore.clearCategories();
                    });
                  } else if (libCreation && libLen === 0) {
                    objectiveStore.libraryMode = false;
                    objectiveStore.clearCategories();
                    objectiveStore.fetchObjectives(currentUser, 1, span() * 2, [
                      "top_level",
                      "bottom_level"
                    ]);
                  }
                });
                handleClose();
              } else {
                handleSubmit(preparePayload());
                handleClose();
              }
            }}
          >
            {editing ? "Save Changes" : "Create"}
          </Button>
        ];
      default:
        return [
          <Button onClick={() => updateStep({ type: "decrement" })}>
            Back
          </Button>,
          <Button
            type="primary"
            onClick={() => updateStep({ type: "increment" })}
          >
            Next
          </Button>
        ];
    }
  };

  const stepIcons = libraryMode
    ? [
        <Step icon={<Icon type="bulb" />} />,
        <Step icon={<Icon type="key" />} />,
        <Step icon={<Icon type="check" />} />
      ]
    : [
        <Step icon={<Icon type="bulb" />} />,
        <Step icon={<Icon type="apartment" />} />,
        <Step icon={<Icon type="key" />} />,
        <Step icon={<Icon type="team" />} />,
        <Step icon={<Icon type="check" />} />
      ];

  const steps = libraryMode
    ? [
        <Box key={0}>
          <Attributes
            ref={(ref) => setRef(ref)}
            parent={localParent}
            importing={importing}
            setParent={setParent}
            setAttributes={setAttributes}
            params={attributes}
            labels={labels}
            libraryMode
            defaultCheckedAutoEndOkr={defaultCheckedAutoEndOkr}
            setLabels={setLabels}
          />
        </Box>,
        <Box key={1}>
          <KeyResults
            results={keyResults}
            addKeyResult={(kr) =>
              updateKeyResults({ type: "add", keyResult: kr })
            }
            removeKeyResult={(i) =>
              updateKeyResults({ type: "remove", index: i })
            }
            editKeyResult={(i, kr) =>
              updateKeyResults({ type: "edit", index: i, keyResult: kr })
            }
          />
        </Box>,
        <Box key={2}>
          <Review
            libraryMode
            owner={owner}
            params={attributes}
            keyResults={keyResults}
            stakeholders={stakeholders}
            parent={localParent}
          />
        </Box>
      ]
    : [
        <Box key={0}>
          <Attributes
            ref={(ref) => setRef(ref)}
            parent={localParent}
            setParent={setParent}
            setAttributes={setAttributes}
            params={attributes}
            labels={labels}
            importing={importing}
            defaultCheckedAutoEndOkr={defaultCheckedAutoEndOkr}
            editing={!!editing}
            setLabels={setLabels}
          />
        </Box>,
        <Box key={1}>
          <AddToParent
            ref={(ref) => setRef(ref)}
            parent={localParent}
            setParent={setParent}
            setAttributes={setAttributes}
            params={attributes}
            isPrivate={isPrivate}
            setIsPrivate={setIsPrivate}
            labels={labels}
            importing={importing}
            editing={!!editing}
            currentId={editing && editing.id}
            setLabels={setLabels}
          />
        </Box>,
        <Box key={2}>
          <KeyResults
            results={keyResults}
            addKeyResult={(kr) =>
              updateKeyResults({ type: "add", keyResult: kr })
            }
            removeKeyResult={(i) =>
              updateKeyResults({ type: "remove", index: i })
            }
            editKeyResult={(i, kr) =>
              updateKeyResults({ type: "edit", index: i, keyResult: kr })
            }
          />
        </Box>,
        <Box key={3}>
          <Stakeholders
            currentUser={currentUser}
            owner={owner}
            setOwner={setOwner}
            addStakeholder={(stakeholder) =>
              updateStakeholders({ type: "add", stakeholder })
            }
            removeStakeholder={(stakeholder) =>
              updateStakeholders({ type: "remove", stakeholder })
            }
            stakeholders={stakeholders}
          />
        </Box>,
        <Box key={4}>
          <Review
            owner={owner}
            params={attributes}
            keyResults={keyResults}
            stakeholders={stakeholders}
            parent={localParent}
          />
        </Box>
      ];
  let title;
  if (editing) {
    title = "Edit Objective";
  } else if (importing) {
    title = "Import Objective";
  } else if (duplicating) {
    title = "Duplicate Objective";
  } else {
    title = "New Objective";
  }
  let objectiveSteps = [];
  if (currentStep !== null) {
    for (let i = 0; i < stepCount; i += 1) {
      objectiveSteps.push(<Step />);
    }
  }
  return (
    <Modal
      title={title}
      onCancel={handleClose}
      centered
      style={{ overflowY: "hidden", position: "relative" }}
      bodyStyle={{
        overflowY: "hidden"
      }}
      visible={visible}
      maskClosable={false}
      destroyOnClose
      width={600}
      footer={getFooter()}
    >
      {currentStep !== null && (
        <Steps size="small" current={currentStep} style={{ marginBottom: 12 }}>
          {objectiveSteps}
        </Steps>
      )}

      {!mobile && (
        <Steps
          current={step}
          direction="horizontal"
          size={mobile ? "small" : "default"}
        >
          {stepIcons}
        </Steps>
      )}
      <PoseGroup preEnterPose="initial">{steps[step]}</PoseGroup>
    </Modal>
  );
};

export default OKRForm;
