import axios from "axios";
import { notification } from "antd";

import profileStore from "stores/profileStore";

import history from "services/history";

import CarrotIcon from "components/CarrotIcon";

const shouldIntercept = error => {
  try {
    return error.response.status === 401 && error.config.url.indexOf("/account/tokens") === -1;
  } catch (e) {
    return false;
  }
};

const setTokenData = (tokenData = {}, axiosClient) => {
  localStorage.setItem("token", tokenData.idToken);
  localStorage.setItem("refresh_token", tokenData.refreshToken);
  // If necessary: save to storage
  //   tokenData's content includes data from handleTokenRefresh(): {
  //     idToken: data.auth_token,
  //     refreshToken: data.refresh_token,
  //     expiresAt: data.expires_in,
  // };
};

const handleTokenRefresh = axiosClient => {
  const refreshToken = window.localStorage.getItem("refresh_token");
  return new Promise((resolve, reject) => {
    axiosClient
      .post("account/tokens")
      .then(({ headers }) => {
        const tokenData = {
          idToken: headers["access-token"],
          refreshToken: headers["refresh-token"]
        };
        resolve(tokenData);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const attachTokenToRequest = (request, token) => {
  request.headers["Authorization"] = "Bearer " + token;

  // If there is an edge case where access token is also set in request query,
  // this is also a nice place to add it
  // Example: /orders?token=xyz-old-token
  if (/\/orders/.test(request.url)) {
    request.params.token = token;
  }
};

export default (axiosClient, customOptions = {}) => {
  let isRefreshing = false;
  let failedQueue = [];

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions
  };
  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const interceptor = error => {
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error);
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (isRefreshing) {
      return new Promise(function(resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then(token => {
          originalRequest._queued = true;
          options.attachTokenToRequest(originalRequest, token);
          return axiosClient.request(originalRequest);
        })
        .catch(err => {
          return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh
        .call(options.handleTokenRefresh, axiosClient)
        .then(tokenData => {
          options.setTokenData(tokenData, axiosClient);
          options.attachTokenToRequest(originalRequest, tokenData.idToken);
          processQueue(null, tokenData.idToken);
          resolve(axiosClient.request(originalRequest));
        })
        .catch(err => {
          processQueue(err, null);
          reject(err);

          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          // window.location.assign('/');
          profileStore.removeToken();
          history.push("/");
          notification.error({
            key: 1,
            message: "Session Expired",
            description: "Please log in again"
          });
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  };

  axiosClient.interceptors.response.use(undefined, interceptor);
};
