/* eslint-disable prefer-destructuring */

/* eslint-disable no-unused-vars */

/* eslint-disable no-debugger */
import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import QASurvey from "assets/QA_SURVEY.svg";
import { Select } from "antd";
import { HabitUser } from "types/shared";
import { Prompt } from "services/api";
import useStores from "utils/useStores";
import { Flair } from "components/Layout/styles";
import { ReactComponent as Third } from "assets/dashboard/objective_review_due.svg";

import {
  ContentContainer,
  DescContainer,
  Header,
  ImageContainer
} from "../styles";

import RaterList from "../../ActionsAndInfo/NetworkRaters/RaterList";
import { useTranslation } from "react-i18next";

const StyledSelect = styled(Select)`
  .ant-select-selection--single {
    height: fit-content;
  }

  .ant-select-selection__rendered {
    height: auto;
    display: flex;
  }

  .ant-select-selection-selected-value {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
  }

  .ant-select-arrow {
    color: rgba(0, 0, 0, 0.9);
  }

  .ant-select-dropdown-menu-item {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
  }
`;
const { Option } = Select;

const StyledOption = styled(Option)`
  overflow: unset;
  white-space: unset;
  text-overflow: unset;
`;

type PickPromptProps = {
  habit: HabitUser;
  setPromptId: (id: string) => void;
  promptId: string;
};

const PickPrompt = ({ habit, setPromptId, promptId }: PickPromptProps) => {
  const [prompts, setPrompts] = useState([]);
  const { t } = useTranslation();
  const {
    profileStore: { currentUser }
  } = useStores();

  const handleChange = (id) => {
    setPromptId(id);
  };

  const fetchPrompts = async () => {
    const data = (await Prompt.fetch()).data;
    if (data.data && data.data[0]) {
      setPrompts(data.data);
      setPromptId(data.data[0].id);
    }
  };

  useLayoutEffect(() => {
    (async () => {
      if (habit && habit.id) {
        const data = await fetchPrompts();
      }
    })();
  }, [habit]);

  const replaceText = (
    text // eslint-disable-next-line no-template-curly-in-string
  ) =>
    text
      .replace(/\${userName}/g, currentUser.name)
      .replace(/\${habitName}/g, habit.name);

  return (
    <ContentContainer>
      <DescContainer>
        <Header data-cy="step2title">
          {t("deep_dive.more_feedback_wizard.pick_prompt_title")}
        </Header>
        <p>{t("deep_dive.more_feedback_wizard.pick_prompt_paragraph_1")}</p>
        <Header style={{ marginTop: "25px" }}>
          {t("deep_dive.more_feedback_wizard.select_prompt")}
        </Header>
        <StyledSelect
          onSelect={handleChange}
          value={promptId}
          dropdownClassName="prompt-selector"
        >
          {prompts.map((p) => {
            // eslint-disable-next-line no-eval
            return (
              <StyledOption value={p.id}>
                {replaceText(p.attributes.question_text)}
              </StyledOption>
            );
          })}
        </StyledSelect>
        <Header style={{ marginTop: "39px" }}>
          {t("deep_dive.more_feedback_wizard.select_prompt_header")}
        </Header>
        <span style={{ marginBottom: "20px" }}>
          {t("deep_dive.network_raters.feedback_providers", {
            count: habit.raters.length
          })}
        </span>
        <RaterList remainingRaters={[]} filteredRaters={habit.raters} />
      </DescContainer>
      <Flair style={{ width: "25%", maxHeight: "unset" }}>
        <Third style={{ width: "100%", height: "auto" }} />
      </Flair>
    </ContentContainer>
  );
};

export default PickPrompt;
