import React from "react";
import moment from "moment";
import SummaryPie from "components/ObjectiveSummaryPie";
import ObjectiveDot from "components/ObjectiveDot";
import useStores from "utils/useStores";
import { Wrapper, Side, Item, Name } from "./styles";

const ObjectiveDisplay: React.FC = ({ objectives }) => {
  const { objectiveStore } = useStores();
  const pickText = (date) => {
    const diff = moment(date).diff(moment(), "days");
    let text;
    if (diff === 0) {
      text = "Today";
    } else if (diff < 0) {
      text = `${Math.abs(diff)} days late`;
    } else {
      text = `In ${diff} days`;
    }
    return text;
  };

  return (
    <Wrapper>
      <Side style={{ alignItems: "center" }}>
        <SummaryPie data={objectives} />
      </Side>
      <Side>
        <h4 style={{ textAlign: "center" }}>Due Soon</h4>
        {objectives
          .slice()
          .sort(
            (a, b) =>
              moment(a.delivery_date).valueOf() -
              moment(b.delivery_date).valueOf()
          )
          .slice(0, 4)
          .map((o) => (
            <Item
              style={{
                marginBottom: 6,
                justifyContent: "space-between"
              }}
            >
              <Item style={{ width: "calc(100% - 100px)" }}>
                <ObjectiveDot show={["latest_check_in"]} objective={o} />
                <Name
                  onClick={() => objectiveStore.changeLocation(o)}
                  style={{ marginLeft: 6 }}
                >
                  {o.name}
                </Name>
              </Item>
              <Item style={{ width: 100, justifyContent: "flex-end" }}>
                {pickText(o.delivery_date || o.deliveryDate)}
              </Item>
            </Item>
          ))}
      </Side>
    </Wrapper>
  );
};

export default ObjectiveDisplay;
