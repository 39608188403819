import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { WorkItems } from "services/api";
import history from "services/history";
import viewModal from "components/FullScreenModal";
import Relationships from "containers/Relationships";

import { ReactComponent as FlairImg } from "assets/dashboard/suggested_employee_feedback_provider.svg";
import { ReactComponent as FlairImgRemoved } from "assets/dashboard/removal_alert.svg";
import { ItemWrapper, Title, ButtonWrapper, Flair, Content } from "../styles";
import { useTranslation } from "react-i18next";

const RaterReciprocityItem = ({ provider, markCompleted, itemId, anti }) => {
  const { t } = useTranslation();

  const discardItem = () => {
    WorkItems.discard(itemId);
    markCompleted();
  };

  const openRelationships = () => {
    viewModal(
      { width: 900, onClose: () => discardItem() },
      history,
      <Relationships focusing={!anti && provider.id} />
    );
  };

  let content;
  let titleContent;

  if (anti) {
    content = t(
      "homepage.action_items.rater_reciprocity.anti_reciprocity_content"
    );
    titleContent = t(
      "homepage.action_items.rater_reciprocity.anti_reciprocity_title",
      {
        providerName: provider.name
      }
    );
  } else {
    content = t("homepage.action_items.rater_reciprocity.content");
    titleContent = t("homepage.action_items.rater_reciprocity.title", {
      providerName: provider.name
    });
  }

  return (
    <ItemWrapper>
      <Content>
        <Title>{titleContent}</Title>
        <p>{content}</p>
        <ButtonWrapper>
          <Button style={{ marginRight: 24 }} onClick={discardItem}>
            {t("homepage.action_items.rater_reciprocity.dismiss_text")}
          </Button>
          <Button onClick={openRelationships} type="primary">
            {t("homepage.action_items.rater_reciprocity.action_text")}
          </Button>
        </ButtonWrapper>
      </Content>
      <Flair>
        {anti ? (
          <FlairImgRemoved style={{ width: "100%" }} />
        ) : (
          <FlairImg style={{ width: "100%" }} />
        )}
      </Flair>
    </ItemWrapper>
  );
};

RaterReciprocityItem.defaultProps = {
  anti: false
};

RaterReciprocityItem.propTypes = {
  provider: PropTypes.object.isRequired,
  markCompleted: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  anti: PropTypes.bool
};

export default RaterReciprocityItem;
