/* eslint-disable */

import React from "react";
import { Form, Row, Col, Select, Input } from "antd";

const { Option } = Select;
const ReviewForm = ({ form, currentReview, editing = false, setChanged }) => {
  const { getFieldDecorator } = form;
  const spans = { xxl: 8, xl: 8, lg: 12, md: 12, sm: 12, xs: 12 };

  return (
    <Form
      style={{
        width: "100%",
        padding: "12px 24px 12px 24px",
        paddingBottom: 0
      }}
    >
      <Row gutter={24}>
        <Col {...spans}>
          <Form.Item style={{ marginBottom: 0 }} label="Readiness">
            {getFieldDecorator("readiness", {
              initialValue: editing ? currentReview.readiness : 1
            })(
              <Select>
                <Option value={0}>Ready Now</Option>
                <Option value={1}>Ready 3-6 Months</Option>
                <Option value={2}>Ready 6-12 Months</Option>
                <Option value={3}>Ready 1 Year Out</Option>
                <Option value={4}>Suitably Placed</Option>
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...spans}>
          <Form.Item style={{ marginBottom: 0 }} label="Risk of Loss">
            {getFieldDecorator("risk_of_loss", {
              initialValue: editing ? currentReview.risk_of_loss : 1
            })(
              <Select>
                <Option value={0}>Low</Option>
                <Option value={1}>Medium</Option>
                <Option value={2}>High</Option>
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...spans}>
          <Form.Item style={{ marginBottom: 0 }} label="Impact of Loss">
            {getFieldDecorator("impact_of_loss", {
              initialValue: editing ? currentReview.impact_of_loss : 1
            })(
              <Select>
                <Option value={0}>Low</Option>
                <Option value={1}>Medium</Option>
                <Option value={2}>High</Option>
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Row gutter={24}>
          <Col {...spans}>
            <Form.Item style={{ marginBottom: 0 }} label="Development Plans">
              {getFieldDecorator("development_plan", {
                initialValue: currentReview
                  ? currentReview.development_plan
                  : ""
              })(
                <Input.TextArea
                  onChange={setChanged}
                  rows={6}
                  placeholder="Keep track of your development plans for this employee"
                />
              )}
            </Form.Item>
          </Col>
          <Col {...spans}>
            <Form.Item style={{ marginBottom: 0 }} label="Risk Rationale">
              {getFieldDecorator("reason_for_leaving", {
                initialValue: currentReview
                  ? currentReview.reason_for_leaving
                  : ""
              })(
                <Input.TextArea
                  onChange={setChanged}
                  rows={6}
                  placeholder="Add some context to the choices you made above"
                />
              )}
            </Form.Item>
          </Col>
          <Col {...spans}>
            <Form.Item style={{ marginBottom: 0 }} label="Assessment Of Impact">
              {getFieldDecorator("assessment_of_impact", {
                initialValue: editing ? currentReview.assessment_of_impact : ""
              })(
                <Input.TextArea
                  onChange={setChanged}
                  rows={6}
                  placeholder="Assess impact of loss"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item style={{ marginBottom: 0 }} label="Comments">
              {getFieldDecorator("comment", {
                initialValue: editing ? currentReview.comment : ""
              })(
                <Input.TextArea
                  onChange={setChanged}
                  rows={3}
                  placeholder="Any extra comments you have about this employee"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Row>
    </Form>
  );
};

export default Form.create({
  onValuesChange: (props, changed, values) => props.checkCompleted(values)
})(ReviewForm);
