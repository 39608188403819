import React from "react";
import styled from "styled-components";
import { Layout } from "antd";
import { media } from "utils/style-utils";

const pickMargin = (props) => {
  if (props.mobile || props.external) {
    return "0px";
  }
  if (props.menuCollapsed) {
    return "80px";
  }
  return "256px";
};

const pickWidth = (props) => {
  if (props.mobile) {
    return "100vw";
  }
  if (props.menuCollapsed) {
    return "calc(100% - 80px)";
  }
  return "calc(100% - 256px)";
};

export const InnerLayout = styled(
  ({ external, mobile, menuCollapsed, ...props }) => <Layout {...props} />
)`
  background-color: #f0f2f5;
  background-size: cover;
  min-height: 100vh;
  margin-left: ${(props) => pickMargin(props)};
  ${media.handheld`
        min-height: unset;
      `};
`;

export const Header = styled(
  ({ mobile, menuCollapsed, external, ...props }) => (
    <Layout.Header {...props} />
  )
)`
  position: fixed;
  z-index: 100;
  width: ${(props) => pickWidth(props)};
  width: ${(props) => props.external && "100%"};
  padding: 0 15px 0 0;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
`;

export const Content = styled(({ mobile, ...props }) => (
  <Layout.Content {...props} />
))`
  height: ${(props) => !props.mobile && "calc(100vh - 64px)"};
  margin-top: 64px;
  z-index: 12;
`;

export const Sider = styled(Layout.Sider)`
  overflow: auto;
  height: 100vh;
`;

export const MobileSider = styled(Layout.Sider)`
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0px;
  z-index: 100;
`;
