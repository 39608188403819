/* eslint-disable prefer-destructuring */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
// @flow

import React, { useState, useEffect } from "react";
import { HabitUser } from "types/shared";
import useStores from "utils/useStores";
import { Icon, Menu, Dropdown, Select, Tooltip, Tag } from "antd";
import { Prompt } from "services/api";
import { withTheme } from "styled-components";
import { HeaderTitle, StyledOption } from "./styles";
import { useTranslation } from "react-i18next";

const { Item, SubMenu } = Menu;
const { Option } = Select;

type ViewSelectionProps = {
  habit: HabitUser;
  theme: any;
  view: string;
  handleViewChange: (e: any) => void;
  viewMap: any;
  addPrompt: (p: string) => void;
  removePrompt: (p: string) => void;
};

const ViewSelection = ({
  habit,
  theme,
  view,
  handleViewChange,
  viewMap,
  removePrompt,
  addPrompt
}: ViewSelectionProps) => {
  const [open, setOpen] = useState(false);
  const { profileStore } = useStores();
  const [prompts, setPrompts] = useState([]);
  const { t } = useTranslation();
  const handleMenuToggle = () => {
    setOpen(!open);
  };

  if (!profileStore.features().includes("ADDITIONAL_FEEDBACK")) {
    return <HeaderTitle>How to Improve</HeaderTitle>;
  }
  const handleSelect = (id) => {
    let text;
    const prompt = prompts.find((p) => p.id === id);
    if (prompt) {
      text = prompt.attributes.question_text;
      addPrompt(text);
    }
  };

  const handleDeselect = (id) => {
    let text;
    const prompt = prompts.find((p) => p.id === id);
    if (prompt) {
      text = prompt.attributes.question_text;
      removePrompt(text);
    }
  };

  useEffect(() => {
    if (prompts.length > 0) {
      handleSelect(prompts[0].id);
    }
    if (view === "additional" && prompts.length <= 0) {
      (async () => {
        const query = `filter[habit_id_in]=${habit.id}`;
        try {
          const data = (await Prompt.fetch(query)).data;
          if (data.data.length > 0) {
            setPrompts(data.data);
          }
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [view, prompts]);

  const replaceText = (str: string) =>
    // eslint-disable-next-line no-template-curly-in-string
    str
      .replace(/\${userName}/g, profileStore.currentUser.name)
      .replace(/\${habitName}/g, habit.name);

  const desktop = window.innerWidth > 1520;
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      <div
        role="presentation"
        data-cy="selector"
        onClick={() => handleViewChange("improve")}
        style={{
          position: "relative",
          cursor: "pointer"
        }}
      >
        <span
          style={{
            fontSize: theme.font.subheader,
            color: view !== "improve" && "rgba(0, 0, 0, 0.4)"
          }}
        >
          {t("deep_dive.improvement.how_to_improve")}
        </span>
      </div>
      <div
        role="presentation"
        data-cy="selector"
        onClick={() => handleViewChange("additional")}
        style={{
          position: "relative",
          cursor: "pointer"
        }}
      >
        <span
          style={{
            fontSize: theme.font.subheader,
            color: view === "improve" && "rgba(0, 0, 0, 0.4)"
          }}
        >
          {t("deep_dive.improvement.additional_feedback")}
        </span>
      </div>
    </div>
  );
};

export default withTheme(ViewSelection);
