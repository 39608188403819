import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { Typography, Divider, Icon } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { Profile } from "services/api";
import useStores from "utils/useStores";
import { Mask, Pane, PostList } from "./styles";
import PostView from "./PostView";
import { Post } from "./types";
import { useTranslation } from "react-i18next";

const BLOG_POST_QUERY = gql`
  query BlogPosts($start: Int, $limit: Int, $where: JSON) {
    blogPosts(
      start: $start
      limit: $limit
      sort: "created_at:desc"
      where: $where
    ) {
      id
      title
      hero_image {
        url
      }
      content
      created_at
    }
  }
`;

interface Props {
  open: boolean;
  onClose: () => void;
}

export const UpdatePane: React.FC<Props> = ({ open, onClose }) => {
  const { profileStore } = useStores();
  const [hasMore, setHasMore] = useState(true);
  const { t } = useTranslation();
  const limit = 5;
  const features = ["NONE", ...profileStore.features()];
  const { data, loading, error, fetchMore } = useQuery(BLOG_POST_QUERY, {
    variables: {
      start: 0,
      limit,
      // eslint-disable-next-line @typescript-eslint/camelcase
      where: { required_feature_in: features }
    },
    context: { clientName: "blog" }
  });

  useEffect(() => {
    if (
      data &&
      data.blogPosts[0] &&
      profileStore.currentUser &&
      profileStore.currentUser.last_seen_update_id !== data.blogPosts[0].id
    ) {
      profileStore.hasNewUpdate = true;
    }
  }, [data]);

  useEffect(() => {
    if (
      open &&
      profileStore.hasNewUpdate &&
      data &&
      data.blogPosts[0] &&
      profileStore.currentUser &&
      profileStore.currentUser.last_seen_update_id !== data.blogPosts[0].id
    ) {
      Profile.update({ last_seen_update_id: data.blogPosts[0].id });
      profileStore.hasNewUpdate = false;
    }
  }, [open, profileStore.hasNewUpdate]);

  const loadMore = (): void => {
    if (!data) {
      return;
    }
    fetchMore({
      variables: {
        start: data.blogPosts.length,
        limit,
        // eslint-disable-next-line @typescript-eslint/camelcase
        where: { required_feature_in: ["NONE", ...profileStore.features()] }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        if (fetchMoreResult.blogPosts.length === 0) {
          setHasMore(false);
        }
        return Object.assign({}, prev, {
          blogPosts: [...prev.blogPosts, ...fetchMoreResult.blogPosts]
        });
      }
    });
  };
  return (
    <div>
      <Mask open={open} onClick={onClose} />
      <Pane open={open}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            padding: "0 24px"
          }}
        >
          <Typography.Title style={{ marginBottom: 0 }} level={4}>
            {t("update_pane.whats_new_in_rhabit")}
          </Typography.Title>
          <Icon type="close" style={{ fontSize: 24 }} onClick={onClose} />
        </div>
        <Divider style={{ marginBottom: 0 }}></Divider>
        {error &&
          "Something went wrong! Our team has been notified and is hard at work fixing the issue. Check back soon!"}
        <PostList>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            useWindow={false}
          >
            {!loading &&
              !error &&
              data.blogPosts.map((post: Post) => (
                <PostView key={post.id} post={post} />
              ))}
          </InfiniteScroll>
        </PostList>
      </Pane>
    </div>
  );
};

export default UpdatePane;
