import { observable, action } from 'mobx';
import { Goals } from 'services/api';
import axios from 'axios';

const { CancelToken } = axios;

const FILTERS = {
  assignee: 'assignee',
  stakeholder: 'stakeholder',
  tags: 'tags',
  team: 'team'
};

const ACTIONS = {
  assignee: (id, status, page, source) =>
    Goals.fetchByAssignee(id, status, page, source.token),
  tags: (tags, status, page, source) =>
    Goals.fetchByTag(tags, false, status, page, source.token),
  team: (team, status, page, source) =>
    Goals.fetchByTeam(team, status, page, source.token),
  stakeholder: (id, status, page, source) =>
    Goals.fetchByStakeholder(id, status, page, source.token)
};

const STATUSES = {
  active: 'active',
  complete: 'complete',
  pending: 'pending'
};

class GoalStore {
  @observable filter = FILTERS.assignee;
  @observable status = STATUSES.active;
  @observable filterValue = null;
  @observable loading = false;
  @observable currentGoals = [];
  @observable page = 1;
  @observable total = 1;
  @observable source = null;

  // TODO: Cache responses

  // Keep track of ui state and cache responses;
  // @observable goals = {
  //   assignee: {
  //     active: { fetched: false, data: [] },
  //     complete: { fetched: false, data: [] }
  //   },
  //   stakeholder: {
  //     active: { fetched: false, data: [] },
  //     complete: { fetched: false, data: [] }
  //   },
  //   tags: {
  //     active: { fetched: false, data: [] },
  //     complete: { fetched: false, data: [] }
  //   },
  //   teamMember: {
  //     active: { fetched: false, data: [] },
  //     complete: { fetched: false, data: [] }
  //   },
  //   team: {
  //     active: { fetched: false, data: [] },
  //     complete: { fetched: false, data: [] }
  //   }
  // };

  // @computed get currentGoals() {
  //   const permutation = this.goals[this.filter][this.status];
  //   return permutation.data;
  // }

  @action
  setFilter = (filter, value) => {
    if (this.source) {
      this.source.cancel();
    }
    this.source = CancelToken.source();
    this.page = 1;
    this.loading = true;
    // source.cancel();
    // const originalStatus = this.status;
    // const loadNew = this.filter === filter && value !== this.filterValue;
    this.filter = FILTERS[filter];
    this.filterValue = value;
    // const permutation = this.goals[this.filter][this.status];
    // if (!permutation.fetched || loadNew) {
    ACTIONS[this.filter](this.filterValue, this.status, this.page, this.source)
      .then(({ data }) => {
        this.loading = false;
        // if (this.filter === FILTERS[filter] && originalStatus === this.status) {
        this.currentGoals = data.data;
        this.total = data.total;
        // }
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          // cancel request
        } else {
          // handle error
        }
      });

    // } else {
    //   this.loading = false;
    // }
  };

  @action
  setStatus = (status) => {
    if (status === 'pending') {
      this.status = 'pending';
      return;
    }
    if (this.source) {
      this.source.cancel();
    }
    this.source = CancelToken.source();
    this.page = 1;
    this.status = STATUSES[status];
    this.loading = true;
    ACTIONS[this.filter](
      this.filterValue,
      this.status,
      this.page,
      this.source
    ).then(({ data }) => {
      this.loading = false;
      this.currentGoals = data.data;
      this.total = data.total;
    });
  };

  @action.bound
  createGoals = (goals, userId) => {
    goals.forEach((goal) => {
      if (
        goal.assignee.id === userId &&
        this.filter === FILTERS.assignee &&
        this.status === STATUSES.active
      ) {
        this.currentGoals = [...this.currentGoals, goal];
      } else if (
        goal.stakeholders.find((id) => id === userId) &&
        this.filter === FILTERS.stakeholder &&
        this.status === STATUSES.complete
      ) {
        this.currentGoals = [...this.currentGoals, goal];
      }
    });
  };

  @action
  updateGoal = (goal) => {
    this.currentGoals = this.currentGoals.filter((g) => g.id !== goal.id);
    this.currentGoals.push(goal);
  };

  @action
  loadMore = (pagination) => {
    this.source = CancelToken.source();

    this.loading = true;
    ACTIONS[this.filter](
      this.filterValue,
      this.status,
      pagination.current,
      this.source
    ).then(({ data }) => {
      this.loading = false;
      this.page = pagination.current;
      this.currentGoals = data.data;
      this.total = data.total;
    });
  };
}

export default new GoalStore();
