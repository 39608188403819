import React from "react";
import Box from "layout/Box";
import { ResponsivePie } from "@nivo/pie";
import { Tooltip } from "antd";

type PieDatum = {
  id: string;
  label: string;
  value: number;
  color: string;
};

type HabitData = {
  name: string;
  data: PieDatum[];
};

const HabitPie: React.FC<{ habit: HabitData }> = ({ habit }) => {
  if (habit.data.every((d) => d.value === 0)) {
    return (
      <Tooltip title="Still collecting data for this habit">
        <Box
          width="250px"
          height="250px"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            width="175px"
            height="175px"
            style={{ filter: "blur(3px)", pointerEvents: "none" }}
          >
            <ResponsivePie
              data={[
                {
                  color: "#A9A9A9",
                  id: "blank",
                  label: "",
                  value: 66
                },
                {
                  color: "#E8E8E8",
                  id: "blank",
                  label: "",
                  value: 33
                }
              ]}
              colorBy={(a) => a.color as string}
              margin={{ top: 0, right: 25, bottom: 0, left: 25 }}
              radialLabelsSkipAngle={10}
              radialLabelsTextXOffset={6}
              enableRadialLabels={false}
              enableSlicesLabels={false}
              radialLabelsTextColor="#333333"
              radialLabelsLinkOffset={0}
              radialLabelsLinkDiagonalLength={16}
              radialLabelsLinkHorizontalLength={24}
              radialLabelsLinkStrokeWidth={1}
              slicesLabelsSkipAngle={10}
              slicesLabelsTextColor="#333333"
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          </Box>
          <Box fontSize="16px" color="rgba(0, 0, 0, 0.8)" textAlign="center">
            {habit.name}
          </Box>
        </Box>
      </Tooltip>
    );
  }
  return (
    <Box
      width="250px"
      height="250px"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box width="175px" height="175px">
        <ResponsivePie
          data={habit.data}
          colorBy={(a) => a.color as string}
          margin={{ top: 0, right: 25, bottom: 0, left: 25 }}
          radialLabelsSkipAngle={10}
          radialLabelsTextXOffset={6}
          enableRadialLabels={false}
          enableSlicesLabels={false}
          radialLabelsTextColor="#333333"
          radialLabelsLinkOffset={0}
          radialLabelsLinkDiagonalLength={16}
          radialLabelsLinkHorizontalLength={24}
          radialLabelsLinkStrokeWidth={1}
          slicesLabelsSkipAngle={10}
          slicesLabelsTextColor="#333333"
          animate
          motionStiffness={90}
          motionDamping={15}
        />
      </Box>
      <Box fontSize="16px" color="rgba(0, 0, 0, 0.8)" textAlign="center">
        {habit.name}
      </Box>
    </Box>
  );
};

export default HabitPie;
