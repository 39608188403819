const breakpoints = ["480px", "576px", "768px", "992px", "1400px", "1600px"];

// aliases
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];
breakpoints.xxl = breakpoints[5];

const theme = {
  primaryColor: "#4AA459",
  breakpoints,
  font: {
    pointOfInterest: 35,
    header: 18,
    subheader: 16,
    body: 14,
    small: 12
  },
  objectives: {
    1: "#F77911",
    2: "#E3AF36",
    3: "#5A9B39",
    4: "#2F7920",
    5: "#349980"
  },
  habitDeepDive: {
    rating: {
      onTrack: "#77D36E",
      excellent: "#1DB35E",
      needsWork: "#FF9231"
    },
    radarChart: {
      borderColor: "#979797",
      dotBorderColor: "white",
      dotTextColor: "white",
      lineStroke: "black"
    }
  }
};

export default theme;
