/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 16px;
`;

export const Item = styled.div`
  padding: 6px 12px 6px 6px;
  display: flex;
  align-items: center;
`;

export const Count = styled.div`
  margin-left: auto;
  font-weight: 500;
`;

export const Position = styled.div`
  font-weight: 500;
  margin-right: 6px;
`;