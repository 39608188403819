/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow strict

import React from "react";
import { Popover } from "antd";
import UserAvatar from "components/UserAvatar";
import Carrot from "assets/carrot.png";
import { LeaderboardUser } from "./index";
import { Container, Item, ItemOuter, CarrotImg } from "./styles";

type Props = {
  users: LeaderboardUser[];
};

const ListItem = ({ user }: { user: LeaderboardUser }) => (
  <ItemOuter>
    <Item>
      <UserAvatar
        style={{ marginRight: 6 }}
        name={user.user.name}
        avatar={user.user.avatar}
      />
      <div>{user.user.name}</div>
    </Item>
    <Item style={{ marginLeft: 12 }}>
      <div>{user.feedback_streak}</div>
      <CarrotImg src={Carrot} />
    </Item>
  </ItemOuter>
);

const getContent = (users) => {
  return (
    <Container
      style={{ maxHeight: "100vh", overflowY: "auto", padding: "24px 0" }}
    >
      {users.map((user) => (
        <ListItem user={user} key={user.user.id} />
      ))}
    </Container>
  );
};

const LeaderList = React.memo<Props>(({ users }: Props) => {
  return (
    <Container>
      {users.slice(0, 6).map((user) => (
        <ListItem user={user} key={user.user.id} />
      ))}
      <Popover content={getContent(users.slice(6, users.length))}>
        {users.length > 6 && (
          <a style={{ marginLeft: "auto", marginRight: 24 }}>
            ...{users.length - 6} others
          </a>
        )}
      </Popover>
    </Container>
  );
});

export default LeaderList;
