import moment from "moment";

// function randomIntFromInterval(min, max) {
//   const val = Math.floor(Math.random() * (max - min + 1) + min);
//   if (val < 0) {
//     return 0;
//   }
//   if (val > 100) {
//     return 100;
//   }
//   return val;
// }
// const generateHistory = () => {
//   const history = [];
//
//   for (let i = 365; i > 0; i -= 4) {
//     history.push({
//       time: moment().subtract(i, "days"),
//       score: randomIntFromInterval(
//         history.length > 0 ? history[history.length - 1].score - 10 : 80,
//         history.length > 0 ? history[history.length - 1].score + 10 : 100
//       )
//     });
//   }
//   return history;
// };

const trend = [
  { value: 94, created_at: moment().subtract(7, "days") },
  { value: 93, created_at: moment().subtract(14, "days") },
  { value: 93, created_at: moment().subtract(21, "days") },
  { value: 93, created_at: moment().subtract(28, "days") },
  { value: 93, created_at: moment().subtract(35, "days") },
  { value: 100, created_at: moment().subtract(42, "days") },
  { value: 99, created_at: moment().subtract(49, "days") },
  { value: 98, created_at: moment().subtract(56, "days") },
  { value: 97, created_at: moment().subtract(63, "days") },
  { value: 96, created_at: moment().subtract(70, "days") },
  { value: 89, created_at: moment().subtract(77, "days") },
  { value: 74, created_at: moment().subtract(84, "days") },
  { value: 56, created_at: moment().subtract(91, "days") },
  { value: 32, created_at: moment().subtract(98, "days") },
  { value: 18, created_at: moment().subtract(105, "days") },
  { value: 13, created_at: moment().subtract(112, "days") },
  { value: 22, created_at: moment().subtract(119, "days") },
  { value: 32, created_at: moment().subtract(126, "days") },
  { value: 40, created_at: moment().subtract(133, "days") },
  { value: 51, created_at: moment().subtract(140, "days") },
  { value: 69, created_at: moment().subtract(147, "days") },
  { value: 100, created_at: moment().subtract(154, "days") },
  { value: 100, created_at: moment().subtract(161, "days") },
  { value: 100, created_at: moment().subtract(168, "days") },
  { value: 100, created_at: moment().subtract(175, "days") },
  { value: 100, created_at: moment().subtract(182, "days") },
  { value: 100, created_at: moment().subtract(189, "days") },
  { value: 100, created_at: moment().subtract(196, "days") },
  { value: 100, created_at: moment().subtract(203, "days") }
];

const w = [
  {
    id: 1,
    video_link: "youtube.com",
    title: "How to get the most out of a tutorial",
    duration: 140,
    content: "yoyoyoy",
    thumbnail: "https://blog.exscudo.com/wp-content/uploads/2017/10/sitemap_tutorial_2.jpg",
    description: "",
    type: "VideoWidget",
    codes: ["cl_er"]
  },
  {
    id: 2,
    content: "You can click the skip button to fast forward through the tutorial!",
    type: "TipWidget",
    title: "In a rush?",
    codes: ["cl_er"]
  },
  {
    id: 3,
    image_url:
      "http://konyvtar.elte.hu/sites/default/files/hirek/online-music-production-tutorials.jpg",
    title: "Tutorials: A History",
    description: "A look in to the rich and storied history of the tutorial.",
    link: "http://rhabitapp.com",
    type: "BlogPostWidget",
    codes: ["cl_er"]
  }
];

const data = {
  questionsCount: 5,
  streak: 245,
  daily_content: {
    behavior: {
      description: "Complete the tutorial from start to finish."
    },
    habit: {
      name: "Learning How To Use Rhabit",
      description: "Understanding the ins and outs of the Rhabit platform."
    },
    widget: {
      type: "TipWidget",
      title: "Need help? Check out the help section!",
      content:
        "The help section can be found at the top of your screen. Clicking it will take you to the Rhabit knowledge base, which has information that this tutorial doesn't cover.  If you're ever unsure of how something in Rhabit works, check it out!",
      code: ["TUT"],
      thumbnail_url: null,
      document_url: null
    }
  },

  widgets: w,
  highFives: [
    {
      id: 1,
      type: "highfive",
      sender: {
        id: 1,
        avatar: "https://randomuser.me/api/portraits/men/62.jpg",
        name: "John Smith"
      },
      receiver: {
        name: "Paul Cook",
        id: 2,
        avatar: "https://randomuser.me/api/portraits/men/30.jpg"
      },
      receivers: [
        {
          name: "Paul Cook",
          id: 2,
          avatar: "https://randomuser.me/api/portraits/men/30.jpg"
        }
      ],
      content: "Great job writing that tutorial Paul, I learned a lot!",
      comments: [],
      likes: ["Sally Patterson", "Kanye West"],
      created_at: moment().subtract(5, "days")
    },
    {
      id: 2,
      type: "highfive",
      sender: {
        name: "Dade Murphy",
        id: 2,
        avatar: "https://randomuser.me/api/portraits/men/1.jpg"
      },
      receiver: {
        name: "Danielle Bobak",
        avatar: "https://randomuser.me/api/portraits/women/59.jpg",
        id: 3
      },
      receivers: [
        {
          name: "Danielle Bobak",
          avatar: "https://randomuser.me/api/portraits/women/59.jpg",
          id: 3
        }
      ],
      content: "Danielle really bailed me out in our meeting today! Thanks!",
      comments: [],

      likes: ["Sally Patterson", "Kanye West"],
      created_at: moment().subtract(7, "days")
    },
    {
      id: 3,
      type: "highfive",
      sender: {
        name: "David Shanabarger",
        id: 3,
        avatar: "https://randomuser.me/api/portraits/men/64.jpg"
      },
      receiver: {
        name: "Jackson Davis",
        id: 4,
        avatar: "https://randomuser.me/api/portraits/men/46.jpg"
      },
      receivers: [
        {
          name: "Jackson Davis",
          id: 4,
          avatar: "https://randomuser.me/api/portraits/men/46.jpg"
        }
      ],
      content:
        "Jackson took the time to explain to me how to use our new HRIS system, a huge help!",
      likes: ["Sally Patterson", "Kanye West"],
      comments: [],

      created_at: moment().subtract(8, "days")
    }
  ],
  habits: [
    {
      id: "skjfghgasliuvbasliibvvalsbv",
      name: "Example Habit 1",
      dont_know_percentage: 13,
      score: 76,
      last_score: 70,
      responses: 30,
      raters_count: 5,
      additional_feedback: [],
      raters: [
        {
          name: "David Shanabarger",
          id: 3,
          avatar: "https://randomuser.me/api/portraits/men/64.jpg"
        },
        {
          name: "Jackson Davis",
          id: 3,
          avatar: "https://randomuser.me/api/portraits/women/46.jpg"
        },
        {
          name: "Tom Shanabarger",
          id: 3,
          avatar: "https://randomuser.me/api/portraits/men/34.jpg"
        },
        {
          name: "David Shanabarger",
          id: 3,
          avatar: "https://randomuser.me/api/portraits/women/24.jpg"
        },
        {
          name: "David Shanabarger",
          id: 3,
          avatar: "https://randomuser.me/api/portraits/men/61.jpg"
        },
        {
          name: "David Shanabarger",
          id: 3,
          avatar: "https://randomuser.me/api/portraits/women/62.jpg"
        },
        {
          name: "David Shanabarger",
          id: 3,
          avatar: "https://randomuser.me/api/portraits/men/69.jpg"
        }
      ],
      trend,
      behaviors: [
        {
          description: "When explaining something, is concise and gets to the point quickly.",
          score: 100,
          code: "cl_er"
        },
        {
          description: "When making a request, clearly states what they want and by when.",
          score: 80,
          code: "cl_er"
        },
        {
          description: "When explaining something, uses easy to follow language.",
          score: 100,
          code: "cl_er"
        },
        {
          description: "When others talk, pays attention and does not do other things on the side.",
          score: 90,
          code: "cl_er"
        },
        {
          description: "Writes clear and easy to understand emails and other written documents.",
          score: 10,
          code: "cl_er"
        }
      ],
      widgets: w,
      active: true
    },
    {
      id: "rekuybfadshbfkjhafiaejyrfawkyf",
      name: "Example Habit 2",
      score: 88,
      widgets: [],
      responses: 30,
      raters_count: 5,
      additional_feedback: [],
      behaviors: [
        {
          description: "Completes deliverables on time.",
          score: 100
        },
        {
          description: "Produces high quality work.",
          score: 80
        },
        {
          description: "Sticks to agreed-upon outcomes, plans, and processes.",
          score: 100
        },
        {
          description: "Is on time for calls and meetings.",
          score: 90
        },
        {
          description: "Makes people aware when a deadline cannot be met ahead of time.",
          score: 70
        }
      ],
      active: true
    }
  ]
};

export default data;
