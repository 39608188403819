import React from "react";
import { Modal, Typography } from "antd";
import Box from "layout/Box";
import { Changes } from ".";
import { useTranslation } from "react-i18next";

interface Props {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  changes: Changes;
}

const ChangeModal: React.FC<Props> = ({ visible, onCancel, onOk, changes }) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      title={t("habit_picker.confirm_title")}
      okText={t("habit_picker.confirm_ok_text")}
      cancelText={t("cancel")}
    >
      <Typography.Title level={4}>{t("habit_picker.added")}</Typography.Title>
      <Box display="flex" flexDirection="column">
        {changes.added.map((c) => (
          <div style={{ color: "#40cc75" }}>+ {c.name}</div>
        ))}
      </Box>
      <Typography.Title level={4} style={{ marginTop: 12 }}>
        {t("habit_picker.removed")}
      </Typography.Title>
      <Box display="flex" flexDirection="column">
        {changes.removed.map((c) => (
          <div style={{ color: "red" }}>- {c.name}</div>
        ))}
      </Box>
      <Typography.Title level={4} style={{ marginTop: 12 }}>
        {t("habit_picker.unchanged")}
      </Typography.Title>
      <Box display="flex" flexDirection="column">
        {changes.unChanged.map((c) => (
          <div>• {c.name}</div>
        ))}
      </Box>
    </Modal>
  );
};

export default ChangeModal;
