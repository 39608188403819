/* eslint-disable no-nested-ternary */
import React from "react";
import { Typography, Button, Modal, Tooltip } from "antd";
import Box from "layout/Box";
import styled from "styled-components";
import useStores from "utils/useStores";
import { IFetchAllHabitsQuery } from "../queries/fetchAllHabits.generated";
import { NotMobile } from "containers/Responsive";

interface Props {
  habit: IFetchAllHabitsQuery["habits"]["nodes"][0];
  selected: boolean;
  removeHabit: (id: string) => void;
  addHabit: (id: string) => void;
  showBehaviors: boolean;
  warnIfNotLeader: boolean;
}

const RemoveButton = styled(Button)`
  background: red;
  color: white;
`;
const AddButton = styled(Button)`
  background: #40cc75;
  color: white;
`;

const HabitListItem: React.FC<Props> = ({
  habit,
  selected,
  removeHabit,
  addHabit,
  showBehaviors,
  warnIfNotLeader
}) => {
  const {
    profileStore: { currentUser }
  } = useStores();

  const handleAdd = (): void => {
    if (warnIfNotLeader && !currentUser.manager) {
      Modal.confirm({
        title: "This is a leader habit, are you sure?",
        content: `${habit.name} is a leader habit.  This habit should only be added if you’re in a positon of executive authority within your company, otherwise the feedback you receive on this habit may not be meaningful or valid. `,
        onOk: () => addHabit(habit.id),
        okText: "I'm Sure"
      });
    } else {
      addHabit(habit.id);
    }
  };

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      padding={showBehaviors ? 0 : "6px"}
    >
      <Box
        width={showBehaviors ? "60%" : "100%"}
        display="flex"
        alignItems="center"
      >
        <Box display="flex" minWidth={120} alignItems="center">
          {selected ? (
            <Button
              style={{ background: "red", color: "white" }}
              size={showBehaviors ? "large" : "small"}
              onClick={(): void => removeHabit(habit.id)}
            >
              Remove
            </Button>
          ) : habit.selectable ? (
            <Button
              style={
                habit.selectable
                  ? { background: "#40cc75", color: "white" }
                  : {}
              }
              onClick={handleAdd}
              disabled={!habit.selectable}
              size={showBehaviors ? "large" : "small"}
            >
              Add
            </Button>
          ) : (
            <Tooltip title="This habit is archived, You can no longer select it, but you can continue using it for now.">
              <Button
                style={
                  habit.selectable
                    ? { background: "#40cc75", color: "white" }
                    : {}
                }
                onClick={handleAdd}
                disabled={!habit.selectable}
                size={showBehaviors ? "large" : "small"}
              >
                Add
              </Button>
            </Tooltip>
          )}
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="center">
          {showBehaviors ? (
            <Typography.Title level={4}>{habit.name}</Typography.Title>
          ) : (
            <Typography>{habit.name}</Typography>
          )}
          {showBehaviors && (
            <Typography style={{ fontSize: 16 }}>
              {habit.description}
            </Typography>
          )}
        </Box>
      </Box>
      <NotMobile>
        {showBehaviors && (
          <Box display="flex" flexDirection="column" width="40%">
            <ul>
              {habit.behaviors.map((behavior) => (
                <li style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  <Typography style={{ fontSize: 16 }}>
                    {behavior.anchor}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        )}
      </NotMobile>
    </Box>
  );
};

export default HabitListItem;
