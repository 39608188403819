import i18n from "utils/i18n";

export const steps = [
  {
    target: "body",
    content: "tutorial.home_1",
    disableBeacon: true,
    placement: "center"
  },
  {
    target: "#habits",
    content: "tutorial.home_3",
    disableBeacon: true
  },
  {
    target: "#demo_habit",
    content: "tutorial.home_4",
    disableBeacon: true
  },
  {
    target: "#feed",
    content: "tutorial.home_5",
    disableBeacon: true
  },
  {
    target: "#fab",
    content: "tutorial.home_6",
    disableBeacon: true,
    spotlightPadding: 24
  },
  {
    target: "body",
    content: "tutorial.home_7",
    disableBeacon: true,
    placement: "center"
  }
];

export default steps;
