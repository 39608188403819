/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const BoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 50px;
  height: 50px;
`;

export const Text = styled.span`
  -webkit-line-clamp: ${({
  shrunk
}) => shrunk && '2'};
  text-overflow: ${({
  shrunk
}) => shrunk && 'ellipsis'};
  display: ${({
  shrunk
}) => shrunk && '-webkit-box'};
  -webkit-box-orient: ${({
  shrunk
}) => shrunk && 'vertical'};
  overflow: ${({
  shrunk
}) => shrunk && 'hidden'};
`;