/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import { media } from "utils/style-utils";

export const Raters = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 8px;
  margin-right: 25px;
  ${media.handheld`
    justify-content: center
  `};
`;

export const Mask = styled.span`
  background-color: black;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: white;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  position: absolute;
  z-index: 500;
  margin-left: -8px;
  opacity: 0.5;
`;