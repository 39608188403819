/* eslint-disable prefer-destructuring */

/* eslint-disable import/prefer-default-export */

/* eslint-disable no-debugger */

/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/prefer-default-export
import queryString from "query-string";

export const paramsIntercept = hash => {
  hash = queryString.parse(hash);
  //
  let category;

  if (hash["status[]"]) {
    category = hash["status[]"][0];
  }

  if ((hash.reorder || hash.page === "1") && category === "COMPLETED") {
    if (hash.reorder) {
      hash.reorder = hash.reorder.replace("delivery_date", "date_completed");
      //
      if (hash.reorder.includes("ASC")) {
        hash.reorder = hash.reorder.replace("ASC", "DESC");
      } else {
        hash.reorder = hash.reorder.replace("DESC", "ASC");
      }
    }
    // if (hash.page === '1') {
    //   hash.reorder = 'date_completed DESC';
    // }
  }
  return queryString.stringify(hash);
};
