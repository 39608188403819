import React from "react";
import Box from "layout/Box";
import { ReactComponent as Image } from "./logo.svg";

interface Props {
  color: string;
  style?: React.CSSProperties;
  size?: string;
  bordered?: boolean;
}

type Options = {
  width: number;
  height: number;
  imageWidth: number;
  imageHeight: number;
};

const options: { [key: string]: Options } = {
  default: {
    width: 48,
    height: 48,
    imageWidth: 32,
    imageHeight: 32
  },
  small: {
    width: 32,
    height: 32,
    imageWidth: 18,
    imageHeight: 18
  }
};

const CategoryIcon: React.FC<Props> = ({
  color,
  style,
  size = "default",
  bordered = false
}) => {
  const { width, height, imageHeight, imageWidth } = options[size];

  return (
    <Box
      background="white"
      height={height}
      width={width}
      display="flex"
      justifyContent="center"
      borderRadius="50%"
      alignItems="center"
      position="absolute"
      bottom={24}
      right={24}
      style={{ ...style }}
      border={bordered ? `1px solid ${color}` : "none"}
    >
      <Image style={{ color, width: imageWidth, height: imageHeight }} />
    </Box>
  );
};

export default CategoryIcon;
