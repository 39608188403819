/* eslint-disable no-unused-expressions */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Input, List, Popover, Icon, Button } from "antd";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import viewModal from "components/FullScreenModal";
import useStores from "utils/useStores";
import { Wrapper, Square, Item, ListItem } from "./styles";
import LoadingOverlay from "../LoadingOverlay";

const LabelPicker = observer(({ onSubmit, currentlySelected }) => {
  const { objectiveStore, profileStore } = useStores();
  const [selected, setSelected] = useState(currentlySelected);
  const [loading, setLoading] = useState(true);
  const [viewingModal, setViewingModal] = useState(false);
  const [query, setQuery] = useState("");
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    objectiveStore.fetchLabels().then(() => {
      setLoading(false);
    });
  }, []);

  const { labels } = objectiveStore;
  const { currentUser } = profileStore;
  useEffect(() => {
    const handler = (e) => {
      const { keyCode } = e;
      if (keyCode === 40) {
        setIndex(index + 1 > labels.length - 1 ? index : index + 1);
      } else if (keyCode === 38) {
        setIndex(index - 1 < 0 ? 0 : index - 1);
      } else if (keyCode === 13) {
        if (index > -1) {
          selected.includes(labels[index].id)
            ? setSelected(selected.filter((s) => s !== labels[index].id))
            : setSelected([...selected, labels[index].id]);
        }
      }
    };
    window.addEventListener("keydown", handler);

    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [labels, index]);

  const handleVisibleChange = (visible) => {
    if (!visible) {
      onSubmit(selected.map((s) => labels.find((l) => l.id === s)));
    }
  };

  const content = (
    <Wrapper>
      {loading && <LoadingOverlay />}
      <Input
        ref={(input) => input && input.focus()}
        onChange={(e) => setQuery(e.target.value)}
        style={{ width: "100%", marginBotton: 12 }}
        placeholder="Search labels"
      />
      <List
        style={{ maxHeight: currentUser.admin ? 300 : 325, overflowY: "auto" }}
        dataSource={
          loading
            ? []
            : labels.filter(
                (l) => l.text.toLowerCase().indexOf(query.toLowerCase()) === 0
              )
        }
        renderItem={(item) => (
          <ListItem
            hovered={index === labels.map((l) => l.id).indexOf(item.id)}
            onClick={() =>
              selected.includes(item.id)
                ? setSelected(selected.filter((s) => s !== item.id))
                : setSelected([...selected, item.id])
            }
          >
            <Item>
              <Square color={item.color} />
              {item.text}
              {selected.includes(item.id) && (
                <Icon
                  style={{ position: "absolute", right: 12 }}
                  type="check"
                />
              )}
            </Item>
          </ListItem>
        )}
      />
      {currentUser.admin && (
        <Button
          onClick={() => {
            setViewingModal(true);
            viewModal({
              type: "labels",
              onClose: () => setViewingModal(false)
            });
          }}
        >
          Edit Labels
        </Button>
      )}
    </Wrapper>
  );
  return (
    <Popover
      content={content}
      title="Manage Labels"
      trigger="click"
      overlayStyle={{ display: viewingModal && "none" }}
      onVisibleChange={handleVisibleChange}
    >
      <a role="presentation">Manage Labels</a>
    </Popover>
  );
});

LabelPicker.defaultProps = {
  currentlySelected: []
};

LabelPicker.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  currentlySelected: PropTypes.array
};
export default LabelPicker;
