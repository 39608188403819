/* eslint-disable */
import React from "react";
import { Avatar, Icon, Tooltip, Button, Typography } from "antd";
import { Wrapper, User, Name } from "./styles";
import { withTheme } from "styled-components";
import nameToInitials from "../../../utils/nameToInitials";

const UserControls = ({
  users,
  reviews,
  currentUser,
  review,
  setUser,
  handleNext,
  buttonDisabled,
  previousDisabled,
  handlePrevious,
  setSummarizing,
  theme
}) => {
  return (
    <Wrapper style={{ flexDirection: "column", padding: 24 }}>
      <Wrapper>
        <Button
          style={{ marginBottom: 12 }}
          disabled={previousDisabled}
          onClick={handlePrevious}
        >
          Previous
        </Button>
        <Button
          style={{ marginBottom: 12 }}
          disabled={buttonDisabled}
          onClick={handleNext}
        >
          Next
        </Button>
        {users.map((u) => (
          <Tooltip key={u.id} title={u.name}>
            <User
              clickable={reviews.find((r) => r.user_id === u.id)}
              active={u.id === currentUser}
              onClick={() =>
                reviews.find((r) => r.user_id === u.id) && setUser(u.id)
              }
            >
              <Avatar size={50} src={u.avatar}>
                {nameToInitials(u.name)}
              </Avatar>
              <Typography.Text style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                {u.name.split(" ")[0]}{" "}
                {reviews.find((r) => r.user_id === u.id) && (
                  <Icon
                    type="check"
                    style={{ color: theme.primary_color, marginLeft: 2 }}
                  />
                )}
              </Typography.Text>
            </User>
          </Tooltip>
        ))}
      </Wrapper>
    </Wrapper>
  );
};

export default withTheme(UserControls);
