/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { Layout, Button, Icon, Drawer, Modal, Menu, Spin } from "antd";
import { Switch, Route, Redirect } from "react-router-dom";
import { withTheme } from "styled-components";
import { observable } from "mobx";
import * as Sentry from "@sentry/browser";
import { observer, inject } from "mobx-react";
import SiderMenu from "components/SiderMenu";

import Relationships from "containers/Relationships";
import HomePage from "containers/HomePage";
import FeedbackForm from "containers/Page/HeaderActions/FeedbackForm";
import viewModal from "components/FullScreenModal";

import Actions from "containers/Actions";
import LoadingOverlay from "components/LoadingOverlay";

import ProtectedRoute from "components/ProtectedRoute";
import Slack from "containers/Slack";
import SlackOAuth from "containers/SlackOAuth";
import TeamsOAuth from "containers/TeamsOAuth";
import MSTeams from "containers/MSTeams";
import ReactGA from "react-ga";
import Art from "assets/Pittsburgh_end.svg";
import Logo from "assets/text_black.png";
import { Team, Profile as ProfileAPI, User } from "services/api";
import ManagerDashboardNext from "containers/ManagerDashboardNext";
import HeaderActions from "./HeaderActions";
import { InnerLayout, Header, Content, Sider, MobileSider } from "./styles";
import { withTranslation } from "react-i18next";
import AccountWizard from "components/AccountWizard";

const LatestDeepDive = React.lazy(() => import("containers/DeepDiveV2"));
const StableDeepDive = React.lazy(() => import("containers/DeepDive"));
const Dashboard = React.lazy(() => import("containers/DashboardOrg"));
const Organizations = React.lazy(() => import("containers/Organizations"));
const PrivacyPolicy = React.lazy(() => import("components/PrivacyPolicyModal"));
const RatingRequest = React.lazy(() => import("containers/RatingRequest"));
const ManagerDashboard = React.lazy(() => import("containers/DashboardTeam"));
const NetworkAnalysis = React.lazy(() => import("containers/NetworkAnalysis"));
const Objectives = React.lazy(() => import("containers/Objectives"));
const NPSFeedback = React.lazy(() => import("containers/NPSFeedback"));
const MyReports = React.lazy(() => import("containers/MyReports"));
const TeamConfig = React.lazy(() => import("containers/TeamConfig"));
const Feedback = React.lazy(() => import("containers/Feedback"));
const Mentoring = React.lazy(() => import("containers/Mentoring"));
const Toolkit = React.lazy(() => import("containers/Toolkit"));
const Shop = React.lazy(() => import("containers/Shop"));
const Analytics = React.lazy(() => import("containers/Analytics"));
const ManageWidgets = React.lazy(() => import("containers/ManageWidgets"));
const CMS = React.lazy(() => import("containers/CMS"));
const Logs = React.lazy(() => import("containers/ApplicationLogs"));
const ManageUpdates = React.lazy(() => import("containers/ManageUpdates"));
const SuperAdminTools = React.lazy(() => import("containers/SuperAdminTools"));
const AdminCarrotShop = React.lazy(() => import("containers/AdminCarrotShop"));
const OrganizationConfig = React.lazy(() =>
  import("containers/OrganizationConfig")
);
const Teams = React.lazy(() => import("containers/Teams"));
const BrandSevenDashboard = React.lazy(() =>
  import("containers/BrandSevenDashboard")
);

@inject("profileStore", "proposalStore", "tutorialStore")
@observer
class BasicLayout extends React.Component {
  @observable menuCollapsed = true;
  @observable mobile = false;
  @observable feedbackOpen = false;
  @observable users = [];
  @observable loadingUsers = false;
  @observable networkSetupFor = null;
  @observable slackModalOpen = false;
  @observable MSTeamsModalOpen = false;
  @observable onboardTeamVisible = false;

  static propTypes = {
    theme: PropTypes.object.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    proposalStore: PropTypes.shape({
      fetchAsTeam: PropTypes.func.isRequired,
      fetchAsAdmin: PropTypes.func.isRequired
    }).isRequired,
    profileStore: PropTypes.shape({
      updateInfo: PropTypes.func.isRequired,
      isSuperAdmin: PropTypes.bool.isRequired,
      isExternalUser: PropTypes.bool.isRequired,
      logout: PropTypes.func.isRequired,
      currentUser: PropTypes.shape({
        email: PropTypes.string.isRequired,
        managed_proposals: PropTypes.array.isRequired,
        admin: PropTypes.bool.isRequired,
        direct_reports_to_onboard: PropTypes.bool.isRequired
      }).isRequired
    }).isRequired
  };

  constructor(props) {
    super(props);
    // console.log("profilestore", props.profileStore)
    // debugger
    if (window.innerWidth < 500) {
      this.mobile = true;
      this.menuCollapsed = true;
    }

    // Sentry.init({
    //   dsn: 'https://43fb863c5fee476299ec6a1fa3f9bb82@sentry.io/162476'
    // });

    Sentry.configureScope((scope) => {
      scope.setUser({ email: props.profileStore.currentUser.email });
    });

    if (props.location.pathname === "/slack") {
      this.slackModalOpen = true;
    }
    if (props.location.pathname === "/slack/oauth") {
      this.slackOAuthModalOpen = true;
    }
    if (props.location.pathname === "/oauth") {
      this.MSTeamsModalOpen = true;
    }

    props.history.listen((location) => ReactGA.pageview(location.pathname));
    window.addEventListener("beforeunload", () => {
      if (props.profileStore.isExternalUser) {
        props.profileStore.logout();
      }
    });

    if (props.profileStore.currentUser.direct_reports_to_onboard) {
      this.loadingUsers = true;
      Team.usersNotOnboarded().then(({ data }) => {
        this.users = data.filter((u) => !u.self_onboard_preference);
        this.onboardTeamVisible = this.users.length > 0;
        this.loadingUsers = false;
      });
    }

    if (
      !props.profileStore.isSuperAdmin &&
      !props.profileStore.isExternalUser &&
      (props.profileStore.currentUser.admin ||
        props.profileStore.currentUser.managed_proposals.length > 0)
    ) {
      if (props.profileStore.currentUser.admin) {
        // props.proposalStore.fetchAsAdmin();
      } else {
        // props.proposalStore.fetchAsTeam();
      }
    }
  }

  closeMenu = () => {
    this.menuCollapsed = true;
  };

  closeSlackModal = () => {
    const { history } = this.props;
    this.slackModalOpen = false;
    history.replace({ pathname: "/" });
  };

  closeSlackOAuthModal = () => {
    const { history } = this.props;
    this.slackOAuthModalOpen = false;
    history.replace({ pathname: "/" });
  };

  closeMSTeamsModal = () => {
    const { history } = this.props;
    this.MSTeamsModalOpen = false;
    history.replace({ pathname: "/" });
  };

  closeTeamsOAuthModal = () => {
    const { history } = this.props;
    this.teamsOAuthModalOpen = false;
    history.replace({ pathname: "/" });
  };

  sendForm = (type, values) => {
    // const form = this.type === "feedback" ? this.feedbackRef.props.form : this.supportRef.props.form
    const { t } = this.props;
    if (type === "feedback") {
      ProfileAPI.sendFeedback(values);
      this.feedbackOpen = false;
      Modal.success({
        title: t("feedback_form_success.title"),
        content: t("feedback_form_success.content")
      });
    } else {
      ProfileAPI.requestSupport(values);
      this.supportOpen = false;
      Modal.success({
        title: t("support_form_success.title"),
        content: t("support_form_success.content")
      });
    }
  };

  toggleCollapsed = () => (this.menuCollapsed = !this.menuCollapsed);

  openRelationships = (user) => {
    const { history } = this.props;
    viewModal(
      {
        width: 900,
        onClose: () => {
          this.users = this.users.map((u) =>
            user.id === u.id ? Object.assign({}, u, { done: true }) : u
          );
          User.update(user.id, {
            initial_config: true,
            onboarded: true
          });
        }
      },
      history,
      <Relationships managedUser={user} />
    );
  };

  render() {
    const {
      location,
      t,
      profileStore,
      tutorialStore,
      theme,
      profileStore: { currentUser }
    } = this.props;

    const {
      organization: { background }
    } = currentUser;
    const items = [
      {
        id: 0,
        color: theme.extra_color_0,
        icon: "message",
        tooltip: t("fab.support_chat"),
        onClick: () => window.olark("api.box.expand")
      },
      {
        id: 1,
        color: theme.extra_color_1,
        icon: "bulb",
        tooltip: t("fab.knowledge_base"),
        onClick: () =>
          window.open("https://help.rhabitapp.com/knowledge-base/", "_blank")
      },
      {
        id: 2,
        color: theme.extra_color_2,
        icon: "rocket",
        tooltip: t("fab.tutorial"),
        onClick: () => tutorialStore.runTutorial("product_tour")
      },
      {
        id: 3,
        color: theme.extra_color_3,
        icon: "mail",
        tooltip: t("fab.give_us_feedback"),
        onClick: () => (this.feedbackOpen = true)
      }
    ];

    const {
      currentUser: { consent },
      logout
    } = profileStore;
    // let visible = false;
    // if (this.users.filter((u) => !u.done).length) {
    //   visible = true;
    // }
    let deepDive = StableDeepDive;
    if (currentUser.organization.features.includes("LATEST_DEEPDIVE")) {
      deepDive = LatestDeepDive;
    }

    const redirectLocation =
      currentUser.admin && profileStore.features().includes("NEXT_UI")
        ? "/admin_dashboard"
        : "/";

    return (
      <div id="scroll-here">
        <AccountWizard
          visible={
            tutorialStore.runningTutorial === "how_to_setup_account" &&
            !profileStore.isSuperAdmin
          }
          onClose={tutorialStore.endCurrentTutorial}
        />
        {!this.mobile && <Actions items={items} />}
        <FeedbackForm
          visible={this.feedbackOpen}
          onCancel={() => (this.feedbackOpen = false)}
          onSend={(values) => this.sendForm("feedback", values)}
        />
        <React.Suspense fallback={<LoadingOverlay />}>
          <PrivacyPolicy initialConsent={!consent} logout={logout} />
        </React.Suspense>
        <Layout
          style={{
            minHeight: window.innerWidth > 500 && "100vh",
            position: "relative",
            zIndex: 1
          }}
        >
          {this.slackModalOpen && <Slack onClose={this.closeSlackModal} />}
          {this.MSTeamsModalOpen && (
            <MSTeams onClose={this.closeMSTeamsModal} />
          )}
          {this.slackOAuthModalOpen && (
            <SlackOAuth onClose={this.closeSlackOAuthModal} />
          )}
          {this.teamsOAuthModalOpen && (
            <TeamsOAuth onClose={this.closeTeamsOAuthModal} />
          )}

          <img
            alt="pittsburgh"
            src={background || Art}
            style={{
              transform: !background && "scaleX(-1)",
              position: "fixed",
              opacity: background && 0.3,
              bottom: 0,
              left: !this.mobile && 80,
              width: this.mobile ? "100%" : 800,
              height: "auto",
              zIndex: 1
            }}
          />

          {!profileStore.isExternalUser && (
            <React.Fragment>
              {!this.mobile ? (
                <MobileSider
                  width={256}
                  collapsed={this.menuCollapsed}
                  onCollapse={this.toggleCollapsed}
                >
                  <SiderMenu
                    collapsed={this.menuCollapsed}
                    canConfigure={currentUser.can_configure}
                    hasTeam={!!currentUser.managed_team_id}
                    isManager={currentUser.manager}
                    includeTeam={
                      !!currentUser.managed_team_id ||
                      !!currentUser.has_managed_groups
                    }
                    mobile={this.mobile}
                    teamName={currentUser.managed_team_name}
                    features={
                      profileStore.isSuperAdmin
                        ? []
                        : currentUser.organization.features
                    }
                    includeOrg={currentUser.admin}
                    isSuperAdmin={profileStore.isSuperAdmin}
                    orgLogoLarge={
                      currentUser.organization &&
                      currentUser.organization.sider_logo_large
                    }
                    orgLogoSmall={
                      currentUser.organization &&
                      currentUser.organization.sider_logo_small
                    }
                    orgName={
                      currentUser.organization && currentUser.organization.name
                    }
                  />
                </MobileSider>
              ) : (
                <Drawer
                  bodyStyle={{ padding: 0 }}
                  placement="left"
                  onClose={this.closeMenu}
                  closable={false}
                  visible={!this.menuCollapsed}
                >
                  <Sider width={256}>
                    <SiderMenu
                      collapsed={false}
                      closeMenu={this.closeMenu}
                      hasTeam={!!currentUser.managed_team_id}
                      isManager={currentUser.manager}
                      includeTeam={
                        !!currentUser.managed_team_id ||
                        !!currentUser.has_managed_groups
                      }
                      mobile={this.mobile}
                      teamName={
                        currentUser.managed_team_id
                          ? currentUser.managed_team_name
                          : "Groups"
                      }
                      includeOrg={currentUser.admin}
                      orgName={
                        currentUser.organization &&
                        currentUser.organization.name
                      }
                      isSuperAdmin={profileStore.isSuperAdmin}
                      features={
                        profileStore.isSuperAdmin
                          ? []
                          : currentUser.organization.features
                      }
                    />
                  </Sider>
                </Drawer>
              )}
            </React.Fragment>
          )
          //
          }
          <InnerLayout
            external={profileStore.isExternalUser}
            id="main-body"
            mobile={this.mobile}
            menuCollapsed={this.menuCollapsed}
          >
            {!profileStore.isExternalUser ? (
              <Header
                mobile={this.mobile}
                menuCollapsed={this.menuCollapsed}
                height={64}
              >
                <Icon
                  onClick={this.toggleCollapsed}
                  fill="rgba(0,0,0,.65)"
                  type={this.menuCollapsed ? "menu-unfold" : "menu-fold"}
                  style={{
                    fontSize: 20,
                    padding: "22px 24px",
                    cursor: "pointer"
                  }}
                  height={64}
                />
                <HeaderActions
                  superAdmin={currentUser.superadmin}
                  noCallToAction={location.pathname === "/feedback"}
                />
              </Header>
            ) : (
              <Header
                style={{
                  alignItems: "center",
                  width: "100vw",
                  justifyContent: "center",
                  display: "flex"
                }}
                mobile={this.mobile}
                menuCollapsed={this.menuCollapsed}
                height={64}
              >
                <img alt="logo" src={Logo} height="80%" />
              </Header>
            )}
            <Content id="content-body" mobile={window.innerWidth < 500}>
              <Modal
                visible={this.onboardTeamVisible}
                title={t("global.onboard_team_members_title")}
                footer={[
                  <Button onClick={() => (this.onboardTeamVisible = false)}>
                    {t("global.remind_me_later")}
                  </Button>,
                  <Button
                    type="primary"
                    disabled={this.users.some((u) => !u.done)}
                    onClick={() =>
                      ProfileAPI.update().then(({ data }) => {
                        profileStore.updateInfo(data);
                        this.onboardTeamVisible = false;
                      })
                    }
                  >
                    {t("ok")}
                  </Button>
                ]}
              >
                {this.loadingUsers ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Spin />
                  </div>
                ) : (
                  <>
                    <Menu selectable={false}>
                      {this.users.map((user) => (
                        <Menu.Item
                          key={user.id}
                          disabled={user.done}
                          onClick={() => this.openRelationships(user)}
                        >
                          {user.name}
                          {user.done && (
                            <Icon type="check" style={{ marginLeft: 20 }} />
                          )}
                          <Button
                            disabled={user.done}
                            style={{ float: "right" }}
                          >
                            {t("global.onboard")}
                          </Button>
                        </Menu.Item>
                      ))}
                    </Menu>
                  </>
                )}
              </Modal>
              <React.Suspense fallback={<LoadingOverlay />}>
                <Switch location={location}>
                  {profileStore.isExternalUser ? (
                    <Switch>
                      <Route exact path="/feedback" component={Feedback} />
                      <Route path="/request/:id/" component={RatingRequest} />
                      <Route path="/nps" component={NPSFeedback} />
                    </Switch>
                  ) : (
                    <Switch>
                      <ProtectedRoute
                        isAllowed={!profileStore.features().includes("NEXT_UI")}
                        exact
                        path="/"
                        redirectTo={
                          currentUser.admin
                            ? "/admin_dashboard"
                            : "/admin/config"
                        }
                        component={HomePage}
                      />
                      <Route exact path="/" component={HomePage} />
                      <ProtectedRoute
                        isAllowed={currentUser.organization.features.includes(
                          "CARROT_SHOP"
                        )}
                        exact
                        path="/carrot_shop"
                        component={Shop}
                      />
                      <Route
                        exact
                        path="/highfive/:highfiveId"
                        render={() => <Redirect to="/" />}
                      />
                      <Route path="/habits" component={deepDive} />
                      <Route
                        path="/mentoring"
                        render={() => (
                          <Mentoring
                            initialPath={profileStore.mentoringPath || ""}
                          />
                        )}
                      />
                      {profileStore.features().includes("ONE_ON_ONES") && (
                        <Route
                          path="/profile/one_on_ones"
                          render={() => <Toolkit mode="user" />}
                        />
                      )}
                      <Route
                        path="/profile/reports"
                        render={() => <Toolkit mode="user" />}
                      />
                      <Route
                        path="/rate"
                        render={() => <Redirect to="/feedback" />}
                      />
                      <Route exact path="/feedback" component={Feedback} />
                      {profileStore.features().includes("OBJECTIVES") && (
                        <Route path="/objectives" component={Objectives} />
                      )}
                      <ProtectedRoute
                        redirectTo="/"
                        isAllowed={profileStore
                          .features()
                          .includes("ONE_ON_ONES")}
                        path="/my-reports"
                        render={() => <MyReports />}
                      />
                      {profileStore.isSuperAdmin && (
                        <Switch>
                          <Route
                            exact
                            path="/superadmin/organizations"
                            component={Organizations}
                          />
                          <Route
                            exact
                            path="/superadmin/tools"
                            component={SuperAdminTools}
                          />
                          <Route
                            exact
                            path="/superadmin/widgets"
                            component={ManageWidgets}
                          />
                          <Route exact path="/superadmin/cms" component={CMS} />
                          <Route
                            exact
                            path="/superadmin/updates"
                            component={ManageUpdates}
                          />
                          <Route
                            exact
                            path="/superadmin/logs"
                            component={Logs}
                          />
                        </Switch>
                      )}
                      {/* Manager Routes */}

                      <ProtectedRoute
                        isAllowed={
                          (profileStore.features().includes("ONE_ON_ONES") &&
                            !profileStore.features().includes("NEXT_UI") &&
                            (!!currentUser.managed_team_id ||
                              !!currentUser.has_managed_groups)) ||
                          currentUser.manager
                        }
                        path="/manager/one_on_ones"
                        redirectTo="/manager/reports"
                        render={() => <Toolkit mode="manager" />}
                      />
                      <ProtectedRoute
                        isAllowed={
                          !profileStore.features().includes("NEXT_UI") &&
                          (!!currentUser.managed_team_id ||
                            !!currentUser.has_managed_groups)
                        }
                        path="/manager/reports"
                        redirectTo="/"
                        render={() => <Toolkit mode="manager" />}
                      />
                      <ProtectedRoute
                        isAllowed={!!currentUser.managed_team_id}
                        path="/team/analytics"
                        redirectTo="/"
                        render={() => <Analytics asManager />}
                      />
                      <ProtectedRoute
                        isAllowed={
                          !profileStore.features().includes("NEXT_UI") &&
                          (!!currentUser.managed_team_id ||
                            !!currentUser.has_managed_groups ||
                            currentUser.isManager)
                        }
                        redirectTo="/"
                        exact
                        path="/manager_dashboard"
                        component={
                          profileStore
                            .features()
                            .includes("NEXT_MANAGER_DASHBOARD") ||
                          (profileStore
                            .features()
                            .includes("ALLOW_OPT_IN_NEXT_MANAGER_DASHBOARD") &&
                            currentUser.preferences
                              .opted_in_new_manager_dashboard)
                            ? ManagerDashboardNext
                            : ManagerDashboard
                        }
                      />
                      <ProtectedRoute
                        isAllowed={
                          !profileStore.features().includes("NEXT_UI") &&
                          (!!currentUser.managed_team_id ||
                            !!currentUser.has_managed_groups ||
                            currentUser.isManager)
                        }
                        redirectTo={redirectLocation}
                        exact
                        path="/manager_dashboard/:id"
                        component={
                          profileStore
                            .features()
                            .includes("NEXT_MANAGER_DASHBOARD") ||
                          (profileStore
                            .features()
                            .includes("ALLOW_OPT_IN_NEXT_MANAGER_DASHBOARD") &&
                            currentUser.preferences
                              .opted_in_new_manager_dashboard)
                            ? ManagerDashboardNext
                            : ManagerDashboard
                        }
                      />
                      <ProtectedRoute
                        isAllowed={
                          !profileStore.features().includes("NEXT_UI") &&
                          (!!currentUser.managed_team_id ||
                            !!currentUser.has_managed_groups)
                        }
                        redirectTo={redirectLocation}
                        exact
                        path="/team/config"
                        component={TeamConfig}
                      />
                      {/* Admin Routes */}
                      <Switch>
                        <ProtectedRoute
                          isAllowed={currentUser.admin}
                          redirectTo="/"
                          exact
                          path="/admin_dashboard"
                          component={
                            currentUser.organization_name === "brandseven"
                              ? BrandSevenDashboard
                              : Dashboard
                          }
                        />
                        <ProtectedRoute
                          isAllowed={
                            currentUser.admin &&
                            currentUser.organization_name !== "brandseven"
                          }
                          redirectTo="/"
                          path="/analytics"
                          component={Analytics}
                        />
                        <ProtectedRoute
                          isAllowed={
                            currentUser.admin &&
                            profileStore.features().includes("ONE_ON_ONES")
                          }
                          path="/admin/one_on_ones"
                          redirectTo="/admin/reports"
                          render={() => <Toolkit mode="admin" />}
                        />
                        <ProtectedRoute
                          isAllowed={
                            currentUser.admin &&
                            profileStore.features().includes("CARROT_SHOP")
                          }
                          path="/admin/carrot_shop"
                          redirectTo="/"
                          render={() => <AdminCarrotShop />}
                        />
                        <ProtectedRoute
                          isAllowed={
                            currentUser.admin &&
                            profileStore.features().includes("CAREER_PLANNER")
                          }
                          path="/admin/career_planner"
                          redirectTo="/admin/reports"
                          render={() => <Toolkit mode="admin" />}
                        />
                        <ProtectedRoute
                          isAllowed={currentUser.admin}
                          path="/admin/reports"
                          redirectTo="/"
                          render={() => <Toolkit mode="admin" />}
                        />
                        <ProtectedRoute
                          isAllowed={
                            currentUser.admin || currentUser.can_configure
                          }
                          redirectTo="/"
                          exact
                          path="/admin/config"
                          component={OrganizationConfig}
                        />
                        <ProtectedRoute
                          isAllowed={
                            currentUser.admin || currentUser.can_configure
                          }
                          redirectTo="/"
                          exact
                          path="/admin/teams"
                          component={Teams}
                        />
                        <ProtectedRoute
                          isAllowed={
                            currentUser.admin &&
                            currentUser.organization_name !== "brandseven"
                          }
                          redirectTo="/"
                          exact
                          path="/admin/network"
                          component={NetworkAnalysis}
                        />
                        <Route
                          path="/*"
                          component={() =>
                            profileStore.features().includes("NEXT_UI") &&
                            (currentUser.admin || currentUser.can_configure) ? (
                              <OrganizationConfig />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                      </Switch>
                    </Switch>
                  )}
                </Switch>
              </React.Suspense>
            </Content>
          </InnerLayout>
        </Layout>
      </div>
    );
  }
}

export default withTranslation()(withTheme(BasicLayout));
