/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const OverflowContainer = styled.div`
  width: 100%;
  height: 75px;
  font-size: ${({
  theme
}) => `${theme.font.body}px`};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  bottom: 3px;
  left: 0px;
  background: linear-gradient(
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 1)
  );
`;