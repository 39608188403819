// import styled from "styled-components";
// import {
//   typography,
//   space,
//   color,
//   flexbox,
//   ColorProps,
//   WidthProps,
//   SpaceProps,
//   TypographyProps,
//   FlexboxProps,
//   width
// } from "styled-system";

// type BoxProps = WidthProps & SpaceProps;

// // // eslint-disable-next-line import/prefer-default-export
// export const Box = styled.div<BoxProps>(typography, space, color, flexbox, width);
// export const Text = styled.span<TypographyProps>(typography);

import React from "react";
import styled from "styled-components";
import {
  borderRadius,
  BorderRadiusProps,
  color,
  layout,
  LayoutProps,
  display,
  DisplayProps,
  ColorProps,
  background,
  BackgroundProps,
  fontFamily,
  FontFamilyProps,
  fontSize,
  FontSizeProps,
  fontStyle,
  FontStyleProps,
  fontWeight,
  FontWeightProps,
  flexbox,
  grid,
  GridProps,
  position,
  PositionProps,
  border,
  BorderProps,
  FlexboxProps,
  letterSpacing,
  MarginProps,
  margin,
  LetterSpacingProps,
  lineHeight,
  LineHeightProps,
  shadow,
  ShadowProps,
  size,
  SizeProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  textStyle,
  TextStyleProps
} from "styled-system";
import { TypographyProps } from "antd/lib/typography";

export type StyledSystemProps =
  | SpaceProps
  | ShadowProps
  | DisplayProps
  | PositionProps
  | ColorProps
  | GridProps
  | MarginProps
  | BackgroundProps
  | BorderProps
  | LayoutProps
  | FontSizeProps
  | FontStyleProps
  | SizeProps
  | TextStyleProps
  | LetterSpacingProps
  | FontFamilyProps
  | FontWeightProps
  | BorderRadiusProps
  | FontFamilyProps
  | LineHeightProps
  | TextAlignProps
  | FlexboxProps
  | TypographyProps
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | {
      color: string;
      as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
      id: string;
    };

const UnstyledBox = styled.div`
  ${space}
  ${fontSize}
  ${fontStyle}
  ${size}
	${color}
	${display}
	${margin}
	${layout}
	${grid}
	${border}
	${textStyle}
	${background}
  ${letterSpacing}
  ${fontFamily}
  ${fontWeight}
  ${borderRadius}
	${lineHeight}
	${position}
	${textAlign}
	${flexbox}
	${shadow}
	`;

const Box: React.FC<StyledSystemProps & {
  as?: any;
  style?: React.CSSProperties;
}> = (props) => {
  return <UnstyledBox {...props} />;
};

export default Box;
