/* eslint-disable no-debugger */
/* eslint-disable no-else-return */
/* eslint-disable one-var */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { Badge, Button } from "antd";
import NoContent from "assets/Flavor5.svg";
import Display from "assets/WomenDisplay.svg";
import AdditionalFeedbackView from "containers/AdditionalFeedback";
import Widget from "components/WidgetV2";
import moment from "moment";
import qs from "querystring";
import { AdditionalFeedback } from "services/api";
import { HomeCard } from "../styles";
import {
  HeaderContainer,
  BehaviorDot,
  BehaviorDesc,
  NoWidgetContainer,
  WidgetContainer,
  HeaderTitle,
  RemainingFeedbackContainer
} from "./styles";
import { alphabet } from "../HabitOverview/alphabet";
import ViewSelection from "./viewSelection";
import ImproveView from "./improveView";
import RatersList from "../ActionsAndInfo/NetworkRaters/RaterList";

const generateKey = (index) => {
  return `${index}_${new Date().getTime()}`;
};

const mobile = window.innerWidth < 500;

const viewMap = {
  improve: "How to Improve",
  additional: "Additional Feedback",
  "How to Improve": "improve",
  "Additional Feedback": "additional"
};

@observer
class Improvement extends React.Component {
  @observable behaviors = [];
  @observable behaviorSelected = null;
  @observable habitSelected = null;
  @observable widgets = [];
  @observable selectedWidget = null;
  @observable taggings = {};
  @observable activeTabKey = "widgets";
  @observable activeKey;
  @observable widgetMap = {};
  @observable habit = { additional_feedback: [] };
  @observable view = "improve";
  @observable selectedPrompts = [];

  static propTypes = {
    habit: PropTypes.shape({
      behaviors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
      widgets: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired
    }).isRequired,
    colorDot: PropTypes.func.isRequired,
    behaviorSelected: PropTypes.number.isRequired,
    // tabsEnabled: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    dashboardViewer: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.setHabit(props);
  }

  setHabit = ({ habit }) => {
    if (!habit || !habit.behaviors) return;
    this.habit = habit;
    this.behaviors = habit.behaviors.filter((b) => typeof b.score === "number");
    this.activeKey = this.behaviors.length ? this.behaviors[0].code : null;
    const codes = this.behaviors.map((b) => b.code);
    const widgets = habit.widgets.filter((w) =>
      w.codes.some((c) => codes.includes(c))
    );
    if (this.behaviors.length && widgets.length) {
      this.setWidgets(widgets, habit);
    } else {
      this.setWidgets(
        habit.widgets.filter((w) => w.codes.includes(habit.code)),
        habit
      );
    }
  };

  emptyCheck = (arr) => {
    let notEmpty = true;
    arr.forEach((wi) => {
      if (wi) {
        notEmpty = false;
      }
    });
    return notEmpty;
  };

  reOrderWidgets = (priorityIndex) => {
    const { habit } = this;

    if (!this.widgetMap[priorityIndex]) {
      return;
    }
    const w = this.widgetMap[priorityIndex].map((id) =>
      habit.widgets.find((wig) => wig.id === id)
    );
    if (this.emptyCheck(w)) {
      return;
    }
    const countObj = {};
    w.forEach((wi) => {
      if (!countObj[wi.title]) {
        countObj[wi.title] = 1;
      } else {
        countObj[wi.title] += 1;
      }
    });
    this.widgets = w.filter((wi) => {
      if (countObj[wi.title] > 1) {
        countObj[wi.title] = 0;
        return true;
      } else if (countObj[wi.title] === 1) {
        return true;
      }
      return false;
    });
  };

  getLocation = (codes) => {
    return this.behaviors.findIndex((b) =>
      Object.values(codes).includes(b.code)
    );
  };

  mapBehaviors = (location, widgetId) => {
    this.widgetMap[location.toString()] = [];
  };

  mapWidgets = (widgets) => {
    widgets.forEach((w, i) => {
      this.widgetMap[this.getLocation(w.codes)].push(w.id);
    });
    debugger;
  };

  getCircle = (codes) => {
    return this.getLocation(codes) + 1;
  };

  getBehaviorScore = (loc) => {
    if (!loc && loc !== 0) {
      return null;
    }
    const { colorDot } = this.props;
    const location = loc;
    let color = "#ccc";
    if (
      this.behaviors[location] &&
      typeof this.behaviors[location].score === "number"
    ) {
      color = colorDot(this.behaviors[location].score);
    }
    return color;
  };

  componentWillMount(props) {
    const { behaviorSelected, habit } = this.props;
    const queryString = qs.parse(window.location.search);
    if (queryString["?view"]) {
      this.view = queryString["?view"];
    }
    if (mobile && behaviorSelected !== this.behaviorSelected) {
      this.habitSelected = habit.id;
      this.behaviorSelected = behaviorSelected;
      if (this.behaviorSelected || this.behaviorSelected === 0) {
        this.reOrderWidgets(behaviorSelected);
      }
    }
  }

  componentWillReceiveProps(newProps) {
    this.habit = newProps.habit;
    //
    if (
      this.habit.has_outstanding_feedback &&
      this.habit.additional_feedback &&
      this.habit.additional_feedback.length > 0 &&
      typeof this.behaviorSelected !== "number"
    ) {
      this.view = "additional";
    }
    if (
      this.habit.additional_feedback &&
      this.habit.additional_feedback.length === 0
    ) {
      this.view = "improve";
    }

    if (newProps.habit.id !== this.habitSelected) {
      if (this.habitSelected !== null) {
        this.behaviorSelected = null;
      }
      this.setHabit(newProps);
      this.habitSelected = newProps.habit.id;
    }
    if (newProps.behaviorSelected !== this.behaviorSelected) {
      if (typeof newProps.behaviorSelected === "number") {
        this.view = "improve";
      }
      this.behaviorSelected = newProps.behaviorSelected;
      if (this.behaviorSelected || this.behaviorSelected === 0) {
        this.reOrderWidgets(newProps.behaviorSelected);
      }
    }
  }

  setWidgets = (s, habit) => {
    const codes = this.behaviors.map((b) => b.code);
    const copy = this.behaviors.slice();
    const widgets = habit.widgets.filter((w) =>
      w.codes.some((c) => codes.includes(c))
    );
    this.behaviors.forEach((w, i) => {
      this.mapBehaviors(i, w.id);
    });

    this.mapWidgets(widgets);
    let arr = [];
    copy
      .sort((a, b) => a.score - b.score)
      .forEach((b, i) => {
        const loc = this.behaviors.findIndex((be) => be.id === b.id);
        const priorityArray = this.widgetMap[loc];
        arr = arr.concat(priorityArray);
      });
    this.widgets = arr.map((id) => widgets.find((w) => id === w.id));
  };

  handleViewChange = (value) => {
    this.view = value;
  };

  measureContainer = () => {
    const img = 32;
    const button = 134;
    const container = document.getElementById("improvement-badge");
    if (container) {
      const containerWidth = container.offsetWidth - 48;
      return Math.floor((containerWidth - button) / img);
    }
    return 10;
  };

  handleRemoveSelectedPrompt = (prompt) => {
    this.selectedPrompts = this.selectedPrompts.filter((p) => p !== prompt);
  };

  handleAddSelectedPrompt = (newPrompt) => {
    this.selectedPrompts.push(newPrompt);
  };

  handlefilterSelected = () => {
    //
    if (this.selectedPrompts.length <= 0) {
      return this.habit.additional_feedback.sort((a, b) => {
        return moment(a.created_at).valueOf() < moment(b.created_at).valueOf()
          ? 1
          : -1;
      });
    }
    return this.habit.additional_feedback
      .filter((af) => {
        if (this.selectedPrompts.includes(af.prompt.question_text)) {
          return true;
        }
        return false;
      })
      .sort((a, b) => {
        return moment(a.created_at).valueOf() < moment(b.created_at).valueOf()
          ? 1
          : -1;
      });
  };

  handleFilterAdditionalFeedback = () => {
    let filtered = this.habit.additional_feedback.filter((af) => {
      if (!af.feedback_answer.response) {
        return true;
      }
      return false;
    });

    const availableSpace = this.measureContainer();

    let remaining = filtered.slice(availableSpace - 1, filtered.length);
    filtered = filtered.slice(0, availableSpace);
    filtered = filtered.map((f) => f.feedback_giver);
    remaining = remaining.map((f) => f.feedback_giver);

    return { filtered, remaining };
  };

  handleDestroyAll = async () => {
    await AdditionalFeedback.destroy(
      this.habit.additional_feedback[0].habit_user_id
    );
    this.habit.has_outstanding_feedback = false;
    this.habit.additional_feedback = this.habit.additional_feedback.filter(
      (feedback) => feedback.feedback_answer.response
    );
  };

  cancelRequest = async (id) => {
    await AdditionalFeedback.destroy(id);
    this.habit.additional_feedback = this.habit.additional_feedback.map(
      (feedback) => {
        if (feedback.id === id) {
          return {
            ...feedback,
            status: "canceled"
          };
        } else {
          return feedback;
        }
      }
    );
    this.habit.has_outstanding_feedback = false;
    // this.habit.additional_feedback = this.habit.additional_feedback.filter(
    //   (feedback) => feedback.feedback_answer.response
    // );
  };

  hasUnansweredFeedback = () => {
    let unansweredFeedback = false;
    this.habit.additional_feedback.forEach((af) => {
      if (!af.feedback_answer.response) {
        unansweredFeedback = true;
      }
    });
    return unansweredFeedback;
  };

  render() {
    const {
      habit: { feedbacks },
      dashboardViewer
    } = this.props;
    const cardStyle = {
      minHeight: "500px",
      height: !mobile && "60vH",
      maxHeight: mobile && 600,
      overflowY: "auto",
      width: "100%"
    };
    const bodyStyle = {
      display: "flex",
      marginTop: mobile ? 32 : 64,
      flexDirection: "column",
      height: "calc(100% - 65px)",
      padding: mobile ? 24 : 0,
      width: "100%"
    };

    let selectFlow;
    if (this.view === "additional") {
      selectFlow = false;
    } else if (this.view === "improve") {
      selectFlow = this.behaviorSelected || this.behaviorSelected === 0;
    }

    const body =
      this.view === "improve" ? (
        <ImproveView widgets={this.widgets} selectFlow={selectFlow} />
      ) : (
        <AdditionalFeedbackView
          feedback={this.habit.additional_feedback}
          cancelRequest={this.cancelRequest}
        />
      );

    return (
      <Badge
        id="improvement-badge"
        count={feedbacks ? feedbacks.filter((f) => !f.seen).length : 0}
        style={{ position: "relative" }}
      >
        <>
          <HeaderContainer>
            <ViewSelection
              addPrompt={this.handleAddSelectedPrompt}
              removePrompt={this.handleRemoveSelectedPrompt}
              habit={this.habit}
              view={this.view}
              handleViewChange={this.handleViewChange}
              viewMap={viewMap}
            />
            {/* {selectFlow && (
                <>
                  <BehaviorDot
                    color={() => {
                      return this.getBehaviorScore(this.behaviorSelected);
                    }}
                  >
                    <span style={{ verticalAlign: 'middle', fontSize: '21px' }}>
                      {this.behaviors[this.behaviorSelected] &&
                        typeof this.behaviors[this.behaviorSelected].score === 'number' &&
                        alphabet[this.behaviorSelected]}
                    </span>
                  </BehaviorDot>
                  <BehaviorDesc width={window.innerWidth > 1290 ? '50%' : '40%'}>
                    {this.behaviors[this.behaviorSelected] &&
                      this.behaviors[this.behaviorSelected].description}
                  </BehaviorDesc>
                </>
              )} */}
          </HeaderContainer>

          {/* {this.view === 'additional' && this.hasUnansweredFeedback() && (
              <RemainingFeedbackContainer id="remaining-feedback-container">
                <span style={{ fontSize: 16 }}>Awaiting Responses From:</span>
                <div style={{ display: 'flex', marginTop: '20px' }}>
                  <RatersList filteredRaters={filtered} remainingRaters={remaining} />
                  <Button onClick={this.handleDestroyAll} type="primary">
                    Cancel Request
                  </Button>
                </div>
              </RemainingFeedbackContainer>
            )} */}
          <HomeCard
            id="improvement"
            style={cardStyle}
            headStyle={{ display: "none" }}
            bodyStyle={bodyStyle}
          >
            {body}
          </HomeCard>
        </>

        {/* {mobile && body} */}
      </Badge>
    );
  }
}

export default Improvement;
