/* eslint-disable no-plusplus */

/* eslint-disable import/prefer-default-export */
export const generateScores = (habitScore, behaviorCount) => {
  const arr = [];
  let count = behaviorCount;
  let boundRange;
  const max = 100;
  const buffer = 10;

  if (max - habitScore < 50) {
    boundRange = max - habitScore;
  } else {
    boundRange = habitScore;
  }

  if (boundRange > buffer) {
    boundRange = buffer;
  }

  if (behaviorCount % 2 !== 0) {
    arr.push(habitScore);
    count -= 1;
  }
  const countByUpperBound = Math.floor(boundRange / (count / 2));
  const countBy = countByUpperBound - 1;
  let multiplier = 1;
  for (let i = 1; i <= count; i++) {
    if (i > count / 2) {
      if (i === count / 2 + 1) {
        multiplier = 1;
      }
      arr.push(habitScore - countBy * multiplier);
    } else {
      arr.push(habitScore + countBy * multiplier);
    }
    multiplier++;
  }
  return arr;
};