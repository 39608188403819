/* eslint-disable no-unused-vars */
/* eslint-disable default-case */

import React, { useState } from "react";
import { Modal, Divider, Button } from "antd";
import { HabitUser } from "types/shared";
import { StyledIcon, BodyContainer } from "./styles";
import ExplainOverall from "./Content/explainOverall";
import PickPrompt from "./Content/pickPrompt";
import ExplainPrivacy from "./Content/explainPrivacy";
import SendRequests from "./Content/sendRequests";
import { useTranslation } from "react-i18next";

type MoreFeedbackWizardProps = {
  visible: boolean;
  close: () => void;
  habit: HabitUser;
};

const mobile = window.innerWidth < 500;

const MoreFeedbackWizard = ({
  visible,
  close,
  habit
}: MoreFeedbackWizardProps) => {
  const [step, setStep] = useState(1);
  const [promptId, setPromptId] = useState("");
  const { t } = useTranslation();

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handleBackStep = () => {
    setStep(step - 1);
  };

  const handleClose = (didSubmit) => {
    setStep(1);
    close(didSubmit);
    setPromptId("");
  };

  let content;

  switch (step) {
    case 1:
      content = <ExplainOverall />;
      break;
    case 2:
      content = (
        <PickPrompt
          promptId={promptId}
          setPromptId={setPromptId}
          habit={habit}
        />
      );
      break;
    case 3:
      content = <ExplainPrivacy />;
      break;
    case 4:
      content = (
        <SendRequests
          promptId={promptId}
          habit={habit}
          close={() => handleClose(true)}
        />
      );
  }

  const progressText = step < 4 ? t("next") : t("submit");
  const progressButton = step < 4 && (
    <Button
      disabled={!promptId && step === 2}
      data-cy="next-btn"
      type="primary"
      onClick={handleNextStep}
    >
      {progressText}
    </Button>
  );
  const regressButton = step > 1 && (
    <Button onClick={handleBackStep}>{t("back")}</Button>
  );
  return (
    <Modal
      destroyOnClose
      title={t("deep_dive.more_feedback_wizard.title")}
      centered
      style={{
        height: "100vh",
        maxHeight: !mobile && 700,
        padding: mobile && 0,
        margin: mobile && 0
      }}
      bodyStyle={{ maxHeight: "calc(100vh - 110px)", overflow: "auto" }}
      width={mobile ? "100%" : "75%"}
      visible={visible}
      onCancel={() => handleClose(false)}
      footer={[regressButton, progressButton]}
      maskClosable
    >
      <BodyContainer>
        <div
          style={{
            width: mobile ? "100%" : "65%",
            display: "flex",
            alignItems: "center",
            marginBottom: 12
          }}
        >
          <StyledIcon step={step === 1} type="question" />
          <Divider style={{ minWidth: "5%", margin: " 0px 15px" }} />
          <StyledIcon step={step === 2} type="bulb" />
          <Divider style={{ minWidth: "5%", margin: "0px 15px" }} />
          <StyledIcon step={step === 3} type="eye" />
          <Divider style={{ minWidth: "5%", margin: "0px 15px" }} />
          <StyledIcon step={step === 4} type="check" />
        </div>
        {content}
      </BodyContainer>
    </Modal>
  );
};

export default MoreFeedbackWizard;
