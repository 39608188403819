import { Icon, Typography } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import useStores from "utils/useStores";
import { ArticulateLesson } from ".";

const CardWrapper = styled.div`
  width: 275px;
  height: 300px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #e8e8e8;
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: 0.2s;
  position: relative;
  &:hover {
    box-shadow: inset 0.1rem 0 0 0 rgba(0, 0, 0, 0.05),
      inset -0.1rem 0 0 0 rgba(0, 0, 0, 0.05),
      inset 0 -0.1rem 0 0 rgba(0, 0, 0, 0.08), 0 0.4rem 2rem rgba(0, 0, 0, 0.1);
    transform: translateY(-0.4rem);
    z-index: 1;
  }
`;

const CoverImage = styled.div`
  width: 100%;
  height: 110px;
`;

const CardBody = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
`;
const CompletionCover = styled.div`
  position: absolute;
  width: 100%;
  height: 110px;
  background: rgba(0, 0, 0, 0.8);
  padding: 24px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 24px;
`;

const Spacer = styled.div`
  width: 50px;
  height: 2px;
  background-color: #e8e8e8;
  margin-bottom: 6px;
`;

const LessonCard: React.FC<{
  lesson: ArticulateLesson;
  completed: boolean;
  disabled: boolean;
}> = ({ lesson, completed, disabled }) => {
  const history = useHistory();
  const {
    profileStore: { currentUser }
  } = useStores();

  return (
    <CardWrapper
      disabled={disabled}
      onClick={() =>
        !disabled &&
        window.open(
          `${lesson.url}?userId=${currentUser.id}&tenant=${currentUser.tenant}`,
          "_blank"
        )
      }
    >
      {completed && (
        <CompletionCover>
          <Icon
            type="check-circle"
            theme="filled"
            style={{ fontSize: 32, color: "#40cc75", marginRight: 12 }}
          />
          Completed
        </CompletionCover>
      )}
      <CoverImage style={{ backgroundImage: `url(${lesson.img})` }} />
      <CardBody>
        <Spacer />
        <Typography.Title level={4}>{lesson.name}</Typography.Title>
      </CardBody>
    </CardWrapper>
  );
};

export default LessonCard;
