/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable no-unused-vars */

/* eslint-disable no-debugger */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Text } from "./styles";

const ExpandableText = ({ text, id }) => {
  const [shrunk, setShrunk] = useState(false);
  const [adjustable, setAdj] = useState(false);

  const measureText = () => {
    const textEle = document.getElementById(id);
    if (textEle) {
      const lines = textEle.offsetHeight / 17;
      if (lines > 2) {
        setAdj(true);
        setShrunk(true);
      }
    }
  };

  const handleToggle = () => {
    setShrunk(!shrunk);
  };

  useEffect(() => {
    measureText();
  }, [text]);
  return (
    <div>
      <Text shrunk={shrunk} id={id}>
        {text}
      </Text>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {adjustable && (
          <a role="presentation" onClick={handleToggle}>
            {shrunk ? "See More" : "See Less"}
          </a>
        )}
      </div>
    </div>
  );
};

ExpandableText.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default ExpandableText;
