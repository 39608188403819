import React from "react";
import PropTypes from "prop-types";
import { Modal, Steps, Button, Icon, Input, Skeleton } from "antd";
import { observable, computed } from "mobx";
import { observer, inject } from "mobx-react";
import {
  Habit,
  User,
  Admin,
  Profile,
  SuperAdmin,
  ExternalUsers
} from "services/api";
import Habits from "./Habits";

import Review from "./Review";

const { Step } = Steps;

@inject("organizationStore", "profileStore")
@observer
class NetworkSetup extends React.Component {
  @observable step = this.startStep || 0;
  @observable habits = [];
  @observable users = [];
  @observable externalUsers = [];
  @observable selectedHabits = [];
  @observable originalHabits = [];
  @observable loading = true;
  @observable userRows = [];
  @observable externalUserRows = [];
  @observable managedMember = {};
  @observable raterSelectOpen = false;
  @observable externalRaterSelectOpen = false;
  @observable updatedUser = null;
  @observable roles = [];
  @observable newRoleName = "";

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onboarding: PropTypes.bool,
    asUser: PropTypes.bool.isRequired,
    step: PropTypes.number,
    managedId: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string
    }).isRequired,
    teamHabits: PropTypes.array,
    organizationStore: PropTypes.shape({
      addRole: PropTypes.func.isRequired
    }).isRequired,
    profileStore: PropTypes.shape({
      addRole: PropTypes.func.isRequired
    }).isRequired
  };

  static defaultProps = {
    onboarding: false,
    step: 0,
    managedId: "",
    teamHabits: []
  };

  constructor(props) {
    super(props);
    let calls = [];
    this.startStep = props.step;
    if (props.managedId) {
      this.api = new SuperAdmin(props.managedId);
      calls = [
        this.api.fetchUser(props.user.id),
        this.api.fetchOrganizationHabits(),
        this.api.fetchUsers(),
        ExternalUsers.all(),
        this.api.fetchRoles()
      ];
    } else {
      calls = [
        User.habits(props.user.id),
        Habit.all()
        // User.all(),
        // ExternalUsers.all()
      ];

      if (!props.asUser) {
        calls.push(Admin.fetchRoles());
      }
    }

    Promise.all(calls).then((resArray) => {
      this.setUser(resArray[0].data);
      this.habits = resArray[1].data;

      // this.users = resArray[2].data.sort((a, b) => (a.name < b.name ? -1 : 1));
      // this.externalUsers = resArray[3].data;

      if (!props.asUser) {
        this.roles = resArray[2].data;
      }
      this.suggestedHabits = props.teamHabits
        ? props.teamHabits.map((h) => h.id)
        : [];
      this.requiredHabits = props.teamHabits
        ? props.teamHabits.filter((h) => h.required).map((h) => h.id)
        : [];
      this.loading = false;
      if (
        this.managedMember.role_id &&
        this.originalHabits.length === 0 &&
        !props.asUser
      ) {
        this.selectRole(this.managedMember.role_id);
      }
    });
  }

  setUser = (res) => {
    this.managedMember = res;
    this.selectedHabits = this.managedMember.habits.map((h) => ({
      id: h.id,
      name: h.name,
      type: h.type,
      employee_type: h.employee_type,
      raters: h.raters
    }));
    this.originalHabits = this.selectedHabits;

    this.userRows = this.managedMember.habits
      .filter((h) => h.type !== 2)
      .reduce((array, habit) => {
        habit.raters.forEach((user) => {
          if (!array.find((u) => u.id === user.id)) {
            // const found =
            //   users.find((u) => u.id === user) ||
            //   externalUsers.find((u) => u.id === user);
            const external = !Object.keys(user).includes("avatar");
            // this.users.push({ id: found.id, name: found.name})
            array.push({
              id: user.id,
              name: user.name,
              key: user.id,
              avatar: user.avatar,
              type: external ? "ExternalUser" : "User",
              accept_status: external ? user.accept_status : null
            });
          }
        });

        this.managedMember.proposals
          .filter((p) => p.type === "RaterProposal")
          .forEach((p) => {
            if (!array.find((u) => u.id === p.rater_id)) {
              array.push({
                id: p.rater_id,
                name: p.rater,
                key: p.rater_id
              });
            }
          });

        return array;
      }, []);
    // this.externalUserRows = this.managedMember.habits.reduce((array, habit) => {
    //   habit.raters.forEach((user) => {
    //     if (!array.find((u) => u.id === user)) {
    //       const found = externalUsers.find((u) => u.id === user);
    //       array.push({ id: found.id, name: found.name, key: found.id });
    //     }
    //   });
    //   return array;
    // }, []);
  };

  handleNext = () => (this.step += 1);
  handleBack = () => (this.step -= 1);

  addExternalUser = (rater) => {
    if (!this.externalUsers.includes((u) => u.id === rater.id)) {
      this.externalUsers.push(rater);
    }
    // !this.externalUsers.includes((u) => u.id === rater.id) &&
    //   this.externalUsers.push(rater);
  };

  handleSelectHabit = (e) => {
    const id = e.target.value;
    if (this.selectedHabits.find((h) => h.id === id)) {
      this.selectedHabits = this.selectedHabits.filter((h) => h.id !== id);
    } else {
      const habit = this.habits.find((h) => h.id === id);
      const prevHabit = this.managedMember.habits.find((h) => h.id === id);
      const raters = prevHabit ? prevHabit.raters : [];
      this.selectedHabits = [
        ...this.selectedHabits,
        {
          id,
          name: habit.name,
          raters,
          type: habit.type,
          employee_type: habit.employee_type
        }
      ];
    }
  };

  componentDidMount() {
    setTimeout(() => {
      this.forceUpdate();
    }, 250);
  }

  selectAll = (habitId) => {
    let { selectedHabits } = this;
    const habit = this.selectedHabits.find((h) => h.id === habitId);

    if (habit.type === 2) {
      if (this.externalUserRows.length === habit.raters.length) {
        habit.raters = [];
      } else {
        this.externalUserRows.forEach((user) => {
          if (!habit.raters.includes(user.id)) {
            habit.raters = [...habit.raters, user.id];
          }
        });
      }
    } else if (this.userRows.length === habit.raters.length) {
      habit.raters = [];
    } else {
      this.userRows.forEach((user) => {
        if (!habit.raters.find((u) => u.id === user.id)) {
          habit.raters = [...habit.raters, user];
        }
      });
    }
    selectedHabits = selectedHabits.filter((h) => h.id !== habitId);
    selectedHabits.push(habit);
    this.selectedHabits = selectedHabits;
  };

  handleSelectRater = (habitId, user) => {
    let { selectedHabits } = this;
    const habit = selectedHabits.find((h) => h.id === habitId);
    if (habit.raters.find((r) => r.id === user.id)) {
      habit.raters = habit.raters.filter((r) => r.id !== user.id);
    } else {
      habit.raters = [...habit.raters, user];
    }
    selectedHabits = selectedHabits.filter((h) => h.id !== habitId);
    selectedHabits.push(habit);
    this.selectedHabits = selectedHabits;
  };

  handleSelectRaterForProposedHabit = (habitId, user, proposal) => {
    const { selectedHabits } = this;
    const selectedHabit = this.selectedHabits.find((h) => h.id === habitId);
    if (selectedHabit) {
      if (selectedHabit.raters.find((r) => r.id === user.id)) {
        selectedHabit.raters = selectedHabit.raters.filter(
          (raterId) => raterId !== user.id
        );
      } else {
        selectedHabit.raters = [...selectedHabit.raters, user];
      }
      this.selectedHabits = [
        ...selectedHabits.filter((h) => h.id !== selectedHabit.id),
        selectedHabit
      ];
    } else {
      const habit = this.habits.find((h) => h.id === habitId);
      selectedHabits.push({
        id: habit.id,
        name: habit.name,
        type: habit.type,
        employee_type: habit.employee_type,
        raters: [user],
        proposal
      });
      this.selectedHabits = selectedHabits;
    }
  };

  selectUser = (id) => {
    User.habits(id).then(({ data }) => {
      this.selectedHabits = data.habits.map((h) => ({
        id: h.id,
        name: h.name,
        type: h.type,
        employee_type: h.employee_type,
        raters: [] // h.raters.map(r => r.id)
      }));
    });
  };

  handleAddRaters = (users) => {
    users.forEach((user) => {
      // const newUser =
      //   this.users.find((u) => u.id === userId) ||
      //   this.externalUsers.find((u) => u.id === userId);
      this.userRows.push({ ...user, key: user.id });
    });
    this.raterSelectOpen = false;
    this.externalRaterSelectOpen = false;
  };
  handleAddExternalRaters = (users) => {
    users.forEach((userId) => {
      const newUser = this.externalUsers.find((u) => u.id === userId);
      if (!this.externalUserRows.find((r) => r.id === userId)) {
        this.externalUserRows.push({
          id: newUser.id,
          name: newUser.name,
          key: newUser.id,
          accept_status: newUser.accept_status
        });
      }
    });
    this.externalRaterSelectOpen = false;
  };

  handleSubmit = () => {
    const { managedId, asUser } = this.props;
    this.loading = true;
    /* eslint-disable */

    const that = this;
    if (managedId) {
      this.api
        .updateUser(this.managedMember.id, {
          habits: this.selectedHabits,
          role_id: this.managedMember.role_id
        })
        .then((res) => {
          this.loading = false;
          [this.updatedUser] = res.data;
          this.handleNext();
        });
    } else if (asUser) {
      Profile.createProposals(this.changes).then(() => {
        this.loading = false;
        this.handleNext();
      });
    } else {
      let params = {
        habits: this.selectedHabits,
        role_id: this.managedMember.role_id
      };
      if (!this.managedMember.onboarded) {
        params = Object.assign({}, params, {
          initial_config: true,
          onboarded: true
        });
      }

      User.update(this.managedMember.id, params).then((res) => {
        this.loading = false;
        [this.updatedUser] = res.data;
        this.handleNext();
      });
    }
  };
  /*eslint-disable */
  removeRater = (rater) => {
    this.userRows = this.userRows.filter((r) => r.id !== rater.id);
    this.selectedHabits.forEach((h) => {
      h.raters = h.raters.filter((r) => r.id !== rater.id);
    });
  };
  /* eslint-enable */
  selectRole = (id) => {
    this.managedMember.role_id = id;

    if (id) {
      this.selectedHabits = this.roles
        .find((role) => role.id === id)
        .habits.filter((h) => this.habits.find((hh) => hh.id === h.id))
        .map((habit) => {
          const h = this.habits.find((hh) => hh.id === habit.id);

          const prevHabit = this.managedMember.habits.find(
            (hh) => hh.id === h.id
          );
          const raters = prevHabit ? prevHabit.raters : [];
          return { id: h.id, name: h.name, raters };
        });
    }
  };

  @computed get changes() {
    const result = {};

    // this.selectedAndProposedHabits.filter(h => h.proposal).forEach(h => { // find habit level PROPOSALS that need to be represented
    //   result[h.id] = {
    //     name: h.name,
    //     change: h.proposal.change_type,
    //     raters: this.managedMember.proposals.filter(proposal => (
    //       proposal.habit_id === h.id && proposal.rater_id
    //     )).reduce((object, proposal) => {
    //       const { add, remove } = object;
    //       if (proposal.change_type === "add") {
    //         add.push({ id: proposal.rater_id, name: proposal.rater });
    //       } else {
    //         remove.push({ id: proposal.rater_id, name: proposal.rater });
    //       }
    //       return { add, remove };
    //     }, { add: [], remove: [] })
    //   }
    // });

    this.managedMember.habits.forEach((habit) => {
      // find habits that have been removed
      const selectedHabit = this.selectedHabits.find((h) => h.id === habit.id);
      // if (!selectedHabit || (selectedHabit.proposal && selectedHabit.proposal.change_type === "remove")) {
      if (!selectedHabit) {
        result[habit.id] = { name: habit.name, change: "remove" };
      }
    });

    this.selectedHabits.forEach((habit) => {
      // find habits that have been added
      if (!this.managedMember.habits.find((h) => h.id === habit.id)) {
        if (!result[habit.id]) {
          result[habit.id] = { name: habit.name, change: "add" };
        }
      }
    });

    this.managedMember.habits.forEach((previouslySelectedHabit) => {
      // find raters that have been removed
      const currentlySelectedHabit = this.selectedHabits.find(
        (h) => h.id === previouslySelectedHabit.id
      );
      if (currentlySelectedHabit) {
        // only compare raters of habits that are still selected
        // if (!currentlySelectedHabit.proposal) { // don't bother with existing habits with proposals because they're all removals
        if (!result[previouslySelectedHabit.id]) {
          result[previouslySelectedHabit.id] = {
            name: previouslySelectedHabit.name
          }; // empty object needs to be initialized
        }
        previouslySelectedHabit.raters.forEach((rater) => {
          if (
            !currentlySelectedHabit.raters.find(
              (rater2) => rater2.id === rater.id
            )
          ) {
            // look for O.G. raters not currently included
            const raters = result[previouslySelectedHabit.id].raters || {
              add: [],
              remove: []
            };
            // const previouslySelectedRater =
            //   this.users.find((u) => u.id === rater.id) ||
            //   this.externalUsers.find((u) => u.id === rater.id);
            raters.remove = [
              ...raters.remove,
              {
                id: rater.id,
                name: rater.name,
                type: rater.type
              }
            ];
            result[previouslySelectedHabit.id] = Object.assign(
              {},
              result[previouslySelectedHabit.id],
              { raters }
            );
          }
        });
        // }
      }
    });

    this.selectedHabits.forEach((currentlySelectedHabit) => {
      // find raters that have been added
      const previouslySelectedHabit = this.managedMember.habits.find(
        (h) => h.id === currentlySelectedHabit.id
      );
      if (previouslySelectedHabit) {
        // if this habit already existed, compare raters before and after
        if (!result[previouslySelectedHabit.id]) {
          // empty object needs to be initialized
          result[previouslySelectedHabit.id] = {
            name: previouslySelectedHabit.name
          };
        }
        currentlySelectedHabit.raters.forEach((rater) => {
          if (!previouslySelectedHabit.raters.find((r) => r.id === rater.id)) {
            // if selected rater is not found in previouslySelectedHabit.raters
            // const previouslySelectedRater =
            //   this.users.find((u) => u.id === rater.id) ||

            //   this.externalUsers.find((u) => u.id === rater.id); // find from master users list
            const raters = result[currentlySelectedHabit.id].raters || {
              add: [],
              remove: []
            };
            raters.add = [
              ...raters.add,
              {
                id: rater.id,
                name: rater.name,
                type: rater.type
              }
            ];
            result[currentlySelectedHabit.id] = Object.assign(
              {},
              result[currentlySelectedHabit.id],
              { raters }
            );
          }
        });
      } else {
        const currentRaters = currentlySelectedHabit.raters;

        // if habit was not previously selected, mark all raters as 'add'
        // if (currentlySelectedHabit.type === 2) {
        //   currentRaters = currentlySelectedHabit.raters
        // } else {
        //   currentRaters = currentlySelectedHabit.raters.map((raterId) => {
        //     const rater = this.users.find((u) => u.id === raterId);
        //     return { id: rater.id, name: rater.name };
        //   });
        // }

        const raters = result[currentlySelectedHabit.id].raters || {
          add: [],
          remove: []
        };
        raters.add = raters.add.concat(currentRaters);

        result[currentlySelectedHabit.id] = Object.assign(
          {},
          result[currentlySelectedHabit.id],
          {
            raters
          }
        );
      }
    });

    // Object.keys(result).filter(habitId => { // find rater proposals related to habits that DON'T have habit proposals
    //   return ( // filter out habits that have habit level proposals (they've already been dealt with)
    //     this.managedMember.proposals.find(p => p.habit_id === habitId) &&
    //     !this.managedMember.proposals.find(p => p.habit_id === habitId && !p.rater_id)
    //   )
    // }).forEach(habitId => { // build a new raters object from just the remaining proposals
    //   const proposedRaterChanges = this.managedMember.proposals.filter(p => p.habit_id === habitId).reduce((object, proposal) => {
    //     if (proposal.change_type === "add") {
    //       object.add.push({ id: proposal.rater_id, name: proposal.rater });
    //     } else {
    //       object.remove.push({ id: proposal.rater_id, name: proposal.rater });
    //     }
    //     return object;
    //   }, { add: [], remove: [] });
    //   if (result[habitId].raters) { // combine with exiting raters object
    //     result[habitId].raters.add = result[habitId].raters.add.concat(proposedRaterChanges.add);
    //     result[habitId].raters.remove = result[habitId].raters.remove.concat(proposedRaterChanges.remove);
    //   } else { // or set a new raters object
    //     result[habitId].raters = proposedRaterChanges;
    //   }
    // });

    // const proposalChanges = this.changesWithProposals;
    //

    return result;
  }
  /*eslint-disable */
  @computed get changesWithProposals() {
    const proposalChanges = this.managedMember.proposals.reduce(
      (result, proposal) => {
        if (result[proposal.habit_id]) {
          if (!proposal.rater_id) {
            result[proposal.habit_id] = Object.assign(
              {},
              result[proposal.habit_id],
              {
                change: proposal.change_type
              }
            );
          } else {
            const habit = result[proposal.habit_id];
            if (proposal.change_type === "add") {
              // habit.raters.add.push({
              //   id: proposal.rater_id,
              //   name: proposal.rater
              // });
            } else {
              // habit.raters.remove.push({
              //   id: proposal.rater_id,
              //   name: proposal.rater
              // });
            }
            result[proposal.habit_id] = habit;
          }
        } else {
          result[proposal.habit_id] = {
            name: proposal.habit,
            change: !proposal.rater_id && proposal.change_type,
            raters: {
              add:
                proposal.rater_id && proposal.change_type === "add"
                  ? [{ id: proposal.rater_id, name: proposal.rater }]
                  : [],
              remove:
                proposal.rater_id && proposal.change_type === "remove"
                  ? [{ id: proposal.rater_id, name: proposal.rater }]
                  : []
            }
          };
        }
        return result;
      },
      {}
    );
    /* eslint-enable */
    const { changes } = this;
    // Object.keys(proposalChanges).forEach((habitId) => {
    //   if (changes[habitId]) {
    //     changes[habitId] = Object.assign({}, changes[habitId], {
    //       change: proposalChanges[habitId].change || changes[habitId].change,
    //       raters: {
    //         add: changes[habitId].raters
    //           ? changes[habitId].raters.add.concat(
    //               proposalChanges[habitId].raters.add
    //             )
    //           : proposalChanges[habitId].raters.add,
    //         remove: changes[habitId].raters
    //           ? changes[habitId].raters.remove.concat(
    //               proposalChanges[habitId].raters.remove
    //             )
    //           : proposalChanges[habitId].raters.remove
    //       }
    //     });
    //   } else {
    //     changes[habitId] = proposalChanges[habitId];
    //   }
    // });
    return changes;
  }

  @computed get selectedAndProposedHabits() {
    const proposedHabits = this.managedMember.proposals
      .filter((p) => !p.rater_id)
      .map((p) => {
        const habit = this.habits.find((h) => h.id === p.habit_id);
        return Object.assign(
          {},
          {
            id: habit.id,
            name: habit.name,
            type: habit.type,
            employee_type: habit.employee_type,
            behaviors: habit.behaviors
          },
          {
            proposal: p,
            raters: this.managedMember.proposals
              .filter((pp) => p.habit_id === pp.habit_id && pp.rater_id)
              .map((pp) => pp.rater_id)
              .concat(
                this.selectedHabits.find((h) => h.id === habit.id)
                  ? this.selectedHabits.find((h) => h.id === habit.id).raters
                  : []
              )
          }
        );
      });
    return this.selectedHabits
      .filter((h) => !proposedHabits.find((hh) => hh.id === h.id))
      .concat(proposedHabits)
      .sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  firstStepFooter = () => {
    const {
      asUser,
      onboarding,
      onClose,
      managedId,
      organizationStore,
      profileStore
    } = this.props;
    if (asUser) {
      return [
        <Button
          key="cancel"
          onClick={() => {
            if (!onboarding) {
              onClose(null);
            }
          }}
        >
          Cancel
        </Button>,
        <Button key="next" type="primary" onClick={this.handleNext}>
          Next
        </Button>
      ];
    }
    return [
      <Button
        key="role"
        style={{ float: "left" }}
        onClick={() =>
          Modal.confirm({
            title: "Name of Role",
            content: (
              <Input onChange={(e) => (this.newRoleName = e.target.value)} />
            ),
            onOk: () =>
              new Promise((resolve) => {
                let apiPromise;
                if (managedId) {
                  apiPromise = this.api;
                } else {
                  apiPromise = Admin;
                }
                apiPromise
                  .createRole({
                    name: this.newRoleName,
                    habits: this.selectedHabits
                  })
                  .then((res) => {
                    this.roles = [...this.roles, res.data];
                    organizationStore.addRole(res.data);
                    profileStore.addRole(res.data);
                    this.newRoleName = "";
                    this.selectRole(res.data.id);

                    resolve();
                  });
              }),
            onCancel: () => (this.newRoleName = "")
          })
        }
      >
        Save Habits As New Role
      </Button>,
      <Button key="cancel" onClick={() => onClose(null)}>
        Cancel
      </Button>,
      <Button key="next" type="primary" onClick={this.handleNext}>
        Next
      </Button>
    ];
  };

  footer = () => {
    const { asUser, onClose } = this.props;
    let stepFooter;
    if (this.step === 0) {
      stepFooter = this.step;
    } else if (asUser) {
      stepFooter = this.step + 1;
    } else {
      stepFooter = this.step;
    }
    switch (stepFooter) {
      case 0:
        return this.firstStepFooter();
      case 1:
        return [
          <Button key="back" onClick={this.handleBack}>
            Back
          </Button>,
          <Button
            key="submit"
            type="primary"
            id="submit"
            loading={this.loading}
            onClick={this.handleSubmit}
          >
            Submit
          </Button>
        ];

      case 2:
        return [
          <Button
            key="next"
            type="primary"
            loading={this.loading}
            onClick={() => onClose(this.updatedUser)}
          >
            Close
          </Button>
        ];

      default:
        return [
          <Button
            key="next"
            type="primary"
            loading={this.loading}
            onClick={() => onClose(this.updatedUser)}
          >
            Close
          </Button>
        ];
    }
  };

  render() {
    const hasExternal = this.selectedHabits.some((h) => h.type === 2);
    const { asUser, user, onboarding, onClose } = this.props;

    return (
      <Modal
        visible
        maskClosable={false}
        id="network-setup-modal"
        className="expandable"
        title={asUser ? "Your Network" : `Choose habits for ${user.name}`}
        footer={this.footer(hasExternal)}
        width={this.step === 0 || window.innnerWidth < 500 ? "95vw" : "500px"}
        onCancel={() => {
          if (!onboarding) {
            onClose(null);
          }
        }}
        centered
      >
        {this.loading ? (
          <Skeleton active />
        ) : (
          <React.Fragment>
            <Steps current={this.step}>
              <Step title="Habits" />
              <Step title="Review" />
            </Steps>

            {this.step === 0 && (
              <Habits
                habits={this.habits}
                onSelect={this.handleSelectHabit}
                selectedHabits={this.selectedHabits}
                requiredHabits={this.requiredHabits}
                suggestedHabits={this.suggestedHabits}
                roles={this.roles}
                asUser={asUser}
                users={this.users}
                roleId={this.managedMember.role_id}
                selectRole={this.selectRole}
                selectUser={this.selectUser}
                proposals={this.managedMember.proposals.filter(
                  (p) => !p.rater_id
                )}
              />
            )}

            {this.step === 1 && (
              <Review
                selectedHabits={this.selectedAndProposedHabits.sort((a, b) =>
                  a.name < b.name ? -1 : 1
                )}
                changes={this.changesWithProposals}
              />
            )}
            {this.step === 2 && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "24px 0px 12px",
                  fontSize: "x-large"
                }}
                id="success"
              >
                <Icon
                  type="check-circle"
                  theme="filled"
                  style={{ fontSize: 64, color: "#40cc75" }}
                />
                Success!
              </div>
            )}
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

export default NetworkSetup;
