import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import PropTypes from "prop-types";
import ColorPicker from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import styled from "styled-components";

const ColorSquare = styled.div`
  height: 30px;
  width: 30px;
  margin-right: 6px;
  border-radius: 4px;
  color: ${(props) => props.color};
`;

const validateHex = (rule, value, callback) => {
  const re = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  if (value && !re.test(value)) {
    callback([new Error("Invalid email.")]);
  } else {
    callback();
  }
};

const LabelForm = ({ form, onSubmit, onCancel, buttonLoading, editing }) => {
  const { getFieldDecorator } = form;
  const [color, setColor] = useState(editing ? editing.color : "#5319e7");

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values);
        form.resetFields();
      }
    });
  };

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };
  return (
    <Form
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Form.Item label="Label Name">
          {getFieldDecorator("text", {
            initialValue: editing ? editing.text : "",
            rules: [{ required: true }]
          })(
            <Input
              style={{ width: 300, marginRight: 24 }}
              placeholder="Enter label name"
            />
          )}
        </Form.Item>
        <Form.Item label="Color">
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              paddingLeft: 6
            }}
          >
            <div style={{ lineHeight: "13px" }}>
              <ColorPicker
                animation="slide-up"
                onChange={(params) => {
                  form.setFieldsValue({ color: params.color });
                  setColor(params.color);
                }}
                color={color}
              >
                <ColorSquare />
              </ColorPicker>
            </div>

            {getFieldDecorator("color", {
              initialValue: color,
              rules: [
                { required: true },
                { validator: validateHex, message: "Must be a valid hex code" }
              ]
            })(<Input onChange={(e) => setColor(e.target.value)} />)}
          </div>
        </Form.Item>
      </div>
      <div style={{ display: "flex", alignItems: "center", paddingTop: 10 }}>
        <Button onClick={handleCancel} style={{ marginRight: 24 }}>
          Cancel
        </Button>
        <Button type="primary" loading={buttonLoading} onClick={handleSubmit}>
          {editing ? "Save" : "Create"}
        </Button>
      </div>
    </Form>
  );
};

LabelForm.defaultProps = {
  editing: false
};

LabelForm.propTypes = {
  form: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  editing: PropTypes.object,
  buttonLoading: PropTypes.bool.isRequired
};

export default Form.create()(LabelForm);
