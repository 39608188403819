/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "antd";
import HorizontalBar from "components/HorizontalBar";
import nameToInitials from "utils/nameToInitials";
import {
  Wrapper,
  LeftSide,
  UserInfo,
  Name,
  Description,
  RightSide,
  RightItem,
  ItemHeader,
  ItemValue
} from "./styles";

const TeamHeader = ({ team, response_rates, habit_dist, goToUser }) => (
  <Wrapper>
    <LeftSide>
      <Avatar
        size="large"
        style={{ marginRight: 20 }}
        src={team.manager.avatar}
      >
        {nameToInitials(team.manager.name)}
      </Avatar>
      <UserInfo>
        <Name>{team.name}</Name>
        <Description>
          <span
            onClick={() => goToUser(team.manager.id)}
            className="colored-tag"
            style={{ marginRight: 5, cursor: "pointer" }}
          >
            Manager: {team.manager.name}, {team.manager.title}
          </span>
        </Description>
      </UserInfo>
    </LeftSide>
    <RightSide>
      <RightItem
        style={{ borderRight: "1px solid #e8e8e8", paddingRight: "20px" }}
      >
        <ItemHeader>Habit Performance</ItemHeader>
        <HorizontalBar
          axisBottom={null}
          axisTop={null}
          axisLeft={null}
          axisRight={null}
          data={habit_dist}
        />
      </RightItem>
      <RightItem
        style={{ borderRight: "1px solid #e8e8e8", paddingRight: "20px" }}
      >
        <ItemHeader>Response Rate</ItemHeader>
        <HorizontalBar data={response_rates} />
      </RightItem>
      <RightItem
        style={{ borderRight: "1px solid #e8e8e8", paddingRight: "20px" }}
      >
        <ItemHeader>Members</ItemHeader>
        <ItemValue>{team.members.length}</ItemValue>
      </RightItem>
    </RightSide>
  </Wrapper>
);

TeamHeader.propTypes = {
  team: PropTypes.object.isRequired,
  response_rates: PropTypes.object.isRequired,
  habit_dist: PropTypes.object.isRequired,
  goToUser: PropTypes.func.isRequired
};

export default TeamHeader;
