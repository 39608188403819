import styled from "styled-components";

export const MoreOptions = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const OptionsContainer = styled.div`
  width: 49%;
  padding: 24px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
`;

export const NameWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: 24px 0px;
`;

export const OptionsLabel = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;