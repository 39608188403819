/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo } from "react";
import Box from "layout/Box";
import HabitPie from "./HabitPie";
import { IFetchManagerDashboardQuery } from "../fetchManagerDashboard.generated";
import { useFetchHabitDeltasQuery } from "./fetchHabitDeltas.generated";

type PieDatum = {
  id: string;
  label: string;
  value: number;
  color: string;
};

type HabitData = {
  name: string;
  data: PieDatum[];
};

type HabitDelta = {
  name: string;
  currentscore: number;
  pastscore: number;
};

type HabitAccumulator = {
  below65: number;
  below80: number;
  below100: number;
};

const colors: { [key: string]: string } = {
  below65: "#F4A085",
  below80: "#9BD199",
  below100: "#68cd67"
};

const labels: { [key: string]: string } = {
  below65: "# of users with scores below 65%",
  below80: "# of users with scores between 65% and 79%",
  below100: "# of users with scores between 80% and 100%"
};

// eslint-disable-next-line @typescript-eslint/ban-types

type PieData = HabitData[];

const HabitPerformance: React.FC<{
  users: IFetchManagerDashboardQuery["team"]["users"];
}> = ({ users }) => {
  const computeHabits = (
    usersWithHabits: IFetchManagerDashboardQuery["team"]["users"]
  ): PieData => {
    const temp: { [key: string]: HabitAccumulator } = {};
    usersWithHabits.forEach((user) => {
      user.habitUsers.forEach((habitUser) => {
        if (!temp[habitUser.habit.name]) {
          temp[habitUser.habit.name] = {
            below65: 0,
            below80: 0,
            below100: 0
          };
        }
        if (habitUser.currentScore) {
          if (habitUser.currentScore.value < 65) {
            temp[habitUser.habit.name].below65 += 1;
          } else if (habitUser.currentScore.value < 80) {
            temp[habitUser.habit.name].below80 += 1;
          } else {
            temp[habitUser.habit.name].below100 += 1;
          }
        }
      });
    });

    const data: PieData = [];

    Object.keys(temp).forEach((key) => {
      const datum: { name: string; data: PieDatum[] } = {
        name: key,
        data: []
      };

      Object.keys(temp[key]).forEach((scoreKey) => {
        datum.data.push({
          id: scoreKey,
          label: labels[scoreKey],
          value: (temp[key] as any)[scoreKey],
          color: colors[scoreKey]
        });
      });
      data.push(datum);
    });
    return data;
  };

  const habits: PieData = useMemo(() => computeHabits(users), [users]);
  const { loading, data, error } = useFetchHabitDeltasQuery();
  if (error) {
    return <span>Something went wrong!</span>;
  }

  if (!data || loading) {
    return <div>Loading...</div>;
  }

  const largestDelta = data.managerDashboard.habitDeltas.reduce(
    (largest: HabitDelta, curr: HabitDelta) => {
      if (!largest) {
        return curr;
      }
      if (
        curr.currentscore > curr.pastscore &&
        curr.currentscore - curr.pastscore >
          largest.currentscore - largest.pastscore
      ) {
        return curr;
      }
      return largest;
    },
    {
      name: "",
      currentscore: 0,
      pastscore: 0
    }
  );
  const smallestDelta = data.managerDashboard.habitDeltas.reduce(
    (smallest: HabitDelta, curr: HabitDelta) => {
      if (!smallest) {
        return curr;
      }
      if (
        curr.currentscore < curr.pastscore &&
        curr.pastscore - curr.currentscore >
          smallest.pastscore - smallest.currentscore
      ) {
        return curr;
      }
      return smallest;
    },
    {
      name: "",
      currentscore: 0,
      pastscore: 0
    }
  );

  const lowest = habits
    .filter(
      (h) =>
        h.data.find((d) => d.id === "below65") &&
        h.data.find((d) => d.id === "below65")!.value > 0
    )
    .sort((a, b) =>
      a.data.find((d) => d.id === "below65")!.value >
      b.data.find((d) => d.id === "below65")!.value
        ? -1
        : 1
    )[0];

  const highest = habits
    .filter(
      (h) =>
        h.data.find((d) => d.id === "below100") &&
        h.data.find((d) => d.id === "below100")!.value > 0
    )
    .sort((a, b) =>
      a.data.find((d) => d.id === "below100")!.value >
      b.data.find((d) => d.id === "below100")!.value
        ? -1
        : 1
    )[0];

  const highestHabit = highest && highest.data.find((d) => d.id === "below100");
  const lowestHabit = lowest && lowest.data.find((d) => d.id === "below65");

  return (
    <Box
      width="100%"
      id="habit-performance"
      display="flex"
      flexWrap="wrap"
      padding="24px"
      justifyContent="center"
      margin="0 auto"
      backgroundColor="white"
      borderRadius="6px"
      overflow="hidden"
      boxShadow="rgba(0,0,0,0.06) 0px 2px 4px 0px"
      border="1px solid #e8e8e8"
    >
      {habits.map((h) => (
        <HabitPie key={h.name} habit={h} />
      ))}
      <Box
        display="flex"
        flexDirection="column"
        textAlign="start"
        width="100%"
        mb="24px"
        border="1px solid #e8e8e8"
        borderRadius="6px"
        backgroundColor="#e8e8e8"
        padding="12px 24px"
      >
        <Box fontSize="18px" color="rgba(0, 0, 0, 0.8)" mb="12px">
          What your team&apos;s habit performance means:
        </Box>
        <Box display="flex" flexDirection="column" fontSize="16px">
          {highestHabit && (
            <Box display="flex" alignItems="center" mb="12px">
              <span
                role="img"
                aria-label="celebration"
                style={{ fontSize: 36, color: "rgba(0, 0, 0, 1)" }}
              >
                🎉
              </span>
              <div style={{ marginLeft: 12, color: "rgba(0, 0, 0, 0.8)" }}>
                Your team&apos;s top habit is {highest.name}, with{" "}
                {highestHabit.value} users having a score above 80%.
              </div>
            </Box>
          )}
          {lowestHabit && (
            <Box display="flex" alignItems="center" mb="12px">
              <span
                role="img"
                aria-label="worry"
                style={{ fontSize: 36, color: "rgba(0, 0, 0, 1)" }}
              >
                😨
              </span>
              <div style={{ marginLeft: 12, color: "rgba(0, 0, 0, 0.8)" }}>
                Your team is struggling with {lowest.name}, with{" "}
                {lowestHabit.value} users having a score below 65%.
              </div>
            </Box>
          )}
          {smallestDelta && largestDelta.name && (
            <Box display="flex" alignItems="center" mb="12px">
              <span
                role="img"
                aria-label="thumbs-down"
                style={{ fontSize: 36, color: "rgba(0, 0, 0, 1)" }}
              >
                👎
              </span>
              <div style={{ marginLeft: 12, color: "rgba(0, 0, 0, 0.8)" }}>
                Your team is receiving more negative feedback for{" "}
                {smallestDelta.name} than usual (last 90 days).
              </div>
            </Box>
          )}
          {largestDelta && largestDelta.name && (
            <Box display="flex" alignItems="center">
              <span
                role="img"
                aria-label="thumbs-up"
                style={{ fontSize: 36, color: "rgba(0, 0, 0, 1)" }}
              >
                👍
              </span>
              <div style={{ marginLeft: 12, color: "rgba(0, 0, 0, 0.8)" }}>
                Your team is receiving more positive feedback for{" "}
                {largestDelta.name} than usual (last 90 days).
              </div>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default HabitPerformance;
