import React from "react";
import { Avatar } from "antd";
import nameToInitials from "utils/nameToInitials";
import PropTypes from "prop-types";
import HabitTrend from "containers/ManagerDashboardNext/ManagedUsers/HabitTrend";
import {
  Wrapper,
  LeftSide,
  UserInfo,
  Name,
  Description,
  RightSide,
  RightItem,
  ItemHeader,
  ItemValue,
  Descriptor
} from "./styles";

const UserHeader = ({
  user,
  adminView,
  goToTeam,
  goToUser,
  currentUserManagerOnlyId,
  currentUserManagerOnlyManagedTeamId
}) => {
  let health;
  if (adminView) {
    if (user.network_health.errors.severe > 0) {
      health = <ItemValue style={{ color: "red" }}>Poor</ItemValue>;
    } else if (user.network_health.errors.warning > 0) {
      health = <ItemValue style={{ color: "orange" }}>Average</ItemValue>;
    } else {
      health = <ItemValue style={{ color: "#40cc75" }}>Good</ItemValue>;
    }
  }

  return (
    <Wrapper>
      <LeftSide>
        <Avatar size="large" style={{ marginRight: 20 }} src={user.avatar}>
          {nameToInitials(user.name)}
        </Avatar>
        <UserInfo>
          <Name>{user.name}</Name>
          <Description>
            <Descriptor noLink>{user.title}</Descriptor>

            {user.team_id &&
              currentUserManagerOnlyManagedTeamId !== user.team_id && (
                <Descriptor onClick={() => adminView && goToTeam(user.team_id)}>
                  {user.team_name}
                </Descriptor>
              )}
          </Description>
          <Description>
            {user.manager_id &&
              currentUserManagerOnlyId !== user.manager_id && (
                <Descriptor
                  onClick={() => adminView && goToUser(user.manager_id)}
                >
                  Reports To: {user.manager_name}
                </Descriptor>
              )}

            {user.group && (
              <Descriptor onClick={() => goToTeam(user.group.id)}>
                Manages Team: {user.group.name}
              </Descriptor>
            )}
          </Description>
        </UserInfo>
      </LeftSide>
      <RightSide>
        <RightItem
          style={{ borderRight: "1px solid #e8e8e8", paddingRight: "20px" }}
        >
          <ItemHeader>Feedback Streak</ItemHeader>
          <ItemValue>{user.feedbackStreak}</ItemValue>
        </RightItem>
        <RightItem
          style={{ borderRight: "1px solid #e8e8e8", paddingRight: "20px" }}
        >
          <ItemHeader>Response Rate</ItemHeader>
          <ItemValue>
            {user.responseRate ? Math.trunc(user.responseRate * 100) : "--"}%
          </ItemValue>
        </RightItem>
        <RightItem
          style={{ borderRight: "1px solid #e8e8e8", paddingRight: "20px" }}
        >
          <ItemHeader>Trend</ItemHeader>
          <ItemValue>
            <HabitTrend habitUsers={user.habitUsers} small />
          </ItemValue>
        </RightItem>
        {/* <RightItem>
          <ItemHeader>High Fives</ItemHeader>
          <div style={{ display: 'flex' }}>
            <ItemValue style={{ marginRight: 5 }}>
              {user.highfives.sent} Sent,{' '}
            </ItemValue>
            <ItemValue>{user.highfives.received} Received</ItemValue>
          </div>
        </RightItem> */}
      </RightSide>
    </Wrapper>
  );
};

UserHeader.defaultProps = {
  adminView: false
};

UserHeader.propTypes = {
  user: PropTypes.object.isRequired,
  goToTeam: PropTypes.func.isRequired,
  adminView: PropTypes.bool,
  goToUser: PropTypes.func.isRequired,
  currentUserManagerOnlyId: PropTypes.bool.isRequired,
  currentUserManagerOnlyManagedTeamId: PropTypes.bool.isRequired
};

export default UserHeader;
