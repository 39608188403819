/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import moment from "moment";
import { Card, Button } from "antd";
import PropTypes from "prop-types";
import { EngagementContainer, Tooltip, Behavior, DateRow } from "./styles";

const getValue = (value) => {
  if (value === undefined) {
    return null;
  }
  if (value > 0) {
    return value;
  }
  return "--";
};

const getColor = (d) => {
  if (d < 30) {
    return "#ff7f01";
  }
  if (d < 60) {
    return "#ff7f01";
  }
  if (d < 70) {
    return "#fdc53f";
  }
  if (d < 80) {
    return "#5ecb53";
  }
  if (d < 90) {
    return "#00a74f";
  }
  return "#019247";
};
// const getColor = (d) => {
//   if(d < 30){
//     return "#ff7f01"
//   } else if (d < 60) {
//     return "#ff7f01"
//   } else if (d < 70) {
//     return "#fdc53f"
//   } else if (d < 80) {
//     return "#5ecb53"
//   } else if (d < 90) {
//     return "#00a74f"
//   } else {
//     return "#019247"
//   }
// }

const generateKey = (index) => {
  return `${index}_${new Date().getTime()}`;
};

const colors = [
  "#51acdb",
  "#015db0",
  "#5ecb53",
  "#09632f",
  "#e77d01",
  "#232b78",
  "#fdc53f",
  "#551height",
  "#a01e0d"
];
@observer
class Engagement extends React.Component {
  @observable loading = true;
  @observable hoveredHabit;
  @observable trend = [];
  @observable ticks = [];
  @observable selectedBehaviors = [];

  static propTypes = {
    engagement: PropTypes.array.isRequired,
    smaller: PropTypes.bool
  };

  static defaultProps = {
    smaller: false
  };

  constructor(props) {
    super(props);
    this.buildEngagementTrend(props.engagement);
    this.setTicks();
    this.loading = false;
  }

  componentWillReceiveProps(newProps) {
    if (newProps.engagement) {
      this.buildEngagementTrend(newProps.engagement);
      this.loading = false;
    }
  }

  HeatMapCellRect = (
    {
      data,
      value,
      x,
      y,
      width,
      height,
      opacity,
      enableLabel,
      onHover,
      onLeave,
      onClick,
      theme
    },
    colorValue,
    border
  ) => {
    debugger;
    return (
      <g
        transform={`translate(${x}, ${y})`}
        onMouseEnter={value ? onHover : null}
        onMouseMove={value ? onHover : null}
        onMouseLeave={onLeave}
        onClick={(e) => {
          onClick(data, e);
        }}
        style={{ cursor: "pointer" }}
        // style={style}
      >
        <rect
          x={width * -0.5}
          y={height * -0.5}
          width={width}
          height={height}
          fill={value > 0 ? colorValue : "#e8e8e8"}
          fillOpacity={value ? opacity : 0}
          strokeWidth={border[0] ? 8 : 1}
          stroke={border[1]}
          strokeOpacity={opacity}
        />
        {enableLabel && (
          <text
            alignmentBaseline="central"
            textAnchor="middle"
            style={{
              ...theme.labels.text,
              fill: "rgba(0, 0, 0, 0.6)"
            }}
            fillOpacity={opacity}
          >
            {getValue(value)}
          </text>
        )}
      </g>
    );
  };

  buildEngagementTrend = (engagement) => {
    this.trend = engagement.map((habit) => ({
      id: habit.habit_id,
      name: habit.habit_name,
      color: habit.color,
      data: habit.history
        .sort(
          (a, b) =>
            moment(a.created_at).valueOf() - moment(b.created_at).valueOf()
        )
        .map((datum) => ({
          x: moment(datum.created_at).format("YYYY-MM-DD"),
          y: datum.value
        }))
    }));
    this.setTicks();
  };
  buildBehaviorTrend = (behavior) => {
    const t = {
      id: behavior.id,
      name: behavior.description,
      color: colors[this.selectedBehaviors.length],
      data: Object.entries(behavior.history)
        .sort((a, b) => a[0] - b[0])
        .map((datum) => ({
          x: moment(datum[0]).format("YYYY-MM-DD"),
          y: Math.round(datum[1] * 100)
        }))
    };

    return t;
  };
  // moment(datum.created_at).format('YYYY-MM-DD'),

  buildSelectedHabitTrend = (habit) => {
    this.trend = habit.behaviors.map((behavior) => ({
      id: behavior.id,
      description: behavior.description,
      color: habit.color,
      data: Object.entries(behavior.history)
        .sort((a, b) => a[0] - b[0])
        .map((datum) => ({
          x: moment(datum[0]).format("MM/DD"),
          y: Math.round(datum[1] * 100)
        }))
    }));
    this.setTicks();
  };

  heatMap = (habit) => {
    return Object.assign(
      {},
      { habit: habit.habit_name, habitColor: habit.color },
      habit.behaviors.reduce((obj, b, idx) => {
        obj[idx.toString()] = b.score;
        return obj;
      }, {})
    );
  };

  bKeys = (habit) => {
    const array = [];

    for (let i = 0; i < habit.behaviors.length; i += 1) {
      array.push(i.toString());
    }
    return array;
  };

  setTicks = () => {
    const ticks = Object.keys(
      this.trend.reduce((count, line) => {
        line.data.forEach((datum) => {
          if (!count[datum.x]) {
            count[datum.x] = true;
          }
        });
        return count;
      }, {})
    );
    const oneFourth = Math.round(ticks.length / 4);
    // if (oneFourth >= 2) {
    this.ticks = [
      ticks[0],
      ticks[oneFourth],
      ticks[oneFourth * 2],
      ticks[oneFourth * 3],
      ticks[ticks.length - 1]
    ];
    // }
    // else {
    // this.ticks = ticks;
    // }
  };

  selectHabit = (habit) => {
    const { engagement } = this.props;
    if (this.selectedHabit === habit) {
      this.buildEngagementTrend(engagement);
    } else {
      this.selectedHabit = habit;
      this.buildSelectedHabitTrend(habit);
    }
  };

  barTooltip = (props) => (
    <Tooltip>
      {props.data.description} ({props.data.score}%)
    </Tooltip>
  );

  lineTooltip = (props) => (
    <Tooltip>
      <DateRow>{moment(props.id).format("MM/DD")}</DateRow>
      {props.data.map((b) => (
        <Behavior>
          <div
            style={{
              marginRight: 5,
              height: 10,
              width: 10,
              minHeight: 10,
              minWidth: 10,
              backgroundColor: b.serie.color
            }}
          />
          {b.serie.name || b.serie.description} ({b.data.y}%)
        </Behavior>
      ))}
    </Tooltip>
  );

  getBorderColor = (e, params) => {
    const behavior = this.selectedBehaviors.find(
      (b) => b.id === e.behaviors[parseInt(params.data.xKey)].id
    );
    if (behavior) {
      return [true, colors[this.selectedBehaviors.indexOf(behavior)]];
    }
    return [false, "rgba(0, 0, 0, 0.1)"];
  };

  getTicks = (props) => moment(props).format("MM/DD");
  selectBehavior = (habit, params) => {
    const behavior = habit.behaviors[parseInt(params.xKey)];
    const data = this.buildBehaviorTrend(behavior);

    if (this.selectedBehaviors.find((b) => b.id === behavior.id)) {
      this.selectedBehaviors = this.selectedBehaviors.filter(
        (b) => b.id !== behavior.id
      );
    } else {
      this.selectedBehaviors = [...this.selectedBehaviors, data];
    }
  };

  clearBehaviors = () => (this.selectedBehaviors = []);

  render() {
    const { smaller, engagement } = this.props;
    if (this.loading) {
      return <EngagementContainer>loading</EngagementContainer>;
    }

    const height = smaller ? 300 : 400;
    // DO NOT REMOVE
    // For some reason if you remove this the hover functionality does not work on the graph
    // eslint-disable-next-line no-unused-vars
    const { hoveredHabit } = this;
    //

    return (
      <Card
        id="engagement"
        title="Cultural Feedback"
        bodyStyle={{ paddingBottom: "24px" }}
        extra={
          <Button
            disabled={this.selectedBehaviors.length === 0}
            onClick={this.clearBehaviors}
          >
            Clear Selected
          </Button>
        }
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "space-around",
            alignItems: "center"
          }}
        >
          <div
            style={{
              width: "45%",
              height: "100%",
              paddingTop: smaller && 5
            }}
          >
            {engagement.map((e, i) => (
              <div
                key={generateKey(i)}
                onMouseEnter={() => {
                  this.hoveredHabit = e;
                }}
                onMouseLeave={() => (this.hoveredHabit = null)}
                style={{
                  width: "100%",
                  height: 40,
                  margin: smaller ? "0 0 16px 0" : "24px 0 36px 12px"
                }}
              >
                <div
                  style={{
                    boxSizing: "unset",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  {e.habit_name}
                  <div
                    style={{
                      marginLeft: 5,
                      height: 10,
                      width: 10,
                      backgroundColor: e.color
                    }}
                  />
                </div>
                <ResponsiveHeatMap
                  axisBottom={null}
                  axisTop={null}
                  axisLeft={null}
                  axisRight={null}
                  data={[this.heatMap(e)]}
                  padding={8}
                  indexBy={e.habit_name}
                  keys={this.bKeys(e)}
                  onClick={(params) => this.selectBehavior(e, params)}
                  cellShape={(params) =>
                    this.HeatMapCellRect(
                      params,
                      getColor(params.value),
                      this.getBorderColor(e, params)
                    )
                  }
                  // eslint-disable-next-line consistent-return
                  tooltip={(t) => {
                    if (t.value) {
                      return `${e.behaviors[parseInt(t.xKey)].description} (${e.habit_name})`;
                    }
                  }}
                />
              </div>
            ))}
          </div>

          <div
            style={{
              width: "45%",
              height: height - 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto 0"
            }}
          >
            {this.selectedBehaviors.length > 0 ? (
              <ResponsiveLine
                pointSize={0}
                data={this.selectedBehaviors}
                margin={{ bottom: 25, left: 45, top: 5 }}
                colors={(props) =>
                  colors[
                    this.selectedBehaviors.indexOf(
                      this.selectedBehaviors.find((b) => b.id === props.id)
                    )
                  ]
                }
                curve="monotoneX"
                enableDots={false}
                // enableArea
                // gridXValues={this.ticks.toJS()}
                tooltip={this.lineTooltip}
                enableGridX={false}
                // enableGridY={false}
                xScale={{ type: "time", format: "%Y-%m-%d", precision: "day" }}
                yScale={{ type: "linear", stacked: false, min: 0, max: 100 }}
                // axisBottom={{tickSize: 0, format:this.getTicks}}
                axisBottom={{ format: "%b %d", tickValues: 5 }}
                axisLeft={{
                  legend: "score",
                  legendPosition: "middle",
                  legendOffset: -30
                }}
              />
            ) : (
              <ResponsiveLine
                data={this.trend}
                pointSize={0}
                margin={{ bottom: 25, left: 45, top: 5 }}
                colors={(props) =>
                  this.hoveredHabit
                    ? props.color === this.hoveredHabit.color
                      ? props.color
                      : "#e8e8e8"
                    : props.color
                }
                curve="monotoneX"
                enableDots={false}
                // gridXValues={this.ticks.toJS()}
                tooltip={this.lineTooltip}
                enableGridX={false}
                // enableGridY={false}
                xScale={{ type: "time", format: "%Y-%m-%d", precision: "day" }}
                yScale={{ type: "linear", stacked: false, min: 0, max: 100 }}
                // axisBottom={{tickSize: 0, format:this.getTicks}}
                axisBottom={{
                  format: "%b %d",
                  tickValues: window.innerWidth < 500 ? 2 : 4
                }}
                axisLeft={{
                  legend: "score",
                  legendPosition: "middle",
                  legendOffset: -30
                }}
              />
            )}
          </div>
        </div>
      </Card>
    );
  }
}

export default Engagement;
