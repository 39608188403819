/* eslint-disable no-plusplus */

/* eslint-disable no-unused-vars */

/* eslint-disable react/no-danger */

/* eslint-disable no-debugger */
import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { ResponsiveRadar } from "@nivo/radar";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import CustomLegend from "./CustomLegend";
import { alphabet } from "../alphabet";
import { setEventListeners, setTooltipListeners } from "./eventListeners";
import { useTranslation } from "react-i18next";
import media from "utils/style-utils";

const laptop = window.innerWidth < 1300;
const desktop = window.innerWidth > 1300;
const mobile = window.innerWidth < 500;

const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

const Container = styled.div`
  svg {
    filter: blur(3px);
  }
`;
const LockedMessage = styled.div`
  width: 300px;
  background: white;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  position: absolute;
  left: calc(50% - 150px);
  top: 40%;
	text-align:center
  background-color: white;
  z-index: 1000;
  padding: 24px;

	${media.laptop`
		width: 150px;
  left: calc(50% - 75px);
	padding: 12px;
	top: 33%

	`}
`;

const RadarChart = ({
  behaviors,
  colorDot,
  shadeColor,
  theme,
  getDot,
  locked,
  behaviorsNeeded
}) => {
  const { t } = useTranslation();
  const eventState = useState(false);
  const currentState = useState(0);
  const [currentDotCount, setCurrentDotCount] = currentState;
  const lastDotCount = usePrevious(currentDotCount);
  const [data, setData] = useState(null);
  const [drawn, setDrawn] = useState(false);
  const [tooltipMap, setToolTipMap] = useState({});
  const {
    habitDeepDive: {
      radarChart: { borderColor, dotBorderColor, dotTextColor, lineStroke }
    }
  } = theme;

  const setBehaviorData = (bh) => {
    // eslint-disable-next-line no-param-reassign
    bh = bh.filter((b) => typeof b.score === "number");
    const map = {};
    const bhs = bh.map((b, i) => {
      map[alphabet[i]] = { desc: b.description, color: colorDot(b.score) };
      return {
        behavior: b.code,
        score: b.score,
        place: alphabet[i]
      };
    });
    setToolTipMap(map);
    setData(bhs);
  };

  const sizing = () => {
    if (mobile) {
      return { top: 70, height: 400, right: 80, left: 80 };
    }
    if (laptop) {
      return { top: 70, height: 365, right: 60, left: 60 };
    }
    if (desktop) {
      return { top: 100, height: 400, right: 60, left: 60 };
    }
    return { top: 70, height: 365, right: 60, left: 60 };
  };

  const colorChart = () => {
    const elements = document.querySelector(
      "#radarChart > div > div > svg > g > g > g > g "
    );

    if (!elements) {
      setDrawn(false);
    }
  };

  useEffect(() => {
    setBehaviorData(behaviors);
    colorChart();
  }, [behaviors, drawn]);

  useLayoutEffect(() => {
    if (!locked) {
      setEventListeners(eventState, currentState, lastDotCount, getDot);
    }
  });

  useLayoutEffect(() => {
    setTooltipListeners(tooltipMap);
  }, [tooltipMap]);

  if (locked) {
    return (
      <Container
        id="radarChart"
        style={{
          width: mobile ? "100%" : "50%",
          height: sizing().height,
          position: "relative"
        }}
      >
        <LockedMessage>
          {t("deep_dive.habit_overview.radar_chart_locked")}
        </LockedMessage>
        <CustomLegend />
        <ResponsiveRadar
          gridLabel={() => <svg></svg>}
          theme={{
            dots: {
              text: {
                fill: dotTextColor,
                fontSize: 12,
                fontFamily:
                  "-apple - system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans- serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'"
              }
            },
            grid: {
              line: {
                stroke: lineStroke,
                strokeWidth: 1
              }
            }
          }}
          enableDots
          data={[]}
          dotSize={25}
          dotColor={(e) => {
            return colorDot(e);
          }}
          dotBorderWidth={2}
          dotBorderColor={dotBorderColor}
          enableDotLabel
          dotLabelYOffset={5}
          indexBy="place"
          maxValue={100}
          margin={{
            top: sizing().top,
            right: sizing().right,
            bottom: 40,
            left: sizing().left
          }}
          curve="catmullRomClosed"
          borderWidth={2}
          gridLevels={4}
          dotLabel="index"
          gridShape="circular"
          keys={["score"]}
          fillOpacity={0.8}
          colors={() => {
            if (!drawn) {
              setDrawn(true);
            }
            return shadeColor();
          }}
          borderColor={borderColor}
          tooltipFormat={(v) => `${v}%`}
        />
      </Container>
    );
  }

  return (
    <div
      id="radarChart"
      style={{
        width: mobile ? "100%" : "50%",
        height: sizing().height,
        position: "relative"
      }}
    >
      <CustomLegend />

      <ResponsiveRadar
        gridLabel={() => <svg></svg>}
        theme={{
          dots: {
            text: {
              fill: dotTextColor,
              fontSize: 12,
              fontFamily:
                "-apple - system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans- serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'"
            }
          },
          grid: {
            line: {
              stroke: lineStroke,
              strokeWidth: 1
            }
          }
        }}
        enableDots
        data={locked ? [] : data}
        dotSize={25}
        dotColor={(e) => {
          return colorDot(e);
        }}
        dotBorderWidth={2}
        dotBorderColor={dotBorderColor}
        enableDotLabel
        dotLabelYOffset={5}
        indexBy="place"
        maxValue={100}
        margin={{
          top: sizing().top,
          right: sizing().right,
          bottom: 40,
          left: sizing().left
        }}
        curve="catmullRomClosed"
        borderWidth={2}
        gridLevels={4}
        dotLabel="index"
        gridShape="circular"
        keys={["score"]}
        fillOpacity={0.8}
        colors={() => {
          if (!drawn) {
            setDrawn(true);
          }
          return shadeColor();
        }}
        borderColor={borderColor}
        tooltipFormat={(v) => `${v}%`}
      />
    </div>
  );
};

RadarChart.propTypes = {
  behaviors: PropTypes.array.isRequired,
  colorDot: PropTypes.func.isRequired,
  shadeColor: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  getDot: PropTypes.func.isRequired
};

export default withTheme(RadarChart);
