/* eslint-disable */
import React from "react";
import { motion } from "framer-motion";
const PreDot = ({ i, isOpen, angle, color }) => {
  // const colors = ['#FF008C', '#D309E1', '#9C1AFF', '#7700FF', '#4400FF'];
  const style = {
    display: "block",
    // position: 'absolute',
    width: 10,
    height: 10,
    borderRadius: "50%",
    background: color
    // boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'
  };

  const wrapperStyle = {
    position: "absolute",
    width: "calc(100% + 12px)",
    height: "calc(100% + 12px)",
    left: -6,
    top: -6,
    transform: `rotate(${angle}deg)`
  };

  const innerStyle = {
    position: "relative",
    width: "100%",
    height: "100%"
  };

  const variants = {
    close: {
      x: "144%",
      y: "144%"
      // rotate: `-${angle}deg`
    },
    open: {
      x: "-40%",
      y: "-40%",
      // rotate: `-${angle}deg`,
      transition: {
        delay: 0.1,
        type: "spring",
        velocity: 3000,
        // mass: 0.2,
        damping: 8
        // stiffness: 200
      }
    }
  };

  return (
    <div style={wrapperStyle}>
      <div style={innerStyle}>
        <motion.div
          initial="close"
          variants={variants}
          style={{
            ...style,
            transform: `rotate(-${angle}deg) !important`
          }}
          animate={isOpen ? "close" : "open"}
        />
      </div>
    </div>
  );
};

export default PreDot;
