import React, { useState } from "react";
import { Tooltip, Icon, Button } from "antd";
import Box from "layout/Box";
import styled from "styled-components";
import capitalize from "utils/capitalize";
import { NotMobile } from "containers/Responsive";
import { IFetchUserHabitsQuery } from "./queries/fetchUserHabits.generated";
import { CATEGORIES } from "./constants";
import CategoryIcon from "./CategoryIcon";
import { Changes } from ".";
import { useTranslation } from "react-i18next";

interface Props {
  selectedHabits: IFetchUserHabitsQuery["profile"]["habits"];
  removeHabit: (id: string) => void;
  hasChanges: boolean;
  saveChanges: () => void;
  changes: Changes;
}

const determineColor = (count: number): string => {
  if (count < 3 || count > 10) {
    return "red";
  }
  if (count < 5 || count > 8) {
    return "orange";
  }
  return "rgba(0, 0, 0, 0.85)";
};

const HabitCount = styled.div`
  color: ${(props) => determineColor(props.count)};
  font-size: 36px;
  font-weight: 500;
  margin-right: 8px;
`;

const HabitTag = styled(Box)``;

const TrashIcon = styled.div`
  height: 20px;
  width: 20px;
  transition: 0.2s ease-in-out;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -12px;
  background: white;
  margin-left: 12px;
  top: 50%;
  color: rgba(0, 0, 0, 0.85);
  overflow: hidden;
`;

const CurrentlySelectedHabits: React.FC<Props> = ({
  selectedHabits,
  removeHabit,
  hasChanges,
  saveChanges,
  changes
}) => {
  const [hovering, setHovering] = useState<null | string>(null);
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      background="#e8e8e8"
      borderRadius="6px"
      minHeight={100}
      padding="12px 24px"
    >
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Box marginRight={16} display="flex" alignItems="center" fontSize={16}>
          <HabitCount count={selectedHabits.length}>
            {selectedHabits.length}
          </HabitCount>{" "}
          {t("habits_plural")}:
        </Box>
        <NotMobile>
          {CATEGORIES.map((category) => (
            <Tooltip
              title={`${t(`habit_picker.${category.name}`)} ${t(
                "habits_plural"
              )}`}
            >
              <Box
                display="flex"
                alignItems="center"
                marginRight="24px"
                fontSize={16}
              >
                {
                  selectedHabits.filter(
                    (habit) => habit.category === category.name
                  ).length
                }
                <CategoryIcon
                  color={category.color}
                  size="small"
                  style={{ position: "static", marginLeft: "6px" }}
                  bordered
                />
              </Box>
            </Tooltip>
          ))}
        </NotMobile>
      </Box>
      <Box display="flex" width="100%" flexWrap="wrap">
        {selectedHabits
          .slice()
          .sort((a, b) => {
            if (changes.added.find((h) => h.id === a.id)) {
              return 1;
            }
            return a.category > b.category ? -1 : 1;
          })
          .map((habit) => (
            <HabitTag
              onMouseEnter={() => setHovering(habit.id)}
              onMouseLeave={() => setHovering(null)}
              borderRadius={15}
              background={
                CATEGORIES.find((c) => c.name === habit.category) &&
                CATEGORIES.find((c) => c.name === habit.category).color
              }
              padding="8px 12px"
              color="white"
              fontSize={14}
              lineHeight="14px"
              margin="6px"
              display="flex"
              alignItems="center"
            >
              {/* <TrashIcon hovering={hovering === habit.id}> */}
              <Icon
                type="close"
                style={{
                  color: "white",
                  marginRight: 7,
                  fontSize: 12,
                  cursor: "pointer"
                }}
                onClick={() => removeHabit(habit.id)}
              />
              {/* </TrashIcon> */}
              {habit.name}
            </HabitTag>
          ))}
      </Box>
      {hasChanges ? (
        <Button
          size="large"
          style={{ maxWidth: 300, marginLeft: "auto", marginTop: 12 }}
          onClick={saveChanges}
        >
          {t("habit_picker.save_changes")}
        </Button>
      ) : (
        <div style={{ maxWidth: 300, marginLeft: "auto" }}>
          <Tooltip title="You haven't made any changes">
            <Button size="large" style={{ marginTop: 12 }} disabled>
              {t("habit_picker.save_changes")}
            </Button>
          </Tooltip>
        </div>
      )}
    </Box>
  );
};

export default CurrentlySelectedHabits;
