// @flow strict

import React, { useState, useEffect, useMemo } from "react";
import { Leaderboard } from "services/api";
import useStores from "utils/useStores";
import LoadingOverlay from "components/LoadingOverlay";
import { PageHeader } from "antd";
import { shuffle } from "lodash";
import { UserLite } from "types/shared";
import {
  Container,
  UserContainer,
  StyledCol as Col,
  StyledRow as Row,
  StyledColBorderRight as ColBorderRight,
  PageHeaderNoPadding
} from "./styles";
import LeaderList from "./leaderList";
import AvatarList from "./avatarList";
import UserInfo from "./userInfo";
import BadgeList from "./badgeList";

type Props = {
  hideLeaderboard?: boolean;
};

export type Badge = {
  name: string;
  id: number;
  custom_fields: {
    title: string;
    description: string;
    value: number;
    img: string;
  };
};

export type LeaderboardUser = {
  user: UserLite;
  position: number;
  feedback_streak: number;
  response_rate: number;
  appended?: boolean;
  badges: Badge[];
};

const FullLeaderboard = React.memo<Props>(({ hideLeaderboard }) => {
  const [users, setUsers] = useState<LeaderboardUser[]>([]);
  const [badges, setBadges] = useState<Badge[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    profileStore: { currentUser }
  } = useStores();

  const cultureChampions: LeaderboardUser[] = useMemo(
    () =>
      shuffle(users.filter((u) => u.position <= 25)).sort((a, b) =>
        a.user.id === currentUser.id && a.position === b.position
          ? -1
          : a.position - b.position
      ),
    [users]
  );

  const cultureSupporters: LeaderboardUser[] = useMemo(() => {
    return shuffle(
      users.filter((u) => u.response_rate >= 0.5 && u.position > 25)
    );
  });

  const needsToStepUp: LeaderboardUser[] = useMemo(() => {
    return shuffle(users.filter((u) => u.response_rate < 0.5));
  }, [users]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await Leaderboard.full();
      setUsers(data.ranks);
      setBadges(data.all_badges);
      setLoading(false);
    })();
  }, []);

  const self = users.find((u) => u.user.id === currentUser.id);
  return (
    <Container>
      {loading && <LoadingOverlay />}
      <Row>
        {!hideLeaderboard && (
          <ColBorderRight xl={8} lg={8} md={8} sm={24} xs={24}>
            <Container height="100%" width="100%">
              <PageHeaderNoPadding title="🏆 Culture Champions" />
              <LeaderList users={cultureChampions} />
              <PageHeaderNoPadding title="🚀 Culture Supporters" />
              <AvatarList users={cultureSupporters} />
              <PageHeaderNoPadding title="😔 Needs to Step Up" />
              <AvatarList users={needsToStepUp} />
            </Container>
          </ColBorderRight>
        )}
        <Col
          xl={hideLeaderboard ? 24 : 16}
          lg={hideLeaderboard ? 24 : 16}
          md={hideLeaderboard ? 24 : 16}
          sm={24}
          xs={24}
        >
          <UserContainer height="30%" width="100%">
            {self && (
              <UserInfo
                user={self}
                total={users.length}
                hideLeaderboard={hideLeaderboard}
              />
            )}
          </UserContainer>
          <Container height="70%" width="100%">
            <PageHeader
              title={`Achievements - ${currentUser.badges.length}/${badges.length}`}
            />
            <BadgeList badges={badges} userBadges={currentUser.badges} />
          </Container>
        </Col>
      </Row>
    </Container>
  );
});

export default FullLeaderboard;
