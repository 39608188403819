import styled from 'styled-components';
import { media } from 'utils/style-utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;

  ${media.handheld`
    padding: 0;
  `}
`;

export const NoContentContainer = styled(Container)`
  font-size: ${({ theme }) => `${theme.font.header}px`};
  margin-top: 40px;
  align-items: center;
`;
