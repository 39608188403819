import styled from "styled-components";
import { media, lightenDarkenColor } from "utils/style-utils";

const isFirefox = typeof InstallTrigger !== 'undefined';

export const Body = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  ${media.handheld`
    flex-direction: column;
  `}
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: 100%;
  ${media.handheld`
    width: 100%;
    height: 100%;
    flex-direction: row;
  `}
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const MobileActions = styled.div`
  background-color: white;
  position: fixed;
  bottom: 0;
  height: 64px;
  top: auto;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #f5f5f5;
  box-shadow: 0px 5px 11px rgba(50, 50, 50, 0.75);
`;

export const ActionImageWhiteLabeled = styled.div`
  width: 60px;
  height: auto;
  .st0 {
    fill: #b8d1e6;
  }
  .st1 {
    fill: #b8bcbf;
  }
  .st2 {
    fill: ${props => lightenDarkenColor(props.theme.primary_color, -20)};
  }
  .st3 {
    fill: #f2f9ff;
  }
  .st4 {
    fill: ${props => props.theme.primary_color};
  }
  .st5 {
    fill: ${props => lightenDarkenColor(props.theme.primary_color, 20)};
  }
  ${media.handheld`
    width: 35px;
  `}
`;

export const ActionImage = styled.div`
  width: 60px;
  height: auto;
  .st0 {
    fill: #f9c791;
  }
  .st1 {
    fill: none;
  }
  .st2 {
    opacity: 0.15;
    enable-background: new;
  }
  .st3 {
    fill: #bc936c;
  }
  .st4 {
    fill: #fdc53f;
  }
  ${media.handheld`
    width: 35px;
  `}
`;

export const ActionText = styled.div`
  width: 100%;
  text-align: center;
  color: ${props => props.theme.primary_color};
  font-weight: 500;
  font-size: 12px;
  margin-top: 6px;
  padding: 0 12px;
`;

export const FeedWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  width: 100%;

  &:hover {
    overflow: ${isFirefox ? 'scroll' : 'overlay'};
  }
  ${media.handheld`
    width: 100%;
    height: 100%;
    overflow: auto;
    height: unset;
  `}
`;