import styled from "styled-components";
import { Row, Col, PageHeader } from "antd";
import { media } from "utils/style-utils";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({
  height
}) => height};
  width: ${({
  width
}) => width};

  ${media.handheld`
    height: auto;
    width: 100%;
    padding-bottom: 12px;
  `}
`;

export const UserContainer = styled(Container)`
  border-bottom: 1px solid #e8e8e8;
  padding: 16px 24px 24px 24px;

  ${media.handheld`
    padding: 12px 0 0 0 !important;
  `}
`;

export const PageHeaderNoPadding = styled(PageHeader)`
  padding-left: 0 !important;
`;

export const StyledRow = styled(Row)`
  height: 100%;
`;

export const UserName = styled.span`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
`;

export const StyledCol = styled(Col)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledColBorderRight = styled(StyledCol)`
  border-right: 1px solid #e8e8e8;

  ${media.handheld`
    border-right: none;
    border-bottom: 1px solid #e8e8e8;
    padding: 12px;

  `}
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemOuter = styled(Item)`
  margin-bottom: 6px;
  padding-right: 24px;
`;

export const CarrotImg = styled.img`
  width: ${({
  large
}) => large ? '20px' : '12px'};
  height: auto;
`;

export const AvatarListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 6px;
`;

export const TopContainer = styled.div`
  display: flex;
  height: 50%;
  justify-content: space-between;

  ${media.handheld`
    flex-direction: column;
    height: auto;
  `}
`;

export const BadgeItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 75px;
`;

export const BadgeItemImage = styled.div`
  width: 65px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const BadgeItemBody = styled.div`
  display: flex;
  width: calc(100% - 120px);
  flex-direction: column;
`;

export const BadgeItemTitle = styled.span`
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
`;

export const BadgeItemDescription = styled.span`
  color: rgba(0, 0, 0, 0.45);
  line-size: 14px;
  line-height: 22px;
`;