/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Launcher from "./Launcher";
import Menu from "./Menu";
import PreDots from "./PreDots";

const defaultMenuStyle = {
  position: "absolute",
  display: "flex",
  top: "0",
  left: -12
};
const defaultMenuItemStyle = {
  display: "flex",
  alignItems: "center"
};
const Actions = ({
  showDots,
  style,
  direction,
  items,
  size,
  icon,
  shade,
  menuStyle,
  menuItemStyle
}) => {
  const [open, setOpen] = useState(false);
  const sizes = {
    default: 40,
    large: 50
  };
  const wrapperStyle = {
    position: "fixed",
    bottom: window.innerWidth >= 500 && 48,
    top: window.innerWidth < 500 && 100,
    right: window.innerWidth < 500 ? 32 : 120,
    zIndex: 101,
    width: sizes[size]
  };

  useEffect(() => {
    if (open && shade) {
      disableBodyScroll();
      document.getElementsByClassName("ant-layout-header")[0].style.zIndex = 0;
    } else {
      enableBodyScroll();
      setTimeout(() => {
        const d = document.getElementsByClassName("ant-layout-header")[0];
        if (d) {
          d.style.zIndex = 100;
        }
      }, 500);
    }
  }, [open]);

  const shadeVariants = {
    visible: {
      opacity: 1
    },
    hidden: {
      opacity: 0
    }
  };

  const shadeStyle = {
    position: "fixed",
    background: "rgba(0, 0, 0, 0.85)",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    pointerEvents: "none"
  };
  const innerWrapperStyle = {
    position: "relative"
    // width: 40
  };

  const angleIncrement = 360.0 / items.length;

  return (
    <>
      {shade && (
        <motion.div
          style={shadeStyle}
          variants={shadeVariants}
          initial="hidden"
          animate={open ? "visible" : "hidden"}
        />
      )}
      <div style={{ ...wrapperStyle, ...style }} id="fab">
        <div style={innerWrapperStyle}>
          {showDots &&
            items.map((item, i) => (
              <PreDots
                i={i}
                key={i}
                isOpen={open}
                angle={i * angleIncrement}
                {...item}
              />
            ))}

          <Launcher
            icon={icon}
            size={size}
            onOpen={() => setOpen(!open)}
            isOpen={open}
          />
          <Menu
            size={size}
            items={items}
            direction={direction}
            isOpen={open}
            menuItemStyle={menuItemStyle}
            style={menuStyle}
          />
        </div>
      </div>
    </>
  );
};

Actions.defaultProps = {
  showDots: true,
  style: {},
  direction: "left",
  size: "default",
  icon: "question",
  shade: false,
  menuStyle: defaultMenuStyle,
  menuItemStyle: defaultMenuItemStyle
};

Actions.propTypes = {
  showDots: PropTypes.bool,
  icon: PropTypes.string,
  style: PropTypes.object,
  shade: PropTypes.bool,
  direction: PropTypes.string,
  items: PropTypes.array.isRequired,
  size: PropTypes.string,
  menuStyle: PropTypes.object,
  menuItemStyle: PropTypes.object
};
export default Actions;
