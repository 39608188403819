import { observable, action, computed } from "mobx";
import { notification } from "antd";
import { Profile, SuperAdmin } from "services/api";
import Cookies from "js-cookie";

import history from "services/history";
import organizationStore from "./organizationStore";

const parseJSONWebToken = token => {
  if (token === "" || token === "undefined") {
    return false;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

const applyTheme = theme => {
  const theme_object = {};
  Object.keys(theme).forEach(k => {
    const key = `@${k.replace(/_/g, "-")}`;
    theme_object[key] = theme[k];
  });
  window.less.modifyVars(theme_object);
  setTimeout(() => {
    window.less.modifyVars(theme_object);
  }, 500);
};

class ProfileStore {
  @observable currentUser = null;
  @observable isLoading = false;
  @observable stepIndex = 0;
  @observable token = localStorage.getItem("token") || "";
  @observable redirectToLinkedIn = false;
  @observable linkedInCode;
  @observable error = false;
  @observable theme = {};
  @observable currentHabitId = null;
  @observable newHighfiveId = null;
  @observable isOnboarding = false;
  @observable mentoringPath = "";
  @observable inTutorial = false;
  @observable pageOffset = 1;
  @observable offset = 0;
  @observable unreadNotificationCount = 0;
  @observable notifications = [];
  @observable isLoadingNotifications = false;
  @observable hasMoreNotifications = true;
  @observable proposalsOpen = false;
  @observable proposals = [];
  @observable questionsAnswered = 0;
  @observable feedbackQuestionsAnswered = 0;
  @observable networkSetupComplete = true;
  @observable settingsOpen = false;
  @observable updatesOpen = false;
  @observable hasNewUpdate = false;
  // @observable
  rootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action
  async fetchProfile() {
    if (this.isLoading) {
      return;
    }
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refresh_token");
    Cookies.set("token", token, { domain: process.env.REACT_APP_BASE_DOMAIN });
    Cookies.set("refresh_token", refreshToken, { domain: process.env.REACT_APP_BASE_DOMAIN });
    this.isLoading = true;
    // const timeout = setTimeout(() => {
    //   this.isLoading = false;
    //   this.token = '';
    //   localStorage.removeItem('token');
    //   message.error("Something went wrong, please try again");
    // }, 10000);
    try {
      const { data } = await Profile.fetch();

      // Sentry.configureScope((scope) => {
      //   scope.setUser({ email: data.email, id: data.id });
      // });

      this.offset = data.notification_offset;
      this.currentUser = data;
      /* eslint-disable */

      // }
      this.currentUser.name = `${this.currentUser.first_name} ${this.currentUser.last_name}`;
      if (this.isDashboardViewer) {
        if (this.currentUser.habits.length) {
          history.push(`/habits/${this.currentUser.habits[0].habit_user_id}`);
          this.isLoading = false;
          return;
        }
        history.push("/habits/NA");
        this.isLoading = false;
        return;
      }
      if (!this.currentUser.onboarded) {
        this.networkSetupComplete = false;
      }
      if (!this.isExternalUser) {
        if (this.currentUser.habits.length > 0) {
          this.currentHabitId = this.currentUser.habits[0].habit_user_id;
        }
        if (this.currentUser) {
          const { features } = this.currentUser.organization;
          applyTheme(data.organization.theme);
          if (features.includes("DDI_CLIENT")) {
            window.olark("api.box.hide");
          } else {
            window.olark.configure("system.is_single_page_application", true);
            window.olark("api.visitor.updateEmailAddress", {
              emailAddress: this.currentUser.email
            });
            window.olark("api.visitor.updateFullName", {
              fullName: this.currentUser.name
            });
          }

          this.theme = data.organization.theme;
          if (this.currentUser.admin || this.currentUser.can_configure) {
            await organizationStore.fetchOrganization();
          }
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
      }
    } catch (err) {
      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 403 || err.response.status === 404)
      ) {
        this.isLoading = false;
        localStorage.removeItem("token");
        this.token = "";
      }
    }
  }

  features = () => {
    if (this.currentUser) {
      return this.isSuperAdmin ? [] : this.currentUser.organization.features;
    }
    return [];
  };
  @action
  setNetworkSetup() {
    this.networkSetupComplete = true;
  }
  @action
  async fetchSuperAdmin() {
    this.isLoading = true;
    const api = new SuperAdmin();
    try {
      const { data } = await api.fetch();
      applyTheme(data.theme);
      this.currentUser = data;
      this.isLoading = false;
    } catch (err) {
      if (err.status && (err.status === 404 || err.status === 401 || err.status === 500)) {
        this.isLoading = false;
        localStorage.removeItem("token");
        this.token = "";
      }
    }
  }

  @computed get authenticated() {
    return !!parseJSONWebToken(this.token);
  }

  @action
  removeToken() {
    this.token = "";
    this.isLoading = false;
  }

  @action.bound
  optInToManagerRedesign() {
    this.currentUser.preferences.opted_in_new_manager_dashboard = true;
    Profile.update({ opted_in_new_manager_dashboard: true });
  }

  @action.bound
  optOutOfManagerRedesign() {
    this.currentUser.preferences.opted_in_new_manager_dashboard = false;
    Profile.update({ opted_in_new_manager_dashboard: false });
  }

  @action.bound
  fetchUpdatedHabits() {
    Profile.fetchHabits("legacy=true").then(({ data }) => {
      this.currentUser.habits = data;
    });
  }

  @action.bound
  answerQuestion() {
    switch (this.currentUser.questions[0].answerable_type) {
      case "FeedbackQuestion":
        this.feedbackQuestionsAnswered += 1;
        this.questionsAnswered += 1;
        break;
      case "EngagementQuestion":
      case "QuickPoll":
        this.questionsAnswered = this.questionsAnswered + 1;
        break;
      default:
        break;
    }
    this.currentUser.questions = this.currentUser.questions.slice(1);
  }

  @action.bound
  setCurrentHabitId(habitId) {
    this.currentHabitId = habitId;
  }

  @action.bound
  updateUser(data) {
    this.currentUser = data;
    this.redirectToLinkedIn = false;
    this.linkedInCode = null;
  }

  @action.bound updateInfo(data) {
    this.currentUser = data;
  }

  @action.bound
  updateAvatar(src) {
    this.currentUser.avatar = src;
  }

  @action.bound
  resetTutorial() {
    history.push("/");
    this.stepIndex = 0;
    this.currentUser.preferences.onboarding_tutorial_viewed = false;
  }

  @action.bound
  isRedirecting(code) {
    this.redirectToLinkedIn = true;
    this.linkedInCode = code;
  }

  @action.bound
  removeRedirect() {
    this.redirectToLinkedIn = false;
    this.linkedInCode = null;
  }

  @action.bound
  openProposals() {
    this.proposalsOpen = true;
  }
  @action.bound
  markGoalsReviewed(params, goal) {
    // let toMark = this.currentUser.goals_needing_review.find(g => g.id === goal)
    // if(toMark){

    this.currentUser.goals_needing_review = this.currentUser.goals_needing_review.filter(
      g => g.id !== goal
    );
    // }
  }

  @action
  addRole(role) {
    this.currentUser.role_id = role.id;
  }

  @action.bound
  setLoggedInUser(token, redirect) {
    localStorage.setItem("token", token);
    Cookies.set("token", token, { domain: process.env.REACT_APP_BASE_DOMAIN });

    this.token = token;
    if (this.isDashboardViewer) {
      localStorage.removeItem("refresh_token");

      localStorage.setItem("token", token);
      history.push("/");
    } else if (parseJSONWebToken(token).impersonator_id) {
      localStorage.removeItem("refresh_token");
      history.push("/");
    } else {
      try {
        Profile.exchangeToken().then(
          ({ data, headers }) => {
            const domain = process.env.REACT_APP_BASE_DOMAIN;
            console.log("exchanging_token");
            localStorage.setItem("token", headers["access-token"]);
            localStorage.setItem("refresh_token", headers["refresh-token"]);
            Cookies.set("token", headers["access-token"], {
              domain
            });
            Cookies.set("refresh_token", headers["refresh-token"], {
              domain
            });
            this.token = headers["access-token"];
            if (redirect) {
              history.push(redirect);
            } else {
              history.push("/");
            }
          },
          () => {
            localStorage.removeItem("token");
            this.token = "";
            history.push("/");
            notification.error({
              message: "Link Expired",
              key: 1,
              description: "Please send another magic link to log in"
            });
          }
        );
      } catch (err) {
        localStorage.removeItem("token");
        this.token = "";
        history.push("/");
      }
    }
  }

  @action.bound
  logout = () => {
    this.token = "";
    this.currentUser = null;
    history.push("/");
    localStorage.removeItem("token");
    localStorage.removeItem("impersonating");
  };

  @computed get isSuperAdmin() {
    return parseJSONWebToken(this.token).role === "superadmin";
  }
  @computed get impersonating() {
    return !!parseJSONWebToken(this.token).impersonator_id;
  }
  @computed get isExternalUser() {
    return parseJSONWebToken(this.token).role === "external_user";
  }
  @computed get isDashboardViewer() {
    return parseJSONWebToken(this.token).role === "dashboard_token";
  }

  @action.bound
  sendMagicLink = params => {
    Profile.magicLink(params);
  };

  @action.bound
  login = async params => {
    this.error = false;
    try {
      const { data, headers } = await Profile.login(params);
      const token = headers["access-token"];
      const refreshToken = headers["refresh-token"];
      const parsed_token = parseJSONWebToken(data.access_token || headers["access-token"]);
      Cookies.set("token", token, { domain: process.env.REACT_APP_BASE_DOMAIN });
      Cookies.set("refresh_token", refreshToken, { domain: process.env.REACT_APP_BASE_DOMAIN });
      localStorage.setItem("token", data.access_token || headers["access-token"]);

      localStorage.setItem("refresh_token", data.refresh_token || headers["refresh-token"]);

      this.token = data.access_token || headers["access-token"];
      if (parsed_token.admin) {
        window.location.assign("/admin");
      } else if (parsed_token.manager) {
        window.location.assign("/team");
      } else if (parsed_token.superadmin) {
        window.location.assign("/superadmin/organizations");
      } else {
        window.location.assign("/");
      }
    } catch (err) {
      localStorage.removeItem("token");
      this.error = true;
    }
  };

  @action
  setSignInRedirect(routerState) {
    this.signInRedirect = routerState;
  }
}

export default new ProfileStore();
