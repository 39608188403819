/* eslint-disable no-unused-vars */

/* eslint-disable no-debugger */
import moment from "moment";

export const dataShape = () => {
  return [{
    id: 'excel',
    name: 'Excelent',
    data: []
  }, {
    id: 'track',
    name: 'On Track',
    data: []
  }, {
    id: 'work',
    name: 'Needs Work',
    data: []
  }];
};

export const sortData = data => {
  return data.map(d => {
    return {
      id: d.id,
      name: d.name,
      data: d.data.sort((a, b) => {
        return moment(a.x, 'YYYY-MM-DD hh:mm a').valueOf() < moment(b.x, 'YYYY-MM-DD hh:mm a').valueOf() ? -1 : 1;
      })
    };
  });
};

export const chartData = (sortLoc, t, data, arr) => {
  const [loc] = sortLoc(t.value);
  arr.splice(loc, 1);
  data[loc].data.push({
    y: t.value,
    x: moment(t.created_at).format('YYYY-MM-DD hh:mm a')
  });
  arr.forEach(e => {
    data[e].data.push({
      y: null,
      x: moment(t.created_at).format('YYYY-MM-DD hh:mm a')
    });
  });
};

export const filterLargeMove = (sortLoc, t, f, d) => {
  // eslint-disable-next-line no-unused-vars
  const [loc, [locBottom, locTop]] = sortLoc(t.value);
  // eslint-disable-next-line no-unused-vars
  const [future, [futBottom, futTop]] = sortLoc(f.value);
  const data = d;
  const futDate = moment(f.created_at);
  const dateDiff = moment(t.created_at).diff(futDate, 'hours') / 4;
  const m = moment;
  if (loc > future) {
    data[loc].data.push({
      y: locTop,
      x: moment(t.created_at).subtract(dateDiff, 'hours').format('YYYY-MM-DD hh:mm a')
    });
    data[1].data.push({
      y: locTop,
      x: moment(t.created_at).subtract(dateDiff, 'hours').format('YYYY-MM-DD hh:mm a')
    });
    data[1].data.push({
      y: Math.round((locTop + futBottom) / 2),
      x: moment(t.created_at).subtract(dateDiff * 2, 'hours').format('YYYY-MM-DD hh:mm a')
    });
    data[1].data.push({
      y: futBottom,
      x: moment(t.created_at).subtract(dateDiff * 3, 'hours').format('YYYY-MM-DD hh:mm a')
    });
    data[future].data.push({
      y: futBottom,
      x: moment(t.created_at).subtract(dateDiff * 3, 'hours').format('YYYY-MM-DD hh:mm a')
    });
  } else {
    data[loc].data.push({
      y: locBottom,
      x: moment(t.created_at).subtract(dateDiff, 'hours').format('YYYY-MM-DD hh:mm a')
    });
    data[1].data.push({
      y: locBottom,
      x: moment(t.created_at).subtract(dateDiff, 'hours').format('YYYY-MM-DD hh:mm a')
    });
    data[1].data.push({
      y: Math.round((locBottom + futTop) / 2),
      x: moment(t.created_at).subtract(dateDiff * 2, 'hours').format('YYYY-MM-DD hh:mm a')
    });
    data[1].data.push({
      y: futTop,
      x: moment(t.created_at).subtract(dateDiff * 3, 'hours').format('YYYY-MM-DD hh:mm a')
    });
    data[future].data.push({
      y: futTop,
      x: moment(t.created_at).subtract(dateDiff * 3, 'hours').format('YYYY-MM-DD hh:mm a')
    });
  }
};

export const filterMove = (sortLoc, t, f, d) => {
  // eslint-disable-next-line no-unused-vars
  const [loc, [locBottom, locTop]] = sortLoc(t.value);
  // eslint-disable-next-line no-unused-vars
  const [future, [futBottom, futTop]] = sortLoc(f.value);
  const data = d;
  const futDate = moment(f.created_at);
  const dateDiff = Math.round(moment(t.created_at).diff(futDate, 'hours') / 2);
  if (loc > future) {
    data[loc].data.push({
      y: locTop,
      x: moment(t.created_at).subtract(dateDiff, 'hours').format('YYYY-MM-DD hh:mm a')
    });
    data[future].data.push({
      y: futBottom,
      x: moment(t.created_at).subtract(dateDiff, 'hours').format('YYYY-MM-DD hh:mm a')
    });
  } else {
    data[loc].data.push({
      y: locBottom,
      x: moment(t.created_at).subtract(dateDiff, 'hours').format('YYYY-MM-DD hh:mm a')
    });
    data[future].data.push({
      y: futTop,
      x: moment(t.created_at).subtract(dateDiff, 'hours').format('YYYY-MM-DD hh:mm a')
    });
  }
};