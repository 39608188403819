/* eslint-disable */
import { TokenRefreshLink } from "utils/refresh_token";
import axios from "axios";

import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from,
  ApolloLink,
  Observable
} from "@apollo/client";
import promiseToObservable from "utils/promiseToObservable";
import { ErrorLink, onError } from "@apollo/link-error";
import i18n from "utils/i18n";
import { network } from "vis-network";
import { API_URL } from "../env";

const apiClient = axios.create({
  baseURL: API_URL
});
// import profileStore from 'stores/profileStore';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL
});

const blogLink = new HttpLink({
  uri: process.env.REACT_APP_BLOG_GRAPHQL_URL
});

const getLanguage = () => {
  const i = i18n;
  const lng = localStorage.getItem("i18nextLng") || "en";
  const lngs = ["en", "de", "fr", "es", "zh-CN"];
  const val = lngs.includes(lng) ? lng : "en";

  return val;
};

const parseJSONWebToken = token => {
  if (token === "" || !token) {
    return false;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

const isTokenValid = (): boolean => {
  const token = localStorage.getItem("token");
  const decodedToken = parseJSONWebToken(token);

  if (!decodedToken) {
    return false;
  }

  const now = new Date();
  return now.getTime() < decodedToken.exp * 1000;
};

const fetchNewAccessToken: FetchNewAccessToken = async () => {
  if (!process.env.REACT_APP_API_BASE_URL) {
    throw new Error(".env.REACT_APP_API_BASE_URL must be set to use refresh token link");
  }

  try {
    const fetchResult = await fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: `
          mutation RefreshToken {
            refreshTokens(input: {
              refreshToken: "${refreshToken}"
            }) {
              accessToken
              refreshToken
              errors {
                field
                message
              }
            }
          }
        `
      })
    });

    const refreshResponse = await fetchResult.json();

    if (
      !refreshResponse ||
      !refreshResponse.data ||
      !refreshResponse.data.refreshTokens ||
      !refreshResponse.data.refreshTokens.accessToken
    ) {
      return undefined;
    }

    return refreshResponse.data.refreshTokens.accessToken;
  } catch (e) {
    throw new Error("Failed to fetch fresh access token");
  }
};

// const refreshTokenLink = getRefreshTokenLink({
//   authorizationHeaderKey: 'Authorization',
//   fetchNewAccessToken,
//   getAccessToken: () => localStorage.getItem('token'),
//   getRefreshToken: () => localStorage.getItem('refresh_token'),
//   isAccessTokenValid: (accessToken) => isTokenValid(accessToken),
//   isUnauthenticatedError: (graphQLError) => {
//     const { extensions } = graphQLError;
//     // eslint-disable-next-line no-debugger
//
//     if (extensions && extensions.code && extensions.code === 'UNAUTHENTICATED') {
//       return true;
//     }
//     return false;
//   }
// });

// const refreshLink = new TokenRefreshLink({
//   isTokenValidOrUndefined: () => !isTokenValid(),
//   fetchAccessToken: async () => {
//   const resp = await fetch('http://localhost:8080/graphql', {
//     method: 'POST',
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem('token')}`,
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       query: `mutation RefreshToken { refreshToken(refreshToken: "${localStorage.getItem(
//         'refresh_token'
//       )}") { token refreshToken } }`
//     })
//   });
//   return resp.json();
// },
//   handleFetch: (accessToken) => {
//     // const accessTokenDecrypted = jwtDecode(accessToken);
//     localStorage.setItem('token', accessToken);
//     // setExpiresIn(parseExp(accessTokenDecrypted.exp).toString());
//   },
//   handleResponse: (operation, accessTokenField) => (response) => {
//     // here you can parse response, handle errors, prepare returned token to
//     // further operations
//     // returned object should be like this:
//     // {
//     //    access_token: 'token string here'
//     // }
//   },
//   handleError: (err) => {
//     // full control over handling token fetch Error
//     console.warn('Your refresh token is invalid. Try to relogin');
//     console.error(err);

//     // your custom action here
//     // user.logout();
//     localStorage.removeItem('token');
//   }
// });

const refreshToken = async () => {
  const resp = await apiClient.post(
    `/account/tokens`,
    {},
    {
      headers: {
        "Accept-Language": getLanguage(),
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
        "Access-Token": window.localStorage.getItem("access_token"),
        "Refresh-Token": window.localStorage.getItem("refresh_token")
      }
    }
  );
  window.localStorage.setItem("token", resp.headers["access-token"]);
  window.localStorage.setItem("refresh_token", resp.headers["refresh-token"]);

  return resp.headers["access-token"];
};

const refreshLink = onError(({ graphQLErrors, operation, forward, networkError }) => {
  if (networkError && networkError.statusCode && networkError.statusCode === 401) {
    if (localStorage.getItem("refresh_token")) {
      // Let's refresh token through async request
      return new Observable(observer => {
        refreshToken()
          .then(refreshResponse => {
            localStorage.setItem("token", refreshResponse);
            operation.setContext(({ headers = {} }) => ({
              headers: {
                // Re-add old headers
                ...headers,
                // Switch out old access token for new one
                Authorization: `Bearer ${refreshResponse}` || null
              }
            }));
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            };
            // Retry last failed request
            forward(operation).subscribe(subscriber);
          })
          .catch(error => {
            // No refresh or client token available, we force user to login
            observer.error(error);
          });
      });
    }
  }

  if (
    graphQLErrors &&
    graphQLErrors.find(err => err.extensions && err.extensions.code === "UNAUTHENTICATED")
  ) {
    // return promiseToObservable(refreshToken()).flatMap(() => forward(operation));
    // const oldHeaders = operation.getContext().headers;
    // const jsonResponse = await resp.json();
    //
    // operation.setContext({
    //   headers: {
    //     ...oldHeaders,
    //     Authorization: `Bearer ${jsonResponse.accessToken}`
    //   }
    // });
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  if (operation.getContext().clientName === "blog") {
    return forward(operation);
  }
  operation.setContext({
    headers: {
      "Accept-Language": getLanguage(),
      Authorization: localStorage.getItem("token")
        ? `Bearer ${localStorage.getItem("token")}`
        : null
    }
  });
  return forward(operation);
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  cache: cache,
  link: from([
    refreshLink,
    authMiddleware,
    ApolloLink.split(operation => operation.getContext().clientName === "blog", blogLink, httpLink)
  ]),
  connectToDevTools: true
});

export { cache };

export default client;
