import ReactGA from "react-ga";

export const watchVideo = label => {
  ReactGA.event({
    category: "Interaction",
    action: "Watched Video",
    label
  });
};
export const sendHighfive = () => {
  ReactGA.event({
    category: "Interaction",
    action: "Sent High five"
  });
};

export const makeAnnouncement = () => {
  ReactGA.event({
    category: "Interaction",
    action: "Made Announcement"
  });
};

export const sendSuggestion = () => {
  ReactGA.event({
    category: "Interaction",
    action: "Sent Suggestion"
  });
};
export const likePost = type => {
  ReactGA.event({
    category: "Interaction",
    action: "Liked feed item",
    label: type
  });
};
export const scrollFeed = () => {
  ReactGA.event({
    category: "Interaction",
    action: "Scrolled feed"
  });
};