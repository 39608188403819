import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "antd";
import RateObjective from "components/RateObjective";
import { Review } from "services/api";
import { ReactComponent as FlairImg } from "assets/dashboard/objective_review_due.svg";
import { ItemWrapper, Title, ButtonWrapper, Content, Flair } from "../styles";
import UserAvatar from "../../../components/UserAvatar";
import { useTranslation } from "react-i18next";

const ObjectiveReviewItem = ({ objective, markCompleted }) => {
  const [reviewOpen, setReviewOpen] = useState(false);
  const { t } = useTranslation();
  const submitObjective = (params) => {
    Review.create(params).then(() => {
      setReviewOpen(false);
      markCompleted();
    });
  };

  return (
    <ItemWrapper>
      <Modal
        visible={reviewOpen}
        onCancel={() => setReviewOpen(false)}
        destroyOnClose
        width={window.innerWidth < 500 ? "98vw" : "600px"}
        footer={false}
      >
        <RateObjective
          onSubmit={submitObjective}
          objectiveId={objective.id}
          withDate
        />
      </Modal>
      <Content>
        <Title>
          {t("homepage.action_items.review_objective.prompt_text", {
            objectiveName: objective.name
          })}
        </Title>
        <p>
          {t("homepage.action_items.review_objective.prompt_subtext")}{" "}
          <UserAvatar
            avatar={objective.owner.avatar}
            name={objective.owner.name}
          />{" "}
          {objective.owner.name}{" "}
        </p>
        <ButtonWrapper>
          <Button onClick={() => setReviewOpen(true)} type="primary">
            {t("homepage.action_items.review_objective.action_text")}
          </Button>
        </ButtonWrapper>
      </Content>
      <Flair>
        <FlairImg />
      </Flair>
    </ItemWrapper>
  );
};

ObjectiveReviewItem.propTypes = {
  objective: PropTypes.object.isRequired,
  markCompleted: PropTypes.func.isRequired
};

export default ObjectiveReviewItem;
