/* eslint-disable */
import * as Types from '../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type IDiscardManagerActionItemMutationVariables = Types.Exact<{
  input: Types.IDiscardManagerActionItemInput;
}>;


export type IDiscardManagerActionItemMutation = (
  { __typename?: 'Mutation' }
  & { discardManagerActionItem?: Types.Maybe<(
    { __typename?: 'ManagerActionItem' }
    & Pick<Types.IManagerActionItem, 'id'>
  )> }
);


export const DiscardManagerActionItemDocument = gql`
    mutation discardManagerActionItem($input: DiscardManagerActionItemInput!) {
  discardManagerActionItem(input: $input) {
    id
  }
}
    `;
export type IDiscardManagerActionItemMutationFn = ApolloReactCommon.MutationFunction<IDiscardManagerActionItemMutation, IDiscardManagerActionItemMutationVariables>;

/**
 * __useDiscardManagerActionItemMutation__
 *
 * To run a mutation, you first call `useDiscardManagerActionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscardManagerActionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discardManagerActionItemMutation, { data, loading, error }] = useDiscardManagerActionItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiscardManagerActionItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDiscardManagerActionItemMutation, IDiscardManagerActionItemMutationVariables>) {
        return ApolloReactHooks.useMutation<IDiscardManagerActionItemMutation, IDiscardManagerActionItemMutationVariables>(DiscardManagerActionItemDocument, baseOptions);
      }
export type DiscardManagerActionItemMutationHookResult = ReturnType<typeof useDiscardManagerActionItemMutation>;
export type DiscardManagerActionItemMutationResult = ApolloReactCommon.MutationResult<IDiscardManagerActionItemMutation>;
export type DiscardManagerActionItemMutationOptions = ApolloReactCommon.BaseMutationOptions<IDiscardManagerActionItemMutation, IDiscardManagerActionItemMutationVariables>;