/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import {
  ActionContainer,
  ActionTitle,
  ActionDesc,
  ActionTitleContainer
} from "./styles";

const desktop = window.innerWidth > 1590;

const Action = ({ title, icon, desc, action, link, inactive }) => {
  const body = (
    <ActionContainer inactive={inactive} desktop={desktop} onClick={action}>
      <ActionTitleContainer desktop={desktop}>
        {icon}
        <ActionTitle>{title}</ActionTitle>
      </ActionTitleContainer>
      {desktop && <ActionDesc>{desc}</ActionDesc>}
    </ActionContainer>
  );
  if (!link) {
    return body;
  }
  return (
    <a style={{ color: "rgba(0, 0, 0, 0.65)" }} href={link}>
      {body}
    </a>
  );
};

Action.propTypes = {
  inactive: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  desc: PropTypes.string.isRequired,
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired
};

export default Action;
