/* eslint-disable  */
import React from "react";
import { Icon, Input, Button } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  width: 100%;
`;

const StyledInput = styled(Input)`
  margin-right: 12px;
  margin-bottom: 6px;
`;
const StyledButton = styled(Button)`
  width: 80%;
  margin-right: 12px;
  margin-bottom: 6px;
`;

const Label = styled.span`
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin-bottom: 8px;
`;

const TinyButton = styled(Button)`
  width: 16px;
  height: 16px;
  min-height: 16px;
  min-width: 16px;
  margin-left: 8px;
  display: flex;
  border: 1px solid #e2e2e2;
  background-color: #e2e2e2;
  justify-content: center;
`;

const KeyResults = ({
  results,
  addKeyResult,
  removeKeyResult,
  editKeyResult
}) => {
  return (
    <Wrapper>
      <Label>Key Results: </Label>
      {results.map((kr, i) => (
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 12 }}
        >
          <StyledInput
            size="large"
            value={kr}
            className="key-results"
            onChange={(e) => editKeyResult(i, e.target.value)}
            placeholder="Enter key result"
            key={i}
            style={{
              width: "80%",
              marginRight: 12
            }}
          />
          <TinyButton shape="circle" onClick={() => removeKeyResult(i)}>
            <Icon type="close" style={{ fontSize: 8, color: "grey" }} />
          </TinyButton>
        </div>
      ))}
      <StyledButton size="large" icon="plus" onClick={addKeyResult}>
        Add another key result
      </StyledButton>
    </Wrapper>
  );
};

KeyResults.propTypes = {
  results: PropTypes.array.isRequired,
  addKeyResult: PropTypes.func.isRequired,
  removeKeyResult: PropTypes.func.isRequired,
  editKeyResult: PropTypes.func.isRequired
};

export default KeyResults;
