/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable react/prop-types */
import React from "react";
import { Button, Tooltip } from "antd";
import { motion } from "framer-motion";

const sizes = {
  default: 40,
  large: 40
};
const fontSizes = {
  default: 14,
  large: 20
};
const MenuItem = ({
  i,
  isOpen,
  color,
  onClick,
  icon,
  tooltip,
  direction,
  text,
  style,
  length,
  size
}) => {
  const buttonStyle = {
    border: color,
    background: color,
    margin: "0 12px ",
    height: sizes[size],
    width: sizes[size],
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"
  };

  const variants = {
    open: {
      x: -50 * (length - i + 1),
      opacity: 1
      // transition: {
      //   y: { stiffness: 1000, velocity: -100 }
      // }
    },
    closed: {
      x: 0,
      opacity: 0,
      transition: { type: "spring", stiffness: 200, damping: 15 }
    }
  };

  switch (direction) {
    case "left":
      variants.open.x = -60 * (length - i);
      variants.closed.x = 0;
      break;
    case "up":
      delete variants.open.x;
      delete variants.closed.x;
      variants.open.y = -60 * (length - i);
      variants.closed.y = 0;
      break;
    case "down":
      delete variants.open.x;
      delete variants.closed.x;
      variants.open.y = 50 * (length - i + 1);
      variants.closed.y = 0;
      break;
    case "right":
      variants.open.x = 50 * (length - i + 1);
      variants.closed.x = 0;
      break;
    default:
      break;
  }
  return (
    <motion.div
      style={{
        pointerEvents: !isOpen ? "none" : "all",
        position: "absolute",
        zIndex: 102
      }}
      initial="closed"
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <Tooltip title={tooltip}>
        <div style={style} onClick={onClick}>
          <Button
            style={buttonStyle}
            type="primary"
            shape="circle"
            icon={icon}
          />
          <div
            style={{ color: "white", zIndex: 601, fontSize: fontSizes[size] }}
          >
            {text}
          </div>
        </div>
      </Tooltip>
    </motion.div>
  );
};

export default MenuItem;
