/* eslint-disable react/no-array-index-key */

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import posed, { PoseGroup } from "react-pose";
import { motion, AnimatePresence } from "framer-motion";

import {
  Collapse,
  Skeleton,
  Button,
  Input,
  Divider,
  Checkbox,
  Icon,
  Empty,
  Modal,
  Select
} from "antd";

import PropTypes from "prop-types";
import normalize from "json-api-normalizer";
import build from "redux-object";
import { Category, Objective } from "services/api";
import { Wrapper, HeaderText, Header, ItemWrapper, AddingList } from "./styles";
import useStores from "../../utils/useStores";
import CategoryForm from "./categoryForm";
import OKRForm from "../OKRForm";

const { Option } = Select;
const { Panel } = Collapse;
const nameFilter = (input, { props: { children } }) => {
  return children.toLowerCase().includes(input.toLowerCase());
};

const OKRLibrary = ({ toggleLoading, handleImport, closeModal }) => {
  const { profileStore } = useStores();
  const [state, setState] = useState({
    category: {},
    objective: {},
    key_result: {}
  });
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState([]);
  const [options, setOptions] = useState([]);
  const [activeKeys, setActiveKeys] = useState(["0"]);
  const [editing, setEditing] = useState(null);
  const [editingObjective, setEditingObjective] = useState(null);
  const [categoryToAdd, setCategoryToAdd] = useState(null);
  const [query, setQuery] = useState("");
  const [objectiveQuery, setObjectiveQuery] = useState(undefined);
  const [categoryFormOpen, setCategoryFormOpen] = useState(false);
  const [objectiveFormOpen, setObjectiveFormOpen] = useState(false);
  const setData = (data) => {
    const d = normalize(data, {
      camelizeKeys: false,
      camelizeTypeValues: false
    });
    setState({
      objective: { ...state.objective, ...d.objective },
      category: { ...state.category, ...d.category },
      key_result: { ...state.key_result, ...d.key_result }
    });
  };
  useEffect(() => {
    Category.all().then(({ data }) => {
      if (data.data.length > 0) {
        setData(data);
        setActiveKeys([data.data[0].id]);
      }
      toggleLoading();
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (objectiveQuery) {
      setOptions(
        build(state, "objective").filter((o) =>
          o.name.toLowerCase().includes(objectiveQuery.toLowerCase())
        )
      );
    } else {
      setOptions([]);
    }
  }, [objectiveQuery]);
  if (loading) {
    return (
      <Wrapper>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Wrapper>
    );
  }

  const importObjectives = () => {
    const b = build(state, "objective", checked);
    handleImport(b);
  };

  const handleCheck = (id) => {
    if (checked.includes(id)) {
      setChecked(checked.filter((c) => c !== id));
    } else {
      setChecked([...checked, id]);
    }
  };

  const handleDelete = (c) => {
    Category.delete(c.id).then(() => {
      const nextState = state.category;
      delete nextState[c.id];
      setState({ category: nextState, ...state });
    });
  };
  const handleDeleteObjective = (o) => {
    Objective.destroy(o.id).then(() => {
      const nextCategoryState = state.category;
      nextCategoryState[
        o.category_id
      ].relationships.objectives.data = nextCategoryState[
        o.category_id
      ].relationships.objectives.data.filter((obj) => obj.id !== o.id);
      const nextObjectiveState = state.objective;
      delete nextObjectiveState[o.id];
      setState({ objective: nextObjectiveState, category: nextCategoryState });
    });
  };
  const confirmDelete = (c) => {
    Modal.confirm({
      title: `Are you sure you want to delete '${c.name}'`,
      content:
        "All library objectives in this category will be destroyed.  This action cannot be undone",
      onOk: () => handleDelete(c),
      onCancel() {}
    });
  };
  const confirmDeleteObjective = (o) => {
    Modal.confirm({
      title: `Are you sure you want to delete '${o.name}'`,
      content: "This action cannot be undone",
      onOk: () => handleDeleteObjective(o),
      onCancel() {}
    });
  };

  const createObjective = (params) => {
    Objective.create({
      ...params,
      library: true,
      category_id: categoryToAdd.id
    }).then(({ data }) => {
      setData(data);
    });
  };
  const editObjective = (params) => {
    Objective.update(editingObjective.id, params).then(({ data }) => {
      setData(data);
      setEditingObjective(null);
    });
  };

  const handleSubmit = (name) => {
    if (editing) {
      Category.update(editing.id, { name }).then(({ data }) => {
        setData(data);

        setCategoryFormOpen(false);
        setTimeout(() => {
          setEditing(null);
        }, 200);
      });
    } else {
      Category.create({ name }).then(({ data }) => {
        const d = normalize(data);
        setState({ ...state, category: { ...state.category, ...d.category } });
        setActiveKeys([...activeKeys, ...Object.keys(d.category)]);
        setCategoryFormOpen(false);
      });
    }
  };

  const openForm = (c) => {
    setCategoryToAdd(c);
    setObjectiveFormOpen(true);
  };

  const handleCancel = () => {
    setCategoryFormOpen(false);
    setEditingObjective(null);
    setTimeout(() => {
      setEditing(null);
    }, 200);
  };

  const setActivePanels = (e) => {
    setActiveKeys(e);
  };

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };
  const handleSearchObjectives = (val) => {
    setObjectiveQuery(val);
  };

  const { admin } = profileStore.currentUser;

  return (
    <Wrapper>
      <CategoryForm
        handleSubmit={handleSubmit}
        visible={categoryFormOpen}
        editing={editing}
        handleCancel={handleCancel}
      />
      <OKRForm
        closeForm={() => {
          setCategoryToAdd(null);
          setObjectiveFormOpen(false);
        }}
        libraryMode
        parent={editingObjective ? editingObjective.parent : null}
        visible={objectiveFormOpen}
        editingObjective={editingObjective}
        currentUser={profileStore.currentUser}
        handleSubmit={editingObjective ? editObjective : createObjective}
      />
      <HeaderText>Objective Library</HeaderText>
      <Header>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Input
            onChange={handleSearch}
            placeholder="Filter by category"
            style={{ width: "300px", marginRight: 24 }}
          />
          <Select
            showSearch
            value={objectiveQuery}
            onSearch={handleSearchObjectives}
            onChange={(val) => {
              if (!checked.includes(val)) {
                setChecked([...checked, val]);
              }
              setObjectiveQuery(undefined);
            }}
            placeholder="Search objectives"
            showArrow={false}
            filterOption={nameFilter}
            notFoundContent={null}
            style={{ width: "300px" }}
          >
            {options.map((o) => (
              <Option value={o.id}>{o.name}</Option>
            ))}
          </Select>
        </div>
        {admin && (
          <Button type="primary" onClick={() => setCategoryFormOpen(true)}>
            New Category
          </Button>
        )}
      </Header>
      <Divider />
      <Wrapper
        style={{ flexDirection: "row", justifyContent: "space-between" }}
      >
        <AddingList style={{ width: "60%" }}>
          {Object.keys(state.category).length > 0 ? (
            <Collapse
              bordered={false}
              activeKey={activeKeys}
              onChange={setActivePanels}
            >
              {build(state, "category")
                .filter((c) =>
                  c.name.toLowerCase().includes(query.toLowerCase())
                )
                .map((c, i) => (
                  <Panel
                    key={`${c.id}`}
                    extra={
                      admin && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <a
                            role="presentation"
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditing(c);
                              setCategoryFormOpen(true);
                            }}
                          >
                            Edit
                          </a>
                          <div style={{ margin: "0 6px" }}>|</div>
                          <a
                            role="presentation"
                            onClick={(e) => {
                              e.stopPropagation();
                              confirmDelete(c);
                            }}
                            style={{ color: "red" }}
                          >
                            Delete
                          </a>
                        </div>
                      )
                    }
                    header={`${c.name} (${c.objectives.length} ${
                      c.objectives.length === 1 ? "objective" : "objectives"
                    })`}
                  >
                    {admin && (
                      <div style={{ marginLeft: 40, marginBottom: 12 }}>
                        <a role="presentation" onClick={() => openForm(c)}>
                          Add new objective
                        </a>
                      </div>
                    )}
                    {c.objectives.map((o) => (
                      <ItemWrapper>
                        <Checkbox
                          onChange={() => handleCheck(o.id)}
                          checked={checked.includes(o.id)}
                        >
                          {o.name}
                        </Checkbox>
                        {admin && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingRight: 16
                            }}
                          >
                            <a
                              role="presentation"
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditingObjective(o);
                                setObjectiveFormOpen(true);
                              }}
                            >
                              Edit
                            </a>
                            <div style={{ margin: "0 6px" }}>|</div>
                            <a
                              role="presentation"
                              onClick={(e) => {
                                e.stopPropagation();
                                confirmDeleteObjective(o);
                              }}
                              style={{ color: "red" }}
                            >
                              Delete
                            </a>
                          </div>
                        )}
                      </ItemWrapper>
                    ))}
                  </Panel>
                ))}
            </Collapse>
          ) : (
            <Empty
              style={{ margin: "auto" }}
              description="No categories yet, use the button in the top right to create a new one"
            />
          )}
        </AddingList>
        <AddingList style={{ padding: 12 }}>
          <div
            style={{
              color: "rgba(0, 0, 0, 0.85)",
              marginBottom: 6,
              fontSize: 14
            }}
          >
            Objectives to import:
          </div>
          {checked.length === 0 && (
            <Empty
              description="Objectives you've selected will show up here"
              style={{ margin: "auto" }}
            />
          )}
          <AnimatePresence>
            {checked.map((c) => (
              <ItemWrapper
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                positionTransition={{
                  type: "spring",
                  stiffness: 200
                }}
                key={c}
                style={{ justifyContent: "flex-start", paddingLeft: 12 }}
              >
                <Icon
                  type="cross"
                  onClick={() => handleCheck(c)}
                  style={{ color: "red", marginRight: 12, cursor: "pointer" }}
                />
                {state.objective[c].attributes.name}
              </ItemWrapper>
            ))}
          </AnimatePresence>
          {checked.length > 0 && (
            <Button
              onClick={() => {
                closeModal();
                importObjectives();
              }}
              style={{
                width: "fit-content",
                marginTop: "auto",
                marginLeft: "auto"
              }}
              type="primary"
            >
              Import Objectives
            </Button>
          )}
        </AddingList>
      </Wrapper>
    </Wrapper>
  );
};

OKRLibrary.propTypes = {
  toggleLoading: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleImport: PropTypes.func.isRequired
};

export default OKRLibrary;
