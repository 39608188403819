import styled from "styled-components";
import { List } from "antd";

export const Wrapper = styled.div`
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
`;

export const Square = styled.div`
  border-radius: 3px;
  width: 18px;
  height: 18px;
  background-color: ${props => props.color};
  margin-right: 12px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  padding-right: 24px;
  width: 100%;
  padding-left: 12px;
`;

export const ListItem = styled(List.Item)`
  &:hover {
    background-color: #e8e8e8;
  }
  transition: 0.2s;
  background-color: ${props => props.hovered ? '#e8e8e8' : 'white'};
  cursor: pointer;
`;