/* eslint-disable */
import React, { Component, Suspense } from "react";
import { Provider } from "mobx-react";
import stores from "stores";
import { Router } from "react-router-dom";
import ReactGA from "react-ga";
import { ApolloProvider } from "@apollo/client";
import ScrollToTop from "components/ScrollToTop";
import history from "services/history";
import client from "services/graphql";
import theme from "assets/theme";
import Shell from "./Shell";
import GlobalStyle from "services/createGlobalStyle";
import { ThemeProvider } from "styled-components";
import LoadingOverlay from "components/LoadingOverlay";

class App extends Component {
  render() {
    const orgTheme = stores.profileStore.organization
      ? stores.profileStore.organization.theme
      : {};
    return (
      <Suspense fallback={<LoadingOverlay />}>
        <Router history={history}>
          <ScrollToTop>
            <ApolloProvider client={client}>
              <Provider {...stores}>
                <div>
                  <Shell />
                  <GlobalStyle />
                </div>
              </Provider>
            </ApolloProvider>
          </ScrollToTop>
        </Router>
      </Suspense>
    );
  }
}

export default App;
