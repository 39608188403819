/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import PropTypes from "prop-types";
import { StyledTable as Table } from "./styles";

// const generateData = (allHabits, relationships) => {

// };

const RelationshipTable = ({
  relationships,
  allHabits,
  selectAll,
  onSelect
}) => {
  // const [generatedColumns, setGeneratedColumns] = useState([]);
  // const [data, setData] = useState([]);

  const renderTitle = (habitUser) => {
    const { habit } = habitUser;
    return (
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: -85,
            left: -30,
            textAlign: "center",
            transform: "rotate(-45deg)",
            width: 150
          }}
        >
          {habit.name}
        </div>
        <Checkbox
          onClick={() => selectAll(habitUser.id)}
          checked={relationships.every((r) =>
            r.habits.find((re) => re.id === habitUser.id)
          )}
        />
      </div>
    );
  };

  const generateColumns = (allhabits) => {
    const columns = [];

    allhabits.forEach((habitUser) => {
      const { habit } = habitUser;
      columns.push({
        title: renderTitle(habitUser),
        dataIndex: habit.id,
        key: habit.id,
        render: (_name, record) => (
          <Checkbox
            onClick={() => onSelect(record, habitUser.id)}
            checked={record.habits.find((h) => h.id === habitUser.id)}
          />
        )
      });
    });
    return columns;
  };

  // useEffect(() => {
  //   setGeneratedColumns(generateColumns(allHabits));
  // }, []);

  // useEffect(() => {
  //   setData(generateData(allHabits, relationships));
  // });

  const columns = [
    {
      title: "Name",
      dataIndex: "user.name",
      key: "name"
    },
    ...generateColumns(allHabits)
  ];
  //
  return (
    <Table
      columns={columns}
      size="small"
      dataSource={relationships}
      pagination={false}
      scroll={{ y: "calc(100vh - 390px)" }}
    />
  );
};

RelationshipTable.propTypes = {
  relationships: PropTypes.array.isRequired,
  allHabits: PropTypes.array.isRequired,
  selectAll: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default RelationshipTable;
