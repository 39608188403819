/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Modal,
  Upload,
  Button,
  Icon,
  Row,
  Col,
  Avatar,
  TimePicker,
  Spin,
  // Select,
  Checkbox,
  Select
} from "antd";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import moment from "moment";
import { observable } from "mobx";
import UserForm from "components/UserForm/Lite";
import nameToInitials from "utils/nameToInitials";
import { Profile } from "services/api";
import PrivacyPolicy from "components/PrivacyPolicyModal";
import { AvatarImg, AvatarWrapper, Column } from "./styles";
import PhoneVerification from "./PhoneVerification";
import { withTranslation } from "react-i18next";
const lngs = ["en", "de", "fr", "es", "zh-CN"];
@observer
class AccountSettings extends React.Component {
  @observable open = false;
  @observable imageUrl;
  @observable mailTime;
  @observable loading = false;
  @observable newAvatar = false;
  @observable newMailTime = false;
  @observable feedbackDelivery = ["email"];
  @observable isImageWidthHigher = null;
  @observable formData = new FormData();
  @observable saved = false;
  @observable error;
  @observable linkedInOpen = false;
  @observable phoneNumberOpen = false;
  @observable phoneNumber = "";
  @observable valueChanged = false;
  @observable success = {
    msg: "Your profile picture has been changed",
    show: false
  };

  uploadProfileAvatar = (newAvatar) => {
    const avatar = newAvatar.file;
    // Generating fake form to send via API
    this.formData.append("avatar", avatar);
    const fileReader = new FileReader();
    const image = new Image();
    fileReader.onload = (e) => {
      this.imageUrl = e.target.result;
      this.newAvatar = true;
      image.src = e.target.result;
      image.onload = () => {
        this.isImageWidthHigher = image.width > image.height;
      };
    };
    this.avatar = avatar;

    if (avatar) {
      fileReader.readAsDataURL(avatar);
    }
  };

  constructor(props) {
    super(props);
    this.mailTime = props.mailTime;
    this.phoneNumber = props.phoneNumber;
    this.imageUrl = props.imageUrl;
    this.feedbackDelivery = props.feedbackDelivery;
    this.spinner = false;
  }

  componentWillMount() {
    const { linkedInCode } = this.props;
    if (linkedInCode) {
      this.spinner = true;
      Profile.avatarFromLinkedIn(linkedInCode)
        .then(({ data }) => {
          this.spinner = false;
          this.imageUrl = data.avatar;
          this.forceUpdate();
        })
        .catch(() => {
          this.spinner = false;
          this.error = "LinkedIn request failed, please try again";
        });
    }
  }

  setTime = (momentTime, stringTime) => {
    this.mailTime = stringTime;
    this.newMailTime = true;
  };

  openPhoneNumber = () => {
    this.phoneNumberOpen = true;
  };

  closePhoneNumber = (verified = false, phoneNumber) => {
    if (verified) {
      this.phoneNumber = `+1${phoneNumber}`;
    }
    this.phoneNumberOpen = false;
  };

  handleSubmit = () => {
    const { updateUser, removeRedirect, onCancel } = this.props;
    if (this.newMailTime) {
      this.formData.append("mail_time", this.mailTime);
    }
    this.formData.append("feedback_delivery", this.feedbackDelivery);
    const form = this.formRef;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      Object.keys(values).forEach((k) => this.formData.append(k, values[k]));
      this.error = false;
      this.loading = true;
      Profile.update(this.formData).then(({ data }) => {
        updateUser(data);
        this.loading = false;
        removeRedirect();
        onCancel();
      });
    });
  };

  render() {
    const mobileRow = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    };
    const {
      linkedInRedirectUri,
      onCancel,
      visible,
      userName,
      mailTime,
      currentUserId,
      editingUser,
      features,
      logout,
      consent,
      i18n,
      t
    } = this.props;
    const consenting = consent && features.includes("REQUIRE_CONSENT");

    let avatar;
    if (this.error) {
      avatar = <span style={{ color: "red" }}>{this.error}</span>;
    } else if (this.spinner) {
      avatar = <Spin size="large" />;
    } else if (this.newAvatar) {
      avatar = (
        <AvatarWrapper>
          <AvatarImg
            widthIsHigher={this.isImageWidthHigher}
            src={this.imageUrl}
          />
        </AvatarWrapper>
      );
    } else {
      avatar = (
        <Avatar size={48} src={this.imageUrl}>
          {nameToInitials(userName)}
        </Avatar>
      );
    }
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&scope=r_liteprofile&client_id=77l9sg7qlym5m9&redirect_uri=${linkedInRedirectUri}&state=${currentUserId}`;
    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        title="Your Settings"
        centered
        footer={[
          <Button key="cancel" onClick={onCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={this.loading}
            onClick={this.handleSubmit}
          >
            {t("save")}
          </Button>
        ]}
      >
        <PhoneVerification
          visible={this.phoneNumberOpen}
          onClose={this.closePhoneNumber}
        />
        <UserForm
          organization={{}}
          ref={(ref) => (this.formRef = ref)}
          inline
          features={features}
          editingUser={editingUser}
        />
        <Row
          gutter={12}
          justify="center"
          style={window.innerWidth < 500 && mobileRow}
        >
          <Column>{avatar}</Column>
          <Column>
            <span>{t("account_settings.set_an_avatar")}</span>
          </Column>
          <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
            <Upload
              name="avatar"
              listType="picture"
              showUploadList={false}
              action="null"
              beforeUpload={() => false}
              onChange={this.uploadProfileAvatar}
            >
              <Button
                style={{
                  marginBottom: window.innerWidth < 500 ? 12 : 36,
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Icon style={{ fontSize: 26, display: "flex" }} type="inbox" />
                {t("account_settings.upload_new_avatar")}
              </Button>
            </Upload>
          </Col>
          <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
            <a href={url}>
              <Button
                style={{
                  backgroundColor: "#0077B5",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: window.innerWidth < 500 && 24
                }}
              >
                <Icon
                  style={{ color: "white", fontSize: 26, display: "flex" }}
                  type="linkedin"
                />
                {t("account_settings.add_from_linkedin")}
              </Button>
            </a>
          </Col>
          {features.includes("TEXTING") && (
            <>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  marginBottom: 5
                }}
              >
                {t("account_settings.receive_feedback_notifications")}
              </Col>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 6
                }}
              >
                <Checkbox.Group
                  value={this.feedbackDelivery}
                  onChange={(val) => {
                    this.feedbackDelivery = val;
                  }}
                >
                  <Row>
                    <Col span={24}>
                      <Checkbox value="email">
                        {t("account_settings.email")}
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="text">
                        {t("account_settings.text_message")}
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="slack" disabled={!editingUser.slack_id}>
                        {t("account_settings.slack")}
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
                {/* <Select
                  value={this.feedbackDelivery}
                  style={{ width: '50%' }}
                  onChange={(val) => (this.feedbackDelivery = val)}
                >
                  <Select.Option value="email">Email</Select.Option>
                  <Select.Option value="text">Text Message</Select.Option>
                  <Select.Option value="both">Email and Text Message</Select.Option>
                </Select> */}
              </Col>
            </>
          )}
          {/* {this.feedbackDelivery.includes('slack')&& (
            <a
              href={`https://slack.com/oauth/v2/authorize?redirect_uri=${window.location.origin}/slack/oauth&scope=channels:manage,chat:write,chat:write.public,commands,groups:write,im:write&client_id=213794168000.1023775258578`}
            >
              <img
                alt="Add to Slack"
                height="40"
                width="139"
                src="https://platform.slack-edge.com/img/add_to_slack.png"
                srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
              />
            </a>
          )} */}
          {this.feedbackDelivery.includes("text") && (
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "12px 0"
              }}
            >
              {this.phoneNumber ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon
                    type="check-circle"
                    theme="filled"
                    style={{
                      color: "#40cc75",
                      marginRight: 6,
                      marginBottom: -2
                    }}
                  />
                  {this.phoneNumber}
                  <Icon
                    type="edit"
                    onClick={() => (this.phoneNumberOpen = true)}
                    style={{ fontSize: 18, marginBottom: -2, marginLeft: 6 }}
                  />
                </div>
              ) : (
                <Button onClick={this.openPhoneNumber}>
                  {t("account_settings.add_phone_number")}
                </Button>
              )}
            </Col>
          )}
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              marginBottom: 5
            }}
          >
            {t("account_settings.select_time")}
          </Col>
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <TimePicker
              onChange={this.setTime}
              use12Hours
              defaultValue={moment(mailTime, "hh:mm A")}
              disabledHours={() => [0]}
              format="hh:mm a"
              style={{ width: 200 }}
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              marginBottom: 5
            }}
          >
            {t("account_settings.language")}
          </Col>
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <Select
              style={{ width: 100 }}
              onChange={(val) => i18n.changeLanguage(val)}
              value={
                lngs.includes(i18n.language)
                  ? i18n.language
                  : i18n.language.split("-")[0]
              }
            >
              <Select.Option value="en">English</Select.Option>
              <Select.Option value="de">German</Select.Option>
              <Select.Option value="zh-CN">Chinese</Select.Option>
              <Select.Option value="fr">French</Select.Option>
              <Select.Option value="es">Spanish</Select.Option>
            </Select>
          </Col>
        </Row>

        {consenting && (
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              height: "50px"
            }}
          >
            <a
              role="presentation"
              onClick={() => {
                this.open = true;
              }}
            >
              {t("account_settings.privacy_policy")}
            </a>
          </div>
        )}
        <PrivacyPolicy
          isRevokeView={this.open}
          logout={logout}
          close={() => {
            this.open = false;
          }}
        />

        {/* <Button onClick={this.openPhoneNumber}>Add Phone Number</Button>
        <span>{this.phoneNumber}</span> */}
      </Modal>
    );
  }
}

AccountSettings.defaultProps = {
  linkedInCode: null,
  linkedInRedirectUri: null,
  editingUser: null
};

AccountSettings.propTypes = {
  logout: PropTypes.func.isRequired,
  consent: PropTypes.bool.isRequired,
  mailTime: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  linkedInCode: PropTypes.string,
  linkedInRedirectUri: PropTypes.string,
  updateUser: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  removeRedirect: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
  currentUserId: PropTypes.string.isRequired,
  feedbackDelivery: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  editingUser: PropTypes.object,
  features: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
};

export default withTranslation()(AccountSettings);
