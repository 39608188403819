import * as React from "react";
import { Cascader as AntCascader, Icon } from "antd";
import styled from "styled-components";
import { HabitUserLite } from "types/shared";
import { useTranslation } from "react-i18next";

type CascaderProps = {
  persistChanges: (habitIds: string[]) => void;
  allHabits: HabitUserLite[];
  selected: string[];
  dataCy?: string;
};

type CascaderOption = {
  label: string | React.ReactElement<"span">;
  value: string;
  children?: CascaderOption[];
};

const MenuTrigger = styled.span`
  &:focus {
    outline: none;
  }
`;

const TriggerText = styled.span`
  font-weight: 600;
  color: ${(props) => props.theme.primary_color};
  cursor: pointer;
`;

const generateOptions = (allHabits, selected, t): CascaderOption[] => {
  return [
    {
      label: t("relationships.all_habits"),
      value: "all"
    },
    {
      label: t("relationships.some_habits"),
      value: "some",
      children: allHabits.map((h) => ({
        label: (
          <span
            key={h.id}
            style={{
              color: selected.includes(h.id) && "green",
              fontWeight: "400"
            }}
          >
            <Icon
              type="check"
              style={{
                marginRight: 6,
                opacity: selected.includes(h.id) ? 1 : 0
              }}
            />
            {h.habit.name}
          </span>
        ),
        value: h.id
      }))
    },
    { label: t("relationships.no_habits"), value: "none" }
  ];
};

const getLabel = (
  allHabits: HabitUserLite[],
  selected: string[],
  t
): string => {
  if (allHabits.length > 0 && allHabits.length === selected.length) {
    return t("relationships.all_habits");
  }
  if (selected.length === 0) {
    return t("relationships.no_habits");
  }
  return `${selected.length} Habit${selected.length !== 1 ? "s" : ""}`;
};

const getStringValue = (
  allHabits: HabitUserLite[],
  selected: string[]
): string => {
  if (allHabits.length > 0 && allHabits.length === selected.length) {
    return "all";
  }
  if (selected.length > 0) {
    return "some";
  }
  return "none";
};

const Cascader = ({
  allHabits,
  selected,
  persistChanges,
  dataCy
}: CascaderProps) => {
  const [visible, setVisible] = React.useState(false);
  const [localSelected, setLocalSelected] = React.useState(selected);
  const { t } = useTranslation();

  React.useEffect(() => {
    setLocalSelected(selected);
  }, [selected]);

  const handleSelect = (value) => {
    const [strVal] = value;
    if (strVal === "all") {
      persistChanges(allHabits.map((h) => h.id));
      setVisible(false);
    } else if (strVal === "none") {
      persistChanges([]);
      setVisible(false);
    } else {
      const id = value[1];
      if (localSelected.includes(id)) {
        setLocalSelected(localSelected.filter((str) => str !== id));
      } else {
        setLocalSelected([...localSelected, id]);
      }
    }
  };

  const applyChanges = () => {
    persistChanges(localSelected);
    setVisible(false);
  };

  const label = getLabel(allHabits, selected, t);
  const stringValue = getStringValue(allHabits, selected);
  return (
    <AntCascader
      value={[stringValue]}
      options={generateOptions(allHabits, localSelected, t)}
      popupVisible={visible}
      onChange={handleSelect}
      getPopupContainer={() => document.getElementById("relationship-modal")}
      onBlur={applyChanges}
    >
      <MenuTrigger onClick={() => setVisible(true)}>
        <TriggerText data-cy={dataCy}>
          {label} <Icon type="down" />
        </TriggerText>
      </MenuTrigger>
    </AntCascader>
  );
};

Cascader.defaultProps = {
  dataCy: "selected-habits"
};

export default Cascader;
