/* eslint-disable no-unused-vars */

/* eslint-disable no-debugger */

/* eslint-disable jsx-a11y/media-has-caption */
// @flow

import React, { useState } from "react";
import { Modal, Divider, Input, Button, Icon } from "antd";
import theme from "assets/theme";
import { AdditionalFeedback } from "services/api";
import useStores from "utils/useStores";
import ConstructiveFeedback from "assets/constructive-feedback.jpg";
import LessonTile from "containers/OneOnOnes/lessonTile";
import {
  InfoContainer,
  ContentContainer,
  Header,
  TipList,
  AnswerContainer,
  TextAreaContainer,
  Before,
  Highlighted
} from "./styles";
import After from "./afterAnimation";

const { TextArea } = Input;

const mobile = window.innerWidth < 500;

type GiveMoreFeedbackProps = {
  open: boolean;
  close: () => void;
  feedback: any;
  updateFeedback: (response: number) => void;
  complete: () => void;
};

const GiveMoreFeedbackModal = ({
  open,
  close,
  feedback,
  updateFeedback,
  complete
}: GiveMoreFeedbackProps) => {
  const {
    profileStore: { currentUser }
  } = useStores();
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState("");
  const [answered, setAnswered] = useState(false);
  const [show, setShow] = useState(false);
  const [focused, setFocused] = useState(false);

  const handleChange = ({ target: { value } }) => {
    setAnswer(value);
  };

  const handleAnswerFeedback = async () => {
    const params = {
      additional_feedback: {
        answer
      }
    };
    if (answer.length > 0) {
      try {
        await AdditionalFeedback.answer(feedback.id, params);
        setAnswered(true);
      } catch (err) {
        const msg = JSON.parse(JSON.stringify(err)).response.data.error;
        setError(msg);
      }
    } else {
      setError("Answer cannot be empty");
    }
  };

  const handleAnimationEnd = () => {
    setShow(true);
  };

  const handleFollowUp = () => {
    updateFeedback(0.5);
  };

  const handleDecline = () => {
    updateFeedback(0);
  };

  const confirmDecline = () => {
    Modal.confirm({
      title: "Are you sure you want to decline to respond?",
      onOk: handleDecline
    });
  };

  const followUpButton = !answered
    ? [
        <Button
          data-cy="deline_to_respond-btn"
          onClick={confirmDecline}
          style={{ marginRight: "25px", color: "red" }}
          type="link"
        >
          Decline to Respond
        </Button>
      ]
    : null;

  const closeModal = answered ? complete : close;

  const buttonFunc = answered ? closeModal : handleAnswerFeedback;

  const replaceText = (text) =>
    // eslint-disable-next-line no-template-curly-in-string
    text
      .replace(/\${userName}/g, feedback.asker.name.split(" ")[0])
      .replace(/\${habitName}/g, feedback.habit);

  const footer = followUpButton ? (
    followUpButton.concat([
      <Button onClick={buttonFunc} type="primary">
        Submit Feedback
      </Button>
    ])
  ) : (
    <Button data-cy="dismiss-btn" onClick={buttonFunc} type="primary">
      Dismiss
    </Button>
  );

  const placeholderText =
    "Remember: \n• Stick to the prompt, answer it specifically as possible. \n• Frame examples of ways people can improve as ways to reach their potential. \n• Don't do a feedback sandwich, it doesn't work.";

  return (
    <Modal
      title="Rhabit helps you give effective feedback"
      centered
      width="100%"
      style={{ maxWidth: !mobile && 1000 }}
      visible={open}
      bodyStyle={{ padding: "16px 24px" }}
      onCancel={closeModal}
      maskClosable={false}
      footer={footer}
    >
      {answered ? (
        <After name={feedback.asker.name} />
      ) : (
        <>
          <InfoContainer>
            <ContentContainer>
              <p style={{ paddingRight: 24, fontSize: 16 }}>
                You&apos;ve agreed to give {feedback.asker.name} some extra
                feedback, and that&apos;s awesome.
                <br />
                {mobile && <br />}
                We know that giving feedback can be hard, so before you start,
                here&apos;s a walkthough that might help. <br /> <br />{" "}
                We&apos;ll launch it in a new browser tab, so you won&apos;t
                lose your place here.
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                <LessonTile
                  style={{ height: 125, width: 125 }}
                  img={ConstructiveFeedback}
                  link="https://learning.rhabitapp.com/constructive-feedback"
                  text="Giving Constructive Feedback"
                />
                <a
                  href="https://learning.rhabitapp.com/constructive-feedback"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Try Our Microcourse
                  <svg width="20px" height="20px" viewBox="0 0 20 20">
                    <g
                      id="external_link"
                      className="icon_svg-stroke"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      fill="none"
                      fillRule="evenodd"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="17 13.5 17 19.5 5 19.5 5 7.5 11 7.5"></polyline>
                      <path d="M14,4.5 L20,4.5 L20,10.5 M20,4.5 L11,13.5"></path>
                    </g>
                  </svg>
                </a>
              </div>
            </ContentContainer>
          </InfoContainer>
          <Divider />
          <Header style={{ display: "block" }}>
            <Highlighted style={{ lineHeight: "16px" }}>
              {feedback.habit}
            </Highlighted>
          </Header>
          <ul>
            {feedback.behaviors.map((b) => (
              <li>{b}</li>
            ))}
          </ul>
          <Header>{replaceText(feedback.prompt.question_text)}</Header>
          <AnswerContainer>
            <TextAreaContainer>
              <TextArea
                autoSize={{ minRows: 4 }}
                onChange={handleChange}
                name="answer"
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                value={answer.length || focused ? answer : placeholderText}
                style={{ marginTop: "12px", color: !focused && "#bfbfbf" }}
              />
              <div style={{ marginTop: "12px" }}>
                {feedback.asker.name.split(" ")[0]} will see your individual
                response and who wrote it.
              </div>
              {error && (
                <div style={{ marginTop: "20px", color: "red" }}>{error}</div>
              )}
            </TextAreaContainer>
          </AnswerContainer>
        </>
      )}
    </Modal>
  );
};

export default GiveMoreFeedbackModal;
