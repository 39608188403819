/* eslint-disable */
import * as Types from '../../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type IFetchUserRelationshipsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type IFetchUserRelationshipsQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & { relationships: Array<(
      { __typename?: 'Relationship' }
      & { relatedUser: (
        { __typename?: 'User' }
        & Pick<Types.IUser, 'id' | 'name' | 'avatar' | 'email' | 'title'>
      ), habitUsers: Array<(
        { __typename?: 'HabitUser' }
        & Pick<Types.IHabitUser, 'id'>
        & { habit: (
          { __typename?: 'Habit' }
          & Pick<Types.IHabit, 'name'>
        ) }
      )> }
    )> }
  ) }
);


export const FetchUserRelationshipsDocument = gql`
    query FetchUserRelationships($id: ID!) {
  user(id: $id) {
    relationships {
      relatedUser {
        id
        name
        avatar
        email
        title
      }
      habitUsers {
        id
        habit {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useFetchUserRelationshipsQuery__
 *
 * To run a query within a React component, call `useFetchUserRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserRelationshipsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchUserRelationshipsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IFetchUserRelationshipsQuery, IFetchUserRelationshipsQueryVariables>) {
        return ApolloReactHooks.useQuery<IFetchUserRelationshipsQuery, IFetchUserRelationshipsQueryVariables>(FetchUserRelationshipsDocument, baseOptions);
      }
export function useFetchUserRelationshipsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IFetchUserRelationshipsQuery, IFetchUserRelationshipsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IFetchUserRelationshipsQuery, IFetchUserRelationshipsQueryVariables>(FetchUserRelationshipsDocument, baseOptions);
        }
export type FetchUserRelationshipsQueryHookResult = ReturnType<typeof useFetchUserRelationshipsQuery>;
export type FetchUserRelationshipsLazyQueryHookResult = ReturnType<typeof useFetchUserRelationshipsLazyQuery>;
export type FetchUserRelationshipsQueryResult = ApolloReactCommon.QueryResult<IFetchUserRelationshipsQuery, IFetchUserRelationshipsQueryVariables>;