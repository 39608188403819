/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable prefer-const */
/* eslint-disable one-var */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import React from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { observable } from "mobx";
import LoadingOverlay from "components/LoadingOverlay";
import PercentChange from "components/PercentChange";
import { withTheme } from "styled-components";
import { Tooltip, Button, Divider } from "antd";
import {
  HeaderContainer,
  PercentContainer,
  BodyHeaderContainer,
  HabitScore,
  ChangeText,
  BehaviorTitle,
  HeaderTitle,
  BehaviorList
} from "./styles";
import HabitSelector from "./HabitSelector";
import { HomeCard } from "../styles";
import RadarChart from "./RadarChart";
import Behavior from "./Behavior";
import { alphabet } from "./alphabet";
import MoreFeedbackWizard from "../MoreFeedbackWizard";
import { withTranslation } from "react-i18next";

const mobile = window.innerWidth < 500;
@inject("profileStore")
@observer
class HabitOverview extends React.Component {
  @observable widgetFormOpen = false;
  @observable habitListOpen = false;
  @observable behaviorStyleList = {};
  @observable selected = null;
  @observable feedbackWizardOpen = false;

  componentWillReceiveProps(newProps) {
    console.log(this.props.profileStore.features());
    const { habit, selectBehavior } = this.props;
    if (newProps.habit.id !== habit.id) {
      this.setDot(null, "shrinkAll");
      this.selected = null;
      selectBehavior(this.selected);
    }
  }

  handleClose = () => {
    this.habitListOpen = false;
  };
  handleOpen = () => {
    this.habitListOpen = true;
  };

  setDot = (loc, action) => {
    const elements = document.querySelector(
      "#radarChart > div > div > svg > g > g"
    );
    if (!elements) {
      return;
    }
    const dots = elements.children[3].children;
    let dot, index;
    if (action === "shrinkAll") {
      Array.from(dots).forEach((d, i) => {
        d.children[0].style.transform = "scale(1)";
        d.children[1].style.transform = "scale(1)";
      });
      return null;
    }
    Array.from(dots).forEach((d, i) => {
      // if (d.textContent === (1 + loc).toString()) {
      if (d.textContent === alphabet[loc]) {
        dot = d.children;
        index = i;
      }
    });
    if (action === "shrink" && dot) {
      dot[0].style.transform = "scale(1)";
      dot[1].style.transform = "scale(1)";
    } else if (dot) {
      dot[0].style.transform = "scale(1)";
      dot[0].style.transition = "transform .25s";
      dot[0].style.transform = "scale(1.5)";
      dot[1].style.transform = "scale(1)";
      dot[1].style.transition = "transform .25s";
      dot[1].style.transform = "scale(1.5)";
    }
  };

  getDot = (loc) => {
    const {
      selectBehavior,
      habit: { behaviors }
    } = this.props;
    const b = behaviors;

    const that = this;
    if (loc === this.selected) {
      this.setDot(this.selected, "shrink");
      this.selected = null;
      selectBehavior(this.selected);
    } else {
      if (this.selected !== null && this.selected >= 0) {
        this.setDot(this.selected, "shrink");
      }
      this.selected = parseInt(loc);
      this.setDot(loc, "grow");
      debugger;

      selectBehavior(this.selected);
    }
  };

  handleCloseWizard = () => {
    const { habit, selectHabit } = this.props;
    this.feedbackWizardOpen = false;
    const habitUserId = window.location.pathname.split("/")[2];

    selectHabit({ key: habitUserId });
  };

  isMoreFeedbackDisabled = () => {
    const { habit } = this.props;
    let disable = false;
    if (habit.additional_feedback) {
      habit.additional_feedback.forEach((a) => {
        if (!a.feedback_answer.response) {
          disable = true;
        }
      });
    }
    return disable;
  };

  getMoreFeedbackButton = () => {
    const {
      t,
      habit: { has_outstanding_feedback }
    } = this.props;
    const habit = has_outstanding_feedback;
    debugger;
    let btn = (
      <div
        style={{
          position: !mobile && "absolute",
          bottom: "25px",
          display: "flex",
          width: mobile ? "100%" : "45%",
          justifyContent: "center",
          marginTop: mobile && "10px",
          marginBottom: "20px"
        }}
      >
        <Button
          disabled={habit}
          type="primary"
          onClick={() => (this.feedbackWizardOpen = true)}
        >
          {t("deep_dive.habit_overview.get_more_feedback")}
        </Button>
      </div>
    );

    if (window.location.pathname === "/manager_dashboard") {
      return <div />;
    }

    if (habit) {
      return (
        <Tooltip
          title={t("deep_dive.habit_overview.existing_request_open", { habit })}
        >
          {btn}
        </Tooltip>
      );
    }
    return btn;
  };

  render() {
    const {
      t,
      habit,
      loading,
      habits,
      selectHabit,
      colorDot,
      dashboardViewer,
      open,
      theme,
      profileStore,
      hideHeader
    } = this.props;
    let behaviorsList = [];
    if (habit.behaviors) {
      behaviorsList = habit.behaviors;
    }
    const bodyStyle = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: 0
    };

    const cardStyle = {
      minHeight: "500px",
      height: "60vH",
      overflowY: "auto"
    };
    let count = -1;
    return (
      <>
        <MoreFeedbackWizard
          habit={habit}
          visible={this.feedbackWizardOpen}
          close={this.handleCloseWizard}
        />
        <HomeCard id="strengths" style={cardStyle} bodyStyle={bodyStyle}>
          {loading && <LoadingOverlay />}
          {!hideHeader && (
            <HeaderContainer>
              <div>
                <HabitSelector
                  close={this.handleClose}
                  open={this.handleOpen}
                  visible={this.habitListOpen}
                  habits={habits}
                  name={habit.name}
                  habit={habit}
                  selectHabit={selectHabit}
                />
              </div>
            </HeaderContainer>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: mobile ? "column" : "row"
            }}
          >
            <RadarChart
              locked={habit.locked}
              behaviorsNeeded={
                3 - behaviorsList.filter((b) => !!b.score).length
              }
              shadeColor={() => colorDot(habit.score)}
              colorDot={colorDot}
              behaviors={behaviorsList}
              score={habit.score}
              getDot={this.getDot}
            />
            <div
              style={{
                width: mobile ? "100%" : "45%",
                padding: mobile && "0px 10px"
              }}
            >
              <BodyHeaderContainer>
                <PercentContainer>
                  {!habit.locked && (
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <HabitScore color={colorDot(habit.score)}>
                        {habit.score}%
                      </HabitScore>
                      <span style={{ fontSize: theme.font.subheader }}>
                        {t("deep_dive.habit_overview.positive_feedback")}
                      </span>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap"
                    }}
                  >
                    {habit.last_score !== habit.score && (
                      <>
                        <ChangeText change={habit.last_score - habit.score > 0}>
                          <PercentChange
                            oldPercent={habit.last_score}
                            currentPercent={habit.score}
                          />
                        </ChangeText>
                        <span style={{ fontSize: theme.font.subheader }}>
                          {t("deep_dive.habit_overview.from_last_week")}
                        </span>
                        <Divider type="vertical" style={{ height: "unset" }} />
                      </>
                    )}
                    {habit.company_average && (
                      <div>
                        <ChangeText change={habit.last_score - habit.score > 0}>
                          {habit.company_average}%
                        </ChangeText>
                        <span style={{ fontSize: theme.font.subheader }}>
                          {t("deep_dive.habit_overview.company_average")}
                        </span>
                      </div>
                    )}
                  </div>
                </PercentContainer>
              </BodyHeaderContainer>
              <div style={{ textAlign: "center" }}>
                <BehaviorTitle>
                  {t("deep_dive.habit_overview.behaviors")}
                </BehaviorTitle>
              </div>

              {behaviorsList.map((b, i) => {
                // if (typeof b.score === "number") {
                //   count += 1;
                // }
                return (
                  <Behavior
                    open={open}
                    behavior={b}
                    count={i}
                    unchangedIndex={i}
                    i={i}
                    colorDot={colorDot}
                    getDot={this.getDot}
                    selected={this.selected}
                    locked={habit.locked}
                  />
                );
              })}
              {profileStore.features().includes("ADDITIONAL_FEEDBACK") &&
                !dashboardViewer &&
                this.getMoreFeedbackButton()}
            </div>
          </div>
        </HomeCard>
      </>
    );
  }
}

HabitOverview.defaultProps = {
  habit: {
    behaviors: [],
    score: 0,
    response_count: 0,
    raters_count: 0,
    name: "",
    description: ""
  }
};

HabitOverview.propTypes = {
  profileStore: PropTypes.object.isRequired,
  open: PropTypes.func.isRequired,
  dashboardViewer: PropTypes.bool.isRequired,
  habit: PropTypes.shape({
    behaviors: PropTypes.arrayOf(PropTypes.object.isRequired),
    score: PropTypes.number,
    response_count: PropTypes.number,
    raters_count: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string
  }),
  loading: PropTypes.bool.isRequired,
  habits: PropTypes.array.isRequired,
  selectHabit: PropTypes.func.isRequired,
  selectBehavior: PropTypes.func.isRequired,
  colorDot: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired
};

export default withTranslation()(withTheme(HabitOverview));
