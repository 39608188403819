import styled from "styled-components";
import { Tabs } from "antd";

export const AvatarWrapper = styled.div`
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background: #00a74f;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc(50% - 45px);
  top: -45px;
  padding-right: 6px;
`;

export const SuperSecretButton = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 50%;
  z-index: 999;
  bottom: 135px;
  right: 395px;
  cursor: pointer;
  position: absolute;
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
`;