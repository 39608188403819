import React from "react";
import PropTypes from "prop-types";
import { Input, Form, Checkbox, Button, Modal } from "antd";
import { observer, inject } from "mobx-react";
import { observable } from "mobx";
import UserSearch from "components/UserSearch";
import { Highfive } from "services/api";
import LoadingOverlay from "components/LoadingOverlay";
import { withTranslation } from "react-i18next";

// const { Option } = Select;
const { TextArea } = Input;
// const nameFilter = (input, { props: { children } }) =>
//   children.toLowerCase().includes(input.toLowerCase());

@inject("profileStore")
@observer
class SendHighfive extends React.Component {
  @observable users = [];
  @observable loading = false;

  // componentWillMount() {
  //   User.all().then(({ data }) => (this.users = data));
  // }

  handleSubmit = (e) => {
    const { handleSubmit, form, onClose, onSuccess } = this.props;

    e.preventDefault();
    form.validateFields((err) => {
      if (err) {
        return;
      }
      this.loading = true;
      if (handleSubmit) {
        handleSubmit(form.getFieldsValue()).then(() => {
          this.loading = false;
          form.resetFields();
        });
      } else {
        Highfive.create(form.getFieldsValue()).then(({ data }) => {
          this.loading = false;
          if (onSuccess) {
            onSuccess();
          }
          onClose(data);
          form.resetFields();
        });
      }
    });
  };
  componentWillUnmount() {
    const { form } = this.props;
    form.setFieldsValue({ recipient_ids: [] });
  }

  componentDidMount() {
    const { selectedUsers, form } = this.props;

    form.setFieldsValue({ recipient_ids: selectedUsers.map((s) => s.id) });
  }

  render() {
    const {
      form,
      selectedUsers,
      visible,
      onClose,
      t,
      profileStore: {
        currentUser: { id }
      }
    } = this.props;
    console.log(form.getFieldsValue());
    return (
      <Modal
        visible={visible}
        title={t("highfive_form.title")}
        destroyOnClose
        onCancel={() => {
          form.setFieldsValue({ recipient_ids: [] });

          onClose();
        }}
        footer={[
          <Button key="cancel" onClick={() => onClose()}>
            {t("cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleSubmit}>
            {t("submit")}
          </Button>
        ]}
      >
        {this.loading && <LoadingOverlay />}
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            <span>{t("highfive_form.recipients")}</span>
            {form.getFieldDecorator("recipient_ids", {
              rules: [
                {
                  required: true,
                  message: t("highfive_form.recipients_required")
                }
              ]
            })(
              <UserSearch
                id="highfive-search"
                currentUserId={id}
                initialValue={selectedUsers}
                onSelect={(recipient_ids) =>
                  form.setFieldsValue({ recipient_ids })
                }
              />
              // <Select
              //   filterOption={nameFilter}
              //   placeholder="Select recipients"
              //   mode="multiple"
              // >
              //   {this.users
              //     .sort((a, b) => (a.name > b.name ? 1 : -1))
              //     .map((u) => (
              //       <Option key={u.id}>{u.name}</Option>
              //     ))}
              // </Select>
            )}
          </Form.Item>
          <Form.Item>
            {form.getFieldDecorator("content", {
              rules: [
                {
                  required: true,
                  message: t("highfive_form.content_required")
                }
              ]
            })(<TextArea placeholder={t("highfive_form.placeholder")} />)}
          </Form.Item>
          <Form.Item>
            {form.getFieldDecorator(
              "private",
              {}
            )(<Checkbox>{t("highfive_form.make_private")}</Checkbox>)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

SendHighfive.defaultProps = {
  visible: false,
  selectedUsers: []
};

SendHighfive.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.any.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array,
  profileStore: PropTypes.object.isRequired
};

export default Form.create()(withTranslation()(SendHighfive));
