import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding-top: 24px;
  flex-direction: column;
  transition: height 0.2s;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ItemWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 40px;
  margin-bottom: 12px;
  justify-content: space-between;
`;

export const AddingList = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 35%;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const FormWrapper = styled.div`
  width: 100%;
  padding: 12px 24px;
  background: #f2f2f2;
  border: 1px solid #eee;
  border-radius: 4px;
  margin-top: 12px;
`;

export const HeaderText = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
`;