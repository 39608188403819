import React from "react";
import { Form, Icon, Input, Button, Row, Col } from "antd";
import { observer } from "mobx-react";
import { observable } from "mobx";
import ForgotPasswordModal from "components/ForgotPasswordModal";
import PropTypes from "prop-types";

const FormItem = Form.Item;

function checkErrors(values) {
  if (
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      values.email
    )
  ) {
    return false;
  }
  return true;
}

@observer
class SignInForm extends React.Component {
  @observable forgotPasswordOpen = false;

  static propTypes = {
    form: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
  };

  handleSubmit = (e) => {
    const { form, submit } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        submit(values);
      }
    });
  };

  openForgotModal = () => {
    this.forgotPasswordOpen = true;
  };

  render() {
    const {
      loading,
      form: { getFieldDecorator, getFieldsValue }
    } = this.props;

    return (
      <Form
        onSubmit={this.handleSubmit}
        className="login-form"
        style={{ width: "100%" }}
      >
        {this.forgotPasswordOpen && (
          <ForgotPasswordModal
            open={this.forgotPasswordOpen}
            onClose={() => (this.forgotPasswordOpen = false)}
          />
        )}
        <FormItem>
          {getFieldDecorator("email", {
            rules: [{ required: true, message: "Please a valid email" }]
          })(
            <Input
              size="large"
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Email"
            />
          )}
        </FormItem>
        {/* <FormItem>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </FormItem> */}
        <Row style={{ display: "flex" }}>
          <Col span={24}>
            <Button
              size="large"
              style={{ width: "100%" }}
              disabled={checkErrors(getFieldsValue())}
              loading={loading}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              data-testid="logIn"
            >
              Send Magic Link
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignInForm);

export default WrappedNormalLoginForm;
