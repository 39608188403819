import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Badge, Tooltip } from "antd";
import Wrapper from "./styles";

const networkHealthContent = (sentences) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "15px 0",
      margin: "20px 0px"
    }}
  >
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ paddingLeft: 15 }}>
        {sentences.habits.length > 0 && (
          <div>
            <span style={{ fontWeight: "bold" }}>Habits:</span>
            <ul>
              {sentences.habits.map((s) => (
                <li key={s}>{s}</li>
              ))}
            </ul>
          </div>
        )}
        {sentences.too_few.length > 0 && (
          <div>
            <span style={{ fontWeight: "bold" }}>
              Habits with too few feedback providers:
            </span>
            <ul>
              {sentences.too_few.map((s) => (
                <li key={s}>{s}</li>
              ))}
            </ul>
          </div>
        )}
        {sentences.too_many.length > 0 && (
          <div>
            <span style={{ fontWeight: "bold" }}>
              Habits with too many feedback providers:
            </span>
            <ul>
              {sentences.too_many.map((s) => (
                <li key={s.id}>{s}</li>
              ))}
            </ul>
          </div>
        )}
        {sentences.targets.length > 0 && (
          <div>
            <span style={{ fontWeight: "bold" }}>Recipients</span>
            <ul>
              {sentences.targets.map((s) => (
                <li key={s}>{s}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  </div>
);

const Actions = ({
  openSendHighfive,
  openAssignGoal,
  goToReports,
  viewFeedbackNetwork,
  networkHealth,
  openNetworkSetup,
  actions
}) => (
  <Wrapper>
    {actions.includes("highfive") && (
      <Button
        onClick={openSendHighfive}
        style={{ marginRight: 24, marginBottom: window.innerWidth < 500 && 6 }}
        icon="like"
      >
        Give High Five
      </Button>
    )}
    {actions.includes("assignGoal") && (
      <Button
        onClick={openAssignGoal}
        style={{ marginRight: 24, marginBottom: window.innerWidth < 500 && 6 }}
        icon="flag"
      >
        Assign Goal
      </Button>
    )}
    {actions.includes("reports") && (
      <Button
        onClick={goToReports}
        style={{ marginRight: 24, marginBottom: window.innerWidth < 500 && 6 }}
        icon="solution"
      >
        Reports
      </Button>
    )}
    {actions.includes("feedbackNetwork") && (
      <Button
        style={{ marginRight: 24, marginBottom: window.innerWidth < 500 && 6 }}
        onClick={viewFeedbackNetwork}
        icon="deployment-unit"
      >
        Feedback Network
      </Button>
    )}
    {actions.includes("networkHealth") && (
      <>
        {networkHealth.errors.severe > 0 || networkHealth.errors.warning > 0 ? (
          <Tooltip title={networkHealthContent(networkHealth.sentences)}>
            <Badge count="!" offset={[-25, 0]}>
              <Button
                onClick={openNetworkSetup}
                style={{
                  marginRight: 24,
                  marginBottom: window.innerWidth < 500 && 6
                }}
              >
                <Icon type="deployment-unit" />
                Manage Feedback Circle
              </Button>
            </Badge>
          </Tooltip>
        ) : (
          <Button
            onClick={openNetworkSetup}
            style={{
              marginRight: 24,
              marginBottom: window.innerWidth < 500 && 6
            }}
          >
            <Icon type="deployment-unit" />
            Manage Feedback Circle
          </Button>
        )}
      </>
    )}
    {/*
    <Button
     type="danger"
     icon={userIsActive ? 'close-circle' : 'check-circle'}
     style={{ marginRight: 24, marginBottom: window.innerWidth < 500 && 6 }}
     onClick={() =>
       Modal.confirm({
         title: 'Confirm',
         content: `Are you sure you want to ${
           userIsActive ? 'de' : ''
         }activate this user?`,
         onOk: () => updateUserActivity(),
         onCancel: () => {}
       })
     }
    >
     {userIsActive ? 'Deactivate' : 'Activate'}
    </Button>
    {currentUserIsAdmin && (
     <Button icon="edit" style={{ marginRight: 24, marginBottom: window.innerWidth < 500 && 6 }} onClick={openEditUser}>
       Edit
     </Button>
    )} */}
  </Wrapper>
);

Actions.propTypes = {
  openSendHighfive: PropTypes.func.isRequired,
  openAssignGoal: PropTypes.func.isRequired,
  goToReports: PropTypes.func.isRequired,
  viewFeedbackNetwork: PropTypes.func.isRequired,
  networkHealth: PropTypes.object.isRequired,
  openNetworkSetup: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired
};

export default Actions;
