import React, { useState, useEffect } from "react";
import { List, Checkbox, Typography } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { User } from "../../../services/api";
import UserAvatar from "../../../components/UserAvatar";

const SelectUser = ({ onSelect, teamId, selected, setAll }) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    User.byTeam(teamId).then(({ data }) => {
      setUsers(data);
    });
  }, []);

  const allChecked = selected.length === users.length;

  return (
    <>
      <Typography.Title level={4}>
        Choose one or more team members:
      </Typography.Title>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: 20
        }}
      >
        <Typography>{selected.length} selected</Typography>
        <Checkbox
          onClick={() => (allChecked ? setAll([]) : setAll(users))}
          checked={allChecked}
        />
      </div>
      <List
        style={{
          width: "100%",
          maxHeight: "370px",
          overflowY: "auto",
          borderTop: "1px solid #e8e8e8"
        }}
        itemLayout="horizontal"
        dataSource={users}
        renderItem={(item) => (
          <List.Item
            style={{ paddingRight: 12 }}
            actions={[
              <Checkbox
                onClick={() => onSelect(item)}
                checked={selected.find((s) => s.id === item.id)}
              />
            ]}
          >
            <List.Item.Meta
              avatar={<UserAvatar avatar={item.avatar} name={item.name} />}
              title={item.name}
              description={`${item.title} - last one-on-one: ${
                item.last_one_on_one
                  ? moment(item.last_one_on_one).format("MM/DD/YY")
                  : "never"
              }`}
            />
          </List.Item>
        )}
      />
    </>
  );
};

SelectUser.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  teamId: PropTypes.string.isRequired,
  setAll: PropTypes.string.isRequired
};

export default SelectUser;
