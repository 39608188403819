import React, { useState, useEffect, useMemo } from "react";
import viewModal from "components/FullScreenModal";
import Box from "layout/Box";
import {
  Typography,
  Menu,
  Divider,
  Button,
  Tooltip,
  Select,
  Row,
  Col,
  Icon,
  Modal
} from "antd";
import LoadingOverlay from "components/LoadingOverlay";
import { IHabitCategory } from "shared/types";
import { Desktop, Default, LaptopOrMobile } from "containers/Responsive";
import { useMediaQuery } from "react-responsive";
import useStores from "utils/useStores";
import { useTranslation } from "react-i18next";
import { UserLite } from "types/shared";
import { useFetchAllHabitsQuery } from "./queries/fetchAllHabits.generated";
import HabitListItem from "./HabitListItem";
import {
  useFetchUserHabitsQuery,
  IFetchUserHabitsQuery
} from "./queries/fetchUserHabits.generated";
import { StyledCol, GridItem, Header } from "./styles";
import Category from "./Category";
import { CATEGORIES } from "./constants";
import CurrentlySelectedHabits from "./CurrentlySelectedHabits";
import ChangeModal from "./ChangeModal";
import { useUpdateUserHabitsMutation } from "./mutations/updateUserHabits.generated";
import UpdateRelationships from "./UpdateRelationships";

const TECHNICAL = "technical";

export type Changes = {
  added: IFetchUserHabitsQuery["user"]["habits"];
  removed: IFetchUserHabitsQuery["user"]["habits"];
  unChanged: IFetchUserHabitsQuery["user"]["habits"];
};
const HabitPicker: React.FC<{
  closeModal: () => void;
  managedUser?: UserLite;
  callback: () => void;
}> = ({ closeModal, managedUser, callback }) => {
  const { profileStore } = useStores();
  const [localHabits, setLocalHabits] = useState<
    IFetchUserHabitsQuery["user"]["habits"]
  >([]);
  const [originalHabits, setOriginalHabits] = useState<
    IFetchUserHabitsQuery["user"]["habits"]
  >([]);
  const [selectedKeys, setSelectedKeys] = useState([TECHNICAL]);
  const [changeModalOpen, setChangeModalOpen] = useState(false);
  const [
    expandedCategory,
    setExpandedCategory
  ] = useState<null | IHabitCategory>(null);
  const [
    transitioningCategory,
    setTransitioningCategory
  ] = useState<null | IHabitCategory>(null);
  const [submitted, setSubmitted] = useState(false);
  const [hasSetHabits, setHasSetHabits] = useState(false);
  const isSingleColumn = useMediaQuery({ maxWidth: 1200 });
  const enableOverflow = useMediaQuery({ maxWidth: 1600 });
  const [updateUserHabits] = useUpdateUserHabitsMutation();
  const { t } = useTranslation();

  const { loading, data } = useFetchAllHabitsQuery({
    variables: { category: selectedKeys[0] }
  });

  const id = managedUser ? managedUser.id : profileStore.currentUser.id;

  const {
    loading: userHabitsLoading,
    data: userHabits
  } = useFetchUserHabitsQuery({
    variables: { id }
  });

  useEffect(() => {
    if (userHabits && !hasSetHabits) {
      setLocalHabits(userHabits.user.habits);
      setOriginalHabits(userHabits.user.habits);
      setHasSetHabits(true);
    }
  }, [userHabits, hasSetHabits]);

  const getChanges = (
    local: IFetchUserHabitsQuery["user"]["habits"],
    originals: IFetchUserHabitsQuery["user"]["habits"]
  ): Changes => {
    const changes: Changes = {
      added: [],
      removed: [],
      unChanged: []
    };
    localHabits.forEach((habit) => {
      if (!originals.find((uh) => uh.id === habit.id)) {
        changes.added.push(habit);
      } else {
        changes.unChanged.push(habit);
      }
    });
    originals.forEach((habit) => {
      if (!localHabits.find((uh) => uh.id === habit.id)) {
        changes.removed.push(habit);
      }
    });

    return changes;
  };

  const changes = useMemo(() => getChanges(localHabits, originalHabits), [
    localHabits,
    originalHabits
  ]);

  const submitChanges = async (): Promise<void> => {
    await updateUserHabits({
      variables: {
        input: {
          userId: id,
          added: changes.added.map((c) => c.id),
          removed: changes.removed.map((c) => c.id)
        }
      }
    });
    setSubmitted(true);
  };

  const addHabit = (habitId: string): void => {
    if (!data) {
      return;
    }
    const habit = data.habits.nodes.find((h) => h.id === habitId);

    if (habit) {
      setLocalHabits([...localHabits, habit]);
    }
  };
  const removeHabit = (habitId: string): void => {
    setLocalHabits(localHabits.filter((h) => h.id !== habitId));
  };

  const openCategory = (name: IHabitCategory): void => {
    if (expandedCategory) {
      return;
    }
    setExpandedCategory(name);
    setTransitioningCategory(name);
  };

  const closeCategory = (): void => {
    setExpandedCategory(null);
    setTimeout(() => {
      setTransitioningCategory(null);
    }, 200);
  };
  const spans = { xxl: 12, xl: 12, lg: 24, md: 24, sm: 24, xs: 24 };

  const confirmClose = () => {
    if (changes.added.length > 0 || changes.removed.length > 0) {
      Modal.confirm({
        title: "Are you sure you want to close?",
        content: "You have unsaved changes that will be discarded on close.",
        okText: "Discard Changes And Close",
        onOk: closeModal,
        okButtonProps: { type: "danger" }
      });
    } else {
      closeModal();
    }
  };

  const handleClose = () => {
    profileStore.fetchUpdatedHabits();
    if (callback) {
      callback();
    }
    closeModal();
  };

  if (submitted) {
    return (
      <Box
        display="flex"
        width="100%"
        flexDirection="column"
        position="relative"
      >
        <UpdateRelationships
          newHabits={changes.added}
          onClose={handleClose}
          managedUser={managedUser}
        />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      width="100%"
      height="100vh"
      flexDirection="column"
      overflowY={enableOverflow ? "auto" : "hidden"}
      position="relative"
    >
      <ChangeModal
        visible={changeModalOpen}
        onCancel={() => setChangeModalOpen(false)}
        onOk={submitChanges}
        changes={changes}
      />
      <Icon
        type="close"
        style={{ position: "absolute", top: 12, right: 12, fontSize: 18 }}
        onClick={confirmClose}
      />
      <Header>
        <Box width={["100%", "100%", "100%", "50%", "50%", "50%"]} padding={24}>
          {t("habit_picker.description")}
        </Box>
        <Box
          width={["100%", "100%", "100%", "50%", "50%", "50%"]}
          paddingRight={isSingleColumn ? 0 : 24}
        >
          <CurrentlySelectedHabits
            selectedHabits={localHabits}
            changes={changes}
            removeHabit={removeHabit}
            saveChanges={(): void => setChangeModalOpen(true)}
            hasChanges={changes.added.length > 0 || changes.removed.length > 0}
          />
        </Box>
      </Header>
      <Desktop>
        <Box
          padding="0 24px 48px 48px"
          width="100%"
          height="calc(100% - 75px)"
          maxHeight="calc(100% - 75px)"
          overflowY="auto"
        >
          <Row
            gutter={[24, 24]}
            style={{ width: "100%", height: "100%", position: "relative" }}
          >
            {CATEGORIES.map((category) => (
              <StyledCol
                // span={expandedCategory === category.name ? 24 : 12}
                {...spans}
                expanded={expandedCategory === category.name}
                anyExpanded={!!expandedCategory}
                onClick={(): void => openCategory(category.name)}
                style={{
                  ...category.style,
                  width: expandedCategory === category.name && "100%",
                  zIndex: transitioningCategory === category.name ? 101 : 100
                }}
              >
                <Category
                  expanded={expandedCategory === category.name}
                  category={category.name}
                  color={category.color}
                  closeCategory={closeCategory}
                  addHabit={addHabit}
                  removeHabit={removeHabit}
                  description={category.description}
                  habits={
                    data
                      ? data.habits.nodes.filter(
                          (h) => h.category === category.name
                        )
                      : []
                  }
                  selectedHabits={localHabits.filter(
                    (h) => h.category === category.name
                  )}
                />
              </StyledCol>
            ))}
          </Row>
        </Box>
      </Desktop>
      <LaptopOrMobile>
        <Box padding={isSingleColumn ? "0" : "0 24px 48px 48px"} width="100%">
          <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
            {/* <Row
            gutter={[24, 24]}
            style={{
              width: "100%",
              position: "relative"
            }}
          > */}
            {CATEGORIES.map((category) => (
              <GridItem
                expanded={expandedCategory === category.name}
                onClick={(): void => openCategory(category.name)}
              >
                {/* <StyledCol
                // span={expandedCategory === category.name ? 24 : 12}
                {...spans}
                expanded={expandedCategory === category.name}
                anyExpanded={!!expandedCategory}
                onClick={(): void => openCategory(category.name)}
                style={{
                  ...category.style,
                  // height: window.innerWidth < 1600 && 400,
                  width: expandedCategory === category.name && "100%",
                  zIndex: transitioningCategory === category.name ? 101 : 100
                }}
              > */}
                <Category
                  expanded={expandedCategory === category.name}
                  category={category.name}
                  color={category.color}
                  closeCategory={closeCategory}
                  addHabit={addHabit}
                  removeHabit={removeHabit}
                  description={category.description}
                  habits={
                    data
                      ? data.habits.nodes.filter(
                          (h) => h.category === category.name
                        )
                      : []
                  }
                  selectedHabits={localHabits.filter(
                    (h) => h.category === category.name
                  )}
                />
                {/* </StyledCol> */}
              </GridItem>
            ))}
            {/* </Row> */}
          </Box>
        </Box>
      </LaptopOrMobile>
    </Box>
  );
};

const openHabitPicker = (
  managedUser?: UserLite,
  callback?: () => void
): void => {
  viewModal(
    {
      maskClosable: false,
      closable: false,
      width: window.innerWidth < 1600 ? "95vw" : "75vw",
      bodyStyle: { padding: 0 },
      domId: "habit-picker"
    },
    null,
    <HabitPicker managedUser={managedUser} callback={callback} />
  );
};

export default openHabitPicker;
