import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";

const Label = styled.span`
  color: rgba(0, 0, 0, 0.85);
  font-size: ${({ large }) => (large ? "18px" : "14px")};
  margin-bottom: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  width: 100%;
`;

const SummaryBlock = styled.div`
  width: 100%;
  max-height: 650px;
  overflow-y: auto;
  background-color: #f5f5f5;
  flex-direction: column;
  display: flex;
  border-radius: 6px;
  padding: 12px 24px;
`;

const Value = styled.div`
  margin-bottom: 12px;
`;

const Review = ({
  params,
  keyResults,
  owner,
  stakeholders,
  parent,
  libraryMode
}) => {
  return (
    <Wrapper>
      <Label large>Review: </Label>
      <SummaryBlock>
        <Label>Name:</Label>
        <Value>{params.name}</Value>
        <Label>Description:</Label>
        <Value>{params.description}</Value>
        <Label>Key results:</Label>
        <Value>
          <ul style={{ paddingLeft: 18 }}>
            {keyResults
              .filter((kr) => kr.length)
              .map((kr) => (
                <li>{kr}</li>
              ))}
          </ul>
        </Value>
        {!libraryMode && (
          <>
            <Label>Dates:</Label>
            <Value>
              {moment(params.start_date).format("LL")} to{" "}
              {moment(params.delivery_date).format("LL")}
            </Value>
            <Label>Part of:</Label>
            <Value>{parent ? parent.name : ""}</Value>
            <Label>Owner: </Label>
            <Value>{owner.name}</Value>
            <Label>Stakeholders: </Label>
            <Value>
              <ul style={{ paddingLeft: 18 }}>
                {stakeholders.map((stakeholder) => (
                  <li>{stakeholder.name}</li>
                ))}
              </ul>
            </Value>
          </>
        )}
      </SummaryBlock>
    </Wrapper>
  );
};

Review.defaultProps = {
  libraryMode: false
};

Review.propTypes = {
  params: PropTypes.object.isRequired,
  keyResults: PropTypes.array.isRequired,
  owner: PropTypes.object.isRequired,
  stakeholders: PropTypes.array.isRequired,
  libraryMode: PropTypes.bool,
  parent: PropTypes.object.isRequired
};

export default Review;
