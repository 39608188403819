import React from "react";
import PropTypes from "prop-types";
import { List, Avatar } from "antd";
import moment from "moment";
import nameToInitials from "utils/nameToInitials";

const NoteItem = ({ creator, createdAt, content }) => (
  <List.Item style={{ flexDirection: "column", alignItems: "flex-start" }}>
    <List.Item.Meta
      avatar={
        <Avatar src={creator.avatar}>{nameToInitials(creator.name)}</Avatar>
      }
      title={`${creator.name} left a note`}
      description={moment(createdAt).format("MMM Do YY")}
    />
    {content}
  </List.Item>
);

NoteItem.propTypes = {
  creator: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

export default NoteItem;
