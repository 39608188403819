import React from "react";
import { Modal, Input, Button } from "antd";
import { observer } from "mobx-react";
import { observable } from "mobx";
import PropTypes from "prop-types";
import { Profile } from "services/api";

@observer
class ForgotPasswordModal extends React.Component {
  @observable input = "";
  @observable submitted = false;
  @observable errors = false;
  @observable loading = false;

  validateEmail() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.input).toLowerCase());
  }

  handleSubmit = () => {
    if (this.input && this.validateEmail()) {
      this.loading = true;
      Profile.sendResetEmail(this.input).then(() => (this.submitted = true));
    } else {
      this.errors = true;
    }
  };

  render() {
    const { onClose, open } = this.props;
    let buttons;
    if (this.submitted) {
      buttons = [
        <Button key="close" onClick={onClose}>
          Close
        </Button>
      ];
    } else {
      buttons = [
        <Button onClick={onClose} key="cancel">
          Cancel
        </Button>,
        <Button onClick={this.handleSubmit} type="primary" key="submit">
          Send Password Reset Email
        </Button>
      ];
    }
    return (
      <Modal
        title="Forgot Password?"
        visible={open}
        footer={buttons}
        onCancel={onClose}
      >
        {!this.submitted ? (
          <div>
            <span>
              Enter your email address below to receive a password reset link.
            </span>
            <Input
              value={this.input}
              onChange={(e) => (this.input = e.target.value)}
              placeholder="Email Address"
            />
            {this.errors && (
              <span style={{ color: "red" }}>
                Please enter a valid email address
              </span>
            )}
          </div>
        ) : (
          <p>
            You should receive a password reset email shortly. You may close
            this window.
          </p>
        )}
      </Modal>
    );
  }
}

ForgotPasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.bool.isRequired
};

export default ForgotPasswordModal;
