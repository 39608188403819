import styled from "styled-components";
import { media } from "utils/style-utils";

export const BehaviorList = styled.div`
  padding: 12px 24px;
`;

export const BodyHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
  position: relative;
`;

export const PercentContainer = styled.div`
  width: 100%;
  margin-right: 25px;
  position: absolute;
  left: -105%;
  top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${media.handheld`
  left: 0px;
  top: -400px;
  `}
`;

export const HabitScore = styled.span`
  font-size: ${({
  theme
}) => `${theme.font.pointOfInterest}px`};
  color: ${props => props.color};
  margin-right: 5px;
`;

export const ChangeText = styled.span`
  margin-right: 5px;
  color: ${props => props.change ? 'rgb(255, 146, 49)' : '#118337'};
  font-size: ${({
  theme
}) => `${theme.font.subheader}px`};
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 33%;
  justify-content: flex-start;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  padding: 15px;
  padding-left: 25px;
  height: 58px;
  ${media.handheld`
      width: 100%;
          `}
`;

export const BehaviorTitle = styled.span`
  font-size: ${({
  theme
}) => `${theme.font.subheader}px`};
  text-decoration: underline;
  lineheight: 1.5;
`;

export const HeaderTitle = styled.span`
  margin-right: 15px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: ${({
  theme
}) => `${theme.font.header}px`};
`;