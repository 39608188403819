/* eslint-disable no-multi-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import { observer, inject } from "mobx-react";
import { observable } from "mobx";
import { withTheme } from "styled-components";
import Joyride, { EVENTS } from "react-joyride";

import demoData from "containers/HomePage/demoData";
import { Profile } from "services/api";
import Spring from "assets/spring.png";
import HabitTrend from "./HabitTrend";
import ActionsAndInfo from "./ActionsAndInfo";
import HabitOverview from "./HabitOverview";
import Improvement from "./Improvement";
import ImprovementModal from "./HabitOverview/ImprovementModal";
import { Body } from "./styles";
import { withTranslation } from "react-i18next";

const mobile = window.innerWidth < 500;

@inject("profileStore", "tutorialStore")
@observer
class DeepDive extends React.Component {
  @observable habit = {};
  @observable demo = false;
  @observable loading = true;
  @observable dashboardViewer = false;
  @observable behaviorSelected = null;
  @observable visible = false;
  @observable clickTriggered = false;
  @observable demoNotAllowScroll = true;
  @observable containerSize = {};

  static propTypes = {
    theme: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    profileStore: PropTypes.shape({
      isDashboardViewer: PropTypes.bool.isRequired,
      currentUser: PropTypes.shape({
        habits: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
        id: PropTypes.string.isRequired,
        organization: PropTypes.shape({
          features: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
        })
      }).isRequired,
      setCurrentHabitId: PropTypes.func.isRequired,
      currentHabitId: PropTypes.string.isRequired,
      theme: PropTypes.shape({
        primary_color: PropTypes.string.isRequired
      })
    }).isRequired
  };

  constructor(props) {
    super(props);
    this.demoRef = React.createRef();
    if (props.profileStore.isDashboardViewer) {
      this.dashboardViewer = true;
    }
    const habitUserId = window.location.pathname.split("/")[2];
    if (habitUserId === "demo") {
      this.demo = true;
      [this.habit] = demoData.habits;
      this.loading = false;
    } else if (habitUserId) {
      this.selectHabit({ key: habitUserId });
    } else {
      this.selectHabit({ key: props.profileStore.currentHabitId });
    }
  }

  componentWillUnmount() {
    const { profileStore } = this.props;
    if (!profileStore.isDashboardViewer && profileStore.currentUser) {
      const habit = profileStore.currentUser.habits.find(
        (h) => h.habit_user_id === profileStore.currentHabitId
      );

      if (habit) {
        habit.has_unseen_feedback = false;
      }
    }
  }

  componentDidMount() {
    const { tutorialStore, profileStore } = this.props;

    const habit = profileStore.currentUser.habits.find(
      (h) => h.habit_user_id === profileStore.currentHabitId
    );

    if (tutorialStore.shouldShowLockedTutorial(habit)) {
      tutorialStore.runTutorial("feedback_locked");
    } else if (tutorialStore.showShowDeepDiveTutorial()) {
      tutorialStore.runTutorial("how_to_interpret_feedback");
    }
  }

  colorDot = (e) => {
    const {
      theme: {
        habitDeepDive: {
          rating: { onTrack, needsWork, excellent }
        }
      }
    } = this.props;
    if (!e && typeof e !== "number") {
      return null;
    }
    const num = typeof e === "number" ? e : e.value;
    if (num > 89 && num <= 100) {
      return excellent;
    }
    if (num > 64 && num <= 89) {
      return onTrack;
    }
    if (num >= 0 && num <= 64) {
      return needsWork;
    }
    return needsWork;
  };

  updateHabit = (key) => {
    Profile.fetchHabit(key, "two_years").then(({ data }) => {
      this.habit = data;
      this.loading = false;
    });
  };

  selectHabit = ({ key }) => {
    const { profileStore } = this.props;
    const habit = profileStore.currentUser.habits.find(
      (h) => h.habit_user_id === profileStore.currentHabitId
    );

    if (habit) {
      habit.has_unseen_feedback = false;
    }
    profileStore.setCurrentHabitId(key);
    this.loading = true;
    Profile.fetchHabit(key, "two_years").then(({ data }) => {
      this.habit = data;
      this.loading = false;
    });
  };

  selectBehavior = (b) => {
    this.behaviorSelected = b;
  };

  resetContainers = () => {
    document.body.style.overflow = "scroll";
    document.getElementById("content-body").style.height = "100%";
  };

  morphContainers = () => {
    this.containerSize.body = document.body.style.overflow;
    this.containerSize.contentBody = document.getElementById(
      "content-body"
    ).style.height;
    document.body.style.overflow = "visible";
    document.getElementById("content-body").style.height = "auto";
  };

  callback = (tour) => {
    const { tutorialStore } = this.props;
    if (tour.index === 4 && !this.clickTriggered) {
      this.demoClickAction();
      this.clickTriggered = true;
    }
    if (tour.index === 5) {
      this.morphContainers();
      this.demoNotAllowScroll = false;
    }
    if (tour.index > 5) {
      this.demoNotAllowScroll = true;
    }
    if (tour.status === "finished") {
      this.resetContainers();
    }
    const { history } = this.props;
    const { type } = tour;
    if (type === EVENTS.TOUR_END) {
      tutorialStore.endCurrentTutorial();
    }
  };
  callbackLocked = (tour) => {
    const { type } = tour;

    const { tutorialStore } = this.props;
    if (type === EVENTS.TOUR_END) {
      tutorialStore.endCurrentTutorial();
    }
  };

  onClose = () => {
    this.visible = false;
  };

  onOpen = () => {
    this.visible = true;
  };

  demoClickAction = () => {
    const event = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true
    });
    const e = document.getElementById("demo_1");
    if (this.behaviorSelected !== 4) {
      e.dispatchEvent(event);
    }
  };

  render() {
    const {
      t,
      theme,
      tutorialStore,
      profileStore: { currentUser },
      profileStore
    } = this.props;
    let color = profileStore.theme.primary_color;

    const steps = [
      {
        target: "body",
        content: t("tutorial.deep_dive_intro"),
        disableBeacon: true,
        placement: "center"
      },
      {
        target: "#strengths",
        content: t("tutorial.behaviors_explanation"),
        disableBeacon: true,
        placement: "right"
      },
      // {
      //   target: '#legend',
      //   content: 'Behaviors are colored on this scale.',
      //   disableBeacon: true,
      //   placement: 'auto'
      //   // isFixed: true
      // },
      {
        target: "#radarChart",
        content: t("tutorial.behaviors_visualization"),
        disableBeacon: true,
        placement: "auto"
        // isFixed: true
      },
      {
        target: "#demo_1",
        content: t("tutorial.behavior_improvement"),
        disableBeacon: true,
        placement: "auto"
        // isFixed: true
      },
      {
        target: "#improvement",
        content: t("tutorial.behavior_improvement_content"),
        disableBeacon: true,
        placement: "left"
      },
      {
        target: "#habitTrend",
        content: t("tutorial.score_trend"),
        disableBeacon: true,
        placement: "right-end"
        // offset: 100
      }
    ];
    const lockedSteps = [
      {
        target: "body",
        content: t("tutorial.deep_dive_locked"),
        disableBeacon: true,
        placement: "center"
      }
    ];

    if (this.dashboardViewer) {
      color = currentUser.organization.theme.primary_color;
    }

    return (
      <Body
        style={{
          overflowY: "scroll",
          minHeight: "100vh"
        }}
        src={Spring}
      >
        <Row gutter={[24, 40]}>
          <Joyride
            spotlightClicks
            disableScrolling={this.demoNotAllowScroll}
            continuous
            steps={steps}
            showSkipButton
            spotlightPadding={0}
            styles={{
              options: { primaryColor: theme.primary_color, zIndex: 999 }
            }}
            locale={{
              back: "Back",
              close: "Next",
              last: "Next",
              next: "Next",
              skip: "Skip"
            }}
            run={tutorialStore.runningTutorial === "how_to_interpret_feedback"}
            callback={this.callback}
          />
          <Joyride
            spotlightClicks
            disableScrolling={this.demoNotAllowScroll}
            continuous
            steps={lockedSteps}
            showSkipButton
            spotlightPadding={0}
            styles={{
              options: { primaryColor: theme.primary_color, zIndex: 999 }
            }}
            locale={{
              last: "Close"
            }}
            run={tutorialStore.runningTutorial === "feedback_locked"}
            callback={this.callbackLocked}
          />
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={14}
            style={{ minHeight: 432 }}
            value={600}
          >
            <HabitOverview
              demoRef={this.demoRef}
              open={this.onOpen}
              colorDot={this.colorDot}
              selectBehavior={this.selectBehavior}
              dashboardViewer={this.dashboardViewer}
              habit={this.habit}
              habits={currentUser.habits}
              loading={this.loading}
              selectHabit={this.selectHabit}
              color={color}
            />
          </Col>

          {/* {this.habit.raters_count >= 3 && mobile && (
            <ImprovementModal
              visible={this.visible}
              close={this.onClose}
              colorDot={this.colorDot}
              behaviorSelected={this.behaviorSelected}
              // dashboardViewer={this.dashboardViewer}
              habit={this.habit}
              loading={this.loading}
              tabsEnabled={currentUser.organization.features.includes('ADDITIONAL_FEEDBACK')}
            />
          )} */}

          <Col xs={24} sm={24} md={24} lg={24} xl={10}>
            <Improvement
              colorDot={this.colorDot}
              behaviorSelected={this.behaviorSelected}
              dashboardViewer={this.dashboardViewer}
              habit={this.habit}
              loading={this.loading}
              tabsEnabled={currentUser.organization.features.includes(
                "ADDITIONAL_FEEDBACK"
              )}
            />
          </Col>
          {/* {((this.habit.raters_count >= 3 &&
            mobile &&
            currentUser.organization.features.includes('ADDITIONAL_FEEDBACK')) ||
            (this.habit.additional_feedback && this.habit.additional_feedback.length > 0)) && (
            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
              <Improvement
                colorDot={this.colorDot}
                behaviorSelected={this.behaviorSelected}
                // dashboardViewer={this.dashboardViewer}
                habit={this.habit}
                loading={this.loading}
                tabsEnabled={currentUser.organization.features.includes('ADDITIONAL_FEEDBACK')}
              />
            </Col>
          )} */}
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <HabitTrend loading={this.loading} habit={this.habit} />
          </Col>
          <Col
            style={{ marginTop: mobile && "24px" }}
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
          >
            <ActionsAndInfo
              updateHabit={this.updateHabit}
              profileStore={profileStore}
              loading={this.loading}
              habit={this.habit}
              currentUserId={currentUser.id}
              isDashboardViewer={this.dashboardViewer}
            />
          </Col>
        </Row>
      </Body>
    );
  }
}

export default withTranslation()(withTheme(DeepDive));
