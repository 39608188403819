import React from "react";
import PropTypes from "prop-types";
import { Form, Modal, Button, Select, Input, Row, Col } from "antd";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { withTranslation } from "react-i18next";

const FormItem = Form.Item;
const { Option } = Select;

const getTitle = (user) => {
  if (!user) {
    return "Create User";
  }
  if (user.onboarded) {
    return "Edit User";
  }
  return "Confirm Your Personal Details";
};
@observer
class UserFormLite extends React.Component {
  @observable loading = false;
  @observable confirmDirty = false;
  handleSubmit = (e) => {
    const { form, onOk } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        this.loading = true;
        onOk(values);
      }
    });
  };

  componentWillReceiveProps(newProps) {
    if (!newProps.open) {
      this.loading = false;
    }
  }

  validate = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.confirmDirty) {
      form.validateFields(["password_confirmation"], {
        force: true
      });
    }
    callback();
  };

  confirm = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Passwords do not match");
    }
    callback();
  };

  handleConfirmBlur = (e) =>
    (this.confirmDirty = this.confirmDirty || e.target.value);

  render() {
    const {
      open,
      onCancel,
      editingUser,
      inline,
      form,
      features,
      t
    } = this.props;
    const { getFieldDecorator } = form;
    const userForm = (
      <div>
        <Row gutter={24}>
          <Col span={12}>
            <FormItem label={t("user_form.first_name")}>
              {getFieldDecorator("first_name", {
                rules: [
                  {
                    required: true,
                    message: t("user_form.first_name_is_required")
                  }
                ],
                initialValue: editingUser ? editingUser.first_name : null
              })(<Input />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label={t("user_form.last_name")}>
              {getFieldDecorator("last_name", {
                rules: [
                  {
                    required: true,
                    message: t("user_form.last_name_is_required")
                  }
                ],
                initialValue: editingUser ? editingUser.last_name : null
              })(<Input />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          {/* <Col span={12}>
            <FormItem label="Email">
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: 'Email is required'
                  }
                ],
                initialValue: editingUser ? editingUser.email : null
              })(<Input />)}
            </FormItem>
          </Col> */}
          <Col span={12}>
            {features.includes("PRONOUNS") ? (
              <FormItem label={t("user_form.pronouns")}>
                {getFieldDecorator("gender", {
                  rules: [
                    {
                      required: true,
                      message: t("user_form.pronouns_are_required")
                    }
                  ],
                  initialValue: editingUser ? editingUser.gender : "male"
                })(
                  <Select style={{ width: "100%" }}>
                    <Option value="male">{t("user_form.male_pronouns")}</Option>
                    <Option value="female">
                      {t("user_form.female_pronouns")}
                    </Option>
                    <Option value="neutral">
                      {t("user_form.neutral_pronouns")}
                    </Option>
                  </Select>
                )}
              </FormItem>
            ) : (
              <FormItem label={t("user_form.gender")}>
                {getFieldDecorator("gender", {
                  rules: [
                    {
                      required: true,
                      message: t("user_form.gender_is_required")
                    }
                  ],
                  initialValue: editingUser ? editingUser.gender : "male"
                })(
                  <Select style={{ width: "100%" }}>
                    <Option value="male">{t("user_form.male")}</Option>
                    <Option value="female">{t("user_form.female")}</Option>
                  </Select>
                )}
              </FormItem>
            )}
          </Col>
        </Row>
      </div>
    );
    if (inline) {
      return userForm;
    }
    return (
      <Modal
        visible={open}
        destroyOnClose
        title={getTitle(editingUser)}
        onCancel={editingUser.onboarded && onCancel}
        onOk={this.handleSubmit}
        width={500}
        footer={[
          <Button key="cancel" onClick={editingUser.onboarded && onCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            loading={this.loading}
            type="primary"
            onClick={this.handleSubmit}
          >
            {t("submit")}
          </Button>
        ]}
      >
        {userForm}
      </Modal>
    );
  }
}

UserFormLite.defaultProps = {
  open: false,
  inline: false,
  editingUser: null
};

UserFormLite.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  features: PropTypes.array.isRequired,
  editingUser: PropTypes.object,
  inline: PropTypes.bool,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired
  }).isRequired
};

export default Form.create()(withTranslation()(UserFormLite));
