/* eslint-disable no-debugger */

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment";
import { SingleLineGraphTooltip } from "utils/nivo";
import { Slider } from "antd";
import { withTheme } from "styled-components";
import { Label, StyledSlider, ChartTitle } from "./styles";
import {
  filterLargeMove,
  filterMove,
  dataShape,
  chartData,
  sortData
} from "./filters";
import { useTranslation } from "react-i18next";

const Trend = ({ habit, theme }) => {
  const [trendData, setTrendData] = useState(null);
  const { t } = useTranslation();
  const [v, setValue] = useState(0);
  const marks = {
    0: { label: <Label>{t("deep_dive.habit_trend.last_quarter")}</Label> },
    33: { label: <Label>{t("deep_dive.habit_trend.last_6_months")}</Label> },
    66: { label: <Label>{t("deep_dive.habit_trend.last_year")}</Label> },
    99: { label: <Label>{t("deep_dive.habit_trend.last_2_years")}</Label> }
  };

  const filterTrendDate = (trend, sliderPoint) => {
    let pastDateCap;
    switch (sliderPoint) {
      case 33:
        pastDateCap = moment().subtract(6, "months");
        break;
      case 66:
        pastDateCap = moment().subtract(1, "years");
        break;
      case 99:
        pastDateCap = moment().subtract(2, "years");
        break;
      default:
        pastDateCap = moment().subtract(3, "months");
    }
    return trend.filter((t) => moment(t.created_at) > pastDateCap);
  };

  // Given the trend point scoreValue
  // this returns the [rangeLocation, [rangMin, rangeMax]].
  // This data is used to indicate range changes in the trend data and
  // place filler points to connect trend lines.
  const sortLoc = (scoreValue) => {
    if (!scoreValue && scoreValue !== 0) {
      return null;
    }
    const num = scoreValue;
    if (num > 89 && num <= 100) {
      return [0, [90, 100]];
    }
    if (num > 64 && num <= 89) {
      return [1, [65, 90]];
    }
    return [2, [0, 65]];
  };

  const sortTrendValue = (trend) => {
    let data = dataShape();
    trend.forEach((t, i) => {
      const index = i;
      const [loc] = sortLoc(t.value);
      if (trend[index + 1]) {
        if (trend[index + 1].value && sortLoc(trend[i + 1].value) !== loc) {
          const [future] = sortLoc(trend[i + 1].value);
          // This condition measures if a rangeLocation change has occured in the
          // trend data that is a move of 2 ex. 0 -> 2 or 2 -> 0
          if (Math.abs(loc - future) === 2) {
            filterLargeMove(sortLoc, t, trend[i + 1], data);
          } // This condition measures if a rangeLocation change has occured in the
          // trend data that is a move of 1 ex. 0 -> 1 or 1 -> 0
          else if (Math.abs(loc - future) === 1) {
            filterMove(sortLoc, t, trend[i + 1], data);
          }
        }
      }
      const arr = [0, 1, 2];
      chartData(sortLoc, t, data, arr);
    });
    data = sortData(data);
    return data;
  };
  const formData = (h, sliderPoint) => {
    let trend = filterTrendDate(h.trend, sliderPoint);
    trend = sortTrendValue(trend);
    setValue(sliderPoint);
    setTrendData(trend);
  };

  const yTicks = [0, 65, 90, 100];

  useEffect(() => {
    if (habit.trend) {
      formData(habit);
      setValue(0);
    }
  }, [habit]);

  if (!habit.trend) {
    return <div />;
  }

  return (
    <>
      <div style={{ width: "100%", height: "250px", position: "relative" }}>
        <ChartTitle>{t("deep_dive.habit_trend.title")}</ChartTitle>
        <ResponsiveLine
          pointSize={0}
          areaOpacity={0.4}
          data={trendData}
          curve="monotoneX"
          colors={["#1DB35E", "#77D36E", "#FF9231"]}
          margin={{ top: 25, right: 25, bottom: 25, left: 50 }}
          enableDots={false}
          enableGridY={false}
          enableGridX={false}
          enableArea
          xScale={{
            type: "time",
            format: "%Y-%m-%d %I:%M %p",
            precision: "hour"
          }}
          yScale={{ type: "linear", stacked: false, min: 0, max: 100 }}
          axisBottom={{ tickSize: 0, format: "%b", tickValues: 2 }}
          axisLeft={{
            tickSize: 0,
            tickValues: 20,
            format: (t) => {
              return yTicks.includes(t) ? `${t}%` : "";
            }
          }}
          enableSlices="x"
          sliceTooltip={SingleLineGraphTooltip}
          markers={yTicks.map((t) => ({
            axis: "y",
            value: t,
            tickSize: 5,
            lineStyle: {
              stroke: `${!t || t === 100 ? "#a4a4a4" : "#e8e8e8"}`,
              strokeWidth: 1
            },
            text: { fontSize: 22 }
          }))}
          theme={{
            fontSize: theme.font.body
          }}
        />
      </div>
      <div key={habit.id} style={{ marginTop: "30px", padding: "0px 20px" }}>
        <StyledSlider
          key={habit.id}
          marks={marks}
          defaultValue={v}
          value={v}
          size="small"
          step={33}
          tooltipVisible={false}
          reverse
          onChange={(sliderPoint) => {
            formData(habit, sliderPoint);
          }}
        />
      </div>
    </>
  );
};

Trend.propTypes = {
  habit: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    trend: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        created_at: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  }).isRequired
};

export default withTheme(Trend);
