/*eslint-disable*/
import React from "react";
import { Form, Icon, Input, Button } from "antd";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { Profile } from "services/api";
const FormItem = Form.Item;

@observer
class SignInForm extends React.Component {
  @observable errors = false;
  @observable response = "";
  @observable submitted = false;
  @observable timer = null;
  @observable timeout;
  handleSubmit = (e) => {
    console.log(this.props.form);
    e.preventDefault();
    let values = this.props.form.getFieldsValue();

    console.log(values);
    if (values.password !== values.password_confirmation) {
      this.errors = true;
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          Profile.resetPassword(values)
            .then((res) => {
              this.submitted = true;
              this.timer = 10;
              setInterval(() => {
                this.timer -= 1;
              }, 1000);
              this.timeout = setTimeout(() => {
                this.props.changeToSignIn();
              }, 10000);
            })
            .catch((err) => (this.response = err.data.errors));
        } else {
        }
      });
    }
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  openForgotModal = () => {
    this.forgotPasswordOpen = true;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {this.submitted ? (
          <div
            style={{
              height: 200,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <p>
              Your password has successfully been reset. You will be redirected
              to the log in page in {this.timer} seconds, or you may use the
              button below to log in now.
            </p>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={this.props.changeToSignIn}
            >
              Log In
            </Button>
          </div>
        ) : (
          <Form
            onSubmit={this.handleSubmit}
            className="login-form"
            style={{ width: "100%" }}
          >
            <FormItem>
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "New password is required." },
                  { min: 6, message: "Must be at least 6 characters" }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="New Password"
                />
              )}
            </FormItem>
            <FormItem style={{ display: "none" }}>
              {getFieldDecorator(
                "token",
                {}
              )(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="New Password"
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator("password_confirmation", {
                rules: [
                  {
                    required: true,
                    message: "Password confirmation is required"
                  },
                  { min: 6, message: "Must be at least 6 characters" }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Confirm Password"
                />
              )}
            </FormItem>
            <FormItem>
              {this.errors && (
                <span style={{ color: "red" }}>Passwords must match</span>
              )}

              <span style={{ color: "red" }}>{this.response}</span>
            </FormItem>
            <Button
              loading={this.props.loading}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: "100%" }}
            >
              Reset Password
            </Button>
          </Form>
        )}
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create({
  mapPropsToFields(props) {
    return {
      token: Form.createFormField({ value: props.code })
    };
  }
})(SignInForm);

export default WrappedNormalLoginForm;
