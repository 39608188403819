/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import { media } from "utils/style-utils";

export const ActionContainer = styled.div`
  height: ${props => props.desktop ? '110px' : '50px'};
  width: ${props => props.desktop ? '200px' : '50%'};
  background-color: ${({
  inactive
}) => inactive && '#e8e8e8'}
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
  display: flex;
  flex-direction: ${props => props.desktop ? 'column' : 'row'};
  align-items: center;
  padding: ${props => props.desktop ? '0px 10px' : '0px 10px'};
  cursor: ${({
  inactive
}) => inactive ? 'not-allowed' : 'pointer'};
  ${media.handheld`
  width: 100%
  `}
`;

export const ActionTitle = styled.span`
  font-size: ${({
  theme
}) => theme.font.subheader}px;
  margin-top: 0px;
`;

export const ActionTitleContainer = styled.span`
  display: flex;
  align-items: center;
  padding-top: 5px;
  width: 100%;
  justify-content: ${({
  desktop
}) => !desktop && 'center'};
`;

export const ActionDesc = styled.span`
  font-size: ${({
  theme
}) => theme.font.small}px;
  margin-top: 10px;
`;