import React, { useState } from "react";
import { Button } from "antd";
import PhoneVerification from "components/AccountSettings/PhoneVerification";
import { Profile } from "services/api";
import { ReactComponent as FlairImg } from "assets/dashboard/text_alert.svg";
import { ItemWrapper, Title, ButtonWrapper, Flair, Content } from "../styles";
import { useTranslation } from "react-i18next";

type Props = {
  markCompleted: () => void;
};

const SMSPromptItem = ({ markCompleted }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [phoneNumberAdded, setPhoneNumberAdded] = useState(false);
  const { t } = useTranslation();

  const setPhoneNumber = async (phoneNumber: string, mailTime: string) => {
    await Profile.update({
      phone_number: phoneNumber,
      mail_time: mailTime,
      feedback_delivery: ["email", "text"]
    });
    setPhoneNumberAdded(true);
  };

  const onClose = () => {
    setModalOpen(false);
    if (phoneNumberAdded) {
      markCompleted();
    }
  };

  return (
    <ItemWrapper>
      <PhoneVerification
        visible={modalOpen}
        onClose={onClose}
        showVerification
        saveNumber={setPhoneNumber}
      />
      <Content>
        <Title>{t("homepage.action_items.sms_prompt.prompt_text")}</Title>
        <span>{t("homepage.action_items.sms_prompt.prompt_subtext")}</span>
        <ButtonWrapper style={{ marginTop: 12 }}>
          <Button onClick={markCompleted}>
            {t("homepage.action_items.sms_prompt.dismiss_text")}
          </Button>
          <Button
            type="primary"
            style={{ marginLeft: 24 }}
            onClick={() => setModalOpen(true)}
            onClicktype="primary"
          >
            {t("homepage.action_items.sms_prompt.action_text")}
          </Button>
        </ButtonWrapper>
      </Content>
      <Flair>
        <FlairImg />
      </Flair>
    </ItemWrapper>
  );
};
export default SMSPromptItem;
