/* eslint-disable react/no-danger */
import React from "react";
import UserAvatar from "components/UserAvatar";
import styled, { withTheme } from "styled-components";
import { Button, Tooltip, Icon, Modal } from "antd";
import PropTypes from "prop-types";
import useStores from "utils/useStores";
import moment from "moment";
import { Like, Highfive } from "services/api";
import viewModal from "components/FullScreenModal";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 6px 0;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextBlock = styled.span`
  margin-bottom: 0px;
`;

const UserName = styled.span`
  font-weight: 600;
  color: ${(props) => props.theme.primary_color};
  margin-right: 6px;
  cursor: pointer;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.3);
`;

export const LikeIcon = styled(({ hasLiked, ...props }) => <Icon {...props} />)`
  color: ${(props) => props.hasLiked && props.color}!important;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: ${(props) => (props.hasLiked ? "red" : props.color)}!important;
    transition: 0.2s;
  }
`;

const CommentItem = ({
  comment: { user, text, created_at, id, likes, commentable_id },
  style,
  onDelete,
  afterCreateLike,
  theme,
  afterDeleteLike
}) => {
  const {
    feedStore,
    profileStore: { currentUser }
  } = useStores();

  const { t } = useTranslation();

  const handleDelete = () => {
    Highfive.deleteComment(commentable_id, id);
    feedStore.deleteComment(id, commentable_id);
    if (onDelete) {
      onDelete(id);
    }
  };

  const confirmDelete = () => {
    Modal.confirm({
      onOk: () => handleDelete(),
      title: t("comments.confirm_delete_title"),
      okButtonProps: { type: "danger" },
      okText: t("delete")
    });
  };

  const parseComments = (cText) => {
    let changeText = cText;
    changeText = changeText.replace(/\^[*]{1}/g, "<a>");
    changeText = changeText.replace(/[*]{1}\^/g, "</a>");
    return { __html: changeText };
  };

  const onLike = (commentId, type) => {
    Like.create({ likeable_id: commentId, likeable_type: type }).then(
      ({ data }) => {
        feedStore.addLikeToComment(commentId, commentable_id, data);
        if (afterCreateLike) {
          afterCreateLike(commentId, data);
        }
      }
    );
  };

  const onUnlike = (likeId, commentId) => {
    Like.delete(commentId);
    feedStore.removeLikeFromComment(id, commentable_id, likeId);
    if (afterDeleteLike) {
      afterDeleteLike(id, likeId, commentable_id);
    }
  };

  const hasLiked = likes.find((l) => l.user_id === currentUser.id);
  return (
    <Wrapper style={style}>
      <UserAvatar
        style={{ marginRight: 12 }}
        avatar={user.avatar}
        name={user.name}
      />
      <ContentBlock>
        <TextBlock>
          <UserName onClick={() => viewModal({ id: user.id, type: "user" })}>
            {user.name}
          </UserName>
          <span dangerouslySetInnerHTML={parseComments(text)} />
        </TextBlock>
        <Footer>
          <>
            <Tooltip
              title={hasLiked ? "Unlike this comment" : "Like this comment"}
            >
              <LikeIcon
                theme={hasLiked && "filled"}
                hasLiked={hasLiked}
                type="like"
                fill={theme.secondary_color}
                color={theme.secondary_color}
                style={{
                  marginRight: 4
                }}
                onClick={() =>
                  !hasLiked ? onLike(id, "Comment") : onUnlike(hasLiked.id, id)
                }
                id={id}
              />
            </Tooltip>
            <Tooltip
              title={likes.map((l) => (
                <li>{`${l.first_name} ${l.last_name}`}</li>
              ))}
            >
              <span style={{ color: "rgba(0,0,0,0.5)" }}>{likes.length}</span>
            </Tooltip>
          </>
          {currentUser.id === user.id && (
            <Button
              type="link"
              style={{ color: "rgba(255, 0, 0, 0.8)" }}
              onClick={confirmDelete}
            >
              {t("homepage.feed.delete")}
            </Button>
          )}
          <div style={{ marginLeft: currentUser.id !== user.id && 16 }}>
            {moment(created_at).format("MMM Do hh:mm a")}
          </div>
        </Footer>
      </ContentBlock>
    </Wrapper>
  );
};

CommentItem.defaultProps = {
  style: {},
  onDelete: null,
  afterCreateLike: null,
  afterDeleteLike: null
};

CommentItem.propTypes = {
  comment: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  style: PropTypes.object,
  onDelete: PropTypes.func,
  afterDeleteLike: PropTypes.func,
  afterCreateLike: PropTypes.func
};

export default withTheme(CommentItem);
