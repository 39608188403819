import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "antd";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { ExternalUsers } from "services/api";
import ExternalUserForm from "components/ExternalUserForm";
import UserSearch from "components/UserSearch";

const nameFilter = (input, { props: { children } }) =>
  children.toLowerCase().includes(input.toLowerCase());

// const { Option } = Select;
@observer
class UserMultiSelect extends React.Component {
  @observable selected = [];
  @observable externalUserFormOpen = false;
  @observable newUsers = [];
  handleSubmit = () => {
    const { handleSubmit, withExternal } = this.props;
    handleSubmit(
      this.selected.map((s) => ({
        id: s.key,
        name: s.label,
        type: withExternal ? "ExternalUser" : "User"
      })),
      withExternal
    );
    this.selected = [];
  };

  createExternalUser = (form) => {
    const { addExternalUser } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        ExternalUsers.create(values).then(({ data }) => {
          this.selected.push({ key: data.id, label: data.name });
          this.newUsers.push(data);
          addExternalUser(data);
          this.externalUserFormOpen = false;
        });
      }
    });
  };

  // eslint-disable-next-line no-unused-vars
  handleSelect = (ids, opt) => {
    this.selected = ids;
  };

  render() {
    const {
      open,
      title,
      handleCancel,
      // users,
      withExternal,
      // externalUsers,
      hideList,
      buttonLoading,
      filterSelf,
      alreadySelectedMessage
    } = this.props;
    return (
      <Modal
        className="user-select"
        visible={open}
        title={title}
        bodyClassName="user-select-body"
        onOk={this.handleSubmit}
        okButtonProps={{ loading: buttonLoading }}
        style={{ paddingBottom: 0 }}
        onCancel={handleCancel}
        width={600}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <UserSearch
            mode="multiple"
            labelInValue
            alreadySelectedMessage={alreadySelectedMessage}
            external={withExternal}
            filterSelf={filterSelf}
            hideList={hideList.map((h) => h.id)}
            style={{ width: "calc(100% - 150px)" }}
            value={this.selected}
            onSelect={this.handleSelect}
            placeholder={`Search ${withExternal ? "External Users" : "Users"}`}
            filterOption={nameFilter}
            id="user-multiselect"
          >
            {/* {withExternal
              ? externalUsers
                  .concat(this.newUsers)
                  .map((u) => <Option key={u.id}>{u.name}</Option>)
              : users
                  .concat(this.newUsers)
                  .map((u) => <Option key={u.id}>{u.name}</Option>)} */}
          </UserSearch>
          {withExternal && (
            <React.Fragment>
              <span style={{ margin: "24px auto" }}> Or </span>
              <Button onClick={() => (this.externalUserFormOpen = true)}>
                Create New External User
              </Button>
            </React.Fragment>
          )}
        </div>
        <Modal
          visible={this.externalUserFormOpen}
          title="Create External User"
          onCancel={() => (this.externalUserFormOpen = false)}
          footer={false}
        >
          <ExternalUserForm noNps handleSubmit={this.createExternalUser} />
        </Modal>
      </Modal>
    );
  }
}

UserMultiSelect.defaultProps = {
  open: false,
  withExternal: false,
  buttonLoading: false
};

UserMultiSelect.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  addExternalUser: PropTypes.func,
  hideList: PropTypes.array,
  alreadySelectedMessage: PropTypes.string,
  withExternal: PropTypes.bool,
  buttonLoading: PropTypes.bool,
  filterSelf: PropTypes.bool
};

UserMultiSelect.defaultProps = {
  addExternalUser: null,
  hideList: [],
  filterSelf: true,
  alreadySelectedMessage: "(Already Selected)"
};

export default UserMultiSelect;
