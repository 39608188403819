import React, { useState, useEffect } from "react";
import { HabitUser } from "containers/ManagerDashboardNext/ManagedUsers/UserHabits";
import PercentageCircle from "components/PercentageCircle";
import Box from "layout/Box";
import HabitOverview from "containers/DeepDiveV2/HabitOverview";
import { IFetchManagedUserProfileQuery } from "./fetchManagerUserProfile.generated";
import { Empty } from "antd";

interface Props {
  habitUsers: IFetchManagedUserProfileQuery["user"]["habitUsers"];
  setStepIndex: () => void;
}

const Habits: React.FC<Props> = ({ habitUsers, setStepIndex }) => {
  const [selectedHabit, setSelectedHabit] = useState(habitUsers[0]);

  useEffect(() => {
    if (selectedHabit && !habitUsers.find((h) => h.id === selectedHabit.id)) {
      setSelectedHabit(habitUsers[0]);
    }
  }, [selectedHabit, habitUsers]);

  const colorDot = (e) => {
    if (!e && typeof e !== "number") {
      return null;
    }
    const num = typeof e === "number" ? e : e.value;
    if (num > 89 && num <= 100) {
      return "#67CD67";
    }
    if (num > 64 && num <= 89) {
      return "#9BD199";
    }
    if (num >= 0 && num <= 64) {
      return "#F4A084";
    }
    return "#F4A084";
  };

  return (
    <Box
      width="100%"
      mt={12}
      display="flex"
      flexDirection="column"
      id="habits"
      flexShrink={0}
    >
      <Box
        flexShrink={0}
        id="habit-list"
        display="flex"
        flexWrap="wrap"
        padding="0px 12px 12px 12px"
        justifyContent="center"
        margin="0 auto"
      >
        {habitUsers.map((habitUser) => (
          <Box
            onClick={() => {
              setSelectedHabit(habitUser);
              setStepIndex();
            }}
            style={{ cursor: "pointer" }}
            id={habitUser.id}
          >
            <PercentageCircle
              key={habitUser.id}
              locked={habitUser.locked}
              unlockThreshold={habitUser.unlockThreshold}
              percent={habitUser.currentScore && habitUser.currentScore.value}
              lastPercent={habitUser.lastScore && habitUser.lastScore.value}
              name={habitUser.habit.name}
              responses={habitUser.validResponses}
              raters={habitUser.raters.length}
            />
          </Box>
        ))}
      </Box>
      {selectedHabit && (
        <Box width="100%">
          <HabitOverview
            selectHabit={({ key }: { key: string }): void => {
              setSelectedHabit(habitUsers.find((h) => h.id === key));
            }}
            selectBehavior={() => null}
            open={() => null}
            colorDot={colorDot}
            habits={habitUsers.map((h) => ({
              active: true,
              habit_user_id: h.id,
              name: h.habit.name,
              company_average: h.companyAverage,
              raters_count: 6,
              response: 10,
              score: h.currentScore && h.currentScore.value,
              lastScore: h.lastScore && h.lastScore.value
            }))}
            habit={{
              last_score:
                selectedHabit.lastScore && selectedHabit.lastScore.value,
              behaviors: selectedHabit.habit.behaviors.map((b) => ({
                ...b,
                description: b.anchor,
                score:
                  selectedHabit.feedbackQuestions.find(
                    (f) => f.behaviorId === b.id
                  ).currentScore &&
                  selectedHabit.feedbackQuestions.find(
                    (f) => f.behaviorId === b.id
                  ).currentScore.value
              })),
              score:
                selectedHabit.currentScore && selectedHabit.currentScore.value,
              response_count: 5,
              description: selectedHabit.habit.description,
              raters_count: 5,
              name: selectedHabit.habit.name
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Habits;
