import { Carousel } from "antd";
import styled from "styled-components";

export const MobileCarousel = styled(Carousel)`
  &&& .slick-next {
    color: grey;
  }

  &&& .slick-dots li button {
    background-color: grey;
  }

  &&& .slick-dots {
    bottom: -10px;
  }
`;

export const ActionButton = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;