/* eslint-disable no-debugger */
import React from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import MenuItem from "./MenuItem";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.1 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const Menu = ({ isOpen, direction, items, style, menuItemStyle, size }) => {
  return (
    <motion.div
      variants={variants}
      style={{ pointerEvents: "none", ...style }}
      animate={isOpen ? "open" : "closed"}
    >
      {items.map((i) => (
        <MenuItem
          style={menuItemStyle}
          direction={direction}
          i={i.id}
          key={i.id}
          length={items.length}
          isOpen={isOpen}
          size={size}
          {...i}
        />
      ))}
    </motion.div>
  );
};

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  direction: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  menuItemStyle: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  size: PropTypes.string.isRequired
};

export default Menu;
