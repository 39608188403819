/* eslint-disable react/prop-types */

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal, Select } from "antd";
import PropTypes from "prop-types";
import posed, { PoseGroup } from "react-pose";

import SelectUser from "./SelectUser";
import SelectCohorts from "./SelectCohorts";
import Attributes from "./Attributes";
import { AdminOneOnOnes, ManagerOneOnOnes } from "../../services/api";

const Box = posed.div({
  initial: { x: 300, opacity: 0 },
  enter: { x: 0, opacity: 1 },
  exit: { x: -200, opacity: 0 }
});

const OneOnOneWizard = ({
  visible,
  currentUser,
  user,
  asAdmin,
  onClose,
  onSubmit,
  addOneOnOnes,
  preSelectedUser
}) => {
  const [step, setStep] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [attributes, setAttributes] = useState({
    label: "",
    dateRange: [],
    includeHabits: false,
    includeObjectives: false,
    scheduleDaysBefore: 7
  });
  const setValue = (field, value) => {
    setAttributes({ ...attributes, [field]: value });
  };

  useEffect(() => {
    if (preSelectedUser) {
      setStep(1);
      setSelectedUsers([preSelectedUser]);
    }
  }, [visible]);

  useEffect(() => {
    if (!visible) {
      setSelectedTeams([]);
      setSelectedUsers([]);
      setStep(0);
      setAttributes({
        label: "",
        dateRange: [],
        includeHabits: false,
        includeObjectives: false,
        scheduleDaysBefore: 7
      });
    }
  }, [visible]);

  const handleClose = () => {
    setAttributes({
      label: "",
      dateRange: [],
      includeHabits: false,
      includeObjectives: false,
      scheduleDaysBefore: 7
    });
    setStep(0);
    setSelectedUsers([]);
    onClose();
  };
  const incrementStep = () => setStep(step + 1);
  const decrementStep = () => setStep(step - 1);

  const handleSubmit = () => {
    const {
      label,
      dateRange,
      includeObjectives,
      includeHabits,
      scheduleDaysBefore
    } = attributes;
    const api = asAdmin ? AdminOneOnOnes : ManagerOneOnOnes;
    const payload = {
      label,
      start_date: dateRange[0],
      end_date: dateRange[1],
      include_habits: includeHabits,
      include_objectives: includeObjectives,
      schedule_days_before: scheduleDaysBefore
    };
    if (asAdmin) {
      payload.team_ids = selectedTeams.map((t) => t.id);
    } else {
      payload.user_ids = selectedUsers.map((u) => u.id);
    }
    api.create(payload).then(({ data }) => {
      addOneOnOnes(data);
      if (onSubmit) {
        onSubmit();
      }
      onClose();
    });
  };

  const adminSteps = [
    {
      name: "select_cohorts",
      okText: "Next",
      cancelText: "Cancel",
      onCancel: handleClose
    },
    {
      name: "attributes",
      okText: "Schedule",
      cancelText: "Back",
      onCancel: handleClose,
      cancelButtonProps: { onClick: decrementStep }
    }
  ];
  const managerSteps = [
    {
      name: "select_user",
      okText: "Next",
      cancelText: "Cancel",
      onCancel: handleClose
    },
    {
      name: "attributes",
      okText: "Schedule",
      cancelText: "Back",
      onCancel: handleClose,
      cancelButtonProps: { onClick: decrementStep }
    }
  ];
  const steps = asAdmin ? adminSteps : managerSteps;

  useEffect(() => {
    if (!visible) {
      setStep(0);
    }
  }, [visible]);

  const handleSelect = (newUser) => {
    if (selectedUsers.find((u) => u.id === newUser.id)) {
      setSelectedUsers(selectedUsers.filter((u) => u.id !== newUser.id));
    } else {
      setSelectedUsers([...selectedUsers, newUser]);
    }
  };
  const handleSelectTeam = (newTeam) => {
    if (selectedTeams.find((u) => u.id === newTeam.id)) {
      setSelectedTeams(selectedTeams.filter((u) => u.id !== newTeam.id));
    } else {
      setSelectedTeams([...selectedTeams, newTeam]);
    }
  };

  const setAllUsers = (arr) => {
    setSelectedUsers(arr);
  };
  const setAllTeams = (arr) => {
    setSelectedTeams(arr);
  };

  const componentFromStep = () => {
    let content;
    switch (steps[step].name) {
      case "select_user":
        content = (
          <Box key="select_user">
            <SelectUser
              selected={selectedUsers}
              teamId={currentUser.managed_team_id}
              onSelect={handleSelect}
              checkAll={selectedUsers}
              setAll={setAllUsers}
            />
          </Box>
        );
        break;
      case "select_cohorts":
        content = (
          <Box key="select_cohorts">
            <SelectCohorts
              setAll={setAllTeams}
              selected={selectedTeams}
              onSelect={handleSelectTeam}
            />
          </Box>
        );
        break;
      case "attributes":
        content = (
          <Box
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -300, opacity: 0 }}
            key="attributes"
          >
            <Attributes {...attributes} setValue={setValue} asAdmin={asAdmin} />
          </Box>
        );
        break;
      default:
        content = <span>hey</span>;
        break;
    }
    return content;
  };
  return (
    <Modal
      visible={visible}
      title={`New One-on-One ${
        preSelectedUser ? `with ${preSelectedUser.name}` : ""
      }`}
      onCancel={steps[step].onCancel}
      cancelButtonProps={steps[step].cancelButtonProps}
      onOk={step === steps.length - 1 ? handleSubmit : incrementStep}
      okText={steps[step].okText}
      cancelText={steps[step].cancelText}
      style={{ overflowX: "hidden", overflowY: "hidden" }}
      bodyStyle={{ overflowX: "hidden", overflowY: "hidden" }}
    >
      {componentFromStep()}
      {/* <PoseGroup preEnterPose="initial">{}</PoseGroup> */}
    </Modal>
  );
};

OneOnOneWizard.propTypes = {
  visible: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  addOneOnOnes: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  asAdmin: PropTypes.bool
};

OneOnOneWizard.defaultProps = {
  asAdmin: false
};

export default OneOnOneWizard;
