export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Represents untyped JSON */
  JSON: any;
};

export type IAccount = {
  __typename?: 'Account';
  email: Scalars['String'];
  id: Scalars['ID'];
  mentorMatchLink: Scalars['String'];
  mentoringLink: Scalars['String'];
  tenant: Scalars['String'];
  user?: Maybe<IAccountUser>;
};

export type IAccountRansack = {
  id_eq?: Maybe<Scalars['String']>;
  id_eq_any?: Maybe<Array<Scalars['String']>>;
  id_eq_all?: Maybe<Array<Scalars['String']>>;
  id_not_eq?: Maybe<Scalars['String']>;
  id_not_eq_any?: Maybe<Array<Scalars['String']>>;
  id_not_eq_all?: Maybe<Array<Scalars['String']>>;
  id_matches?: Maybe<Scalars['String']>;
  id_matches_any?: Maybe<Array<Scalars['String']>>;
  id_matches_all?: Maybe<Array<Scalars['String']>>;
  id_does_not_match?: Maybe<Scalars['String']>;
  id_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  id_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  id_lt?: Maybe<Scalars['String']>;
  id_lt_any?: Maybe<Array<Scalars['String']>>;
  id_lt_all?: Maybe<Array<Scalars['String']>>;
  id_lteq?: Maybe<Scalars['String']>;
  id_lteq_any?: Maybe<Array<Scalars['String']>>;
  id_lteq_all?: Maybe<Array<Scalars['String']>>;
  id_gt?: Maybe<Scalars['String']>;
  id_gt_any?: Maybe<Array<Scalars['String']>>;
  id_gt_all?: Maybe<Array<Scalars['String']>>;
  id_gteq?: Maybe<Scalars['String']>;
  id_gteq_any?: Maybe<Array<Scalars['String']>>;
  id_gteq_all?: Maybe<Array<Scalars['String']>>;
  id_in?: Maybe<Array<Scalars['String']>>;
  id_in_any?: Maybe<Array<Scalars['String']>>;
  id_in_all?: Maybe<Array<Scalars['String']>>;
  id_not_in?: Maybe<Array<Scalars['String']>>;
  id_not_in_any?: Maybe<Array<Scalars['String']>>;
  id_not_in_all?: Maybe<Array<Scalars['String']>>;
  id_cont?: Maybe<Scalars['String']>;
  id_cont_any?: Maybe<Array<Scalars['String']>>;
  id_cont_all?: Maybe<Array<Scalars['String']>>;
  id_not_cont?: Maybe<Scalars['String']>;
  id_not_cont_any?: Maybe<Array<Scalars['String']>>;
  id_not_cont_all?: Maybe<Array<Scalars['String']>>;
  id_i_cont?: Maybe<Scalars['String']>;
  id_i_cont_any?: Maybe<Array<Scalars['String']>>;
  id_i_cont_all?: Maybe<Array<Scalars['String']>>;
  id_not_i_cont?: Maybe<Scalars['String']>;
  id_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  id_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  id_start?: Maybe<Scalars['String']>;
  id_start_any?: Maybe<Array<Scalars['String']>>;
  id_start_all?: Maybe<Array<Scalars['String']>>;
  id_not_start?: Maybe<Scalars['String']>;
  id_not_start_any?: Maybe<Array<Scalars['String']>>;
  id_not_start_all?: Maybe<Array<Scalars['String']>>;
  id_end?: Maybe<Scalars['String']>;
  id_end_any?: Maybe<Array<Scalars['String']>>;
  id_end_all?: Maybe<Array<Scalars['String']>>;
  id_not_end?: Maybe<Scalars['String']>;
  id_not_end_any?: Maybe<Array<Scalars['String']>>;
  id_not_end_all?: Maybe<Array<Scalars['String']>>;
  id_true?: Maybe<Scalars['String']>;
  id_not_true?: Maybe<Scalars['String']>;
  id_false?: Maybe<Scalars['String']>;
  id_not_false?: Maybe<Scalars['String']>;
  id_present?: Maybe<Scalars['String']>;
  id_blank?: Maybe<Scalars['String']>;
  id_null?: Maybe<Scalars['String']>;
  id_not_null?: Maybe<Scalars['String']>;
  id_count?: Maybe<Scalars['String']>;
  id_count_distinct?: Maybe<Scalars['String']>;
  id_sum?: Maybe<Scalars['String']>;
  id_avg?: Maybe<Scalars['String']>;
  id_min?: Maybe<Scalars['String']>;
  id_max?: Maybe<Scalars['String']>;
  user_id_eq?: Maybe<Scalars['String']>;
  user_id_eq_any?: Maybe<Array<Scalars['String']>>;
  user_id_eq_all?: Maybe<Array<Scalars['String']>>;
  user_id_not_eq?: Maybe<Scalars['String']>;
  user_id_not_eq_any?: Maybe<Array<Scalars['String']>>;
  user_id_not_eq_all?: Maybe<Array<Scalars['String']>>;
  user_id_matches?: Maybe<Scalars['String']>;
  user_id_matches_any?: Maybe<Array<Scalars['String']>>;
  user_id_matches_all?: Maybe<Array<Scalars['String']>>;
  user_id_does_not_match?: Maybe<Scalars['String']>;
  user_id_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  user_id_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  user_id_lt?: Maybe<Scalars['String']>;
  user_id_lt_any?: Maybe<Array<Scalars['String']>>;
  user_id_lt_all?: Maybe<Array<Scalars['String']>>;
  user_id_lteq?: Maybe<Scalars['String']>;
  user_id_lteq_any?: Maybe<Array<Scalars['String']>>;
  user_id_lteq_all?: Maybe<Array<Scalars['String']>>;
  user_id_gt?: Maybe<Scalars['String']>;
  user_id_gt_any?: Maybe<Array<Scalars['String']>>;
  user_id_gt_all?: Maybe<Array<Scalars['String']>>;
  user_id_gteq?: Maybe<Scalars['String']>;
  user_id_gteq_any?: Maybe<Array<Scalars['String']>>;
  user_id_gteq_all?: Maybe<Array<Scalars['String']>>;
  user_id_in?: Maybe<Array<Scalars['String']>>;
  user_id_in_any?: Maybe<Array<Scalars['String']>>;
  user_id_in_all?: Maybe<Array<Scalars['String']>>;
  user_id_not_in?: Maybe<Array<Scalars['String']>>;
  user_id_not_in_any?: Maybe<Array<Scalars['String']>>;
  user_id_not_in_all?: Maybe<Array<Scalars['String']>>;
  user_id_cont?: Maybe<Scalars['String']>;
  user_id_cont_any?: Maybe<Array<Scalars['String']>>;
  user_id_cont_all?: Maybe<Array<Scalars['String']>>;
  user_id_not_cont?: Maybe<Scalars['String']>;
  user_id_not_cont_any?: Maybe<Array<Scalars['String']>>;
  user_id_not_cont_all?: Maybe<Array<Scalars['String']>>;
  user_id_i_cont?: Maybe<Scalars['String']>;
  user_id_i_cont_any?: Maybe<Array<Scalars['String']>>;
  user_id_i_cont_all?: Maybe<Array<Scalars['String']>>;
  user_id_not_i_cont?: Maybe<Scalars['String']>;
  user_id_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  user_id_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  user_id_start?: Maybe<Scalars['String']>;
  user_id_start_any?: Maybe<Array<Scalars['String']>>;
  user_id_start_all?: Maybe<Array<Scalars['String']>>;
  user_id_not_start?: Maybe<Scalars['String']>;
  user_id_not_start_any?: Maybe<Array<Scalars['String']>>;
  user_id_not_start_all?: Maybe<Array<Scalars['String']>>;
  user_id_end?: Maybe<Scalars['String']>;
  user_id_end_any?: Maybe<Array<Scalars['String']>>;
  user_id_end_all?: Maybe<Array<Scalars['String']>>;
  user_id_not_end?: Maybe<Scalars['String']>;
  user_id_not_end_any?: Maybe<Array<Scalars['String']>>;
  user_id_not_end_all?: Maybe<Array<Scalars['String']>>;
  user_id_true?: Maybe<Scalars['String']>;
  user_id_not_true?: Maybe<Scalars['String']>;
  user_id_false?: Maybe<Scalars['String']>;
  user_id_not_false?: Maybe<Scalars['String']>;
  user_id_present?: Maybe<Scalars['String']>;
  user_id_blank?: Maybe<Scalars['String']>;
  user_id_null?: Maybe<Scalars['String']>;
  user_id_not_null?: Maybe<Scalars['String']>;
  user_id_count?: Maybe<Scalars['String']>;
  user_id_count_distinct?: Maybe<Scalars['String']>;
  user_id_sum?: Maybe<Scalars['String']>;
  user_id_avg?: Maybe<Scalars['String']>;
  user_id_min?: Maybe<Scalars['String']>;
  user_id_max?: Maybe<Scalars['String']>;
  user_type_eq?: Maybe<Scalars['String']>;
  user_type_eq_any?: Maybe<Array<Scalars['String']>>;
  user_type_eq_all?: Maybe<Array<Scalars['String']>>;
  user_type_not_eq?: Maybe<Scalars['String']>;
  user_type_not_eq_any?: Maybe<Array<Scalars['String']>>;
  user_type_not_eq_all?: Maybe<Array<Scalars['String']>>;
  user_type_matches?: Maybe<Scalars['String']>;
  user_type_matches_any?: Maybe<Array<Scalars['String']>>;
  user_type_matches_all?: Maybe<Array<Scalars['String']>>;
  user_type_does_not_match?: Maybe<Scalars['String']>;
  user_type_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  user_type_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  user_type_lt?: Maybe<Scalars['String']>;
  user_type_lt_any?: Maybe<Array<Scalars['String']>>;
  user_type_lt_all?: Maybe<Array<Scalars['String']>>;
  user_type_lteq?: Maybe<Scalars['String']>;
  user_type_lteq_any?: Maybe<Array<Scalars['String']>>;
  user_type_lteq_all?: Maybe<Array<Scalars['String']>>;
  user_type_gt?: Maybe<Scalars['String']>;
  user_type_gt_any?: Maybe<Array<Scalars['String']>>;
  user_type_gt_all?: Maybe<Array<Scalars['String']>>;
  user_type_gteq?: Maybe<Scalars['String']>;
  user_type_gteq_any?: Maybe<Array<Scalars['String']>>;
  user_type_gteq_all?: Maybe<Array<Scalars['String']>>;
  user_type_in?: Maybe<Array<Scalars['String']>>;
  user_type_in_any?: Maybe<Array<Scalars['String']>>;
  user_type_in_all?: Maybe<Array<Scalars['String']>>;
  user_type_not_in?: Maybe<Array<Scalars['String']>>;
  user_type_not_in_any?: Maybe<Array<Scalars['String']>>;
  user_type_not_in_all?: Maybe<Array<Scalars['String']>>;
  user_type_cont?: Maybe<Scalars['String']>;
  user_type_cont_any?: Maybe<Array<Scalars['String']>>;
  user_type_cont_all?: Maybe<Array<Scalars['String']>>;
  user_type_not_cont?: Maybe<Scalars['String']>;
  user_type_not_cont_any?: Maybe<Array<Scalars['String']>>;
  user_type_not_cont_all?: Maybe<Array<Scalars['String']>>;
  user_type_i_cont?: Maybe<Scalars['String']>;
  user_type_i_cont_any?: Maybe<Array<Scalars['String']>>;
  user_type_i_cont_all?: Maybe<Array<Scalars['String']>>;
  user_type_not_i_cont?: Maybe<Scalars['String']>;
  user_type_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  user_type_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  user_type_start?: Maybe<Scalars['String']>;
  user_type_start_any?: Maybe<Array<Scalars['String']>>;
  user_type_start_all?: Maybe<Array<Scalars['String']>>;
  user_type_not_start?: Maybe<Scalars['String']>;
  user_type_not_start_any?: Maybe<Array<Scalars['String']>>;
  user_type_not_start_all?: Maybe<Array<Scalars['String']>>;
  user_type_end?: Maybe<Scalars['String']>;
  user_type_end_any?: Maybe<Array<Scalars['String']>>;
  user_type_end_all?: Maybe<Array<Scalars['String']>>;
  user_type_not_end?: Maybe<Scalars['String']>;
  user_type_not_end_any?: Maybe<Array<Scalars['String']>>;
  user_type_not_end_all?: Maybe<Array<Scalars['String']>>;
  user_type_true?: Maybe<Scalars['String']>;
  user_type_not_true?: Maybe<Scalars['String']>;
  user_type_false?: Maybe<Scalars['String']>;
  user_type_not_false?: Maybe<Scalars['String']>;
  user_type_present?: Maybe<Scalars['String']>;
  user_type_blank?: Maybe<Scalars['String']>;
  user_type_null?: Maybe<Scalars['String']>;
  user_type_not_null?: Maybe<Scalars['String']>;
  user_type_count?: Maybe<Scalars['String']>;
  user_type_count_distinct?: Maybe<Scalars['String']>;
  user_type_sum?: Maybe<Scalars['String']>;
  user_type_avg?: Maybe<Scalars['String']>;
  user_type_min?: Maybe<Scalars['String']>;
  user_type_max?: Maybe<Scalars['String']>;
  token_issued_at_eq?: Maybe<Scalars['String']>;
  token_issued_at_eq_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_eq_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_not_eq?: Maybe<Scalars['String']>;
  token_issued_at_not_eq_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_not_eq_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_matches?: Maybe<Scalars['String']>;
  token_issued_at_matches_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_matches_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_does_not_match?: Maybe<Scalars['String']>;
  token_issued_at_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_lt?: Maybe<Scalars['String']>;
  token_issued_at_lt_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_lt_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_lteq?: Maybe<Scalars['String']>;
  token_issued_at_lteq_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_lteq_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_gt?: Maybe<Scalars['String']>;
  token_issued_at_gt_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_gt_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_gteq?: Maybe<Scalars['String']>;
  token_issued_at_gteq_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_gteq_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_in?: Maybe<Array<Scalars['String']>>;
  token_issued_at_in_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_in_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_not_in?: Maybe<Array<Scalars['String']>>;
  token_issued_at_not_in_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_not_in_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_cont?: Maybe<Scalars['String']>;
  token_issued_at_cont_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_cont_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_not_cont?: Maybe<Scalars['String']>;
  token_issued_at_not_cont_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_not_cont_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_i_cont?: Maybe<Scalars['String']>;
  token_issued_at_i_cont_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_i_cont_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_not_i_cont?: Maybe<Scalars['String']>;
  token_issued_at_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_start?: Maybe<Scalars['String']>;
  token_issued_at_start_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_start_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_not_start?: Maybe<Scalars['String']>;
  token_issued_at_not_start_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_not_start_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_end?: Maybe<Scalars['String']>;
  token_issued_at_end_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_end_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_not_end?: Maybe<Scalars['String']>;
  token_issued_at_not_end_any?: Maybe<Array<Scalars['String']>>;
  token_issued_at_not_end_all?: Maybe<Array<Scalars['String']>>;
  token_issued_at_true?: Maybe<Scalars['String']>;
  token_issued_at_not_true?: Maybe<Scalars['String']>;
  token_issued_at_false?: Maybe<Scalars['String']>;
  token_issued_at_not_false?: Maybe<Scalars['String']>;
  token_issued_at_present?: Maybe<Scalars['String']>;
  token_issued_at_blank?: Maybe<Scalars['String']>;
  token_issued_at_null?: Maybe<Scalars['String']>;
  token_issued_at_not_null?: Maybe<Scalars['String']>;
  token_issued_at_count?: Maybe<Scalars['String']>;
  token_issued_at_count_distinct?: Maybe<Scalars['String']>;
  token_issued_at_sum?: Maybe<Scalars['String']>;
  token_issued_at_avg?: Maybe<Scalars['String']>;
  token_issued_at_min?: Maybe<Scalars['String']>;
  token_issued_at_max?: Maybe<Scalars['String']>;
  email_eq?: Maybe<Scalars['String']>;
  email_eq_any?: Maybe<Array<Scalars['String']>>;
  email_eq_all?: Maybe<Array<Scalars['String']>>;
  email_not_eq?: Maybe<Scalars['String']>;
  email_not_eq_any?: Maybe<Array<Scalars['String']>>;
  email_not_eq_all?: Maybe<Array<Scalars['String']>>;
  email_matches?: Maybe<Scalars['String']>;
  email_matches_any?: Maybe<Array<Scalars['String']>>;
  email_matches_all?: Maybe<Array<Scalars['String']>>;
  email_does_not_match?: Maybe<Scalars['String']>;
  email_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  email_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lt_any?: Maybe<Array<Scalars['String']>>;
  email_lt_all?: Maybe<Array<Scalars['String']>>;
  email_lteq?: Maybe<Scalars['String']>;
  email_lteq_any?: Maybe<Array<Scalars['String']>>;
  email_lteq_all?: Maybe<Array<Scalars['String']>>;
  email_gt?: Maybe<Scalars['String']>;
  email_gt_any?: Maybe<Array<Scalars['String']>>;
  email_gt_all?: Maybe<Array<Scalars['String']>>;
  email_gteq?: Maybe<Scalars['String']>;
  email_gteq_any?: Maybe<Array<Scalars['String']>>;
  email_gteq_all?: Maybe<Array<Scalars['String']>>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_in_any?: Maybe<Array<Scalars['String']>>;
  email_in_all?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_not_in_any?: Maybe<Array<Scalars['String']>>;
  email_not_in_all?: Maybe<Array<Scalars['String']>>;
  email_cont?: Maybe<Scalars['String']>;
  email_cont_any?: Maybe<Array<Scalars['String']>>;
  email_cont_all?: Maybe<Array<Scalars['String']>>;
  email_not_cont?: Maybe<Scalars['String']>;
  email_not_cont_any?: Maybe<Array<Scalars['String']>>;
  email_not_cont_all?: Maybe<Array<Scalars['String']>>;
  email_i_cont?: Maybe<Scalars['String']>;
  email_i_cont_any?: Maybe<Array<Scalars['String']>>;
  email_i_cont_all?: Maybe<Array<Scalars['String']>>;
  email_not_i_cont?: Maybe<Scalars['String']>;
  email_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  email_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  email_start?: Maybe<Scalars['String']>;
  email_start_any?: Maybe<Array<Scalars['String']>>;
  email_start_all?: Maybe<Array<Scalars['String']>>;
  email_not_start?: Maybe<Scalars['String']>;
  email_not_start_any?: Maybe<Array<Scalars['String']>>;
  email_not_start_all?: Maybe<Array<Scalars['String']>>;
  email_end?: Maybe<Scalars['String']>;
  email_end_any?: Maybe<Array<Scalars['String']>>;
  email_end_all?: Maybe<Array<Scalars['String']>>;
  email_not_end?: Maybe<Scalars['String']>;
  email_not_end_any?: Maybe<Array<Scalars['String']>>;
  email_not_end_all?: Maybe<Array<Scalars['String']>>;
  email_true?: Maybe<Scalars['String']>;
  email_not_true?: Maybe<Scalars['String']>;
  email_false?: Maybe<Scalars['String']>;
  email_not_false?: Maybe<Scalars['String']>;
  email_present?: Maybe<Scalars['String']>;
  email_blank?: Maybe<Scalars['String']>;
  email_null?: Maybe<Scalars['String']>;
  email_not_null?: Maybe<Scalars['String']>;
  email_count?: Maybe<Scalars['String']>;
  email_count_distinct?: Maybe<Scalars['String']>;
  email_sum?: Maybe<Scalars['String']>;
  email_avg?: Maybe<Scalars['String']>;
  email_min?: Maybe<Scalars['String']>;
  email_max?: Maybe<Scalars['String']>;
  encrypted_password_eq?: Maybe<Scalars['String']>;
  encrypted_password_eq_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_eq_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_not_eq?: Maybe<Scalars['String']>;
  encrypted_password_not_eq_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_not_eq_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_matches?: Maybe<Scalars['String']>;
  encrypted_password_matches_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_matches_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_does_not_match?: Maybe<Scalars['String']>;
  encrypted_password_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_lt?: Maybe<Scalars['String']>;
  encrypted_password_lt_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_lt_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_lteq?: Maybe<Scalars['String']>;
  encrypted_password_lteq_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_lteq_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_gt?: Maybe<Scalars['String']>;
  encrypted_password_gt_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_gt_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_gteq?: Maybe<Scalars['String']>;
  encrypted_password_gteq_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_gteq_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_in?: Maybe<Array<Scalars['String']>>;
  encrypted_password_in_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_in_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_not_in?: Maybe<Array<Scalars['String']>>;
  encrypted_password_not_in_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_not_in_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_cont?: Maybe<Scalars['String']>;
  encrypted_password_cont_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_cont_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_not_cont?: Maybe<Scalars['String']>;
  encrypted_password_not_cont_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_not_cont_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_i_cont?: Maybe<Scalars['String']>;
  encrypted_password_i_cont_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_i_cont_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_not_i_cont?: Maybe<Scalars['String']>;
  encrypted_password_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_start?: Maybe<Scalars['String']>;
  encrypted_password_start_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_start_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_not_start?: Maybe<Scalars['String']>;
  encrypted_password_not_start_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_not_start_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_end?: Maybe<Scalars['String']>;
  encrypted_password_end_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_end_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_not_end?: Maybe<Scalars['String']>;
  encrypted_password_not_end_any?: Maybe<Array<Scalars['String']>>;
  encrypted_password_not_end_all?: Maybe<Array<Scalars['String']>>;
  encrypted_password_true?: Maybe<Scalars['String']>;
  encrypted_password_not_true?: Maybe<Scalars['String']>;
  encrypted_password_false?: Maybe<Scalars['String']>;
  encrypted_password_not_false?: Maybe<Scalars['String']>;
  encrypted_password_present?: Maybe<Scalars['String']>;
  encrypted_password_blank?: Maybe<Scalars['String']>;
  encrypted_password_null?: Maybe<Scalars['String']>;
  encrypted_password_not_null?: Maybe<Scalars['String']>;
  encrypted_password_count?: Maybe<Scalars['String']>;
  encrypted_password_count_distinct?: Maybe<Scalars['String']>;
  encrypted_password_sum?: Maybe<Scalars['String']>;
  encrypted_password_avg?: Maybe<Scalars['String']>;
  encrypted_password_min?: Maybe<Scalars['String']>;
  encrypted_password_max?: Maybe<Scalars['String']>;
  tenant_eq?: Maybe<Scalars['String']>;
  tenant_eq_any?: Maybe<Array<Scalars['String']>>;
  tenant_eq_all?: Maybe<Array<Scalars['String']>>;
  tenant_not_eq?: Maybe<Scalars['String']>;
  tenant_not_eq_any?: Maybe<Array<Scalars['String']>>;
  tenant_not_eq_all?: Maybe<Array<Scalars['String']>>;
  tenant_matches?: Maybe<Scalars['String']>;
  tenant_matches_any?: Maybe<Array<Scalars['String']>>;
  tenant_matches_all?: Maybe<Array<Scalars['String']>>;
  tenant_does_not_match?: Maybe<Scalars['String']>;
  tenant_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  tenant_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  tenant_lt?: Maybe<Scalars['String']>;
  tenant_lt_any?: Maybe<Array<Scalars['String']>>;
  tenant_lt_all?: Maybe<Array<Scalars['String']>>;
  tenant_lteq?: Maybe<Scalars['String']>;
  tenant_lteq_any?: Maybe<Array<Scalars['String']>>;
  tenant_lteq_all?: Maybe<Array<Scalars['String']>>;
  tenant_gt?: Maybe<Scalars['String']>;
  tenant_gt_any?: Maybe<Array<Scalars['String']>>;
  tenant_gt_all?: Maybe<Array<Scalars['String']>>;
  tenant_gteq?: Maybe<Scalars['String']>;
  tenant_gteq_any?: Maybe<Array<Scalars['String']>>;
  tenant_gteq_all?: Maybe<Array<Scalars['String']>>;
  tenant_in?: Maybe<Array<Scalars['String']>>;
  tenant_in_any?: Maybe<Array<Scalars['String']>>;
  tenant_in_all?: Maybe<Array<Scalars['String']>>;
  tenant_not_in?: Maybe<Array<Scalars['String']>>;
  tenant_not_in_any?: Maybe<Array<Scalars['String']>>;
  tenant_not_in_all?: Maybe<Array<Scalars['String']>>;
  tenant_cont?: Maybe<Scalars['String']>;
  tenant_cont_any?: Maybe<Array<Scalars['String']>>;
  tenant_cont_all?: Maybe<Array<Scalars['String']>>;
  tenant_not_cont?: Maybe<Scalars['String']>;
  tenant_not_cont_any?: Maybe<Array<Scalars['String']>>;
  tenant_not_cont_all?: Maybe<Array<Scalars['String']>>;
  tenant_i_cont?: Maybe<Scalars['String']>;
  tenant_i_cont_any?: Maybe<Array<Scalars['String']>>;
  tenant_i_cont_all?: Maybe<Array<Scalars['String']>>;
  tenant_not_i_cont?: Maybe<Scalars['String']>;
  tenant_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  tenant_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  tenant_start?: Maybe<Scalars['String']>;
  tenant_start_any?: Maybe<Array<Scalars['String']>>;
  tenant_start_all?: Maybe<Array<Scalars['String']>>;
  tenant_not_start?: Maybe<Scalars['String']>;
  tenant_not_start_any?: Maybe<Array<Scalars['String']>>;
  tenant_not_start_all?: Maybe<Array<Scalars['String']>>;
  tenant_end?: Maybe<Scalars['String']>;
  tenant_end_any?: Maybe<Array<Scalars['String']>>;
  tenant_end_all?: Maybe<Array<Scalars['String']>>;
  tenant_not_end?: Maybe<Scalars['String']>;
  tenant_not_end_any?: Maybe<Array<Scalars['String']>>;
  tenant_not_end_all?: Maybe<Array<Scalars['String']>>;
  tenant_true?: Maybe<Scalars['String']>;
  tenant_not_true?: Maybe<Scalars['String']>;
  tenant_false?: Maybe<Scalars['String']>;
  tenant_not_false?: Maybe<Scalars['String']>;
  tenant_present?: Maybe<Scalars['String']>;
  tenant_blank?: Maybe<Scalars['String']>;
  tenant_null?: Maybe<Scalars['String']>;
  tenant_not_null?: Maybe<Scalars['String']>;
  tenant_count?: Maybe<Scalars['String']>;
  tenant_count_distinct?: Maybe<Scalars['String']>;
  tenant_sum?: Maybe<Scalars['String']>;
  tenant_avg?: Maybe<Scalars['String']>;
  tenant_min?: Maybe<Scalars['String']>;
  tenant_max?: Maybe<Scalars['String']>;
  reset_password_token_eq?: Maybe<Scalars['String']>;
  reset_password_token_eq_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_eq_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_not_eq?: Maybe<Scalars['String']>;
  reset_password_token_not_eq_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_not_eq_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_matches?: Maybe<Scalars['String']>;
  reset_password_token_matches_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_matches_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_does_not_match?: Maybe<Scalars['String']>;
  reset_password_token_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_lt?: Maybe<Scalars['String']>;
  reset_password_token_lt_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_lt_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_lteq?: Maybe<Scalars['String']>;
  reset_password_token_lteq_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_lteq_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_gt?: Maybe<Scalars['String']>;
  reset_password_token_gt_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_gt_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_gteq?: Maybe<Scalars['String']>;
  reset_password_token_gteq_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_gteq_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_in?: Maybe<Array<Scalars['String']>>;
  reset_password_token_in_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_in_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_not_in?: Maybe<Array<Scalars['String']>>;
  reset_password_token_not_in_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_not_in_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_cont?: Maybe<Scalars['String']>;
  reset_password_token_cont_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_cont_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_not_cont?: Maybe<Scalars['String']>;
  reset_password_token_not_cont_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_not_cont_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_i_cont?: Maybe<Scalars['String']>;
  reset_password_token_i_cont_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_i_cont_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_not_i_cont?: Maybe<Scalars['String']>;
  reset_password_token_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_start?: Maybe<Scalars['String']>;
  reset_password_token_start_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_start_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_not_start?: Maybe<Scalars['String']>;
  reset_password_token_not_start_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_not_start_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_end?: Maybe<Scalars['String']>;
  reset_password_token_end_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_end_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_not_end?: Maybe<Scalars['String']>;
  reset_password_token_not_end_any?: Maybe<Array<Scalars['String']>>;
  reset_password_token_not_end_all?: Maybe<Array<Scalars['String']>>;
  reset_password_token_true?: Maybe<Scalars['String']>;
  reset_password_token_not_true?: Maybe<Scalars['String']>;
  reset_password_token_false?: Maybe<Scalars['String']>;
  reset_password_token_not_false?: Maybe<Scalars['String']>;
  reset_password_token_present?: Maybe<Scalars['String']>;
  reset_password_token_blank?: Maybe<Scalars['String']>;
  reset_password_token_null?: Maybe<Scalars['String']>;
  reset_password_token_not_null?: Maybe<Scalars['String']>;
  reset_password_token_count?: Maybe<Scalars['String']>;
  reset_password_token_count_distinct?: Maybe<Scalars['String']>;
  reset_password_token_sum?: Maybe<Scalars['String']>;
  reset_password_token_avg?: Maybe<Scalars['String']>;
  reset_password_token_min?: Maybe<Scalars['String']>;
  reset_password_token_max?: Maybe<Scalars['String']>;
  reset_password_sent_at_eq?: Maybe<Scalars['String']>;
  reset_password_sent_at_eq_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_eq_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_not_eq?: Maybe<Scalars['String']>;
  reset_password_sent_at_not_eq_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_not_eq_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_matches?: Maybe<Scalars['String']>;
  reset_password_sent_at_matches_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_matches_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_does_not_match?: Maybe<Scalars['String']>;
  reset_password_sent_at_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_lt?: Maybe<Scalars['String']>;
  reset_password_sent_at_lt_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_lt_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_lteq?: Maybe<Scalars['String']>;
  reset_password_sent_at_lteq_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_lteq_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_gt?: Maybe<Scalars['String']>;
  reset_password_sent_at_gt_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_gt_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_gteq?: Maybe<Scalars['String']>;
  reset_password_sent_at_gteq_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_gteq_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_in?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_in_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_in_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_not_in?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_not_in_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_not_in_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_cont?: Maybe<Scalars['String']>;
  reset_password_sent_at_cont_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_cont_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_not_cont?: Maybe<Scalars['String']>;
  reset_password_sent_at_not_cont_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_not_cont_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_i_cont?: Maybe<Scalars['String']>;
  reset_password_sent_at_i_cont_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_i_cont_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_not_i_cont?: Maybe<Scalars['String']>;
  reset_password_sent_at_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_start?: Maybe<Scalars['String']>;
  reset_password_sent_at_start_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_start_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_not_start?: Maybe<Scalars['String']>;
  reset_password_sent_at_not_start_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_not_start_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_end?: Maybe<Scalars['String']>;
  reset_password_sent_at_end_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_end_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_not_end?: Maybe<Scalars['String']>;
  reset_password_sent_at_not_end_any?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_not_end_all?: Maybe<Array<Scalars['String']>>;
  reset_password_sent_at_true?: Maybe<Scalars['String']>;
  reset_password_sent_at_not_true?: Maybe<Scalars['String']>;
  reset_password_sent_at_false?: Maybe<Scalars['String']>;
  reset_password_sent_at_not_false?: Maybe<Scalars['String']>;
  reset_password_sent_at_present?: Maybe<Scalars['String']>;
  reset_password_sent_at_blank?: Maybe<Scalars['String']>;
  reset_password_sent_at_null?: Maybe<Scalars['String']>;
  reset_password_sent_at_not_null?: Maybe<Scalars['String']>;
  reset_password_sent_at_count?: Maybe<Scalars['String']>;
  reset_password_sent_at_count_distinct?: Maybe<Scalars['String']>;
  reset_password_sent_at_sum?: Maybe<Scalars['String']>;
  reset_password_sent_at_avg?: Maybe<Scalars['String']>;
  reset_password_sent_at_min?: Maybe<Scalars['String']>;
  reset_password_sent_at_max?: Maybe<Scalars['String']>;
  remember_created_at_eq?: Maybe<Scalars['String']>;
  remember_created_at_eq_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_eq_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_not_eq?: Maybe<Scalars['String']>;
  remember_created_at_not_eq_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_not_eq_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_matches?: Maybe<Scalars['String']>;
  remember_created_at_matches_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_matches_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_does_not_match?: Maybe<Scalars['String']>;
  remember_created_at_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_lt?: Maybe<Scalars['String']>;
  remember_created_at_lt_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_lt_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_lteq?: Maybe<Scalars['String']>;
  remember_created_at_lteq_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_lteq_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_gt?: Maybe<Scalars['String']>;
  remember_created_at_gt_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_gt_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_gteq?: Maybe<Scalars['String']>;
  remember_created_at_gteq_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_gteq_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_in?: Maybe<Array<Scalars['String']>>;
  remember_created_at_in_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_in_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_not_in?: Maybe<Array<Scalars['String']>>;
  remember_created_at_not_in_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_not_in_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_cont?: Maybe<Scalars['String']>;
  remember_created_at_cont_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_cont_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_not_cont?: Maybe<Scalars['String']>;
  remember_created_at_not_cont_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_not_cont_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_i_cont?: Maybe<Scalars['String']>;
  remember_created_at_i_cont_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_i_cont_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_not_i_cont?: Maybe<Scalars['String']>;
  remember_created_at_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_start?: Maybe<Scalars['String']>;
  remember_created_at_start_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_start_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_not_start?: Maybe<Scalars['String']>;
  remember_created_at_not_start_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_not_start_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_end?: Maybe<Scalars['String']>;
  remember_created_at_end_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_end_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_not_end?: Maybe<Scalars['String']>;
  remember_created_at_not_end_any?: Maybe<Array<Scalars['String']>>;
  remember_created_at_not_end_all?: Maybe<Array<Scalars['String']>>;
  remember_created_at_true?: Maybe<Scalars['String']>;
  remember_created_at_not_true?: Maybe<Scalars['String']>;
  remember_created_at_false?: Maybe<Scalars['String']>;
  remember_created_at_not_false?: Maybe<Scalars['String']>;
  remember_created_at_present?: Maybe<Scalars['String']>;
  remember_created_at_blank?: Maybe<Scalars['String']>;
  remember_created_at_null?: Maybe<Scalars['String']>;
  remember_created_at_not_null?: Maybe<Scalars['String']>;
  remember_created_at_count?: Maybe<Scalars['String']>;
  remember_created_at_count_distinct?: Maybe<Scalars['String']>;
  remember_created_at_sum?: Maybe<Scalars['String']>;
  remember_created_at_avg?: Maybe<Scalars['String']>;
  remember_created_at_min?: Maybe<Scalars['String']>;
  remember_created_at_max?: Maybe<Scalars['String']>;
  sign_in_count_eq?: Maybe<Scalars['String']>;
  sign_in_count_eq_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_eq_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_not_eq?: Maybe<Scalars['String']>;
  sign_in_count_not_eq_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_not_eq_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_matches?: Maybe<Scalars['String']>;
  sign_in_count_matches_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_matches_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_does_not_match?: Maybe<Scalars['String']>;
  sign_in_count_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_lt?: Maybe<Scalars['String']>;
  sign_in_count_lt_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_lt_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_lteq?: Maybe<Scalars['String']>;
  sign_in_count_lteq_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_lteq_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_gt?: Maybe<Scalars['String']>;
  sign_in_count_gt_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_gt_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_gteq?: Maybe<Scalars['String']>;
  sign_in_count_gteq_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_gteq_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_in?: Maybe<Array<Scalars['String']>>;
  sign_in_count_in_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_in_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_not_in?: Maybe<Array<Scalars['String']>>;
  sign_in_count_not_in_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_not_in_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_cont?: Maybe<Scalars['String']>;
  sign_in_count_cont_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_cont_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_not_cont?: Maybe<Scalars['String']>;
  sign_in_count_not_cont_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_not_cont_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_i_cont?: Maybe<Scalars['String']>;
  sign_in_count_i_cont_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_i_cont_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_not_i_cont?: Maybe<Scalars['String']>;
  sign_in_count_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_start?: Maybe<Scalars['String']>;
  sign_in_count_start_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_start_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_not_start?: Maybe<Scalars['String']>;
  sign_in_count_not_start_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_not_start_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_end?: Maybe<Scalars['String']>;
  sign_in_count_end_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_end_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_not_end?: Maybe<Scalars['String']>;
  sign_in_count_not_end_any?: Maybe<Array<Scalars['String']>>;
  sign_in_count_not_end_all?: Maybe<Array<Scalars['String']>>;
  sign_in_count_true?: Maybe<Scalars['String']>;
  sign_in_count_not_true?: Maybe<Scalars['String']>;
  sign_in_count_false?: Maybe<Scalars['String']>;
  sign_in_count_not_false?: Maybe<Scalars['String']>;
  sign_in_count_present?: Maybe<Scalars['String']>;
  sign_in_count_blank?: Maybe<Scalars['String']>;
  sign_in_count_null?: Maybe<Scalars['String']>;
  sign_in_count_not_null?: Maybe<Scalars['String']>;
  sign_in_count_count?: Maybe<Scalars['String']>;
  sign_in_count_count_distinct?: Maybe<Scalars['String']>;
  sign_in_count_sum?: Maybe<Scalars['String']>;
  sign_in_count_avg?: Maybe<Scalars['String']>;
  sign_in_count_min?: Maybe<Scalars['String']>;
  sign_in_count_max?: Maybe<Scalars['String']>;
  current_sign_in_at_eq?: Maybe<Scalars['String']>;
  current_sign_in_at_eq_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_eq_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_not_eq?: Maybe<Scalars['String']>;
  current_sign_in_at_not_eq_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_not_eq_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_matches?: Maybe<Scalars['String']>;
  current_sign_in_at_matches_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_matches_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_does_not_match?: Maybe<Scalars['String']>;
  current_sign_in_at_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_lt?: Maybe<Scalars['String']>;
  current_sign_in_at_lt_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_lt_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_lteq?: Maybe<Scalars['String']>;
  current_sign_in_at_lteq_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_lteq_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_gt?: Maybe<Scalars['String']>;
  current_sign_in_at_gt_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_gt_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_gteq?: Maybe<Scalars['String']>;
  current_sign_in_at_gteq_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_gteq_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_in?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_in_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_in_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_not_in?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_not_in_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_not_in_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_cont?: Maybe<Scalars['String']>;
  current_sign_in_at_cont_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_cont_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_not_cont?: Maybe<Scalars['String']>;
  current_sign_in_at_not_cont_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_not_cont_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_i_cont?: Maybe<Scalars['String']>;
  current_sign_in_at_i_cont_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_i_cont_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_not_i_cont?: Maybe<Scalars['String']>;
  current_sign_in_at_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_start?: Maybe<Scalars['String']>;
  current_sign_in_at_start_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_start_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_not_start?: Maybe<Scalars['String']>;
  current_sign_in_at_not_start_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_not_start_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_end?: Maybe<Scalars['String']>;
  current_sign_in_at_end_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_end_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_not_end?: Maybe<Scalars['String']>;
  current_sign_in_at_not_end_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_not_end_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_at_true?: Maybe<Scalars['String']>;
  current_sign_in_at_not_true?: Maybe<Scalars['String']>;
  current_sign_in_at_false?: Maybe<Scalars['String']>;
  current_sign_in_at_not_false?: Maybe<Scalars['String']>;
  current_sign_in_at_present?: Maybe<Scalars['String']>;
  current_sign_in_at_blank?: Maybe<Scalars['String']>;
  current_sign_in_at_null?: Maybe<Scalars['String']>;
  current_sign_in_at_not_null?: Maybe<Scalars['String']>;
  current_sign_in_at_count?: Maybe<Scalars['String']>;
  current_sign_in_at_count_distinct?: Maybe<Scalars['String']>;
  current_sign_in_at_sum?: Maybe<Scalars['String']>;
  current_sign_in_at_avg?: Maybe<Scalars['String']>;
  current_sign_in_at_min?: Maybe<Scalars['String']>;
  current_sign_in_at_max?: Maybe<Scalars['String']>;
  last_sign_in_at_eq?: Maybe<Scalars['String']>;
  last_sign_in_at_eq_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_eq_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_not_eq?: Maybe<Scalars['String']>;
  last_sign_in_at_not_eq_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_not_eq_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_matches?: Maybe<Scalars['String']>;
  last_sign_in_at_matches_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_matches_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_does_not_match?: Maybe<Scalars['String']>;
  last_sign_in_at_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_lt?: Maybe<Scalars['String']>;
  last_sign_in_at_lt_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_lt_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_lteq?: Maybe<Scalars['String']>;
  last_sign_in_at_lteq_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_lteq_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_gt?: Maybe<Scalars['String']>;
  last_sign_in_at_gt_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_gt_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_gteq?: Maybe<Scalars['String']>;
  last_sign_in_at_gteq_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_gteq_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_in?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_in_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_in_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_not_in?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_not_in_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_not_in_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_cont?: Maybe<Scalars['String']>;
  last_sign_in_at_cont_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_cont_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_not_cont?: Maybe<Scalars['String']>;
  last_sign_in_at_not_cont_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_not_cont_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_i_cont?: Maybe<Scalars['String']>;
  last_sign_in_at_i_cont_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_i_cont_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_not_i_cont?: Maybe<Scalars['String']>;
  last_sign_in_at_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_start?: Maybe<Scalars['String']>;
  last_sign_in_at_start_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_start_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_not_start?: Maybe<Scalars['String']>;
  last_sign_in_at_not_start_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_not_start_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_end?: Maybe<Scalars['String']>;
  last_sign_in_at_end_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_end_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_not_end?: Maybe<Scalars['String']>;
  last_sign_in_at_not_end_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_not_end_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_at_true?: Maybe<Scalars['String']>;
  last_sign_in_at_not_true?: Maybe<Scalars['String']>;
  last_sign_in_at_false?: Maybe<Scalars['String']>;
  last_sign_in_at_not_false?: Maybe<Scalars['String']>;
  last_sign_in_at_present?: Maybe<Scalars['String']>;
  last_sign_in_at_blank?: Maybe<Scalars['String']>;
  last_sign_in_at_null?: Maybe<Scalars['String']>;
  last_sign_in_at_not_null?: Maybe<Scalars['String']>;
  last_sign_in_at_count?: Maybe<Scalars['String']>;
  last_sign_in_at_count_distinct?: Maybe<Scalars['String']>;
  last_sign_in_at_sum?: Maybe<Scalars['String']>;
  last_sign_in_at_avg?: Maybe<Scalars['String']>;
  last_sign_in_at_min?: Maybe<Scalars['String']>;
  last_sign_in_at_max?: Maybe<Scalars['String']>;
  current_sign_in_ip_eq?: Maybe<Scalars['String']>;
  current_sign_in_ip_eq_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_eq_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_not_eq?: Maybe<Scalars['String']>;
  current_sign_in_ip_not_eq_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_not_eq_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_matches?: Maybe<Scalars['String']>;
  current_sign_in_ip_matches_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_matches_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_does_not_match?: Maybe<Scalars['String']>;
  current_sign_in_ip_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_lt?: Maybe<Scalars['String']>;
  current_sign_in_ip_lt_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_lt_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_lteq?: Maybe<Scalars['String']>;
  current_sign_in_ip_lteq_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_lteq_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_gt?: Maybe<Scalars['String']>;
  current_sign_in_ip_gt_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_gt_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_gteq?: Maybe<Scalars['String']>;
  current_sign_in_ip_gteq_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_gteq_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_in?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_in_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_in_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_not_in?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_not_in_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_not_in_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_cont?: Maybe<Scalars['String']>;
  current_sign_in_ip_cont_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_cont_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_not_cont?: Maybe<Scalars['String']>;
  current_sign_in_ip_not_cont_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_not_cont_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_i_cont?: Maybe<Scalars['String']>;
  current_sign_in_ip_i_cont_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_i_cont_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_not_i_cont?: Maybe<Scalars['String']>;
  current_sign_in_ip_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_start?: Maybe<Scalars['String']>;
  current_sign_in_ip_start_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_start_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_not_start?: Maybe<Scalars['String']>;
  current_sign_in_ip_not_start_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_not_start_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_end?: Maybe<Scalars['String']>;
  current_sign_in_ip_end_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_end_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_not_end?: Maybe<Scalars['String']>;
  current_sign_in_ip_not_end_any?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_not_end_all?: Maybe<Array<Scalars['String']>>;
  current_sign_in_ip_true?: Maybe<Scalars['String']>;
  current_sign_in_ip_not_true?: Maybe<Scalars['String']>;
  current_sign_in_ip_false?: Maybe<Scalars['String']>;
  current_sign_in_ip_not_false?: Maybe<Scalars['String']>;
  current_sign_in_ip_present?: Maybe<Scalars['String']>;
  current_sign_in_ip_blank?: Maybe<Scalars['String']>;
  current_sign_in_ip_null?: Maybe<Scalars['String']>;
  current_sign_in_ip_not_null?: Maybe<Scalars['String']>;
  current_sign_in_ip_count?: Maybe<Scalars['String']>;
  current_sign_in_ip_count_distinct?: Maybe<Scalars['String']>;
  current_sign_in_ip_sum?: Maybe<Scalars['String']>;
  current_sign_in_ip_avg?: Maybe<Scalars['String']>;
  current_sign_in_ip_min?: Maybe<Scalars['String']>;
  current_sign_in_ip_max?: Maybe<Scalars['String']>;
  last_sign_in_ip_eq?: Maybe<Scalars['String']>;
  last_sign_in_ip_eq_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_eq_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_not_eq?: Maybe<Scalars['String']>;
  last_sign_in_ip_not_eq_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_not_eq_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_matches?: Maybe<Scalars['String']>;
  last_sign_in_ip_matches_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_matches_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_does_not_match?: Maybe<Scalars['String']>;
  last_sign_in_ip_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_lt?: Maybe<Scalars['String']>;
  last_sign_in_ip_lt_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_lt_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_lteq?: Maybe<Scalars['String']>;
  last_sign_in_ip_lteq_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_lteq_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_gt?: Maybe<Scalars['String']>;
  last_sign_in_ip_gt_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_gt_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_gteq?: Maybe<Scalars['String']>;
  last_sign_in_ip_gteq_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_gteq_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_in?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_in_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_in_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_not_in?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_not_in_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_not_in_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_cont?: Maybe<Scalars['String']>;
  last_sign_in_ip_cont_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_cont_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_not_cont?: Maybe<Scalars['String']>;
  last_sign_in_ip_not_cont_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_not_cont_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_i_cont?: Maybe<Scalars['String']>;
  last_sign_in_ip_i_cont_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_i_cont_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_not_i_cont?: Maybe<Scalars['String']>;
  last_sign_in_ip_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_start?: Maybe<Scalars['String']>;
  last_sign_in_ip_start_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_start_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_not_start?: Maybe<Scalars['String']>;
  last_sign_in_ip_not_start_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_not_start_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_end?: Maybe<Scalars['String']>;
  last_sign_in_ip_end_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_end_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_not_end?: Maybe<Scalars['String']>;
  last_sign_in_ip_not_end_any?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_not_end_all?: Maybe<Array<Scalars['String']>>;
  last_sign_in_ip_true?: Maybe<Scalars['String']>;
  last_sign_in_ip_not_true?: Maybe<Scalars['String']>;
  last_sign_in_ip_false?: Maybe<Scalars['String']>;
  last_sign_in_ip_not_false?: Maybe<Scalars['String']>;
  last_sign_in_ip_present?: Maybe<Scalars['String']>;
  last_sign_in_ip_blank?: Maybe<Scalars['String']>;
  last_sign_in_ip_null?: Maybe<Scalars['String']>;
  last_sign_in_ip_not_null?: Maybe<Scalars['String']>;
  last_sign_in_ip_count?: Maybe<Scalars['String']>;
  last_sign_in_ip_count_distinct?: Maybe<Scalars['String']>;
  last_sign_in_ip_sum?: Maybe<Scalars['String']>;
  last_sign_in_ip_avg?: Maybe<Scalars['String']>;
  last_sign_in_ip_min?: Maybe<Scalars['String']>;
  last_sign_in_ip_max?: Maybe<Scalars['String']>;
  created_at_eq?: Maybe<Scalars['String']>;
  created_at_eq_any?: Maybe<Array<Scalars['String']>>;
  created_at_eq_all?: Maybe<Array<Scalars['String']>>;
  created_at_not_eq?: Maybe<Scalars['String']>;
  created_at_not_eq_any?: Maybe<Array<Scalars['String']>>;
  created_at_not_eq_all?: Maybe<Array<Scalars['String']>>;
  created_at_matches?: Maybe<Scalars['String']>;
  created_at_matches_any?: Maybe<Array<Scalars['String']>>;
  created_at_matches_all?: Maybe<Array<Scalars['String']>>;
  created_at_does_not_match?: Maybe<Scalars['String']>;
  created_at_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  created_at_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  created_at_lt?: Maybe<Scalars['String']>;
  created_at_lt_any?: Maybe<Array<Scalars['String']>>;
  created_at_lt_all?: Maybe<Array<Scalars['String']>>;
  created_at_lteq?: Maybe<Scalars['String']>;
  created_at_lteq_any?: Maybe<Array<Scalars['String']>>;
  created_at_lteq_all?: Maybe<Array<Scalars['String']>>;
  created_at_gt?: Maybe<Scalars['String']>;
  created_at_gt_any?: Maybe<Array<Scalars['String']>>;
  created_at_gt_all?: Maybe<Array<Scalars['String']>>;
  created_at_gteq?: Maybe<Scalars['String']>;
  created_at_gteq_any?: Maybe<Array<Scalars['String']>>;
  created_at_gteq_all?: Maybe<Array<Scalars['String']>>;
  created_at_in?: Maybe<Array<Scalars['String']>>;
  created_at_in_any?: Maybe<Array<Scalars['String']>>;
  created_at_in_all?: Maybe<Array<Scalars['String']>>;
  created_at_not_in?: Maybe<Array<Scalars['String']>>;
  created_at_not_in_any?: Maybe<Array<Scalars['String']>>;
  created_at_not_in_all?: Maybe<Array<Scalars['String']>>;
  created_at_cont?: Maybe<Scalars['String']>;
  created_at_cont_any?: Maybe<Array<Scalars['String']>>;
  created_at_cont_all?: Maybe<Array<Scalars['String']>>;
  created_at_not_cont?: Maybe<Scalars['String']>;
  created_at_not_cont_any?: Maybe<Array<Scalars['String']>>;
  created_at_not_cont_all?: Maybe<Array<Scalars['String']>>;
  created_at_i_cont?: Maybe<Scalars['String']>;
  created_at_i_cont_any?: Maybe<Array<Scalars['String']>>;
  created_at_i_cont_all?: Maybe<Array<Scalars['String']>>;
  created_at_not_i_cont?: Maybe<Scalars['String']>;
  created_at_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  created_at_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  created_at_start?: Maybe<Scalars['String']>;
  created_at_start_any?: Maybe<Array<Scalars['String']>>;
  created_at_start_all?: Maybe<Array<Scalars['String']>>;
  created_at_not_start?: Maybe<Scalars['String']>;
  created_at_not_start_any?: Maybe<Array<Scalars['String']>>;
  created_at_not_start_all?: Maybe<Array<Scalars['String']>>;
  created_at_end?: Maybe<Scalars['String']>;
  created_at_end_any?: Maybe<Array<Scalars['String']>>;
  created_at_end_all?: Maybe<Array<Scalars['String']>>;
  created_at_not_end?: Maybe<Scalars['String']>;
  created_at_not_end_any?: Maybe<Array<Scalars['String']>>;
  created_at_not_end_all?: Maybe<Array<Scalars['String']>>;
  created_at_true?: Maybe<Scalars['String']>;
  created_at_not_true?: Maybe<Scalars['String']>;
  created_at_false?: Maybe<Scalars['String']>;
  created_at_not_false?: Maybe<Scalars['String']>;
  created_at_present?: Maybe<Scalars['String']>;
  created_at_blank?: Maybe<Scalars['String']>;
  created_at_null?: Maybe<Scalars['String']>;
  created_at_not_null?: Maybe<Scalars['String']>;
  created_at_count?: Maybe<Scalars['String']>;
  created_at_count_distinct?: Maybe<Scalars['String']>;
  created_at_sum?: Maybe<Scalars['String']>;
  created_at_avg?: Maybe<Scalars['String']>;
  created_at_min?: Maybe<Scalars['String']>;
  created_at_max?: Maybe<Scalars['String']>;
  updated_at_eq?: Maybe<Scalars['String']>;
  updated_at_eq_any?: Maybe<Array<Scalars['String']>>;
  updated_at_eq_all?: Maybe<Array<Scalars['String']>>;
  updated_at_not_eq?: Maybe<Scalars['String']>;
  updated_at_not_eq_any?: Maybe<Array<Scalars['String']>>;
  updated_at_not_eq_all?: Maybe<Array<Scalars['String']>>;
  updated_at_matches?: Maybe<Scalars['String']>;
  updated_at_matches_any?: Maybe<Array<Scalars['String']>>;
  updated_at_matches_all?: Maybe<Array<Scalars['String']>>;
  updated_at_does_not_match?: Maybe<Scalars['String']>;
  updated_at_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  updated_at_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  updated_at_lt?: Maybe<Scalars['String']>;
  updated_at_lt_any?: Maybe<Array<Scalars['String']>>;
  updated_at_lt_all?: Maybe<Array<Scalars['String']>>;
  updated_at_lteq?: Maybe<Scalars['String']>;
  updated_at_lteq_any?: Maybe<Array<Scalars['String']>>;
  updated_at_lteq_all?: Maybe<Array<Scalars['String']>>;
  updated_at_gt?: Maybe<Scalars['String']>;
  updated_at_gt_any?: Maybe<Array<Scalars['String']>>;
  updated_at_gt_all?: Maybe<Array<Scalars['String']>>;
  updated_at_gteq?: Maybe<Scalars['String']>;
  updated_at_gteq_any?: Maybe<Array<Scalars['String']>>;
  updated_at_gteq_all?: Maybe<Array<Scalars['String']>>;
  updated_at_in?: Maybe<Array<Scalars['String']>>;
  updated_at_in_any?: Maybe<Array<Scalars['String']>>;
  updated_at_in_all?: Maybe<Array<Scalars['String']>>;
  updated_at_not_in?: Maybe<Array<Scalars['String']>>;
  updated_at_not_in_any?: Maybe<Array<Scalars['String']>>;
  updated_at_not_in_all?: Maybe<Array<Scalars['String']>>;
  updated_at_cont?: Maybe<Scalars['String']>;
  updated_at_cont_any?: Maybe<Array<Scalars['String']>>;
  updated_at_cont_all?: Maybe<Array<Scalars['String']>>;
  updated_at_not_cont?: Maybe<Scalars['String']>;
  updated_at_not_cont_any?: Maybe<Array<Scalars['String']>>;
  updated_at_not_cont_all?: Maybe<Array<Scalars['String']>>;
  updated_at_i_cont?: Maybe<Scalars['String']>;
  updated_at_i_cont_any?: Maybe<Array<Scalars['String']>>;
  updated_at_i_cont_all?: Maybe<Array<Scalars['String']>>;
  updated_at_not_i_cont?: Maybe<Scalars['String']>;
  updated_at_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  updated_at_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  updated_at_start?: Maybe<Scalars['String']>;
  updated_at_start_any?: Maybe<Array<Scalars['String']>>;
  updated_at_start_all?: Maybe<Array<Scalars['String']>>;
  updated_at_not_start?: Maybe<Scalars['String']>;
  updated_at_not_start_any?: Maybe<Array<Scalars['String']>>;
  updated_at_not_start_all?: Maybe<Array<Scalars['String']>>;
  updated_at_end?: Maybe<Scalars['String']>;
  updated_at_end_any?: Maybe<Array<Scalars['String']>>;
  updated_at_end_all?: Maybe<Array<Scalars['String']>>;
  updated_at_not_end?: Maybe<Scalars['String']>;
  updated_at_not_end_any?: Maybe<Array<Scalars['String']>>;
  updated_at_not_end_all?: Maybe<Array<Scalars['String']>>;
  updated_at_true?: Maybe<Scalars['String']>;
  updated_at_not_true?: Maybe<Scalars['String']>;
  updated_at_false?: Maybe<Scalars['String']>;
  updated_at_not_false?: Maybe<Scalars['String']>;
  updated_at_present?: Maybe<Scalars['String']>;
  updated_at_blank?: Maybe<Scalars['String']>;
  updated_at_null?: Maybe<Scalars['String']>;
  updated_at_not_null?: Maybe<Scalars['String']>;
  updated_at_count?: Maybe<Scalars['String']>;
  updated_at_count_distinct?: Maybe<Scalars['String']>;
  updated_at_sum?: Maybe<Scalars['String']>;
  updated_at_avg?: Maybe<Scalars['String']>;
  updated_at_min?: Maybe<Scalars['String']>;
  updated_at_max?: Maybe<Scalars['String']>;
  tenants_eq?: Maybe<Scalars['String']>;
  tenants_eq_any?: Maybe<Array<Scalars['String']>>;
  tenants_eq_all?: Maybe<Array<Scalars['String']>>;
  tenants_not_eq?: Maybe<Scalars['String']>;
  tenants_not_eq_any?: Maybe<Array<Scalars['String']>>;
  tenants_not_eq_all?: Maybe<Array<Scalars['String']>>;
  tenants_matches?: Maybe<Scalars['String']>;
  tenants_matches_any?: Maybe<Array<Scalars['String']>>;
  tenants_matches_all?: Maybe<Array<Scalars['String']>>;
  tenants_does_not_match?: Maybe<Scalars['String']>;
  tenants_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  tenants_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  tenants_lt?: Maybe<Scalars['String']>;
  tenants_lt_any?: Maybe<Array<Scalars['String']>>;
  tenants_lt_all?: Maybe<Array<Scalars['String']>>;
  tenants_lteq?: Maybe<Scalars['String']>;
  tenants_lteq_any?: Maybe<Array<Scalars['String']>>;
  tenants_lteq_all?: Maybe<Array<Scalars['String']>>;
  tenants_gt?: Maybe<Scalars['String']>;
  tenants_gt_any?: Maybe<Array<Scalars['String']>>;
  tenants_gt_all?: Maybe<Array<Scalars['String']>>;
  tenants_gteq?: Maybe<Scalars['String']>;
  tenants_gteq_any?: Maybe<Array<Scalars['String']>>;
  tenants_gteq_all?: Maybe<Array<Scalars['String']>>;
  tenants_in?: Maybe<Array<Scalars['String']>>;
  tenants_in_any?: Maybe<Array<Scalars['String']>>;
  tenants_in_all?: Maybe<Array<Scalars['String']>>;
  tenants_not_in?: Maybe<Array<Scalars['String']>>;
  tenants_not_in_any?: Maybe<Array<Scalars['String']>>;
  tenants_not_in_all?: Maybe<Array<Scalars['String']>>;
  tenants_cont?: Maybe<Scalars['String']>;
  tenants_cont_any?: Maybe<Array<Scalars['String']>>;
  tenants_cont_all?: Maybe<Array<Scalars['String']>>;
  tenants_not_cont?: Maybe<Scalars['String']>;
  tenants_not_cont_any?: Maybe<Array<Scalars['String']>>;
  tenants_not_cont_all?: Maybe<Array<Scalars['String']>>;
  tenants_i_cont?: Maybe<Scalars['String']>;
  tenants_i_cont_any?: Maybe<Array<Scalars['String']>>;
  tenants_i_cont_all?: Maybe<Array<Scalars['String']>>;
  tenants_not_i_cont?: Maybe<Scalars['String']>;
  tenants_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  tenants_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  tenants_start?: Maybe<Scalars['String']>;
  tenants_start_any?: Maybe<Array<Scalars['String']>>;
  tenants_start_all?: Maybe<Array<Scalars['String']>>;
  tenants_not_start?: Maybe<Scalars['String']>;
  tenants_not_start_any?: Maybe<Array<Scalars['String']>>;
  tenants_not_start_all?: Maybe<Array<Scalars['String']>>;
  tenants_end?: Maybe<Scalars['String']>;
  tenants_end_any?: Maybe<Array<Scalars['String']>>;
  tenants_end_all?: Maybe<Array<Scalars['String']>>;
  tenants_not_end?: Maybe<Scalars['String']>;
  tenants_not_end_any?: Maybe<Array<Scalars['String']>>;
  tenants_not_end_all?: Maybe<Array<Scalars['String']>>;
  tenants_true?: Maybe<Scalars['String']>;
  tenants_not_true?: Maybe<Scalars['String']>;
  tenants_false?: Maybe<Scalars['String']>;
  tenants_not_false?: Maybe<Scalars['String']>;
  tenants_present?: Maybe<Scalars['String']>;
  tenants_blank?: Maybe<Scalars['String']>;
  tenants_null?: Maybe<Scalars['String']>;
  tenants_not_null?: Maybe<Scalars['String']>;
  tenants_count?: Maybe<Scalars['String']>;
  tenants_count_distinct?: Maybe<Scalars['String']>;
  tenants_sum?: Maybe<Scalars['String']>;
  tenants_avg?: Maybe<Scalars['String']>;
  tenants_min?: Maybe<Scalars['String']>;
  tenants_max?: Maybe<Scalars['String']>;
  provider_eq?: Maybe<Scalars['String']>;
  provider_eq_any?: Maybe<Array<Scalars['String']>>;
  provider_eq_all?: Maybe<Array<Scalars['String']>>;
  provider_not_eq?: Maybe<Scalars['String']>;
  provider_not_eq_any?: Maybe<Array<Scalars['String']>>;
  provider_not_eq_all?: Maybe<Array<Scalars['String']>>;
  provider_matches?: Maybe<Scalars['String']>;
  provider_matches_any?: Maybe<Array<Scalars['String']>>;
  provider_matches_all?: Maybe<Array<Scalars['String']>>;
  provider_does_not_match?: Maybe<Scalars['String']>;
  provider_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  provider_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  provider_lt?: Maybe<Scalars['String']>;
  provider_lt_any?: Maybe<Array<Scalars['String']>>;
  provider_lt_all?: Maybe<Array<Scalars['String']>>;
  provider_lteq?: Maybe<Scalars['String']>;
  provider_lteq_any?: Maybe<Array<Scalars['String']>>;
  provider_lteq_all?: Maybe<Array<Scalars['String']>>;
  provider_gt?: Maybe<Scalars['String']>;
  provider_gt_any?: Maybe<Array<Scalars['String']>>;
  provider_gt_all?: Maybe<Array<Scalars['String']>>;
  provider_gteq?: Maybe<Scalars['String']>;
  provider_gteq_any?: Maybe<Array<Scalars['String']>>;
  provider_gteq_all?: Maybe<Array<Scalars['String']>>;
  provider_in?: Maybe<Array<Scalars['String']>>;
  provider_in_any?: Maybe<Array<Scalars['String']>>;
  provider_in_all?: Maybe<Array<Scalars['String']>>;
  provider_not_in?: Maybe<Array<Scalars['String']>>;
  provider_not_in_any?: Maybe<Array<Scalars['String']>>;
  provider_not_in_all?: Maybe<Array<Scalars['String']>>;
  provider_cont?: Maybe<Scalars['String']>;
  provider_cont_any?: Maybe<Array<Scalars['String']>>;
  provider_cont_all?: Maybe<Array<Scalars['String']>>;
  provider_not_cont?: Maybe<Scalars['String']>;
  provider_not_cont_any?: Maybe<Array<Scalars['String']>>;
  provider_not_cont_all?: Maybe<Array<Scalars['String']>>;
  provider_i_cont?: Maybe<Scalars['String']>;
  provider_i_cont_any?: Maybe<Array<Scalars['String']>>;
  provider_i_cont_all?: Maybe<Array<Scalars['String']>>;
  provider_not_i_cont?: Maybe<Scalars['String']>;
  provider_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  provider_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  provider_start?: Maybe<Scalars['String']>;
  provider_start_any?: Maybe<Array<Scalars['String']>>;
  provider_start_all?: Maybe<Array<Scalars['String']>>;
  provider_not_start?: Maybe<Scalars['String']>;
  provider_not_start_any?: Maybe<Array<Scalars['String']>>;
  provider_not_start_all?: Maybe<Array<Scalars['String']>>;
  provider_end?: Maybe<Scalars['String']>;
  provider_end_any?: Maybe<Array<Scalars['String']>>;
  provider_end_all?: Maybe<Array<Scalars['String']>>;
  provider_not_end?: Maybe<Scalars['String']>;
  provider_not_end_any?: Maybe<Array<Scalars['String']>>;
  provider_not_end_all?: Maybe<Array<Scalars['String']>>;
  provider_true?: Maybe<Scalars['String']>;
  provider_not_true?: Maybe<Scalars['String']>;
  provider_false?: Maybe<Scalars['String']>;
  provider_not_false?: Maybe<Scalars['String']>;
  provider_present?: Maybe<Scalars['String']>;
  provider_blank?: Maybe<Scalars['String']>;
  provider_null?: Maybe<Scalars['String']>;
  provider_not_null?: Maybe<Scalars['String']>;
  provider_count?: Maybe<Scalars['String']>;
  provider_count_distinct?: Maybe<Scalars['String']>;
  provider_sum?: Maybe<Scalars['String']>;
  provider_avg?: Maybe<Scalars['String']>;
  provider_min?: Maybe<Scalars['String']>;
  provider_max?: Maybe<Scalars['String']>;
  uid_eq?: Maybe<Scalars['String']>;
  uid_eq_any?: Maybe<Array<Scalars['String']>>;
  uid_eq_all?: Maybe<Array<Scalars['String']>>;
  uid_not_eq?: Maybe<Scalars['String']>;
  uid_not_eq_any?: Maybe<Array<Scalars['String']>>;
  uid_not_eq_all?: Maybe<Array<Scalars['String']>>;
  uid_matches?: Maybe<Scalars['String']>;
  uid_matches_any?: Maybe<Array<Scalars['String']>>;
  uid_matches_all?: Maybe<Array<Scalars['String']>>;
  uid_does_not_match?: Maybe<Scalars['String']>;
  uid_does_not_match_any?: Maybe<Array<Scalars['String']>>;
  uid_does_not_match_all?: Maybe<Array<Scalars['String']>>;
  uid_lt?: Maybe<Scalars['String']>;
  uid_lt_any?: Maybe<Array<Scalars['String']>>;
  uid_lt_all?: Maybe<Array<Scalars['String']>>;
  uid_lteq?: Maybe<Scalars['String']>;
  uid_lteq_any?: Maybe<Array<Scalars['String']>>;
  uid_lteq_all?: Maybe<Array<Scalars['String']>>;
  uid_gt?: Maybe<Scalars['String']>;
  uid_gt_any?: Maybe<Array<Scalars['String']>>;
  uid_gt_all?: Maybe<Array<Scalars['String']>>;
  uid_gteq?: Maybe<Scalars['String']>;
  uid_gteq_any?: Maybe<Array<Scalars['String']>>;
  uid_gteq_all?: Maybe<Array<Scalars['String']>>;
  uid_in?: Maybe<Array<Scalars['String']>>;
  uid_in_any?: Maybe<Array<Scalars['String']>>;
  uid_in_all?: Maybe<Array<Scalars['String']>>;
  uid_not_in?: Maybe<Array<Scalars['String']>>;
  uid_not_in_any?: Maybe<Array<Scalars['String']>>;
  uid_not_in_all?: Maybe<Array<Scalars['String']>>;
  uid_cont?: Maybe<Scalars['String']>;
  uid_cont_any?: Maybe<Array<Scalars['String']>>;
  uid_cont_all?: Maybe<Array<Scalars['String']>>;
  uid_not_cont?: Maybe<Scalars['String']>;
  uid_not_cont_any?: Maybe<Array<Scalars['String']>>;
  uid_not_cont_all?: Maybe<Array<Scalars['String']>>;
  uid_i_cont?: Maybe<Scalars['String']>;
  uid_i_cont_any?: Maybe<Array<Scalars['String']>>;
  uid_i_cont_all?: Maybe<Array<Scalars['String']>>;
  uid_not_i_cont?: Maybe<Scalars['String']>;
  uid_not_i_cont_any?: Maybe<Array<Scalars['String']>>;
  uid_not_i_cont_all?: Maybe<Array<Scalars['String']>>;
  uid_start?: Maybe<Scalars['String']>;
  uid_start_any?: Maybe<Array<Scalars['String']>>;
  uid_start_all?: Maybe<Array<Scalars['String']>>;
  uid_not_start?: Maybe<Scalars['String']>;
  uid_not_start_any?: Maybe<Array<Scalars['String']>>;
  uid_not_start_all?: Maybe<Array<Scalars['String']>>;
  uid_end?: Maybe<Scalars['String']>;
  uid_end_any?: Maybe<Array<Scalars['String']>>;
  uid_end_all?: Maybe<Array<Scalars['String']>>;
  uid_not_end?: Maybe<Scalars['String']>;
  uid_not_end_any?: Maybe<Array<Scalars['String']>>;
  uid_not_end_all?: Maybe<Array<Scalars['String']>>;
  uid_true?: Maybe<Scalars['String']>;
  uid_not_true?: Maybe<Scalars['String']>;
  uid_false?: Maybe<Scalars['String']>;
  uid_not_false?: Maybe<Scalars['String']>;
  uid_present?: Maybe<Scalars['String']>;
  uid_blank?: Maybe<Scalars['String']>;
  uid_null?: Maybe<Scalars['String']>;
  uid_not_null?: Maybe<Scalars['String']>;
  uid_count?: Maybe<Scalars['String']>;
  uid_count_distinct?: Maybe<Scalars['String']>;
  uid_sum?: Maybe<Scalars['String']>;
  uid_avg?: Maybe<Scalars['String']>;
  uid_min?: Maybe<Scalars['String']>;
  uid_max?: Maybe<Scalars['String']>;
};

/** Objects that can have accounts */
export type IAccountUser = IServiceAccount | IUser;

export type IActivitiesFilter = {
  search?: Maybe<Scalars['String']>;
};

export type IActivity = {
  __typename?: 'Activity';
  id: Scalars['ID'];
  subject?: Maybe<IActivitySubject>;
};

/** The connection type for Activity. */
export type IActivityConnection = {
  __typename?: 'ActivityConnection';
  /** A list of edges. */
  edges: Array<IActivityEdge>;
  /** A list of nodes. */
  nodes: Array<IActivity>;
  /** Information to aid in pagination */
  pageInfo: IExtendedPageInfo;
};

/** An edge in a connection. */
export type IActivityEdge = {
  __typename?: 'ActivityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: IActivity;
};

/** Objects that can exist in the activity feed */
export type IActivitySubject = IBadgeUnlock | IHighfive;

export type IAdditionalFeedback = {
  __typename?: 'AdditionalFeedback';
  answer?: Maybe<Scalars['String']>;
  boolean?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  expired?: Maybe<Scalars['Boolean']>;
  feedbackAnswer?: Maybe<IAnswer>;
  feedbackGiver?: Maybe<IUser>;
  habitUser?: Maybe<IHabitUser>;
  id: Scalars['ID'];
  prompt?: Maybe<IPrompt>;
  user?: Maybe<IUser>;
};

export type IAdditionalFeedbackGroup = {
  __typename?: 'AdditionalFeedbackGroup';
  additionalFeedbacks?: Maybe<Array<IAdditionalFeedback>>;
  habitUser?: Maybe<IHabitUser>;
  id: Scalars['ID'];
  prompt?: Maybe<IPrompt>;
  status?: Maybe<IAdditionalFeedbackGroupStatus>;
};

export enum IAdditionalFeedbackGroupStatus {
  /** additional feedback group is collecting feedback */
  Active = 'active',
  /** user canceled additional feedback request */
  Canceled = 'canceled',
  /** all feedback has either been completed or has expired */
  Completed = 'completed'
}

export type IAnswer = {
  __typename?: 'Answer';
  answerable?: Maybe<IAnswerable>;
  deckId?: Maybe<Scalars['ID']>;
  habitUser?: Maybe<IHabitUser>;
  id: Scalars['ID'];
  lifespan?: Maybe<Scalars['Int']>;
  respondent?: Maybe<IRespondent>;
  response?: Maybe<Scalars['String']>;
};

/** Objects that can be answered */
export type IAnswerable = IEngagementQuestion | IFeedbackQuestion | IObjectiveCompletionQuestion | IObjectiveQuestion | IQuickPoll;

export type IArticulateLessonCompletion = {
  __typename?: 'ArticulateLessonCompletion';
  courseId: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated input type of AwardCarrots */
export type IAwardCarrotsInput = {
  deckIds: Array<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AwardCarrots */
export type IAwardCarrotsPayload = {
  __typename?: 'AwardCarrotsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profile: IUser;
};

export type IBadge = {
  __typename?: 'Badge';
  description?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  img?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type IBadgeUnlock = {
  __typename?: 'BadgeUnlock';
  badge?: Maybe<IBadge>;
  id: Scalars['ID'];
  users?: Maybe<Array<IUser>>;
};

export type IBehavior = {
  __typename?: 'Behavior';
  active: Scalars['Boolean'];
  anchor: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  frequency: Scalars['Int'];
  habit: IHabit;
  id: Scalars['ID'];
  timeFrame: Scalars['String'];
  widgets: Array<IWidget>;
};

/** The connection type for Behavior. */
export type IBehaviorConnection = {
  __typename?: 'BehaviorConnection';
  /** A list of edges. */
  edges: Array<IBehaviorEdge>;
  /** A list of nodes. */
  nodes: Array<IBehavior>;
  /** Information to aid in pagination */
  pageInfo: IExtendedPageInfo;
};

/** An edge in a connection. */
export type IBehaviorEdge = {
  __typename?: 'BehaviorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: IBehavior;
};

export type IBehaviorsFilter = {
  search?: Maybe<Scalars['String']>;
};

export type IBlogPostWidget = {
  __typename?: 'BlogPostWidget';
  description: Scalars['String'];
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  link: Scalars['String'];
  title: Scalars['String'];
};

/** Autogenerated input type of BulkImportUsers */
export type IBulkImportUsersInput = {
  fileName: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CancelVacation */
export type ICancelVacationInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ICategory = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  objectives: Array<IObjective>;
};

/** Autogenerated input type of CheckPhoneVerification */
export type ICheckPhoneVerificationInput = {
  phoneNumber: Scalars['String'];
  code: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CheckPhoneVerification */
export type ICheckPhoneVerificationPayload = {
  __typename?: 'CheckPhoneVerificationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  user?: Maybe<IUser>;
};

export type IComment = {
  __typename?: 'Comment';
  commentableId: Scalars['String'];
  commentableType: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  likes: Array<ILike>;
  text: Scalars['String'];
  user: IUser;
};

/** Autogenerated input type of CreateBehavior */
export type ICreateBehaviorInput = {
  anchor: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  timeFrame: Scalars['String'];
  habitId: Scalars['ID'];
  frequency: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateCategory */
export type ICreateCategoryInput = {
  name: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateComment */
export type ICreateCommentInput = {
  text: Scalars['String'];
  mentions: Array<Scalars['String']>;
  commentableType: Scalars['String'];
  commentableId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateComment */
export type ICreateCommentPayload = {
  __typename?: 'CreateCommentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<IComment>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of CreateDashboardToken */
export type ICreateDashboardTokenInput = {
  email: Scalars['String'];
  expiresIn: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateExternalUser */
export type ICreateExternalUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateExtraFeedbackRequest */
export type ICreateExtraFeedbackRequestInput = {
  userId: Scalars['ID'];
  habitUserId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateHabit */
export type ICreateHabitInput = {
  name: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  employeeType: Scalars['String'];
  habitType: Scalars['Int'];
  benchmark: Scalars['Float'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateHighfive */
export type ICreateHighfiveInput = {
  recipientIds: Array<Scalars['String']>;
  private: Scalars['Boolean'];
  content: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateHighfive */
export type ICreateHighfivePayload = {
  __typename?: 'CreateHighfivePayload';
  activity?: Maybe<IActivityEdge>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of CreateLibraryObjective */
export type ICreateLibraryObjectiveInput = {
  name: Scalars['String'];
  categoryId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  keyResults: Array<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateLike */
export type ICreateLikeInput = {
  likeableType: Scalars['String'];
  likeableId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateLike */
export type ICreateLikePayload = {
  __typename?: 'CreateLikePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  like: ILike;
};

/** Autogenerated input type of CreateObjective */
export type ICreateObjectiveInput = {
  parentId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description: Scalars['String'];
  startDate: Scalars['String'];
  deliveryDate: Scalars['String'];
  ownerId: Scalars['ID'];
  stakeholderIds: Array<Scalars['ID']>;
  labelIds: Array<Scalars['ID']>;
  autoComplete: Scalars['Boolean'];
  keyResults: Array<Scalars['String']>;
  private: Scalars['Boolean'];
  checkInInterval: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateOneOnOnes */
export type ICreateOneOnOnesInput = {
  userIds: Array<Scalars['ID']>;
  label: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  includeHabits: Scalars['Boolean'];
  includeObjectives: Scalars['Boolean'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateRelationship */
export type ICreateRelationshipInput = {
  relatedUserId: Scalars['ID'];
  relatingUserId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateReview */
export type ICreateReviewInput = {
  objectiveId?: Maybe<Scalars['ID']>;
  score?: Maybe<Scalars['Int']>;
  context?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateRole */
export type ICreateRoleInput = {
  name: Scalars['String'];
  habitIds: Array<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateSuggestion */
export type ICreateSuggestionInput = {
  content: Scalars['String'];
  followUp: Scalars['Boolean'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateTalkingPoint */
export type ICreateTalkingPointInput = {
  oneOnOneId: Scalars['ID'];
  content: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateTeam */
export type ICreateTeamInput = {
  name: Scalars['String'];
  managerId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateTutorialView */
export type ICreateTutorialViewInput = {
  tutorialName: ITutorialName;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateUser */
export type ICreateUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  title: Scalars['String'];
  roleId?: Maybe<Scalars['ID']>;
  teamId?: Maybe<Scalars['ID']>;
  gender: Scalars['String'];
  admin?: Maybe<Scalars['Boolean']>;
  manager?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateVacation */
export type ICreateVacationInput = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateWorkdayAccount */
export type ICreateWorkdayAccountInput = {
  url: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
  importTeams: Scalars['Boolean'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type IDashboardToken = {
  __typename?: 'DashboardToken';
  email: Scalars['String'];
  expiresAt: Scalars['String'];
  id: Scalars['ID'];
  token: Scalars['String'];
  url: Scalars['String'];
  user: IUser;
};

/** Autogenerated input type of DeleteCategory */
export type IDeleteCategoryInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteComment */
export type IDeleteCommentInput = {
  commentId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteComment */
export type IDeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<IComment>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of DeleteHighfive */
export type IDeleteHighfiveInput = {
  highfiveId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteHighfive */
export type IDeleteHighfivePayload = {
  __typename?: 'DeleteHighfivePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  highfive?: Maybe<IHighfive>;
};

/** Autogenerated input type of DeleteLibraryObjective */
export type IDeleteLibraryObjectiveInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteLike */
export type IDeleteLikeInput = {
  likeId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteLike */
export type IDeleteLikePayload = {
  __typename?: 'DeleteLikePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  like: ILike;
};

/** Autogenerated input type of DeleteOneOnOne */
export type IDeleteOneOnOneInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteTalkingPoint */
export type IDeleteTalkingPointInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DiscardManagerActionItem */
export type IDiscardManagerActionItemInput = {
  managerActionItemId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DiscardSuggestionBoxPrompt */
export type IDiscardSuggestionBoxPromptInput = {
  suggestionBoxPromptId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DiscardWorkItem */
export type IDiscardWorkItemInput = {
  workItemId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type IDocumentWidget = {
  __typename?: 'DocumentWidget';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type IEngagementQuestion = {
  __typename?: 'EngagementQuestion';
  behavior: IBehavior;
  id: Scalars['ID'];
  organization: IOrganization;
};

/** Information about pagination in a connection. */
export type IExtendedPageInfo = {
  __typename?: 'ExtendedPageInfo';
  count: Scalars['Int'];
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  hasMore?: Maybe<Scalars['Boolean']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type IExternalUser = {
  __typename?: 'ExternalUser';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type IFeedbackQuestion = {
  __typename?: 'FeedbackQuestion';
  behavior: IBehavior;
  behaviorId: Scalars['ID'];
  currentScore?: Maybe<IScore>;
  id: Scalars['ID'];
  scores: Array<IScore>;
  subject: IUser;
};

export type IGroup = {
  __typename?: 'Group';
  id: Scalars['ID'];
  managers: Array<IUser>;
  name: Scalars['String'];
  userIds: Array<Scalars['ID']>;
  users: Array<IUser>;
};

export type IHabit = {
  __typename?: 'Habit';
  archived?: Maybe<Scalars['Boolean']>;
  behaviors: Array<IBehavior>;
  benchmark?: Maybe<Scalars['Float']>;
  category?: Maybe<IHabitCategory>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employeeType?: Maybe<Scalars['String']>;
  habitType?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  selectable: Scalars['Boolean'];
  widgets: Array<IWidget>;
};

export enum IHabitCategory {
  /**
   * The Values Cluster describes the ground rules that people within a company
   * need to follow so that they get along and work gets done.
   */
  Core = 'core',
  /**
   * These habits focus on interpersonal relationships, trust, and communication.
   * These habits help you improve as a teammate and trusted partner with your coworkers.
   */
  People = 'people',
  /** These habits are very technical and are often specific to a role. */
  Technical = 'technical',
  /** These habits focus on effective behaviors that ensure work is done in a timely manner, complete, and high quality. */
  Results = 'results',
  /** Engagement habits */
  Engagement = 'engagement'
}

/** The connection type for Habit. */
export type IHabitConnection = {
  __typename?: 'HabitConnection';
  /** A list of edges. */
  edges: Array<IHabitEdge>;
  /** A list of nodes. */
  nodes: Array<IHabit>;
  /** Information to aid in pagination */
  pageInfo: IExtendedPageInfo;
};

/** An edge in a connection. */
export type IHabitEdge = {
  __typename?: 'HabitEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: IHabit;
};

export type IHabitRansack = {
  id_eq?: Maybe<Scalars['String']>;
  id_eq_any?: Maybe<Scalars['String']>;
  id_eq_all?: Maybe<Scalars['String']>;
  id_not_eq?: Maybe<Scalars['String']>;
  id_not_eq_any?: Maybe<Scalars['String']>;
  id_not_eq_all?: Maybe<Scalars['String']>;
  id_matches?: Maybe<Scalars['String']>;
  id_matches_any?: Maybe<Scalars['String']>;
  id_matches_all?: Maybe<Scalars['String']>;
  id_does_not_match?: Maybe<Scalars['String']>;
  id_does_not_match_any?: Maybe<Scalars['String']>;
  id_does_not_match_all?: Maybe<Scalars['String']>;
  id_lt?: Maybe<Scalars['String']>;
  id_lt_any?: Maybe<Scalars['String']>;
  id_lt_all?: Maybe<Scalars['String']>;
  id_lteq?: Maybe<Scalars['String']>;
  id_lteq_any?: Maybe<Scalars['String']>;
  id_lteq_all?: Maybe<Scalars['String']>;
  id_gt?: Maybe<Scalars['String']>;
  id_gt_any?: Maybe<Scalars['String']>;
  id_gt_all?: Maybe<Scalars['String']>;
  id_gteq?: Maybe<Scalars['String']>;
  id_gteq_any?: Maybe<Scalars['String']>;
  id_gteq_all?: Maybe<Scalars['String']>;
  id_in?: Maybe<Scalars['String']>;
  id_in_any?: Maybe<Scalars['String']>;
  id_in_all?: Maybe<Scalars['String']>;
  id_not_in?: Maybe<Scalars['String']>;
  id_not_in_any?: Maybe<Scalars['String']>;
  id_not_in_all?: Maybe<Scalars['String']>;
  id_cont?: Maybe<Scalars['String']>;
  id_cont_any?: Maybe<Scalars['String']>;
  id_cont_all?: Maybe<Scalars['String']>;
  id_not_cont?: Maybe<Scalars['String']>;
  id_not_cont_any?: Maybe<Scalars['String']>;
  id_not_cont_all?: Maybe<Scalars['String']>;
  id_i_cont?: Maybe<Scalars['String']>;
  id_i_cont_any?: Maybe<Scalars['String']>;
  id_i_cont_all?: Maybe<Scalars['String']>;
  id_not_i_cont?: Maybe<Scalars['String']>;
  id_not_i_cont_any?: Maybe<Scalars['String']>;
  id_not_i_cont_all?: Maybe<Scalars['String']>;
  id_start?: Maybe<Scalars['String']>;
  id_start_any?: Maybe<Scalars['String']>;
  id_start_all?: Maybe<Scalars['String']>;
  id_not_start?: Maybe<Scalars['String']>;
  id_not_start_any?: Maybe<Scalars['String']>;
  id_not_start_all?: Maybe<Scalars['String']>;
  id_end?: Maybe<Scalars['String']>;
  id_end_any?: Maybe<Scalars['String']>;
  id_end_all?: Maybe<Scalars['String']>;
  id_not_end?: Maybe<Scalars['String']>;
  id_not_end_any?: Maybe<Scalars['String']>;
  id_not_end_all?: Maybe<Scalars['String']>;
  id_true?: Maybe<Scalars['String']>;
  id_not_true?: Maybe<Scalars['String']>;
  id_false?: Maybe<Scalars['String']>;
  id_not_false?: Maybe<Scalars['String']>;
  id_present?: Maybe<Scalars['String']>;
  id_blank?: Maybe<Scalars['String']>;
  id_null?: Maybe<Scalars['String']>;
  id_not_null?: Maybe<Scalars['String']>;
  id_count?: Maybe<Scalars['String']>;
  id_count_distinct?: Maybe<Scalars['String']>;
  id_sum?: Maybe<Scalars['String']>;
  id_avg?: Maybe<Scalars['String']>;
  id_min?: Maybe<Scalars['String']>;
  id_max?: Maybe<Scalars['String']>;
  icon_eq?: Maybe<Scalars['String']>;
  icon_eq_any?: Maybe<Scalars['String']>;
  icon_eq_all?: Maybe<Scalars['String']>;
  icon_not_eq?: Maybe<Scalars['String']>;
  icon_not_eq_any?: Maybe<Scalars['String']>;
  icon_not_eq_all?: Maybe<Scalars['String']>;
  icon_matches?: Maybe<Scalars['String']>;
  icon_matches_any?: Maybe<Scalars['String']>;
  icon_matches_all?: Maybe<Scalars['String']>;
  icon_does_not_match?: Maybe<Scalars['String']>;
  icon_does_not_match_any?: Maybe<Scalars['String']>;
  icon_does_not_match_all?: Maybe<Scalars['String']>;
  icon_lt?: Maybe<Scalars['String']>;
  icon_lt_any?: Maybe<Scalars['String']>;
  icon_lt_all?: Maybe<Scalars['String']>;
  icon_lteq?: Maybe<Scalars['String']>;
  icon_lteq_any?: Maybe<Scalars['String']>;
  icon_lteq_all?: Maybe<Scalars['String']>;
  icon_gt?: Maybe<Scalars['String']>;
  icon_gt_any?: Maybe<Scalars['String']>;
  icon_gt_all?: Maybe<Scalars['String']>;
  icon_gteq?: Maybe<Scalars['String']>;
  icon_gteq_any?: Maybe<Scalars['String']>;
  icon_gteq_all?: Maybe<Scalars['String']>;
  icon_in?: Maybe<Scalars['String']>;
  icon_in_any?: Maybe<Scalars['String']>;
  icon_in_all?: Maybe<Scalars['String']>;
  icon_not_in?: Maybe<Scalars['String']>;
  icon_not_in_any?: Maybe<Scalars['String']>;
  icon_not_in_all?: Maybe<Scalars['String']>;
  icon_cont?: Maybe<Scalars['String']>;
  icon_cont_any?: Maybe<Scalars['String']>;
  icon_cont_all?: Maybe<Scalars['String']>;
  icon_not_cont?: Maybe<Scalars['String']>;
  icon_not_cont_any?: Maybe<Scalars['String']>;
  icon_not_cont_all?: Maybe<Scalars['String']>;
  icon_i_cont?: Maybe<Scalars['String']>;
  icon_i_cont_any?: Maybe<Scalars['String']>;
  icon_i_cont_all?: Maybe<Scalars['String']>;
  icon_not_i_cont?: Maybe<Scalars['String']>;
  icon_not_i_cont_any?: Maybe<Scalars['String']>;
  icon_not_i_cont_all?: Maybe<Scalars['String']>;
  icon_start?: Maybe<Scalars['String']>;
  icon_start_any?: Maybe<Scalars['String']>;
  icon_start_all?: Maybe<Scalars['String']>;
  icon_not_start?: Maybe<Scalars['String']>;
  icon_not_start_any?: Maybe<Scalars['String']>;
  icon_not_start_all?: Maybe<Scalars['String']>;
  icon_end?: Maybe<Scalars['String']>;
  icon_end_any?: Maybe<Scalars['String']>;
  icon_end_all?: Maybe<Scalars['String']>;
  icon_not_end?: Maybe<Scalars['String']>;
  icon_not_end_any?: Maybe<Scalars['String']>;
  icon_not_end_all?: Maybe<Scalars['String']>;
  icon_true?: Maybe<Scalars['String']>;
  icon_not_true?: Maybe<Scalars['String']>;
  icon_false?: Maybe<Scalars['String']>;
  icon_not_false?: Maybe<Scalars['String']>;
  icon_present?: Maybe<Scalars['String']>;
  icon_blank?: Maybe<Scalars['String']>;
  icon_null?: Maybe<Scalars['String']>;
  icon_not_null?: Maybe<Scalars['String']>;
  icon_count?: Maybe<Scalars['String']>;
  icon_count_distinct?: Maybe<Scalars['String']>;
  icon_sum?: Maybe<Scalars['String']>;
  icon_avg?: Maybe<Scalars['String']>;
  icon_min?: Maybe<Scalars['String']>;
  icon_max?: Maybe<Scalars['String']>;
  created_at_eq?: Maybe<Scalars['String']>;
  created_at_eq_any?: Maybe<Scalars['String']>;
  created_at_eq_all?: Maybe<Scalars['String']>;
  created_at_not_eq?: Maybe<Scalars['String']>;
  created_at_not_eq_any?: Maybe<Scalars['String']>;
  created_at_not_eq_all?: Maybe<Scalars['String']>;
  created_at_matches?: Maybe<Scalars['String']>;
  created_at_matches_any?: Maybe<Scalars['String']>;
  created_at_matches_all?: Maybe<Scalars['String']>;
  created_at_does_not_match?: Maybe<Scalars['String']>;
  created_at_does_not_match_any?: Maybe<Scalars['String']>;
  created_at_does_not_match_all?: Maybe<Scalars['String']>;
  created_at_lt?: Maybe<Scalars['String']>;
  created_at_lt_any?: Maybe<Scalars['String']>;
  created_at_lt_all?: Maybe<Scalars['String']>;
  created_at_lteq?: Maybe<Scalars['String']>;
  created_at_lteq_any?: Maybe<Scalars['String']>;
  created_at_lteq_all?: Maybe<Scalars['String']>;
  created_at_gt?: Maybe<Scalars['String']>;
  created_at_gt_any?: Maybe<Scalars['String']>;
  created_at_gt_all?: Maybe<Scalars['String']>;
  created_at_gteq?: Maybe<Scalars['String']>;
  created_at_gteq_any?: Maybe<Scalars['String']>;
  created_at_gteq_all?: Maybe<Scalars['String']>;
  created_at_in?: Maybe<Scalars['String']>;
  created_at_in_any?: Maybe<Scalars['String']>;
  created_at_in_all?: Maybe<Scalars['String']>;
  created_at_not_in?: Maybe<Scalars['String']>;
  created_at_not_in_any?: Maybe<Scalars['String']>;
  created_at_not_in_all?: Maybe<Scalars['String']>;
  created_at_cont?: Maybe<Scalars['String']>;
  created_at_cont_any?: Maybe<Scalars['String']>;
  created_at_cont_all?: Maybe<Scalars['String']>;
  created_at_not_cont?: Maybe<Scalars['String']>;
  created_at_not_cont_any?: Maybe<Scalars['String']>;
  created_at_not_cont_all?: Maybe<Scalars['String']>;
  created_at_i_cont?: Maybe<Scalars['String']>;
  created_at_i_cont_any?: Maybe<Scalars['String']>;
  created_at_i_cont_all?: Maybe<Scalars['String']>;
  created_at_not_i_cont?: Maybe<Scalars['String']>;
  created_at_not_i_cont_any?: Maybe<Scalars['String']>;
  created_at_not_i_cont_all?: Maybe<Scalars['String']>;
  created_at_start?: Maybe<Scalars['String']>;
  created_at_start_any?: Maybe<Scalars['String']>;
  created_at_start_all?: Maybe<Scalars['String']>;
  created_at_not_start?: Maybe<Scalars['String']>;
  created_at_not_start_any?: Maybe<Scalars['String']>;
  created_at_not_start_all?: Maybe<Scalars['String']>;
  created_at_end?: Maybe<Scalars['String']>;
  created_at_end_any?: Maybe<Scalars['String']>;
  created_at_end_all?: Maybe<Scalars['String']>;
  created_at_not_end?: Maybe<Scalars['String']>;
  created_at_not_end_any?: Maybe<Scalars['String']>;
  created_at_not_end_all?: Maybe<Scalars['String']>;
  created_at_true?: Maybe<Scalars['String']>;
  created_at_not_true?: Maybe<Scalars['String']>;
  created_at_false?: Maybe<Scalars['String']>;
  created_at_not_false?: Maybe<Scalars['String']>;
  created_at_present?: Maybe<Scalars['String']>;
  created_at_blank?: Maybe<Scalars['String']>;
  created_at_null?: Maybe<Scalars['String']>;
  created_at_not_null?: Maybe<Scalars['String']>;
  created_at_count?: Maybe<Scalars['String']>;
  created_at_count_distinct?: Maybe<Scalars['String']>;
  created_at_sum?: Maybe<Scalars['String']>;
  created_at_avg?: Maybe<Scalars['String']>;
  created_at_min?: Maybe<Scalars['String']>;
  created_at_max?: Maybe<Scalars['String']>;
  updated_at_eq?: Maybe<Scalars['String']>;
  updated_at_eq_any?: Maybe<Scalars['String']>;
  updated_at_eq_all?: Maybe<Scalars['String']>;
  updated_at_not_eq?: Maybe<Scalars['String']>;
  updated_at_not_eq_any?: Maybe<Scalars['String']>;
  updated_at_not_eq_all?: Maybe<Scalars['String']>;
  updated_at_matches?: Maybe<Scalars['String']>;
  updated_at_matches_any?: Maybe<Scalars['String']>;
  updated_at_matches_all?: Maybe<Scalars['String']>;
  updated_at_does_not_match?: Maybe<Scalars['String']>;
  updated_at_does_not_match_any?: Maybe<Scalars['String']>;
  updated_at_does_not_match_all?: Maybe<Scalars['String']>;
  updated_at_lt?: Maybe<Scalars['String']>;
  updated_at_lt_any?: Maybe<Scalars['String']>;
  updated_at_lt_all?: Maybe<Scalars['String']>;
  updated_at_lteq?: Maybe<Scalars['String']>;
  updated_at_lteq_any?: Maybe<Scalars['String']>;
  updated_at_lteq_all?: Maybe<Scalars['String']>;
  updated_at_gt?: Maybe<Scalars['String']>;
  updated_at_gt_any?: Maybe<Scalars['String']>;
  updated_at_gt_all?: Maybe<Scalars['String']>;
  updated_at_gteq?: Maybe<Scalars['String']>;
  updated_at_gteq_any?: Maybe<Scalars['String']>;
  updated_at_gteq_all?: Maybe<Scalars['String']>;
  updated_at_in?: Maybe<Scalars['String']>;
  updated_at_in_any?: Maybe<Scalars['String']>;
  updated_at_in_all?: Maybe<Scalars['String']>;
  updated_at_not_in?: Maybe<Scalars['String']>;
  updated_at_not_in_any?: Maybe<Scalars['String']>;
  updated_at_not_in_all?: Maybe<Scalars['String']>;
  updated_at_cont?: Maybe<Scalars['String']>;
  updated_at_cont_any?: Maybe<Scalars['String']>;
  updated_at_cont_all?: Maybe<Scalars['String']>;
  updated_at_not_cont?: Maybe<Scalars['String']>;
  updated_at_not_cont_any?: Maybe<Scalars['String']>;
  updated_at_not_cont_all?: Maybe<Scalars['String']>;
  updated_at_i_cont?: Maybe<Scalars['String']>;
  updated_at_i_cont_any?: Maybe<Scalars['String']>;
  updated_at_i_cont_all?: Maybe<Scalars['String']>;
  updated_at_not_i_cont?: Maybe<Scalars['String']>;
  updated_at_not_i_cont_any?: Maybe<Scalars['String']>;
  updated_at_not_i_cont_all?: Maybe<Scalars['String']>;
  updated_at_start?: Maybe<Scalars['String']>;
  updated_at_start_any?: Maybe<Scalars['String']>;
  updated_at_start_all?: Maybe<Scalars['String']>;
  updated_at_not_start?: Maybe<Scalars['String']>;
  updated_at_not_start_any?: Maybe<Scalars['String']>;
  updated_at_not_start_all?: Maybe<Scalars['String']>;
  updated_at_end?: Maybe<Scalars['String']>;
  updated_at_end_any?: Maybe<Scalars['String']>;
  updated_at_end_all?: Maybe<Scalars['String']>;
  updated_at_not_end?: Maybe<Scalars['String']>;
  updated_at_not_end_any?: Maybe<Scalars['String']>;
  updated_at_not_end_all?: Maybe<Scalars['String']>;
  updated_at_true?: Maybe<Scalars['String']>;
  updated_at_not_true?: Maybe<Scalars['String']>;
  updated_at_false?: Maybe<Scalars['String']>;
  updated_at_not_false?: Maybe<Scalars['String']>;
  updated_at_present?: Maybe<Scalars['String']>;
  updated_at_blank?: Maybe<Scalars['String']>;
  updated_at_null?: Maybe<Scalars['String']>;
  updated_at_not_null?: Maybe<Scalars['String']>;
  updated_at_count?: Maybe<Scalars['String']>;
  updated_at_count_distinct?: Maybe<Scalars['String']>;
  updated_at_sum?: Maybe<Scalars['String']>;
  updated_at_avg?: Maybe<Scalars['String']>;
  updated_at_min?: Maybe<Scalars['String']>;
  updated_at_max?: Maybe<Scalars['String']>;
  code_eq?: Maybe<Scalars['String']>;
  code_eq_any?: Maybe<Scalars['String']>;
  code_eq_all?: Maybe<Scalars['String']>;
  code_not_eq?: Maybe<Scalars['String']>;
  code_not_eq_any?: Maybe<Scalars['String']>;
  code_not_eq_all?: Maybe<Scalars['String']>;
  code_matches?: Maybe<Scalars['String']>;
  code_matches_any?: Maybe<Scalars['String']>;
  code_matches_all?: Maybe<Scalars['String']>;
  code_does_not_match?: Maybe<Scalars['String']>;
  code_does_not_match_any?: Maybe<Scalars['String']>;
  code_does_not_match_all?: Maybe<Scalars['String']>;
  code_lt?: Maybe<Scalars['String']>;
  code_lt_any?: Maybe<Scalars['String']>;
  code_lt_all?: Maybe<Scalars['String']>;
  code_lteq?: Maybe<Scalars['String']>;
  code_lteq_any?: Maybe<Scalars['String']>;
  code_lteq_all?: Maybe<Scalars['String']>;
  code_gt?: Maybe<Scalars['String']>;
  code_gt_any?: Maybe<Scalars['String']>;
  code_gt_all?: Maybe<Scalars['String']>;
  code_gteq?: Maybe<Scalars['String']>;
  code_gteq_any?: Maybe<Scalars['String']>;
  code_gteq_all?: Maybe<Scalars['String']>;
  code_in?: Maybe<Scalars['String']>;
  code_in_any?: Maybe<Scalars['String']>;
  code_in_all?: Maybe<Scalars['String']>;
  code_not_in?: Maybe<Scalars['String']>;
  code_not_in_any?: Maybe<Scalars['String']>;
  code_not_in_all?: Maybe<Scalars['String']>;
  code_cont?: Maybe<Scalars['String']>;
  code_cont_any?: Maybe<Scalars['String']>;
  code_cont_all?: Maybe<Scalars['String']>;
  code_not_cont?: Maybe<Scalars['String']>;
  code_not_cont_any?: Maybe<Scalars['String']>;
  code_not_cont_all?: Maybe<Scalars['String']>;
  code_i_cont?: Maybe<Scalars['String']>;
  code_i_cont_any?: Maybe<Scalars['String']>;
  code_i_cont_all?: Maybe<Scalars['String']>;
  code_not_i_cont?: Maybe<Scalars['String']>;
  code_not_i_cont_any?: Maybe<Scalars['String']>;
  code_not_i_cont_all?: Maybe<Scalars['String']>;
  code_start?: Maybe<Scalars['String']>;
  code_start_any?: Maybe<Scalars['String']>;
  code_start_all?: Maybe<Scalars['String']>;
  code_not_start?: Maybe<Scalars['String']>;
  code_not_start_any?: Maybe<Scalars['String']>;
  code_not_start_all?: Maybe<Scalars['String']>;
  code_end?: Maybe<Scalars['String']>;
  code_end_any?: Maybe<Scalars['String']>;
  code_end_all?: Maybe<Scalars['String']>;
  code_not_end?: Maybe<Scalars['String']>;
  code_not_end_any?: Maybe<Scalars['String']>;
  code_not_end_all?: Maybe<Scalars['String']>;
  code_true?: Maybe<Scalars['String']>;
  code_not_true?: Maybe<Scalars['String']>;
  code_false?: Maybe<Scalars['String']>;
  code_not_false?: Maybe<Scalars['String']>;
  code_present?: Maybe<Scalars['String']>;
  code_blank?: Maybe<Scalars['String']>;
  code_null?: Maybe<Scalars['String']>;
  code_not_null?: Maybe<Scalars['String']>;
  code_count?: Maybe<Scalars['String']>;
  code_count_distinct?: Maybe<Scalars['String']>;
  code_sum?: Maybe<Scalars['String']>;
  code_avg?: Maybe<Scalars['String']>;
  code_min?: Maybe<Scalars['String']>;
  code_max?: Maybe<Scalars['String']>;
  employee_type_eq?: Maybe<Scalars['String']>;
  employee_type_eq_any?: Maybe<Scalars['String']>;
  employee_type_eq_all?: Maybe<Scalars['String']>;
  employee_type_not_eq?: Maybe<Scalars['String']>;
  employee_type_not_eq_any?: Maybe<Scalars['String']>;
  employee_type_not_eq_all?: Maybe<Scalars['String']>;
  employee_type_matches?: Maybe<Scalars['String']>;
  employee_type_matches_any?: Maybe<Scalars['String']>;
  employee_type_matches_all?: Maybe<Scalars['String']>;
  employee_type_does_not_match?: Maybe<Scalars['String']>;
  employee_type_does_not_match_any?: Maybe<Scalars['String']>;
  employee_type_does_not_match_all?: Maybe<Scalars['String']>;
  employee_type_lt?: Maybe<Scalars['String']>;
  employee_type_lt_any?: Maybe<Scalars['String']>;
  employee_type_lt_all?: Maybe<Scalars['String']>;
  employee_type_lteq?: Maybe<Scalars['String']>;
  employee_type_lteq_any?: Maybe<Scalars['String']>;
  employee_type_lteq_all?: Maybe<Scalars['String']>;
  employee_type_gt?: Maybe<Scalars['String']>;
  employee_type_gt_any?: Maybe<Scalars['String']>;
  employee_type_gt_all?: Maybe<Scalars['String']>;
  employee_type_gteq?: Maybe<Scalars['String']>;
  employee_type_gteq_any?: Maybe<Scalars['String']>;
  employee_type_gteq_all?: Maybe<Scalars['String']>;
  employee_type_in?: Maybe<Scalars['String']>;
  employee_type_in_any?: Maybe<Scalars['String']>;
  employee_type_in_all?: Maybe<Scalars['String']>;
  employee_type_not_in?: Maybe<Scalars['String']>;
  employee_type_not_in_any?: Maybe<Scalars['String']>;
  employee_type_not_in_all?: Maybe<Scalars['String']>;
  employee_type_cont?: Maybe<Scalars['String']>;
  employee_type_cont_any?: Maybe<Scalars['String']>;
  employee_type_cont_all?: Maybe<Scalars['String']>;
  employee_type_not_cont?: Maybe<Scalars['String']>;
  employee_type_not_cont_any?: Maybe<Scalars['String']>;
  employee_type_not_cont_all?: Maybe<Scalars['String']>;
  employee_type_i_cont?: Maybe<Scalars['String']>;
  employee_type_i_cont_any?: Maybe<Scalars['String']>;
  employee_type_i_cont_all?: Maybe<Scalars['String']>;
  employee_type_not_i_cont?: Maybe<Scalars['String']>;
  employee_type_not_i_cont_any?: Maybe<Scalars['String']>;
  employee_type_not_i_cont_all?: Maybe<Scalars['String']>;
  employee_type_start?: Maybe<Scalars['String']>;
  employee_type_start_any?: Maybe<Scalars['String']>;
  employee_type_start_all?: Maybe<Scalars['String']>;
  employee_type_not_start?: Maybe<Scalars['String']>;
  employee_type_not_start_any?: Maybe<Scalars['String']>;
  employee_type_not_start_all?: Maybe<Scalars['String']>;
  employee_type_end?: Maybe<Scalars['String']>;
  employee_type_end_any?: Maybe<Scalars['String']>;
  employee_type_end_all?: Maybe<Scalars['String']>;
  employee_type_not_end?: Maybe<Scalars['String']>;
  employee_type_not_end_any?: Maybe<Scalars['String']>;
  employee_type_not_end_all?: Maybe<Scalars['String']>;
  employee_type_true?: Maybe<Scalars['String']>;
  employee_type_not_true?: Maybe<Scalars['String']>;
  employee_type_false?: Maybe<Scalars['String']>;
  employee_type_not_false?: Maybe<Scalars['String']>;
  employee_type_present?: Maybe<Scalars['String']>;
  employee_type_blank?: Maybe<Scalars['String']>;
  employee_type_null?: Maybe<Scalars['String']>;
  employee_type_not_null?: Maybe<Scalars['String']>;
  employee_type_count?: Maybe<Scalars['String']>;
  employee_type_count_distinct?: Maybe<Scalars['String']>;
  employee_type_sum?: Maybe<Scalars['String']>;
  employee_type_avg?: Maybe<Scalars['String']>;
  employee_type_min?: Maybe<Scalars['String']>;
  employee_type_max?: Maybe<Scalars['String']>;
  habit_type_eq?: Maybe<Scalars['String']>;
  habit_type_eq_any?: Maybe<Scalars['String']>;
  habit_type_eq_all?: Maybe<Scalars['String']>;
  habit_type_not_eq?: Maybe<Scalars['String']>;
  habit_type_not_eq_any?: Maybe<Scalars['String']>;
  habit_type_not_eq_all?: Maybe<Scalars['String']>;
  habit_type_matches?: Maybe<Scalars['String']>;
  habit_type_matches_any?: Maybe<Scalars['String']>;
  habit_type_matches_all?: Maybe<Scalars['String']>;
  habit_type_does_not_match?: Maybe<Scalars['String']>;
  habit_type_does_not_match_any?: Maybe<Scalars['String']>;
  habit_type_does_not_match_all?: Maybe<Scalars['String']>;
  habit_type_lt?: Maybe<Scalars['String']>;
  habit_type_lt_any?: Maybe<Scalars['String']>;
  habit_type_lt_all?: Maybe<Scalars['String']>;
  habit_type_lteq?: Maybe<Scalars['String']>;
  habit_type_lteq_any?: Maybe<Scalars['String']>;
  habit_type_lteq_all?: Maybe<Scalars['String']>;
  habit_type_gt?: Maybe<Scalars['String']>;
  habit_type_gt_any?: Maybe<Scalars['String']>;
  habit_type_gt_all?: Maybe<Scalars['String']>;
  habit_type_gteq?: Maybe<Scalars['String']>;
  habit_type_gteq_any?: Maybe<Scalars['String']>;
  habit_type_gteq_all?: Maybe<Scalars['String']>;
  habit_type_in?: Maybe<Scalars['String']>;
  habit_type_in_any?: Maybe<Scalars['String']>;
  habit_type_in_all?: Maybe<Scalars['String']>;
  habit_type_not_in?: Maybe<Scalars['String']>;
  habit_type_not_in_any?: Maybe<Scalars['String']>;
  habit_type_not_in_all?: Maybe<Scalars['String']>;
  habit_type_cont?: Maybe<Scalars['String']>;
  habit_type_cont_any?: Maybe<Scalars['String']>;
  habit_type_cont_all?: Maybe<Scalars['String']>;
  habit_type_not_cont?: Maybe<Scalars['String']>;
  habit_type_not_cont_any?: Maybe<Scalars['String']>;
  habit_type_not_cont_all?: Maybe<Scalars['String']>;
  habit_type_i_cont?: Maybe<Scalars['String']>;
  habit_type_i_cont_any?: Maybe<Scalars['String']>;
  habit_type_i_cont_all?: Maybe<Scalars['String']>;
  habit_type_not_i_cont?: Maybe<Scalars['String']>;
  habit_type_not_i_cont_any?: Maybe<Scalars['String']>;
  habit_type_not_i_cont_all?: Maybe<Scalars['String']>;
  habit_type_start?: Maybe<Scalars['String']>;
  habit_type_start_any?: Maybe<Scalars['String']>;
  habit_type_start_all?: Maybe<Scalars['String']>;
  habit_type_not_start?: Maybe<Scalars['String']>;
  habit_type_not_start_any?: Maybe<Scalars['String']>;
  habit_type_not_start_all?: Maybe<Scalars['String']>;
  habit_type_end?: Maybe<Scalars['String']>;
  habit_type_end_any?: Maybe<Scalars['String']>;
  habit_type_end_all?: Maybe<Scalars['String']>;
  habit_type_not_end?: Maybe<Scalars['String']>;
  habit_type_not_end_any?: Maybe<Scalars['String']>;
  habit_type_not_end_all?: Maybe<Scalars['String']>;
  habit_type_true?: Maybe<Scalars['String']>;
  habit_type_not_true?: Maybe<Scalars['String']>;
  habit_type_false?: Maybe<Scalars['String']>;
  habit_type_not_false?: Maybe<Scalars['String']>;
  habit_type_present?: Maybe<Scalars['String']>;
  habit_type_blank?: Maybe<Scalars['String']>;
  habit_type_null?: Maybe<Scalars['String']>;
  habit_type_not_null?: Maybe<Scalars['String']>;
  habit_type_count?: Maybe<Scalars['String']>;
  habit_type_count_distinct?: Maybe<Scalars['String']>;
  habit_type_sum?: Maybe<Scalars['String']>;
  habit_type_avg?: Maybe<Scalars['String']>;
  habit_type_min?: Maybe<Scalars['String']>;
  habit_type_max?: Maybe<Scalars['String']>;
  benchmark_eq?: Maybe<Scalars['String']>;
  benchmark_eq_any?: Maybe<Scalars['String']>;
  benchmark_eq_all?: Maybe<Scalars['String']>;
  benchmark_not_eq?: Maybe<Scalars['String']>;
  benchmark_not_eq_any?: Maybe<Scalars['String']>;
  benchmark_not_eq_all?: Maybe<Scalars['String']>;
  benchmark_matches?: Maybe<Scalars['String']>;
  benchmark_matches_any?: Maybe<Scalars['String']>;
  benchmark_matches_all?: Maybe<Scalars['String']>;
  benchmark_does_not_match?: Maybe<Scalars['String']>;
  benchmark_does_not_match_any?: Maybe<Scalars['String']>;
  benchmark_does_not_match_all?: Maybe<Scalars['String']>;
  benchmark_lt?: Maybe<Scalars['String']>;
  benchmark_lt_any?: Maybe<Scalars['String']>;
  benchmark_lt_all?: Maybe<Scalars['String']>;
  benchmark_lteq?: Maybe<Scalars['String']>;
  benchmark_lteq_any?: Maybe<Scalars['String']>;
  benchmark_lteq_all?: Maybe<Scalars['String']>;
  benchmark_gt?: Maybe<Scalars['String']>;
  benchmark_gt_any?: Maybe<Scalars['String']>;
  benchmark_gt_all?: Maybe<Scalars['String']>;
  benchmark_gteq?: Maybe<Scalars['String']>;
  benchmark_gteq_any?: Maybe<Scalars['String']>;
  benchmark_gteq_all?: Maybe<Scalars['String']>;
  benchmark_in?: Maybe<Scalars['String']>;
  benchmark_in_any?: Maybe<Scalars['String']>;
  benchmark_in_all?: Maybe<Scalars['String']>;
  benchmark_not_in?: Maybe<Scalars['String']>;
  benchmark_not_in_any?: Maybe<Scalars['String']>;
  benchmark_not_in_all?: Maybe<Scalars['String']>;
  benchmark_cont?: Maybe<Scalars['String']>;
  benchmark_cont_any?: Maybe<Scalars['String']>;
  benchmark_cont_all?: Maybe<Scalars['String']>;
  benchmark_not_cont?: Maybe<Scalars['String']>;
  benchmark_not_cont_any?: Maybe<Scalars['String']>;
  benchmark_not_cont_all?: Maybe<Scalars['String']>;
  benchmark_i_cont?: Maybe<Scalars['String']>;
  benchmark_i_cont_any?: Maybe<Scalars['String']>;
  benchmark_i_cont_all?: Maybe<Scalars['String']>;
  benchmark_not_i_cont?: Maybe<Scalars['String']>;
  benchmark_not_i_cont_any?: Maybe<Scalars['String']>;
  benchmark_not_i_cont_all?: Maybe<Scalars['String']>;
  benchmark_start?: Maybe<Scalars['String']>;
  benchmark_start_any?: Maybe<Scalars['String']>;
  benchmark_start_all?: Maybe<Scalars['String']>;
  benchmark_not_start?: Maybe<Scalars['String']>;
  benchmark_not_start_any?: Maybe<Scalars['String']>;
  benchmark_not_start_all?: Maybe<Scalars['String']>;
  benchmark_end?: Maybe<Scalars['String']>;
  benchmark_end_any?: Maybe<Scalars['String']>;
  benchmark_end_all?: Maybe<Scalars['String']>;
  benchmark_not_end?: Maybe<Scalars['String']>;
  benchmark_not_end_any?: Maybe<Scalars['String']>;
  benchmark_not_end_all?: Maybe<Scalars['String']>;
  benchmark_true?: Maybe<Scalars['String']>;
  benchmark_not_true?: Maybe<Scalars['String']>;
  benchmark_false?: Maybe<Scalars['String']>;
  benchmark_not_false?: Maybe<Scalars['String']>;
  benchmark_present?: Maybe<Scalars['String']>;
  benchmark_blank?: Maybe<Scalars['String']>;
  benchmark_null?: Maybe<Scalars['String']>;
  benchmark_not_null?: Maybe<Scalars['String']>;
  benchmark_count?: Maybe<Scalars['String']>;
  benchmark_count_distinct?: Maybe<Scalars['String']>;
  benchmark_sum?: Maybe<Scalars['String']>;
  benchmark_avg?: Maybe<Scalars['String']>;
  benchmark_min?: Maybe<Scalars['String']>;
  benchmark_max?: Maybe<Scalars['String']>;
  archived_eq?: Maybe<Scalars['String']>;
  archived_eq_any?: Maybe<Scalars['String']>;
  archived_eq_all?: Maybe<Scalars['String']>;
  archived_not_eq?: Maybe<Scalars['String']>;
  archived_not_eq_any?: Maybe<Scalars['String']>;
  archived_not_eq_all?: Maybe<Scalars['String']>;
  archived_matches?: Maybe<Scalars['String']>;
  archived_matches_any?: Maybe<Scalars['String']>;
  archived_matches_all?: Maybe<Scalars['String']>;
  archived_does_not_match?: Maybe<Scalars['String']>;
  archived_does_not_match_any?: Maybe<Scalars['String']>;
  archived_does_not_match_all?: Maybe<Scalars['String']>;
  archived_lt?: Maybe<Scalars['String']>;
  archived_lt_any?: Maybe<Scalars['String']>;
  archived_lt_all?: Maybe<Scalars['String']>;
  archived_lteq?: Maybe<Scalars['String']>;
  archived_lteq_any?: Maybe<Scalars['String']>;
  archived_lteq_all?: Maybe<Scalars['String']>;
  archived_gt?: Maybe<Scalars['String']>;
  archived_gt_any?: Maybe<Scalars['String']>;
  archived_gt_all?: Maybe<Scalars['String']>;
  archived_gteq?: Maybe<Scalars['String']>;
  archived_gteq_any?: Maybe<Scalars['String']>;
  archived_gteq_all?: Maybe<Scalars['String']>;
  archived_in?: Maybe<Scalars['String']>;
  archived_in_any?: Maybe<Scalars['String']>;
  archived_in_all?: Maybe<Scalars['String']>;
  archived_not_in?: Maybe<Scalars['String']>;
  archived_not_in_any?: Maybe<Scalars['String']>;
  archived_not_in_all?: Maybe<Scalars['String']>;
  archived_cont?: Maybe<Scalars['String']>;
  archived_cont_any?: Maybe<Scalars['String']>;
  archived_cont_all?: Maybe<Scalars['String']>;
  archived_not_cont?: Maybe<Scalars['String']>;
  archived_not_cont_any?: Maybe<Scalars['String']>;
  archived_not_cont_all?: Maybe<Scalars['String']>;
  archived_i_cont?: Maybe<Scalars['String']>;
  archived_i_cont_any?: Maybe<Scalars['String']>;
  archived_i_cont_all?: Maybe<Scalars['String']>;
  archived_not_i_cont?: Maybe<Scalars['String']>;
  archived_not_i_cont_any?: Maybe<Scalars['String']>;
  archived_not_i_cont_all?: Maybe<Scalars['String']>;
  archived_start?: Maybe<Scalars['String']>;
  archived_start_any?: Maybe<Scalars['String']>;
  archived_start_all?: Maybe<Scalars['String']>;
  archived_not_start?: Maybe<Scalars['String']>;
  archived_not_start_any?: Maybe<Scalars['String']>;
  archived_not_start_all?: Maybe<Scalars['String']>;
  archived_end?: Maybe<Scalars['String']>;
  archived_end_any?: Maybe<Scalars['String']>;
  archived_end_all?: Maybe<Scalars['String']>;
  archived_not_end?: Maybe<Scalars['String']>;
  archived_not_end_any?: Maybe<Scalars['String']>;
  archived_not_end_all?: Maybe<Scalars['String']>;
  archived_true?: Maybe<Scalars['String']>;
  archived_not_true?: Maybe<Scalars['String']>;
  archived_false?: Maybe<Scalars['String']>;
  archived_not_false?: Maybe<Scalars['String']>;
  archived_present?: Maybe<Scalars['String']>;
  archived_blank?: Maybe<Scalars['String']>;
  archived_null?: Maybe<Scalars['String']>;
  archived_not_null?: Maybe<Scalars['String']>;
  archived_count?: Maybe<Scalars['String']>;
  archived_count_distinct?: Maybe<Scalars['String']>;
  archived_sum?: Maybe<Scalars['String']>;
  archived_avg?: Maybe<Scalars['String']>;
  archived_min?: Maybe<Scalars['String']>;
  archived_max?: Maybe<Scalars['String']>;
  category_eq?: Maybe<Scalars['String']>;
  category_eq_any?: Maybe<Scalars['String']>;
  category_eq_all?: Maybe<Scalars['String']>;
  category_not_eq?: Maybe<Scalars['String']>;
  category_not_eq_any?: Maybe<Scalars['String']>;
  category_not_eq_all?: Maybe<Scalars['String']>;
  category_matches?: Maybe<Scalars['String']>;
  category_matches_any?: Maybe<Scalars['String']>;
  category_matches_all?: Maybe<Scalars['String']>;
  category_does_not_match?: Maybe<Scalars['String']>;
  category_does_not_match_any?: Maybe<Scalars['String']>;
  category_does_not_match_all?: Maybe<Scalars['String']>;
  category_lt?: Maybe<Scalars['String']>;
  category_lt_any?: Maybe<Scalars['String']>;
  category_lt_all?: Maybe<Scalars['String']>;
  category_lteq?: Maybe<Scalars['String']>;
  category_lteq_any?: Maybe<Scalars['String']>;
  category_lteq_all?: Maybe<Scalars['String']>;
  category_gt?: Maybe<Scalars['String']>;
  category_gt_any?: Maybe<Scalars['String']>;
  category_gt_all?: Maybe<Scalars['String']>;
  category_gteq?: Maybe<Scalars['String']>;
  category_gteq_any?: Maybe<Scalars['String']>;
  category_gteq_all?: Maybe<Scalars['String']>;
  category_in?: Maybe<Scalars['String']>;
  category_in_any?: Maybe<Scalars['String']>;
  category_in_all?: Maybe<Scalars['String']>;
  category_not_in?: Maybe<Scalars['String']>;
  category_not_in_any?: Maybe<Scalars['String']>;
  category_not_in_all?: Maybe<Scalars['String']>;
  category_cont?: Maybe<Scalars['String']>;
  category_cont_any?: Maybe<Scalars['String']>;
  category_cont_all?: Maybe<Scalars['String']>;
  category_not_cont?: Maybe<Scalars['String']>;
  category_not_cont_any?: Maybe<Scalars['String']>;
  category_not_cont_all?: Maybe<Scalars['String']>;
  category_i_cont?: Maybe<Scalars['String']>;
  category_i_cont_any?: Maybe<Scalars['String']>;
  category_i_cont_all?: Maybe<Scalars['String']>;
  category_not_i_cont?: Maybe<Scalars['String']>;
  category_not_i_cont_any?: Maybe<Scalars['String']>;
  category_not_i_cont_all?: Maybe<Scalars['String']>;
  category_start?: Maybe<Scalars['String']>;
  category_start_any?: Maybe<Scalars['String']>;
  category_start_all?: Maybe<Scalars['String']>;
  category_not_start?: Maybe<Scalars['String']>;
  category_not_start_any?: Maybe<Scalars['String']>;
  category_not_start_all?: Maybe<Scalars['String']>;
  category_end?: Maybe<Scalars['String']>;
  category_end_any?: Maybe<Scalars['String']>;
  category_end_all?: Maybe<Scalars['String']>;
  category_not_end?: Maybe<Scalars['String']>;
  category_not_end_any?: Maybe<Scalars['String']>;
  category_not_end_all?: Maybe<Scalars['String']>;
  category_true?: Maybe<Scalars['String']>;
  category_not_true?: Maybe<Scalars['String']>;
  category_false?: Maybe<Scalars['String']>;
  category_not_false?: Maybe<Scalars['String']>;
  category_present?: Maybe<Scalars['String']>;
  category_blank?: Maybe<Scalars['String']>;
  category_null?: Maybe<Scalars['String']>;
  category_not_null?: Maybe<Scalars['String']>;
  category_count?: Maybe<Scalars['String']>;
  category_count_distinct?: Maybe<Scalars['String']>;
  category_sum?: Maybe<Scalars['String']>;
  category_avg?: Maybe<Scalars['String']>;
  category_min?: Maybe<Scalars['String']>;
  category_max?: Maybe<Scalars['String']>;
  selectable_eq?: Maybe<Scalars['String']>;
  selectable_eq_any?: Maybe<Scalars['String']>;
  selectable_eq_all?: Maybe<Scalars['String']>;
  selectable_not_eq?: Maybe<Scalars['String']>;
  selectable_not_eq_any?: Maybe<Scalars['String']>;
  selectable_not_eq_all?: Maybe<Scalars['String']>;
  selectable_matches?: Maybe<Scalars['String']>;
  selectable_matches_any?: Maybe<Scalars['String']>;
  selectable_matches_all?: Maybe<Scalars['String']>;
  selectable_does_not_match?: Maybe<Scalars['String']>;
  selectable_does_not_match_any?: Maybe<Scalars['String']>;
  selectable_does_not_match_all?: Maybe<Scalars['String']>;
  selectable_lt?: Maybe<Scalars['String']>;
  selectable_lt_any?: Maybe<Scalars['String']>;
  selectable_lt_all?: Maybe<Scalars['String']>;
  selectable_lteq?: Maybe<Scalars['String']>;
  selectable_lteq_any?: Maybe<Scalars['String']>;
  selectable_lteq_all?: Maybe<Scalars['String']>;
  selectable_gt?: Maybe<Scalars['String']>;
  selectable_gt_any?: Maybe<Scalars['String']>;
  selectable_gt_all?: Maybe<Scalars['String']>;
  selectable_gteq?: Maybe<Scalars['String']>;
  selectable_gteq_any?: Maybe<Scalars['String']>;
  selectable_gteq_all?: Maybe<Scalars['String']>;
  selectable_in?: Maybe<Scalars['String']>;
  selectable_in_any?: Maybe<Scalars['String']>;
  selectable_in_all?: Maybe<Scalars['String']>;
  selectable_not_in?: Maybe<Scalars['String']>;
  selectable_not_in_any?: Maybe<Scalars['String']>;
  selectable_not_in_all?: Maybe<Scalars['String']>;
  selectable_cont?: Maybe<Scalars['String']>;
  selectable_cont_any?: Maybe<Scalars['String']>;
  selectable_cont_all?: Maybe<Scalars['String']>;
  selectable_not_cont?: Maybe<Scalars['String']>;
  selectable_not_cont_any?: Maybe<Scalars['String']>;
  selectable_not_cont_all?: Maybe<Scalars['String']>;
  selectable_i_cont?: Maybe<Scalars['String']>;
  selectable_i_cont_any?: Maybe<Scalars['String']>;
  selectable_i_cont_all?: Maybe<Scalars['String']>;
  selectable_not_i_cont?: Maybe<Scalars['String']>;
  selectable_not_i_cont_any?: Maybe<Scalars['String']>;
  selectable_not_i_cont_all?: Maybe<Scalars['String']>;
  selectable_start?: Maybe<Scalars['String']>;
  selectable_start_any?: Maybe<Scalars['String']>;
  selectable_start_all?: Maybe<Scalars['String']>;
  selectable_not_start?: Maybe<Scalars['String']>;
  selectable_not_start_any?: Maybe<Scalars['String']>;
  selectable_not_start_all?: Maybe<Scalars['String']>;
  selectable_end?: Maybe<Scalars['String']>;
  selectable_end_any?: Maybe<Scalars['String']>;
  selectable_end_all?: Maybe<Scalars['String']>;
  selectable_not_end?: Maybe<Scalars['String']>;
  selectable_not_end_any?: Maybe<Scalars['String']>;
  selectable_not_end_all?: Maybe<Scalars['String']>;
  selectable_true?: Maybe<Scalars['String']>;
  selectable_not_true?: Maybe<Scalars['String']>;
  selectable_false?: Maybe<Scalars['String']>;
  selectable_not_false?: Maybe<Scalars['String']>;
  selectable_present?: Maybe<Scalars['String']>;
  selectable_blank?: Maybe<Scalars['String']>;
  selectable_null?: Maybe<Scalars['String']>;
  selectable_not_null?: Maybe<Scalars['String']>;
  selectable_count?: Maybe<Scalars['String']>;
  selectable_count_distinct?: Maybe<Scalars['String']>;
  selectable_sum?: Maybe<Scalars['String']>;
  selectable_avg?: Maybe<Scalars['String']>;
  selectable_min?: Maybe<Scalars['String']>;
  selectable_max?: Maybe<Scalars['String']>;
};

export type IHabitUser = {
  __typename?: 'HabitUser';
  active: Scalars['Boolean'];
  additionalFeedbackGroups: Array<IAdditionalFeedbackGroup>;
  answersCount: Scalars['Int'];
  companyAverage?: Maybe<Scalars['Int']>;
  currentScore?: Maybe<IScore>;
  dontKnowPercentage?: Maybe<Scalars['Int']>;
  feedbackQuestions: Array<IFeedbackQuestion>;
  habit: IHabit;
  habitable: IHabitUserHabitable;
  habitableId: Scalars['ID'];
  id: Scalars['ID'];
  lastScore?: Maybe<IScore>;
  locked: Scalars['Boolean'];
  mostRecentScores: Array<IScore>;
  raters: Array<IUser>;
  ratersCount: Scalars['Int'];
  scores: Array<IScore>;
  unlockThreshold: Scalars['Int'];
  validResponses: Scalars['Int'];
};


export type IHabitUserScoresArgs = {
  range?: Maybe<IScoreDateRange>;
};

/** Objects which may have habits */
export type IHabitUserHabitable = IUser;

export type IHighfive = {
  __typename?: 'Highfive';
  comments: Array<IComment>;
  content: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  likes: Array<ILike>;
  private: Scalars['Boolean'];
  receivers: Array<IUser>;
  sender: IUser;
};

export type IHighfiveStats = {
  __typename?: 'HighfiveStats';
  received: Scalars['Int'];
  sent: Scalars['Int'];
};

/** Autogenerated input type of ImportFromWorkday */
export type IImportFromWorkdayInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type IJobStatus = {
  __typename?: 'JobStatus';
  errors?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};


export type IKeyResults = {
  __typename?: 'KeyResults';
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
};

export type ILabel = {
  __typename?: 'Label';
  color: Scalars['String'];
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type ILeaderboard = {
  __typename?: 'Leaderboard';
  id: Scalars['ID'];
  users: Array<IUser>;
  usersCount: Scalars['Int'];
};

export type ILike = {
  __typename?: 'Like';
  id: Scalars['ID'];
  likeableId: Scalars['String'];
  likeableType: Scalars['String'];
  user: IUser;
};

/** Autogenerated input type of LoginUser */
export type ILoginUserInput = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of LoginUser */
export type ILoginUserPayload = {
  __typename?: 'LoginUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of LoginWithCode */
export type ILoginWithCodeInput = {
  code: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type IManagerActionItem = {
  __typename?: 'ManagerActionItem';
  completed: Scalars['Boolean'];
  discardedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  manager: IUser;
  name: Scalars['String'];
  subject: IManagerActionSubject;
  text: Scalars['String'];
  user: IUser;
};

/** Objects that can have manager activity items created about them */
export type IManagerActionSubject = IHabitUser | IUser;

export type IManagerDashboard = {
  __typename?: 'ManagerDashboard';
  companyComparison: Array<Scalars['JSON']>;
  engagementMinMax?: Maybe<Scalars['JSON']>;
  engagementOverall?: Maybe<Scalars['JSON']>;
  engagementPast?: Maybe<Scalars['JSON']>;
  engagementRecent?: Maybe<Scalars['JSON']>;
  habitDeltas?: Maybe<Scalars['JSON']>;
  highestLowestDeltas?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
};

export type IManagerDashboardDemo = {
  __typename?: 'ManagerDashboardDemo';
  companyComparison: Array<Scalars['JSON']>;
  engagementMinMax?: Maybe<Scalars['JSON']>;
  engagementOverall?: Maybe<Scalars['JSON']>;
  engagementPast?: Maybe<Scalars['JSON']>;
  engagementRecent?: Maybe<Scalars['JSON']>;
  habitDeltas?: Maybe<Scalars['JSON']>;
  highestLowestDeltas?: Maybe<Scalars['JSON']>;
};

export type IMutation = {
  __typename?: 'Mutation';
  awardCarrots?: Maybe<IAwardCarrotsPayload>;
  bulkImportUsers?: Maybe<Scalars['String']>;
  cancelVacation?: Maybe<IVacation>;
  checkPhoneVerification: ICheckPhoneVerificationPayload;
  createBehavior?: Maybe<IBehavior>;
  createCategory?: Maybe<ICategory>;
  createComment: ICreateCommentPayload;
  createDashboardToken?: Maybe<IDashboardToken>;
  createExternalUser?: Maybe<IExternalUser>;
  createExtraFeedbackRequest?: Maybe<IWorkItem>;
  createHabit?: Maybe<IHabit>;
  createHighfive?: Maybe<ICreateHighfivePayload>;
  createLibraryObjective?: Maybe<ICategory>;
  createLike?: Maybe<ICreateLikePayload>;
  createObjective?: Maybe<IObjective>;
  createOneOnOnes?: Maybe<Array<IOneOnOne>>;
  createRelationship?: Maybe<IRelationship>;
  createReview?: Maybe<IObjective>;
  createRole?: Maybe<IRole>;
  createSuggestion?: Maybe<ISuggestion>;
  createTalkingPoint?: Maybe<IOneOnOne>;
  createTeam?: Maybe<ITeam>;
  createTutorialView?: Maybe<IUser>;
  createUser?: Maybe<IUser>;
  createVacation?: Maybe<IVacation>;
  createWorkdayAccount?: Maybe<IWorkdayAccount>;
  deleteCategory?: Maybe<ICategory>;
  deleteComment?: Maybe<IDeleteCommentPayload>;
  deleteHighfive?: Maybe<IDeleteHighfivePayload>;
  deleteLibraryObjective?: Maybe<ICategory>;
  deleteLike?: Maybe<IDeleteLikePayload>;
  deleteOneOnOne?: Maybe<IOneOnOne>;
  deleteTalkingPoint?: Maybe<IOneOnOne>;
  discardManagerActionItem?: Maybe<IManagerActionItem>;
  discardSuggestionBoxPrompt?: Maybe<ISuggestionBoxPrompt>;
  discardWorkItem?: Maybe<IWorkItem>;
  importFromWorkday: Scalars['String'];
  loginUser?: Maybe<ILoginUserPayload>;
  loginWithCode?: Maybe<ISurveyCodeResponse>;
  refreshToken?: Maybe<IRefreshToken>;
  sendRhabitFeedback?: Maybe<ISendRhabitFeedbackPayload>;
  setAvatarFromLinkedIn?: Maybe<IUser>;
  setNewAvatarPath?: Maybe<IUser>;
  updateAnswer?: Maybe<IAnswer>;
  updateBehavior?: Maybe<IBehavior>;
  updateCategory?: Maybe<ICategory>;
  updateHabit?: Maybe<IHabit>;
  updateObjective?: Maybe<IObjective>;
  updateOneOnOne?: Maybe<IOneOnOne>;
  updateRaters?: Maybe<Scalars['Boolean']>;
  updateRelationship?: Maybe<IRelationship>;
  updateReview?: Maybe<IReview>;
  updateRole?: Maybe<IRole>;
  updateTalkingPoint?: Maybe<IOneOnOne>;
  updateUser?: Maybe<IUser>;
  updateUserHabits?: Maybe<Scalars['Boolean']>;
  updateWorkdayAccount?: Maybe<IWorkdayAccount>;
  verifyPhoneNumber: Scalars['Boolean'];
};


export type IMutationAwardCarrotsArgs = {
  input: IAwardCarrotsInput;
};


export type IMutationBulkImportUsersArgs = {
  input: IBulkImportUsersInput;
};


export type IMutationCancelVacationArgs = {
  input: ICancelVacationInput;
};


export type IMutationCheckPhoneVerificationArgs = {
  input: ICheckPhoneVerificationInput;
};


export type IMutationCreateBehaviorArgs = {
  input: ICreateBehaviorInput;
};


export type IMutationCreateCategoryArgs = {
  input: ICreateCategoryInput;
};


export type IMutationCreateCommentArgs = {
  input: ICreateCommentInput;
};


export type IMutationCreateDashboardTokenArgs = {
  input: ICreateDashboardTokenInput;
};


export type IMutationCreateExternalUserArgs = {
  input: ICreateExternalUserInput;
};


export type IMutationCreateExtraFeedbackRequestArgs = {
  input: ICreateExtraFeedbackRequestInput;
};


export type IMutationCreateHabitArgs = {
  input: ICreateHabitInput;
};


export type IMutationCreateHighfiveArgs = {
  input: ICreateHighfiveInput;
};


export type IMutationCreateLibraryObjectiveArgs = {
  input: ICreateLibraryObjectiveInput;
};


export type IMutationCreateLikeArgs = {
  input: ICreateLikeInput;
};


export type IMutationCreateObjectiveArgs = {
  input: ICreateObjectiveInput;
};


export type IMutationCreateOneOnOnesArgs = {
  input: ICreateOneOnOnesInput;
};


export type IMutationCreateRelationshipArgs = {
  input: ICreateRelationshipInput;
};


export type IMutationCreateReviewArgs = {
  input: ICreateReviewInput;
};


export type IMutationCreateRoleArgs = {
  input: ICreateRoleInput;
};


export type IMutationCreateSuggestionArgs = {
  input: ICreateSuggestionInput;
};


export type IMutationCreateTalkingPointArgs = {
  input: ICreateTalkingPointInput;
};


export type IMutationCreateTeamArgs = {
  input: ICreateTeamInput;
};


export type IMutationCreateTutorialViewArgs = {
  input: ICreateTutorialViewInput;
};


export type IMutationCreateUserArgs = {
  input: ICreateUserInput;
};


export type IMutationCreateVacationArgs = {
  input: ICreateVacationInput;
};


export type IMutationCreateWorkdayAccountArgs = {
  input: ICreateWorkdayAccountInput;
};


export type IMutationDeleteCategoryArgs = {
  input: IDeleteCategoryInput;
};


export type IMutationDeleteCommentArgs = {
  input: IDeleteCommentInput;
};


export type IMutationDeleteHighfiveArgs = {
  input: IDeleteHighfiveInput;
};


export type IMutationDeleteLibraryObjectiveArgs = {
  input: IDeleteLibraryObjectiveInput;
};


export type IMutationDeleteLikeArgs = {
  input: IDeleteLikeInput;
};


export type IMutationDeleteOneOnOneArgs = {
  input: IDeleteOneOnOneInput;
};


export type IMutationDeleteTalkingPointArgs = {
  input: IDeleteTalkingPointInput;
};


export type IMutationDiscardManagerActionItemArgs = {
  input: IDiscardManagerActionItemInput;
};


export type IMutationDiscardSuggestionBoxPromptArgs = {
  input: IDiscardSuggestionBoxPromptInput;
};


export type IMutationDiscardWorkItemArgs = {
  input: IDiscardWorkItemInput;
};


export type IMutationImportFromWorkdayArgs = {
  input: IImportFromWorkdayInput;
};


export type IMutationLoginUserArgs = {
  input: ILoginUserInput;
};


export type IMutationLoginWithCodeArgs = {
  input: ILoginWithCodeInput;
};


export type IMutationRefreshTokenArgs = {
  input: IRefreshTokenInput;
};


export type IMutationSendRhabitFeedbackArgs = {
  input: ISendRhabitFeedbackInput;
};


export type IMutationSetAvatarFromLinkedInArgs = {
  input: ISetAvatarFromLinkedInInput;
};


export type IMutationSetNewAvatarPathArgs = {
  input: ISetNewAvatarPathInput;
};


export type IMutationUpdateAnswerArgs = {
  input: IUpdateAnswerInput;
};


export type IMutationUpdateBehaviorArgs = {
  input: IUpdateBehaviorInput;
};


export type IMutationUpdateCategoryArgs = {
  input: IUpdateCategoryInput;
};


export type IMutationUpdateHabitArgs = {
  input: IUpdateHabitInput;
};


export type IMutationUpdateObjectiveArgs = {
  input: IUpdateObjectiveInput;
};


export type IMutationUpdateOneOnOneArgs = {
  input: IUpdateOneOnOneInput;
};


export type IMutationUpdateRatersArgs = {
  input: IUpdateRatersInput;
};


export type IMutationUpdateRelationshipArgs = {
  input: IUpdateRelationshipInput;
};


export type IMutationUpdateReviewArgs = {
  input: IUpdateReviewInput;
};


export type IMutationUpdateRoleArgs = {
  input: IUpdateRoleInput;
};


export type IMutationUpdateTalkingPointArgs = {
  input: IUpdateTalkingPointInput;
};


export type IMutationUpdateUserArgs = {
  input: IUpdateUserInput;
};


export type IMutationUpdateUserHabitsArgs = {
  input: IUpdateUserHabitsInput;
};


export type IMutationUpdateWorkdayAccountArgs = {
  input: IUpdateWorkdayAccountInput;
};


export type IMutationVerifyPhoneNumberArgs = {
  input: IVerifyPhoneNumberInput;
};

export type IObjective = {
  __typename?: 'Objective';
  autoComplete: Scalars['Boolean'];
  category?: Maybe<ICategory>;
  checkInInterval?: Maybe<Scalars['Int']>;
  checkIns: Array<IObjectiveQuestion>;
  children: Array<IObjective>;
  color?: Maybe<Scalars['String']>;
  comments: Array<IComment>;
  completionCheckIns: Array<IObjectiveCompletionQuestion>;
  dateCompleted?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  keyResults: Array<IKeyResults>;
  labels: Array<ILabel>;
  latestResponse?: Maybe<Scalars['String']>;
  latestResponseDate?: Maybe<Scalars['String']>;
  library: Scalars['Boolean'];
  name: Scalars['String'];
  owner?: Maybe<IUser>;
  parent?: Maybe<IObjective>;
  private: Scalars['Boolean'];
  reviews: Array<IReview>;
  stakeholders: Array<IUser>;
  startDate?: Maybe<Scalars['String']>;
  status?: Maybe<IObjectiveStatus>;
};

export type IObjectiveCompletionQuestion = {
  __typename?: 'ObjectiveCompletionQuestion';
  answer?: Maybe<IAnswer>;
  checkIn: Scalars['String'];
  id: Scalars['ID'];
  objective: IObjective;
};

/** The connection type for Objective. */
export type IObjectiveConnection = {
  __typename?: 'ObjectiveConnection';
  /** A list of edges. */
  edges: Array<IObjectiveEdge>;
  /** A list of nodes. */
  nodes: Array<IObjective>;
  /** Information to aid in pagination */
  pageInfo: IExtendedPageInfo;
};

/** An edge in a connection. */
export type IObjectiveEdge = {
  __typename?: 'ObjectiveEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: IObjective;
};

export type IObjectiveQuestion = {
  __typename?: 'ObjectiveQuestion';
  answer?: Maybe<IAnswer>;
  checkIn: Scalars['String'];
  id: Scalars['ID'];
  objective: IObjective;
};

export type IObjectiveRansack = {
  id_eq?: Maybe<Scalars['String']>;
  id_eq_any?: Maybe<Scalars['String']>;
  id_eq_all?: Maybe<Scalars['String']>;
  id_not_eq?: Maybe<Scalars['String']>;
  id_not_eq_any?: Maybe<Scalars['String']>;
  id_not_eq_all?: Maybe<Scalars['String']>;
  id_matches?: Maybe<Scalars['String']>;
  id_matches_any?: Maybe<Scalars['String']>;
  id_matches_all?: Maybe<Scalars['String']>;
  id_does_not_match?: Maybe<Scalars['String']>;
  id_does_not_match_any?: Maybe<Scalars['String']>;
  id_does_not_match_all?: Maybe<Scalars['String']>;
  id_lt?: Maybe<Scalars['String']>;
  id_lt_any?: Maybe<Scalars['String']>;
  id_lt_all?: Maybe<Scalars['String']>;
  id_lteq?: Maybe<Scalars['String']>;
  id_lteq_any?: Maybe<Scalars['String']>;
  id_lteq_all?: Maybe<Scalars['String']>;
  id_gt?: Maybe<Scalars['String']>;
  id_gt_any?: Maybe<Scalars['String']>;
  id_gt_all?: Maybe<Scalars['String']>;
  id_gteq?: Maybe<Scalars['String']>;
  id_gteq_any?: Maybe<Scalars['String']>;
  id_gteq_all?: Maybe<Scalars['String']>;
  id_in?: Maybe<Scalars['String']>;
  id_in_any?: Maybe<Scalars['String']>;
  id_in_all?: Maybe<Scalars['String']>;
  id_not_in?: Maybe<Scalars['String']>;
  id_not_in_any?: Maybe<Scalars['String']>;
  id_not_in_all?: Maybe<Scalars['String']>;
  id_cont?: Maybe<Scalars['String']>;
  id_cont_any?: Maybe<Scalars['String']>;
  id_cont_all?: Maybe<Scalars['String']>;
  id_not_cont?: Maybe<Scalars['String']>;
  id_not_cont_any?: Maybe<Scalars['String']>;
  id_not_cont_all?: Maybe<Scalars['String']>;
  id_i_cont?: Maybe<Scalars['String']>;
  id_i_cont_any?: Maybe<Scalars['String']>;
  id_i_cont_all?: Maybe<Scalars['String']>;
  id_not_i_cont?: Maybe<Scalars['String']>;
  id_not_i_cont_any?: Maybe<Scalars['String']>;
  id_not_i_cont_all?: Maybe<Scalars['String']>;
  id_start?: Maybe<Scalars['String']>;
  id_start_any?: Maybe<Scalars['String']>;
  id_start_all?: Maybe<Scalars['String']>;
  id_not_start?: Maybe<Scalars['String']>;
  id_not_start_any?: Maybe<Scalars['String']>;
  id_not_start_all?: Maybe<Scalars['String']>;
  id_end?: Maybe<Scalars['String']>;
  id_end_any?: Maybe<Scalars['String']>;
  id_end_all?: Maybe<Scalars['String']>;
  id_not_end?: Maybe<Scalars['String']>;
  id_not_end_any?: Maybe<Scalars['String']>;
  id_not_end_all?: Maybe<Scalars['String']>;
  id_true?: Maybe<Scalars['String']>;
  id_not_true?: Maybe<Scalars['String']>;
  id_false?: Maybe<Scalars['String']>;
  id_not_false?: Maybe<Scalars['String']>;
  id_present?: Maybe<Scalars['String']>;
  id_blank?: Maybe<Scalars['String']>;
  id_null?: Maybe<Scalars['String']>;
  id_not_null?: Maybe<Scalars['String']>;
  id_count?: Maybe<Scalars['String']>;
  id_count_distinct?: Maybe<Scalars['String']>;
  id_sum?: Maybe<Scalars['String']>;
  id_avg?: Maybe<Scalars['String']>;
  id_min?: Maybe<Scalars['String']>;
  id_max?: Maybe<Scalars['String']>;
  name_eq?: Maybe<Scalars['String']>;
  name_eq_any?: Maybe<Scalars['String']>;
  name_eq_all?: Maybe<Scalars['String']>;
  name_not_eq?: Maybe<Scalars['String']>;
  name_not_eq_any?: Maybe<Scalars['String']>;
  name_not_eq_all?: Maybe<Scalars['String']>;
  name_matches?: Maybe<Scalars['String']>;
  name_matches_any?: Maybe<Scalars['String']>;
  name_matches_all?: Maybe<Scalars['String']>;
  name_does_not_match?: Maybe<Scalars['String']>;
  name_does_not_match_any?: Maybe<Scalars['String']>;
  name_does_not_match_all?: Maybe<Scalars['String']>;
  name_lt?: Maybe<Scalars['String']>;
  name_lt_any?: Maybe<Scalars['String']>;
  name_lt_all?: Maybe<Scalars['String']>;
  name_lteq?: Maybe<Scalars['String']>;
  name_lteq_any?: Maybe<Scalars['String']>;
  name_lteq_all?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gt_any?: Maybe<Scalars['String']>;
  name_gt_all?: Maybe<Scalars['String']>;
  name_gteq?: Maybe<Scalars['String']>;
  name_gteq_any?: Maybe<Scalars['String']>;
  name_gteq_all?: Maybe<Scalars['String']>;
  name_in?: Maybe<Scalars['String']>;
  name_in_any?: Maybe<Scalars['String']>;
  name_in_all?: Maybe<Scalars['String']>;
  name_not_in?: Maybe<Scalars['String']>;
  name_not_in_any?: Maybe<Scalars['String']>;
  name_not_in_all?: Maybe<Scalars['String']>;
  name_cont?: Maybe<Scalars['String']>;
  name_cont_any?: Maybe<Scalars['String']>;
  name_cont_all?: Maybe<Scalars['String']>;
  name_not_cont?: Maybe<Scalars['String']>;
  name_not_cont_any?: Maybe<Scalars['String']>;
  name_not_cont_all?: Maybe<Scalars['String']>;
  name_i_cont?: Maybe<Scalars['String']>;
  name_i_cont_any?: Maybe<Scalars['String']>;
  name_i_cont_all?: Maybe<Scalars['String']>;
  name_not_i_cont?: Maybe<Scalars['String']>;
  name_not_i_cont_any?: Maybe<Scalars['String']>;
  name_not_i_cont_all?: Maybe<Scalars['String']>;
  name_start?: Maybe<Scalars['String']>;
  name_start_any?: Maybe<Scalars['String']>;
  name_start_all?: Maybe<Scalars['String']>;
  name_not_start?: Maybe<Scalars['String']>;
  name_not_start_any?: Maybe<Scalars['String']>;
  name_not_start_all?: Maybe<Scalars['String']>;
  name_end?: Maybe<Scalars['String']>;
  name_end_any?: Maybe<Scalars['String']>;
  name_end_all?: Maybe<Scalars['String']>;
  name_not_end?: Maybe<Scalars['String']>;
  name_not_end_any?: Maybe<Scalars['String']>;
  name_not_end_all?: Maybe<Scalars['String']>;
  name_true?: Maybe<Scalars['String']>;
  name_not_true?: Maybe<Scalars['String']>;
  name_false?: Maybe<Scalars['String']>;
  name_not_false?: Maybe<Scalars['String']>;
  name_present?: Maybe<Scalars['String']>;
  name_blank?: Maybe<Scalars['String']>;
  name_null?: Maybe<Scalars['String']>;
  name_not_null?: Maybe<Scalars['String']>;
  name_count?: Maybe<Scalars['String']>;
  name_count_distinct?: Maybe<Scalars['String']>;
  name_sum?: Maybe<Scalars['String']>;
  name_avg?: Maybe<Scalars['String']>;
  name_min?: Maybe<Scalars['String']>;
  name_max?: Maybe<Scalars['String']>;
  start_date_eq?: Maybe<Scalars['String']>;
  start_date_eq_any?: Maybe<Scalars['String']>;
  start_date_eq_all?: Maybe<Scalars['String']>;
  start_date_not_eq?: Maybe<Scalars['String']>;
  start_date_not_eq_any?: Maybe<Scalars['String']>;
  start_date_not_eq_all?: Maybe<Scalars['String']>;
  start_date_matches?: Maybe<Scalars['String']>;
  start_date_matches_any?: Maybe<Scalars['String']>;
  start_date_matches_all?: Maybe<Scalars['String']>;
  start_date_does_not_match?: Maybe<Scalars['String']>;
  start_date_does_not_match_any?: Maybe<Scalars['String']>;
  start_date_does_not_match_all?: Maybe<Scalars['String']>;
  start_date_lt?: Maybe<Scalars['String']>;
  start_date_lt_any?: Maybe<Scalars['String']>;
  start_date_lt_all?: Maybe<Scalars['String']>;
  start_date_lteq?: Maybe<Scalars['String']>;
  start_date_lteq_any?: Maybe<Scalars['String']>;
  start_date_lteq_all?: Maybe<Scalars['String']>;
  start_date_gt?: Maybe<Scalars['String']>;
  start_date_gt_any?: Maybe<Scalars['String']>;
  start_date_gt_all?: Maybe<Scalars['String']>;
  start_date_gteq?: Maybe<Scalars['String']>;
  start_date_gteq_any?: Maybe<Scalars['String']>;
  start_date_gteq_all?: Maybe<Scalars['String']>;
  start_date_in?: Maybe<Scalars['String']>;
  start_date_in_any?: Maybe<Scalars['String']>;
  start_date_in_all?: Maybe<Scalars['String']>;
  start_date_not_in?: Maybe<Scalars['String']>;
  start_date_not_in_any?: Maybe<Scalars['String']>;
  start_date_not_in_all?: Maybe<Scalars['String']>;
  start_date_cont?: Maybe<Scalars['String']>;
  start_date_cont_any?: Maybe<Scalars['String']>;
  start_date_cont_all?: Maybe<Scalars['String']>;
  start_date_not_cont?: Maybe<Scalars['String']>;
  start_date_not_cont_any?: Maybe<Scalars['String']>;
  start_date_not_cont_all?: Maybe<Scalars['String']>;
  start_date_i_cont?: Maybe<Scalars['String']>;
  start_date_i_cont_any?: Maybe<Scalars['String']>;
  start_date_i_cont_all?: Maybe<Scalars['String']>;
  start_date_not_i_cont?: Maybe<Scalars['String']>;
  start_date_not_i_cont_any?: Maybe<Scalars['String']>;
  start_date_not_i_cont_all?: Maybe<Scalars['String']>;
  start_date_start?: Maybe<Scalars['String']>;
  start_date_start_any?: Maybe<Scalars['String']>;
  start_date_start_all?: Maybe<Scalars['String']>;
  start_date_not_start?: Maybe<Scalars['String']>;
  start_date_not_start_any?: Maybe<Scalars['String']>;
  start_date_not_start_all?: Maybe<Scalars['String']>;
  start_date_end?: Maybe<Scalars['String']>;
  start_date_end_any?: Maybe<Scalars['String']>;
  start_date_end_all?: Maybe<Scalars['String']>;
  start_date_not_end?: Maybe<Scalars['String']>;
  start_date_not_end_any?: Maybe<Scalars['String']>;
  start_date_not_end_all?: Maybe<Scalars['String']>;
  start_date_true?: Maybe<Scalars['String']>;
  start_date_not_true?: Maybe<Scalars['String']>;
  start_date_false?: Maybe<Scalars['String']>;
  start_date_not_false?: Maybe<Scalars['String']>;
  start_date_present?: Maybe<Scalars['String']>;
  start_date_blank?: Maybe<Scalars['String']>;
  start_date_null?: Maybe<Scalars['String']>;
  start_date_not_null?: Maybe<Scalars['String']>;
  start_date_count?: Maybe<Scalars['String']>;
  start_date_count_distinct?: Maybe<Scalars['String']>;
  start_date_sum?: Maybe<Scalars['String']>;
  start_date_avg?: Maybe<Scalars['String']>;
  start_date_min?: Maybe<Scalars['String']>;
  start_date_max?: Maybe<Scalars['String']>;
  delivery_date_eq?: Maybe<Scalars['String']>;
  delivery_date_eq_any?: Maybe<Scalars['String']>;
  delivery_date_eq_all?: Maybe<Scalars['String']>;
  delivery_date_not_eq?: Maybe<Scalars['String']>;
  delivery_date_not_eq_any?: Maybe<Scalars['String']>;
  delivery_date_not_eq_all?: Maybe<Scalars['String']>;
  delivery_date_matches?: Maybe<Scalars['String']>;
  delivery_date_matches_any?: Maybe<Scalars['String']>;
  delivery_date_matches_all?: Maybe<Scalars['String']>;
  delivery_date_does_not_match?: Maybe<Scalars['String']>;
  delivery_date_does_not_match_any?: Maybe<Scalars['String']>;
  delivery_date_does_not_match_all?: Maybe<Scalars['String']>;
  delivery_date_lt?: Maybe<Scalars['String']>;
  delivery_date_lt_any?: Maybe<Scalars['String']>;
  delivery_date_lt_all?: Maybe<Scalars['String']>;
  delivery_date_lteq?: Maybe<Scalars['String']>;
  delivery_date_lteq_any?: Maybe<Scalars['String']>;
  delivery_date_lteq_all?: Maybe<Scalars['String']>;
  delivery_date_gt?: Maybe<Scalars['String']>;
  delivery_date_gt_any?: Maybe<Scalars['String']>;
  delivery_date_gt_all?: Maybe<Scalars['String']>;
  delivery_date_gteq?: Maybe<Scalars['String']>;
  delivery_date_gteq_any?: Maybe<Scalars['String']>;
  delivery_date_gteq_all?: Maybe<Scalars['String']>;
  delivery_date_in?: Maybe<Scalars['String']>;
  delivery_date_in_any?: Maybe<Scalars['String']>;
  delivery_date_in_all?: Maybe<Scalars['String']>;
  delivery_date_not_in?: Maybe<Scalars['String']>;
  delivery_date_not_in_any?: Maybe<Scalars['String']>;
  delivery_date_not_in_all?: Maybe<Scalars['String']>;
  delivery_date_cont?: Maybe<Scalars['String']>;
  delivery_date_cont_any?: Maybe<Scalars['String']>;
  delivery_date_cont_all?: Maybe<Scalars['String']>;
  delivery_date_not_cont?: Maybe<Scalars['String']>;
  delivery_date_not_cont_any?: Maybe<Scalars['String']>;
  delivery_date_not_cont_all?: Maybe<Scalars['String']>;
  delivery_date_i_cont?: Maybe<Scalars['String']>;
  delivery_date_i_cont_any?: Maybe<Scalars['String']>;
  delivery_date_i_cont_all?: Maybe<Scalars['String']>;
  delivery_date_not_i_cont?: Maybe<Scalars['String']>;
  delivery_date_not_i_cont_any?: Maybe<Scalars['String']>;
  delivery_date_not_i_cont_all?: Maybe<Scalars['String']>;
  delivery_date_start?: Maybe<Scalars['String']>;
  delivery_date_start_any?: Maybe<Scalars['String']>;
  delivery_date_start_all?: Maybe<Scalars['String']>;
  delivery_date_not_start?: Maybe<Scalars['String']>;
  delivery_date_not_start_any?: Maybe<Scalars['String']>;
  delivery_date_not_start_all?: Maybe<Scalars['String']>;
  delivery_date_end?: Maybe<Scalars['String']>;
  delivery_date_end_any?: Maybe<Scalars['String']>;
  delivery_date_end_all?: Maybe<Scalars['String']>;
  delivery_date_not_end?: Maybe<Scalars['String']>;
  delivery_date_not_end_any?: Maybe<Scalars['String']>;
  delivery_date_not_end_all?: Maybe<Scalars['String']>;
  delivery_date_true?: Maybe<Scalars['String']>;
  delivery_date_not_true?: Maybe<Scalars['String']>;
  delivery_date_false?: Maybe<Scalars['String']>;
  delivery_date_not_false?: Maybe<Scalars['String']>;
  delivery_date_present?: Maybe<Scalars['String']>;
  delivery_date_blank?: Maybe<Scalars['String']>;
  delivery_date_null?: Maybe<Scalars['String']>;
  delivery_date_not_null?: Maybe<Scalars['String']>;
  delivery_date_count?: Maybe<Scalars['String']>;
  delivery_date_count_distinct?: Maybe<Scalars['String']>;
  delivery_date_sum?: Maybe<Scalars['String']>;
  delivery_date_avg?: Maybe<Scalars['String']>;
  delivery_date_min?: Maybe<Scalars['String']>;
  delivery_date_max?: Maybe<Scalars['String']>;
  date_completed_eq?: Maybe<Scalars['String']>;
  date_completed_eq_any?: Maybe<Scalars['String']>;
  date_completed_eq_all?: Maybe<Scalars['String']>;
  date_completed_not_eq?: Maybe<Scalars['String']>;
  date_completed_not_eq_any?: Maybe<Scalars['String']>;
  date_completed_not_eq_all?: Maybe<Scalars['String']>;
  date_completed_matches?: Maybe<Scalars['String']>;
  date_completed_matches_any?: Maybe<Scalars['String']>;
  date_completed_matches_all?: Maybe<Scalars['String']>;
  date_completed_does_not_match?: Maybe<Scalars['String']>;
  date_completed_does_not_match_any?: Maybe<Scalars['String']>;
  date_completed_does_not_match_all?: Maybe<Scalars['String']>;
  date_completed_lt?: Maybe<Scalars['String']>;
  date_completed_lt_any?: Maybe<Scalars['String']>;
  date_completed_lt_all?: Maybe<Scalars['String']>;
  date_completed_lteq?: Maybe<Scalars['String']>;
  date_completed_lteq_any?: Maybe<Scalars['String']>;
  date_completed_lteq_all?: Maybe<Scalars['String']>;
  date_completed_gt?: Maybe<Scalars['String']>;
  date_completed_gt_any?: Maybe<Scalars['String']>;
  date_completed_gt_all?: Maybe<Scalars['String']>;
  date_completed_gteq?: Maybe<Scalars['String']>;
  date_completed_gteq_any?: Maybe<Scalars['String']>;
  date_completed_gteq_all?: Maybe<Scalars['String']>;
  date_completed_in?: Maybe<Scalars['String']>;
  date_completed_in_any?: Maybe<Scalars['String']>;
  date_completed_in_all?: Maybe<Scalars['String']>;
  date_completed_not_in?: Maybe<Scalars['String']>;
  date_completed_not_in_any?: Maybe<Scalars['String']>;
  date_completed_not_in_all?: Maybe<Scalars['String']>;
  date_completed_cont?: Maybe<Scalars['String']>;
  date_completed_cont_any?: Maybe<Scalars['String']>;
  date_completed_cont_all?: Maybe<Scalars['String']>;
  date_completed_not_cont?: Maybe<Scalars['String']>;
  date_completed_not_cont_any?: Maybe<Scalars['String']>;
  date_completed_not_cont_all?: Maybe<Scalars['String']>;
  date_completed_i_cont?: Maybe<Scalars['String']>;
  date_completed_i_cont_any?: Maybe<Scalars['String']>;
  date_completed_i_cont_all?: Maybe<Scalars['String']>;
  date_completed_not_i_cont?: Maybe<Scalars['String']>;
  date_completed_not_i_cont_any?: Maybe<Scalars['String']>;
  date_completed_not_i_cont_all?: Maybe<Scalars['String']>;
  date_completed_start?: Maybe<Scalars['String']>;
  date_completed_start_any?: Maybe<Scalars['String']>;
  date_completed_start_all?: Maybe<Scalars['String']>;
  date_completed_not_start?: Maybe<Scalars['String']>;
  date_completed_not_start_any?: Maybe<Scalars['String']>;
  date_completed_not_start_all?: Maybe<Scalars['String']>;
  date_completed_end?: Maybe<Scalars['String']>;
  date_completed_end_any?: Maybe<Scalars['String']>;
  date_completed_end_all?: Maybe<Scalars['String']>;
  date_completed_not_end?: Maybe<Scalars['String']>;
  date_completed_not_end_any?: Maybe<Scalars['String']>;
  date_completed_not_end_all?: Maybe<Scalars['String']>;
  date_completed_true?: Maybe<Scalars['String']>;
  date_completed_not_true?: Maybe<Scalars['String']>;
  date_completed_false?: Maybe<Scalars['String']>;
  date_completed_not_false?: Maybe<Scalars['String']>;
  date_completed_present?: Maybe<Scalars['String']>;
  date_completed_blank?: Maybe<Scalars['String']>;
  date_completed_null?: Maybe<Scalars['String']>;
  date_completed_not_null?: Maybe<Scalars['String']>;
  date_completed_count?: Maybe<Scalars['String']>;
  date_completed_count_distinct?: Maybe<Scalars['String']>;
  date_completed_sum?: Maybe<Scalars['String']>;
  date_completed_avg?: Maybe<Scalars['String']>;
  date_completed_min?: Maybe<Scalars['String']>;
  date_completed_max?: Maybe<Scalars['String']>;
  owner_id_eq?: Maybe<Scalars['String']>;
  owner_id_eq_any?: Maybe<Scalars['String']>;
  owner_id_eq_all?: Maybe<Scalars['String']>;
  owner_id_not_eq?: Maybe<Scalars['String']>;
  owner_id_not_eq_any?: Maybe<Scalars['String']>;
  owner_id_not_eq_all?: Maybe<Scalars['String']>;
  owner_id_matches?: Maybe<Scalars['String']>;
  owner_id_matches_any?: Maybe<Scalars['String']>;
  owner_id_matches_all?: Maybe<Scalars['String']>;
  owner_id_does_not_match?: Maybe<Scalars['String']>;
  owner_id_does_not_match_any?: Maybe<Scalars['String']>;
  owner_id_does_not_match_all?: Maybe<Scalars['String']>;
  owner_id_lt?: Maybe<Scalars['String']>;
  owner_id_lt_any?: Maybe<Scalars['String']>;
  owner_id_lt_all?: Maybe<Scalars['String']>;
  owner_id_lteq?: Maybe<Scalars['String']>;
  owner_id_lteq_any?: Maybe<Scalars['String']>;
  owner_id_lteq_all?: Maybe<Scalars['String']>;
  owner_id_gt?: Maybe<Scalars['String']>;
  owner_id_gt_any?: Maybe<Scalars['String']>;
  owner_id_gt_all?: Maybe<Scalars['String']>;
  owner_id_gteq?: Maybe<Scalars['String']>;
  owner_id_gteq_any?: Maybe<Scalars['String']>;
  owner_id_gteq_all?: Maybe<Scalars['String']>;
  owner_id_in?: Maybe<Scalars['String']>;
  owner_id_in_any?: Maybe<Scalars['String']>;
  owner_id_in_all?: Maybe<Scalars['String']>;
  owner_id_not_in?: Maybe<Scalars['String']>;
  owner_id_not_in_any?: Maybe<Scalars['String']>;
  owner_id_not_in_all?: Maybe<Scalars['String']>;
  owner_id_cont?: Maybe<Scalars['String']>;
  owner_id_cont_any?: Maybe<Scalars['String']>;
  owner_id_cont_all?: Maybe<Scalars['String']>;
  owner_id_not_cont?: Maybe<Scalars['String']>;
  owner_id_not_cont_any?: Maybe<Scalars['String']>;
  owner_id_not_cont_all?: Maybe<Scalars['String']>;
  owner_id_i_cont?: Maybe<Scalars['String']>;
  owner_id_i_cont_any?: Maybe<Scalars['String']>;
  owner_id_i_cont_all?: Maybe<Scalars['String']>;
  owner_id_not_i_cont?: Maybe<Scalars['String']>;
  owner_id_not_i_cont_any?: Maybe<Scalars['String']>;
  owner_id_not_i_cont_all?: Maybe<Scalars['String']>;
  owner_id_start?: Maybe<Scalars['String']>;
  owner_id_start_any?: Maybe<Scalars['String']>;
  owner_id_start_all?: Maybe<Scalars['String']>;
  owner_id_not_start?: Maybe<Scalars['String']>;
  owner_id_not_start_any?: Maybe<Scalars['String']>;
  owner_id_not_start_all?: Maybe<Scalars['String']>;
  owner_id_end?: Maybe<Scalars['String']>;
  owner_id_end_any?: Maybe<Scalars['String']>;
  owner_id_end_all?: Maybe<Scalars['String']>;
  owner_id_not_end?: Maybe<Scalars['String']>;
  owner_id_not_end_any?: Maybe<Scalars['String']>;
  owner_id_not_end_all?: Maybe<Scalars['String']>;
  owner_id_true?: Maybe<Scalars['String']>;
  owner_id_not_true?: Maybe<Scalars['String']>;
  owner_id_false?: Maybe<Scalars['String']>;
  owner_id_not_false?: Maybe<Scalars['String']>;
  owner_id_present?: Maybe<Scalars['String']>;
  owner_id_blank?: Maybe<Scalars['String']>;
  owner_id_null?: Maybe<Scalars['String']>;
  owner_id_not_null?: Maybe<Scalars['String']>;
  owner_id_count?: Maybe<Scalars['String']>;
  owner_id_count_distinct?: Maybe<Scalars['String']>;
  owner_id_sum?: Maybe<Scalars['String']>;
  owner_id_avg?: Maybe<Scalars['String']>;
  owner_id_min?: Maybe<Scalars['String']>;
  owner_id_max?: Maybe<Scalars['String']>;
  creator_id_eq?: Maybe<Scalars['String']>;
  creator_id_eq_any?: Maybe<Scalars['String']>;
  creator_id_eq_all?: Maybe<Scalars['String']>;
  creator_id_not_eq?: Maybe<Scalars['String']>;
  creator_id_not_eq_any?: Maybe<Scalars['String']>;
  creator_id_not_eq_all?: Maybe<Scalars['String']>;
  creator_id_matches?: Maybe<Scalars['String']>;
  creator_id_matches_any?: Maybe<Scalars['String']>;
  creator_id_matches_all?: Maybe<Scalars['String']>;
  creator_id_does_not_match?: Maybe<Scalars['String']>;
  creator_id_does_not_match_any?: Maybe<Scalars['String']>;
  creator_id_does_not_match_all?: Maybe<Scalars['String']>;
  creator_id_lt?: Maybe<Scalars['String']>;
  creator_id_lt_any?: Maybe<Scalars['String']>;
  creator_id_lt_all?: Maybe<Scalars['String']>;
  creator_id_lteq?: Maybe<Scalars['String']>;
  creator_id_lteq_any?: Maybe<Scalars['String']>;
  creator_id_lteq_all?: Maybe<Scalars['String']>;
  creator_id_gt?: Maybe<Scalars['String']>;
  creator_id_gt_any?: Maybe<Scalars['String']>;
  creator_id_gt_all?: Maybe<Scalars['String']>;
  creator_id_gteq?: Maybe<Scalars['String']>;
  creator_id_gteq_any?: Maybe<Scalars['String']>;
  creator_id_gteq_all?: Maybe<Scalars['String']>;
  creator_id_in?: Maybe<Scalars['String']>;
  creator_id_in_any?: Maybe<Scalars['String']>;
  creator_id_in_all?: Maybe<Scalars['String']>;
  creator_id_not_in?: Maybe<Scalars['String']>;
  creator_id_not_in_any?: Maybe<Scalars['String']>;
  creator_id_not_in_all?: Maybe<Scalars['String']>;
  creator_id_cont?: Maybe<Scalars['String']>;
  creator_id_cont_any?: Maybe<Scalars['String']>;
  creator_id_cont_all?: Maybe<Scalars['String']>;
  creator_id_not_cont?: Maybe<Scalars['String']>;
  creator_id_not_cont_any?: Maybe<Scalars['String']>;
  creator_id_not_cont_all?: Maybe<Scalars['String']>;
  creator_id_i_cont?: Maybe<Scalars['String']>;
  creator_id_i_cont_any?: Maybe<Scalars['String']>;
  creator_id_i_cont_all?: Maybe<Scalars['String']>;
  creator_id_not_i_cont?: Maybe<Scalars['String']>;
  creator_id_not_i_cont_any?: Maybe<Scalars['String']>;
  creator_id_not_i_cont_all?: Maybe<Scalars['String']>;
  creator_id_start?: Maybe<Scalars['String']>;
  creator_id_start_any?: Maybe<Scalars['String']>;
  creator_id_start_all?: Maybe<Scalars['String']>;
  creator_id_not_start?: Maybe<Scalars['String']>;
  creator_id_not_start_any?: Maybe<Scalars['String']>;
  creator_id_not_start_all?: Maybe<Scalars['String']>;
  creator_id_end?: Maybe<Scalars['String']>;
  creator_id_end_any?: Maybe<Scalars['String']>;
  creator_id_end_all?: Maybe<Scalars['String']>;
  creator_id_not_end?: Maybe<Scalars['String']>;
  creator_id_not_end_any?: Maybe<Scalars['String']>;
  creator_id_not_end_all?: Maybe<Scalars['String']>;
  creator_id_true?: Maybe<Scalars['String']>;
  creator_id_not_true?: Maybe<Scalars['String']>;
  creator_id_false?: Maybe<Scalars['String']>;
  creator_id_not_false?: Maybe<Scalars['String']>;
  creator_id_present?: Maybe<Scalars['String']>;
  creator_id_blank?: Maybe<Scalars['String']>;
  creator_id_null?: Maybe<Scalars['String']>;
  creator_id_not_null?: Maybe<Scalars['String']>;
  creator_id_count?: Maybe<Scalars['String']>;
  creator_id_count_distinct?: Maybe<Scalars['String']>;
  creator_id_sum?: Maybe<Scalars['String']>;
  creator_id_avg?: Maybe<Scalars['String']>;
  creator_id_min?: Maybe<Scalars['String']>;
  creator_id_max?: Maybe<Scalars['String']>;
  parent_id_eq?: Maybe<Scalars['String']>;
  parent_id_eq_any?: Maybe<Scalars['String']>;
  parent_id_eq_all?: Maybe<Scalars['String']>;
  parent_id_not_eq?: Maybe<Scalars['String']>;
  parent_id_not_eq_any?: Maybe<Scalars['String']>;
  parent_id_not_eq_all?: Maybe<Scalars['String']>;
  parent_id_matches?: Maybe<Scalars['String']>;
  parent_id_matches_any?: Maybe<Scalars['String']>;
  parent_id_matches_all?: Maybe<Scalars['String']>;
  parent_id_does_not_match?: Maybe<Scalars['String']>;
  parent_id_does_not_match_any?: Maybe<Scalars['String']>;
  parent_id_does_not_match_all?: Maybe<Scalars['String']>;
  parent_id_lt?: Maybe<Scalars['String']>;
  parent_id_lt_any?: Maybe<Scalars['String']>;
  parent_id_lt_all?: Maybe<Scalars['String']>;
  parent_id_lteq?: Maybe<Scalars['String']>;
  parent_id_lteq_any?: Maybe<Scalars['String']>;
  parent_id_lteq_all?: Maybe<Scalars['String']>;
  parent_id_gt?: Maybe<Scalars['String']>;
  parent_id_gt_any?: Maybe<Scalars['String']>;
  parent_id_gt_all?: Maybe<Scalars['String']>;
  parent_id_gteq?: Maybe<Scalars['String']>;
  parent_id_gteq_any?: Maybe<Scalars['String']>;
  parent_id_gteq_all?: Maybe<Scalars['String']>;
  parent_id_in?: Maybe<Scalars['String']>;
  parent_id_in_any?: Maybe<Scalars['String']>;
  parent_id_in_all?: Maybe<Scalars['String']>;
  parent_id_not_in?: Maybe<Scalars['String']>;
  parent_id_not_in_any?: Maybe<Scalars['String']>;
  parent_id_not_in_all?: Maybe<Scalars['String']>;
  parent_id_cont?: Maybe<Scalars['String']>;
  parent_id_cont_any?: Maybe<Scalars['String']>;
  parent_id_cont_all?: Maybe<Scalars['String']>;
  parent_id_not_cont?: Maybe<Scalars['String']>;
  parent_id_not_cont_any?: Maybe<Scalars['String']>;
  parent_id_not_cont_all?: Maybe<Scalars['String']>;
  parent_id_i_cont?: Maybe<Scalars['String']>;
  parent_id_i_cont_any?: Maybe<Scalars['String']>;
  parent_id_i_cont_all?: Maybe<Scalars['String']>;
  parent_id_not_i_cont?: Maybe<Scalars['String']>;
  parent_id_not_i_cont_any?: Maybe<Scalars['String']>;
  parent_id_not_i_cont_all?: Maybe<Scalars['String']>;
  parent_id_start?: Maybe<Scalars['String']>;
  parent_id_start_any?: Maybe<Scalars['String']>;
  parent_id_start_all?: Maybe<Scalars['String']>;
  parent_id_not_start?: Maybe<Scalars['String']>;
  parent_id_not_start_any?: Maybe<Scalars['String']>;
  parent_id_not_start_all?: Maybe<Scalars['String']>;
  parent_id_end?: Maybe<Scalars['String']>;
  parent_id_end_any?: Maybe<Scalars['String']>;
  parent_id_end_all?: Maybe<Scalars['String']>;
  parent_id_not_end?: Maybe<Scalars['String']>;
  parent_id_not_end_any?: Maybe<Scalars['String']>;
  parent_id_not_end_all?: Maybe<Scalars['String']>;
  parent_id_true?: Maybe<Scalars['String']>;
  parent_id_not_true?: Maybe<Scalars['String']>;
  parent_id_false?: Maybe<Scalars['String']>;
  parent_id_not_false?: Maybe<Scalars['String']>;
  parent_id_present?: Maybe<Scalars['String']>;
  parent_id_blank?: Maybe<Scalars['String']>;
  parent_id_null?: Maybe<Scalars['String']>;
  parent_id_not_null?: Maybe<Scalars['String']>;
  parent_id_count?: Maybe<Scalars['String']>;
  parent_id_count_distinct?: Maybe<Scalars['String']>;
  parent_id_sum?: Maybe<Scalars['String']>;
  parent_id_avg?: Maybe<Scalars['String']>;
  parent_id_min?: Maybe<Scalars['String']>;
  parent_id_max?: Maybe<Scalars['String']>;
  check_in_interval_eq?: Maybe<Scalars['String']>;
  check_in_interval_eq_any?: Maybe<Scalars['String']>;
  check_in_interval_eq_all?: Maybe<Scalars['String']>;
  check_in_interval_not_eq?: Maybe<Scalars['String']>;
  check_in_interval_not_eq_any?: Maybe<Scalars['String']>;
  check_in_interval_not_eq_all?: Maybe<Scalars['String']>;
  check_in_interval_matches?: Maybe<Scalars['String']>;
  check_in_interval_matches_any?: Maybe<Scalars['String']>;
  check_in_interval_matches_all?: Maybe<Scalars['String']>;
  check_in_interval_does_not_match?: Maybe<Scalars['String']>;
  check_in_interval_does_not_match_any?: Maybe<Scalars['String']>;
  check_in_interval_does_not_match_all?: Maybe<Scalars['String']>;
  check_in_interval_lt?: Maybe<Scalars['String']>;
  check_in_interval_lt_any?: Maybe<Scalars['String']>;
  check_in_interval_lt_all?: Maybe<Scalars['String']>;
  check_in_interval_lteq?: Maybe<Scalars['String']>;
  check_in_interval_lteq_any?: Maybe<Scalars['String']>;
  check_in_interval_lteq_all?: Maybe<Scalars['String']>;
  check_in_interval_gt?: Maybe<Scalars['String']>;
  check_in_interval_gt_any?: Maybe<Scalars['String']>;
  check_in_interval_gt_all?: Maybe<Scalars['String']>;
  check_in_interval_gteq?: Maybe<Scalars['String']>;
  check_in_interval_gteq_any?: Maybe<Scalars['String']>;
  check_in_interval_gteq_all?: Maybe<Scalars['String']>;
  check_in_interval_in?: Maybe<Scalars['String']>;
  check_in_interval_in_any?: Maybe<Scalars['String']>;
  check_in_interval_in_all?: Maybe<Scalars['String']>;
  check_in_interval_not_in?: Maybe<Scalars['String']>;
  check_in_interval_not_in_any?: Maybe<Scalars['String']>;
  check_in_interval_not_in_all?: Maybe<Scalars['String']>;
  check_in_interval_cont?: Maybe<Scalars['String']>;
  check_in_interval_cont_any?: Maybe<Scalars['String']>;
  check_in_interval_cont_all?: Maybe<Scalars['String']>;
  check_in_interval_not_cont?: Maybe<Scalars['String']>;
  check_in_interval_not_cont_any?: Maybe<Scalars['String']>;
  check_in_interval_not_cont_all?: Maybe<Scalars['String']>;
  check_in_interval_i_cont?: Maybe<Scalars['String']>;
  check_in_interval_i_cont_any?: Maybe<Scalars['String']>;
  check_in_interval_i_cont_all?: Maybe<Scalars['String']>;
  check_in_interval_not_i_cont?: Maybe<Scalars['String']>;
  check_in_interval_not_i_cont_any?: Maybe<Scalars['String']>;
  check_in_interval_not_i_cont_all?: Maybe<Scalars['String']>;
  check_in_interval_start?: Maybe<Scalars['String']>;
  check_in_interval_start_any?: Maybe<Scalars['String']>;
  check_in_interval_start_all?: Maybe<Scalars['String']>;
  check_in_interval_not_start?: Maybe<Scalars['String']>;
  check_in_interval_not_start_any?: Maybe<Scalars['String']>;
  check_in_interval_not_start_all?: Maybe<Scalars['String']>;
  check_in_interval_end?: Maybe<Scalars['String']>;
  check_in_interval_end_any?: Maybe<Scalars['String']>;
  check_in_interval_end_all?: Maybe<Scalars['String']>;
  check_in_interval_not_end?: Maybe<Scalars['String']>;
  check_in_interval_not_end_any?: Maybe<Scalars['String']>;
  check_in_interval_not_end_all?: Maybe<Scalars['String']>;
  check_in_interval_true?: Maybe<Scalars['String']>;
  check_in_interval_not_true?: Maybe<Scalars['String']>;
  check_in_interval_false?: Maybe<Scalars['String']>;
  check_in_interval_not_false?: Maybe<Scalars['String']>;
  check_in_interval_present?: Maybe<Scalars['String']>;
  check_in_interval_blank?: Maybe<Scalars['String']>;
  check_in_interval_null?: Maybe<Scalars['String']>;
  check_in_interval_not_null?: Maybe<Scalars['String']>;
  check_in_interval_count?: Maybe<Scalars['String']>;
  check_in_interval_count_distinct?: Maybe<Scalars['String']>;
  check_in_interval_sum?: Maybe<Scalars['String']>;
  check_in_interval_avg?: Maybe<Scalars['String']>;
  check_in_interval_min?: Maybe<Scalars['String']>;
  check_in_interval_max?: Maybe<Scalars['String']>;
  status_eq?: Maybe<Scalars['String']>;
  status_eq_any?: Maybe<Scalars['String']>;
  status_eq_all?: Maybe<Scalars['String']>;
  status_not_eq?: Maybe<Scalars['String']>;
  status_not_eq_any?: Maybe<Scalars['String']>;
  status_not_eq_all?: Maybe<Scalars['String']>;
  status_matches?: Maybe<Scalars['String']>;
  status_matches_any?: Maybe<Scalars['String']>;
  status_matches_all?: Maybe<Scalars['String']>;
  status_does_not_match?: Maybe<Scalars['String']>;
  status_does_not_match_any?: Maybe<Scalars['String']>;
  status_does_not_match_all?: Maybe<Scalars['String']>;
  status_lt?: Maybe<Scalars['String']>;
  status_lt_any?: Maybe<Scalars['String']>;
  status_lt_all?: Maybe<Scalars['String']>;
  status_lteq?: Maybe<Scalars['String']>;
  status_lteq_any?: Maybe<Scalars['String']>;
  status_lteq_all?: Maybe<Scalars['String']>;
  status_gt?: Maybe<Scalars['String']>;
  status_gt_any?: Maybe<Scalars['String']>;
  status_gt_all?: Maybe<Scalars['String']>;
  status_gteq?: Maybe<Scalars['String']>;
  status_gteq_any?: Maybe<Scalars['String']>;
  status_gteq_all?: Maybe<Scalars['String']>;
  status_in?: Maybe<Scalars['String']>;
  status_in_any?: Maybe<Scalars['String']>;
  status_in_all?: Maybe<Scalars['String']>;
  status_not_in?: Maybe<Scalars['String']>;
  status_not_in_any?: Maybe<Scalars['String']>;
  status_not_in_all?: Maybe<Scalars['String']>;
  status_cont?: Maybe<Scalars['String']>;
  status_cont_any?: Maybe<Scalars['String']>;
  status_cont_all?: Maybe<Scalars['String']>;
  status_not_cont?: Maybe<Scalars['String']>;
  status_not_cont_any?: Maybe<Scalars['String']>;
  status_not_cont_all?: Maybe<Scalars['String']>;
  status_i_cont?: Maybe<Scalars['String']>;
  status_i_cont_any?: Maybe<Scalars['String']>;
  status_i_cont_all?: Maybe<Scalars['String']>;
  status_not_i_cont?: Maybe<Scalars['String']>;
  status_not_i_cont_any?: Maybe<Scalars['String']>;
  status_not_i_cont_all?: Maybe<Scalars['String']>;
  status_start?: Maybe<Scalars['String']>;
  status_start_any?: Maybe<Scalars['String']>;
  status_start_all?: Maybe<Scalars['String']>;
  status_not_start?: Maybe<Scalars['String']>;
  status_not_start_any?: Maybe<Scalars['String']>;
  status_not_start_all?: Maybe<Scalars['String']>;
  status_end?: Maybe<Scalars['String']>;
  status_end_any?: Maybe<Scalars['String']>;
  status_end_all?: Maybe<Scalars['String']>;
  status_not_end?: Maybe<Scalars['String']>;
  status_not_end_any?: Maybe<Scalars['String']>;
  status_not_end_all?: Maybe<Scalars['String']>;
  status_true?: Maybe<Scalars['String']>;
  status_not_true?: Maybe<Scalars['String']>;
  status_false?: Maybe<Scalars['String']>;
  status_not_false?: Maybe<Scalars['String']>;
  status_present?: Maybe<Scalars['String']>;
  status_blank?: Maybe<Scalars['String']>;
  status_null?: Maybe<Scalars['String']>;
  status_not_null?: Maybe<Scalars['String']>;
  status_count?: Maybe<Scalars['String']>;
  status_count_distinct?: Maybe<Scalars['String']>;
  status_sum?: Maybe<Scalars['String']>;
  status_avg?: Maybe<Scalars['String']>;
  status_min?: Maybe<Scalars['String']>;
  status_max?: Maybe<Scalars['String']>;
  description_eq?: Maybe<Scalars['String']>;
  description_eq_any?: Maybe<Scalars['String']>;
  description_eq_all?: Maybe<Scalars['String']>;
  description_not_eq?: Maybe<Scalars['String']>;
  description_not_eq_any?: Maybe<Scalars['String']>;
  description_not_eq_all?: Maybe<Scalars['String']>;
  description_matches?: Maybe<Scalars['String']>;
  description_matches_any?: Maybe<Scalars['String']>;
  description_matches_all?: Maybe<Scalars['String']>;
  description_does_not_match?: Maybe<Scalars['String']>;
  description_does_not_match_any?: Maybe<Scalars['String']>;
  description_does_not_match_all?: Maybe<Scalars['String']>;
  description_lt?: Maybe<Scalars['String']>;
  description_lt_any?: Maybe<Scalars['String']>;
  description_lt_all?: Maybe<Scalars['String']>;
  description_lteq?: Maybe<Scalars['String']>;
  description_lteq_any?: Maybe<Scalars['String']>;
  description_lteq_all?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gt_any?: Maybe<Scalars['String']>;
  description_gt_all?: Maybe<Scalars['String']>;
  description_gteq?: Maybe<Scalars['String']>;
  description_gteq_any?: Maybe<Scalars['String']>;
  description_gteq_all?: Maybe<Scalars['String']>;
  description_in?: Maybe<Scalars['String']>;
  description_in_any?: Maybe<Scalars['String']>;
  description_in_all?: Maybe<Scalars['String']>;
  description_not_in?: Maybe<Scalars['String']>;
  description_not_in_any?: Maybe<Scalars['String']>;
  description_not_in_all?: Maybe<Scalars['String']>;
  description_cont?: Maybe<Scalars['String']>;
  description_cont_any?: Maybe<Scalars['String']>;
  description_cont_all?: Maybe<Scalars['String']>;
  description_not_cont?: Maybe<Scalars['String']>;
  description_not_cont_any?: Maybe<Scalars['String']>;
  description_not_cont_all?: Maybe<Scalars['String']>;
  description_i_cont?: Maybe<Scalars['String']>;
  description_i_cont_any?: Maybe<Scalars['String']>;
  description_i_cont_all?: Maybe<Scalars['String']>;
  description_not_i_cont?: Maybe<Scalars['String']>;
  description_not_i_cont_any?: Maybe<Scalars['String']>;
  description_not_i_cont_all?: Maybe<Scalars['String']>;
  description_start?: Maybe<Scalars['String']>;
  description_start_any?: Maybe<Scalars['String']>;
  description_start_all?: Maybe<Scalars['String']>;
  description_not_start?: Maybe<Scalars['String']>;
  description_not_start_any?: Maybe<Scalars['String']>;
  description_not_start_all?: Maybe<Scalars['String']>;
  description_end?: Maybe<Scalars['String']>;
  description_end_any?: Maybe<Scalars['String']>;
  description_end_all?: Maybe<Scalars['String']>;
  description_not_end?: Maybe<Scalars['String']>;
  description_not_end_any?: Maybe<Scalars['String']>;
  description_not_end_all?: Maybe<Scalars['String']>;
  description_true?: Maybe<Scalars['String']>;
  description_not_true?: Maybe<Scalars['String']>;
  description_false?: Maybe<Scalars['String']>;
  description_not_false?: Maybe<Scalars['String']>;
  description_present?: Maybe<Scalars['String']>;
  description_blank?: Maybe<Scalars['String']>;
  description_null?: Maybe<Scalars['String']>;
  description_not_null?: Maybe<Scalars['String']>;
  description_count?: Maybe<Scalars['String']>;
  description_count_distinct?: Maybe<Scalars['String']>;
  description_sum?: Maybe<Scalars['String']>;
  description_avg?: Maybe<Scalars['String']>;
  description_min?: Maybe<Scalars['String']>;
  description_max?: Maybe<Scalars['String']>;
  created_at_eq?: Maybe<Scalars['String']>;
  created_at_eq_any?: Maybe<Scalars['String']>;
  created_at_eq_all?: Maybe<Scalars['String']>;
  created_at_not_eq?: Maybe<Scalars['String']>;
  created_at_not_eq_any?: Maybe<Scalars['String']>;
  created_at_not_eq_all?: Maybe<Scalars['String']>;
  created_at_matches?: Maybe<Scalars['String']>;
  created_at_matches_any?: Maybe<Scalars['String']>;
  created_at_matches_all?: Maybe<Scalars['String']>;
  created_at_does_not_match?: Maybe<Scalars['String']>;
  created_at_does_not_match_any?: Maybe<Scalars['String']>;
  created_at_does_not_match_all?: Maybe<Scalars['String']>;
  created_at_lt?: Maybe<Scalars['String']>;
  created_at_lt_any?: Maybe<Scalars['String']>;
  created_at_lt_all?: Maybe<Scalars['String']>;
  created_at_lteq?: Maybe<Scalars['String']>;
  created_at_lteq_any?: Maybe<Scalars['String']>;
  created_at_lteq_all?: Maybe<Scalars['String']>;
  created_at_gt?: Maybe<Scalars['String']>;
  created_at_gt_any?: Maybe<Scalars['String']>;
  created_at_gt_all?: Maybe<Scalars['String']>;
  created_at_gteq?: Maybe<Scalars['String']>;
  created_at_gteq_any?: Maybe<Scalars['String']>;
  created_at_gteq_all?: Maybe<Scalars['String']>;
  created_at_in?: Maybe<Scalars['String']>;
  created_at_in_any?: Maybe<Scalars['String']>;
  created_at_in_all?: Maybe<Scalars['String']>;
  created_at_not_in?: Maybe<Scalars['String']>;
  created_at_not_in_any?: Maybe<Scalars['String']>;
  created_at_not_in_all?: Maybe<Scalars['String']>;
  created_at_cont?: Maybe<Scalars['String']>;
  created_at_cont_any?: Maybe<Scalars['String']>;
  created_at_cont_all?: Maybe<Scalars['String']>;
  created_at_not_cont?: Maybe<Scalars['String']>;
  created_at_not_cont_any?: Maybe<Scalars['String']>;
  created_at_not_cont_all?: Maybe<Scalars['String']>;
  created_at_i_cont?: Maybe<Scalars['String']>;
  created_at_i_cont_any?: Maybe<Scalars['String']>;
  created_at_i_cont_all?: Maybe<Scalars['String']>;
  created_at_not_i_cont?: Maybe<Scalars['String']>;
  created_at_not_i_cont_any?: Maybe<Scalars['String']>;
  created_at_not_i_cont_all?: Maybe<Scalars['String']>;
  created_at_start?: Maybe<Scalars['String']>;
  created_at_start_any?: Maybe<Scalars['String']>;
  created_at_start_all?: Maybe<Scalars['String']>;
  created_at_not_start?: Maybe<Scalars['String']>;
  created_at_not_start_any?: Maybe<Scalars['String']>;
  created_at_not_start_all?: Maybe<Scalars['String']>;
  created_at_end?: Maybe<Scalars['String']>;
  created_at_end_any?: Maybe<Scalars['String']>;
  created_at_end_all?: Maybe<Scalars['String']>;
  created_at_not_end?: Maybe<Scalars['String']>;
  created_at_not_end_any?: Maybe<Scalars['String']>;
  created_at_not_end_all?: Maybe<Scalars['String']>;
  created_at_true?: Maybe<Scalars['String']>;
  created_at_not_true?: Maybe<Scalars['String']>;
  created_at_false?: Maybe<Scalars['String']>;
  created_at_not_false?: Maybe<Scalars['String']>;
  created_at_present?: Maybe<Scalars['String']>;
  created_at_blank?: Maybe<Scalars['String']>;
  created_at_null?: Maybe<Scalars['String']>;
  created_at_not_null?: Maybe<Scalars['String']>;
  created_at_count?: Maybe<Scalars['String']>;
  created_at_count_distinct?: Maybe<Scalars['String']>;
  created_at_sum?: Maybe<Scalars['String']>;
  created_at_avg?: Maybe<Scalars['String']>;
  created_at_min?: Maybe<Scalars['String']>;
  created_at_max?: Maybe<Scalars['String']>;
  updated_at_eq?: Maybe<Scalars['String']>;
  updated_at_eq_any?: Maybe<Scalars['String']>;
  updated_at_eq_all?: Maybe<Scalars['String']>;
  updated_at_not_eq?: Maybe<Scalars['String']>;
  updated_at_not_eq_any?: Maybe<Scalars['String']>;
  updated_at_not_eq_all?: Maybe<Scalars['String']>;
  updated_at_matches?: Maybe<Scalars['String']>;
  updated_at_matches_any?: Maybe<Scalars['String']>;
  updated_at_matches_all?: Maybe<Scalars['String']>;
  updated_at_does_not_match?: Maybe<Scalars['String']>;
  updated_at_does_not_match_any?: Maybe<Scalars['String']>;
  updated_at_does_not_match_all?: Maybe<Scalars['String']>;
  updated_at_lt?: Maybe<Scalars['String']>;
  updated_at_lt_any?: Maybe<Scalars['String']>;
  updated_at_lt_all?: Maybe<Scalars['String']>;
  updated_at_lteq?: Maybe<Scalars['String']>;
  updated_at_lteq_any?: Maybe<Scalars['String']>;
  updated_at_lteq_all?: Maybe<Scalars['String']>;
  updated_at_gt?: Maybe<Scalars['String']>;
  updated_at_gt_any?: Maybe<Scalars['String']>;
  updated_at_gt_all?: Maybe<Scalars['String']>;
  updated_at_gteq?: Maybe<Scalars['String']>;
  updated_at_gteq_any?: Maybe<Scalars['String']>;
  updated_at_gteq_all?: Maybe<Scalars['String']>;
  updated_at_in?: Maybe<Scalars['String']>;
  updated_at_in_any?: Maybe<Scalars['String']>;
  updated_at_in_all?: Maybe<Scalars['String']>;
  updated_at_not_in?: Maybe<Scalars['String']>;
  updated_at_not_in_any?: Maybe<Scalars['String']>;
  updated_at_not_in_all?: Maybe<Scalars['String']>;
  updated_at_cont?: Maybe<Scalars['String']>;
  updated_at_cont_any?: Maybe<Scalars['String']>;
  updated_at_cont_all?: Maybe<Scalars['String']>;
  updated_at_not_cont?: Maybe<Scalars['String']>;
  updated_at_not_cont_any?: Maybe<Scalars['String']>;
  updated_at_not_cont_all?: Maybe<Scalars['String']>;
  updated_at_i_cont?: Maybe<Scalars['String']>;
  updated_at_i_cont_any?: Maybe<Scalars['String']>;
  updated_at_i_cont_all?: Maybe<Scalars['String']>;
  updated_at_not_i_cont?: Maybe<Scalars['String']>;
  updated_at_not_i_cont_any?: Maybe<Scalars['String']>;
  updated_at_not_i_cont_all?: Maybe<Scalars['String']>;
  updated_at_start?: Maybe<Scalars['String']>;
  updated_at_start_any?: Maybe<Scalars['String']>;
  updated_at_start_all?: Maybe<Scalars['String']>;
  updated_at_not_start?: Maybe<Scalars['String']>;
  updated_at_not_start_any?: Maybe<Scalars['String']>;
  updated_at_not_start_all?: Maybe<Scalars['String']>;
  updated_at_end?: Maybe<Scalars['String']>;
  updated_at_end_any?: Maybe<Scalars['String']>;
  updated_at_end_all?: Maybe<Scalars['String']>;
  updated_at_not_end?: Maybe<Scalars['String']>;
  updated_at_not_end_any?: Maybe<Scalars['String']>;
  updated_at_not_end_all?: Maybe<Scalars['String']>;
  updated_at_true?: Maybe<Scalars['String']>;
  updated_at_not_true?: Maybe<Scalars['String']>;
  updated_at_false?: Maybe<Scalars['String']>;
  updated_at_not_false?: Maybe<Scalars['String']>;
  updated_at_present?: Maybe<Scalars['String']>;
  updated_at_blank?: Maybe<Scalars['String']>;
  updated_at_null?: Maybe<Scalars['String']>;
  updated_at_not_null?: Maybe<Scalars['String']>;
  updated_at_count?: Maybe<Scalars['String']>;
  updated_at_count_distinct?: Maybe<Scalars['String']>;
  updated_at_sum?: Maybe<Scalars['String']>;
  updated_at_avg?: Maybe<Scalars['String']>;
  updated_at_min?: Maybe<Scalars['String']>;
  updated_at_max?: Maybe<Scalars['String']>;
  discarded_at_eq?: Maybe<Scalars['String']>;
  discarded_at_eq_any?: Maybe<Scalars['String']>;
  discarded_at_eq_all?: Maybe<Scalars['String']>;
  discarded_at_not_eq?: Maybe<Scalars['String']>;
  discarded_at_not_eq_any?: Maybe<Scalars['String']>;
  discarded_at_not_eq_all?: Maybe<Scalars['String']>;
  discarded_at_matches?: Maybe<Scalars['String']>;
  discarded_at_matches_any?: Maybe<Scalars['String']>;
  discarded_at_matches_all?: Maybe<Scalars['String']>;
  discarded_at_does_not_match?: Maybe<Scalars['String']>;
  discarded_at_does_not_match_any?: Maybe<Scalars['String']>;
  discarded_at_does_not_match_all?: Maybe<Scalars['String']>;
  discarded_at_lt?: Maybe<Scalars['String']>;
  discarded_at_lt_any?: Maybe<Scalars['String']>;
  discarded_at_lt_all?: Maybe<Scalars['String']>;
  discarded_at_lteq?: Maybe<Scalars['String']>;
  discarded_at_lteq_any?: Maybe<Scalars['String']>;
  discarded_at_lteq_all?: Maybe<Scalars['String']>;
  discarded_at_gt?: Maybe<Scalars['String']>;
  discarded_at_gt_any?: Maybe<Scalars['String']>;
  discarded_at_gt_all?: Maybe<Scalars['String']>;
  discarded_at_gteq?: Maybe<Scalars['String']>;
  discarded_at_gteq_any?: Maybe<Scalars['String']>;
  discarded_at_gteq_all?: Maybe<Scalars['String']>;
  discarded_at_in?: Maybe<Scalars['String']>;
  discarded_at_in_any?: Maybe<Scalars['String']>;
  discarded_at_in_all?: Maybe<Scalars['String']>;
  discarded_at_not_in?: Maybe<Scalars['String']>;
  discarded_at_not_in_any?: Maybe<Scalars['String']>;
  discarded_at_not_in_all?: Maybe<Scalars['String']>;
  discarded_at_cont?: Maybe<Scalars['String']>;
  discarded_at_cont_any?: Maybe<Scalars['String']>;
  discarded_at_cont_all?: Maybe<Scalars['String']>;
  discarded_at_not_cont?: Maybe<Scalars['String']>;
  discarded_at_not_cont_any?: Maybe<Scalars['String']>;
  discarded_at_not_cont_all?: Maybe<Scalars['String']>;
  discarded_at_i_cont?: Maybe<Scalars['String']>;
  discarded_at_i_cont_any?: Maybe<Scalars['String']>;
  discarded_at_i_cont_all?: Maybe<Scalars['String']>;
  discarded_at_not_i_cont?: Maybe<Scalars['String']>;
  discarded_at_not_i_cont_any?: Maybe<Scalars['String']>;
  discarded_at_not_i_cont_all?: Maybe<Scalars['String']>;
  discarded_at_start?: Maybe<Scalars['String']>;
  discarded_at_start_any?: Maybe<Scalars['String']>;
  discarded_at_start_all?: Maybe<Scalars['String']>;
  discarded_at_not_start?: Maybe<Scalars['String']>;
  discarded_at_not_start_any?: Maybe<Scalars['String']>;
  discarded_at_not_start_all?: Maybe<Scalars['String']>;
  discarded_at_end?: Maybe<Scalars['String']>;
  discarded_at_end_any?: Maybe<Scalars['String']>;
  discarded_at_end_all?: Maybe<Scalars['String']>;
  discarded_at_not_end?: Maybe<Scalars['String']>;
  discarded_at_not_end_any?: Maybe<Scalars['String']>;
  discarded_at_not_end_all?: Maybe<Scalars['String']>;
  discarded_at_true?: Maybe<Scalars['String']>;
  discarded_at_not_true?: Maybe<Scalars['String']>;
  discarded_at_false?: Maybe<Scalars['String']>;
  discarded_at_not_false?: Maybe<Scalars['String']>;
  discarded_at_present?: Maybe<Scalars['String']>;
  discarded_at_blank?: Maybe<Scalars['String']>;
  discarded_at_null?: Maybe<Scalars['String']>;
  discarded_at_not_null?: Maybe<Scalars['String']>;
  discarded_at_count?: Maybe<Scalars['String']>;
  discarded_at_count_distinct?: Maybe<Scalars['String']>;
  discarded_at_sum?: Maybe<Scalars['String']>;
  discarded_at_avg?: Maybe<Scalars['String']>;
  discarded_at_min?: Maybe<Scalars['String']>;
  discarded_at_max?: Maybe<Scalars['String']>;
  color_eq?: Maybe<Scalars['String']>;
  color_eq_any?: Maybe<Scalars['String']>;
  color_eq_all?: Maybe<Scalars['String']>;
  color_not_eq?: Maybe<Scalars['String']>;
  color_not_eq_any?: Maybe<Scalars['String']>;
  color_not_eq_all?: Maybe<Scalars['String']>;
  color_matches?: Maybe<Scalars['String']>;
  color_matches_any?: Maybe<Scalars['String']>;
  color_matches_all?: Maybe<Scalars['String']>;
  color_does_not_match?: Maybe<Scalars['String']>;
  color_does_not_match_any?: Maybe<Scalars['String']>;
  color_does_not_match_all?: Maybe<Scalars['String']>;
  color_lt?: Maybe<Scalars['String']>;
  color_lt_any?: Maybe<Scalars['String']>;
  color_lt_all?: Maybe<Scalars['String']>;
  color_lteq?: Maybe<Scalars['String']>;
  color_lteq_any?: Maybe<Scalars['String']>;
  color_lteq_all?: Maybe<Scalars['String']>;
  color_gt?: Maybe<Scalars['String']>;
  color_gt_any?: Maybe<Scalars['String']>;
  color_gt_all?: Maybe<Scalars['String']>;
  color_gteq?: Maybe<Scalars['String']>;
  color_gteq_any?: Maybe<Scalars['String']>;
  color_gteq_all?: Maybe<Scalars['String']>;
  color_in?: Maybe<Scalars['String']>;
  color_in_any?: Maybe<Scalars['String']>;
  color_in_all?: Maybe<Scalars['String']>;
  color_not_in?: Maybe<Scalars['String']>;
  color_not_in_any?: Maybe<Scalars['String']>;
  color_not_in_all?: Maybe<Scalars['String']>;
  color_cont?: Maybe<Scalars['String']>;
  color_cont_any?: Maybe<Scalars['String']>;
  color_cont_all?: Maybe<Scalars['String']>;
  color_not_cont?: Maybe<Scalars['String']>;
  color_not_cont_any?: Maybe<Scalars['String']>;
  color_not_cont_all?: Maybe<Scalars['String']>;
  color_i_cont?: Maybe<Scalars['String']>;
  color_i_cont_any?: Maybe<Scalars['String']>;
  color_i_cont_all?: Maybe<Scalars['String']>;
  color_not_i_cont?: Maybe<Scalars['String']>;
  color_not_i_cont_any?: Maybe<Scalars['String']>;
  color_not_i_cont_all?: Maybe<Scalars['String']>;
  color_start?: Maybe<Scalars['String']>;
  color_start_any?: Maybe<Scalars['String']>;
  color_start_all?: Maybe<Scalars['String']>;
  color_not_start?: Maybe<Scalars['String']>;
  color_not_start_any?: Maybe<Scalars['String']>;
  color_not_start_all?: Maybe<Scalars['String']>;
  color_end?: Maybe<Scalars['String']>;
  color_end_any?: Maybe<Scalars['String']>;
  color_end_all?: Maybe<Scalars['String']>;
  color_not_end?: Maybe<Scalars['String']>;
  color_not_end_any?: Maybe<Scalars['String']>;
  color_not_end_all?: Maybe<Scalars['String']>;
  color_true?: Maybe<Scalars['String']>;
  color_not_true?: Maybe<Scalars['String']>;
  color_false?: Maybe<Scalars['String']>;
  color_not_false?: Maybe<Scalars['String']>;
  color_present?: Maybe<Scalars['String']>;
  color_blank?: Maybe<Scalars['String']>;
  color_null?: Maybe<Scalars['String']>;
  color_not_null?: Maybe<Scalars['String']>;
  color_count?: Maybe<Scalars['String']>;
  color_count_distinct?: Maybe<Scalars['String']>;
  color_sum?: Maybe<Scalars['String']>;
  color_avg?: Maybe<Scalars['String']>;
  color_min?: Maybe<Scalars['String']>;
  color_max?: Maybe<Scalars['String']>;
  private_eq?: Maybe<Scalars['String']>;
  private_eq_any?: Maybe<Scalars['String']>;
  private_eq_all?: Maybe<Scalars['String']>;
  private_not_eq?: Maybe<Scalars['String']>;
  private_not_eq_any?: Maybe<Scalars['String']>;
  private_not_eq_all?: Maybe<Scalars['String']>;
  private_matches?: Maybe<Scalars['String']>;
  private_matches_any?: Maybe<Scalars['String']>;
  private_matches_all?: Maybe<Scalars['String']>;
  private_does_not_match?: Maybe<Scalars['String']>;
  private_does_not_match_any?: Maybe<Scalars['String']>;
  private_does_not_match_all?: Maybe<Scalars['String']>;
  private_lt?: Maybe<Scalars['String']>;
  private_lt_any?: Maybe<Scalars['String']>;
  private_lt_all?: Maybe<Scalars['String']>;
  private_lteq?: Maybe<Scalars['String']>;
  private_lteq_any?: Maybe<Scalars['String']>;
  private_lteq_all?: Maybe<Scalars['String']>;
  private_gt?: Maybe<Scalars['String']>;
  private_gt_any?: Maybe<Scalars['String']>;
  private_gt_all?: Maybe<Scalars['String']>;
  private_gteq?: Maybe<Scalars['String']>;
  private_gteq_any?: Maybe<Scalars['String']>;
  private_gteq_all?: Maybe<Scalars['String']>;
  private_in?: Maybe<Scalars['String']>;
  private_in_any?: Maybe<Scalars['String']>;
  private_in_all?: Maybe<Scalars['String']>;
  private_not_in?: Maybe<Scalars['String']>;
  private_not_in_any?: Maybe<Scalars['String']>;
  private_not_in_all?: Maybe<Scalars['String']>;
  private_cont?: Maybe<Scalars['String']>;
  private_cont_any?: Maybe<Scalars['String']>;
  private_cont_all?: Maybe<Scalars['String']>;
  private_not_cont?: Maybe<Scalars['String']>;
  private_not_cont_any?: Maybe<Scalars['String']>;
  private_not_cont_all?: Maybe<Scalars['String']>;
  private_i_cont?: Maybe<Scalars['String']>;
  private_i_cont_any?: Maybe<Scalars['String']>;
  private_i_cont_all?: Maybe<Scalars['String']>;
  private_not_i_cont?: Maybe<Scalars['String']>;
  private_not_i_cont_any?: Maybe<Scalars['String']>;
  private_not_i_cont_all?: Maybe<Scalars['String']>;
  private_start?: Maybe<Scalars['String']>;
  private_start_any?: Maybe<Scalars['String']>;
  private_start_all?: Maybe<Scalars['String']>;
  private_not_start?: Maybe<Scalars['String']>;
  private_not_start_any?: Maybe<Scalars['String']>;
  private_not_start_all?: Maybe<Scalars['String']>;
  private_end?: Maybe<Scalars['String']>;
  private_end_any?: Maybe<Scalars['String']>;
  private_end_all?: Maybe<Scalars['String']>;
  private_not_end?: Maybe<Scalars['String']>;
  private_not_end_any?: Maybe<Scalars['String']>;
  private_not_end_all?: Maybe<Scalars['String']>;
  private_true?: Maybe<Scalars['String']>;
  private_not_true?: Maybe<Scalars['String']>;
  private_false?: Maybe<Scalars['String']>;
  private_not_false?: Maybe<Scalars['String']>;
  private_present?: Maybe<Scalars['String']>;
  private_blank?: Maybe<Scalars['String']>;
  private_null?: Maybe<Scalars['String']>;
  private_not_null?: Maybe<Scalars['String']>;
  private_count?: Maybe<Scalars['String']>;
  private_count_distinct?: Maybe<Scalars['String']>;
  private_sum?: Maybe<Scalars['String']>;
  private_avg?: Maybe<Scalars['String']>;
  private_min?: Maybe<Scalars['String']>;
  private_max?: Maybe<Scalars['String']>;
  library_eq?: Maybe<Scalars['String']>;
  library_eq_any?: Maybe<Scalars['String']>;
  library_eq_all?: Maybe<Scalars['String']>;
  library_not_eq?: Maybe<Scalars['String']>;
  library_not_eq_any?: Maybe<Scalars['String']>;
  library_not_eq_all?: Maybe<Scalars['String']>;
  library_matches?: Maybe<Scalars['String']>;
  library_matches_any?: Maybe<Scalars['String']>;
  library_matches_all?: Maybe<Scalars['String']>;
  library_does_not_match?: Maybe<Scalars['String']>;
  library_does_not_match_any?: Maybe<Scalars['String']>;
  library_does_not_match_all?: Maybe<Scalars['String']>;
  library_lt?: Maybe<Scalars['String']>;
  library_lt_any?: Maybe<Scalars['String']>;
  library_lt_all?: Maybe<Scalars['String']>;
  library_lteq?: Maybe<Scalars['String']>;
  library_lteq_any?: Maybe<Scalars['String']>;
  library_lteq_all?: Maybe<Scalars['String']>;
  library_gt?: Maybe<Scalars['String']>;
  library_gt_any?: Maybe<Scalars['String']>;
  library_gt_all?: Maybe<Scalars['String']>;
  library_gteq?: Maybe<Scalars['String']>;
  library_gteq_any?: Maybe<Scalars['String']>;
  library_gteq_all?: Maybe<Scalars['String']>;
  library_in?: Maybe<Scalars['String']>;
  library_in_any?: Maybe<Scalars['String']>;
  library_in_all?: Maybe<Scalars['String']>;
  library_not_in?: Maybe<Scalars['String']>;
  library_not_in_any?: Maybe<Scalars['String']>;
  library_not_in_all?: Maybe<Scalars['String']>;
  library_cont?: Maybe<Scalars['String']>;
  library_cont_any?: Maybe<Scalars['String']>;
  library_cont_all?: Maybe<Scalars['String']>;
  library_not_cont?: Maybe<Scalars['String']>;
  library_not_cont_any?: Maybe<Scalars['String']>;
  library_not_cont_all?: Maybe<Scalars['String']>;
  library_i_cont?: Maybe<Scalars['String']>;
  library_i_cont_any?: Maybe<Scalars['String']>;
  library_i_cont_all?: Maybe<Scalars['String']>;
  library_not_i_cont?: Maybe<Scalars['String']>;
  library_not_i_cont_any?: Maybe<Scalars['String']>;
  library_not_i_cont_all?: Maybe<Scalars['String']>;
  library_start?: Maybe<Scalars['String']>;
  library_start_any?: Maybe<Scalars['String']>;
  library_start_all?: Maybe<Scalars['String']>;
  library_not_start?: Maybe<Scalars['String']>;
  library_not_start_any?: Maybe<Scalars['String']>;
  library_not_start_all?: Maybe<Scalars['String']>;
  library_end?: Maybe<Scalars['String']>;
  library_end_any?: Maybe<Scalars['String']>;
  library_end_all?: Maybe<Scalars['String']>;
  library_not_end?: Maybe<Scalars['String']>;
  library_not_end_any?: Maybe<Scalars['String']>;
  library_not_end_all?: Maybe<Scalars['String']>;
  library_true?: Maybe<Scalars['String']>;
  library_not_true?: Maybe<Scalars['String']>;
  library_false?: Maybe<Scalars['String']>;
  library_not_false?: Maybe<Scalars['String']>;
  library_present?: Maybe<Scalars['String']>;
  library_blank?: Maybe<Scalars['String']>;
  library_null?: Maybe<Scalars['String']>;
  library_not_null?: Maybe<Scalars['String']>;
  library_count?: Maybe<Scalars['String']>;
  library_count_distinct?: Maybe<Scalars['String']>;
  library_sum?: Maybe<Scalars['String']>;
  library_avg?: Maybe<Scalars['String']>;
  library_min?: Maybe<Scalars['String']>;
  library_max?: Maybe<Scalars['String']>;
  category_id_eq?: Maybe<Scalars['String']>;
  category_id_eq_any?: Maybe<Scalars['String']>;
  category_id_eq_all?: Maybe<Scalars['String']>;
  category_id_not_eq?: Maybe<Scalars['String']>;
  category_id_not_eq_any?: Maybe<Scalars['String']>;
  category_id_not_eq_all?: Maybe<Scalars['String']>;
  category_id_matches?: Maybe<Scalars['String']>;
  category_id_matches_any?: Maybe<Scalars['String']>;
  category_id_matches_all?: Maybe<Scalars['String']>;
  category_id_does_not_match?: Maybe<Scalars['String']>;
  category_id_does_not_match_any?: Maybe<Scalars['String']>;
  category_id_does_not_match_all?: Maybe<Scalars['String']>;
  category_id_lt?: Maybe<Scalars['String']>;
  category_id_lt_any?: Maybe<Scalars['String']>;
  category_id_lt_all?: Maybe<Scalars['String']>;
  category_id_lteq?: Maybe<Scalars['String']>;
  category_id_lteq_any?: Maybe<Scalars['String']>;
  category_id_lteq_all?: Maybe<Scalars['String']>;
  category_id_gt?: Maybe<Scalars['String']>;
  category_id_gt_any?: Maybe<Scalars['String']>;
  category_id_gt_all?: Maybe<Scalars['String']>;
  category_id_gteq?: Maybe<Scalars['String']>;
  category_id_gteq_any?: Maybe<Scalars['String']>;
  category_id_gteq_all?: Maybe<Scalars['String']>;
  category_id_in?: Maybe<Scalars['String']>;
  category_id_in_any?: Maybe<Scalars['String']>;
  category_id_in_all?: Maybe<Scalars['String']>;
  category_id_not_in?: Maybe<Scalars['String']>;
  category_id_not_in_any?: Maybe<Scalars['String']>;
  category_id_not_in_all?: Maybe<Scalars['String']>;
  category_id_cont?: Maybe<Scalars['String']>;
  category_id_cont_any?: Maybe<Scalars['String']>;
  category_id_cont_all?: Maybe<Scalars['String']>;
  category_id_not_cont?: Maybe<Scalars['String']>;
  category_id_not_cont_any?: Maybe<Scalars['String']>;
  category_id_not_cont_all?: Maybe<Scalars['String']>;
  category_id_i_cont?: Maybe<Scalars['String']>;
  category_id_i_cont_any?: Maybe<Scalars['String']>;
  category_id_i_cont_all?: Maybe<Scalars['String']>;
  category_id_not_i_cont?: Maybe<Scalars['String']>;
  category_id_not_i_cont_any?: Maybe<Scalars['String']>;
  category_id_not_i_cont_all?: Maybe<Scalars['String']>;
  category_id_start?: Maybe<Scalars['String']>;
  category_id_start_any?: Maybe<Scalars['String']>;
  category_id_start_all?: Maybe<Scalars['String']>;
  category_id_not_start?: Maybe<Scalars['String']>;
  category_id_not_start_any?: Maybe<Scalars['String']>;
  category_id_not_start_all?: Maybe<Scalars['String']>;
  category_id_end?: Maybe<Scalars['String']>;
  category_id_end_any?: Maybe<Scalars['String']>;
  category_id_end_all?: Maybe<Scalars['String']>;
  category_id_not_end?: Maybe<Scalars['String']>;
  category_id_not_end_any?: Maybe<Scalars['String']>;
  category_id_not_end_all?: Maybe<Scalars['String']>;
  category_id_true?: Maybe<Scalars['String']>;
  category_id_not_true?: Maybe<Scalars['String']>;
  category_id_false?: Maybe<Scalars['String']>;
  category_id_not_false?: Maybe<Scalars['String']>;
  category_id_present?: Maybe<Scalars['String']>;
  category_id_blank?: Maybe<Scalars['String']>;
  category_id_null?: Maybe<Scalars['String']>;
  category_id_not_null?: Maybe<Scalars['String']>;
  category_id_count?: Maybe<Scalars['String']>;
  category_id_count_distinct?: Maybe<Scalars['String']>;
  category_id_sum?: Maybe<Scalars['String']>;
  category_id_avg?: Maybe<Scalars['String']>;
  category_id_min?: Maybe<Scalars['String']>;
  category_id_max?: Maybe<Scalars['String']>;
  members_provide_feedback_eq?: Maybe<Scalars['String']>;
  members_provide_feedback_eq_any?: Maybe<Scalars['String']>;
  members_provide_feedback_eq_all?: Maybe<Scalars['String']>;
  members_provide_feedback_not_eq?: Maybe<Scalars['String']>;
  members_provide_feedback_not_eq_any?: Maybe<Scalars['String']>;
  members_provide_feedback_not_eq_all?: Maybe<Scalars['String']>;
  members_provide_feedback_matches?: Maybe<Scalars['String']>;
  members_provide_feedback_matches_any?: Maybe<Scalars['String']>;
  members_provide_feedback_matches_all?: Maybe<Scalars['String']>;
  members_provide_feedback_does_not_match?: Maybe<Scalars['String']>;
  members_provide_feedback_does_not_match_any?: Maybe<Scalars['String']>;
  members_provide_feedback_does_not_match_all?: Maybe<Scalars['String']>;
  members_provide_feedback_lt?: Maybe<Scalars['String']>;
  members_provide_feedback_lt_any?: Maybe<Scalars['String']>;
  members_provide_feedback_lt_all?: Maybe<Scalars['String']>;
  members_provide_feedback_lteq?: Maybe<Scalars['String']>;
  members_provide_feedback_lteq_any?: Maybe<Scalars['String']>;
  members_provide_feedback_lteq_all?: Maybe<Scalars['String']>;
  members_provide_feedback_gt?: Maybe<Scalars['String']>;
  members_provide_feedback_gt_any?: Maybe<Scalars['String']>;
  members_provide_feedback_gt_all?: Maybe<Scalars['String']>;
  members_provide_feedback_gteq?: Maybe<Scalars['String']>;
  members_provide_feedback_gteq_any?: Maybe<Scalars['String']>;
  members_provide_feedback_gteq_all?: Maybe<Scalars['String']>;
  members_provide_feedback_in?: Maybe<Scalars['String']>;
  members_provide_feedback_in_any?: Maybe<Scalars['String']>;
  members_provide_feedback_in_all?: Maybe<Scalars['String']>;
  members_provide_feedback_not_in?: Maybe<Scalars['String']>;
  members_provide_feedback_not_in_any?: Maybe<Scalars['String']>;
  members_provide_feedback_not_in_all?: Maybe<Scalars['String']>;
  members_provide_feedback_cont?: Maybe<Scalars['String']>;
  members_provide_feedback_cont_any?: Maybe<Scalars['String']>;
  members_provide_feedback_cont_all?: Maybe<Scalars['String']>;
  members_provide_feedback_not_cont?: Maybe<Scalars['String']>;
  members_provide_feedback_not_cont_any?: Maybe<Scalars['String']>;
  members_provide_feedback_not_cont_all?: Maybe<Scalars['String']>;
  members_provide_feedback_i_cont?: Maybe<Scalars['String']>;
  members_provide_feedback_i_cont_any?: Maybe<Scalars['String']>;
  members_provide_feedback_i_cont_all?: Maybe<Scalars['String']>;
  members_provide_feedback_not_i_cont?: Maybe<Scalars['String']>;
  members_provide_feedback_not_i_cont_any?: Maybe<Scalars['String']>;
  members_provide_feedback_not_i_cont_all?: Maybe<Scalars['String']>;
  members_provide_feedback_start?: Maybe<Scalars['String']>;
  members_provide_feedback_start_any?: Maybe<Scalars['String']>;
  members_provide_feedback_start_all?: Maybe<Scalars['String']>;
  members_provide_feedback_not_start?: Maybe<Scalars['String']>;
  members_provide_feedback_not_start_any?: Maybe<Scalars['String']>;
  members_provide_feedback_not_start_all?: Maybe<Scalars['String']>;
  members_provide_feedback_end?: Maybe<Scalars['String']>;
  members_provide_feedback_end_any?: Maybe<Scalars['String']>;
  members_provide_feedback_end_all?: Maybe<Scalars['String']>;
  members_provide_feedback_not_end?: Maybe<Scalars['String']>;
  members_provide_feedback_not_end_any?: Maybe<Scalars['String']>;
  members_provide_feedback_not_end_all?: Maybe<Scalars['String']>;
  members_provide_feedback_true?: Maybe<Scalars['String']>;
  members_provide_feedback_not_true?: Maybe<Scalars['String']>;
  members_provide_feedback_false?: Maybe<Scalars['String']>;
  members_provide_feedback_not_false?: Maybe<Scalars['String']>;
  members_provide_feedback_present?: Maybe<Scalars['String']>;
  members_provide_feedback_blank?: Maybe<Scalars['String']>;
  members_provide_feedback_null?: Maybe<Scalars['String']>;
  members_provide_feedback_not_null?: Maybe<Scalars['String']>;
  members_provide_feedback_count?: Maybe<Scalars['String']>;
  members_provide_feedback_count_distinct?: Maybe<Scalars['String']>;
  members_provide_feedback_sum?: Maybe<Scalars['String']>;
  members_provide_feedback_avg?: Maybe<Scalars['String']>;
  members_provide_feedback_min?: Maybe<Scalars['String']>;
  members_provide_feedback_max?: Maybe<Scalars['String']>;
  latest_response_eq?: Maybe<Scalars['String']>;
  latest_response_eq_any?: Maybe<Scalars['String']>;
  latest_response_eq_all?: Maybe<Scalars['String']>;
  latest_response_not_eq?: Maybe<Scalars['String']>;
  latest_response_not_eq_any?: Maybe<Scalars['String']>;
  latest_response_not_eq_all?: Maybe<Scalars['String']>;
  latest_response_matches?: Maybe<Scalars['String']>;
  latest_response_matches_any?: Maybe<Scalars['String']>;
  latest_response_matches_all?: Maybe<Scalars['String']>;
  latest_response_does_not_match?: Maybe<Scalars['String']>;
  latest_response_does_not_match_any?: Maybe<Scalars['String']>;
  latest_response_does_not_match_all?: Maybe<Scalars['String']>;
  latest_response_lt?: Maybe<Scalars['String']>;
  latest_response_lt_any?: Maybe<Scalars['String']>;
  latest_response_lt_all?: Maybe<Scalars['String']>;
  latest_response_lteq?: Maybe<Scalars['String']>;
  latest_response_lteq_any?: Maybe<Scalars['String']>;
  latest_response_lteq_all?: Maybe<Scalars['String']>;
  latest_response_gt?: Maybe<Scalars['String']>;
  latest_response_gt_any?: Maybe<Scalars['String']>;
  latest_response_gt_all?: Maybe<Scalars['String']>;
  latest_response_gteq?: Maybe<Scalars['String']>;
  latest_response_gteq_any?: Maybe<Scalars['String']>;
  latest_response_gteq_all?: Maybe<Scalars['String']>;
  latest_response_in?: Maybe<Scalars['String']>;
  latest_response_in_any?: Maybe<Scalars['String']>;
  latest_response_in_all?: Maybe<Scalars['String']>;
  latest_response_not_in?: Maybe<Scalars['String']>;
  latest_response_not_in_any?: Maybe<Scalars['String']>;
  latest_response_not_in_all?: Maybe<Scalars['String']>;
  latest_response_cont?: Maybe<Scalars['String']>;
  latest_response_cont_any?: Maybe<Scalars['String']>;
  latest_response_cont_all?: Maybe<Scalars['String']>;
  latest_response_not_cont?: Maybe<Scalars['String']>;
  latest_response_not_cont_any?: Maybe<Scalars['String']>;
  latest_response_not_cont_all?: Maybe<Scalars['String']>;
  latest_response_i_cont?: Maybe<Scalars['String']>;
  latest_response_i_cont_any?: Maybe<Scalars['String']>;
  latest_response_i_cont_all?: Maybe<Scalars['String']>;
  latest_response_not_i_cont?: Maybe<Scalars['String']>;
  latest_response_not_i_cont_any?: Maybe<Scalars['String']>;
  latest_response_not_i_cont_all?: Maybe<Scalars['String']>;
  latest_response_start?: Maybe<Scalars['String']>;
  latest_response_start_any?: Maybe<Scalars['String']>;
  latest_response_start_all?: Maybe<Scalars['String']>;
  latest_response_not_start?: Maybe<Scalars['String']>;
  latest_response_not_start_any?: Maybe<Scalars['String']>;
  latest_response_not_start_all?: Maybe<Scalars['String']>;
  latest_response_end?: Maybe<Scalars['String']>;
  latest_response_end_any?: Maybe<Scalars['String']>;
  latest_response_end_all?: Maybe<Scalars['String']>;
  latest_response_not_end?: Maybe<Scalars['String']>;
  latest_response_not_end_any?: Maybe<Scalars['String']>;
  latest_response_not_end_all?: Maybe<Scalars['String']>;
  latest_response_true?: Maybe<Scalars['String']>;
  latest_response_not_true?: Maybe<Scalars['String']>;
  latest_response_false?: Maybe<Scalars['String']>;
  latest_response_not_false?: Maybe<Scalars['String']>;
  latest_response_present?: Maybe<Scalars['String']>;
  latest_response_blank?: Maybe<Scalars['String']>;
  latest_response_null?: Maybe<Scalars['String']>;
  latest_response_not_null?: Maybe<Scalars['String']>;
  latest_response_count?: Maybe<Scalars['String']>;
  latest_response_count_distinct?: Maybe<Scalars['String']>;
  latest_response_sum?: Maybe<Scalars['String']>;
  latest_response_avg?: Maybe<Scalars['String']>;
  latest_response_min?: Maybe<Scalars['String']>;
  latest_response_max?: Maybe<Scalars['String']>;
  latest_response_date_eq?: Maybe<Scalars['String']>;
  latest_response_date_eq_any?: Maybe<Scalars['String']>;
  latest_response_date_eq_all?: Maybe<Scalars['String']>;
  latest_response_date_not_eq?: Maybe<Scalars['String']>;
  latest_response_date_not_eq_any?: Maybe<Scalars['String']>;
  latest_response_date_not_eq_all?: Maybe<Scalars['String']>;
  latest_response_date_matches?: Maybe<Scalars['String']>;
  latest_response_date_matches_any?: Maybe<Scalars['String']>;
  latest_response_date_matches_all?: Maybe<Scalars['String']>;
  latest_response_date_does_not_match?: Maybe<Scalars['String']>;
  latest_response_date_does_not_match_any?: Maybe<Scalars['String']>;
  latest_response_date_does_not_match_all?: Maybe<Scalars['String']>;
  latest_response_date_lt?: Maybe<Scalars['String']>;
  latest_response_date_lt_any?: Maybe<Scalars['String']>;
  latest_response_date_lt_all?: Maybe<Scalars['String']>;
  latest_response_date_lteq?: Maybe<Scalars['String']>;
  latest_response_date_lteq_any?: Maybe<Scalars['String']>;
  latest_response_date_lteq_all?: Maybe<Scalars['String']>;
  latest_response_date_gt?: Maybe<Scalars['String']>;
  latest_response_date_gt_any?: Maybe<Scalars['String']>;
  latest_response_date_gt_all?: Maybe<Scalars['String']>;
  latest_response_date_gteq?: Maybe<Scalars['String']>;
  latest_response_date_gteq_any?: Maybe<Scalars['String']>;
  latest_response_date_gteq_all?: Maybe<Scalars['String']>;
  latest_response_date_in?: Maybe<Scalars['String']>;
  latest_response_date_in_any?: Maybe<Scalars['String']>;
  latest_response_date_in_all?: Maybe<Scalars['String']>;
  latest_response_date_not_in?: Maybe<Scalars['String']>;
  latest_response_date_not_in_any?: Maybe<Scalars['String']>;
  latest_response_date_not_in_all?: Maybe<Scalars['String']>;
  latest_response_date_cont?: Maybe<Scalars['String']>;
  latest_response_date_cont_any?: Maybe<Scalars['String']>;
  latest_response_date_cont_all?: Maybe<Scalars['String']>;
  latest_response_date_not_cont?: Maybe<Scalars['String']>;
  latest_response_date_not_cont_any?: Maybe<Scalars['String']>;
  latest_response_date_not_cont_all?: Maybe<Scalars['String']>;
  latest_response_date_i_cont?: Maybe<Scalars['String']>;
  latest_response_date_i_cont_any?: Maybe<Scalars['String']>;
  latest_response_date_i_cont_all?: Maybe<Scalars['String']>;
  latest_response_date_not_i_cont?: Maybe<Scalars['String']>;
  latest_response_date_not_i_cont_any?: Maybe<Scalars['String']>;
  latest_response_date_not_i_cont_all?: Maybe<Scalars['String']>;
  latest_response_date_start?: Maybe<Scalars['String']>;
  latest_response_date_start_any?: Maybe<Scalars['String']>;
  latest_response_date_start_all?: Maybe<Scalars['String']>;
  latest_response_date_not_start?: Maybe<Scalars['String']>;
  latest_response_date_not_start_any?: Maybe<Scalars['String']>;
  latest_response_date_not_start_all?: Maybe<Scalars['String']>;
  latest_response_date_end?: Maybe<Scalars['String']>;
  latest_response_date_end_any?: Maybe<Scalars['String']>;
  latest_response_date_end_all?: Maybe<Scalars['String']>;
  latest_response_date_not_end?: Maybe<Scalars['String']>;
  latest_response_date_not_end_any?: Maybe<Scalars['String']>;
  latest_response_date_not_end_all?: Maybe<Scalars['String']>;
  latest_response_date_true?: Maybe<Scalars['String']>;
  latest_response_date_not_true?: Maybe<Scalars['String']>;
  latest_response_date_false?: Maybe<Scalars['String']>;
  latest_response_date_not_false?: Maybe<Scalars['String']>;
  latest_response_date_present?: Maybe<Scalars['String']>;
  latest_response_date_blank?: Maybe<Scalars['String']>;
  latest_response_date_null?: Maybe<Scalars['String']>;
  latest_response_date_not_null?: Maybe<Scalars['String']>;
  latest_response_date_count?: Maybe<Scalars['String']>;
  latest_response_date_count_distinct?: Maybe<Scalars['String']>;
  latest_response_date_sum?: Maybe<Scalars['String']>;
  latest_response_date_avg?: Maybe<Scalars['String']>;
  latest_response_date_min?: Maybe<Scalars['String']>;
  latest_response_date_max?: Maybe<Scalars['String']>;
  auto_complete_eq?: Maybe<Scalars['String']>;
  auto_complete_eq_any?: Maybe<Scalars['String']>;
  auto_complete_eq_all?: Maybe<Scalars['String']>;
  auto_complete_not_eq?: Maybe<Scalars['String']>;
  auto_complete_not_eq_any?: Maybe<Scalars['String']>;
  auto_complete_not_eq_all?: Maybe<Scalars['String']>;
  auto_complete_matches?: Maybe<Scalars['String']>;
  auto_complete_matches_any?: Maybe<Scalars['String']>;
  auto_complete_matches_all?: Maybe<Scalars['String']>;
  auto_complete_does_not_match?: Maybe<Scalars['String']>;
  auto_complete_does_not_match_any?: Maybe<Scalars['String']>;
  auto_complete_does_not_match_all?: Maybe<Scalars['String']>;
  auto_complete_lt?: Maybe<Scalars['String']>;
  auto_complete_lt_any?: Maybe<Scalars['String']>;
  auto_complete_lt_all?: Maybe<Scalars['String']>;
  auto_complete_lteq?: Maybe<Scalars['String']>;
  auto_complete_lteq_any?: Maybe<Scalars['String']>;
  auto_complete_lteq_all?: Maybe<Scalars['String']>;
  auto_complete_gt?: Maybe<Scalars['String']>;
  auto_complete_gt_any?: Maybe<Scalars['String']>;
  auto_complete_gt_all?: Maybe<Scalars['String']>;
  auto_complete_gteq?: Maybe<Scalars['String']>;
  auto_complete_gteq_any?: Maybe<Scalars['String']>;
  auto_complete_gteq_all?: Maybe<Scalars['String']>;
  auto_complete_in?: Maybe<Scalars['String']>;
  auto_complete_in_any?: Maybe<Scalars['String']>;
  auto_complete_in_all?: Maybe<Scalars['String']>;
  auto_complete_not_in?: Maybe<Scalars['String']>;
  auto_complete_not_in_any?: Maybe<Scalars['String']>;
  auto_complete_not_in_all?: Maybe<Scalars['String']>;
  auto_complete_cont?: Maybe<Scalars['String']>;
  auto_complete_cont_any?: Maybe<Scalars['String']>;
  auto_complete_cont_all?: Maybe<Scalars['String']>;
  auto_complete_not_cont?: Maybe<Scalars['String']>;
  auto_complete_not_cont_any?: Maybe<Scalars['String']>;
  auto_complete_not_cont_all?: Maybe<Scalars['String']>;
  auto_complete_i_cont?: Maybe<Scalars['String']>;
  auto_complete_i_cont_any?: Maybe<Scalars['String']>;
  auto_complete_i_cont_all?: Maybe<Scalars['String']>;
  auto_complete_not_i_cont?: Maybe<Scalars['String']>;
  auto_complete_not_i_cont_any?: Maybe<Scalars['String']>;
  auto_complete_not_i_cont_all?: Maybe<Scalars['String']>;
  auto_complete_start?: Maybe<Scalars['String']>;
  auto_complete_start_any?: Maybe<Scalars['String']>;
  auto_complete_start_all?: Maybe<Scalars['String']>;
  auto_complete_not_start?: Maybe<Scalars['String']>;
  auto_complete_not_start_any?: Maybe<Scalars['String']>;
  auto_complete_not_start_all?: Maybe<Scalars['String']>;
  auto_complete_end?: Maybe<Scalars['String']>;
  auto_complete_end_any?: Maybe<Scalars['String']>;
  auto_complete_end_all?: Maybe<Scalars['String']>;
  auto_complete_not_end?: Maybe<Scalars['String']>;
  auto_complete_not_end_any?: Maybe<Scalars['String']>;
  auto_complete_not_end_all?: Maybe<Scalars['String']>;
  auto_complete_true?: Maybe<Scalars['String']>;
  auto_complete_not_true?: Maybe<Scalars['String']>;
  auto_complete_false?: Maybe<Scalars['String']>;
  auto_complete_not_false?: Maybe<Scalars['String']>;
  auto_complete_present?: Maybe<Scalars['String']>;
  auto_complete_blank?: Maybe<Scalars['String']>;
  auto_complete_null?: Maybe<Scalars['String']>;
  auto_complete_not_null?: Maybe<Scalars['String']>;
  auto_complete_count?: Maybe<Scalars['String']>;
  auto_complete_count_distinct?: Maybe<Scalars['String']>;
  auto_complete_sum?: Maybe<Scalars['String']>;
  auto_complete_avg?: Maybe<Scalars['String']>;
  auto_complete_min?: Maybe<Scalars['String']>;
  auto_complete_max?: Maybe<Scalars['String']>;
  one_on_one_id_eq?: Maybe<Scalars['String']>;
  one_on_one_id_eq_any?: Maybe<Scalars['String']>;
  one_on_one_id_eq_all?: Maybe<Scalars['String']>;
  one_on_one_id_not_eq?: Maybe<Scalars['String']>;
  one_on_one_id_not_eq_any?: Maybe<Scalars['String']>;
  one_on_one_id_not_eq_all?: Maybe<Scalars['String']>;
  one_on_one_id_matches?: Maybe<Scalars['String']>;
  one_on_one_id_matches_any?: Maybe<Scalars['String']>;
  one_on_one_id_matches_all?: Maybe<Scalars['String']>;
  one_on_one_id_does_not_match?: Maybe<Scalars['String']>;
  one_on_one_id_does_not_match_any?: Maybe<Scalars['String']>;
  one_on_one_id_does_not_match_all?: Maybe<Scalars['String']>;
  one_on_one_id_lt?: Maybe<Scalars['String']>;
  one_on_one_id_lt_any?: Maybe<Scalars['String']>;
  one_on_one_id_lt_all?: Maybe<Scalars['String']>;
  one_on_one_id_lteq?: Maybe<Scalars['String']>;
  one_on_one_id_lteq_any?: Maybe<Scalars['String']>;
  one_on_one_id_lteq_all?: Maybe<Scalars['String']>;
  one_on_one_id_gt?: Maybe<Scalars['String']>;
  one_on_one_id_gt_any?: Maybe<Scalars['String']>;
  one_on_one_id_gt_all?: Maybe<Scalars['String']>;
  one_on_one_id_gteq?: Maybe<Scalars['String']>;
  one_on_one_id_gteq_any?: Maybe<Scalars['String']>;
  one_on_one_id_gteq_all?: Maybe<Scalars['String']>;
  one_on_one_id_in?: Maybe<Scalars['String']>;
  one_on_one_id_in_any?: Maybe<Scalars['String']>;
  one_on_one_id_in_all?: Maybe<Scalars['String']>;
  one_on_one_id_not_in?: Maybe<Scalars['String']>;
  one_on_one_id_not_in_any?: Maybe<Scalars['String']>;
  one_on_one_id_not_in_all?: Maybe<Scalars['String']>;
  one_on_one_id_cont?: Maybe<Scalars['String']>;
  one_on_one_id_cont_any?: Maybe<Scalars['String']>;
  one_on_one_id_cont_all?: Maybe<Scalars['String']>;
  one_on_one_id_not_cont?: Maybe<Scalars['String']>;
  one_on_one_id_not_cont_any?: Maybe<Scalars['String']>;
  one_on_one_id_not_cont_all?: Maybe<Scalars['String']>;
  one_on_one_id_i_cont?: Maybe<Scalars['String']>;
  one_on_one_id_i_cont_any?: Maybe<Scalars['String']>;
  one_on_one_id_i_cont_all?: Maybe<Scalars['String']>;
  one_on_one_id_not_i_cont?: Maybe<Scalars['String']>;
  one_on_one_id_not_i_cont_any?: Maybe<Scalars['String']>;
  one_on_one_id_not_i_cont_all?: Maybe<Scalars['String']>;
  one_on_one_id_start?: Maybe<Scalars['String']>;
  one_on_one_id_start_any?: Maybe<Scalars['String']>;
  one_on_one_id_start_all?: Maybe<Scalars['String']>;
  one_on_one_id_not_start?: Maybe<Scalars['String']>;
  one_on_one_id_not_start_any?: Maybe<Scalars['String']>;
  one_on_one_id_not_start_all?: Maybe<Scalars['String']>;
  one_on_one_id_end?: Maybe<Scalars['String']>;
  one_on_one_id_end_any?: Maybe<Scalars['String']>;
  one_on_one_id_end_all?: Maybe<Scalars['String']>;
  one_on_one_id_not_end?: Maybe<Scalars['String']>;
  one_on_one_id_not_end_any?: Maybe<Scalars['String']>;
  one_on_one_id_not_end_all?: Maybe<Scalars['String']>;
  one_on_one_id_true?: Maybe<Scalars['String']>;
  one_on_one_id_not_true?: Maybe<Scalars['String']>;
  one_on_one_id_false?: Maybe<Scalars['String']>;
  one_on_one_id_not_false?: Maybe<Scalars['String']>;
  one_on_one_id_present?: Maybe<Scalars['String']>;
  one_on_one_id_blank?: Maybe<Scalars['String']>;
  one_on_one_id_null?: Maybe<Scalars['String']>;
  one_on_one_id_not_null?: Maybe<Scalars['String']>;
  one_on_one_id_count?: Maybe<Scalars['String']>;
  one_on_one_id_count_distinct?: Maybe<Scalars['String']>;
  one_on_one_id_sum?: Maybe<Scalars['String']>;
  one_on_one_id_avg?: Maybe<Scalars['String']>;
  one_on_one_id_min?: Maybe<Scalars['String']>;
  one_on_one_id_max?: Maybe<Scalars['String']>;
  has_generated_one_on_one_eq?: Maybe<Scalars['String']>;
  has_generated_one_on_one_eq_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_eq_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_eq?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_eq_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_eq_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_matches?: Maybe<Scalars['String']>;
  has_generated_one_on_one_matches_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_matches_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_does_not_match?: Maybe<Scalars['String']>;
  has_generated_one_on_one_does_not_match_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_does_not_match_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_lt?: Maybe<Scalars['String']>;
  has_generated_one_on_one_lt_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_lt_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_lteq?: Maybe<Scalars['String']>;
  has_generated_one_on_one_lteq_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_lteq_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_gt?: Maybe<Scalars['String']>;
  has_generated_one_on_one_gt_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_gt_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_gteq?: Maybe<Scalars['String']>;
  has_generated_one_on_one_gteq_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_gteq_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_in?: Maybe<Scalars['String']>;
  has_generated_one_on_one_in_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_in_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_in?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_in_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_in_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_cont?: Maybe<Scalars['String']>;
  has_generated_one_on_one_cont_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_cont_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_cont?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_cont_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_cont_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_i_cont?: Maybe<Scalars['String']>;
  has_generated_one_on_one_i_cont_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_i_cont_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_i_cont?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_i_cont_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_i_cont_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_start?: Maybe<Scalars['String']>;
  has_generated_one_on_one_start_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_start_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_start?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_start_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_start_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_end?: Maybe<Scalars['String']>;
  has_generated_one_on_one_end_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_end_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_end?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_end_any?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_end_all?: Maybe<Scalars['String']>;
  has_generated_one_on_one_true?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_true?: Maybe<Scalars['String']>;
  has_generated_one_on_one_false?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_false?: Maybe<Scalars['String']>;
  has_generated_one_on_one_present?: Maybe<Scalars['String']>;
  has_generated_one_on_one_blank?: Maybe<Scalars['String']>;
  has_generated_one_on_one_null?: Maybe<Scalars['String']>;
  has_generated_one_on_one_not_null?: Maybe<Scalars['String']>;
  has_generated_one_on_one_count?: Maybe<Scalars['String']>;
  has_generated_one_on_one_count_distinct?: Maybe<Scalars['String']>;
  has_generated_one_on_one_sum?: Maybe<Scalars['String']>;
  has_generated_one_on_one_avg?: Maybe<Scalars['String']>;
  has_generated_one_on_one_min?: Maybe<Scalars['String']>;
  has_generated_one_on_one_max?: Maybe<Scalars['String']>;
  stakeholder_id_eq?: Maybe<Scalars['String']>;
};

export enum IObjectiveStatus {
  /** objective is currently active */
  InProgress = 'IN_PROGRESS',
  /** objective is waiting to be accepted/rejected */
  Pending = 'PENDING',
  /** objective has been rejected */
  Rejected = 'REJECTED',
  /** objective has been abandoned */
  Abandoned = 'ABANDONED',
  /** objective has been completed */
  Completed = 'COMPLETED',
  /** objective is awaiting review */
  AwaitingReview = 'AWAITING_REVIEW'
}

export type IOneOnOne = {
  __typename?: 'OneOnOne';
  createdAt: Scalars['String'];
  creator: IUser;
  creatorId: Scalars['ID'];
  data?: Maybe<Scalars['JSON']>;
  endDate: Scalars['String'];
  generatedFromObjectiveId?: Maybe<Scalars['ID']>;
  highfives: Array<IHighfive>;
  id: Scalars['ID'];
  includeHabits: Scalars['Boolean'];
  includeObjectives: Scalars['Boolean'];
  label: Scalars['String'];
  manager: IUser;
  managerId: Scalars['ID'];
  networkManaged: Scalars['Boolean'];
  notes: Scalars['String'];
  objectives: Array<IObjective>;
  prepared: Scalars['Boolean'];
  published: Scalars['Boolean'];
  publishedAt?: Maybe<Scalars['String']>;
  scheduledOneOnOneId?: Maybe<Scalars['ID']>;
  startDate: Scalars['String'];
  talkingPoints: Array<ITalkingPoint>;
  updatedAt: Scalars['String'];
  user: IUser;
  userId: Scalars['ID'];
};

/** The connection type for OneOnOne. */
export type IOneOnOneConnection = {
  __typename?: 'OneOnOneConnection';
  /** A list of edges. */
  edges: Array<IOneOnOneEdge>;
  /** A list of nodes. */
  nodes: Array<IOneOnOne>;
  /** Information to aid in pagination */
  pageInfo: IExtendedPageInfo;
};

/** An edge in a connection. */
export type IOneOnOneEdge = {
  __typename?: 'OneOnOneEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: IOneOnOne;
};

export type IOneOnOneFilter = {
  id_eq?: Maybe<Scalars['String']>;
  id_eq_any?: Maybe<Scalars['String']>;
  id_eq_all?: Maybe<Scalars['String']>;
  id_not_eq?: Maybe<Scalars['String']>;
  id_not_eq_any?: Maybe<Scalars['String']>;
  id_not_eq_all?: Maybe<Scalars['String']>;
  id_matches?: Maybe<Scalars['String']>;
  id_matches_any?: Maybe<Scalars['String']>;
  id_matches_all?: Maybe<Scalars['String']>;
  id_does_not_match?: Maybe<Scalars['String']>;
  id_does_not_match_any?: Maybe<Scalars['String']>;
  id_does_not_match_all?: Maybe<Scalars['String']>;
  id_lt?: Maybe<Scalars['String']>;
  id_lt_any?: Maybe<Scalars['String']>;
  id_lt_all?: Maybe<Scalars['String']>;
  id_lteq?: Maybe<Scalars['String']>;
  id_lteq_any?: Maybe<Scalars['String']>;
  id_lteq_all?: Maybe<Scalars['String']>;
  id_gt?: Maybe<Scalars['String']>;
  id_gt_any?: Maybe<Scalars['String']>;
  id_gt_all?: Maybe<Scalars['String']>;
  id_gteq?: Maybe<Scalars['String']>;
  id_gteq_any?: Maybe<Scalars['String']>;
  id_gteq_all?: Maybe<Scalars['String']>;
  id_in?: Maybe<Scalars['String']>;
  id_in_any?: Maybe<Scalars['String']>;
  id_in_all?: Maybe<Scalars['String']>;
  id_not_in?: Maybe<Scalars['String']>;
  id_not_in_any?: Maybe<Scalars['String']>;
  id_not_in_all?: Maybe<Scalars['String']>;
  id_cont?: Maybe<Scalars['String']>;
  id_cont_any?: Maybe<Scalars['String']>;
  id_cont_all?: Maybe<Scalars['String']>;
  id_not_cont?: Maybe<Scalars['String']>;
  id_not_cont_any?: Maybe<Scalars['String']>;
  id_not_cont_all?: Maybe<Scalars['String']>;
  id_i_cont?: Maybe<Scalars['String']>;
  id_i_cont_any?: Maybe<Scalars['String']>;
  id_i_cont_all?: Maybe<Scalars['String']>;
  id_not_i_cont?: Maybe<Scalars['String']>;
  id_not_i_cont_any?: Maybe<Scalars['String']>;
  id_not_i_cont_all?: Maybe<Scalars['String']>;
  id_start?: Maybe<Scalars['String']>;
  id_start_any?: Maybe<Scalars['String']>;
  id_start_all?: Maybe<Scalars['String']>;
  id_not_start?: Maybe<Scalars['String']>;
  id_not_start_any?: Maybe<Scalars['String']>;
  id_not_start_all?: Maybe<Scalars['String']>;
  id_end?: Maybe<Scalars['String']>;
  id_end_any?: Maybe<Scalars['String']>;
  id_end_all?: Maybe<Scalars['String']>;
  id_not_end?: Maybe<Scalars['String']>;
  id_not_end_any?: Maybe<Scalars['String']>;
  id_not_end_all?: Maybe<Scalars['String']>;
  id_true?: Maybe<Scalars['String']>;
  id_not_true?: Maybe<Scalars['String']>;
  id_false?: Maybe<Scalars['String']>;
  id_not_false?: Maybe<Scalars['String']>;
  id_present?: Maybe<Scalars['String']>;
  id_blank?: Maybe<Scalars['String']>;
  id_null?: Maybe<Scalars['String']>;
  id_not_null?: Maybe<Scalars['String']>;
  id_count?: Maybe<Scalars['String']>;
  id_count_distinct?: Maybe<Scalars['String']>;
  id_sum?: Maybe<Scalars['String']>;
  id_avg?: Maybe<Scalars['String']>;
  id_min?: Maybe<Scalars['String']>;
  id_max?: Maybe<Scalars['String']>;
  user_id_eq?: Maybe<Scalars['String']>;
  user_id_eq_any?: Maybe<Scalars['String']>;
  user_id_eq_all?: Maybe<Scalars['String']>;
  user_id_not_eq?: Maybe<Scalars['String']>;
  user_id_not_eq_any?: Maybe<Scalars['String']>;
  user_id_not_eq_all?: Maybe<Scalars['String']>;
  user_id_matches?: Maybe<Scalars['String']>;
  user_id_matches_any?: Maybe<Scalars['String']>;
  user_id_matches_all?: Maybe<Scalars['String']>;
  user_id_does_not_match?: Maybe<Scalars['String']>;
  user_id_does_not_match_any?: Maybe<Scalars['String']>;
  user_id_does_not_match_all?: Maybe<Scalars['String']>;
  user_id_lt?: Maybe<Scalars['String']>;
  user_id_lt_any?: Maybe<Scalars['String']>;
  user_id_lt_all?: Maybe<Scalars['String']>;
  user_id_lteq?: Maybe<Scalars['String']>;
  user_id_lteq_any?: Maybe<Scalars['String']>;
  user_id_lteq_all?: Maybe<Scalars['String']>;
  user_id_gt?: Maybe<Scalars['String']>;
  user_id_gt_any?: Maybe<Scalars['String']>;
  user_id_gt_all?: Maybe<Scalars['String']>;
  user_id_gteq?: Maybe<Scalars['String']>;
  user_id_gteq_any?: Maybe<Scalars['String']>;
  user_id_gteq_all?: Maybe<Scalars['String']>;
  user_id_in?: Maybe<Scalars['String']>;
  user_id_in_any?: Maybe<Scalars['String']>;
  user_id_in_all?: Maybe<Scalars['String']>;
  user_id_not_in?: Maybe<Scalars['String']>;
  user_id_not_in_any?: Maybe<Scalars['String']>;
  user_id_not_in_all?: Maybe<Scalars['String']>;
  user_id_cont?: Maybe<Scalars['String']>;
  user_id_cont_any?: Maybe<Scalars['String']>;
  user_id_cont_all?: Maybe<Scalars['String']>;
  user_id_not_cont?: Maybe<Scalars['String']>;
  user_id_not_cont_any?: Maybe<Scalars['String']>;
  user_id_not_cont_all?: Maybe<Scalars['String']>;
  user_id_i_cont?: Maybe<Scalars['String']>;
  user_id_i_cont_any?: Maybe<Scalars['String']>;
  user_id_i_cont_all?: Maybe<Scalars['String']>;
  user_id_not_i_cont?: Maybe<Scalars['String']>;
  user_id_not_i_cont_any?: Maybe<Scalars['String']>;
  user_id_not_i_cont_all?: Maybe<Scalars['String']>;
  user_id_start?: Maybe<Scalars['String']>;
  user_id_start_any?: Maybe<Scalars['String']>;
  user_id_start_all?: Maybe<Scalars['String']>;
  user_id_not_start?: Maybe<Scalars['String']>;
  user_id_not_start_any?: Maybe<Scalars['String']>;
  user_id_not_start_all?: Maybe<Scalars['String']>;
  user_id_end?: Maybe<Scalars['String']>;
  user_id_end_any?: Maybe<Scalars['String']>;
  user_id_end_all?: Maybe<Scalars['String']>;
  user_id_not_end?: Maybe<Scalars['String']>;
  user_id_not_end_any?: Maybe<Scalars['String']>;
  user_id_not_end_all?: Maybe<Scalars['String']>;
  user_id_true?: Maybe<Scalars['String']>;
  user_id_not_true?: Maybe<Scalars['String']>;
  user_id_false?: Maybe<Scalars['String']>;
  user_id_not_false?: Maybe<Scalars['String']>;
  user_id_present?: Maybe<Scalars['String']>;
  user_id_blank?: Maybe<Scalars['String']>;
  user_id_null?: Maybe<Scalars['String']>;
  user_id_not_null?: Maybe<Scalars['String']>;
  user_id_count?: Maybe<Scalars['String']>;
  user_id_count_distinct?: Maybe<Scalars['String']>;
  user_id_sum?: Maybe<Scalars['String']>;
  user_id_avg?: Maybe<Scalars['String']>;
  user_id_min?: Maybe<Scalars['String']>;
  user_id_max?: Maybe<Scalars['String']>;
  manager_id_eq?: Maybe<Scalars['String']>;
  manager_id_eq_any?: Maybe<Scalars['String']>;
  manager_id_eq_all?: Maybe<Scalars['String']>;
  manager_id_not_eq?: Maybe<Scalars['String']>;
  manager_id_not_eq_any?: Maybe<Scalars['String']>;
  manager_id_not_eq_all?: Maybe<Scalars['String']>;
  manager_id_matches?: Maybe<Scalars['String']>;
  manager_id_matches_any?: Maybe<Scalars['String']>;
  manager_id_matches_all?: Maybe<Scalars['String']>;
  manager_id_does_not_match?: Maybe<Scalars['String']>;
  manager_id_does_not_match_any?: Maybe<Scalars['String']>;
  manager_id_does_not_match_all?: Maybe<Scalars['String']>;
  manager_id_lt?: Maybe<Scalars['String']>;
  manager_id_lt_any?: Maybe<Scalars['String']>;
  manager_id_lt_all?: Maybe<Scalars['String']>;
  manager_id_lteq?: Maybe<Scalars['String']>;
  manager_id_lteq_any?: Maybe<Scalars['String']>;
  manager_id_lteq_all?: Maybe<Scalars['String']>;
  manager_id_gt?: Maybe<Scalars['String']>;
  manager_id_gt_any?: Maybe<Scalars['String']>;
  manager_id_gt_all?: Maybe<Scalars['String']>;
  manager_id_gteq?: Maybe<Scalars['String']>;
  manager_id_gteq_any?: Maybe<Scalars['String']>;
  manager_id_gteq_all?: Maybe<Scalars['String']>;
  manager_id_in?: Maybe<Scalars['String']>;
  manager_id_in_any?: Maybe<Scalars['String']>;
  manager_id_in_all?: Maybe<Scalars['String']>;
  manager_id_not_in?: Maybe<Scalars['String']>;
  manager_id_not_in_any?: Maybe<Scalars['String']>;
  manager_id_not_in_all?: Maybe<Scalars['String']>;
  manager_id_cont?: Maybe<Scalars['String']>;
  manager_id_cont_any?: Maybe<Scalars['String']>;
  manager_id_cont_all?: Maybe<Scalars['String']>;
  manager_id_not_cont?: Maybe<Scalars['String']>;
  manager_id_not_cont_any?: Maybe<Scalars['String']>;
  manager_id_not_cont_all?: Maybe<Scalars['String']>;
  manager_id_i_cont?: Maybe<Scalars['String']>;
  manager_id_i_cont_any?: Maybe<Scalars['String']>;
  manager_id_i_cont_all?: Maybe<Scalars['String']>;
  manager_id_not_i_cont?: Maybe<Scalars['String']>;
  manager_id_not_i_cont_any?: Maybe<Scalars['String']>;
  manager_id_not_i_cont_all?: Maybe<Scalars['String']>;
  manager_id_start?: Maybe<Scalars['String']>;
  manager_id_start_any?: Maybe<Scalars['String']>;
  manager_id_start_all?: Maybe<Scalars['String']>;
  manager_id_not_start?: Maybe<Scalars['String']>;
  manager_id_not_start_any?: Maybe<Scalars['String']>;
  manager_id_not_start_all?: Maybe<Scalars['String']>;
  manager_id_end?: Maybe<Scalars['String']>;
  manager_id_end_any?: Maybe<Scalars['String']>;
  manager_id_end_all?: Maybe<Scalars['String']>;
  manager_id_not_end?: Maybe<Scalars['String']>;
  manager_id_not_end_any?: Maybe<Scalars['String']>;
  manager_id_not_end_all?: Maybe<Scalars['String']>;
  manager_id_true?: Maybe<Scalars['String']>;
  manager_id_not_true?: Maybe<Scalars['String']>;
  manager_id_false?: Maybe<Scalars['String']>;
  manager_id_not_false?: Maybe<Scalars['String']>;
  manager_id_present?: Maybe<Scalars['String']>;
  manager_id_blank?: Maybe<Scalars['String']>;
  manager_id_null?: Maybe<Scalars['String']>;
  manager_id_not_null?: Maybe<Scalars['String']>;
  manager_id_count?: Maybe<Scalars['String']>;
  manager_id_count_distinct?: Maybe<Scalars['String']>;
  manager_id_sum?: Maybe<Scalars['String']>;
  manager_id_avg?: Maybe<Scalars['String']>;
  manager_id_min?: Maybe<Scalars['String']>;
  manager_id_max?: Maybe<Scalars['String']>;
  label_eq?: Maybe<Scalars['String']>;
  label_eq_any?: Maybe<Scalars['String']>;
  label_eq_all?: Maybe<Scalars['String']>;
  label_not_eq?: Maybe<Scalars['String']>;
  label_not_eq_any?: Maybe<Scalars['String']>;
  label_not_eq_all?: Maybe<Scalars['String']>;
  label_matches?: Maybe<Scalars['String']>;
  label_matches_any?: Maybe<Scalars['String']>;
  label_matches_all?: Maybe<Scalars['String']>;
  label_does_not_match?: Maybe<Scalars['String']>;
  label_does_not_match_any?: Maybe<Scalars['String']>;
  label_does_not_match_all?: Maybe<Scalars['String']>;
  label_lt?: Maybe<Scalars['String']>;
  label_lt_any?: Maybe<Scalars['String']>;
  label_lt_all?: Maybe<Scalars['String']>;
  label_lteq?: Maybe<Scalars['String']>;
  label_lteq_any?: Maybe<Scalars['String']>;
  label_lteq_all?: Maybe<Scalars['String']>;
  label_gt?: Maybe<Scalars['String']>;
  label_gt_any?: Maybe<Scalars['String']>;
  label_gt_all?: Maybe<Scalars['String']>;
  label_gteq?: Maybe<Scalars['String']>;
  label_gteq_any?: Maybe<Scalars['String']>;
  label_gteq_all?: Maybe<Scalars['String']>;
  label_in?: Maybe<Scalars['String']>;
  label_in_any?: Maybe<Scalars['String']>;
  label_in_all?: Maybe<Scalars['String']>;
  label_not_in?: Maybe<Scalars['String']>;
  label_not_in_any?: Maybe<Scalars['String']>;
  label_not_in_all?: Maybe<Scalars['String']>;
  label_cont?: Maybe<Scalars['String']>;
  label_cont_any?: Maybe<Scalars['String']>;
  label_cont_all?: Maybe<Scalars['String']>;
  label_not_cont?: Maybe<Scalars['String']>;
  label_not_cont_any?: Maybe<Scalars['String']>;
  label_not_cont_all?: Maybe<Scalars['String']>;
  label_i_cont?: Maybe<Scalars['String']>;
  label_i_cont_any?: Maybe<Scalars['String']>;
  label_i_cont_all?: Maybe<Scalars['String']>;
  label_not_i_cont?: Maybe<Scalars['String']>;
  label_not_i_cont_any?: Maybe<Scalars['String']>;
  label_not_i_cont_all?: Maybe<Scalars['String']>;
  label_start?: Maybe<Scalars['String']>;
  label_start_any?: Maybe<Scalars['String']>;
  label_start_all?: Maybe<Scalars['String']>;
  label_not_start?: Maybe<Scalars['String']>;
  label_not_start_any?: Maybe<Scalars['String']>;
  label_not_start_all?: Maybe<Scalars['String']>;
  label_end?: Maybe<Scalars['String']>;
  label_end_any?: Maybe<Scalars['String']>;
  label_end_all?: Maybe<Scalars['String']>;
  label_not_end?: Maybe<Scalars['String']>;
  label_not_end_any?: Maybe<Scalars['String']>;
  label_not_end_all?: Maybe<Scalars['String']>;
  label_true?: Maybe<Scalars['String']>;
  label_not_true?: Maybe<Scalars['String']>;
  label_false?: Maybe<Scalars['String']>;
  label_not_false?: Maybe<Scalars['String']>;
  label_present?: Maybe<Scalars['String']>;
  label_blank?: Maybe<Scalars['String']>;
  label_null?: Maybe<Scalars['String']>;
  label_not_null?: Maybe<Scalars['String']>;
  label_count?: Maybe<Scalars['String']>;
  label_count_distinct?: Maybe<Scalars['String']>;
  label_sum?: Maybe<Scalars['String']>;
  label_avg?: Maybe<Scalars['String']>;
  label_min?: Maybe<Scalars['String']>;
  label_max?: Maybe<Scalars['String']>;
  include_habits_eq?: Maybe<Scalars['String']>;
  include_habits_eq_any?: Maybe<Scalars['String']>;
  include_habits_eq_all?: Maybe<Scalars['String']>;
  include_habits_not_eq?: Maybe<Scalars['String']>;
  include_habits_not_eq_any?: Maybe<Scalars['String']>;
  include_habits_not_eq_all?: Maybe<Scalars['String']>;
  include_habits_matches?: Maybe<Scalars['String']>;
  include_habits_matches_any?: Maybe<Scalars['String']>;
  include_habits_matches_all?: Maybe<Scalars['String']>;
  include_habits_does_not_match?: Maybe<Scalars['String']>;
  include_habits_does_not_match_any?: Maybe<Scalars['String']>;
  include_habits_does_not_match_all?: Maybe<Scalars['String']>;
  include_habits_lt?: Maybe<Scalars['String']>;
  include_habits_lt_any?: Maybe<Scalars['String']>;
  include_habits_lt_all?: Maybe<Scalars['String']>;
  include_habits_lteq?: Maybe<Scalars['String']>;
  include_habits_lteq_any?: Maybe<Scalars['String']>;
  include_habits_lteq_all?: Maybe<Scalars['String']>;
  include_habits_gt?: Maybe<Scalars['String']>;
  include_habits_gt_any?: Maybe<Scalars['String']>;
  include_habits_gt_all?: Maybe<Scalars['String']>;
  include_habits_gteq?: Maybe<Scalars['String']>;
  include_habits_gteq_any?: Maybe<Scalars['String']>;
  include_habits_gteq_all?: Maybe<Scalars['String']>;
  include_habits_in?: Maybe<Scalars['String']>;
  include_habits_in_any?: Maybe<Scalars['String']>;
  include_habits_in_all?: Maybe<Scalars['String']>;
  include_habits_not_in?: Maybe<Scalars['String']>;
  include_habits_not_in_any?: Maybe<Scalars['String']>;
  include_habits_not_in_all?: Maybe<Scalars['String']>;
  include_habits_cont?: Maybe<Scalars['String']>;
  include_habits_cont_any?: Maybe<Scalars['String']>;
  include_habits_cont_all?: Maybe<Scalars['String']>;
  include_habits_not_cont?: Maybe<Scalars['String']>;
  include_habits_not_cont_any?: Maybe<Scalars['String']>;
  include_habits_not_cont_all?: Maybe<Scalars['String']>;
  include_habits_i_cont?: Maybe<Scalars['String']>;
  include_habits_i_cont_any?: Maybe<Scalars['String']>;
  include_habits_i_cont_all?: Maybe<Scalars['String']>;
  include_habits_not_i_cont?: Maybe<Scalars['String']>;
  include_habits_not_i_cont_any?: Maybe<Scalars['String']>;
  include_habits_not_i_cont_all?: Maybe<Scalars['String']>;
  include_habits_start?: Maybe<Scalars['String']>;
  include_habits_start_any?: Maybe<Scalars['String']>;
  include_habits_start_all?: Maybe<Scalars['String']>;
  include_habits_not_start?: Maybe<Scalars['String']>;
  include_habits_not_start_any?: Maybe<Scalars['String']>;
  include_habits_not_start_all?: Maybe<Scalars['String']>;
  include_habits_end?: Maybe<Scalars['String']>;
  include_habits_end_any?: Maybe<Scalars['String']>;
  include_habits_end_all?: Maybe<Scalars['String']>;
  include_habits_not_end?: Maybe<Scalars['String']>;
  include_habits_not_end_any?: Maybe<Scalars['String']>;
  include_habits_not_end_all?: Maybe<Scalars['String']>;
  include_habits_true?: Maybe<Scalars['String']>;
  include_habits_not_true?: Maybe<Scalars['String']>;
  include_habits_false?: Maybe<Scalars['String']>;
  include_habits_not_false?: Maybe<Scalars['String']>;
  include_habits_present?: Maybe<Scalars['String']>;
  include_habits_blank?: Maybe<Scalars['String']>;
  include_habits_null?: Maybe<Scalars['String']>;
  include_habits_not_null?: Maybe<Scalars['String']>;
  include_habits_count?: Maybe<Scalars['String']>;
  include_habits_count_distinct?: Maybe<Scalars['String']>;
  include_habits_sum?: Maybe<Scalars['String']>;
  include_habits_avg?: Maybe<Scalars['String']>;
  include_habits_min?: Maybe<Scalars['String']>;
  include_habits_max?: Maybe<Scalars['String']>;
  include_objectives_eq?: Maybe<Scalars['String']>;
  include_objectives_eq_any?: Maybe<Scalars['String']>;
  include_objectives_eq_all?: Maybe<Scalars['String']>;
  include_objectives_not_eq?: Maybe<Scalars['String']>;
  include_objectives_not_eq_any?: Maybe<Scalars['String']>;
  include_objectives_not_eq_all?: Maybe<Scalars['String']>;
  include_objectives_matches?: Maybe<Scalars['String']>;
  include_objectives_matches_any?: Maybe<Scalars['String']>;
  include_objectives_matches_all?: Maybe<Scalars['String']>;
  include_objectives_does_not_match?: Maybe<Scalars['String']>;
  include_objectives_does_not_match_any?: Maybe<Scalars['String']>;
  include_objectives_does_not_match_all?: Maybe<Scalars['String']>;
  include_objectives_lt?: Maybe<Scalars['String']>;
  include_objectives_lt_any?: Maybe<Scalars['String']>;
  include_objectives_lt_all?: Maybe<Scalars['String']>;
  include_objectives_lteq?: Maybe<Scalars['String']>;
  include_objectives_lteq_any?: Maybe<Scalars['String']>;
  include_objectives_lteq_all?: Maybe<Scalars['String']>;
  include_objectives_gt?: Maybe<Scalars['String']>;
  include_objectives_gt_any?: Maybe<Scalars['String']>;
  include_objectives_gt_all?: Maybe<Scalars['String']>;
  include_objectives_gteq?: Maybe<Scalars['String']>;
  include_objectives_gteq_any?: Maybe<Scalars['String']>;
  include_objectives_gteq_all?: Maybe<Scalars['String']>;
  include_objectives_in?: Maybe<Scalars['String']>;
  include_objectives_in_any?: Maybe<Scalars['String']>;
  include_objectives_in_all?: Maybe<Scalars['String']>;
  include_objectives_not_in?: Maybe<Scalars['String']>;
  include_objectives_not_in_any?: Maybe<Scalars['String']>;
  include_objectives_not_in_all?: Maybe<Scalars['String']>;
  include_objectives_cont?: Maybe<Scalars['String']>;
  include_objectives_cont_any?: Maybe<Scalars['String']>;
  include_objectives_cont_all?: Maybe<Scalars['String']>;
  include_objectives_not_cont?: Maybe<Scalars['String']>;
  include_objectives_not_cont_any?: Maybe<Scalars['String']>;
  include_objectives_not_cont_all?: Maybe<Scalars['String']>;
  include_objectives_i_cont?: Maybe<Scalars['String']>;
  include_objectives_i_cont_any?: Maybe<Scalars['String']>;
  include_objectives_i_cont_all?: Maybe<Scalars['String']>;
  include_objectives_not_i_cont?: Maybe<Scalars['String']>;
  include_objectives_not_i_cont_any?: Maybe<Scalars['String']>;
  include_objectives_not_i_cont_all?: Maybe<Scalars['String']>;
  include_objectives_start?: Maybe<Scalars['String']>;
  include_objectives_start_any?: Maybe<Scalars['String']>;
  include_objectives_start_all?: Maybe<Scalars['String']>;
  include_objectives_not_start?: Maybe<Scalars['String']>;
  include_objectives_not_start_any?: Maybe<Scalars['String']>;
  include_objectives_not_start_all?: Maybe<Scalars['String']>;
  include_objectives_end?: Maybe<Scalars['String']>;
  include_objectives_end_any?: Maybe<Scalars['String']>;
  include_objectives_end_all?: Maybe<Scalars['String']>;
  include_objectives_not_end?: Maybe<Scalars['String']>;
  include_objectives_not_end_any?: Maybe<Scalars['String']>;
  include_objectives_not_end_all?: Maybe<Scalars['String']>;
  include_objectives_true?: Maybe<Scalars['String']>;
  include_objectives_not_true?: Maybe<Scalars['String']>;
  include_objectives_false?: Maybe<Scalars['String']>;
  include_objectives_not_false?: Maybe<Scalars['String']>;
  include_objectives_present?: Maybe<Scalars['String']>;
  include_objectives_blank?: Maybe<Scalars['String']>;
  include_objectives_null?: Maybe<Scalars['String']>;
  include_objectives_not_null?: Maybe<Scalars['String']>;
  include_objectives_count?: Maybe<Scalars['String']>;
  include_objectives_count_distinct?: Maybe<Scalars['String']>;
  include_objectives_sum?: Maybe<Scalars['String']>;
  include_objectives_avg?: Maybe<Scalars['String']>;
  include_objectives_min?: Maybe<Scalars['String']>;
  include_objectives_max?: Maybe<Scalars['String']>;
  start_date_eq?: Maybe<Scalars['String']>;
  start_date_eq_any?: Maybe<Scalars['String']>;
  start_date_eq_all?: Maybe<Scalars['String']>;
  start_date_not_eq?: Maybe<Scalars['String']>;
  start_date_not_eq_any?: Maybe<Scalars['String']>;
  start_date_not_eq_all?: Maybe<Scalars['String']>;
  start_date_matches?: Maybe<Scalars['String']>;
  start_date_matches_any?: Maybe<Scalars['String']>;
  start_date_matches_all?: Maybe<Scalars['String']>;
  start_date_does_not_match?: Maybe<Scalars['String']>;
  start_date_does_not_match_any?: Maybe<Scalars['String']>;
  start_date_does_not_match_all?: Maybe<Scalars['String']>;
  start_date_lt?: Maybe<Scalars['String']>;
  start_date_lt_any?: Maybe<Scalars['String']>;
  start_date_lt_all?: Maybe<Scalars['String']>;
  start_date_lteq?: Maybe<Scalars['String']>;
  start_date_lteq_any?: Maybe<Scalars['String']>;
  start_date_lteq_all?: Maybe<Scalars['String']>;
  start_date_gt?: Maybe<Scalars['String']>;
  start_date_gt_any?: Maybe<Scalars['String']>;
  start_date_gt_all?: Maybe<Scalars['String']>;
  start_date_gteq?: Maybe<Scalars['String']>;
  start_date_gteq_any?: Maybe<Scalars['String']>;
  start_date_gteq_all?: Maybe<Scalars['String']>;
  start_date_in?: Maybe<Scalars['String']>;
  start_date_in_any?: Maybe<Scalars['String']>;
  start_date_in_all?: Maybe<Scalars['String']>;
  start_date_not_in?: Maybe<Scalars['String']>;
  start_date_not_in_any?: Maybe<Scalars['String']>;
  start_date_not_in_all?: Maybe<Scalars['String']>;
  start_date_cont?: Maybe<Scalars['String']>;
  start_date_cont_any?: Maybe<Scalars['String']>;
  start_date_cont_all?: Maybe<Scalars['String']>;
  start_date_not_cont?: Maybe<Scalars['String']>;
  start_date_not_cont_any?: Maybe<Scalars['String']>;
  start_date_not_cont_all?: Maybe<Scalars['String']>;
  start_date_i_cont?: Maybe<Scalars['String']>;
  start_date_i_cont_any?: Maybe<Scalars['String']>;
  start_date_i_cont_all?: Maybe<Scalars['String']>;
  start_date_not_i_cont?: Maybe<Scalars['String']>;
  start_date_not_i_cont_any?: Maybe<Scalars['String']>;
  start_date_not_i_cont_all?: Maybe<Scalars['String']>;
  start_date_start?: Maybe<Scalars['String']>;
  start_date_start_any?: Maybe<Scalars['String']>;
  start_date_start_all?: Maybe<Scalars['String']>;
  start_date_not_start?: Maybe<Scalars['String']>;
  start_date_not_start_any?: Maybe<Scalars['String']>;
  start_date_not_start_all?: Maybe<Scalars['String']>;
  start_date_end?: Maybe<Scalars['String']>;
  start_date_end_any?: Maybe<Scalars['String']>;
  start_date_end_all?: Maybe<Scalars['String']>;
  start_date_not_end?: Maybe<Scalars['String']>;
  start_date_not_end_any?: Maybe<Scalars['String']>;
  start_date_not_end_all?: Maybe<Scalars['String']>;
  start_date_true?: Maybe<Scalars['String']>;
  start_date_not_true?: Maybe<Scalars['String']>;
  start_date_false?: Maybe<Scalars['String']>;
  start_date_not_false?: Maybe<Scalars['String']>;
  start_date_present?: Maybe<Scalars['String']>;
  start_date_blank?: Maybe<Scalars['String']>;
  start_date_null?: Maybe<Scalars['String']>;
  start_date_not_null?: Maybe<Scalars['String']>;
  start_date_count?: Maybe<Scalars['String']>;
  start_date_count_distinct?: Maybe<Scalars['String']>;
  start_date_sum?: Maybe<Scalars['String']>;
  start_date_avg?: Maybe<Scalars['String']>;
  start_date_min?: Maybe<Scalars['String']>;
  start_date_max?: Maybe<Scalars['String']>;
  end_date_eq?: Maybe<Scalars['String']>;
  end_date_eq_any?: Maybe<Scalars['String']>;
  end_date_eq_all?: Maybe<Scalars['String']>;
  end_date_not_eq?: Maybe<Scalars['String']>;
  end_date_not_eq_any?: Maybe<Scalars['String']>;
  end_date_not_eq_all?: Maybe<Scalars['String']>;
  end_date_matches?: Maybe<Scalars['String']>;
  end_date_matches_any?: Maybe<Scalars['String']>;
  end_date_matches_all?: Maybe<Scalars['String']>;
  end_date_does_not_match?: Maybe<Scalars['String']>;
  end_date_does_not_match_any?: Maybe<Scalars['String']>;
  end_date_does_not_match_all?: Maybe<Scalars['String']>;
  end_date_lt?: Maybe<Scalars['String']>;
  end_date_lt_any?: Maybe<Scalars['String']>;
  end_date_lt_all?: Maybe<Scalars['String']>;
  end_date_lteq?: Maybe<Scalars['String']>;
  end_date_lteq_any?: Maybe<Scalars['String']>;
  end_date_lteq_all?: Maybe<Scalars['String']>;
  end_date_gt?: Maybe<Scalars['String']>;
  end_date_gt_any?: Maybe<Scalars['String']>;
  end_date_gt_all?: Maybe<Scalars['String']>;
  end_date_gteq?: Maybe<Scalars['String']>;
  end_date_gteq_any?: Maybe<Scalars['String']>;
  end_date_gteq_all?: Maybe<Scalars['String']>;
  end_date_in?: Maybe<Scalars['String']>;
  end_date_in_any?: Maybe<Scalars['String']>;
  end_date_in_all?: Maybe<Scalars['String']>;
  end_date_not_in?: Maybe<Scalars['String']>;
  end_date_not_in_any?: Maybe<Scalars['String']>;
  end_date_not_in_all?: Maybe<Scalars['String']>;
  end_date_cont?: Maybe<Scalars['String']>;
  end_date_cont_any?: Maybe<Scalars['String']>;
  end_date_cont_all?: Maybe<Scalars['String']>;
  end_date_not_cont?: Maybe<Scalars['String']>;
  end_date_not_cont_any?: Maybe<Scalars['String']>;
  end_date_not_cont_all?: Maybe<Scalars['String']>;
  end_date_i_cont?: Maybe<Scalars['String']>;
  end_date_i_cont_any?: Maybe<Scalars['String']>;
  end_date_i_cont_all?: Maybe<Scalars['String']>;
  end_date_not_i_cont?: Maybe<Scalars['String']>;
  end_date_not_i_cont_any?: Maybe<Scalars['String']>;
  end_date_not_i_cont_all?: Maybe<Scalars['String']>;
  end_date_start?: Maybe<Scalars['String']>;
  end_date_start_any?: Maybe<Scalars['String']>;
  end_date_start_all?: Maybe<Scalars['String']>;
  end_date_not_start?: Maybe<Scalars['String']>;
  end_date_not_start_any?: Maybe<Scalars['String']>;
  end_date_not_start_all?: Maybe<Scalars['String']>;
  end_date_end?: Maybe<Scalars['String']>;
  end_date_end_any?: Maybe<Scalars['String']>;
  end_date_end_all?: Maybe<Scalars['String']>;
  end_date_not_end?: Maybe<Scalars['String']>;
  end_date_not_end_any?: Maybe<Scalars['String']>;
  end_date_not_end_all?: Maybe<Scalars['String']>;
  end_date_true?: Maybe<Scalars['String']>;
  end_date_not_true?: Maybe<Scalars['String']>;
  end_date_false?: Maybe<Scalars['String']>;
  end_date_not_false?: Maybe<Scalars['String']>;
  end_date_present?: Maybe<Scalars['String']>;
  end_date_blank?: Maybe<Scalars['String']>;
  end_date_null?: Maybe<Scalars['String']>;
  end_date_not_null?: Maybe<Scalars['String']>;
  end_date_count?: Maybe<Scalars['String']>;
  end_date_count_distinct?: Maybe<Scalars['String']>;
  end_date_sum?: Maybe<Scalars['String']>;
  end_date_avg?: Maybe<Scalars['String']>;
  end_date_min?: Maybe<Scalars['String']>;
  end_date_max?: Maybe<Scalars['String']>;
  creator_id_eq?: Maybe<Scalars['String']>;
  creator_id_eq_any?: Maybe<Scalars['String']>;
  creator_id_eq_all?: Maybe<Scalars['String']>;
  creator_id_not_eq?: Maybe<Scalars['String']>;
  creator_id_not_eq_any?: Maybe<Scalars['String']>;
  creator_id_not_eq_all?: Maybe<Scalars['String']>;
  creator_id_matches?: Maybe<Scalars['String']>;
  creator_id_matches_any?: Maybe<Scalars['String']>;
  creator_id_matches_all?: Maybe<Scalars['String']>;
  creator_id_does_not_match?: Maybe<Scalars['String']>;
  creator_id_does_not_match_any?: Maybe<Scalars['String']>;
  creator_id_does_not_match_all?: Maybe<Scalars['String']>;
  creator_id_lt?: Maybe<Scalars['String']>;
  creator_id_lt_any?: Maybe<Scalars['String']>;
  creator_id_lt_all?: Maybe<Scalars['String']>;
  creator_id_lteq?: Maybe<Scalars['String']>;
  creator_id_lteq_any?: Maybe<Scalars['String']>;
  creator_id_lteq_all?: Maybe<Scalars['String']>;
  creator_id_gt?: Maybe<Scalars['String']>;
  creator_id_gt_any?: Maybe<Scalars['String']>;
  creator_id_gt_all?: Maybe<Scalars['String']>;
  creator_id_gteq?: Maybe<Scalars['String']>;
  creator_id_gteq_any?: Maybe<Scalars['String']>;
  creator_id_gteq_all?: Maybe<Scalars['String']>;
  creator_id_in?: Maybe<Scalars['String']>;
  creator_id_in_any?: Maybe<Scalars['String']>;
  creator_id_in_all?: Maybe<Scalars['String']>;
  creator_id_not_in?: Maybe<Scalars['String']>;
  creator_id_not_in_any?: Maybe<Scalars['String']>;
  creator_id_not_in_all?: Maybe<Scalars['String']>;
  creator_id_cont?: Maybe<Scalars['String']>;
  creator_id_cont_any?: Maybe<Scalars['String']>;
  creator_id_cont_all?: Maybe<Scalars['String']>;
  creator_id_not_cont?: Maybe<Scalars['String']>;
  creator_id_not_cont_any?: Maybe<Scalars['String']>;
  creator_id_not_cont_all?: Maybe<Scalars['String']>;
  creator_id_i_cont?: Maybe<Scalars['String']>;
  creator_id_i_cont_any?: Maybe<Scalars['String']>;
  creator_id_i_cont_all?: Maybe<Scalars['String']>;
  creator_id_not_i_cont?: Maybe<Scalars['String']>;
  creator_id_not_i_cont_any?: Maybe<Scalars['String']>;
  creator_id_not_i_cont_all?: Maybe<Scalars['String']>;
  creator_id_start?: Maybe<Scalars['String']>;
  creator_id_start_any?: Maybe<Scalars['String']>;
  creator_id_start_all?: Maybe<Scalars['String']>;
  creator_id_not_start?: Maybe<Scalars['String']>;
  creator_id_not_start_any?: Maybe<Scalars['String']>;
  creator_id_not_start_all?: Maybe<Scalars['String']>;
  creator_id_end?: Maybe<Scalars['String']>;
  creator_id_end_any?: Maybe<Scalars['String']>;
  creator_id_end_all?: Maybe<Scalars['String']>;
  creator_id_not_end?: Maybe<Scalars['String']>;
  creator_id_not_end_any?: Maybe<Scalars['String']>;
  creator_id_not_end_all?: Maybe<Scalars['String']>;
  creator_id_true?: Maybe<Scalars['String']>;
  creator_id_not_true?: Maybe<Scalars['String']>;
  creator_id_false?: Maybe<Scalars['String']>;
  creator_id_not_false?: Maybe<Scalars['String']>;
  creator_id_present?: Maybe<Scalars['String']>;
  creator_id_blank?: Maybe<Scalars['String']>;
  creator_id_null?: Maybe<Scalars['String']>;
  creator_id_not_null?: Maybe<Scalars['String']>;
  creator_id_count?: Maybe<Scalars['String']>;
  creator_id_count_distinct?: Maybe<Scalars['String']>;
  creator_id_sum?: Maybe<Scalars['String']>;
  creator_id_avg?: Maybe<Scalars['String']>;
  creator_id_min?: Maybe<Scalars['String']>;
  creator_id_max?: Maybe<Scalars['String']>;
  notes_eq?: Maybe<Scalars['String']>;
  notes_eq_any?: Maybe<Scalars['String']>;
  notes_eq_all?: Maybe<Scalars['String']>;
  notes_not_eq?: Maybe<Scalars['String']>;
  notes_not_eq_any?: Maybe<Scalars['String']>;
  notes_not_eq_all?: Maybe<Scalars['String']>;
  notes_matches?: Maybe<Scalars['String']>;
  notes_matches_any?: Maybe<Scalars['String']>;
  notes_matches_all?: Maybe<Scalars['String']>;
  notes_does_not_match?: Maybe<Scalars['String']>;
  notes_does_not_match_any?: Maybe<Scalars['String']>;
  notes_does_not_match_all?: Maybe<Scalars['String']>;
  notes_lt?: Maybe<Scalars['String']>;
  notes_lt_any?: Maybe<Scalars['String']>;
  notes_lt_all?: Maybe<Scalars['String']>;
  notes_lteq?: Maybe<Scalars['String']>;
  notes_lteq_any?: Maybe<Scalars['String']>;
  notes_lteq_all?: Maybe<Scalars['String']>;
  notes_gt?: Maybe<Scalars['String']>;
  notes_gt_any?: Maybe<Scalars['String']>;
  notes_gt_all?: Maybe<Scalars['String']>;
  notes_gteq?: Maybe<Scalars['String']>;
  notes_gteq_any?: Maybe<Scalars['String']>;
  notes_gteq_all?: Maybe<Scalars['String']>;
  notes_in?: Maybe<Scalars['String']>;
  notes_in_any?: Maybe<Scalars['String']>;
  notes_in_all?: Maybe<Scalars['String']>;
  notes_not_in?: Maybe<Scalars['String']>;
  notes_not_in_any?: Maybe<Scalars['String']>;
  notes_not_in_all?: Maybe<Scalars['String']>;
  notes_cont?: Maybe<Scalars['String']>;
  notes_cont_any?: Maybe<Scalars['String']>;
  notes_cont_all?: Maybe<Scalars['String']>;
  notes_not_cont?: Maybe<Scalars['String']>;
  notes_not_cont_any?: Maybe<Scalars['String']>;
  notes_not_cont_all?: Maybe<Scalars['String']>;
  notes_i_cont?: Maybe<Scalars['String']>;
  notes_i_cont_any?: Maybe<Scalars['String']>;
  notes_i_cont_all?: Maybe<Scalars['String']>;
  notes_not_i_cont?: Maybe<Scalars['String']>;
  notes_not_i_cont_any?: Maybe<Scalars['String']>;
  notes_not_i_cont_all?: Maybe<Scalars['String']>;
  notes_start?: Maybe<Scalars['String']>;
  notes_start_any?: Maybe<Scalars['String']>;
  notes_start_all?: Maybe<Scalars['String']>;
  notes_not_start?: Maybe<Scalars['String']>;
  notes_not_start_any?: Maybe<Scalars['String']>;
  notes_not_start_all?: Maybe<Scalars['String']>;
  notes_end?: Maybe<Scalars['String']>;
  notes_end_any?: Maybe<Scalars['String']>;
  notes_end_all?: Maybe<Scalars['String']>;
  notes_not_end?: Maybe<Scalars['String']>;
  notes_not_end_any?: Maybe<Scalars['String']>;
  notes_not_end_all?: Maybe<Scalars['String']>;
  notes_true?: Maybe<Scalars['String']>;
  notes_not_true?: Maybe<Scalars['String']>;
  notes_false?: Maybe<Scalars['String']>;
  notes_not_false?: Maybe<Scalars['String']>;
  notes_present?: Maybe<Scalars['String']>;
  notes_blank?: Maybe<Scalars['String']>;
  notes_null?: Maybe<Scalars['String']>;
  notes_not_null?: Maybe<Scalars['String']>;
  notes_count?: Maybe<Scalars['String']>;
  notes_count_distinct?: Maybe<Scalars['String']>;
  notes_sum?: Maybe<Scalars['String']>;
  notes_avg?: Maybe<Scalars['String']>;
  notes_min?: Maybe<Scalars['String']>;
  notes_max?: Maybe<Scalars['String']>;
  published_eq?: Maybe<Scalars['String']>;
  published_eq_any?: Maybe<Scalars['String']>;
  published_eq_all?: Maybe<Scalars['String']>;
  published_not_eq?: Maybe<Scalars['String']>;
  published_not_eq_any?: Maybe<Scalars['String']>;
  published_not_eq_all?: Maybe<Scalars['String']>;
  published_matches?: Maybe<Scalars['String']>;
  published_matches_any?: Maybe<Scalars['String']>;
  published_matches_all?: Maybe<Scalars['String']>;
  published_does_not_match?: Maybe<Scalars['String']>;
  published_does_not_match_any?: Maybe<Scalars['String']>;
  published_does_not_match_all?: Maybe<Scalars['String']>;
  published_lt?: Maybe<Scalars['String']>;
  published_lt_any?: Maybe<Scalars['String']>;
  published_lt_all?: Maybe<Scalars['String']>;
  published_lteq?: Maybe<Scalars['String']>;
  published_lteq_any?: Maybe<Scalars['String']>;
  published_lteq_all?: Maybe<Scalars['String']>;
  published_gt?: Maybe<Scalars['String']>;
  published_gt_any?: Maybe<Scalars['String']>;
  published_gt_all?: Maybe<Scalars['String']>;
  published_gteq?: Maybe<Scalars['String']>;
  published_gteq_any?: Maybe<Scalars['String']>;
  published_gteq_all?: Maybe<Scalars['String']>;
  published_in?: Maybe<Scalars['String']>;
  published_in_any?: Maybe<Scalars['String']>;
  published_in_all?: Maybe<Scalars['String']>;
  published_not_in?: Maybe<Scalars['String']>;
  published_not_in_any?: Maybe<Scalars['String']>;
  published_not_in_all?: Maybe<Scalars['String']>;
  published_cont?: Maybe<Scalars['String']>;
  published_cont_any?: Maybe<Scalars['String']>;
  published_cont_all?: Maybe<Scalars['String']>;
  published_not_cont?: Maybe<Scalars['String']>;
  published_not_cont_any?: Maybe<Scalars['String']>;
  published_not_cont_all?: Maybe<Scalars['String']>;
  published_i_cont?: Maybe<Scalars['String']>;
  published_i_cont_any?: Maybe<Scalars['String']>;
  published_i_cont_all?: Maybe<Scalars['String']>;
  published_not_i_cont?: Maybe<Scalars['String']>;
  published_not_i_cont_any?: Maybe<Scalars['String']>;
  published_not_i_cont_all?: Maybe<Scalars['String']>;
  published_start?: Maybe<Scalars['String']>;
  published_start_any?: Maybe<Scalars['String']>;
  published_start_all?: Maybe<Scalars['String']>;
  published_not_start?: Maybe<Scalars['String']>;
  published_not_start_any?: Maybe<Scalars['String']>;
  published_not_start_all?: Maybe<Scalars['String']>;
  published_end?: Maybe<Scalars['String']>;
  published_end_any?: Maybe<Scalars['String']>;
  published_end_all?: Maybe<Scalars['String']>;
  published_not_end?: Maybe<Scalars['String']>;
  published_not_end_any?: Maybe<Scalars['String']>;
  published_not_end_all?: Maybe<Scalars['String']>;
  published_true?: Maybe<Scalars['String']>;
  published_not_true?: Maybe<Scalars['String']>;
  published_false?: Maybe<Scalars['String']>;
  published_not_false?: Maybe<Scalars['String']>;
  published_present?: Maybe<Scalars['String']>;
  published_blank?: Maybe<Scalars['String']>;
  published_null?: Maybe<Scalars['String']>;
  published_not_null?: Maybe<Scalars['String']>;
  published_count?: Maybe<Scalars['String']>;
  published_count_distinct?: Maybe<Scalars['String']>;
  published_sum?: Maybe<Scalars['String']>;
  published_avg?: Maybe<Scalars['String']>;
  published_min?: Maybe<Scalars['String']>;
  published_max?: Maybe<Scalars['String']>;
  network_managed_eq?: Maybe<Scalars['String']>;
  network_managed_eq_any?: Maybe<Scalars['String']>;
  network_managed_eq_all?: Maybe<Scalars['String']>;
  network_managed_not_eq?: Maybe<Scalars['String']>;
  network_managed_not_eq_any?: Maybe<Scalars['String']>;
  network_managed_not_eq_all?: Maybe<Scalars['String']>;
  network_managed_matches?: Maybe<Scalars['String']>;
  network_managed_matches_any?: Maybe<Scalars['String']>;
  network_managed_matches_all?: Maybe<Scalars['String']>;
  network_managed_does_not_match?: Maybe<Scalars['String']>;
  network_managed_does_not_match_any?: Maybe<Scalars['String']>;
  network_managed_does_not_match_all?: Maybe<Scalars['String']>;
  network_managed_lt?: Maybe<Scalars['String']>;
  network_managed_lt_any?: Maybe<Scalars['String']>;
  network_managed_lt_all?: Maybe<Scalars['String']>;
  network_managed_lteq?: Maybe<Scalars['String']>;
  network_managed_lteq_any?: Maybe<Scalars['String']>;
  network_managed_lteq_all?: Maybe<Scalars['String']>;
  network_managed_gt?: Maybe<Scalars['String']>;
  network_managed_gt_any?: Maybe<Scalars['String']>;
  network_managed_gt_all?: Maybe<Scalars['String']>;
  network_managed_gteq?: Maybe<Scalars['String']>;
  network_managed_gteq_any?: Maybe<Scalars['String']>;
  network_managed_gteq_all?: Maybe<Scalars['String']>;
  network_managed_in?: Maybe<Scalars['String']>;
  network_managed_in_any?: Maybe<Scalars['String']>;
  network_managed_in_all?: Maybe<Scalars['String']>;
  network_managed_not_in?: Maybe<Scalars['String']>;
  network_managed_not_in_any?: Maybe<Scalars['String']>;
  network_managed_not_in_all?: Maybe<Scalars['String']>;
  network_managed_cont?: Maybe<Scalars['String']>;
  network_managed_cont_any?: Maybe<Scalars['String']>;
  network_managed_cont_all?: Maybe<Scalars['String']>;
  network_managed_not_cont?: Maybe<Scalars['String']>;
  network_managed_not_cont_any?: Maybe<Scalars['String']>;
  network_managed_not_cont_all?: Maybe<Scalars['String']>;
  network_managed_i_cont?: Maybe<Scalars['String']>;
  network_managed_i_cont_any?: Maybe<Scalars['String']>;
  network_managed_i_cont_all?: Maybe<Scalars['String']>;
  network_managed_not_i_cont?: Maybe<Scalars['String']>;
  network_managed_not_i_cont_any?: Maybe<Scalars['String']>;
  network_managed_not_i_cont_all?: Maybe<Scalars['String']>;
  network_managed_start?: Maybe<Scalars['String']>;
  network_managed_start_any?: Maybe<Scalars['String']>;
  network_managed_start_all?: Maybe<Scalars['String']>;
  network_managed_not_start?: Maybe<Scalars['String']>;
  network_managed_not_start_any?: Maybe<Scalars['String']>;
  network_managed_not_start_all?: Maybe<Scalars['String']>;
  network_managed_end?: Maybe<Scalars['String']>;
  network_managed_end_any?: Maybe<Scalars['String']>;
  network_managed_end_all?: Maybe<Scalars['String']>;
  network_managed_not_end?: Maybe<Scalars['String']>;
  network_managed_not_end_any?: Maybe<Scalars['String']>;
  network_managed_not_end_all?: Maybe<Scalars['String']>;
  network_managed_true?: Maybe<Scalars['String']>;
  network_managed_not_true?: Maybe<Scalars['String']>;
  network_managed_false?: Maybe<Scalars['String']>;
  network_managed_not_false?: Maybe<Scalars['String']>;
  network_managed_present?: Maybe<Scalars['String']>;
  network_managed_blank?: Maybe<Scalars['String']>;
  network_managed_null?: Maybe<Scalars['String']>;
  network_managed_not_null?: Maybe<Scalars['String']>;
  network_managed_count?: Maybe<Scalars['String']>;
  network_managed_count_distinct?: Maybe<Scalars['String']>;
  network_managed_sum?: Maybe<Scalars['String']>;
  network_managed_avg?: Maybe<Scalars['String']>;
  network_managed_min?: Maybe<Scalars['String']>;
  network_managed_max?: Maybe<Scalars['String']>;
  data_eq?: Maybe<Scalars['String']>;
  data_eq_any?: Maybe<Scalars['String']>;
  data_eq_all?: Maybe<Scalars['String']>;
  data_not_eq?: Maybe<Scalars['String']>;
  data_not_eq_any?: Maybe<Scalars['String']>;
  data_not_eq_all?: Maybe<Scalars['String']>;
  data_matches?: Maybe<Scalars['String']>;
  data_matches_any?: Maybe<Scalars['String']>;
  data_matches_all?: Maybe<Scalars['String']>;
  data_does_not_match?: Maybe<Scalars['String']>;
  data_does_not_match_any?: Maybe<Scalars['String']>;
  data_does_not_match_all?: Maybe<Scalars['String']>;
  data_lt?: Maybe<Scalars['String']>;
  data_lt_any?: Maybe<Scalars['String']>;
  data_lt_all?: Maybe<Scalars['String']>;
  data_lteq?: Maybe<Scalars['String']>;
  data_lteq_any?: Maybe<Scalars['String']>;
  data_lteq_all?: Maybe<Scalars['String']>;
  data_gt?: Maybe<Scalars['String']>;
  data_gt_any?: Maybe<Scalars['String']>;
  data_gt_all?: Maybe<Scalars['String']>;
  data_gteq?: Maybe<Scalars['String']>;
  data_gteq_any?: Maybe<Scalars['String']>;
  data_gteq_all?: Maybe<Scalars['String']>;
  data_in?: Maybe<Scalars['String']>;
  data_in_any?: Maybe<Scalars['String']>;
  data_in_all?: Maybe<Scalars['String']>;
  data_not_in?: Maybe<Scalars['String']>;
  data_not_in_any?: Maybe<Scalars['String']>;
  data_not_in_all?: Maybe<Scalars['String']>;
  data_cont?: Maybe<Scalars['String']>;
  data_cont_any?: Maybe<Scalars['String']>;
  data_cont_all?: Maybe<Scalars['String']>;
  data_not_cont?: Maybe<Scalars['String']>;
  data_not_cont_any?: Maybe<Scalars['String']>;
  data_not_cont_all?: Maybe<Scalars['String']>;
  data_i_cont?: Maybe<Scalars['String']>;
  data_i_cont_any?: Maybe<Scalars['String']>;
  data_i_cont_all?: Maybe<Scalars['String']>;
  data_not_i_cont?: Maybe<Scalars['String']>;
  data_not_i_cont_any?: Maybe<Scalars['String']>;
  data_not_i_cont_all?: Maybe<Scalars['String']>;
  data_start?: Maybe<Scalars['String']>;
  data_start_any?: Maybe<Scalars['String']>;
  data_start_all?: Maybe<Scalars['String']>;
  data_not_start?: Maybe<Scalars['String']>;
  data_not_start_any?: Maybe<Scalars['String']>;
  data_not_start_all?: Maybe<Scalars['String']>;
  data_end?: Maybe<Scalars['String']>;
  data_end_any?: Maybe<Scalars['String']>;
  data_end_all?: Maybe<Scalars['String']>;
  data_not_end?: Maybe<Scalars['String']>;
  data_not_end_any?: Maybe<Scalars['String']>;
  data_not_end_all?: Maybe<Scalars['String']>;
  data_true?: Maybe<Scalars['String']>;
  data_not_true?: Maybe<Scalars['String']>;
  data_false?: Maybe<Scalars['String']>;
  data_not_false?: Maybe<Scalars['String']>;
  data_present?: Maybe<Scalars['String']>;
  data_blank?: Maybe<Scalars['String']>;
  data_null?: Maybe<Scalars['String']>;
  data_not_null?: Maybe<Scalars['String']>;
  data_count?: Maybe<Scalars['String']>;
  data_count_distinct?: Maybe<Scalars['String']>;
  data_sum?: Maybe<Scalars['String']>;
  data_avg?: Maybe<Scalars['String']>;
  data_min?: Maybe<Scalars['String']>;
  data_max?: Maybe<Scalars['String']>;
  created_at_eq?: Maybe<Scalars['String']>;
  created_at_eq_any?: Maybe<Scalars['String']>;
  created_at_eq_all?: Maybe<Scalars['String']>;
  created_at_not_eq?: Maybe<Scalars['String']>;
  created_at_not_eq_any?: Maybe<Scalars['String']>;
  created_at_not_eq_all?: Maybe<Scalars['String']>;
  created_at_matches?: Maybe<Scalars['String']>;
  created_at_matches_any?: Maybe<Scalars['String']>;
  created_at_matches_all?: Maybe<Scalars['String']>;
  created_at_does_not_match?: Maybe<Scalars['String']>;
  created_at_does_not_match_any?: Maybe<Scalars['String']>;
  created_at_does_not_match_all?: Maybe<Scalars['String']>;
  created_at_lt?: Maybe<Scalars['String']>;
  created_at_lt_any?: Maybe<Scalars['String']>;
  created_at_lt_all?: Maybe<Scalars['String']>;
  created_at_lteq?: Maybe<Scalars['String']>;
  created_at_lteq_any?: Maybe<Scalars['String']>;
  created_at_lteq_all?: Maybe<Scalars['String']>;
  created_at_gt?: Maybe<Scalars['String']>;
  created_at_gt_any?: Maybe<Scalars['String']>;
  created_at_gt_all?: Maybe<Scalars['String']>;
  created_at_gteq?: Maybe<Scalars['String']>;
  created_at_gteq_any?: Maybe<Scalars['String']>;
  created_at_gteq_all?: Maybe<Scalars['String']>;
  created_at_in?: Maybe<Scalars['String']>;
  created_at_in_any?: Maybe<Scalars['String']>;
  created_at_in_all?: Maybe<Scalars['String']>;
  created_at_not_in?: Maybe<Scalars['String']>;
  created_at_not_in_any?: Maybe<Scalars['String']>;
  created_at_not_in_all?: Maybe<Scalars['String']>;
  created_at_cont?: Maybe<Scalars['String']>;
  created_at_cont_any?: Maybe<Scalars['String']>;
  created_at_cont_all?: Maybe<Scalars['String']>;
  created_at_not_cont?: Maybe<Scalars['String']>;
  created_at_not_cont_any?: Maybe<Scalars['String']>;
  created_at_not_cont_all?: Maybe<Scalars['String']>;
  created_at_i_cont?: Maybe<Scalars['String']>;
  created_at_i_cont_any?: Maybe<Scalars['String']>;
  created_at_i_cont_all?: Maybe<Scalars['String']>;
  created_at_not_i_cont?: Maybe<Scalars['String']>;
  created_at_not_i_cont_any?: Maybe<Scalars['String']>;
  created_at_not_i_cont_all?: Maybe<Scalars['String']>;
  created_at_start?: Maybe<Scalars['String']>;
  created_at_start_any?: Maybe<Scalars['String']>;
  created_at_start_all?: Maybe<Scalars['String']>;
  created_at_not_start?: Maybe<Scalars['String']>;
  created_at_not_start_any?: Maybe<Scalars['String']>;
  created_at_not_start_all?: Maybe<Scalars['String']>;
  created_at_end?: Maybe<Scalars['String']>;
  created_at_end_any?: Maybe<Scalars['String']>;
  created_at_end_all?: Maybe<Scalars['String']>;
  created_at_not_end?: Maybe<Scalars['String']>;
  created_at_not_end_any?: Maybe<Scalars['String']>;
  created_at_not_end_all?: Maybe<Scalars['String']>;
  created_at_true?: Maybe<Scalars['String']>;
  created_at_not_true?: Maybe<Scalars['String']>;
  created_at_false?: Maybe<Scalars['String']>;
  created_at_not_false?: Maybe<Scalars['String']>;
  created_at_present?: Maybe<Scalars['String']>;
  created_at_blank?: Maybe<Scalars['String']>;
  created_at_null?: Maybe<Scalars['String']>;
  created_at_not_null?: Maybe<Scalars['String']>;
  created_at_count?: Maybe<Scalars['String']>;
  created_at_count_distinct?: Maybe<Scalars['String']>;
  created_at_sum?: Maybe<Scalars['String']>;
  created_at_avg?: Maybe<Scalars['String']>;
  created_at_min?: Maybe<Scalars['String']>;
  created_at_max?: Maybe<Scalars['String']>;
  updated_at_eq?: Maybe<Scalars['String']>;
  updated_at_eq_any?: Maybe<Scalars['String']>;
  updated_at_eq_all?: Maybe<Scalars['String']>;
  updated_at_not_eq?: Maybe<Scalars['String']>;
  updated_at_not_eq_any?: Maybe<Scalars['String']>;
  updated_at_not_eq_all?: Maybe<Scalars['String']>;
  updated_at_matches?: Maybe<Scalars['String']>;
  updated_at_matches_any?: Maybe<Scalars['String']>;
  updated_at_matches_all?: Maybe<Scalars['String']>;
  updated_at_does_not_match?: Maybe<Scalars['String']>;
  updated_at_does_not_match_any?: Maybe<Scalars['String']>;
  updated_at_does_not_match_all?: Maybe<Scalars['String']>;
  updated_at_lt?: Maybe<Scalars['String']>;
  updated_at_lt_any?: Maybe<Scalars['String']>;
  updated_at_lt_all?: Maybe<Scalars['String']>;
  updated_at_lteq?: Maybe<Scalars['String']>;
  updated_at_lteq_any?: Maybe<Scalars['String']>;
  updated_at_lteq_all?: Maybe<Scalars['String']>;
  updated_at_gt?: Maybe<Scalars['String']>;
  updated_at_gt_any?: Maybe<Scalars['String']>;
  updated_at_gt_all?: Maybe<Scalars['String']>;
  updated_at_gteq?: Maybe<Scalars['String']>;
  updated_at_gteq_any?: Maybe<Scalars['String']>;
  updated_at_gteq_all?: Maybe<Scalars['String']>;
  updated_at_in?: Maybe<Scalars['String']>;
  updated_at_in_any?: Maybe<Scalars['String']>;
  updated_at_in_all?: Maybe<Scalars['String']>;
  updated_at_not_in?: Maybe<Scalars['String']>;
  updated_at_not_in_any?: Maybe<Scalars['String']>;
  updated_at_not_in_all?: Maybe<Scalars['String']>;
  updated_at_cont?: Maybe<Scalars['String']>;
  updated_at_cont_any?: Maybe<Scalars['String']>;
  updated_at_cont_all?: Maybe<Scalars['String']>;
  updated_at_not_cont?: Maybe<Scalars['String']>;
  updated_at_not_cont_any?: Maybe<Scalars['String']>;
  updated_at_not_cont_all?: Maybe<Scalars['String']>;
  updated_at_i_cont?: Maybe<Scalars['String']>;
  updated_at_i_cont_any?: Maybe<Scalars['String']>;
  updated_at_i_cont_all?: Maybe<Scalars['String']>;
  updated_at_not_i_cont?: Maybe<Scalars['String']>;
  updated_at_not_i_cont_any?: Maybe<Scalars['String']>;
  updated_at_not_i_cont_all?: Maybe<Scalars['String']>;
  updated_at_start?: Maybe<Scalars['String']>;
  updated_at_start_any?: Maybe<Scalars['String']>;
  updated_at_start_all?: Maybe<Scalars['String']>;
  updated_at_not_start?: Maybe<Scalars['String']>;
  updated_at_not_start_any?: Maybe<Scalars['String']>;
  updated_at_not_start_all?: Maybe<Scalars['String']>;
  updated_at_end?: Maybe<Scalars['String']>;
  updated_at_end_any?: Maybe<Scalars['String']>;
  updated_at_end_all?: Maybe<Scalars['String']>;
  updated_at_not_end?: Maybe<Scalars['String']>;
  updated_at_not_end_any?: Maybe<Scalars['String']>;
  updated_at_not_end_all?: Maybe<Scalars['String']>;
  updated_at_true?: Maybe<Scalars['String']>;
  updated_at_not_true?: Maybe<Scalars['String']>;
  updated_at_false?: Maybe<Scalars['String']>;
  updated_at_not_false?: Maybe<Scalars['String']>;
  updated_at_present?: Maybe<Scalars['String']>;
  updated_at_blank?: Maybe<Scalars['String']>;
  updated_at_null?: Maybe<Scalars['String']>;
  updated_at_not_null?: Maybe<Scalars['String']>;
  updated_at_count?: Maybe<Scalars['String']>;
  updated_at_count_distinct?: Maybe<Scalars['String']>;
  updated_at_sum?: Maybe<Scalars['String']>;
  updated_at_avg?: Maybe<Scalars['String']>;
  updated_at_min?: Maybe<Scalars['String']>;
  updated_at_max?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_eq?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_eq_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_eq_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_eq?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_eq_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_eq_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_matches?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_matches_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_matches_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_does_not_match?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_does_not_match_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_does_not_match_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_lt?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_lt_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_lt_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_lteq?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_lteq_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_lteq_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_gt?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_gt_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_gt_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_gteq?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_gteq_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_gteq_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_in?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_in_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_in_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_in?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_in_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_in_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_cont?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_cont_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_cont_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_cont?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_cont_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_cont_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_i_cont?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_i_cont_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_i_cont_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_i_cont?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_i_cont_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_i_cont_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_start?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_start_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_start_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_start?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_start_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_start_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_end?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_end_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_end_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_end?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_end_any?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_end_all?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_true?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_true?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_false?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_false?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_present?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_blank?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_null?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_not_null?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_count?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_count_distinct?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_sum?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_avg?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_min?: Maybe<Scalars['String']>;
  scheduled_one_on_one_id_max?: Maybe<Scalars['String']>;
  generated_from_objective_id_eq?: Maybe<Scalars['String']>;
  generated_from_objective_id_eq_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_eq_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_eq?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_eq_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_eq_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_matches?: Maybe<Scalars['String']>;
  generated_from_objective_id_matches_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_matches_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_does_not_match?: Maybe<Scalars['String']>;
  generated_from_objective_id_does_not_match_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_does_not_match_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_lt?: Maybe<Scalars['String']>;
  generated_from_objective_id_lt_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_lt_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_lteq?: Maybe<Scalars['String']>;
  generated_from_objective_id_lteq_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_lteq_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_gt?: Maybe<Scalars['String']>;
  generated_from_objective_id_gt_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_gt_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_gteq?: Maybe<Scalars['String']>;
  generated_from_objective_id_gteq_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_gteq_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_in?: Maybe<Scalars['String']>;
  generated_from_objective_id_in_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_in_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_in?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_in_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_in_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_cont?: Maybe<Scalars['String']>;
  generated_from_objective_id_cont_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_cont_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_cont?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_cont_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_cont_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_i_cont?: Maybe<Scalars['String']>;
  generated_from_objective_id_i_cont_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_i_cont_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_i_cont?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_i_cont_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_i_cont_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_start?: Maybe<Scalars['String']>;
  generated_from_objective_id_start_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_start_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_start?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_start_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_start_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_end?: Maybe<Scalars['String']>;
  generated_from_objective_id_end_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_end_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_end?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_end_any?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_end_all?: Maybe<Scalars['String']>;
  generated_from_objective_id_true?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_true?: Maybe<Scalars['String']>;
  generated_from_objective_id_false?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_false?: Maybe<Scalars['String']>;
  generated_from_objective_id_present?: Maybe<Scalars['String']>;
  generated_from_objective_id_blank?: Maybe<Scalars['String']>;
  generated_from_objective_id_null?: Maybe<Scalars['String']>;
  generated_from_objective_id_not_null?: Maybe<Scalars['String']>;
  generated_from_objective_id_count?: Maybe<Scalars['String']>;
  generated_from_objective_id_count_distinct?: Maybe<Scalars['String']>;
  generated_from_objective_id_sum?: Maybe<Scalars['String']>;
  generated_from_objective_id_avg?: Maybe<Scalars['String']>;
  generated_from_objective_id_min?: Maybe<Scalars['String']>;
  generated_from_objective_id_max?: Maybe<Scalars['String']>;
  prepared_eq?: Maybe<Scalars['String']>;
  prepared_eq_any?: Maybe<Scalars['String']>;
  prepared_eq_all?: Maybe<Scalars['String']>;
  prepared_not_eq?: Maybe<Scalars['String']>;
  prepared_not_eq_any?: Maybe<Scalars['String']>;
  prepared_not_eq_all?: Maybe<Scalars['String']>;
  prepared_matches?: Maybe<Scalars['String']>;
  prepared_matches_any?: Maybe<Scalars['String']>;
  prepared_matches_all?: Maybe<Scalars['String']>;
  prepared_does_not_match?: Maybe<Scalars['String']>;
  prepared_does_not_match_any?: Maybe<Scalars['String']>;
  prepared_does_not_match_all?: Maybe<Scalars['String']>;
  prepared_lt?: Maybe<Scalars['String']>;
  prepared_lt_any?: Maybe<Scalars['String']>;
  prepared_lt_all?: Maybe<Scalars['String']>;
  prepared_lteq?: Maybe<Scalars['String']>;
  prepared_lteq_any?: Maybe<Scalars['String']>;
  prepared_lteq_all?: Maybe<Scalars['String']>;
  prepared_gt?: Maybe<Scalars['String']>;
  prepared_gt_any?: Maybe<Scalars['String']>;
  prepared_gt_all?: Maybe<Scalars['String']>;
  prepared_gteq?: Maybe<Scalars['String']>;
  prepared_gteq_any?: Maybe<Scalars['String']>;
  prepared_gteq_all?: Maybe<Scalars['String']>;
  prepared_in?: Maybe<Scalars['String']>;
  prepared_in_any?: Maybe<Scalars['String']>;
  prepared_in_all?: Maybe<Scalars['String']>;
  prepared_not_in?: Maybe<Scalars['String']>;
  prepared_not_in_any?: Maybe<Scalars['String']>;
  prepared_not_in_all?: Maybe<Scalars['String']>;
  prepared_cont?: Maybe<Scalars['String']>;
  prepared_cont_any?: Maybe<Scalars['String']>;
  prepared_cont_all?: Maybe<Scalars['String']>;
  prepared_not_cont?: Maybe<Scalars['String']>;
  prepared_not_cont_any?: Maybe<Scalars['String']>;
  prepared_not_cont_all?: Maybe<Scalars['String']>;
  prepared_i_cont?: Maybe<Scalars['String']>;
  prepared_i_cont_any?: Maybe<Scalars['String']>;
  prepared_i_cont_all?: Maybe<Scalars['String']>;
  prepared_not_i_cont?: Maybe<Scalars['String']>;
  prepared_not_i_cont_any?: Maybe<Scalars['String']>;
  prepared_not_i_cont_all?: Maybe<Scalars['String']>;
  prepared_start?: Maybe<Scalars['String']>;
  prepared_start_any?: Maybe<Scalars['String']>;
  prepared_start_all?: Maybe<Scalars['String']>;
  prepared_not_start?: Maybe<Scalars['String']>;
  prepared_not_start_any?: Maybe<Scalars['String']>;
  prepared_not_start_all?: Maybe<Scalars['String']>;
  prepared_end?: Maybe<Scalars['String']>;
  prepared_end_any?: Maybe<Scalars['String']>;
  prepared_end_all?: Maybe<Scalars['String']>;
  prepared_not_end?: Maybe<Scalars['String']>;
  prepared_not_end_any?: Maybe<Scalars['String']>;
  prepared_not_end_all?: Maybe<Scalars['String']>;
  prepared_true?: Maybe<Scalars['String']>;
  prepared_not_true?: Maybe<Scalars['String']>;
  prepared_false?: Maybe<Scalars['String']>;
  prepared_not_false?: Maybe<Scalars['String']>;
  prepared_present?: Maybe<Scalars['String']>;
  prepared_blank?: Maybe<Scalars['String']>;
  prepared_null?: Maybe<Scalars['String']>;
  prepared_not_null?: Maybe<Scalars['String']>;
  prepared_count?: Maybe<Scalars['String']>;
  prepared_count_distinct?: Maybe<Scalars['String']>;
  prepared_sum?: Maybe<Scalars['String']>;
  prepared_avg?: Maybe<Scalars['String']>;
  prepared_min?: Maybe<Scalars['String']>;
  prepared_max?: Maybe<Scalars['String']>;
  published_at_eq?: Maybe<Scalars['String']>;
  published_at_eq_any?: Maybe<Scalars['String']>;
  published_at_eq_all?: Maybe<Scalars['String']>;
  published_at_not_eq?: Maybe<Scalars['String']>;
  published_at_not_eq_any?: Maybe<Scalars['String']>;
  published_at_not_eq_all?: Maybe<Scalars['String']>;
  published_at_matches?: Maybe<Scalars['String']>;
  published_at_matches_any?: Maybe<Scalars['String']>;
  published_at_matches_all?: Maybe<Scalars['String']>;
  published_at_does_not_match?: Maybe<Scalars['String']>;
  published_at_does_not_match_any?: Maybe<Scalars['String']>;
  published_at_does_not_match_all?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  published_at_lt_any?: Maybe<Scalars['String']>;
  published_at_lt_all?: Maybe<Scalars['String']>;
  published_at_lteq?: Maybe<Scalars['String']>;
  published_at_lteq_any?: Maybe<Scalars['String']>;
  published_at_lteq_all?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_gt_any?: Maybe<Scalars['String']>;
  published_at_gt_all?: Maybe<Scalars['String']>;
  published_at_gteq?: Maybe<Scalars['String']>;
  published_at_gteq_any?: Maybe<Scalars['String']>;
  published_at_gteq_all?: Maybe<Scalars['String']>;
  published_at_in?: Maybe<Scalars['String']>;
  published_at_in_any?: Maybe<Scalars['String']>;
  published_at_in_all?: Maybe<Scalars['String']>;
  published_at_not_in?: Maybe<Scalars['String']>;
  published_at_not_in_any?: Maybe<Scalars['String']>;
  published_at_not_in_all?: Maybe<Scalars['String']>;
  published_at_cont?: Maybe<Scalars['String']>;
  published_at_cont_any?: Maybe<Scalars['String']>;
  published_at_cont_all?: Maybe<Scalars['String']>;
  published_at_not_cont?: Maybe<Scalars['String']>;
  published_at_not_cont_any?: Maybe<Scalars['String']>;
  published_at_not_cont_all?: Maybe<Scalars['String']>;
  published_at_i_cont?: Maybe<Scalars['String']>;
  published_at_i_cont_any?: Maybe<Scalars['String']>;
  published_at_i_cont_all?: Maybe<Scalars['String']>;
  published_at_not_i_cont?: Maybe<Scalars['String']>;
  published_at_not_i_cont_any?: Maybe<Scalars['String']>;
  published_at_not_i_cont_all?: Maybe<Scalars['String']>;
  published_at_start?: Maybe<Scalars['String']>;
  published_at_start_any?: Maybe<Scalars['String']>;
  published_at_start_all?: Maybe<Scalars['String']>;
  published_at_not_start?: Maybe<Scalars['String']>;
  published_at_not_start_any?: Maybe<Scalars['String']>;
  published_at_not_start_all?: Maybe<Scalars['String']>;
  published_at_end?: Maybe<Scalars['String']>;
  published_at_end_any?: Maybe<Scalars['String']>;
  published_at_end_all?: Maybe<Scalars['String']>;
  published_at_not_end?: Maybe<Scalars['String']>;
  published_at_not_end_any?: Maybe<Scalars['String']>;
  published_at_not_end_all?: Maybe<Scalars['String']>;
  published_at_true?: Maybe<Scalars['String']>;
  published_at_not_true?: Maybe<Scalars['String']>;
  published_at_false?: Maybe<Scalars['String']>;
  published_at_not_false?: Maybe<Scalars['String']>;
  published_at_present?: Maybe<Scalars['String']>;
  published_at_blank?: Maybe<Scalars['String']>;
  published_at_null?: Maybe<Scalars['String']>;
  published_at_not_null?: Maybe<Scalars['String']>;
  published_at_count?: Maybe<Scalars['String']>;
  published_at_count_distinct?: Maybe<Scalars['String']>;
  published_at_sum?: Maybe<Scalars['String']>;
  published_at_avg?: Maybe<Scalars['String']>;
  published_at_min?: Maybe<Scalars['String']>;
  published_at_max?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type IOrganization = {
  __typename?: 'Organization';
  feedbackDays: Scalars['String'];
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  powerByDashboardId: Scalars['String'];
  settings: IOrganizationSettings;
  siderLogoLarge?: Maybe<Scalars['String']>;
  siderLogoSmall?: Maybe<Scalars['String']>;
  subdomain: Scalars['String'];
  theme: ITheme;
};

/** The connection type for Organization. */
export type IOrganizationConnection = {
  __typename?: 'OrganizationConnection';
  /** A list of edges. */
  edges: Array<IOrganizationEdge>;
  /** A list of nodes. */
  nodes: Array<IOrganization>;
  /** Information to aid in pagination */
  pageInfo: IExtendedPageInfo;
};

/** An edge in a connection. */
export type IOrganizationEdge = {
  __typename?: 'OrganizationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: IOrganization;
};

export type IOrganizationSettings = {
  __typename?: 'OrganizationSettings';
  announcementPermission?: Maybe<Array<Scalars['String']>>;
  approvalByAcquiescence?: Maybe<Scalars['Boolean']>;
  autoEnroll?: Maybe<Scalars['Boolean']>;
  defaultCheckedAutoEndOkr?: Maybe<Scalars['Boolean']>;
  engagementEnabled?: Maybe<Scalars['Boolean']>;
  engagementThreshold?: Maybe<Scalars['Int']>;
  managersCanScheduleOneOnOne?: Maybe<Scalars['Boolean']>;
  pinnedHabits?: Maybe<Array<Scalars['String']>>;
  suggestionManagers?: Maybe<Array<Scalars['String']>>;
  usersSelfOnboard?: Maybe<Scalars['Boolean']>;
};

/** Pagination for queries */
export type IPagination = {
  /** Page number */
  page: Scalars['Int'];
  /** Records per page */
  perPage: Scalars['Int'];
};

export type IPrompt = {
  __typename?: 'Prompt';
  id: Scalars['ID'];
  questionText?: Maybe<Scalars['String']>;
};

/** The query root of this schema */
export type IQuery = {
  __typename?: 'Query';
  /** Gets a list of accounts */
  accounts: Array<IAccount>;
  /** Get the activity feed */
  activities: IActivityConnection;
  /** Get all badges */
  badges: Array<IBadge>;
  /** Find a behavior by ID */
  behavior: IBehavior;
  /** Get a list of behaviors */
  behaviors: IBehaviorConnection;
  /** Get a list of objective categories */
  categories: Array<ICategory>;
  /** Get the current user's deck */
  currentAnswers: Array<IAnswer>;
  /** Get a list of current user's dashboard tokens */
  dashboardTokens: Array<IDashboardToken>;
  /** Get the current user's editable answers */
  editableAnswers: Array<IAnswer>;
  /** Find a habit by ID */
  habit: IHabit;
  /** Find a habit user by ID */
  habitUser: IHabitUser;
  /** Get a list of habits */
  habits: IHabitConnection;
  /** Get job status by job id */
  jobStatus?: Maybe<IJobStatus>;
  /** Get objective labels */
  labels: Array<ILabel>;
  /** get leaderboard */
  leaderboard: ILeaderboard;
  /** Get the action items for the current user */
  managerActionItems: Array<IManagerActionItem>;
  /** Get the manager dashboard for the current user's team */
  managerDashboard: IManagerDashboard;
  /** Get the manager dashboard for the current user's team */
  managerDashboardDemo: IManagerDashboardDemo;
  /** Find an objective by ID */
  objective: IObjective;
  /** Find objectives */
  objectives: IObjectiveConnection;
  /** Show objectives that require review */
  objectivesAwaitingReview: IObjectiveConnection;
  /** Find a one on one by ID */
  oneOnOne?: Maybe<IOneOnOne>;
  /** List all one on ones */
  oneOnOnes: IOneOnOneConnection;
  /** Get the current organization */
  organization: IOrganization;
  /** Get a list of organizations */
  organizations: IOrganizationConnection;
  /** Generate a presigned url for aws uploading */
  presignedUrl: Scalars['String'];
  /** Get the current user's profile */
  profile: IUser;
  /** Find a role by ID */
  role: IRole;
  /** Gets a list of roles */
  roles: IRoleConnection;
  /** Get the current user's suggestion box prompts */
  suggestionBoxPrompts: Array<ISuggestionBoxPrompt>;
  /** Find a user by a survey code */
  surveyCodeUser: ISurveyCodeResponse;
  /** Find a team by ID, or the current user's team */
  team: ITeam;
  /** Gets a list of teams */
  teams: ITeamConnection;
  /** Find a user by ID */
  user: IUser;
  /** Find a user's badges by user ID */
  userBadges: Array<IBadge>;
  /** Find a user by name */
  userSearch: IUserConnection;
  /** Gets a list of users */
  users: IUserConnection;
  /** Return the current organization's Workday account */
  workdayAccount?: Maybe<IWorkdayAccount>;
};


/** The query root of this schema */
export type IQueryAccountsArgs = {
  pagination?: Maybe<IPagination>;
  sort?: Maybe<ISort>;
  filter?: Maybe<IAccountRansack>;
};


/** The query root of this schema */
export type IQueryActivitiesArgs = {
  pagination?: Maybe<IPagination>;
  sort?: Maybe<ISort>;
  filter?: Maybe<IActivitiesFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type IQueryBehaviorArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type IQueryBehaviorsArgs = {
  pagination?: Maybe<IPagination>;
  sort?: Maybe<ISort>;
  filter?: Maybe<IBehaviorsFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type IQueryHabitArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type IQueryHabitUserArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type IQueryHabitsArgs = {
  pagination?: Maybe<IPagination>;
  sort?: Maybe<ISort>;
  filter?: Maybe<IHabitRansack>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type IQueryJobStatusArgs = {
  id: Scalars['String'];
};


/** The query root of this schema */
export type IQueryLeaderboardArgs = {
  full?: Maybe<Scalars['Boolean']>;
};


/** The query root of this schema */
export type IQueryManagerDashboardArgs = {
  id?: Maybe<Scalars['ID']>;
};


/** The query root of this schema */
export type IQueryObjectiveArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type IQueryObjectivesArgs = {
  pagination?: Maybe<IPagination>;
  sort?: Maybe<ISort>;
  filter?: Maybe<IObjectiveRansack>;
  ownerId?: Maybe<Scalars['String']>;
  status?: Maybe<IObjectiveStatus>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type IQueryObjectivesAwaitingReviewArgs = {
  pagination?: Maybe<IPagination>;
  sort?: Maybe<ISort>;
  filter?: Maybe<IObjectiveRansack>;
  ownerId?: Maybe<Scalars['String']>;
  status?: Maybe<IObjectiveStatus>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type IQueryOneOnOneArgs = {
  id: Scalars['ID'];
  firstRender: Scalars['Boolean'];
};


/** The query root of this schema */
export type IQueryOneOnOnesArgs = {
  pagination?: Maybe<IPagination>;
  sort?: Maybe<ISort>;
  filter?: Maybe<IOneOnOneFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type IQueryOrganizationsArgs = {
  pagination?: Maybe<IPagination>;
  sort?: Maybe<ISort>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type IQueryPresignedUrlArgs = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  bucket?: Maybe<Scalars['String']>;
  folder?: Maybe<Scalars['String']>;
};


/** The query root of this schema */
export type IQueryRoleArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type IQueryRolesArgs = {
  pagination?: Maybe<IPagination>;
  sort?: Maybe<ISort>;
  filter?: Maybe<IRolesFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type IQuerySurveyCodeUserArgs = {
  code: Scalars['String'];
};


/** The query root of this schema */
export type IQueryTeamArgs = {
  id?: Maybe<Scalars['ID']>;
};


/** The query root of this schema */
export type IQueryTeamsArgs = {
  pagination?: Maybe<IPagination>;
  sort?: Maybe<ISort>;
  filter?: Maybe<ITeamsFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type IQueryUserArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type IQueryUserBadgesArgs = {
  userId?: Maybe<Scalars['ID']>;
};


/** The query root of this schema */
export type IQueryUserSearchArgs = {
  name: Scalars['String'];
  status?: Maybe<IUserStatus>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type IQueryUsersArgs = {
  pagination?: Maybe<IPagination>;
  sort?: Maybe<ISort>;
  filter?: Maybe<IUserRansack>;
  search?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type IQuickPoll = {
  __typename?: 'QuickPoll';
  dontKnowText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  noText: Scalars['String'];
  text: Scalars['String'];
  yesText: Scalars['String'];
};

export type IRefreshToken = {
  __typename?: 'RefreshToken';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

/** Autogenerated input type of RefreshToken */
export type IRefreshTokenInput = {
  refreshToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type IRelationship = {
  __typename?: 'Relationship';
  habitUsers: Array<IHabitUser>;
  id: Scalars['ID'];
  inverseRelationship: IRelationship;
  relatedUser: IUser;
  relatingUser: IUser;
};

/** Objects that can respond to answers */
export type IRespondent = IUser;

export type IReview = {
  __typename?: 'Review';
  id: Scalars['ID'];
  score?: Maybe<Scalars['Int']>;
  user: IUser;
};

export type IRole = {
  __typename?: 'Role';
  habitIds?: Maybe<Array<Scalars['String']>>;
  habits?: Maybe<Array<IHabit>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

/** The connection type for Role. */
export type IRoleConnection = {
  __typename?: 'RoleConnection';
  /** A list of edges. */
  edges: Array<IRoleEdge>;
  /** A list of nodes. */
  nodes: Array<IRole>;
  /** Information to aid in pagination */
  pageInfo: IExtendedPageInfo;
};

/** An edge in a connection. */
export type IRoleEdge = {
  __typename?: 'RoleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: IRole;
};

export type IRolesFilter = {
  search?: Maybe<Scalars['String']>;
};

export type IScore = {
  __typename?: 'Score';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  mostRecent: Scalars['Boolean'];
  scoreable: IScoreable;
  value: Scalars['Int'];
};

/** Objects that can be scored */
export type IScoreable = IFeedbackQuestion | IHabitUser;

export enum IScoreDateRange {
  /** the last quarter */
  LastQuarter = 'LAST_QUARTER',
  /** the last half-year */
  Last_6Months = 'LAST_6_MONTHS',
  /** the last year */
  LastYear = 'LAST_YEAR',
  /** the last 2 years (max) */
  Last_2Years = 'LAST_2_YEARS'
}

/** Autogenerated input type of SendRhabitFeedback */
export type ISendRhabitFeedbackInput = {
  content: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SendRhabitFeedback */
export type ISendRhabitFeedbackPayload = {
  __typename?: 'SendRhabitFeedbackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  successful: Scalars['Boolean'];
};

export type IServiceAccount = {
  __typename?: 'ServiceAccount';
  email: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated input type of SetAvatarFromLinkedIn */
export type ISetAvatarFromLinkedInInput = {
  linkedinCode: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of SetNewAvatarPath */
export type ISetNewAvatarPathInput = {
  fileName: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Sorting for queries */
export type ISort = {
  /** Field to sort on */
  field?: Maybe<Scalars['String']>;
  /** Order to sort by */
  order?: Maybe<Scalars['String']>;
};

export type ISuggestion = {
  __typename?: 'Suggestion';
  content: Scalars['String'];
  followUp: Scalars['Boolean'];
  id: Scalars['ID'];
  user: IUser;
};

export type ISuggestionBoxPrompt = {
  __typename?: 'SuggestionBoxPrompt';
  discardedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type ISurveyCodeResponse = {
  __typename?: 'SurveyCodeResponse';
  organization: IOrganization;
  token: Scalars['String'];
  user: IUser;
};

export type ITalkingPoint = {
  __typename?: 'TalkingPoint';
  addressed: Scalars['Boolean'];
  comments: Array<IComment>;
  content: Scalars['String'];
  createdAt: Scalars['String'];
  future: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  oneOnOne: IOneOnOne;
  oneOnOneId: Scalars['ID'];
  position: Scalars['Int'];
  subjectId?: Maybe<Scalars['ID']>;
  subjectType?: Maybe<Scalars['String']>;
  summary: Scalars['Boolean'];
  updatedAt: Scalars['String'];
  user: IUser;
  userId: Scalars['ID'];
};

export type ITeam = {
  __typename?: 'Team';
  data: IManagerDashboard;
  id: Scalars['ID'];
  manager: IUser;
  managerActionItems: Array<IManagerActionItem>;
  managerId: Scalars['String'];
  name: Scalars['String'];
  userIds?: Maybe<Array<Scalars['ID']>>;
  users: Array<IUser>;
};

/** The connection type for Team. */
export type ITeamConnection = {
  __typename?: 'TeamConnection';
  /** A list of edges. */
  edges: Array<ITeamEdge>;
  /** A list of nodes. */
  nodes: Array<ITeam>;
  /** Information to aid in pagination */
  pageInfo: IExtendedPageInfo;
};

/** An edge in a connection. */
export type ITeamEdge = {
  __typename?: 'TeamEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ITeam;
};

/** filter options for teams */
export type ITeamsFilter = {
  search?: Maybe<Scalars['String']>;
};

export type ITheme = {
  __typename?: 'Theme';
  extraColor0: Scalars['String'];
  extraColor1: Scalars['String'];
  extraColor2: Scalars['String'];
  extraColor3: Scalars['String'];
  layoutHeaderBackground: Scalars['String'];
  layoutSiderBackground: Scalars['String'];
  menuDarkBg: Scalars['String'];
  menuDarkItemActiveBg: Scalars['String'];
  primaryColor: Scalars['String'];
  primaryColorDanger: Scalars['String'];
  primaryColorLite: Scalars['String'];
  processDefaultColor: Scalars['String'];
  secondaryColor: Scalars['String'];
};

export type ITipWidget = {
  __typename?: 'TipWidget';
  content: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export enum ITutorialName {
  HowToGiveFeedback = 'HOW_TO_GIVE_FEEDBACK',
  HowToSetupFeedbackNetwork = 'HOW_TO_SETUP_FEEDBACK_NETWORK',
  HowToInterpretFeedback = 'HOW_TO_INTERPRET_FEEDBACK',
  HowToSetupAccount = 'HOW_TO_SETUP_ACCOUNT',
  FeedbackLocked = 'FEEDBACK_LOCKED',
  ProductTour = 'PRODUCT_TOUR'
}

/** Autogenerated input type of UpdateAnswer */
export type IUpdateAnswerInput = {
  response: Scalars['String'];
  answerId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateBehavior */
export type IUpdateBehaviorInput = {
  id: Scalars['ID'];
  anchor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  timeFrame?: Maybe<Scalars['String']>;
  habitId?: Maybe<Scalars['ID']>;
  frequency?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateCategory */
export type IUpdateCategoryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateHabit */
export type IUpdateHabitInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employeeType?: Maybe<Scalars['String']>;
  habitType?: Maybe<Scalars['Int']>;
  benchmark?: Maybe<Scalars['Float']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateObjective */
export type IUpdateObjectiveInput = {
  status?: Maybe<IObjectiveStatus>;
  objectiveId: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['ID']>;
  stakeholderIds?: Maybe<Array<Scalars['ID']>>;
  labelIds?: Maybe<Array<Scalars['ID']>>;
  autoComplete?: Maybe<Scalars['Boolean']>;
  keyResults?: Maybe<Array<Scalars['String']>>;
  private?: Maybe<Scalars['Boolean']>;
  checkInInterval?: Maybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateOneOnOne */
export type IUpdateOneOnOneInput = {
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  prepared?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateRaters */
export type IUpdateRatersInput = {
  habitId?: Maybe<Scalars['ID']>;
  habitUserId?: Maybe<Scalars['ID']>;
  raterIds: Array<Scalars['ID']>;
  userId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateRelationship */
export type IUpdateRelationshipInput = {
  relationshipId: Scalars['ID'];
  habitUserIds: Array<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateReview */
export type IUpdateReviewInput = {
  score?: Maybe<Scalars['Int']>;
  context?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateRole */
export type IUpdateRoleInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  habitIds?: Maybe<Array<Scalars['ID']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateTalkingPoint */
export type IUpdateTalkingPointInput = {
  id: Scalars['ID'];
  position?: Maybe<Scalars['Int']>;
  addressed?: Maybe<Scalars['Boolean']>;
  hidden?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateUserHabits */
export type IUpdateUserHabitsInput = {
  /** Array of habit ids to be assigned to the user */
  added: Array<Scalars['String']>;
  /** Array of habit ids to be removed from the user */
  removed: Array<Scalars['String']>;
  userId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateUser */
export type IUpdateUserInput = {
  firstName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  admin?: Maybe<Scalars['Boolean']>;
  manager?: Maybe<Scalars['Boolean']>;
  roleId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  mailTime?: Maybe<Scalars['String']>;
  feedbackDelivery?: Maybe<Array<Scalars['String']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateWorkdayAccount */
export type IUpdateWorkdayAccountInput = {
  id: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  importTeams?: Maybe<Scalars['Boolean']>;
  dailySync?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type IUser = {
  __typename?: 'User';
  accountId: Scalars['String'];
  accountType: Scalars['String'];
  active: Scalars['Boolean'];
  admin: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  badges: Array<IBadge>;
  canConfigure: Scalars['Boolean'];
  completedArticulateLessons: Array<IArticulateLessonCompletion>;
  currentObjectives: Array<IObjective>;
  currentQuarterCarrots: Scalars['Int'];
  editableResponses: Array<IAnswer>;
  email: Scalars['String'];
  features: Array<Scalars['String']>;
  feedbackStreak: Scalars['Int'];
  firstName: Scalars['String'];
  gender: Scalars['String'];
  group?: Maybe<ITeam>;
  habitUsers: Array<IHabitUser>;
  habits: Array<IHabit>;
  hasResponded: Scalars['Boolean'];
  highfiveStats: IHighfiveStats;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  latestResponse?: Maybe<Scalars['String']>;
  mailTime: Scalars['String'];
  managedActionItems: Array<IManagerActionItem>;
  manager: Scalars['Boolean'];
  name: Scalars['String'];
  networkHealth: Scalars['JSON'];
  objectives: Array<IObjective>;
  onVacation: Scalars['Boolean'];
  onboarded: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  preferences: IUserPreferences;
  rank?: Maybe<Scalars['Int']>;
  raters: Array<IUser>;
  relationships: Array<IRelationship>;
  responseRate?: Maybe<Scalars['Float']>;
  roleId?: Maybe<Scalars['String']>;
  team?: Maybe<ITeam>;
  teamId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  upcomingVacations: Array<IVacation>;
  userLevel: IUserLevel;
  vacations: Array<IVacation>;
  viewedTutorials: Array<ITutorialName>;
  workItems: Array<IWorkItem>;
};

/** The connection type for User. */
export type IUserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges: Array<IUserEdge>;
  /** A list of nodes. */
  nodes: Array<IUser>;
  /** Information to aid in pagination */
  pageInfo: IExtendedPageInfo;
};

/** An edge in a connection. */
export type IUserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: IUser;
};

export enum IUserLevel {
  /** user is an admin */
  Admin = 'ADMIN',
  /** user is a manager */
  Manager = 'MANAGER',
  /** user is a user */
  User = 'USER'
}

export type IUserPreferences = {
  __typename?: 'UserPreferences';
  feedbackDelivery?: Maybe<Scalars['String']>;
  initialConfig?: Maybe<Scalars['Boolean']>;
  linkedInDismissed?: Maybe<Scalars['Boolean']>;
  maintenanceTutorialViewed?: Maybe<Scalars['Boolean']>;
  onboardingTutorialViewed?: Maybe<Scalars['Boolean']>;
  passwordSet?: Maybe<Scalars['Boolean']>;
  selfOnboard?: Maybe<Scalars['Boolean']>;
};

export type IUserRansack = {
  id_eq?: Maybe<Scalars['String']>;
  id_eq_any?: Maybe<Scalars['String']>;
  id_eq_all?: Maybe<Scalars['String']>;
  id_not_eq?: Maybe<Scalars['String']>;
  id_not_eq_any?: Maybe<Scalars['String']>;
  id_not_eq_all?: Maybe<Scalars['String']>;
  id_matches?: Maybe<Scalars['String']>;
  id_matches_any?: Maybe<Scalars['String']>;
  id_matches_all?: Maybe<Scalars['String']>;
  id_does_not_match?: Maybe<Scalars['String']>;
  id_does_not_match_any?: Maybe<Scalars['String']>;
  id_does_not_match_all?: Maybe<Scalars['String']>;
  id_lt?: Maybe<Scalars['String']>;
  id_lt_any?: Maybe<Scalars['String']>;
  id_lt_all?: Maybe<Scalars['String']>;
  id_lteq?: Maybe<Scalars['String']>;
  id_lteq_any?: Maybe<Scalars['String']>;
  id_lteq_all?: Maybe<Scalars['String']>;
  id_gt?: Maybe<Scalars['String']>;
  id_gt_any?: Maybe<Scalars['String']>;
  id_gt_all?: Maybe<Scalars['String']>;
  id_gteq?: Maybe<Scalars['String']>;
  id_gteq_any?: Maybe<Scalars['String']>;
  id_gteq_all?: Maybe<Scalars['String']>;
  id_in?: Maybe<Scalars['String']>;
  id_in_any?: Maybe<Scalars['String']>;
  id_in_all?: Maybe<Scalars['String']>;
  id_not_in?: Maybe<Scalars['String']>;
  id_not_in_any?: Maybe<Scalars['String']>;
  id_not_in_all?: Maybe<Scalars['String']>;
  id_cont?: Maybe<Scalars['String']>;
  id_cont_any?: Maybe<Scalars['String']>;
  id_cont_all?: Maybe<Scalars['String']>;
  id_not_cont?: Maybe<Scalars['String']>;
  id_not_cont_any?: Maybe<Scalars['String']>;
  id_not_cont_all?: Maybe<Scalars['String']>;
  id_i_cont?: Maybe<Scalars['String']>;
  id_i_cont_any?: Maybe<Scalars['String']>;
  id_i_cont_all?: Maybe<Scalars['String']>;
  id_not_i_cont?: Maybe<Scalars['String']>;
  id_not_i_cont_any?: Maybe<Scalars['String']>;
  id_not_i_cont_all?: Maybe<Scalars['String']>;
  id_start?: Maybe<Scalars['String']>;
  id_start_any?: Maybe<Scalars['String']>;
  id_start_all?: Maybe<Scalars['String']>;
  id_not_start?: Maybe<Scalars['String']>;
  id_not_start_any?: Maybe<Scalars['String']>;
  id_not_start_all?: Maybe<Scalars['String']>;
  id_end?: Maybe<Scalars['String']>;
  id_end_any?: Maybe<Scalars['String']>;
  id_end_all?: Maybe<Scalars['String']>;
  id_not_end?: Maybe<Scalars['String']>;
  id_not_end_any?: Maybe<Scalars['String']>;
  id_not_end_all?: Maybe<Scalars['String']>;
  id_true?: Maybe<Scalars['String']>;
  id_not_true?: Maybe<Scalars['String']>;
  id_false?: Maybe<Scalars['String']>;
  id_not_false?: Maybe<Scalars['String']>;
  id_present?: Maybe<Scalars['String']>;
  id_blank?: Maybe<Scalars['String']>;
  id_null?: Maybe<Scalars['String']>;
  id_not_null?: Maybe<Scalars['String']>;
  id_count?: Maybe<Scalars['String']>;
  id_count_distinct?: Maybe<Scalars['String']>;
  id_sum?: Maybe<Scalars['String']>;
  id_avg?: Maybe<Scalars['String']>;
  id_min?: Maybe<Scalars['String']>;
  id_max?: Maybe<Scalars['String']>;
  first_name_eq?: Maybe<Scalars['String']>;
  first_name_eq_any?: Maybe<Scalars['String']>;
  first_name_eq_all?: Maybe<Scalars['String']>;
  first_name_not_eq?: Maybe<Scalars['String']>;
  first_name_not_eq_any?: Maybe<Scalars['String']>;
  first_name_not_eq_all?: Maybe<Scalars['String']>;
  first_name_matches?: Maybe<Scalars['String']>;
  first_name_matches_any?: Maybe<Scalars['String']>;
  first_name_matches_all?: Maybe<Scalars['String']>;
  first_name_does_not_match?: Maybe<Scalars['String']>;
  first_name_does_not_match_any?: Maybe<Scalars['String']>;
  first_name_does_not_match_all?: Maybe<Scalars['String']>;
  first_name_lt?: Maybe<Scalars['String']>;
  first_name_lt_any?: Maybe<Scalars['String']>;
  first_name_lt_all?: Maybe<Scalars['String']>;
  first_name_lteq?: Maybe<Scalars['String']>;
  first_name_lteq_any?: Maybe<Scalars['String']>;
  first_name_lteq_all?: Maybe<Scalars['String']>;
  first_name_gt?: Maybe<Scalars['String']>;
  first_name_gt_any?: Maybe<Scalars['String']>;
  first_name_gt_all?: Maybe<Scalars['String']>;
  first_name_gteq?: Maybe<Scalars['String']>;
  first_name_gteq_any?: Maybe<Scalars['String']>;
  first_name_gteq_all?: Maybe<Scalars['String']>;
  first_name_in?: Maybe<Scalars['String']>;
  first_name_in_any?: Maybe<Scalars['String']>;
  first_name_in_all?: Maybe<Scalars['String']>;
  first_name_not_in?: Maybe<Scalars['String']>;
  first_name_not_in_any?: Maybe<Scalars['String']>;
  first_name_not_in_all?: Maybe<Scalars['String']>;
  first_name_cont?: Maybe<Scalars['String']>;
  first_name_cont_any?: Maybe<Scalars['String']>;
  first_name_cont_all?: Maybe<Scalars['String']>;
  first_name_not_cont?: Maybe<Scalars['String']>;
  first_name_not_cont_any?: Maybe<Scalars['String']>;
  first_name_not_cont_all?: Maybe<Scalars['String']>;
  first_name_i_cont?: Maybe<Scalars['String']>;
  first_name_i_cont_any?: Maybe<Scalars['String']>;
  first_name_i_cont_all?: Maybe<Scalars['String']>;
  first_name_not_i_cont?: Maybe<Scalars['String']>;
  first_name_not_i_cont_any?: Maybe<Scalars['String']>;
  first_name_not_i_cont_all?: Maybe<Scalars['String']>;
  first_name_start?: Maybe<Scalars['String']>;
  first_name_start_any?: Maybe<Scalars['String']>;
  first_name_start_all?: Maybe<Scalars['String']>;
  first_name_not_start?: Maybe<Scalars['String']>;
  first_name_not_start_any?: Maybe<Scalars['String']>;
  first_name_not_start_all?: Maybe<Scalars['String']>;
  first_name_end?: Maybe<Scalars['String']>;
  first_name_end_any?: Maybe<Scalars['String']>;
  first_name_end_all?: Maybe<Scalars['String']>;
  first_name_not_end?: Maybe<Scalars['String']>;
  first_name_not_end_any?: Maybe<Scalars['String']>;
  first_name_not_end_all?: Maybe<Scalars['String']>;
  first_name_true?: Maybe<Scalars['String']>;
  first_name_not_true?: Maybe<Scalars['String']>;
  first_name_false?: Maybe<Scalars['String']>;
  first_name_not_false?: Maybe<Scalars['String']>;
  first_name_present?: Maybe<Scalars['String']>;
  first_name_blank?: Maybe<Scalars['String']>;
  first_name_null?: Maybe<Scalars['String']>;
  first_name_not_null?: Maybe<Scalars['String']>;
  first_name_count?: Maybe<Scalars['String']>;
  first_name_count_distinct?: Maybe<Scalars['String']>;
  first_name_sum?: Maybe<Scalars['String']>;
  first_name_avg?: Maybe<Scalars['String']>;
  first_name_min?: Maybe<Scalars['String']>;
  first_name_max?: Maybe<Scalars['String']>;
  last_name_eq?: Maybe<Scalars['String']>;
  last_name_eq_any?: Maybe<Scalars['String']>;
  last_name_eq_all?: Maybe<Scalars['String']>;
  last_name_not_eq?: Maybe<Scalars['String']>;
  last_name_not_eq_any?: Maybe<Scalars['String']>;
  last_name_not_eq_all?: Maybe<Scalars['String']>;
  last_name_matches?: Maybe<Scalars['String']>;
  last_name_matches_any?: Maybe<Scalars['String']>;
  last_name_matches_all?: Maybe<Scalars['String']>;
  last_name_does_not_match?: Maybe<Scalars['String']>;
  last_name_does_not_match_any?: Maybe<Scalars['String']>;
  last_name_does_not_match_all?: Maybe<Scalars['String']>;
  last_name_lt?: Maybe<Scalars['String']>;
  last_name_lt_any?: Maybe<Scalars['String']>;
  last_name_lt_all?: Maybe<Scalars['String']>;
  last_name_lteq?: Maybe<Scalars['String']>;
  last_name_lteq_any?: Maybe<Scalars['String']>;
  last_name_lteq_all?: Maybe<Scalars['String']>;
  last_name_gt?: Maybe<Scalars['String']>;
  last_name_gt_any?: Maybe<Scalars['String']>;
  last_name_gt_all?: Maybe<Scalars['String']>;
  last_name_gteq?: Maybe<Scalars['String']>;
  last_name_gteq_any?: Maybe<Scalars['String']>;
  last_name_gteq_all?: Maybe<Scalars['String']>;
  last_name_in?: Maybe<Scalars['String']>;
  last_name_in_any?: Maybe<Scalars['String']>;
  last_name_in_all?: Maybe<Scalars['String']>;
  last_name_not_in?: Maybe<Scalars['String']>;
  last_name_not_in_any?: Maybe<Scalars['String']>;
  last_name_not_in_all?: Maybe<Scalars['String']>;
  last_name_cont?: Maybe<Scalars['String']>;
  last_name_cont_any?: Maybe<Scalars['String']>;
  last_name_cont_all?: Maybe<Scalars['String']>;
  last_name_not_cont?: Maybe<Scalars['String']>;
  last_name_not_cont_any?: Maybe<Scalars['String']>;
  last_name_not_cont_all?: Maybe<Scalars['String']>;
  last_name_i_cont?: Maybe<Scalars['String']>;
  last_name_i_cont_any?: Maybe<Scalars['String']>;
  last_name_i_cont_all?: Maybe<Scalars['String']>;
  last_name_not_i_cont?: Maybe<Scalars['String']>;
  last_name_not_i_cont_any?: Maybe<Scalars['String']>;
  last_name_not_i_cont_all?: Maybe<Scalars['String']>;
  last_name_start?: Maybe<Scalars['String']>;
  last_name_start_any?: Maybe<Scalars['String']>;
  last_name_start_all?: Maybe<Scalars['String']>;
  last_name_not_start?: Maybe<Scalars['String']>;
  last_name_not_start_any?: Maybe<Scalars['String']>;
  last_name_not_start_all?: Maybe<Scalars['String']>;
  last_name_end?: Maybe<Scalars['String']>;
  last_name_end_any?: Maybe<Scalars['String']>;
  last_name_end_all?: Maybe<Scalars['String']>;
  last_name_not_end?: Maybe<Scalars['String']>;
  last_name_not_end_any?: Maybe<Scalars['String']>;
  last_name_not_end_all?: Maybe<Scalars['String']>;
  last_name_true?: Maybe<Scalars['String']>;
  last_name_not_true?: Maybe<Scalars['String']>;
  last_name_false?: Maybe<Scalars['String']>;
  last_name_not_false?: Maybe<Scalars['String']>;
  last_name_present?: Maybe<Scalars['String']>;
  last_name_blank?: Maybe<Scalars['String']>;
  last_name_null?: Maybe<Scalars['String']>;
  last_name_not_null?: Maybe<Scalars['String']>;
  last_name_count?: Maybe<Scalars['String']>;
  last_name_count_distinct?: Maybe<Scalars['String']>;
  last_name_sum?: Maybe<Scalars['String']>;
  last_name_avg?: Maybe<Scalars['String']>;
  last_name_min?: Maybe<Scalars['String']>;
  last_name_max?: Maybe<Scalars['String']>;
  email_eq?: Maybe<Scalars['String']>;
  email_eq_any?: Maybe<Scalars['String']>;
  email_eq_all?: Maybe<Scalars['String']>;
  email_not_eq?: Maybe<Scalars['String']>;
  email_not_eq_any?: Maybe<Scalars['String']>;
  email_not_eq_all?: Maybe<Scalars['String']>;
  email_matches?: Maybe<Scalars['String']>;
  email_matches_any?: Maybe<Scalars['String']>;
  email_matches_all?: Maybe<Scalars['String']>;
  email_does_not_match?: Maybe<Scalars['String']>;
  email_does_not_match_any?: Maybe<Scalars['String']>;
  email_does_not_match_all?: Maybe<Scalars['String']>;
  email_lt?: Maybe<Scalars['String']>;
  email_lt_any?: Maybe<Scalars['String']>;
  email_lt_all?: Maybe<Scalars['String']>;
  email_lteq?: Maybe<Scalars['String']>;
  email_lteq_any?: Maybe<Scalars['String']>;
  email_lteq_all?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gt_any?: Maybe<Scalars['String']>;
  email_gt_all?: Maybe<Scalars['String']>;
  email_gteq?: Maybe<Scalars['String']>;
  email_gteq_any?: Maybe<Scalars['String']>;
  email_gteq_all?: Maybe<Scalars['String']>;
  email_in?: Maybe<Scalars['String']>;
  email_in_any?: Maybe<Scalars['String']>;
  email_in_all?: Maybe<Scalars['String']>;
  email_not_in?: Maybe<Scalars['String']>;
  email_not_in_any?: Maybe<Scalars['String']>;
  email_not_in_all?: Maybe<Scalars['String']>;
  email_cont?: Maybe<Scalars['String']>;
  email_cont_any?: Maybe<Scalars['String']>;
  email_cont_all?: Maybe<Scalars['String']>;
  email_not_cont?: Maybe<Scalars['String']>;
  email_not_cont_any?: Maybe<Scalars['String']>;
  email_not_cont_all?: Maybe<Scalars['String']>;
  email_i_cont?: Maybe<Scalars['String']>;
  email_i_cont_any?: Maybe<Scalars['String']>;
  email_i_cont_all?: Maybe<Scalars['String']>;
  email_not_i_cont?: Maybe<Scalars['String']>;
  email_not_i_cont_any?: Maybe<Scalars['String']>;
  email_not_i_cont_all?: Maybe<Scalars['String']>;
  email_start?: Maybe<Scalars['String']>;
  email_start_any?: Maybe<Scalars['String']>;
  email_start_all?: Maybe<Scalars['String']>;
  email_not_start?: Maybe<Scalars['String']>;
  email_not_start_any?: Maybe<Scalars['String']>;
  email_not_start_all?: Maybe<Scalars['String']>;
  email_end?: Maybe<Scalars['String']>;
  email_end_any?: Maybe<Scalars['String']>;
  email_end_all?: Maybe<Scalars['String']>;
  email_not_end?: Maybe<Scalars['String']>;
  email_not_end_any?: Maybe<Scalars['String']>;
  email_not_end_all?: Maybe<Scalars['String']>;
  email_true?: Maybe<Scalars['String']>;
  email_not_true?: Maybe<Scalars['String']>;
  email_false?: Maybe<Scalars['String']>;
  email_not_false?: Maybe<Scalars['String']>;
  email_present?: Maybe<Scalars['String']>;
  email_blank?: Maybe<Scalars['String']>;
  email_null?: Maybe<Scalars['String']>;
  email_not_null?: Maybe<Scalars['String']>;
  email_count?: Maybe<Scalars['String']>;
  email_count_distinct?: Maybe<Scalars['String']>;
  email_sum?: Maybe<Scalars['String']>;
  email_avg?: Maybe<Scalars['String']>;
  email_min?: Maybe<Scalars['String']>;
  email_max?: Maybe<Scalars['String']>;
  password_digest_eq?: Maybe<Scalars['String']>;
  password_digest_eq_any?: Maybe<Scalars['String']>;
  password_digest_eq_all?: Maybe<Scalars['String']>;
  password_digest_not_eq?: Maybe<Scalars['String']>;
  password_digest_not_eq_any?: Maybe<Scalars['String']>;
  password_digest_not_eq_all?: Maybe<Scalars['String']>;
  password_digest_matches?: Maybe<Scalars['String']>;
  password_digest_matches_any?: Maybe<Scalars['String']>;
  password_digest_matches_all?: Maybe<Scalars['String']>;
  password_digest_does_not_match?: Maybe<Scalars['String']>;
  password_digest_does_not_match_any?: Maybe<Scalars['String']>;
  password_digest_does_not_match_all?: Maybe<Scalars['String']>;
  password_digest_lt?: Maybe<Scalars['String']>;
  password_digest_lt_any?: Maybe<Scalars['String']>;
  password_digest_lt_all?: Maybe<Scalars['String']>;
  password_digest_lteq?: Maybe<Scalars['String']>;
  password_digest_lteq_any?: Maybe<Scalars['String']>;
  password_digest_lteq_all?: Maybe<Scalars['String']>;
  password_digest_gt?: Maybe<Scalars['String']>;
  password_digest_gt_any?: Maybe<Scalars['String']>;
  password_digest_gt_all?: Maybe<Scalars['String']>;
  password_digest_gteq?: Maybe<Scalars['String']>;
  password_digest_gteq_any?: Maybe<Scalars['String']>;
  password_digest_gteq_all?: Maybe<Scalars['String']>;
  password_digest_in?: Maybe<Scalars['String']>;
  password_digest_in_any?: Maybe<Scalars['String']>;
  password_digest_in_all?: Maybe<Scalars['String']>;
  password_digest_not_in?: Maybe<Scalars['String']>;
  password_digest_not_in_any?: Maybe<Scalars['String']>;
  password_digest_not_in_all?: Maybe<Scalars['String']>;
  password_digest_cont?: Maybe<Scalars['String']>;
  password_digest_cont_any?: Maybe<Scalars['String']>;
  password_digest_cont_all?: Maybe<Scalars['String']>;
  password_digest_not_cont?: Maybe<Scalars['String']>;
  password_digest_not_cont_any?: Maybe<Scalars['String']>;
  password_digest_not_cont_all?: Maybe<Scalars['String']>;
  password_digest_i_cont?: Maybe<Scalars['String']>;
  password_digest_i_cont_any?: Maybe<Scalars['String']>;
  password_digest_i_cont_all?: Maybe<Scalars['String']>;
  password_digest_not_i_cont?: Maybe<Scalars['String']>;
  password_digest_not_i_cont_any?: Maybe<Scalars['String']>;
  password_digest_not_i_cont_all?: Maybe<Scalars['String']>;
  password_digest_start?: Maybe<Scalars['String']>;
  password_digest_start_any?: Maybe<Scalars['String']>;
  password_digest_start_all?: Maybe<Scalars['String']>;
  password_digest_not_start?: Maybe<Scalars['String']>;
  password_digest_not_start_any?: Maybe<Scalars['String']>;
  password_digest_not_start_all?: Maybe<Scalars['String']>;
  password_digest_end?: Maybe<Scalars['String']>;
  password_digest_end_any?: Maybe<Scalars['String']>;
  password_digest_end_all?: Maybe<Scalars['String']>;
  password_digest_not_end?: Maybe<Scalars['String']>;
  password_digest_not_end_any?: Maybe<Scalars['String']>;
  password_digest_not_end_all?: Maybe<Scalars['String']>;
  password_digest_true?: Maybe<Scalars['String']>;
  password_digest_not_true?: Maybe<Scalars['String']>;
  password_digest_false?: Maybe<Scalars['String']>;
  password_digest_not_false?: Maybe<Scalars['String']>;
  password_digest_present?: Maybe<Scalars['String']>;
  password_digest_blank?: Maybe<Scalars['String']>;
  password_digest_null?: Maybe<Scalars['String']>;
  password_digest_not_null?: Maybe<Scalars['String']>;
  password_digest_count?: Maybe<Scalars['String']>;
  password_digest_count_distinct?: Maybe<Scalars['String']>;
  password_digest_sum?: Maybe<Scalars['String']>;
  password_digest_avg?: Maybe<Scalars['String']>;
  password_digest_min?: Maybe<Scalars['String']>;
  password_digest_max?: Maybe<Scalars['String']>;
  avatar_eq?: Maybe<Scalars['String']>;
  avatar_eq_any?: Maybe<Scalars['String']>;
  avatar_eq_all?: Maybe<Scalars['String']>;
  avatar_not_eq?: Maybe<Scalars['String']>;
  avatar_not_eq_any?: Maybe<Scalars['String']>;
  avatar_not_eq_all?: Maybe<Scalars['String']>;
  avatar_matches?: Maybe<Scalars['String']>;
  avatar_matches_any?: Maybe<Scalars['String']>;
  avatar_matches_all?: Maybe<Scalars['String']>;
  avatar_does_not_match?: Maybe<Scalars['String']>;
  avatar_does_not_match_any?: Maybe<Scalars['String']>;
  avatar_does_not_match_all?: Maybe<Scalars['String']>;
  avatar_lt?: Maybe<Scalars['String']>;
  avatar_lt_any?: Maybe<Scalars['String']>;
  avatar_lt_all?: Maybe<Scalars['String']>;
  avatar_lteq?: Maybe<Scalars['String']>;
  avatar_lteq_any?: Maybe<Scalars['String']>;
  avatar_lteq_all?: Maybe<Scalars['String']>;
  avatar_gt?: Maybe<Scalars['String']>;
  avatar_gt_any?: Maybe<Scalars['String']>;
  avatar_gt_all?: Maybe<Scalars['String']>;
  avatar_gteq?: Maybe<Scalars['String']>;
  avatar_gteq_any?: Maybe<Scalars['String']>;
  avatar_gteq_all?: Maybe<Scalars['String']>;
  avatar_in?: Maybe<Scalars['String']>;
  avatar_in_any?: Maybe<Scalars['String']>;
  avatar_in_all?: Maybe<Scalars['String']>;
  avatar_not_in?: Maybe<Scalars['String']>;
  avatar_not_in_any?: Maybe<Scalars['String']>;
  avatar_not_in_all?: Maybe<Scalars['String']>;
  avatar_cont?: Maybe<Scalars['String']>;
  avatar_cont_any?: Maybe<Scalars['String']>;
  avatar_cont_all?: Maybe<Scalars['String']>;
  avatar_not_cont?: Maybe<Scalars['String']>;
  avatar_not_cont_any?: Maybe<Scalars['String']>;
  avatar_not_cont_all?: Maybe<Scalars['String']>;
  avatar_i_cont?: Maybe<Scalars['String']>;
  avatar_i_cont_any?: Maybe<Scalars['String']>;
  avatar_i_cont_all?: Maybe<Scalars['String']>;
  avatar_not_i_cont?: Maybe<Scalars['String']>;
  avatar_not_i_cont_any?: Maybe<Scalars['String']>;
  avatar_not_i_cont_all?: Maybe<Scalars['String']>;
  avatar_start?: Maybe<Scalars['String']>;
  avatar_start_any?: Maybe<Scalars['String']>;
  avatar_start_all?: Maybe<Scalars['String']>;
  avatar_not_start?: Maybe<Scalars['String']>;
  avatar_not_start_any?: Maybe<Scalars['String']>;
  avatar_not_start_all?: Maybe<Scalars['String']>;
  avatar_end?: Maybe<Scalars['String']>;
  avatar_end_any?: Maybe<Scalars['String']>;
  avatar_end_all?: Maybe<Scalars['String']>;
  avatar_not_end?: Maybe<Scalars['String']>;
  avatar_not_end_any?: Maybe<Scalars['String']>;
  avatar_not_end_all?: Maybe<Scalars['String']>;
  avatar_true?: Maybe<Scalars['String']>;
  avatar_not_true?: Maybe<Scalars['String']>;
  avatar_false?: Maybe<Scalars['String']>;
  avatar_not_false?: Maybe<Scalars['String']>;
  avatar_present?: Maybe<Scalars['String']>;
  avatar_blank?: Maybe<Scalars['String']>;
  avatar_null?: Maybe<Scalars['String']>;
  avatar_not_null?: Maybe<Scalars['String']>;
  avatar_count?: Maybe<Scalars['String']>;
  avatar_count_distinct?: Maybe<Scalars['String']>;
  avatar_sum?: Maybe<Scalars['String']>;
  avatar_avg?: Maybe<Scalars['String']>;
  avatar_min?: Maybe<Scalars['String']>;
  avatar_max?: Maybe<Scalars['String']>;
  admin_eq?: Maybe<Scalars['String']>;
  admin_eq_any?: Maybe<Scalars['String']>;
  admin_eq_all?: Maybe<Scalars['String']>;
  admin_not_eq?: Maybe<Scalars['String']>;
  admin_not_eq_any?: Maybe<Scalars['String']>;
  admin_not_eq_all?: Maybe<Scalars['String']>;
  admin_matches?: Maybe<Scalars['String']>;
  admin_matches_any?: Maybe<Scalars['String']>;
  admin_matches_all?: Maybe<Scalars['String']>;
  admin_does_not_match?: Maybe<Scalars['String']>;
  admin_does_not_match_any?: Maybe<Scalars['String']>;
  admin_does_not_match_all?: Maybe<Scalars['String']>;
  admin_lt?: Maybe<Scalars['String']>;
  admin_lt_any?: Maybe<Scalars['String']>;
  admin_lt_all?: Maybe<Scalars['String']>;
  admin_lteq?: Maybe<Scalars['String']>;
  admin_lteq_any?: Maybe<Scalars['String']>;
  admin_lteq_all?: Maybe<Scalars['String']>;
  admin_gt?: Maybe<Scalars['String']>;
  admin_gt_any?: Maybe<Scalars['String']>;
  admin_gt_all?: Maybe<Scalars['String']>;
  admin_gteq?: Maybe<Scalars['String']>;
  admin_gteq_any?: Maybe<Scalars['String']>;
  admin_gteq_all?: Maybe<Scalars['String']>;
  admin_in?: Maybe<Scalars['String']>;
  admin_in_any?: Maybe<Scalars['String']>;
  admin_in_all?: Maybe<Scalars['String']>;
  admin_not_in?: Maybe<Scalars['String']>;
  admin_not_in_any?: Maybe<Scalars['String']>;
  admin_not_in_all?: Maybe<Scalars['String']>;
  admin_cont?: Maybe<Scalars['String']>;
  admin_cont_any?: Maybe<Scalars['String']>;
  admin_cont_all?: Maybe<Scalars['String']>;
  admin_not_cont?: Maybe<Scalars['String']>;
  admin_not_cont_any?: Maybe<Scalars['String']>;
  admin_not_cont_all?: Maybe<Scalars['String']>;
  admin_i_cont?: Maybe<Scalars['String']>;
  admin_i_cont_any?: Maybe<Scalars['String']>;
  admin_i_cont_all?: Maybe<Scalars['String']>;
  admin_not_i_cont?: Maybe<Scalars['String']>;
  admin_not_i_cont_any?: Maybe<Scalars['String']>;
  admin_not_i_cont_all?: Maybe<Scalars['String']>;
  admin_start?: Maybe<Scalars['String']>;
  admin_start_any?: Maybe<Scalars['String']>;
  admin_start_all?: Maybe<Scalars['String']>;
  admin_not_start?: Maybe<Scalars['String']>;
  admin_not_start_any?: Maybe<Scalars['String']>;
  admin_not_start_all?: Maybe<Scalars['String']>;
  admin_end?: Maybe<Scalars['String']>;
  admin_end_any?: Maybe<Scalars['String']>;
  admin_end_all?: Maybe<Scalars['String']>;
  admin_not_end?: Maybe<Scalars['String']>;
  admin_not_end_any?: Maybe<Scalars['String']>;
  admin_not_end_all?: Maybe<Scalars['String']>;
  admin_true?: Maybe<Scalars['String']>;
  admin_not_true?: Maybe<Scalars['String']>;
  admin_false?: Maybe<Scalars['String']>;
  admin_not_false?: Maybe<Scalars['String']>;
  admin_present?: Maybe<Scalars['String']>;
  admin_blank?: Maybe<Scalars['String']>;
  admin_null?: Maybe<Scalars['String']>;
  admin_not_null?: Maybe<Scalars['String']>;
  admin_count?: Maybe<Scalars['String']>;
  admin_count_distinct?: Maybe<Scalars['String']>;
  admin_sum?: Maybe<Scalars['String']>;
  admin_avg?: Maybe<Scalars['String']>;
  admin_min?: Maybe<Scalars['String']>;
  admin_max?: Maybe<Scalars['String']>;
  manager_eq?: Maybe<Scalars['String']>;
  manager_eq_any?: Maybe<Scalars['String']>;
  manager_eq_all?: Maybe<Scalars['String']>;
  manager_not_eq?: Maybe<Scalars['String']>;
  manager_not_eq_any?: Maybe<Scalars['String']>;
  manager_not_eq_all?: Maybe<Scalars['String']>;
  manager_matches?: Maybe<Scalars['String']>;
  manager_matches_any?: Maybe<Scalars['String']>;
  manager_matches_all?: Maybe<Scalars['String']>;
  manager_does_not_match?: Maybe<Scalars['String']>;
  manager_does_not_match_any?: Maybe<Scalars['String']>;
  manager_does_not_match_all?: Maybe<Scalars['String']>;
  manager_lt?: Maybe<Scalars['String']>;
  manager_lt_any?: Maybe<Scalars['String']>;
  manager_lt_all?: Maybe<Scalars['String']>;
  manager_lteq?: Maybe<Scalars['String']>;
  manager_lteq_any?: Maybe<Scalars['String']>;
  manager_lteq_all?: Maybe<Scalars['String']>;
  manager_gt?: Maybe<Scalars['String']>;
  manager_gt_any?: Maybe<Scalars['String']>;
  manager_gt_all?: Maybe<Scalars['String']>;
  manager_gteq?: Maybe<Scalars['String']>;
  manager_gteq_any?: Maybe<Scalars['String']>;
  manager_gteq_all?: Maybe<Scalars['String']>;
  manager_in?: Maybe<Scalars['String']>;
  manager_in_any?: Maybe<Scalars['String']>;
  manager_in_all?: Maybe<Scalars['String']>;
  manager_not_in?: Maybe<Scalars['String']>;
  manager_not_in_any?: Maybe<Scalars['String']>;
  manager_not_in_all?: Maybe<Scalars['String']>;
  manager_cont?: Maybe<Scalars['String']>;
  manager_cont_any?: Maybe<Scalars['String']>;
  manager_cont_all?: Maybe<Scalars['String']>;
  manager_not_cont?: Maybe<Scalars['String']>;
  manager_not_cont_any?: Maybe<Scalars['String']>;
  manager_not_cont_all?: Maybe<Scalars['String']>;
  manager_i_cont?: Maybe<Scalars['String']>;
  manager_i_cont_any?: Maybe<Scalars['String']>;
  manager_i_cont_all?: Maybe<Scalars['String']>;
  manager_not_i_cont?: Maybe<Scalars['String']>;
  manager_not_i_cont_any?: Maybe<Scalars['String']>;
  manager_not_i_cont_all?: Maybe<Scalars['String']>;
  manager_start?: Maybe<Scalars['String']>;
  manager_start_any?: Maybe<Scalars['String']>;
  manager_start_all?: Maybe<Scalars['String']>;
  manager_not_start?: Maybe<Scalars['String']>;
  manager_not_start_any?: Maybe<Scalars['String']>;
  manager_not_start_all?: Maybe<Scalars['String']>;
  manager_end?: Maybe<Scalars['String']>;
  manager_end_any?: Maybe<Scalars['String']>;
  manager_end_all?: Maybe<Scalars['String']>;
  manager_not_end?: Maybe<Scalars['String']>;
  manager_not_end_any?: Maybe<Scalars['String']>;
  manager_not_end_all?: Maybe<Scalars['String']>;
  manager_true?: Maybe<Scalars['String']>;
  manager_not_true?: Maybe<Scalars['String']>;
  manager_false?: Maybe<Scalars['String']>;
  manager_not_false?: Maybe<Scalars['String']>;
  manager_present?: Maybe<Scalars['String']>;
  manager_blank?: Maybe<Scalars['String']>;
  manager_null?: Maybe<Scalars['String']>;
  manager_not_null?: Maybe<Scalars['String']>;
  manager_count?: Maybe<Scalars['String']>;
  manager_count_distinct?: Maybe<Scalars['String']>;
  manager_sum?: Maybe<Scalars['String']>;
  manager_avg?: Maybe<Scalars['String']>;
  manager_min?: Maybe<Scalars['String']>;
  manager_max?: Maybe<Scalars['String']>;
  title_eq?: Maybe<Scalars['String']>;
  title_eq_any?: Maybe<Scalars['String']>;
  title_eq_all?: Maybe<Scalars['String']>;
  title_not_eq?: Maybe<Scalars['String']>;
  title_not_eq_any?: Maybe<Scalars['String']>;
  title_not_eq_all?: Maybe<Scalars['String']>;
  title_matches?: Maybe<Scalars['String']>;
  title_matches_any?: Maybe<Scalars['String']>;
  title_matches_all?: Maybe<Scalars['String']>;
  title_does_not_match?: Maybe<Scalars['String']>;
  title_does_not_match_any?: Maybe<Scalars['String']>;
  title_does_not_match_all?: Maybe<Scalars['String']>;
  title_lt?: Maybe<Scalars['String']>;
  title_lt_any?: Maybe<Scalars['String']>;
  title_lt_all?: Maybe<Scalars['String']>;
  title_lteq?: Maybe<Scalars['String']>;
  title_lteq_any?: Maybe<Scalars['String']>;
  title_lteq_all?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gt_any?: Maybe<Scalars['String']>;
  title_gt_all?: Maybe<Scalars['String']>;
  title_gteq?: Maybe<Scalars['String']>;
  title_gteq_any?: Maybe<Scalars['String']>;
  title_gteq_all?: Maybe<Scalars['String']>;
  title_in?: Maybe<Scalars['String']>;
  title_in_any?: Maybe<Scalars['String']>;
  title_in_all?: Maybe<Scalars['String']>;
  title_not_in?: Maybe<Scalars['String']>;
  title_not_in_any?: Maybe<Scalars['String']>;
  title_not_in_all?: Maybe<Scalars['String']>;
  title_cont?: Maybe<Scalars['String']>;
  title_cont_any?: Maybe<Scalars['String']>;
  title_cont_all?: Maybe<Scalars['String']>;
  title_not_cont?: Maybe<Scalars['String']>;
  title_not_cont_any?: Maybe<Scalars['String']>;
  title_not_cont_all?: Maybe<Scalars['String']>;
  title_i_cont?: Maybe<Scalars['String']>;
  title_i_cont_any?: Maybe<Scalars['String']>;
  title_i_cont_all?: Maybe<Scalars['String']>;
  title_not_i_cont?: Maybe<Scalars['String']>;
  title_not_i_cont_any?: Maybe<Scalars['String']>;
  title_not_i_cont_all?: Maybe<Scalars['String']>;
  title_start?: Maybe<Scalars['String']>;
  title_start_any?: Maybe<Scalars['String']>;
  title_start_all?: Maybe<Scalars['String']>;
  title_not_start?: Maybe<Scalars['String']>;
  title_not_start_any?: Maybe<Scalars['String']>;
  title_not_start_all?: Maybe<Scalars['String']>;
  title_end?: Maybe<Scalars['String']>;
  title_end_any?: Maybe<Scalars['String']>;
  title_end_all?: Maybe<Scalars['String']>;
  title_not_end?: Maybe<Scalars['String']>;
  title_not_end_any?: Maybe<Scalars['String']>;
  title_not_end_all?: Maybe<Scalars['String']>;
  title_true?: Maybe<Scalars['String']>;
  title_not_true?: Maybe<Scalars['String']>;
  title_false?: Maybe<Scalars['String']>;
  title_not_false?: Maybe<Scalars['String']>;
  title_present?: Maybe<Scalars['String']>;
  title_blank?: Maybe<Scalars['String']>;
  title_null?: Maybe<Scalars['String']>;
  title_not_null?: Maybe<Scalars['String']>;
  title_count?: Maybe<Scalars['String']>;
  title_count_distinct?: Maybe<Scalars['String']>;
  title_sum?: Maybe<Scalars['String']>;
  title_avg?: Maybe<Scalars['String']>;
  title_min?: Maybe<Scalars['String']>;
  title_max?: Maybe<Scalars['String']>;
  created_at_eq?: Maybe<Scalars['String']>;
  created_at_eq_any?: Maybe<Scalars['String']>;
  created_at_eq_all?: Maybe<Scalars['String']>;
  created_at_not_eq?: Maybe<Scalars['String']>;
  created_at_not_eq_any?: Maybe<Scalars['String']>;
  created_at_not_eq_all?: Maybe<Scalars['String']>;
  created_at_matches?: Maybe<Scalars['String']>;
  created_at_matches_any?: Maybe<Scalars['String']>;
  created_at_matches_all?: Maybe<Scalars['String']>;
  created_at_does_not_match?: Maybe<Scalars['String']>;
  created_at_does_not_match_any?: Maybe<Scalars['String']>;
  created_at_does_not_match_all?: Maybe<Scalars['String']>;
  created_at_lt?: Maybe<Scalars['String']>;
  created_at_lt_any?: Maybe<Scalars['String']>;
  created_at_lt_all?: Maybe<Scalars['String']>;
  created_at_lteq?: Maybe<Scalars['String']>;
  created_at_lteq_any?: Maybe<Scalars['String']>;
  created_at_lteq_all?: Maybe<Scalars['String']>;
  created_at_gt?: Maybe<Scalars['String']>;
  created_at_gt_any?: Maybe<Scalars['String']>;
  created_at_gt_all?: Maybe<Scalars['String']>;
  created_at_gteq?: Maybe<Scalars['String']>;
  created_at_gteq_any?: Maybe<Scalars['String']>;
  created_at_gteq_all?: Maybe<Scalars['String']>;
  created_at_in?: Maybe<Scalars['String']>;
  created_at_in_any?: Maybe<Scalars['String']>;
  created_at_in_all?: Maybe<Scalars['String']>;
  created_at_not_in?: Maybe<Scalars['String']>;
  created_at_not_in_any?: Maybe<Scalars['String']>;
  created_at_not_in_all?: Maybe<Scalars['String']>;
  created_at_cont?: Maybe<Scalars['String']>;
  created_at_cont_any?: Maybe<Scalars['String']>;
  created_at_cont_all?: Maybe<Scalars['String']>;
  created_at_not_cont?: Maybe<Scalars['String']>;
  created_at_not_cont_any?: Maybe<Scalars['String']>;
  created_at_not_cont_all?: Maybe<Scalars['String']>;
  created_at_i_cont?: Maybe<Scalars['String']>;
  created_at_i_cont_any?: Maybe<Scalars['String']>;
  created_at_i_cont_all?: Maybe<Scalars['String']>;
  created_at_not_i_cont?: Maybe<Scalars['String']>;
  created_at_not_i_cont_any?: Maybe<Scalars['String']>;
  created_at_not_i_cont_all?: Maybe<Scalars['String']>;
  created_at_start?: Maybe<Scalars['String']>;
  created_at_start_any?: Maybe<Scalars['String']>;
  created_at_start_all?: Maybe<Scalars['String']>;
  created_at_not_start?: Maybe<Scalars['String']>;
  created_at_not_start_any?: Maybe<Scalars['String']>;
  created_at_not_start_all?: Maybe<Scalars['String']>;
  created_at_end?: Maybe<Scalars['String']>;
  created_at_end_any?: Maybe<Scalars['String']>;
  created_at_end_all?: Maybe<Scalars['String']>;
  created_at_not_end?: Maybe<Scalars['String']>;
  created_at_not_end_any?: Maybe<Scalars['String']>;
  created_at_not_end_all?: Maybe<Scalars['String']>;
  created_at_true?: Maybe<Scalars['String']>;
  created_at_not_true?: Maybe<Scalars['String']>;
  created_at_false?: Maybe<Scalars['String']>;
  created_at_not_false?: Maybe<Scalars['String']>;
  created_at_present?: Maybe<Scalars['String']>;
  created_at_blank?: Maybe<Scalars['String']>;
  created_at_null?: Maybe<Scalars['String']>;
  created_at_not_null?: Maybe<Scalars['String']>;
  created_at_count?: Maybe<Scalars['String']>;
  created_at_count_distinct?: Maybe<Scalars['String']>;
  created_at_sum?: Maybe<Scalars['String']>;
  created_at_avg?: Maybe<Scalars['String']>;
  created_at_min?: Maybe<Scalars['String']>;
  created_at_max?: Maybe<Scalars['String']>;
  updated_at_eq?: Maybe<Scalars['String']>;
  updated_at_eq_any?: Maybe<Scalars['String']>;
  updated_at_eq_all?: Maybe<Scalars['String']>;
  updated_at_not_eq?: Maybe<Scalars['String']>;
  updated_at_not_eq_any?: Maybe<Scalars['String']>;
  updated_at_not_eq_all?: Maybe<Scalars['String']>;
  updated_at_matches?: Maybe<Scalars['String']>;
  updated_at_matches_any?: Maybe<Scalars['String']>;
  updated_at_matches_all?: Maybe<Scalars['String']>;
  updated_at_does_not_match?: Maybe<Scalars['String']>;
  updated_at_does_not_match_any?: Maybe<Scalars['String']>;
  updated_at_does_not_match_all?: Maybe<Scalars['String']>;
  updated_at_lt?: Maybe<Scalars['String']>;
  updated_at_lt_any?: Maybe<Scalars['String']>;
  updated_at_lt_all?: Maybe<Scalars['String']>;
  updated_at_lteq?: Maybe<Scalars['String']>;
  updated_at_lteq_any?: Maybe<Scalars['String']>;
  updated_at_lteq_all?: Maybe<Scalars['String']>;
  updated_at_gt?: Maybe<Scalars['String']>;
  updated_at_gt_any?: Maybe<Scalars['String']>;
  updated_at_gt_all?: Maybe<Scalars['String']>;
  updated_at_gteq?: Maybe<Scalars['String']>;
  updated_at_gteq_any?: Maybe<Scalars['String']>;
  updated_at_gteq_all?: Maybe<Scalars['String']>;
  updated_at_in?: Maybe<Scalars['String']>;
  updated_at_in_any?: Maybe<Scalars['String']>;
  updated_at_in_all?: Maybe<Scalars['String']>;
  updated_at_not_in?: Maybe<Scalars['String']>;
  updated_at_not_in_any?: Maybe<Scalars['String']>;
  updated_at_not_in_all?: Maybe<Scalars['String']>;
  updated_at_cont?: Maybe<Scalars['String']>;
  updated_at_cont_any?: Maybe<Scalars['String']>;
  updated_at_cont_all?: Maybe<Scalars['String']>;
  updated_at_not_cont?: Maybe<Scalars['String']>;
  updated_at_not_cont_any?: Maybe<Scalars['String']>;
  updated_at_not_cont_all?: Maybe<Scalars['String']>;
  updated_at_i_cont?: Maybe<Scalars['String']>;
  updated_at_i_cont_any?: Maybe<Scalars['String']>;
  updated_at_i_cont_all?: Maybe<Scalars['String']>;
  updated_at_not_i_cont?: Maybe<Scalars['String']>;
  updated_at_not_i_cont_any?: Maybe<Scalars['String']>;
  updated_at_not_i_cont_all?: Maybe<Scalars['String']>;
  updated_at_start?: Maybe<Scalars['String']>;
  updated_at_start_any?: Maybe<Scalars['String']>;
  updated_at_start_all?: Maybe<Scalars['String']>;
  updated_at_not_start?: Maybe<Scalars['String']>;
  updated_at_not_start_any?: Maybe<Scalars['String']>;
  updated_at_not_start_all?: Maybe<Scalars['String']>;
  updated_at_end?: Maybe<Scalars['String']>;
  updated_at_end_any?: Maybe<Scalars['String']>;
  updated_at_end_all?: Maybe<Scalars['String']>;
  updated_at_not_end?: Maybe<Scalars['String']>;
  updated_at_not_end_any?: Maybe<Scalars['String']>;
  updated_at_not_end_all?: Maybe<Scalars['String']>;
  updated_at_true?: Maybe<Scalars['String']>;
  updated_at_not_true?: Maybe<Scalars['String']>;
  updated_at_false?: Maybe<Scalars['String']>;
  updated_at_not_false?: Maybe<Scalars['String']>;
  updated_at_present?: Maybe<Scalars['String']>;
  updated_at_blank?: Maybe<Scalars['String']>;
  updated_at_null?: Maybe<Scalars['String']>;
  updated_at_not_null?: Maybe<Scalars['String']>;
  updated_at_count?: Maybe<Scalars['String']>;
  updated_at_count_distinct?: Maybe<Scalars['String']>;
  updated_at_sum?: Maybe<Scalars['String']>;
  updated_at_avg?: Maybe<Scalars['String']>;
  updated_at_min?: Maybe<Scalars['String']>;
  updated_at_max?: Maybe<Scalars['String']>;
  gender_eq?: Maybe<Scalars['String']>;
  gender_eq_any?: Maybe<Scalars['String']>;
  gender_eq_all?: Maybe<Scalars['String']>;
  gender_not_eq?: Maybe<Scalars['String']>;
  gender_not_eq_any?: Maybe<Scalars['String']>;
  gender_not_eq_all?: Maybe<Scalars['String']>;
  gender_matches?: Maybe<Scalars['String']>;
  gender_matches_any?: Maybe<Scalars['String']>;
  gender_matches_all?: Maybe<Scalars['String']>;
  gender_does_not_match?: Maybe<Scalars['String']>;
  gender_does_not_match_any?: Maybe<Scalars['String']>;
  gender_does_not_match_all?: Maybe<Scalars['String']>;
  gender_lt?: Maybe<Scalars['String']>;
  gender_lt_any?: Maybe<Scalars['String']>;
  gender_lt_all?: Maybe<Scalars['String']>;
  gender_lteq?: Maybe<Scalars['String']>;
  gender_lteq_any?: Maybe<Scalars['String']>;
  gender_lteq_all?: Maybe<Scalars['String']>;
  gender_gt?: Maybe<Scalars['String']>;
  gender_gt_any?: Maybe<Scalars['String']>;
  gender_gt_all?: Maybe<Scalars['String']>;
  gender_gteq?: Maybe<Scalars['String']>;
  gender_gteq_any?: Maybe<Scalars['String']>;
  gender_gteq_all?: Maybe<Scalars['String']>;
  gender_in?: Maybe<Scalars['String']>;
  gender_in_any?: Maybe<Scalars['String']>;
  gender_in_all?: Maybe<Scalars['String']>;
  gender_not_in?: Maybe<Scalars['String']>;
  gender_not_in_any?: Maybe<Scalars['String']>;
  gender_not_in_all?: Maybe<Scalars['String']>;
  gender_cont?: Maybe<Scalars['String']>;
  gender_cont_any?: Maybe<Scalars['String']>;
  gender_cont_all?: Maybe<Scalars['String']>;
  gender_not_cont?: Maybe<Scalars['String']>;
  gender_not_cont_any?: Maybe<Scalars['String']>;
  gender_not_cont_all?: Maybe<Scalars['String']>;
  gender_i_cont?: Maybe<Scalars['String']>;
  gender_i_cont_any?: Maybe<Scalars['String']>;
  gender_i_cont_all?: Maybe<Scalars['String']>;
  gender_not_i_cont?: Maybe<Scalars['String']>;
  gender_not_i_cont_any?: Maybe<Scalars['String']>;
  gender_not_i_cont_all?: Maybe<Scalars['String']>;
  gender_start?: Maybe<Scalars['String']>;
  gender_start_any?: Maybe<Scalars['String']>;
  gender_start_all?: Maybe<Scalars['String']>;
  gender_not_start?: Maybe<Scalars['String']>;
  gender_not_start_any?: Maybe<Scalars['String']>;
  gender_not_start_all?: Maybe<Scalars['String']>;
  gender_end?: Maybe<Scalars['String']>;
  gender_end_any?: Maybe<Scalars['String']>;
  gender_end_all?: Maybe<Scalars['String']>;
  gender_not_end?: Maybe<Scalars['String']>;
  gender_not_end_any?: Maybe<Scalars['String']>;
  gender_not_end_all?: Maybe<Scalars['String']>;
  gender_true?: Maybe<Scalars['String']>;
  gender_not_true?: Maybe<Scalars['String']>;
  gender_false?: Maybe<Scalars['String']>;
  gender_not_false?: Maybe<Scalars['String']>;
  gender_present?: Maybe<Scalars['String']>;
  gender_blank?: Maybe<Scalars['String']>;
  gender_null?: Maybe<Scalars['String']>;
  gender_not_null?: Maybe<Scalars['String']>;
  gender_count?: Maybe<Scalars['String']>;
  gender_count_distinct?: Maybe<Scalars['String']>;
  gender_sum?: Maybe<Scalars['String']>;
  gender_avg?: Maybe<Scalars['String']>;
  gender_min?: Maybe<Scalars['String']>;
  gender_max?: Maybe<Scalars['String']>;
  team_id_eq?: Maybe<Scalars['String']>;
  team_id_eq_any?: Maybe<Scalars['String']>;
  team_id_eq_all?: Maybe<Scalars['String']>;
  team_id_not_eq?: Maybe<Scalars['String']>;
  team_id_not_eq_any?: Maybe<Scalars['String']>;
  team_id_not_eq_all?: Maybe<Scalars['String']>;
  team_id_matches?: Maybe<Scalars['String']>;
  team_id_matches_any?: Maybe<Scalars['String']>;
  team_id_matches_all?: Maybe<Scalars['String']>;
  team_id_does_not_match?: Maybe<Scalars['String']>;
  team_id_does_not_match_any?: Maybe<Scalars['String']>;
  team_id_does_not_match_all?: Maybe<Scalars['String']>;
  team_id_lt?: Maybe<Scalars['String']>;
  team_id_lt_any?: Maybe<Scalars['String']>;
  team_id_lt_all?: Maybe<Scalars['String']>;
  team_id_lteq?: Maybe<Scalars['String']>;
  team_id_lteq_any?: Maybe<Scalars['String']>;
  team_id_lteq_all?: Maybe<Scalars['String']>;
  team_id_gt?: Maybe<Scalars['String']>;
  team_id_gt_any?: Maybe<Scalars['String']>;
  team_id_gt_all?: Maybe<Scalars['String']>;
  team_id_gteq?: Maybe<Scalars['String']>;
  team_id_gteq_any?: Maybe<Scalars['String']>;
  team_id_gteq_all?: Maybe<Scalars['String']>;
  team_id_in?: Maybe<Scalars['String']>;
  team_id_in_any?: Maybe<Scalars['String']>;
  team_id_in_all?: Maybe<Scalars['String']>;
  team_id_not_in?: Maybe<Scalars['String']>;
  team_id_not_in_any?: Maybe<Scalars['String']>;
  team_id_not_in_all?: Maybe<Scalars['String']>;
  team_id_cont?: Maybe<Scalars['String']>;
  team_id_cont_any?: Maybe<Scalars['String']>;
  team_id_cont_all?: Maybe<Scalars['String']>;
  team_id_not_cont?: Maybe<Scalars['String']>;
  team_id_not_cont_any?: Maybe<Scalars['String']>;
  team_id_not_cont_all?: Maybe<Scalars['String']>;
  team_id_i_cont?: Maybe<Scalars['String']>;
  team_id_i_cont_any?: Maybe<Scalars['String']>;
  team_id_i_cont_all?: Maybe<Scalars['String']>;
  team_id_not_i_cont?: Maybe<Scalars['String']>;
  team_id_not_i_cont_any?: Maybe<Scalars['String']>;
  team_id_not_i_cont_all?: Maybe<Scalars['String']>;
  team_id_start?: Maybe<Scalars['String']>;
  team_id_start_any?: Maybe<Scalars['String']>;
  team_id_start_all?: Maybe<Scalars['String']>;
  team_id_not_start?: Maybe<Scalars['String']>;
  team_id_not_start_any?: Maybe<Scalars['String']>;
  team_id_not_start_all?: Maybe<Scalars['String']>;
  team_id_end?: Maybe<Scalars['String']>;
  team_id_end_any?: Maybe<Scalars['String']>;
  team_id_end_all?: Maybe<Scalars['String']>;
  team_id_not_end?: Maybe<Scalars['String']>;
  team_id_not_end_any?: Maybe<Scalars['String']>;
  team_id_not_end_all?: Maybe<Scalars['String']>;
  team_id_true?: Maybe<Scalars['String']>;
  team_id_not_true?: Maybe<Scalars['String']>;
  team_id_false?: Maybe<Scalars['String']>;
  team_id_not_false?: Maybe<Scalars['String']>;
  team_id_present?: Maybe<Scalars['String']>;
  team_id_blank?: Maybe<Scalars['String']>;
  team_id_null?: Maybe<Scalars['String']>;
  team_id_not_null?: Maybe<Scalars['String']>;
  team_id_count?: Maybe<Scalars['String']>;
  team_id_count_distinct?: Maybe<Scalars['String']>;
  team_id_sum?: Maybe<Scalars['String']>;
  team_id_avg?: Maybe<Scalars['String']>;
  team_id_min?: Maybe<Scalars['String']>;
  team_id_max?: Maybe<Scalars['String']>;
  active_eq?: Maybe<Scalars['String']>;
  active_eq_any?: Maybe<Scalars['String']>;
  active_eq_all?: Maybe<Scalars['String']>;
  active_not_eq?: Maybe<Scalars['String']>;
  active_not_eq_any?: Maybe<Scalars['String']>;
  active_not_eq_all?: Maybe<Scalars['String']>;
  active_matches?: Maybe<Scalars['String']>;
  active_matches_any?: Maybe<Scalars['String']>;
  active_matches_all?: Maybe<Scalars['String']>;
  active_does_not_match?: Maybe<Scalars['String']>;
  active_does_not_match_any?: Maybe<Scalars['String']>;
  active_does_not_match_all?: Maybe<Scalars['String']>;
  active_lt?: Maybe<Scalars['String']>;
  active_lt_any?: Maybe<Scalars['String']>;
  active_lt_all?: Maybe<Scalars['String']>;
  active_lteq?: Maybe<Scalars['String']>;
  active_lteq_any?: Maybe<Scalars['String']>;
  active_lteq_all?: Maybe<Scalars['String']>;
  active_gt?: Maybe<Scalars['String']>;
  active_gt_any?: Maybe<Scalars['String']>;
  active_gt_all?: Maybe<Scalars['String']>;
  active_gteq?: Maybe<Scalars['String']>;
  active_gteq_any?: Maybe<Scalars['String']>;
  active_gteq_all?: Maybe<Scalars['String']>;
  active_in?: Maybe<Scalars['String']>;
  active_in_any?: Maybe<Scalars['String']>;
  active_in_all?: Maybe<Scalars['String']>;
  active_not_in?: Maybe<Scalars['String']>;
  active_not_in_any?: Maybe<Scalars['String']>;
  active_not_in_all?: Maybe<Scalars['String']>;
  active_cont?: Maybe<Scalars['String']>;
  active_cont_any?: Maybe<Scalars['String']>;
  active_cont_all?: Maybe<Scalars['String']>;
  active_not_cont?: Maybe<Scalars['String']>;
  active_not_cont_any?: Maybe<Scalars['String']>;
  active_not_cont_all?: Maybe<Scalars['String']>;
  active_i_cont?: Maybe<Scalars['String']>;
  active_i_cont_any?: Maybe<Scalars['String']>;
  active_i_cont_all?: Maybe<Scalars['String']>;
  active_not_i_cont?: Maybe<Scalars['String']>;
  active_not_i_cont_any?: Maybe<Scalars['String']>;
  active_not_i_cont_all?: Maybe<Scalars['String']>;
  active_start?: Maybe<Scalars['String']>;
  active_start_any?: Maybe<Scalars['String']>;
  active_start_all?: Maybe<Scalars['String']>;
  active_not_start?: Maybe<Scalars['String']>;
  active_not_start_any?: Maybe<Scalars['String']>;
  active_not_start_all?: Maybe<Scalars['String']>;
  active_end?: Maybe<Scalars['String']>;
  active_end_any?: Maybe<Scalars['String']>;
  active_end_all?: Maybe<Scalars['String']>;
  active_not_end?: Maybe<Scalars['String']>;
  active_not_end_any?: Maybe<Scalars['String']>;
  active_not_end_all?: Maybe<Scalars['String']>;
  active_true?: Maybe<Scalars['String']>;
  active_not_true?: Maybe<Scalars['String']>;
  active_false?: Maybe<Scalars['String']>;
  active_not_false?: Maybe<Scalars['String']>;
  active_present?: Maybe<Scalars['String']>;
  active_blank?: Maybe<Scalars['String']>;
  active_null?: Maybe<Scalars['String']>;
  active_not_null?: Maybe<Scalars['String']>;
  active_count?: Maybe<Scalars['String']>;
  active_count_distinct?: Maybe<Scalars['String']>;
  active_sum?: Maybe<Scalars['String']>;
  active_avg?: Maybe<Scalars['String']>;
  active_min?: Maybe<Scalars['String']>;
  active_max?: Maybe<Scalars['String']>;
  frequency_eq?: Maybe<Scalars['String']>;
  frequency_eq_any?: Maybe<Scalars['String']>;
  frequency_eq_all?: Maybe<Scalars['String']>;
  frequency_not_eq?: Maybe<Scalars['String']>;
  frequency_not_eq_any?: Maybe<Scalars['String']>;
  frequency_not_eq_all?: Maybe<Scalars['String']>;
  frequency_matches?: Maybe<Scalars['String']>;
  frequency_matches_any?: Maybe<Scalars['String']>;
  frequency_matches_all?: Maybe<Scalars['String']>;
  frequency_does_not_match?: Maybe<Scalars['String']>;
  frequency_does_not_match_any?: Maybe<Scalars['String']>;
  frequency_does_not_match_all?: Maybe<Scalars['String']>;
  frequency_lt?: Maybe<Scalars['String']>;
  frequency_lt_any?: Maybe<Scalars['String']>;
  frequency_lt_all?: Maybe<Scalars['String']>;
  frequency_lteq?: Maybe<Scalars['String']>;
  frequency_lteq_any?: Maybe<Scalars['String']>;
  frequency_lteq_all?: Maybe<Scalars['String']>;
  frequency_gt?: Maybe<Scalars['String']>;
  frequency_gt_any?: Maybe<Scalars['String']>;
  frequency_gt_all?: Maybe<Scalars['String']>;
  frequency_gteq?: Maybe<Scalars['String']>;
  frequency_gteq_any?: Maybe<Scalars['String']>;
  frequency_gteq_all?: Maybe<Scalars['String']>;
  frequency_in?: Maybe<Scalars['String']>;
  frequency_in_any?: Maybe<Scalars['String']>;
  frequency_in_all?: Maybe<Scalars['String']>;
  frequency_not_in?: Maybe<Scalars['String']>;
  frequency_not_in_any?: Maybe<Scalars['String']>;
  frequency_not_in_all?: Maybe<Scalars['String']>;
  frequency_cont?: Maybe<Scalars['String']>;
  frequency_cont_any?: Maybe<Scalars['String']>;
  frequency_cont_all?: Maybe<Scalars['String']>;
  frequency_not_cont?: Maybe<Scalars['String']>;
  frequency_not_cont_any?: Maybe<Scalars['String']>;
  frequency_not_cont_all?: Maybe<Scalars['String']>;
  frequency_i_cont?: Maybe<Scalars['String']>;
  frequency_i_cont_any?: Maybe<Scalars['String']>;
  frequency_i_cont_all?: Maybe<Scalars['String']>;
  frequency_not_i_cont?: Maybe<Scalars['String']>;
  frequency_not_i_cont_any?: Maybe<Scalars['String']>;
  frequency_not_i_cont_all?: Maybe<Scalars['String']>;
  frequency_start?: Maybe<Scalars['String']>;
  frequency_start_any?: Maybe<Scalars['String']>;
  frequency_start_all?: Maybe<Scalars['String']>;
  frequency_not_start?: Maybe<Scalars['String']>;
  frequency_not_start_any?: Maybe<Scalars['String']>;
  frequency_not_start_all?: Maybe<Scalars['String']>;
  frequency_end?: Maybe<Scalars['String']>;
  frequency_end_any?: Maybe<Scalars['String']>;
  frequency_end_all?: Maybe<Scalars['String']>;
  frequency_not_end?: Maybe<Scalars['String']>;
  frequency_not_end_any?: Maybe<Scalars['String']>;
  frequency_not_end_all?: Maybe<Scalars['String']>;
  frequency_true?: Maybe<Scalars['String']>;
  frequency_not_true?: Maybe<Scalars['String']>;
  frequency_false?: Maybe<Scalars['String']>;
  frequency_not_false?: Maybe<Scalars['String']>;
  frequency_present?: Maybe<Scalars['String']>;
  frequency_blank?: Maybe<Scalars['String']>;
  frequency_null?: Maybe<Scalars['String']>;
  frequency_not_null?: Maybe<Scalars['String']>;
  frequency_count?: Maybe<Scalars['String']>;
  frequency_count_distinct?: Maybe<Scalars['String']>;
  frequency_sum?: Maybe<Scalars['String']>;
  frequency_avg?: Maybe<Scalars['String']>;
  frequency_min?: Maybe<Scalars['String']>;
  frequency_max?: Maybe<Scalars['String']>;
  rate_load_eq?: Maybe<Scalars['String']>;
  rate_load_eq_any?: Maybe<Scalars['String']>;
  rate_load_eq_all?: Maybe<Scalars['String']>;
  rate_load_not_eq?: Maybe<Scalars['String']>;
  rate_load_not_eq_any?: Maybe<Scalars['String']>;
  rate_load_not_eq_all?: Maybe<Scalars['String']>;
  rate_load_matches?: Maybe<Scalars['String']>;
  rate_load_matches_any?: Maybe<Scalars['String']>;
  rate_load_matches_all?: Maybe<Scalars['String']>;
  rate_load_does_not_match?: Maybe<Scalars['String']>;
  rate_load_does_not_match_any?: Maybe<Scalars['String']>;
  rate_load_does_not_match_all?: Maybe<Scalars['String']>;
  rate_load_lt?: Maybe<Scalars['String']>;
  rate_load_lt_any?: Maybe<Scalars['String']>;
  rate_load_lt_all?: Maybe<Scalars['String']>;
  rate_load_lteq?: Maybe<Scalars['String']>;
  rate_load_lteq_any?: Maybe<Scalars['String']>;
  rate_load_lteq_all?: Maybe<Scalars['String']>;
  rate_load_gt?: Maybe<Scalars['String']>;
  rate_load_gt_any?: Maybe<Scalars['String']>;
  rate_load_gt_all?: Maybe<Scalars['String']>;
  rate_load_gteq?: Maybe<Scalars['String']>;
  rate_load_gteq_any?: Maybe<Scalars['String']>;
  rate_load_gteq_all?: Maybe<Scalars['String']>;
  rate_load_in?: Maybe<Scalars['String']>;
  rate_load_in_any?: Maybe<Scalars['String']>;
  rate_load_in_all?: Maybe<Scalars['String']>;
  rate_load_not_in?: Maybe<Scalars['String']>;
  rate_load_not_in_any?: Maybe<Scalars['String']>;
  rate_load_not_in_all?: Maybe<Scalars['String']>;
  rate_load_cont?: Maybe<Scalars['String']>;
  rate_load_cont_any?: Maybe<Scalars['String']>;
  rate_load_cont_all?: Maybe<Scalars['String']>;
  rate_load_not_cont?: Maybe<Scalars['String']>;
  rate_load_not_cont_any?: Maybe<Scalars['String']>;
  rate_load_not_cont_all?: Maybe<Scalars['String']>;
  rate_load_i_cont?: Maybe<Scalars['String']>;
  rate_load_i_cont_any?: Maybe<Scalars['String']>;
  rate_load_i_cont_all?: Maybe<Scalars['String']>;
  rate_load_not_i_cont?: Maybe<Scalars['String']>;
  rate_load_not_i_cont_any?: Maybe<Scalars['String']>;
  rate_load_not_i_cont_all?: Maybe<Scalars['String']>;
  rate_load_start?: Maybe<Scalars['String']>;
  rate_load_start_any?: Maybe<Scalars['String']>;
  rate_load_start_all?: Maybe<Scalars['String']>;
  rate_load_not_start?: Maybe<Scalars['String']>;
  rate_load_not_start_any?: Maybe<Scalars['String']>;
  rate_load_not_start_all?: Maybe<Scalars['String']>;
  rate_load_end?: Maybe<Scalars['String']>;
  rate_load_end_any?: Maybe<Scalars['String']>;
  rate_load_end_all?: Maybe<Scalars['String']>;
  rate_load_not_end?: Maybe<Scalars['String']>;
  rate_load_not_end_any?: Maybe<Scalars['String']>;
  rate_load_not_end_all?: Maybe<Scalars['String']>;
  rate_load_true?: Maybe<Scalars['String']>;
  rate_load_not_true?: Maybe<Scalars['String']>;
  rate_load_false?: Maybe<Scalars['String']>;
  rate_load_not_false?: Maybe<Scalars['String']>;
  rate_load_present?: Maybe<Scalars['String']>;
  rate_load_blank?: Maybe<Scalars['String']>;
  rate_load_null?: Maybe<Scalars['String']>;
  rate_load_not_null?: Maybe<Scalars['String']>;
  rate_load_count?: Maybe<Scalars['String']>;
  rate_load_count_distinct?: Maybe<Scalars['String']>;
  rate_load_sum?: Maybe<Scalars['String']>;
  rate_load_avg?: Maybe<Scalars['String']>;
  rate_load_min?: Maybe<Scalars['String']>;
  rate_load_max?: Maybe<Scalars['String']>;
  temp_token_eq?: Maybe<Scalars['String']>;
  temp_token_eq_any?: Maybe<Scalars['String']>;
  temp_token_eq_all?: Maybe<Scalars['String']>;
  temp_token_not_eq?: Maybe<Scalars['String']>;
  temp_token_not_eq_any?: Maybe<Scalars['String']>;
  temp_token_not_eq_all?: Maybe<Scalars['String']>;
  temp_token_matches?: Maybe<Scalars['String']>;
  temp_token_matches_any?: Maybe<Scalars['String']>;
  temp_token_matches_all?: Maybe<Scalars['String']>;
  temp_token_does_not_match?: Maybe<Scalars['String']>;
  temp_token_does_not_match_any?: Maybe<Scalars['String']>;
  temp_token_does_not_match_all?: Maybe<Scalars['String']>;
  temp_token_lt?: Maybe<Scalars['String']>;
  temp_token_lt_any?: Maybe<Scalars['String']>;
  temp_token_lt_all?: Maybe<Scalars['String']>;
  temp_token_lteq?: Maybe<Scalars['String']>;
  temp_token_lteq_any?: Maybe<Scalars['String']>;
  temp_token_lteq_all?: Maybe<Scalars['String']>;
  temp_token_gt?: Maybe<Scalars['String']>;
  temp_token_gt_any?: Maybe<Scalars['String']>;
  temp_token_gt_all?: Maybe<Scalars['String']>;
  temp_token_gteq?: Maybe<Scalars['String']>;
  temp_token_gteq_any?: Maybe<Scalars['String']>;
  temp_token_gteq_all?: Maybe<Scalars['String']>;
  temp_token_in?: Maybe<Scalars['String']>;
  temp_token_in_any?: Maybe<Scalars['String']>;
  temp_token_in_all?: Maybe<Scalars['String']>;
  temp_token_not_in?: Maybe<Scalars['String']>;
  temp_token_not_in_any?: Maybe<Scalars['String']>;
  temp_token_not_in_all?: Maybe<Scalars['String']>;
  temp_token_cont?: Maybe<Scalars['String']>;
  temp_token_cont_any?: Maybe<Scalars['String']>;
  temp_token_cont_all?: Maybe<Scalars['String']>;
  temp_token_not_cont?: Maybe<Scalars['String']>;
  temp_token_not_cont_any?: Maybe<Scalars['String']>;
  temp_token_not_cont_all?: Maybe<Scalars['String']>;
  temp_token_i_cont?: Maybe<Scalars['String']>;
  temp_token_i_cont_any?: Maybe<Scalars['String']>;
  temp_token_i_cont_all?: Maybe<Scalars['String']>;
  temp_token_not_i_cont?: Maybe<Scalars['String']>;
  temp_token_not_i_cont_any?: Maybe<Scalars['String']>;
  temp_token_not_i_cont_all?: Maybe<Scalars['String']>;
  temp_token_start?: Maybe<Scalars['String']>;
  temp_token_start_any?: Maybe<Scalars['String']>;
  temp_token_start_all?: Maybe<Scalars['String']>;
  temp_token_not_start?: Maybe<Scalars['String']>;
  temp_token_not_start_any?: Maybe<Scalars['String']>;
  temp_token_not_start_all?: Maybe<Scalars['String']>;
  temp_token_end?: Maybe<Scalars['String']>;
  temp_token_end_any?: Maybe<Scalars['String']>;
  temp_token_end_all?: Maybe<Scalars['String']>;
  temp_token_not_end?: Maybe<Scalars['String']>;
  temp_token_not_end_any?: Maybe<Scalars['String']>;
  temp_token_not_end_all?: Maybe<Scalars['String']>;
  temp_token_true?: Maybe<Scalars['String']>;
  temp_token_not_true?: Maybe<Scalars['String']>;
  temp_token_false?: Maybe<Scalars['String']>;
  temp_token_not_false?: Maybe<Scalars['String']>;
  temp_token_present?: Maybe<Scalars['String']>;
  temp_token_blank?: Maybe<Scalars['String']>;
  temp_token_null?: Maybe<Scalars['String']>;
  temp_token_not_null?: Maybe<Scalars['String']>;
  temp_token_count?: Maybe<Scalars['String']>;
  temp_token_count_distinct?: Maybe<Scalars['String']>;
  temp_token_sum?: Maybe<Scalars['String']>;
  temp_token_avg?: Maybe<Scalars['String']>;
  temp_token_min?: Maybe<Scalars['String']>;
  temp_token_max?: Maybe<Scalars['String']>;
  temp_token_sent_at_eq?: Maybe<Scalars['String']>;
  temp_token_sent_at_eq_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_eq_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_eq?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_eq_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_eq_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_matches?: Maybe<Scalars['String']>;
  temp_token_sent_at_matches_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_matches_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_does_not_match?: Maybe<Scalars['String']>;
  temp_token_sent_at_does_not_match_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_does_not_match_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_lt?: Maybe<Scalars['String']>;
  temp_token_sent_at_lt_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_lt_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_lteq?: Maybe<Scalars['String']>;
  temp_token_sent_at_lteq_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_lteq_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_gt?: Maybe<Scalars['String']>;
  temp_token_sent_at_gt_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_gt_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_gteq?: Maybe<Scalars['String']>;
  temp_token_sent_at_gteq_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_gteq_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_in?: Maybe<Scalars['String']>;
  temp_token_sent_at_in_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_in_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_in?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_in_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_in_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_cont?: Maybe<Scalars['String']>;
  temp_token_sent_at_cont_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_cont_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_cont?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_cont_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_cont_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_i_cont?: Maybe<Scalars['String']>;
  temp_token_sent_at_i_cont_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_i_cont_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_i_cont?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_i_cont_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_i_cont_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_start?: Maybe<Scalars['String']>;
  temp_token_sent_at_start_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_start_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_start?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_start_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_start_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_end?: Maybe<Scalars['String']>;
  temp_token_sent_at_end_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_end_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_end?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_end_any?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_end_all?: Maybe<Scalars['String']>;
  temp_token_sent_at_true?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_true?: Maybe<Scalars['String']>;
  temp_token_sent_at_false?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_false?: Maybe<Scalars['String']>;
  temp_token_sent_at_present?: Maybe<Scalars['String']>;
  temp_token_sent_at_blank?: Maybe<Scalars['String']>;
  temp_token_sent_at_null?: Maybe<Scalars['String']>;
  temp_token_sent_at_not_null?: Maybe<Scalars['String']>;
  temp_token_sent_at_count?: Maybe<Scalars['String']>;
  temp_token_sent_at_count_distinct?: Maybe<Scalars['String']>;
  temp_token_sent_at_sum?: Maybe<Scalars['String']>;
  temp_token_sent_at_avg?: Maybe<Scalars['String']>;
  temp_token_sent_at_min?: Maybe<Scalars['String']>;
  temp_token_sent_at_max?: Maybe<Scalars['String']>;
  tenant_eq?: Maybe<Scalars['String']>;
  tenant_eq_any?: Maybe<Scalars['String']>;
  tenant_eq_all?: Maybe<Scalars['String']>;
  tenant_not_eq?: Maybe<Scalars['String']>;
  tenant_not_eq_any?: Maybe<Scalars['String']>;
  tenant_not_eq_all?: Maybe<Scalars['String']>;
  tenant_matches?: Maybe<Scalars['String']>;
  tenant_matches_any?: Maybe<Scalars['String']>;
  tenant_matches_all?: Maybe<Scalars['String']>;
  tenant_does_not_match?: Maybe<Scalars['String']>;
  tenant_does_not_match_any?: Maybe<Scalars['String']>;
  tenant_does_not_match_all?: Maybe<Scalars['String']>;
  tenant_lt?: Maybe<Scalars['String']>;
  tenant_lt_any?: Maybe<Scalars['String']>;
  tenant_lt_all?: Maybe<Scalars['String']>;
  tenant_lteq?: Maybe<Scalars['String']>;
  tenant_lteq_any?: Maybe<Scalars['String']>;
  tenant_lteq_all?: Maybe<Scalars['String']>;
  tenant_gt?: Maybe<Scalars['String']>;
  tenant_gt_any?: Maybe<Scalars['String']>;
  tenant_gt_all?: Maybe<Scalars['String']>;
  tenant_gteq?: Maybe<Scalars['String']>;
  tenant_gteq_any?: Maybe<Scalars['String']>;
  tenant_gteq_all?: Maybe<Scalars['String']>;
  tenant_in?: Maybe<Scalars['String']>;
  tenant_in_any?: Maybe<Scalars['String']>;
  tenant_in_all?: Maybe<Scalars['String']>;
  tenant_not_in?: Maybe<Scalars['String']>;
  tenant_not_in_any?: Maybe<Scalars['String']>;
  tenant_not_in_all?: Maybe<Scalars['String']>;
  tenant_cont?: Maybe<Scalars['String']>;
  tenant_cont_any?: Maybe<Scalars['String']>;
  tenant_cont_all?: Maybe<Scalars['String']>;
  tenant_not_cont?: Maybe<Scalars['String']>;
  tenant_not_cont_any?: Maybe<Scalars['String']>;
  tenant_not_cont_all?: Maybe<Scalars['String']>;
  tenant_i_cont?: Maybe<Scalars['String']>;
  tenant_i_cont_any?: Maybe<Scalars['String']>;
  tenant_i_cont_all?: Maybe<Scalars['String']>;
  tenant_not_i_cont?: Maybe<Scalars['String']>;
  tenant_not_i_cont_any?: Maybe<Scalars['String']>;
  tenant_not_i_cont_all?: Maybe<Scalars['String']>;
  tenant_start?: Maybe<Scalars['String']>;
  tenant_start_any?: Maybe<Scalars['String']>;
  tenant_start_all?: Maybe<Scalars['String']>;
  tenant_not_start?: Maybe<Scalars['String']>;
  tenant_not_start_any?: Maybe<Scalars['String']>;
  tenant_not_start_all?: Maybe<Scalars['String']>;
  tenant_end?: Maybe<Scalars['String']>;
  tenant_end_any?: Maybe<Scalars['String']>;
  tenant_end_all?: Maybe<Scalars['String']>;
  tenant_not_end?: Maybe<Scalars['String']>;
  tenant_not_end_any?: Maybe<Scalars['String']>;
  tenant_not_end_all?: Maybe<Scalars['String']>;
  tenant_true?: Maybe<Scalars['String']>;
  tenant_not_true?: Maybe<Scalars['String']>;
  tenant_false?: Maybe<Scalars['String']>;
  tenant_not_false?: Maybe<Scalars['String']>;
  tenant_present?: Maybe<Scalars['String']>;
  tenant_blank?: Maybe<Scalars['String']>;
  tenant_null?: Maybe<Scalars['String']>;
  tenant_not_null?: Maybe<Scalars['String']>;
  tenant_count?: Maybe<Scalars['String']>;
  tenant_count_distinct?: Maybe<Scalars['String']>;
  tenant_sum?: Maybe<Scalars['String']>;
  tenant_avg?: Maybe<Scalars['String']>;
  tenant_min?: Maybe<Scalars['String']>;
  tenant_max?: Maybe<Scalars['String']>;
  feedback_streak_eq?: Maybe<Scalars['String']>;
  feedback_streak_eq_any?: Maybe<Scalars['String']>;
  feedback_streak_eq_all?: Maybe<Scalars['String']>;
  feedback_streak_not_eq?: Maybe<Scalars['String']>;
  feedback_streak_not_eq_any?: Maybe<Scalars['String']>;
  feedback_streak_not_eq_all?: Maybe<Scalars['String']>;
  feedback_streak_matches?: Maybe<Scalars['String']>;
  feedback_streak_matches_any?: Maybe<Scalars['String']>;
  feedback_streak_matches_all?: Maybe<Scalars['String']>;
  feedback_streak_does_not_match?: Maybe<Scalars['String']>;
  feedback_streak_does_not_match_any?: Maybe<Scalars['String']>;
  feedback_streak_does_not_match_all?: Maybe<Scalars['String']>;
  feedback_streak_lt?: Maybe<Scalars['String']>;
  feedback_streak_lt_any?: Maybe<Scalars['String']>;
  feedback_streak_lt_all?: Maybe<Scalars['String']>;
  feedback_streak_lteq?: Maybe<Scalars['String']>;
  feedback_streak_lteq_any?: Maybe<Scalars['String']>;
  feedback_streak_lteq_all?: Maybe<Scalars['String']>;
  feedback_streak_gt?: Maybe<Scalars['String']>;
  feedback_streak_gt_any?: Maybe<Scalars['String']>;
  feedback_streak_gt_all?: Maybe<Scalars['String']>;
  feedback_streak_gteq?: Maybe<Scalars['String']>;
  feedback_streak_gteq_any?: Maybe<Scalars['String']>;
  feedback_streak_gteq_all?: Maybe<Scalars['String']>;
  feedback_streak_in?: Maybe<Scalars['String']>;
  feedback_streak_in_any?: Maybe<Scalars['String']>;
  feedback_streak_in_all?: Maybe<Scalars['String']>;
  feedback_streak_not_in?: Maybe<Scalars['String']>;
  feedback_streak_not_in_any?: Maybe<Scalars['String']>;
  feedback_streak_not_in_all?: Maybe<Scalars['String']>;
  feedback_streak_cont?: Maybe<Scalars['String']>;
  feedback_streak_cont_any?: Maybe<Scalars['String']>;
  feedback_streak_cont_all?: Maybe<Scalars['String']>;
  feedback_streak_not_cont?: Maybe<Scalars['String']>;
  feedback_streak_not_cont_any?: Maybe<Scalars['String']>;
  feedback_streak_not_cont_all?: Maybe<Scalars['String']>;
  feedback_streak_i_cont?: Maybe<Scalars['String']>;
  feedback_streak_i_cont_any?: Maybe<Scalars['String']>;
  feedback_streak_i_cont_all?: Maybe<Scalars['String']>;
  feedback_streak_not_i_cont?: Maybe<Scalars['String']>;
  feedback_streak_not_i_cont_any?: Maybe<Scalars['String']>;
  feedback_streak_not_i_cont_all?: Maybe<Scalars['String']>;
  feedback_streak_start?: Maybe<Scalars['String']>;
  feedback_streak_start_any?: Maybe<Scalars['String']>;
  feedback_streak_start_all?: Maybe<Scalars['String']>;
  feedback_streak_not_start?: Maybe<Scalars['String']>;
  feedback_streak_not_start_any?: Maybe<Scalars['String']>;
  feedback_streak_not_start_all?: Maybe<Scalars['String']>;
  feedback_streak_end?: Maybe<Scalars['String']>;
  feedback_streak_end_any?: Maybe<Scalars['String']>;
  feedback_streak_end_all?: Maybe<Scalars['String']>;
  feedback_streak_not_end?: Maybe<Scalars['String']>;
  feedback_streak_not_end_any?: Maybe<Scalars['String']>;
  feedback_streak_not_end_all?: Maybe<Scalars['String']>;
  feedback_streak_true?: Maybe<Scalars['String']>;
  feedback_streak_not_true?: Maybe<Scalars['String']>;
  feedback_streak_false?: Maybe<Scalars['String']>;
  feedback_streak_not_false?: Maybe<Scalars['String']>;
  feedback_streak_present?: Maybe<Scalars['String']>;
  feedback_streak_blank?: Maybe<Scalars['String']>;
  feedback_streak_null?: Maybe<Scalars['String']>;
  feedback_streak_not_null?: Maybe<Scalars['String']>;
  feedback_streak_count?: Maybe<Scalars['String']>;
  feedback_streak_count_distinct?: Maybe<Scalars['String']>;
  feedback_streak_sum?: Maybe<Scalars['String']>;
  feedback_streak_avg?: Maybe<Scalars['String']>;
  feedback_streak_min?: Maybe<Scalars['String']>;
  feedback_streak_max?: Maybe<Scalars['String']>;
  badge_level_eq?: Maybe<Scalars['String']>;
  badge_level_eq_any?: Maybe<Scalars['String']>;
  badge_level_eq_all?: Maybe<Scalars['String']>;
  badge_level_not_eq?: Maybe<Scalars['String']>;
  badge_level_not_eq_any?: Maybe<Scalars['String']>;
  badge_level_not_eq_all?: Maybe<Scalars['String']>;
  badge_level_matches?: Maybe<Scalars['String']>;
  badge_level_matches_any?: Maybe<Scalars['String']>;
  badge_level_matches_all?: Maybe<Scalars['String']>;
  badge_level_does_not_match?: Maybe<Scalars['String']>;
  badge_level_does_not_match_any?: Maybe<Scalars['String']>;
  badge_level_does_not_match_all?: Maybe<Scalars['String']>;
  badge_level_lt?: Maybe<Scalars['String']>;
  badge_level_lt_any?: Maybe<Scalars['String']>;
  badge_level_lt_all?: Maybe<Scalars['String']>;
  badge_level_lteq?: Maybe<Scalars['String']>;
  badge_level_lteq_any?: Maybe<Scalars['String']>;
  badge_level_lteq_all?: Maybe<Scalars['String']>;
  badge_level_gt?: Maybe<Scalars['String']>;
  badge_level_gt_any?: Maybe<Scalars['String']>;
  badge_level_gt_all?: Maybe<Scalars['String']>;
  badge_level_gteq?: Maybe<Scalars['String']>;
  badge_level_gteq_any?: Maybe<Scalars['String']>;
  badge_level_gteq_all?: Maybe<Scalars['String']>;
  badge_level_in?: Maybe<Scalars['String']>;
  badge_level_in_any?: Maybe<Scalars['String']>;
  badge_level_in_all?: Maybe<Scalars['String']>;
  badge_level_not_in?: Maybe<Scalars['String']>;
  badge_level_not_in_any?: Maybe<Scalars['String']>;
  badge_level_not_in_all?: Maybe<Scalars['String']>;
  badge_level_cont?: Maybe<Scalars['String']>;
  badge_level_cont_any?: Maybe<Scalars['String']>;
  badge_level_cont_all?: Maybe<Scalars['String']>;
  badge_level_not_cont?: Maybe<Scalars['String']>;
  badge_level_not_cont_any?: Maybe<Scalars['String']>;
  badge_level_not_cont_all?: Maybe<Scalars['String']>;
  badge_level_i_cont?: Maybe<Scalars['String']>;
  badge_level_i_cont_any?: Maybe<Scalars['String']>;
  badge_level_i_cont_all?: Maybe<Scalars['String']>;
  badge_level_not_i_cont?: Maybe<Scalars['String']>;
  badge_level_not_i_cont_any?: Maybe<Scalars['String']>;
  badge_level_not_i_cont_all?: Maybe<Scalars['String']>;
  badge_level_start?: Maybe<Scalars['String']>;
  badge_level_start_any?: Maybe<Scalars['String']>;
  badge_level_start_all?: Maybe<Scalars['String']>;
  badge_level_not_start?: Maybe<Scalars['String']>;
  badge_level_not_start_any?: Maybe<Scalars['String']>;
  badge_level_not_start_all?: Maybe<Scalars['String']>;
  badge_level_end?: Maybe<Scalars['String']>;
  badge_level_end_any?: Maybe<Scalars['String']>;
  badge_level_end_all?: Maybe<Scalars['String']>;
  badge_level_not_end?: Maybe<Scalars['String']>;
  badge_level_not_end_any?: Maybe<Scalars['String']>;
  badge_level_not_end_all?: Maybe<Scalars['String']>;
  badge_level_true?: Maybe<Scalars['String']>;
  badge_level_not_true?: Maybe<Scalars['String']>;
  badge_level_false?: Maybe<Scalars['String']>;
  badge_level_not_false?: Maybe<Scalars['String']>;
  badge_level_present?: Maybe<Scalars['String']>;
  badge_level_blank?: Maybe<Scalars['String']>;
  badge_level_null?: Maybe<Scalars['String']>;
  badge_level_not_null?: Maybe<Scalars['String']>;
  badge_level_count?: Maybe<Scalars['String']>;
  badge_level_count_distinct?: Maybe<Scalars['String']>;
  badge_level_sum?: Maybe<Scalars['String']>;
  badge_level_avg?: Maybe<Scalars['String']>;
  badge_level_min?: Maybe<Scalars['String']>;
  badge_level_max?: Maybe<Scalars['String']>;
  engagement_enabled_eq?: Maybe<Scalars['String']>;
  engagement_enabled_eq_any?: Maybe<Scalars['String']>;
  engagement_enabled_eq_all?: Maybe<Scalars['String']>;
  engagement_enabled_not_eq?: Maybe<Scalars['String']>;
  engagement_enabled_not_eq_any?: Maybe<Scalars['String']>;
  engagement_enabled_not_eq_all?: Maybe<Scalars['String']>;
  engagement_enabled_matches?: Maybe<Scalars['String']>;
  engagement_enabled_matches_any?: Maybe<Scalars['String']>;
  engagement_enabled_matches_all?: Maybe<Scalars['String']>;
  engagement_enabled_does_not_match?: Maybe<Scalars['String']>;
  engagement_enabled_does_not_match_any?: Maybe<Scalars['String']>;
  engagement_enabled_does_not_match_all?: Maybe<Scalars['String']>;
  engagement_enabled_lt?: Maybe<Scalars['String']>;
  engagement_enabled_lt_any?: Maybe<Scalars['String']>;
  engagement_enabled_lt_all?: Maybe<Scalars['String']>;
  engagement_enabled_lteq?: Maybe<Scalars['String']>;
  engagement_enabled_lteq_any?: Maybe<Scalars['String']>;
  engagement_enabled_lteq_all?: Maybe<Scalars['String']>;
  engagement_enabled_gt?: Maybe<Scalars['String']>;
  engagement_enabled_gt_any?: Maybe<Scalars['String']>;
  engagement_enabled_gt_all?: Maybe<Scalars['String']>;
  engagement_enabled_gteq?: Maybe<Scalars['String']>;
  engagement_enabled_gteq_any?: Maybe<Scalars['String']>;
  engagement_enabled_gteq_all?: Maybe<Scalars['String']>;
  engagement_enabled_in?: Maybe<Scalars['String']>;
  engagement_enabled_in_any?: Maybe<Scalars['String']>;
  engagement_enabled_in_all?: Maybe<Scalars['String']>;
  engagement_enabled_not_in?: Maybe<Scalars['String']>;
  engagement_enabled_not_in_any?: Maybe<Scalars['String']>;
  engagement_enabled_not_in_all?: Maybe<Scalars['String']>;
  engagement_enabled_cont?: Maybe<Scalars['String']>;
  engagement_enabled_cont_any?: Maybe<Scalars['String']>;
  engagement_enabled_cont_all?: Maybe<Scalars['String']>;
  engagement_enabled_not_cont?: Maybe<Scalars['String']>;
  engagement_enabled_not_cont_any?: Maybe<Scalars['String']>;
  engagement_enabled_not_cont_all?: Maybe<Scalars['String']>;
  engagement_enabled_i_cont?: Maybe<Scalars['String']>;
  engagement_enabled_i_cont_any?: Maybe<Scalars['String']>;
  engagement_enabled_i_cont_all?: Maybe<Scalars['String']>;
  engagement_enabled_not_i_cont?: Maybe<Scalars['String']>;
  engagement_enabled_not_i_cont_any?: Maybe<Scalars['String']>;
  engagement_enabled_not_i_cont_all?: Maybe<Scalars['String']>;
  engagement_enabled_start?: Maybe<Scalars['String']>;
  engagement_enabled_start_any?: Maybe<Scalars['String']>;
  engagement_enabled_start_all?: Maybe<Scalars['String']>;
  engagement_enabled_not_start?: Maybe<Scalars['String']>;
  engagement_enabled_not_start_any?: Maybe<Scalars['String']>;
  engagement_enabled_not_start_all?: Maybe<Scalars['String']>;
  engagement_enabled_end?: Maybe<Scalars['String']>;
  engagement_enabled_end_any?: Maybe<Scalars['String']>;
  engagement_enabled_end_all?: Maybe<Scalars['String']>;
  engagement_enabled_not_end?: Maybe<Scalars['String']>;
  engagement_enabled_not_end_any?: Maybe<Scalars['String']>;
  engagement_enabled_not_end_all?: Maybe<Scalars['String']>;
  engagement_enabled_true?: Maybe<Scalars['String']>;
  engagement_enabled_not_true?: Maybe<Scalars['String']>;
  engagement_enabled_false?: Maybe<Scalars['String']>;
  engagement_enabled_not_false?: Maybe<Scalars['String']>;
  engagement_enabled_present?: Maybe<Scalars['String']>;
  engagement_enabled_blank?: Maybe<Scalars['String']>;
  engagement_enabled_null?: Maybe<Scalars['String']>;
  engagement_enabled_not_null?: Maybe<Scalars['String']>;
  engagement_enabled_count?: Maybe<Scalars['String']>;
  engagement_enabled_count_distinct?: Maybe<Scalars['String']>;
  engagement_enabled_sum?: Maybe<Scalars['String']>;
  engagement_enabled_avg?: Maybe<Scalars['String']>;
  engagement_enabled_min?: Maybe<Scalars['String']>;
  engagement_enabled_max?: Maybe<Scalars['String']>;
  can_configure_eq?: Maybe<Scalars['String']>;
  can_configure_eq_any?: Maybe<Scalars['String']>;
  can_configure_eq_all?: Maybe<Scalars['String']>;
  can_configure_not_eq?: Maybe<Scalars['String']>;
  can_configure_not_eq_any?: Maybe<Scalars['String']>;
  can_configure_not_eq_all?: Maybe<Scalars['String']>;
  can_configure_matches?: Maybe<Scalars['String']>;
  can_configure_matches_any?: Maybe<Scalars['String']>;
  can_configure_matches_all?: Maybe<Scalars['String']>;
  can_configure_does_not_match?: Maybe<Scalars['String']>;
  can_configure_does_not_match_any?: Maybe<Scalars['String']>;
  can_configure_does_not_match_all?: Maybe<Scalars['String']>;
  can_configure_lt?: Maybe<Scalars['String']>;
  can_configure_lt_any?: Maybe<Scalars['String']>;
  can_configure_lt_all?: Maybe<Scalars['String']>;
  can_configure_lteq?: Maybe<Scalars['String']>;
  can_configure_lteq_any?: Maybe<Scalars['String']>;
  can_configure_lteq_all?: Maybe<Scalars['String']>;
  can_configure_gt?: Maybe<Scalars['String']>;
  can_configure_gt_any?: Maybe<Scalars['String']>;
  can_configure_gt_all?: Maybe<Scalars['String']>;
  can_configure_gteq?: Maybe<Scalars['String']>;
  can_configure_gteq_any?: Maybe<Scalars['String']>;
  can_configure_gteq_all?: Maybe<Scalars['String']>;
  can_configure_in?: Maybe<Scalars['String']>;
  can_configure_in_any?: Maybe<Scalars['String']>;
  can_configure_in_all?: Maybe<Scalars['String']>;
  can_configure_not_in?: Maybe<Scalars['String']>;
  can_configure_not_in_any?: Maybe<Scalars['String']>;
  can_configure_not_in_all?: Maybe<Scalars['String']>;
  can_configure_cont?: Maybe<Scalars['String']>;
  can_configure_cont_any?: Maybe<Scalars['String']>;
  can_configure_cont_all?: Maybe<Scalars['String']>;
  can_configure_not_cont?: Maybe<Scalars['String']>;
  can_configure_not_cont_any?: Maybe<Scalars['String']>;
  can_configure_not_cont_all?: Maybe<Scalars['String']>;
  can_configure_i_cont?: Maybe<Scalars['String']>;
  can_configure_i_cont_any?: Maybe<Scalars['String']>;
  can_configure_i_cont_all?: Maybe<Scalars['String']>;
  can_configure_not_i_cont?: Maybe<Scalars['String']>;
  can_configure_not_i_cont_any?: Maybe<Scalars['String']>;
  can_configure_not_i_cont_all?: Maybe<Scalars['String']>;
  can_configure_start?: Maybe<Scalars['String']>;
  can_configure_start_any?: Maybe<Scalars['String']>;
  can_configure_start_all?: Maybe<Scalars['String']>;
  can_configure_not_start?: Maybe<Scalars['String']>;
  can_configure_not_start_any?: Maybe<Scalars['String']>;
  can_configure_not_start_all?: Maybe<Scalars['String']>;
  can_configure_end?: Maybe<Scalars['String']>;
  can_configure_end_any?: Maybe<Scalars['String']>;
  can_configure_end_all?: Maybe<Scalars['String']>;
  can_configure_not_end?: Maybe<Scalars['String']>;
  can_configure_not_end_any?: Maybe<Scalars['String']>;
  can_configure_not_end_all?: Maybe<Scalars['String']>;
  can_configure_true?: Maybe<Scalars['String']>;
  can_configure_not_true?: Maybe<Scalars['String']>;
  can_configure_false?: Maybe<Scalars['String']>;
  can_configure_not_false?: Maybe<Scalars['String']>;
  can_configure_present?: Maybe<Scalars['String']>;
  can_configure_blank?: Maybe<Scalars['String']>;
  can_configure_null?: Maybe<Scalars['String']>;
  can_configure_not_null?: Maybe<Scalars['String']>;
  can_configure_count?: Maybe<Scalars['String']>;
  can_configure_count_distinct?: Maybe<Scalars['String']>;
  can_configure_sum?: Maybe<Scalars['String']>;
  can_configure_avg?: Maybe<Scalars['String']>;
  can_configure_min?: Maybe<Scalars['String']>;
  can_configure_max?: Maybe<Scalars['String']>;
  longest_feedback_streak_eq?: Maybe<Scalars['String']>;
  longest_feedback_streak_eq_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_eq_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_eq?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_eq_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_eq_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_matches?: Maybe<Scalars['String']>;
  longest_feedback_streak_matches_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_matches_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_does_not_match?: Maybe<Scalars['String']>;
  longest_feedback_streak_does_not_match_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_does_not_match_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_lt?: Maybe<Scalars['String']>;
  longest_feedback_streak_lt_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_lt_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_lteq?: Maybe<Scalars['String']>;
  longest_feedback_streak_lteq_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_lteq_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_gt?: Maybe<Scalars['String']>;
  longest_feedback_streak_gt_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_gt_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_gteq?: Maybe<Scalars['String']>;
  longest_feedback_streak_gteq_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_gteq_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_in?: Maybe<Scalars['String']>;
  longest_feedback_streak_in_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_in_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_in?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_in_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_in_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_cont?: Maybe<Scalars['String']>;
  longest_feedback_streak_cont_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_cont_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_cont?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_cont_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_cont_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_i_cont?: Maybe<Scalars['String']>;
  longest_feedback_streak_i_cont_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_i_cont_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_i_cont?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_i_cont_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_i_cont_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_start?: Maybe<Scalars['String']>;
  longest_feedback_streak_start_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_start_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_start?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_start_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_start_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_end?: Maybe<Scalars['String']>;
  longest_feedback_streak_end_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_end_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_end?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_end_any?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_end_all?: Maybe<Scalars['String']>;
  longest_feedback_streak_true?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_true?: Maybe<Scalars['String']>;
  longest_feedback_streak_false?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_false?: Maybe<Scalars['String']>;
  longest_feedback_streak_present?: Maybe<Scalars['String']>;
  longest_feedback_streak_blank?: Maybe<Scalars['String']>;
  longest_feedback_streak_null?: Maybe<Scalars['String']>;
  longest_feedback_streak_not_null?: Maybe<Scalars['String']>;
  longest_feedback_streak_count?: Maybe<Scalars['String']>;
  longest_feedback_streak_count_distinct?: Maybe<Scalars['String']>;
  longest_feedback_streak_sum?: Maybe<Scalars['String']>;
  longest_feedback_streak_avg?: Maybe<Scalars['String']>;
  longest_feedback_streak_min?: Maybe<Scalars['String']>;
  longest_feedback_streak_max?: Maybe<Scalars['String']>;
  role_id_eq?: Maybe<Scalars['String']>;
  role_id_eq_any?: Maybe<Scalars['String']>;
  role_id_eq_all?: Maybe<Scalars['String']>;
  role_id_not_eq?: Maybe<Scalars['String']>;
  role_id_not_eq_any?: Maybe<Scalars['String']>;
  role_id_not_eq_all?: Maybe<Scalars['String']>;
  role_id_matches?: Maybe<Scalars['String']>;
  role_id_matches_any?: Maybe<Scalars['String']>;
  role_id_matches_all?: Maybe<Scalars['String']>;
  role_id_does_not_match?: Maybe<Scalars['String']>;
  role_id_does_not_match_any?: Maybe<Scalars['String']>;
  role_id_does_not_match_all?: Maybe<Scalars['String']>;
  role_id_lt?: Maybe<Scalars['String']>;
  role_id_lt_any?: Maybe<Scalars['String']>;
  role_id_lt_all?: Maybe<Scalars['String']>;
  role_id_lteq?: Maybe<Scalars['String']>;
  role_id_lteq_any?: Maybe<Scalars['String']>;
  role_id_lteq_all?: Maybe<Scalars['String']>;
  role_id_gt?: Maybe<Scalars['String']>;
  role_id_gt_any?: Maybe<Scalars['String']>;
  role_id_gt_all?: Maybe<Scalars['String']>;
  role_id_gteq?: Maybe<Scalars['String']>;
  role_id_gteq_any?: Maybe<Scalars['String']>;
  role_id_gteq_all?: Maybe<Scalars['String']>;
  role_id_in?: Maybe<Scalars['String']>;
  role_id_in_any?: Maybe<Scalars['String']>;
  role_id_in_all?: Maybe<Scalars['String']>;
  role_id_not_in?: Maybe<Scalars['String']>;
  role_id_not_in_any?: Maybe<Scalars['String']>;
  role_id_not_in_all?: Maybe<Scalars['String']>;
  role_id_cont?: Maybe<Scalars['String']>;
  role_id_cont_any?: Maybe<Scalars['String']>;
  role_id_cont_all?: Maybe<Scalars['String']>;
  role_id_not_cont?: Maybe<Scalars['String']>;
  role_id_not_cont_any?: Maybe<Scalars['String']>;
  role_id_not_cont_all?: Maybe<Scalars['String']>;
  role_id_i_cont?: Maybe<Scalars['String']>;
  role_id_i_cont_any?: Maybe<Scalars['String']>;
  role_id_i_cont_all?: Maybe<Scalars['String']>;
  role_id_not_i_cont?: Maybe<Scalars['String']>;
  role_id_not_i_cont_any?: Maybe<Scalars['String']>;
  role_id_not_i_cont_all?: Maybe<Scalars['String']>;
  role_id_start?: Maybe<Scalars['String']>;
  role_id_start_any?: Maybe<Scalars['String']>;
  role_id_start_all?: Maybe<Scalars['String']>;
  role_id_not_start?: Maybe<Scalars['String']>;
  role_id_not_start_any?: Maybe<Scalars['String']>;
  role_id_not_start_all?: Maybe<Scalars['String']>;
  role_id_end?: Maybe<Scalars['String']>;
  role_id_end_any?: Maybe<Scalars['String']>;
  role_id_end_all?: Maybe<Scalars['String']>;
  role_id_not_end?: Maybe<Scalars['String']>;
  role_id_not_end_any?: Maybe<Scalars['String']>;
  role_id_not_end_all?: Maybe<Scalars['String']>;
  role_id_true?: Maybe<Scalars['String']>;
  role_id_not_true?: Maybe<Scalars['String']>;
  role_id_false?: Maybe<Scalars['String']>;
  role_id_not_false?: Maybe<Scalars['String']>;
  role_id_present?: Maybe<Scalars['String']>;
  role_id_blank?: Maybe<Scalars['String']>;
  role_id_null?: Maybe<Scalars['String']>;
  role_id_not_null?: Maybe<Scalars['String']>;
  role_id_count?: Maybe<Scalars['String']>;
  role_id_count_distinct?: Maybe<Scalars['String']>;
  role_id_sum?: Maybe<Scalars['String']>;
  role_id_avg?: Maybe<Scalars['String']>;
  role_id_min?: Maybe<Scalars['String']>;
  role_id_max?: Maybe<Scalars['String']>;
  preferences_eq?: Maybe<Scalars['String']>;
  preferences_eq_any?: Maybe<Scalars['String']>;
  preferences_eq_all?: Maybe<Scalars['String']>;
  preferences_not_eq?: Maybe<Scalars['String']>;
  preferences_not_eq_any?: Maybe<Scalars['String']>;
  preferences_not_eq_all?: Maybe<Scalars['String']>;
  preferences_matches?: Maybe<Scalars['String']>;
  preferences_matches_any?: Maybe<Scalars['String']>;
  preferences_matches_all?: Maybe<Scalars['String']>;
  preferences_does_not_match?: Maybe<Scalars['String']>;
  preferences_does_not_match_any?: Maybe<Scalars['String']>;
  preferences_does_not_match_all?: Maybe<Scalars['String']>;
  preferences_lt?: Maybe<Scalars['String']>;
  preferences_lt_any?: Maybe<Scalars['String']>;
  preferences_lt_all?: Maybe<Scalars['String']>;
  preferences_lteq?: Maybe<Scalars['String']>;
  preferences_lteq_any?: Maybe<Scalars['String']>;
  preferences_lteq_all?: Maybe<Scalars['String']>;
  preferences_gt?: Maybe<Scalars['String']>;
  preferences_gt_any?: Maybe<Scalars['String']>;
  preferences_gt_all?: Maybe<Scalars['String']>;
  preferences_gteq?: Maybe<Scalars['String']>;
  preferences_gteq_any?: Maybe<Scalars['String']>;
  preferences_gteq_all?: Maybe<Scalars['String']>;
  preferences_in?: Maybe<Scalars['String']>;
  preferences_in_any?: Maybe<Scalars['String']>;
  preferences_in_all?: Maybe<Scalars['String']>;
  preferences_not_in?: Maybe<Scalars['String']>;
  preferences_not_in_any?: Maybe<Scalars['String']>;
  preferences_not_in_all?: Maybe<Scalars['String']>;
  preferences_cont?: Maybe<Scalars['String']>;
  preferences_cont_any?: Maybe<Scalars['String']>;
  preferences_cont_all?: Maybe<Scalars['String']>;
  preferences_not_cont?: Maybe<Scalars['String']>;
  preferences_not_cont_any?: Maybe<Scalars['String']>;
  preferences_not_cont_all?: Maybe<Scalars['String']>;
  preferences_i_cont?: Maybe<Scalars['String']>;
  preferences_i_cont_any?: Maybe<Scalars['String']>;
  preferences_i_cont_all?: Maybe<Scalars['String']>;
  preferences_not_i_cont?: Maybe<Scalars['String']>;
  preferences_not_i_cont_any?: Maybe<Scalars['String']>;
  preferences_not_i_cont_all?: Maybe<Scalars['String']>;
  preferences_start?: Maybe<Scalars['String']>;
  preferences_start_any?: Maybe<Scalars['String']>;
  preferences_start_all?: Maybe<Scalars['String']>;
  preferences_not_start?: Maybe<Scalars['String']>;
  preferences_not_start_any?: Maybe<Scalars['String']>;
  preferences_not_start_all?: Maybe<Scalars['String']>;
  preferences_end?: Maybe<Scalars['String']>;
  preferences_end_any?: Maybe<Scalars['String']>;
  preferences_end_all?: Maybe<Scalars['String']>;
  preferences_not_end?: Maybe<Scalars['String']>;
  preferences_not_end_any?: Maybe<Scalars['String']>;
  preferences_not_end_all?: Maybe<Scalars['String']>;
  preferences_true?: Maybe<Scalars['String']>;
  preferences_not_true?: Maybe<Scalars['String']>;
  preferences_false?: Maybe<Scalars['String']>;
  preferences_not_false?: Maybe<Scalars['String']>;
  preferences_present?: Maybe<Scalars['String']>;
  preferences_blank?: Maybe<Scalars['String']>;
  preferences_null?: Maybe<Scalars['String']>;
  preferences_not_null?: Maybe<Scalars['String']>;
  preferences_count?: Maybe<Scalars['String']>;
  preferences_count_distinct?: Maybe<Scalars['String']>;
  preferences_sum?: Maybe<Scalars['String']>;
  preferences_avg?: Maybe<Scalars['String']>;
  preferences_min?: Maybe<Scalars['String']>;
  preferences_max?: Maybe<Scalars['String']>;
  engagement_rate_load_eq?: Maybe<Scalars['String']>;
  engagement_rate_load_eq_any?: Maybe<Scalars['String']>;
  engagement_rate_load_eq_all?: Maybe<Scalars['String']>;
  engagement_rate_load_not_eq?: Maybe<Scalars['String']>;
  engagement_rate_load_not_eq_any?: Maybe<Scalars['String']>;
  engagement_rate_load_not_eq_all?: Maybe<Scalars['String']>;
  engagement_rate_load_matches?: Maybe<Scalars['String']>;
  engagement_rate_load_matches_any?: Maybe<Scalars['String']>;
  engagement_rate_load_matches_all?: Maybe<Scalars['String']>;
  engagement_rate_load_does_not_match?: Maybe<Scalars['String']>;
  engagement_rate_load_does_not_match_any?: Maybe<Scalars['String']>;
  engagement_rate_load_does_not_match_all?: Maybe<Scalars['String']>;
  engagement_rate_load_lt?: Maybe<Scalars['String']>;
  engagement_rate_load_lt_any?: Maybe<Scalars['String']>;
  engagement_rate_load_lt_all?: Maybe<Scalars['String']>;
  engagement_rate_load_lteq?: Maybe<Scalars['String']>;
  engagement_rate_load_lteq_any?: Maybe<Scalars['String']>;
  engagement_rate_load_lteq_all?: Maybe<Scalars['String']>;
  engagement_rate_load_gt?: Maybe<Scalars['String']>;
  engagement_rate_load_gt_any?: Maybe<Scalars['String']>;
  engagement_rate_load_gt_all?: Maybe<Scalars['String']>;
  engagement_rate_load_gteq?: Maybe<Scalars['String']>;
  engagement_rate_load_gteq_any?: Maybe<Scalars['String']>;
  engagement_rate_load_gteq_all?: Maybe<Scalars['String']>;
  engagement_rate_load_in?: Maybe<Scalars['String']>;
  engagement_rate_load_in_any?: Maybe<Scalars['String']>;
  engagement_rate_load_in_all?: Maybe<Scalars['String']>;
  engagement_rate_load_not_in?: Maybe<Scalars['String']>;
  engagement_rate_load_not_in_any?: Maybe<Scalars['String']>;
  engagement_rate_load_not_in_all?: Maybe<Scalars['String']>;
  engagement_rate_load_cont?: Maybe<Scalars['String']>;
  engagement_rate_load_cont_any?: Maybe<Scalars['String']>;
  engagement_rate_load_cont_all?: Maybe<Scalars['String']>;
  engagement_rate_load_not_cont?: Maybe<Scalars['String']>;
  engagement_rate_load_not_cont_any?: Maybe<Scalars['String']>;
  engagement_rate_load_not_cont_all?: Maybe<Scalars['String']>;
  engagement_rate_load_i_cont?: Maybe<Scalars['String']>;
  engagement_rate_load_i_cont_any?: Maybe<Scalars['String']>;
  engagement_rate_load_i_cont_all?: Maybe<Scalars['String']>;
  engagement_rate_load_not_i_cont?: Maybe<Scalars['String']>;
  engagement_rate_load_not_i_cont_any?: Maybe<Scalars['String']>;
  engagement_rate_load_not_i_cont_all?: Maybe<Scalars['String']>;
  engagement_rate_load_start?: Maybe<Scalars['String']>;
  engagement_rate_load_start_any?: Maybe<Scalars['String']>;
  engagement_rate_load_start_all?: Maybe<Scalars['String']>;
  engagement_rate_load_not_start?: Maybe<Scalars['String']>;
  engagement_rate_load_not_start_any?: Maybe<Scalars['String']>;
  engagement_rate_load_not_start_all?: Maybe<Scalars['String']>;
  engagement_rate_load_end?: Maybe<Scalars['String']>;
  engagement_rate_load_end_any?: Maybe<Scalars['String']>;
  engagement_rate_load_end_all?: Maybe<Scalars['String']>;
  engagement_rate_load_not_end?: Maybe<Scalars['String']>;
  engagement_rate_load_not_end_any?: Maybe<Scalars['String']>;
  engagement_rate_load_not_end_all?: Maybe<Scalars['String']>;
  engagement_rate_load_true?: Maybe<Scalars['String']>;
  engagement_rate_load_not_true?: Maybe<Scalars['String']>;
  engagement_rate_load_false?: Maybe<Scalars['String']>;
  engagement_rate_load_not_false?: Maybe<Scalars['String']>;
  engagement_rate_load_present?: Maybe<Scalars['String']>;
  engagement_rate_load_blank?: Maybe<Scalars['String']>;
  engagement_rate_load_null?: Maybe<Scalars['String']>;
  engagement_rate_load_not_null?: Maybe<Scalars['String']>;
  engagement_rate_load_count?: Maybe<Scalars['String']>;
  engagement_rate_load_count_distinct?: Maybe<Scalars['String']>;
  engagement_rate_load_sum?: Maybe<Scalars['String']>;
  engagement_rate_load_avg?: Maybe<Scalars['String']>;
  engagement_rate_load_min?: Maybe<Scalars['String']>;
  engagement_rate_load_max?: Maybe<Scalars['String']>;
  engagement_only_eq?: Maybe<Scalars['String']>;
  engagement_only_eq_any?: Maybe<Scalars['String']>;
  engagement_only_eq_all?: Maybe<Scalars['String']>;
  engagement_only_not_eq?: Maybe<Scalars['String']>;
  engagement_only_not_eq_any?: Maybe<Scalars['String']>;
  engagement_only_not_eq_all?: Maybe<Scalars['String']>;
  engagement_only_matches?: Maybe<Scalars['String']>;
  engagement_only_matches_any?: Maybe<Scalars['String']>;
  engagement_only_matches_all?: Maybe<Scalars['String']>;
  engagement_only_does_not_match?: Maybe<Scalars['String']>;
  engagement_only_does_not_match_any?: Maybe<Scalars['String']>;
  engagement_only_does_not_match_all?: Maybe<Scalars['String']>;
  engagement_only_lt?: Maybe<Scalars['String']>;
  engagement_only_lt_any?: Maybe<Scalars['String']>;
  engagement_only_lt_all?: Maybe<Scalars['String']>;
  engagement_only_lteq?: Maybe<Scalars['String']>;
  engagement_only_lteq_any?: Maybe<Scalars['String']>;
  engagement_only_lteq_all?: Maybe<Scalars['String']>;
  engagement_only_gt?: Maybe<Scalars['String']>;
  engagement_only_gt_any?: Maybe<Scalars['String']>;
  engagement_only_gt_all?: Maybe<Scalars['String']>;
  engagement_only_gteq?: Maybe<Scalars['String']>;
  engagement_only_gteq_any?: Maybe<Scalars['String']>;
  engagement_only_gteq_all?: Maybe<Scalars['String']>;
  engagement_only_in?: Maybe<Scalars['String']>;
  engagement_only_in_any?: Maybe<Scalars['String']>;
  engagement_only_in_all?: Maybe<Scalars['String']>;
  engagement_only_not_in?: Maybe<Scalars['String']>;
  engagement_only_not_in_any?: Maybe<Scalars['String']>;
  engagement_only_not_in_all?: Maybe<Scalars['String']>;
  engagement_only_cont?: Maybe<Scalars['String']>;
  engagement_only_cont_any?: Maybe<Scalars['String']>;
  engagement_only_cont_all?: Maybe<Scalars['String']>;
  engagement_only_not_cont?: Maybe<Scalars['String']>;
  engagement_only_not_cont_any?: Maybe<Scalars['String']>;
  engagement_only_not_cont_all?: Maybe<Scalars['String']>;
  engagement_only_i_cont?: Maybe<Scalars['String']>;
  engagement_only_i_cont_any?: Maybe<Scalars['String']>;
  engagement_only_i_cont_all?: Maybe<Scalars['String']>;
  engagement_only_not_i_cont?: Maybe<Scalars['String']>;
  engagement_only_not_i_cont_any?: Maybe<Scalars['String']>;
  engagement_only_not_i_cont_all?: Maybe<Scalars['String']>;
  engagement_only_start?: Maybe<Scalars['String']>;
  engagement_only_start_any?: Maybe<Scalars['String']>;
  engagement_only_start_all?: Maybe<Scalars['String']>;
  engagement_only_not_start?: Maybe<Scalars['String']>;
  engagement_only_not_start_any?: Maybe<Scalars['String']>;
  engagement_only_not_start_all?: Maybe<Scalars['String']>;
  engagement_only_end?: Maybe<Scalars['String']>;
  engagement_only_end_any?: Maybe<Scalars['String']>;
  engagement_only_end_all?: Maybe<Scalars['String']>;
  engagement_only_not_end?: Maybe<Scalars['String']>;
  engagement_only_not_end_any?: Maybe<Scalars['String']>;
  engagement_only_not_end_all?: Maybe<Scalars['String']>;
  engagement_only_true?: Maybe<Scalars['String']>;
  engagement_only_not_true?: Maybe<Scalars['String']>;
  engagement_only_false?: Maybe<Scalars['String']>;
  engagement_only_not_false?: Maybe<Scalars['String']>;
  engagement_only_present?: Maybe<Scalars['String']>;
  engagement_only_blank?: Maybe<Scalars['String']>;
  engagement_only_null?: Maybe<Scalars['String']>;
  engagement_only_not_null?: Maybe<Scalars['String']>;
  engagement_only_count?: Maybe<Scalars['String']>;
  engagement_only_count_distinct?: Maybe<Scalars['String']>;
  engagement_only_sum?: Maybe<Scalars['String']>;
  engagement_only_avg?: Maybe<Scalars['String']>;
  engagement_only_min?: Maybe<Scalars['String']>;
  engagement_only_max?: Maybe<Scalars['String']>;
  new_hire_eq?: Maybe<Scalars['String']>;
  new_hire_eq_any?: Maybe<Scalars['String']>;
  new_hire_eq_all?: Maybe<Scalars['String']>;
  new_hire_not_eq?: Maybe<Scalars['String']>;
  new_hire_not_eq_any?: Maybe<Scalars['String']>;
  new_hire_not_eq_all?: Maybe<Scalars['String']>;
  new_hire_matches?: Maybe<Scalars['String']>;
  new_hire_matches_any?: Maybe<Scalars['String']>;
  new_hire_matches_all?: Maybe<Scalars['String']>;
  new_hire_does_not_match?: Maybe<Scalars['String']>;
  new_hire_does_not_match_any?: Maybe<Scalars['String']>;
  new_hire_does_not_match_all?: Maybe<Scalars['String']>;
  new_hire_lt?: Maybe<Scalars['String']>;
  new_hire_lt_any?: Maybe<Scalars['String']>;
  new_hire_lt_all?: Maybe<Scalars['String']>;
  new_hire_lteq?: Maybe<Scalars['String']>;
  new_hire_lteq_any?: Maybe<Scalars['String']>;
  new_hire_lteq_all?: Maybe<Scalars['String']>;
  new_hire_gt?: Maybe<Scalars['String']>;
  new_hire_gt_any?: Maybe<Scalars['String']>;
  new_hire_gt_all?: Maybe<Scalars['String']>;
  new_hire_gteq?: Maybe<Scalars['String']>;
  new_hire_gteq_any?: Maybe<Scalars['String']>;
  new_hire_gteq_all?: Maybe<Scalars['String']>;
  new_hire_in?: Maybe<Scalars['String']>;
  new_hire_in_any?: Maybe<Scalars['String']>;
  new_hire_in_all?: Maybe<Scalars['String']>;
  new_hire_not_in?: Maybe<Scalars['String']>;
  new_hire_not_in_any?: Maybe<Scalars['String']>;
  new_hire_not_in_all?: Maybe<Scalars['String']>;
  new_hire_cont?: Maybe<Scalars['String']>;
  new_hire_cont_any?: Maybe<Scalars['String']>;
  new_hire_cont_all?: Maybe<Scalars['String']>;
  new_hire_not_cont?: Maybe<Scalars['String']>;
  new_hire_not_cont_any?: Maybe<Scalars['String']>;
  new_hire_not_cont_all?: Maybe<Scalars['String']>;
  new_hire_i_cont?: Maybe<Scalars['String']>;
  new_hire_i_cont_any?: Maybe<Scalars['String']>;
  new_hire_i_cont_all?: Maybe<Scalars['String']>;
  new_hire_not_i_cont?: Maybe<Scalars['String']>;
  new_hire_not_i_cont_any?: Maybe<Scalars['String']>;
  new_hire_not_i_cont_all?: Maybe<Scalars['String']>;
  new_hire_start?: Maybe<Scalars['String']>;
  new_hire_start_any?: Maybe<Scalars['String']>;
  new_hire_start_all?: Maybe<Scalars['String']>;
  new_hire_not_start?: Maybe<Scalars['String']>;
  new_hire_not_start_any?: Maybe<Scalars['String']>;
  new_hire_not_start_all?: Maybe<Scalars['String']>;
  new_hire_end?: Maybe<Scalars['String']>;
  new_hire_end_any?: Maybe<Scalars['String']>;
  new_hire_end_all?: Maybe<Scalars['String']>;
  new_hire_not_end?: Maybe<Scalars['String']>;
  new_hire_not_end_any?: Maybe<Scalars['String']>;
  new_hire_not_end_all?: Maybe<Scalars['String']>;
  new_hire_true?: Maybe<Scalars['String']>;
  new_hire_not_true?: Maybe<Scalars['String']>;
  new_hire_false?: Maybe<Scalars['String']>;
  new_hire_not_false?: Maybe<Scalars['String']>;
  new_hire_present?: Maybe<Scalars['String']>;
  new_hire_blank?: Maybe<Scalars['String']>;
  new_hire_null?: Maybe<Scalars['String']>;
  new_hire_not_null?: Maybe<Scalars['String']>;
  new_hire_count?: Maybe<Scalars['String']>;
  new_hire_count_distinct?: Maybe<Scalars['String']>;
  new_hire_sum?: Maybe<Scalars['String']>;
  new_hire_avg?: Maybe<Scalars['String']>;
  new_hire_min?: Maybe<Scalars['String']>;
  new_hire_max?: Maybe<Scalars['String']>;
  mail_time_eq?: Maybe<Scalars['String']>;
  mail_time_eq_any?: Maybe<Scalars['String']>;
  mail_time_eq_all?: Maybe<Scalars['String']>;
  mail_time_not_eq?: Maybe<Scalars['String']>;
  mail_time_not_eq_any?: Maybe<Scalars['String']>;
  mail_time_not_eq_all?: Maybe<Scalars['String']>;
  mail_time_matches?: Maybe<Scalars['String']>;
  mail_time_matches_any?: Maybe<Scalars['String']>;
  mail_time_matches_all?: Maybe<Scalars['String']>;
  mail_time_does_not_match?: Maybe<Scalars['String']>;
  mail_time_does_not_match_any?: Maybe<Scalars['String']>;
  mail_time_does_not_match_all?: Maybe<Scalars['String']>;
  mail_time_lt?: Maybe<Scalars['String']>;
  mail_time_lt_any?: Maybe<Scalars['String']>;
  mail_time_lt_all?: Maybe<Scalars['String']>;
  mail_time_lteq?: Maybe<Scalars['String']>;
  mail_time_lteq_any?: Maybe<Scalars['String']>;
  mail_time_lteq_all?: Maybe<Scalars['String']>;
  mail_time_gt?: Maybe<Scalars['String']>;
  mail_time_gt_any?: Maybe<Scalars['String']>;
  mail_time_gt_all?: Maybe<Scalars['String']>;
  mail_time_gteq?: Maybe<Scalars['String']>;
  mail_time_gteq_any?: Maybe<Scalars['String']>;
  mail_time_gteq_all?: Maybe<Scalars['String']>;
  mail_time_in?: Maybe<Scalars['String']>;
  mail_time_in_any?: Maybe<Scalars['String']>;
  mail_time_in_all?: Maybe<Scalars['String']>;
  mail_time_not_in?: Maybe<Scalars['String']>;
  mail_time_not_in_any?: Maybe<Scalars['String']>;
  mail_time_not_in_all?: Maybe<Scalars['String']>;
  mail_time_cont?: Maybe<Scalars['String']>;
  mail_time_cont_any?: Maybe<Scalars['String']>;
  mail_time_cont_all?: Maybe<Scalars['String']>;
  mail_time_not_cont?: Maybe<Scalars['String']>;
  mail_time_not_cont_any?: Maybe<Scalars['String']>;
  mail_time_not_cont_all?: Maybe<Scalars['String']>;
  mail_time_i_cont?: Maybe<Scalars['String']>;
  mail_time_i_cont_any?: Maybe<Scalars['String']>;
  mail_time_i_cont_all?: Maybe<Scalars['String']>;
  mail_time_not_i_cont?: Maybe<Scalars['String']>;
  mail_time_not_i_cont_any?: Maybe<Scalars['String']>;
  mail_time_not_i_cont_all?: Maybe<Scalars['String']>;
  mail_time_start?: Maybe<Scalars['String']>;
  mail_time_start_any?: Maybe<Scalars['String']>;
  mail_time_start_all?: Maybe<Scalars['String']>;
  mail_time_not_start?: Maybe<Scalars['String']>;
  mail_time_not_start_any?: Maybe<Scalars['String']>;
  mail_time_not_start_all?: Maybe<Scalars['String']>;
  mail_time_end?: Maybe<Scalars['String']>;
  mail_time_end_any?: Maybe<Scalars['String']>;
  mail_time_end_all?: Maybe<Scalars['String']>;
  mail_time_not_end?: Maybe<Scalars['String']>;
  mail_time_not_end_any?: Maybe<Scalars['String']>;
  mail_time_not_end_all?: Maybe<Scalars['String']>;
  mail_time_true?: Maybe<Scalars['String']>;
  mail_time_not_true?: Maybe<Scalars['String']>;
  mail_time_false?: Maybe<Scalars['String']>;
  mail_time_not_false?: Maybe<Scalars['String']>;
  mail_time_present?: Maybe<Scalars['String']>;
  mail_time_blank?: Maybe<Scalars['String']>;
  mail_time_null?: Maybe<Scalars['String']>;
  mail_time_not_null?: Maybe<Scalars['String']>;
  mail_time_count?: Maybe<Scalars['String']>;
  mail_time_count_distinct?: Maybe<Scalars['String']>;
  mail_time_sum?: Maybe<Scalars['String']>;
  mail_time_avg?: Maybe<Scalars['String']>;
  mail_time_min?: Maybe<Scalars['String']>;
  mail_time_max?: Maybe<Scalars['String']>;
  last_login_eq?: Maybe<Scalars['String']>;
  last_login_eq_any?: Maybe<Scalars['String']>;
  last_login_eq_all?: Maybe<Scalars['String']>;
  last_login_not_eq?: Maybe<Scalars['String']>;
  last_login_not_eq_any?: Maybe<Scalars['String']>;
  last_login_not_eq_all?: Maybe<Scalars['String']>;
  last_login_matches?: Maybe<Scalars['String']>;
  last_login_matches_any?: Maybe<Scalars['String']>;
  last_login_matches_all?: Maybe<Scalars['String']>;
  last_login_does_not_match?: Maybe<Scalars['String']>;
  last_login_does_not_match_any?: Maybe<Scalars['String']>;
  last_login_does_not_match_all?: Maybe<Scalars['String']>;
  last_login_lt?: Maybe<Scalars['String']>;
  last_login_lt_any?: Maybe<Scalars['String']>;
  last_login_lt_all?: Maybe<Scalars['String']>;
  last_login_lteq?: Maybe<Scalars['String']>;
  last_login_lteq_any?: Maybe<Scalars['String']>;
  last_login_lteq_all?: Maybe<Scalars['String']>;
  last_login_gt?: Maybe<Scalars['String']>;
  last_login_gt_any?: Maybe<Scalars['String']>;
  last_login_gt_all?: Maybe<Scalars['String']>;
  last_login_gteq?: Maybe<Scalars['String']>;
  last_login_gteq_any?: Maybe<Scalars['String']>;
  last_login_gteq_all?: Maybe<Scalars['String']>;
  last_login_in?: Maybe<Scalars['String']>;
  last_login_in_any?: Maybe<Scalars['String']>;
  last_login_in_all?: Maybe<Scalars['String']>;
  last_login_not_in?: Maybe<Scalars['String']>;
  last_login_not_in_any?: Maybe<Scalars['String']>;
  last_login_not_in_all?: Maybe<Scalars['String']>;
  last_login_cont?: Maybe<Scalars['String']>;
  last_login_cont_any?: Maybe<Scalars['String']>;
  last_login_cont_all?: Maybe<Scalars['String']>;
  last_login_not_cont?: Maybe<Scalars['String']>;
  last_login_not_cont_any?: Maybe<Scalars['String']>;
  last_login_not_cont_all?: Maybe<Scalars['String']>;
  last_login_i_cont?: Maybe<Scalars['String']>;
  last_login_i_cont_any?: Maybe<Scalars['String']>;
  last_login_i_cont_all?: Maybe<Scalars['String']>;
  last_login_not_i_cont?: Maybe<Scalars['String']>;
  last_login_not_i_cont_any?: Maybe<Scalars['String']>;
  last_login_not_i_cont_all?: Maybe<Scalars['String']>;
  last_login_start?: Maybe<Scalars['String']>;
  last_login_start_any?: Maybe<Scalars['String']>;
  last_login_start_all?: Maybe<Scalars['String']>;
  last_login_not_start?: Maybe<Scalars['String']>;
  last_login_not_start_any?: Maybe<Scalars['String']>;
  last_login_not_start_all?: Maybe<Scalars['String']>;
  last_login_end?: Maybe<Scalars['String']>;
  last_login_end_any?: Maybe<Scalars['String']>;
  last_login_end_all?: Maybe<Scalars['String']>;
  last_login_not_end?: Maybe<Scalars['String']>;
  last_login_not_end_any?: Maybe<Scalars['String']>;
  last_login_not_end_all?: Maybe<Scalars['String']>;
  last_login_true?: Maybe<Scalars['String']>;
  last_login_not_true?: Maybe<Scalars['String']>;
  last_login_false?: Maybe<Scalars['String']>;
  last_login_not_false?: Maybe<Scalars['String']>;
  last_login_present?: Maybe<Scalars['String']>;
  last_login_blank?: Maybe<Scalars['String']>;
  last_login_null?: Maybe<Scalars['String']>;
  last_login_not_null?: Maybe<Scalars['String']>;
  last_login_count?: Maybe<Scalars['String']>;
  last_login_count_distinct?: Maybe<Scalars['String']>;
  last_login_sum?: Maybe<Scalars['String']>;
  last_login_avg?: Maybe<Scalars['String']>;
  last_login_min?: Maybe<Scalars['String']>;
  last_login_max?: Maybe<Scalars['String']>;
  last_random_cta_eq?: Maybe<Scalars['String']>;
  last_random_cta_eq_any?: Maybe<Scalars['String']>;
  last_random_cta_eq_all?: Maybe<Scalars['String']>;
  last_random_cta_not_eq?: Maybe<Scalars['String']>;
  last_random_cta_not_eq_any?: Maybe<Scalars['String']>;
  last_random_cta_not_eq_all?: Maybe<Scalars['String']>;
  last_random_cta_matches?: Maybe<Scalars['String']>;
  last_random_cta_matches_any?: Maybe<Scalars['String']>;
  last_random_cta_matches_all?: Maybe<Scalars['String']>;
  last_random_cta_does_not_match?: Maybe<Scalars['String']>;
  last_random_cta_does_not_match_any?: Maybe<Scalars['String']>;
  last_random_cta_does_not_match_all?: Maybe<Scalars['String']>;
  last_random_cta_lt?: Maybe<Scalars['String']>;
  last_random_cta_lt_any?: Maybe<Scalars['String']>;
  last_random_cta_lt_all?: Maybe<Scalars['String']>;
  last_random_cta_lteq?: Maybe<Scalars['String']>;
  last_random_cta_lteq_any?: Maybe<Scalars['String']>;
  last_random_cta_lteq_all?: Maybe<Scalars['String']>;
  last_random_cta_gt?: Maybe<Scalars['String']>;
  last_random_cta_gt_any?: Maybe<Scalars['String']>;
  last_random_cta_gt_all?: Maybe<Scalars['String']>;
  last_random_cta_gteq?: Maybe<Scalars['String']>;
  last_random_cta_gteq_any?: Maybe<Scalars['String']>;
  last_random_cta_gteq_all?: Maybe<Scalars['String']>;
  last_random_cta_in?: Maybe<Scalars['String']>;
  last_random_cta_in_any?: Maybe<Scalars['String']>;
  last_random_cta_in_all?: Maybe<Scalars['String']>;
  last_random_cta_not_in?: Maybe<Scalars['String']>;
  last_random_cta_not_in_any?: Maybe<Scalars['String']>;
  last_random_cta_not_in_all?: Maybe<Scalars['String']>;
  last_random_cta_cont?: Maybe<Scalars['String']>;
  last_random_cta_cont_any?: Maybe<Scalars['String']>;
  last_random_cta_cont_all?: Maybe<Scalars['String']>;
  last_random_cta_not_cont?: Maybe<Scalars['String']>;
  last_random_cta_not_cont_any?: Maybe<Scalars['String']>;
  last_random_cta_not_cont_all?: Maybe<Scalars['String']>;
  last_random_cta_i_cont?: Maybe<Scalars['String']>;
  last_random_cta_i_cont_any?: Maybe<Scalars['String']>;
  last_random_cta_i_cont_all?: Maybe<Scalars['String']>;
  last_random_cta_not_i_cont?: Maybe<Scalars['String']>;
  last_random_cta_not_i_cont_any?: Maybe<Scalars['String']>;
  last_random_cta_not_i_cont_all?: Maybe<Scalars['String']>;
  last_random_cta_start?: Maybe<Scalars['String']>;
  last_random_cta_start_any?: Maybe<Scalars['String']>;
  last_random_cta_start_all?: Maybe<Scalars['String']>;
  last_random_cta_not_start?: Maybe<Scalars['String']>;
  last_random_cta_not_start_any?: Maybe<Scalars['String']>;
  last_random_cta_not_start_all?: Maybe<Scalars['String']>;
  last_random_cta_end?: Maybe<Scalars['String']>;
  last_random_cta_end_any?: Maybe<Scalars['String']>;
  last_random_cta_end_all?: Maybe<Scalars['String']>;
  last_random_cta_not_end?: Maybe<Scalars['String']>;
  last_random_cta_not_end_any?: Maybe<Scalars['String']>;
  last_random_cta_not_end_all?: Maybe<Scalars['String']>;
  last_random_cta_true?: Maybe<Scalars['String']>;
  last_random_cta_not_true?: Maybe<Scalars['String']>;
  last_random_cta_false?: Maybe<Scalars['String']>;
  last_random_cta_not_false?: Maybe<Scalars['String']>;
  last_random_cta_present?: Maybe<Scalars['String']>;
  last_random_cta_blank?: Maybe<Scalars['String']>;
  last_random_cta_null?: Maybe<Scalars['String']>;
  last_random_cta_not_null?: Maybe<Scalars['String']>;
  last_random_cta_count?: Maybe<Scalars['String']>;
  last_random_cta_count_distinct?: Maybe<Scalars['String']>;
  last_random_cta_sum?: Maybe<Scalars['String']>;
  last_random_cta_avg?: Maybe<Scalars['String']>;
  last_random_cta_min?: Maybe<Scalars['String']>;
  last_random_cta_max?: Maybe<Scalars['String']>;
  phone_number_eq?: Maybe<Scalars['String']>;
  phone_number_eq_any?: Maybe<Scalars['String']>;
  phone_number_eq_all?: Maybe<Scalars['String']>;
  phone_number_not_eq?: Maybe<Scalars['String']>;
  phone_number_not_eq_any?: Maybe<Scalars['String']>;
  phone_number_not_eq_all?: Maybe<Scalars['String']>;
  phone_number_matches?: Maybe<Scalars['String']>;
  phone_number_matches_any?: Maybe<Scalars['String']>;
  phone_number_matches_all?: Maybe<Scalars['String']>;
  phone_number_does_not_match?: Maybe<Scalars['String']>;
  phone_number_does_not_match_any?: Maybe<Scalars['String']>;
  phone_number_does_not_match_all?: Maybe<Scalars['String']>;
  phone_number_lt?: Maybe<Scalars['String']>;
  phone_number_lt_any?: Maybe<Scalars['String']>;
  phone_number_lt_all?: Maybe<Scalars['String']>;
  phone_number_lteq?: Maybe<Scalars['String']>;
  phone_number_lteq_any?: Maybe<Scalars['String']>;
  phone_number_lteq_all?: Maybe<Scalars['String']>;
  phone_number_gt?: Maybe<Scalars['String']>;
  phone_number_gt_any?: Maybe<Scalars['String']>;
  phone_number_gt_all?: Maybe<Scalars['String']>;
  phone_number_gteq?: Maybe<Scalars['String']>;
  phone_number_gteq_any?: Maybe<Scalars['String']>;
  phone_number_gteq_all?: Maybe<Scalars['String']>;
  phone_number_in?: Maybe<Scalars['String']>;
  phone_number_in_any?: Maybe<Scalars['String']>;
  phone_number_in_all?: Maybe<Scalars['String']>;
  phone_number_not_in?: Maybe<Scalars['String']>;
  phone_number_not_in_any?: Maybe<Scalars['String']>;
  phone_number_not_in_all?: Maybe<Scalars['String']>;
  phone_number_cont?: Maybe<Scalars['String']>;
  phone_number_cont_any?: Maybe<Scalars['String']>;
  phone_number_cont_all?: Maybe<Scalars['String']>;
  phone_number_not_cont?: Maybe<Scalars['String']>;
  phone_number_not_cont_any?: Maybe<Scalars['String']>;
  phone_number_not_cont_all?: Maybe<Scalars['String']>;
  phone_number_i_cont?: Maybe<Scalars['String']>;
  phone_number_i_cont_any?: Maybe<Scalars['String']>;
  phone_number_i_cont_all?: Maybe<Scalars['String']>;
  phone_number_not_i_cont?: Maybe<Scalars['String']>;
  phone_number_not_i_cont_any?: Maybe<Scalars['String']>;
  phone_number_not_i_cont_all?: Maybe<Scalars['String']>;
  phone_number_start?: Maybe<Scalars['String']>;
  phone_number_start_any?: Maybe<Scalars['String']>;
  phone_number_start_all?: Maybe<Scalars['String']>;
  phone_number_not_start?: Maybe<Scalars['String']>;
  phone_number_not_start_any?: Maybe<Scalars['String']>;
  phone_number_not_start_all?: Maybe<Scalars['String']>;
  phone_number_end?: Maybe<Scalars['String']>;
  phone_number_end_any?: Maybe<Scalars['String']>;
  phone_number_end_all?: Maybe<Scalars['String']>;
  phone_number_not_end?: Maybe<Scalars['String']>;
  phone_number_not_end_any?: Maybe<Scalars['String']>;
  phone_number_not_end_all?: Maybe<Scalars['String']>;
  phone_number_true?: Maybe<Scalars['String']>;
  phone_number_not_true?: Maybe<Scalars['String']>;
  phone_number_false?: Maybe<Scalars['String']>;
  phone_number_not_false?: Maybe<Scalars['String']>;
  phone_number_present?: Maybe<Scalars['String']>;
  phone_number_blank?: Maybe<Scalars['String']>;
  phone_number_null?: Maybe<Scalars['String']>;
  phone_number_not_null?: Maybe<Scalars['String']>;
  phone_number_count?: Maybe<Scalars['String']>;
  phone_number_count_distinct?: Maybe<Scalars['String']>;
  phone_number_sum?: Maybe<Scalars['String']>;
  phone_number_avg?: Maybe<Scalars['String']>;
  phone_number_min?: Maybe<Scalars['String']>;
  phone_number_max?: Maybe<Scalars['String']>;
  onboarded_eq?: Maybe<Scalars['String']>;
  onboarded_eq_any?: Maybe<Scalars['String']>;
  onboarded_eq_all?: Maybe<Scalars['String']>;
  onboarded_not_eq?: Maybe<Scalars['String']>;
  onboarded_not_eq_any?: Maybe<Scalars['String']>;
  onboarded_not_eq_all?: Maybe<Scalars['String']>;
  onboarded_matches?: Maybe<Scalars['String']>;
  onboarded_matches_any?: Maybe<Scalars['String']>;
  onboarded_matches_all?: Maybe<Scalars['String']>;
  onboarded_does_not_match?: Maybe<Scalars['String']>;
  onboarded_does_not_match_any?: Maybe<Scalars['String']>;
  onboarded_does_not_match_all?: Maybe<Scalars['String']>;
  onboarded_lt?: Maybe<Scalars['String']>;
  onboarded_lt_any?: Maybe<Scalars['String']>;
  onboarded_lt_all?: Maybe<Scalars['String']>;
  onboarded_lteq?: Maybe<Scalars['String']>;
  onboarded_lteq_any?: Maybe<Scalars['String']>;
  onboarded_lteq_all?: Maybe<Scalars['String']>;
  onboarded_gt?: Maybe<Scalars['String']>;
  onboarded_gt_any?: Maybe<Scalars['String']>;
  onboarded_gt_all?: Maybe<Scalars['String']>;
  onboarded_gteq?: Maybe<Scalars['String']>;
  onboarded_gteq_any?: Maybe<Scalars['String']>;
  onboarded_gteq_all?: Maybe<Scalars['String']>;
  onboarded_in?: Maybe<Scalars['String']>;
  onboarded_in_any?: Maybe<Scalars['String']>;
  onboarded_in_all?: Maybe<Scalars['String']>;
  onboarded_not_in?: Maybe<Scalars['String']>;
  onboarded_not_in_any?: Maybe<Scalars['String']>;
  onboarded_not_in_all?: Maybe<Scalars['String']>;
  onboarded_cont?: Maybe<Scalars['String']>;
  onboarded_cont_any?: Maybe<Scalars['String']>;
  onboarded_cont_all?: Maybe<Scalars['String']>;
  onboarded_not_cont?: Maybe<Scalars['String']>;
  onboarded_not_cont_any?: Maybe<Scalars['String']>;
  onboarded_not_cont_all?: Maybe<Scalars['String']>;
  onboarded_i_cont?: Maybe<Scalars['String']>;
  onboarded_i_cont_any?: Maybe<Scalars['String']>;
  onboarded_i_cont_all?: Maybe<Scalars['String']>;
  onboarded_not_i_cont?: Maybe<Scalars['String']>;
  onboarded_not_i_cont_any?: Maybe<Scalars['String']>;
  onboarded_not_i_cont_all?: Maybe<Scalars['String']>;
  onboarded_start?: Maybe<Scalars['String']>;
  onboarded_start_any?: Maybe<Scalars['String']>;
  onboarded_start_all?: Maybe<Scalars['String']>;
  onboarded_not_start?: Maybe<Scalars['String']>;
  onboarded_not_start_any?: Maybe<Scalars['String']>;
  onboarded_not_start_all?: Maybe<Scalars['String']>;
  onboarded_end?: Maybe<Scalars['String']>;
  onboarded_end_any?: Maybe<Scalars['String']>;
  onboarded_end_all?: Maybe<Scalars['String']>;
  onboarded_not_end?: Maybe<Scalars['String']>;
  onboarded_not_end_any?: Maybe<Scalars['String']>;
  onboarded_not_end_all?: Maybe<Scalars['String']>;
  onboarded_true?: Maybe<Scalars['String']>;
  onboarded_not_true?: Maybe<Scalars['String']>;
  onboarded_false?: Maybe<Scalars['String']>;
  onboarded_not_false?: Maybe<Scalars['String']>;
  onboarded_present?: Maybe<Scalars['String']>;
  onboarded_blank?: Maybe<Scalars['String']>;
  onboarded_null?: Maybe<Scalars['String']>;
  onboarded_not_null?: Maybe<Scalars['String']>;
  onboarded_count?: Maybe<Scalars['String']>;
  onboarded_count_distinct?: Maybe<Scalars['String']>;
  onboarded_sum?: Maybe<Scalars['String']>;
  onboarded_avg?: Maybe<Scalars['String']>;
  onboarded_min?: Maybe<Scalars['String']>;
  onboarded_max?: Maybe<Scalars['String']>;
  jti_eq?: Maybe<Scalars['String']>;
  jti_eq_any?: Maybe<Scalars['String']>;
  jti_eq_all?: Maybe<Scalars['String']>;
  jti_not_eq?: Maybe<Scalars['String']>;
  jti_not_eq_any?: Maybe<Scalars['String']>;
  jti_not_eq_all?: Maybe<Scalars['String']>;
  jti_matches?: Maybe<Scalars['String']>;
  jti_matches_any?: Maybe<Scalars['String']>;
  jti_matches_all?: Maybe<Scalars['String']>;
  jti_does_not_match?: Maybe<Scalars['String']>;
  jti_does_not_match_any?: Maybe<Scalars['String']>;
  jti_does_not_match_all?: Maybe<Scalars['String']>;
  jti_lt?: Maybe<Scalars['String']>;
  jti_lt_any?: Maybe<Scalars['String']>;
  jti_lt_all?: Maybe<Scalars['String']>;
  jti_lteq?: Maybe<Scalars['String']>;
  jti_lteq_any?: Maybe<Scalars['String']>;
  jti_lteq_all?: Maybe<Scalars['String']>;
  jti_gt?: Maybe<Scalars['String']>;
  jti_gt_any?: Maybe<Scalars['String']>;
  jti_gt_all?: Maybe<Scalars['String']>;
  jti_gteq?: Maybe<Scalars['String']>;
  jti_gteq_any?: Maybe<Scalars['String']>;
  jti_gteq_all?: Maybe<Scalars['String']>;
  jti_in?: Maybe<Scalars['String']>;
  jti_in_any?: Maybe<Scalars['String']>;
  jti_in_all?: Maybe<Scalars['String']>;
  jti_not_in?: Maybe<Scalars['String']>;
  jti_not_in_any?: Maybe<Scalars['String']>;
  jti_not_in_all?: Maybe<Scalars['String']>;
  jti_cont?: Maybe<Scalars['String']>;
  jti_cont_any?: Maybe<Scalars['String']>;
  jti_cont_all?: Maybe<Scalars['String']>;
  jti_not_cont?: Maybe<Scalars['String']>;
  jti_not_cont_any?: Maybe<Scalars['String']>;
  jti_not_cont_all?: Maybe<Scalars['String']>;
  jti_i_cont?: Maybe<Scalars['String']>;
  jti_i_cont_any?: Maybe<Scalars['String']>;
  jti_i_cont_all?: Maybe<Scalars['String']>;
  jti_not_i_cont?: Maybe<Scalars['String']>;
  jti_not_i_cont_any?: Maybe<Scalars['String']>;
  jti_not_i_cont_all?: Maybe<Scalars['String']>;
  jti_start?: Maybe<Scalars['String']>;
  jti_start_any?: Maybe<Scalars['String']>;
  jti_start_all?: Maybe<Scalars['String']>;
  jti_not_start?: Maybe<Scalars['String']>;
  jti_not_start_any?: Maybe<Scalars['String']>;
  jti_not_start_all?: Maybe<Scalars['String']>;
  jti_end?: Maybe<Scalars['String']>;
  jti_end_any?: Maybe<Scalars['String']>;
  jti_end_all?: Maybe<Scalars['String']>;
  jti_not_end?: Maybe<Scalars['String']>;
  jti_not_end_any?: Maybe<Scalars['String']>;
  jti_not_end_all?: Maybe<Scalars['String']>;
  jti_true?: Maybe<Scalars['String']>;
  jti_not_true?: Maybe<Scalars['String']>;
  jti_false?: Maybe<Scalars['String']>;
  jti_not_false?: Maybe<Scalars['String']>;
  jti_present?: Maybe<Scalars['String']>;
  jti_blank?: Maybe<Scalars['String']>;
  jti_null?: Maybe<Scalars['String']>;
  jti_not_null?: Maybe<Scalars['String']>;
  jti_count?: Maybe<Scalars['String']>;
  jti_count_distinct?: Maybe<Scalars['String']>;
  jti_sum?: Maybe<Scalars['String']>;
  jti_avg?: Maybe<Scalars['String']>;
  jti_min?: Maybe<Scalars['String']>;
  jti_max?: Maybe<Scalars['String']>;
  sash_id_eq?: Maybe<Scalars['String']>;
  sash_id_eq_any?: Maybe<Scalars['String']>;
  sash_id_eq_all?: Maybe<Scalars['String']>;
  sash_id_not_eq?: Maybe<Scalars['String']>;
  sash_id_not_eq_any?: Maybe<Scalars['String']>;
  sash_id_not_eq_all?: Maybe<Scalars['String']>;
  sash_id_matches?: Maybe<Scalars['String']>;
  sash_id_matches_any?: Maybe<Scalars['String']>;
  sash_id_matches_all?: Maybe<Scalars['String']>;
  sash_id_does_not_match?: Maybe<Scalars['String']>;
  sash_id_does_not_match_any?: Maybe<Scalars['String']>;
  sash_id_does_not_match_all?: Maybe<Scalars['String']>;
  sash_id_lt?: Maybe<Scalars['String']>;
  sash_id_lt_any?: Maybe<Scalars['String']>;
  sash_id_lt_all?: Maybe<Scalars['String']>;
  sash_id_lteq?: Maybe<Scalars['String']>;
  sash_id_lteq_any?: Maybe<Scalars['String']>;
  sash_id_lteq_all?: Maybe<Scalars['String']>;
  sash_id_gt?: Maybe<Scalars['String']>;
  sash_id_gt_any?: Maybe<Scalars['String']>;
  sash_id_gt_all?: Maybe<Scalars['String']>;
  sash_id_gteq?: Maybe<Scalars['String']>;
  sash_id_gteq_any?: Maybe<Scalars['String']>;
  sash_id_gteq_all?: Maybe<Scalars['String']>;
  sash_id_in?: Maybe<Scalars['String']>;
  sash_id_in_any?: Maybe<Scalars['String']>;
  sash_id_in_all?: Maybe<Scalars['String']>;
  sash_id_not_in?: Maybe<Scalars['String']>;
  sash_id_not_in_any?: Maybe<Scalars['String']>;
  sash_id_not_in_all?: Maybe<Scalars['String']>;
  sash_id_cont?: Maybe<Scalars['String']>;
  sash_id_cont_any?: Maybe<Scalars['String']>;
  sash_id_cont_all?: Maybe<Scalars['String']>;
  sash_id_not_cont?: Maybe<Scalars['String']>;
  sash_id_not_cont_any?: Maybe<Scalars['String']>;
  sash_id_not_cont_all?: Maybe<Scalars['String']>;
  sash_id_i_cont?: Maybe<Scalars['String']>;
  sash_id_i_cont_any?: Maybe<Scalars['String']>;
  sash_id_i_cont_all?: Maybe<Scalars['String']>;
  sash_id_not_i_cont?: Maybe<Scalars['String']>;
  sash_id_not_i_cont_any?: Maybe<Scalars['String']>;
  sash_id_not_i_cont_all?: Maybe<Scalars['String']>;
  sash_id_start?: Maybe<Scalars['String']>;
  sash_id_start_any?: Maybe<Scalars['String']>;
  sash_id_start_all?: Maybe<Scalars['String']>;
  sash_id_not_start?: Maybe<Scalars['String']>;
  sash_id_not_start_any?: Maybe<Scalars['String']>;
  sash_id_not_start_all?: Maybe<Scalars['String']>;
  sash_id_end?: Maybe<Scalars['String']>;
  sash_id_end_any?: Maybe<Scalars['String']>;
  sash_id_end_all?: Maybe<Scalars['String']>;
  sash_id_not_end?: Maybe<Scalars['String']>;
  sash_id_not_end_any?: Maybe<Scalars['String']>;
  sash_id_not_end_all?: Maybe<Scalars['String']>;
  sash_id_true?: Maybe<Scalars['String']>;
  sash_id_not_true?: Maybe<Scalars['String']>;
  sash_id_false?: Maybe<Scalars['String']>;
  sash_id_not_false?: Maybe<Scalars['String']>;
  sash_id_present?: Maybe<Scalars['String']>;
  sash_id_blank?: Maybe<Scalars['String']>;
  sash_id_null?: Maybe<Scalars['String']>;
  sash_id_not_null?: Maybe<Scalars['String']>;
  sash_id_count?: Maybe<Scalars['String']>;
  sash_id_count_distinct?: Maybe<Scalars['String']>;
  sash_id_sum?: Maybe<Scalars['String']>;
  sash_id_avg?: Maybe<Scalars['String']>;
  sash_id_min?: Maybe<Scalars['String']>;
  sash_id_max?: Maybe<Scalars['String']>;
  level_eq?: Maybe<Scalars['String']>;
  level_eq_any?: Maybe<Scalars['String']>;
  level_eq_all?: Maybe<Scalars['String']>;
  level_not_eq?: Maybe<Scalars['String']>;
  level_not_eq_any?: Maybe<Scalars['String']>;
  level_not_eq_all?: Maybe<Scalars['String']>;
  level_matches?: Maybe<Scalars['String']>;
  level_matches_any?: Maybe<Scalars['String']>;
  level_matches_all?: Maybe<Scalars['String']>;
  level_does_not_match?: Maybe<Scalars['String']>;
  level_does_not_match_any?: Maybe<Scalars['String']>;
  level_does_not_match_all?: Maybe<Scalars['String']>;
  level_lt?: Maybe<Scalars['String']>;
  level_lt_any?: Maybe<Scalars['String']>;
  level_lt_all?: Maybe<Scalars['String']>;
  level_lteq?: Maybe<Scalars['String']>;
  level_lteq_any?: Maybe<Scalars['String']>;
  level_lteq_all?: Maybe<Scalars['String']>;
  level_gt?: Maybe<Scalars['String']>;
  level_gt_any?: Maybe<Scalars['String']>;
  level_gt_all?: Maybe<Scalars['String']>;
  level_gteq?: Maybe<Scalars['String']>;
  level_gteq_any?: Maybe<Scalars['String']>;
  level_gteq_all?: Maybe<Scalars['String']>;
  level_in?: Maybe<Scalars['String']>;
  level_in_any?: Maybe<Scalars['String']>;
  level_in_all?: Maybe<Scalars['String']>;
  level_not_in?: Maybe<Scalars['String']>;
  level_not_in_any?: Maybe<Scalars['String']>;
  level_not_in_all?: Maybe<Scalars['String']>;
  level_cont?: Maybe<Scalars['String']>;
  level_cont_any?: Maybe<Scalars['String']>;
  level_cont_all?: Maybe<Scalars['String']>;
  level_not_cont?: Maybe<Scalars['String']>;
  level_not_cont_any?: Maybe<Scalars['String']>;
  level_not_cont_all?: Maybe<Scalars['String']>;
  level_i_cont?: Maybe<Scalars['String']>;
  level_i_cont_any?: Maybe<Scalars['String']>;
  level_i_cont_all?: Maybe<Scalars['String']>;
  level_not_i_cont?: Maybe<Scalars['String']>;
  level_not_i_cont_any?: Maybe<Scalars['String']>;
  level_not_i_cont_all?: Maybe<Scalars['String']>;
  level_start?: Maybe<Scalars['String']>;
  level_start_any?: Maybe<Scalars['String']>;
  level_start_all?: Maybe<Scalars['String']>;
  level_not_start?: Maybe<Scalars['String']>;
  level_not_start_any?: Maybe<Scalars['String']>;
  level_not_start_all?: Maybe<Scalars['String']>;
  level_end?: Maybe<Scalars['String']>;
  level_end_any?: Maybe<Scalars['String']>;
  level_end_all?: Maybe<Scalars['String']>;
  level_not_end?: Maybe<Scalars['String']>;
  level_not_end_any?: Maybe<Scalars['String']>;
  level_not_end_all?: Maybe<Scalars['String']>;
  level_true?: Maybe<Scalars['String']>;
  level_not_true?: Maybe<Scalars['String']>;
  level_false?: Maybe<Scalars['String']>;
  level_not_false?: Maybe<Scalars['String']>;
  level_present?: Maybe<Scalars['String']>;
  level_blank?: Maybe<Scalars['String']>;
  level_null?: Maybe<Scalars['String']>;
  level_not_null?: Maybe<Scalars['String']>;
  level_count?: Maybe<Scalars['String']>;
  level_count_distinct?: Maybe<Scalars['String']>;
  level_sum?: Maybe<Scalars['String']>;
  level_avg?: Maybe<Scalars['String']>;
  level_min?: Maybe<Scalars['String']>;
  level_max?: Maybe<Scalars['String']>;
  current_quarter_carrots_eq?: Maybe<Scalars['String']>;
  current_quarter_carrots_eq_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_eq_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_eq?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_eq_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_eq_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_matches?: Maybe<Scalars['String']>;
  current_quarter_carrots_matches_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_matches_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_does_not_match?: Maybe<Scalars['String']>;
  current_quarter_carrots_does_not_match_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_does_not_match_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_lt?: Maybe<Scalars['String']>;
  current_quarter_carrots_lt_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_lt_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_lteq?: Maybe<Scalars['String']>;
  current_quarter_carrots_lteq_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_lteq_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_gt?: Maybe<Scalars['String']>;
  current_quarter_carrots_gt_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_gt_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_gteq?: Maybe<Scalars['String']>;
  current_quarter_carrots_gteq_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_gteq_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_in?: Maybe<Scalars['String']>;
  current_quarter_carrots_in_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_in_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_in?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_in_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_in_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_cont?: Maybe<Scalars['String']>;
  current_quarter_carrots_cont_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_cont_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_cont?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_cont_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_cont_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_i_cont?: Maybe<Scalars['String']>;
  current_quarter_carrots_i_cont_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_i_cont_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_i_cont?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_i_cont_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_i_cont_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_start?: Maybe<Scalars['String']>;
  current_quarter_carrots_start_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_start_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_start?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_start_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_start_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_end?: Maybe<Scalars['String']>;
  current_quarter_carrots_end_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_end_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_end?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_end_any?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_end_all?: Maybe<Scalars['String']>;
  current_quarter_carrots_true?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_true?: Maybe<Scalars['String']>;
  current_quarter_carrots_false?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_false?: Maybe<Scalars['String']>;
  current_quarter_carrots_present?: Maybe<Scalars['String']>;
  current_quarter_carrots_blank?: Maybe<Scalars['String']>;
  current_quarter_carrots_null?: Maybe<Scalars['String']>;
  current_quarter_carrots_not_null?: Maybe<Scalars['String']>;
  current_quarter_carrots_count?: Maybe<Scalars['String']>;
  current_quarter_carrots_count_distinct?: Maybe<Scalars['String']>;
  current_quarter_carrots_sum?: Maybe<Scalars['String']>;
  current_quarter_carrots_avg?: Maybe<Scalars['String']>;
  current_quarter_carrots_min?: Maybe<Scalars['String']>;
  current_quarter_carrots_max?: Maybe<Scalars['String']>;
  last_claimed_login_eq?: Maybe<Scalars['String']>;
  last_claimed_login_eq_any?: Maybe<Scalars['String']>;
  last_claimed_login_eq_all?: Maybe<Scalars['String']>;
  last_claimed_login_not_eq?: Maybe<Scalars['String']>;
  last_claimed_login_not_eq_any?: Maybe<Scalars['String']>;
  last_claimed_login_not_eq_all?: Maybe<Scalars['String']>;
  last_claimed_login_matches?: Maybe<Scalars['String']>;
  last_claimed_login_matches_any?: Maybe<Scalars['String']>;
  last_claimed_login_matches_all?: Maybe<Scalars['String']>;
  last_claimed_login_does_not_match?: Maybe<Scalars['String']>;
  last_claimed_login_does_not_match_any?: Maybe<Scalars['String']>;
  last_claimed_login_does_not_match_all?: Maybe<Scalars['String']>;
  last_claimed_login_lt?: Maybe<Scalars['String']>;
  last_claimed_login_lt_any?: Maybe<Scalars['String']>;
  last_claimed_login_lt_all?: Maybe<Scalars['String']>;
  last_claimed_login_lteq?: Maybe<Scalars['String']>;
  last_claimed_login_lteq_any?: Maybe<Scalars['String']>;
  last_claimed_login_lteq_all?: Maybe<Scalars['String']>;
  last_claimed_login_gt?: Maybe<Scalars['String']>;
  last_claimed_login_gt_any?: Maybe<Scalars['String']>;
  last_claimed_login_gt_all?: Maybe<Scalars['String']>;
  last_claimed_login_gteq?: Maybe<Scalars['String']>;
  last_claimed_login_gteq_any?: Maybe<Scalars['String']>;
  last_claimed_login_gteq_all?: Maybe<Scalars['String']>;
  last_claimed_login_in?: Maybe<Scalars['String']>;
  last_claimed_login_in_any?: Maybe<Scalars['String']>;
  last_claimed_login_in_all?: Maybe<Scalars['String']>;
  last_claimed_login_not_in?: Maybe<Scalars['String']>;
  last_claimed_login_not_in_any?: Maybe<Scalars['String']>;
  last_claimed_login_not_in_all?: Maybe<Scalars['String']>;
  last_claimed_login_cont?: Maybe<Scalars['String']>;
  last_claimed_login_cont_any?: Maybe<Scalars['String']>;
  last_claimed_login_cont_all?: Maybe<Scalars['String']>;
  last_claimed_login_not_cont?: Maybe<Scalars['String']>;
  last_claimed_login_not_cont_any?: Maybe<Scalars['String']>;
  last_claimed_login_not_cont_all?: Maybe<Scalars['String']>;
  last_claimed_login_i_cont?: Maybe<Scalars['String']>;
  last_claimed_login_i_cont_any?: Maybe<Scalars['String']>;
  last_claimed_login_i_cont_all?: Maybe<Scalars['String']>;
  last_claimed_login_not_i_cont?: Maybe<Scalars['String']>;
  last_claimed_login_not_i_cont_any?: Maybe<Scalars['String']>;
  last_claimed_login_not_i_cont_all?: Maybe<Scalars['String']>;
  last_claimed_login_start?: Maybe<Scalars['String']>;
  last_claimed_login_start_any?: Maybe<Scalars['String']>;
  last_claimed_login_start_all?: Maybe<Scalars['String']>;
  last_claimed_login_not_start?: Maybe<Scalars['String']>;
  last_claimed_login_not_start_any?: Maybe<Scalars['String']>;
  last_claimed_login_not_start_all?: Maybe<Scalars['String']>;
  last_claimed_login_end?: Maybe<Scalars['String']>;
  last_claimed_login_end_any?: Maybe<Scalars['String']>;
  last_claimed_login_end_all?: Maybe<Scalars['String']>;
  last_claimed_login_not_end?: Maybe<Scalars['String']>;
  last_claimed_login_not_end_any?: Maybe<Scalars['String']>;
  last_claimed_login_not_end_all?: Maybe<Scalars['String']>;
  last_claimed_login_true?: Maybe<Scalars['String']>;
  last_claimed_login_not_true?: Maybe<Scalars['String']>;
  last_claimed_login_false?: Maybe<Scalars['String']>;
  last_claimed_login_not_false?: Maybe<Scalars['String']>;
  last_claimed_login_present?: Maybe<Scalars['String']>;
  last_claimed_login_blank?: Maybe<Scalars['String']>;
  last_claimed_login_null?: Maybe<Scalars['String']>;
  last_claimed_login_not_null?: Maybe<Scalars['String']>;
  last_claimed_login_count?: Maybe<Scalars['String']>;
  last_claimed_login_count_distinct?: Maybe<Scalars['String']>;
  last_claimed_login_sum?: Maybe<Scalars['String']>;
  last_claimed_login_avg?: Maybe<Scalars['String']>;
  last_claimed_login_min?: Maybe<Scalars['String']>;
  last_claimed_login_max?: Maybe<Scalars['String']>;
  slack_id_eq?: Maybe<Scalars['String']>;
  slack_id_eq_any?: Maybe<Scalars['String']>;
  slack_id_eq_all?: Maybe<Scalars['String']>;
  slack_id_not_eq?: Maybe<Scalars['String']>;
  slack_id_not_eq_any?: Maybe<Scalars['String']>;
  slack_id_not_eq_all?: Maybe<Scalars['String']>;
  slack_id_matches?: Maybe<Scalars['String']>;
  slack_id_matches_any?: Maybe<Scalars['String']>;
  slack_id_matches_all?: Maybe<Scalars['String']>;
  slack_id_does_not_match?: Maybe<Scalars['String']>;
  slack_id_does_not_match_any?: Maybe<Scalars['String']>;
  slack_id_does_not_match_all?: Maybe<Scalars['String']>;
  slack_id_lt?: Maybe<Scalars['String']>;
  slack_id_lt_any?: Maybe<Scalars['String']>;
  slack_id_lt_all?: Maybe<Scalars['String']>;
  slack_id_lteq?: Maybe<Scalars['String']>;
  slack_id_lteq_any?: Maybe<Scalars['String']>;
  slack_id_lteq_all?: Maybe<Scalars['String']>;
  slack_id_gt?: Maybe<Scalars['String']>;
  slack_id_gt_any?: Maybe<Scalars['String']>;
  slack_id_gt_all?: Maybe<Scalars['String']>;
  slack_id_gteq?: Maybe<Scalars['String']>;
  slack_id_gteq_any?: Maybe<Scalars['String']>;
  slack_id_gteq_all?: Maybe<Scalars['String']>;
  slack_id_in?: Maybe<Scalars['String']>;
  slack_id_in_any?: Maybe<Scalars['String']>;
  slack_id_in_all?: Maybe<Scalars['String']>;
  slack_id_not_in?: Maybe<Scalars['String']>;
  slack_id_not_in_any?: Maybe<Scalars['String']>;
  slack_id_not_in_all?: Maybe<Scalars['String']>;
  slack_id_cont?: Maybe<Scalars['String']>;
  slack_id_cont_any?: Maybe<Scalars['String']>;
  slack_id_cont_all?: Maybe<Scalars['String']>;
  slack_id_not_cont?: Maybe<Scalars['String']>;
  slack_id_not_cont_any?: Maybe<Scalars['String']>;
  slack_id_not_cont_all?: Maybe<Scalars['String']>;
  slack_id_i_cont?: Maybe<Scalars['String']>;
  slack_id_i_cont_any?: Maybe<Scalars['String']>;
  slack_id_i_cont_all?: Maybe<Scalars['String']>;
  slack_id_not_i_cont?: Maybe<Scalars['String']>;
  slack_id_not_i_cont_any?: Maybe<Scalars['String']>;
  slack_id_not_i_cont_all?: Maybe<Scalars['String']>;
  slack_id_start?: Maybe<Scalars['String']>;
  slack_id_start_any?: Maybe<Scalars['String']>;
  slack_id_start_all?: Maybe<Scalars['String']>;
  slack_id_not_start?: Maybe<Scalars['String']>;
  slack_id_not_start_any?: Maybe<Scalars['String']>;
  slack_id_not_start_all?: Maybe<Scalars['String']>;
  slack_id_end?: Maybe<Scalars['String']>;
  slack_id_end_any?: Maybe<Scalars['String']>;
  slack_id_end_all?: Maybe<Scalars['String']>;
  slack_id_not_end?: Maybe<Scalars['String']>;
  slack_id_not_end_any?: Maybe<Scalars['String']>;
  slack_id_not_end_all?: Maybe<Scalars['String']>;
  slack_id_true?: Maybe<Scalars['String']>;
  slack_id_not_true?: Maybe<Scalars['String']>;
  slack_id_false?: Maybe<Scalars['String']>;
  slack_id_not_false?: Maybe<Scalars['String']>;
  slack_id_present?: Maybe<Scalars['String']>;
  slack_id_blank?: Maybe<Scalars['String']>;
  slack_id_null?: Maybe<Scalars['String']>;
  slack_id_not_null?: Maybe<Scalars['String']>;
  slack_id_count?: Maybe<Scalars['String']>;
  slack_id_count_distinct?: Maybe<Scalars['String']>;
  slack_id_sum?: Maybe<Scalars['String']>;
  slack_id_avg?: Maybe<Scalars['String']>;
  slack_id_min?: Maybe<Scalars['String']>;
  slack_id_max?: Maybe<Scalars['String']>;
  latest_response_eq?: Maybe<Scalars['String']>;
  latest_response_eq_any?: Maybe<Scalars['String']>;
  latest_response_eq_all?: Maybe<Scalars['String']>;
  latest_response_not_eq?: Maybe<Scalars['String']>;
  latest_response_not_eq_any?: Maybe<Scalars['String']>;
  latest_response_not_eq_all?: Maybe<Scalars['String']>;
  latest_response_matches?: Maybe<Scalars['String']>;
  latest_response_matches_any?: Maybe<Scalars['String']>;
  latest_response_matches_all?: Maybe<Scalars['String']>;
  latest_response_does_not_match?: Maybe<Scalars['String']>;
  latest_response_does_not_match_any?: Maybe<Scalars['String']>;
  latest_response_does_not_match_all?: Maybe<Scalars['String']>;
  latest_response_lt?: Maybe<Scalars['String']>;
  latest_response_lt_any?: Maybe<Scalars['String']>;
  latest_response_lt_all?: Maybe<Scalars['String']>;
  latest_response_lteq?: Maybe<Scalars['String']>;
  latest_response_lteq_any?: Maybe<Scalars['String']>;
  latest_response_lteq_all?: Maybe<Scalars['String']>;
  latest_response_gt?: Maybe<Scalars['String']>;
  latest_response_gt_any?: Maybe<Scalars['String']>;
  latest_response_gt_all?: Maybe<Scalars['String']>;
  latest_response_gteq?: Maybe<Scalars['String']>;
  latest_response_gteq_any?: Maybe<Scalars['String']>;
  latest_response_gteq_all?: Maybe<Scalars['String']>;
  latest_response_in?: Maybe<Scalars['String']>;
  latest_response_in_any?: Maybe<Scalars['String']>;
  latest_response_in_all?: Maybe<Scalars['String']>;
  latest_response_not_in?: Maybe<Scalars['String']>;
  latest_response_not_in_any?: Maybe<Scalars['String']>;
  latest_response_not_in_all?: Maybe<Scalars['String']>;
  latest_response_cont?: Maybe<Scalars['String']>;
  latest_response_cont_any?: Maybe<Scalars['String']>;
  latest_response_cont_all?: Maybe<Scalars['String']>;
  latest_response_not_cont?: Maybe<Scalars['String']>;
  latest_response_not_cont_any?: Maybe<Scalars['String']>;
  latest_response_not_cont_all?: Maybe<Scalars['String']>;
  latest_response_i_cont?: Maybe<Scalars['String']>;
  latest_response_i_cont_any?: Maybe<Scalars['String']>;
  latest_response_i_cont_all?: Maybe<Scalars['String']>;
  latest_response_not_i_cont?: Maybe<Scalars['String']>;
  latest_response_not_i_cont_any?: Maybe<Scalars['String']>;
  latest_response_not_i_cont_all?: Maybe<Scalars['String']>;
  latest_response_start?: Maybe<Scalars['String']>;
  latest_response_start_any?: Maybe<Scalars['String']>;
  latest_response_start_all?: Maybe<Scalars['String']>;
  latest_response_not_start?: Maybe<Scalars['String']>;
  latest_response_not_start_any?: Maybe<Scalars['String']>;
  latest_response_not_start_all?: Maybe<Scalars['String']>;
  latest_response_end?: Maybe<Scalars['String']>;
  latest_response_end_any?: Maybe<Scalars['String']>;
  latest_response_end_all?: Maybe<Scalars['String']>;
  latest_response_not_end?: Maybe<Scalars['String']>;
  latest_response_not_end_any?: Maybe<Scalars['String']>;
  latest_response_not_end_all?: Maybe<Scalars['String']>;
  latest_response_true?: Maybe<Scalars['String']>;
  latest_response_not_true?: Maybe<Scalars['String']>;
  latest_response_false?: Maybe<Scalars['String']>;
  latest_response_not_false?: Maybe<Scalars['String']>;
  latest_response_present?: Maybe<Scalars['String']>;
  latest_response_blank?: Maybe<Scalars['String']>;
  latest_response_null?: Maybe<Scalars['String']>;
  latest_response_not_null?: Maybe<Scalars['String']>;
  latest_response_count?: Maybe<Scalars['String']>;
  latest_response_count_distinct?: Maybe<Scalars['String']>;
  latest_response_sum?: Maybe<Scalars['String']>;
  latest_response_avg?: Maybe<Scalars['String']>;
  latest_response_min?: Maybe<Scalars['String']>;
  latest_response_max?: Maybe<Scalars['String']>;
  account_type_eq?: Maybe<Scalars['String']>;
  account_type_eq_any?: Maybe<Scalars['String']>;
  account_type_eq_all?: Maybe<Scalars['String']>;
  account_type_not_eq?: Maybe<Scalars['String']>;
  account_type_not_eq_any?: Maybe<Scalars['String']>;
  account_type_not_eq_all?: Maybe<Scalars['String']>;
  account_type_matches?: Maybe<Scalars['String']>;
  account_type_matches_any?: Maybe<Scalars['String']>;
  account_type_matches_all?: Maybe<Scalars['String']>;
  account_type_does_not_match?: Maybe<Scalars['String']>;
  account_type_does_not_match_any?: Maybe<Scalars['String']>;
  account_type_does_not_match_all?: Maybe<Scalars['String']>;
  account_type_lt?: Maybe<Scalars['String']>;
  account_type_lt_any?: Maybe<Scalars['String']>;
  account_type_lt_all?: Maybe<Scalars['String']>;
  account_type_lteq?: Maybe<Scalars['String']>;
  account_type_lteq_any?: Maybe<Scalars['String']>;
  account_type_lteq_all?: Maybe<Scalars['String']>;
  account_type_gt?: Maybe<Scalars['String']>;
  account_type_gt_any?: Maybe<Scalars['String']>;
  account_type_gt_all?: Maybe<Scalars['String']>;
  account_type_gteq?: Maybe<Scalars['String']>;
  account_type_gteq_any?: Maybe<Scalars['String']>;
  account_type_gteq_all?: Maybe<Scalars['String']>;
  account_type_in?: Maybe<Scalars['String']>;
  account_type_in_any?: Maybe<Scalars['String']>;
  account_type_in_all?: Maybe<Scalars['String']>;
  account_type_not_in?: Maybe<Scalars['String']>;
  account_type_not_in_any?: Maybe<Scalars['String']>;
  account_type_not_in_all?: Maybe<Scalars['String']>;
  account_type_cont?: Maybe<Scalars['String']>;
  account_type_cont_any?: Maybe<Scalars['String']>;
  account_type_cont_all?: Maybe<Scalars['String']>;
  account_type_not_cont?: Maybe<Scalars['String']>;
  account_type_not_cont_any?: Maybe<Scalars['String']>;
  account_type_not_cont_all?: Maybe<Scalars['String']>;
  account_type_i_cont?: Maybe<Scalars['String']>;
  account_type_i_cont_any?: Maybe<Scalars['String']>;
  account_type_i_cont_all?: Maybe<Scalars['String']>;
  account_type_not_i_cont?: Maybe<Scalars['String']>;
  account_type_not_i_cont_any?: Maybe<Scalars['String']>;
  account_type_not_i_cont_all?: Maybe<Scalars['String']>;
  account_type_start?: Maybe<Scalars['String']>;
  account_type_start_any?: Maybe<Scalars['String']>;
  account_type_start_all?: Maybe<Scalars['String']>;
  account_type_not_start?: Maybe<Scalars['String']>;
  account_type_not_start_any?: Maybe<Scalars['String']>;
  account_type_not_start_all?: Maybe<Scalars['String']>;
  account_type_end?: Maybe<Scalars['String']>;
  account_type_end_any?: Maybe<Scalars['String']>;
  account_type_end_all?: Maybe<Scalars['String']>;
  account_type_not_end?: Maybe<Scalars['String']>;
  account_type_not_end_any?: Maybe<Scalars['String']>;
  account_type_not_end_all?: Maybe<Scalars['String']>;
  account_type_true?: Maybe<Scalars['String']>;
  account_type_not_true?: Maybe<Scalars['String']>;
  account_type_false?: Maybe<Scalars['String']>;
  account_type_not_false?: Maybe<Scalars['String']>;
  account_type_present?: Maybe<Scalars['String']>;
  account_type_blank?: Maybe<Scalars['String']>;
  account_type_null?: Maybe<Scalars['String']>;
  account_type_not_null?: Maybe<Scalars['String']>;
  account_type_count?: Maybe<Scalars['String']>;
  account_type_count_distinct?: Maybe<Scalars['String']>;
  account_type_sum?: Maybe<Scalars['String']>;
  account_type_avg?: Maybe<Scalars['String']>;
  account_type_min?: Maybe<Scalars['String']>;
  account_type_max?: Maybe<Scalars['String']>;
  last_seen_update_id_eq?: Maybe<Scalars['String']>;
  last_seen_update_id_eq_any?: Maybe<Scalars['String']>;
  last_seen_update_id_eq_all?: Maybe<Scalars['String']>;
  last_seen_update_id_not_eq?: Maybe<Scalars['String']>;
  last_seen_update_id_not_eq_any?: Maybe<Scalars['String']>;
  last_seen_update_id_not_eq_all?: Maybe<Scalars['String']>;
  last_seen_update_id_matches?: Maybe<Scalars['String']>;
  last_seen_update_id_matches_any?: Maybe<Scalars['String']>;
  last_seen_update_id_matches_all?: Maybe<Scalars['String']>;
  last_seen_update_id_does_not_match?: Maybe<Scalars['String']>;
  last_seen_update_id_does_not_match_any?: Maybe<Scalars['String']>;
  last_seen_update_id_does_not_match_all?: Maybe<Scalars['String']>;
  last_seen_update_id_lt?: Maybe<Scalars['String']>;
  last_seen_update_id_lt_any?: Maybe<Scalars['String']>;
  last_seen_update_id_lt_all?: Maybe<Scalars['String']>;
  last_seen_update_id_lteq?: Maybe<Scalars['String']>;
  last_seen_update_id_lteq_any?: Maybe<Scalars['String']>;
  last_seen_update_id_lteq_all?: Maybe<Scalars['String']>;
  last_seen_update_id_gt?: Maybe<Scalars['String']>;
  last_seen_update_id_gt_any?: Maybe<Scalars['String']>;
  last_seen_update_id_gt_all?: Maybe<Scalars['String']>;
  last_seen_update_id_gteq?: Maybe<Scalars['String']>;
  last_seen_update_id_gteq_any?: Maybe<Scalars['String']>;
  last_seen_update_id_gteq_all?: Maybe<Scalars['String']>;
  last_seen_update_id_in?: Maybe<Scalars['String']>;
  last_seen_update_id_in_any?: Maybe<Scalars['String']>;
  last_seen_update_id_in_all?: Maybe<Scalars['String']>;
  last_seen_update_id_not_in?: Maybe<Scalars['String']>;
  last_seen_update_id_not_in_any?: Maybe<Scalars['String']>;
  last_seen_update_id_not_in_all?: Maybe<Scalars['String']>;
  last_seen_update_id_cont?: Maybe<Scalars['String']>;
  last_seen_update_id_cont_any?: Maybe<Scalars['String']>;
  last_seen_update_id_cont_all?: Maybe<Scalars['String']>;
  last_seen_update_id_not_cont?: Maybe<Scalars['String']>;
  last_seen_update_id_not_cont_any?: Maybe<Scalars['String']>;
  last_seen_update_id_not_cont_all?: Maybe<Scalars['String']>;
  last_seen_update_id_i_cont?: Maybe<Scalars['String']>;
  last_seen_update_id_i_cont_any?: Maybe<Scalars['String']>;
  last_seen_update_id_i_cont_all?: Maybe<Scalars['String']>;
  last_seen_update_id_not_i_cont?: Maybe<Scalars['String']>;
  last_seen_update_id_not_i_cont_any?: Maybe<Scalars['String']>;
  last_seen_update_id_not_i_cont_all?: Maybe<Scalars['String']>;
  last_seen_update_id_start?: Maybe<Scalars['String']>;
  last_seen_update_id_start_any?: Maybe<Scalars['String']>;
  last_seen_update_id_start_all?: Maybe<Scalars['String']>;
  last_seen_update_id_not_start?: Maybe<Scalars['String']>;
  last_seen_update_id_not_start_any?: Maybe<Scalars['String']>;
  last_seen_update_id_not_start_all?: Maybe<Scalars['String']>;
  last_seen_update_id_end?: Maybe<Scalars['String']>;
  last_seen_update_id_end_any?: Maybe<Scalars['String']>;
  last_seen_update_id_end_all?: Maybe<Scalars['String']>;
  last_seen_update_id_not_end?: Maybe<Scalars['String']>;
  last_seen_update_id_not_end_any?: Maybe<Scalars['String']>;
  last_seen_update_id_not_end_all?: Maybe<Scalars['String']>;
  last_seen_update_id_true?: Maybe<Scalars['String']>;
  last_seen_update_id_not_true?: Maybe<Scalars['String']>;
  last_seen_update_id_false?: Maybe<Scalars['String']>;
  last_seen_update_id_not_false?: Maybe<Scalars['String']>;
  last_seen_update_id_present?: Maybe<Scalars['String']>;
  last_seen_update_id_blank?: Maybe<Scalars['String']>;
  last_seen_update_id_null?: Maybe<Scalars['String']>;
  last_seen_update_id_not_null?: Maybe<Scalars['String']>;
  last_seen_update_id_count?: Maybe<Scalars['String']>;
  last_seen_update_id_count_distinct?: Maybe<Scalars['String']>;
  last_seen_update_id_sum?: Maybe<Scalars['String']>;
  last_seen_update_id_avg?: Maybe<Scalars['String']>;
  last_seen_update_id_min?: Maybe<Scalars['String']>;
  last_seen_update_id_max?: Maybe<Scalars['String']>;
  on_vacation_eq?: Maybe<Scalars['String']>;
  on_vacation_eq_any?: Maybe<Scalars['String']>;
  on_vacation_eq_all?: Maybe<Scalars['String']>;
  on_vacation_not_eq?: Maybe<Scalars['String']>;
  on_vacation_not_eq_any?: Maybe<Scalars['String']>;
  on_vacation_not_eq_all?: Maybe<Scalars['String']>;
  on_vacation_matches?: Maybe<Scalars['String']>;
  on_vacation_matches_any?: Maybe<Scalars['String']>;
  on_vacation_matches_all?: Maybe<Scalars['String']>;
  on_vacation_does_not_match?: Maybe<Scalars['String']>;
  on_vacation_does_not_match_any?: Maybe<Scalars['String']>;
  on_vacation_does_not_match_all?: Maybe<Scalars['String']>;
  on_vacation_lt?: Maybe<Scalars['String']>;
  on_vacation_lt_any?: Maybe<Scalars['String']>;
  on_vacation_lt_all?: Maybe<Scalars['String']>;
  on_vacation_lteq?: Maybe<Scalars['String']>;
  on_vacation_lteq_any?: Maybe<Scalars['String']>;
  on_vacation_lteq_all?: Maybe<Scalars['String']>;
  on_vacation_gt?: Maybe<Scalars['String']>;
  on_vacation_gt_any?: Maybe<Scalars['String']>;
  on_vacation_gt_all?: Maybe<Scalars['String']>;
  on_vacation_gteq?: Maybe<Scalars['String']>;
  on_vacation_gteq_any?: Maybe<Scalars['String']>;
  on_vacation_gteq_all?: Maybe<Scalars['String']>;
  on_vacation_in?: Maybe<Scalars['String']>;
  on_vacation_in_any?: Maybe<Scalars['String']>;
  on_vacation_in_all?: Maybe<Scalars['String']>;
  on_vacation_not_in?: Maybe<Scalars['String']>;
  on_vacation_not_in_any?: Maybe<Scalars['String']>;
  on_vacation_not_in_all?: Maybe<Scalars['String']>;
  on_vacation_cont?: Maybe<Scalars['String']>;
  on_vacation_cont_any?: Maybe<Scalars['String']>;
  on_vacation_cont_all?: Maybe<Scalars['String']>;
  on_vacation_not_cont?: Maybe<Scalars['String']>;
  on_vacation_not_cont_any?: Maybe<Scalars['String']>;
  on_vacation_not_cont_all?: Maybe<Scalars['String']>;
  on_vacation_i_cont?: Maybe<Scalars['String']>;
  on_vacation_i_cont_any?: Maybe<Scalars['String']>;
  on_vacation_i_cont_all?: Maybe<Scalars['String']>;
  on_vacation_not_i_cont?: Maybe<Scalars['String']>;
  on_vacation_not_i_cont_any?: Maybe<Scalars['String']>;
  on_vacation_not_i_cont_all?: Maybe<Scalars['String']>;
  on_vacation_start?: Maybe<Scalars['String']>;
  on_vacation_start_any?: Maybe<Scalars['String']>;
  on_vacation_start_all?: Maybe<Scalars['String']>;
  on_vacation_not_start?: Maybe<Scalars['String']>;
  on_vacation_not_start_any?: Maybe<Scalars['String']>;
  on_vacation_not_start_all?: Maybe<Scalars['String']>;
  on_vacation_end?: Maybe<Scalars['String']>;
  on_vacation_end_any?: Maybe<Scalars['String']>;
  on_vacation_end_all?: Maybe<Scalars['String']>;
  on_vacation_not_end?: Maybe<Scalars['String']>;
  on_vacation_not_end_any?: Maybe<Scalars['String']>;
  on_vacation_not_end_all?: Maybe<Scalars['String']>;
  on_vacation_true?: Maybe<Scalars['String']>;
  on_vacation_not_true?: Maybe<Scalars['String']>;
  on_vacation_false?: Maybe<Scalars['String']>;
  on_vacation_not_false?: Maybe<Scalars['String']>;
  on_vacation_present?: Maybe<Scalars['String']>;
  on_vacation_blank?: Maybe<Scalars['String']>;
  on_vacation_null?: Maybe<Scalars['String']>;
  on_vacation_not_null?: Maybe<Scalars['String']>;
  on_vacation_count?: Maybe<Scalars['String']>;
  on_vacation_count_distinct?: Maybe<Scalars['String']>;
  on_vacation_sum?: Maybe<Scalars['String']>;
  on_vacation_avg?: Maybe<Scalars['String']>;
  on_vacation_min?: Maybe<Scalars['String']>;
  on_vacation_max?: Maybe<Scalars['String']>;
  account_id_eq?: Maybe<Scalars['String']>;
  account_id_eq_any?: Maybe<Scalars['String']>;
  account_id_eq_all?: Maybe<Scalars['String']>;
  account_id_not_eq?: Maybe<Scalars['String']>;
  account_id_not_eq_any?: Maybe<Scalars['String']>;
  account_id_not_eq_all?: Maybe<Scalars['String']>;
  account_id_matches?: Maybe<Scalars['String']>;
  account_id_matches_any?: Maybe<Scalars['String']>;
  account_id_matches_all?: Maybe<Scalars['String']>;
  account_id_does_not_match?: Maybe<Scalars['String']>;
  account_id_does_not_match_any?: Maybe<Scalars['String']>;
  account_id_does_not_match_all?: Maybe<Scalars['String']>;
  account_id_lt?: Maybe<Scalars['String']>;
  account_id_lt_any?: Maybe<Scalars['String']>;
  account_id_lt_all?: Maybe<Scalars['String']>;
  account_id_lteq?: Maybe<Scalars['String']>;
  account_id_lteq_any?: Maybe<Scalars['String']>;
  account_id_lteq_all?: Maybe<Scalars['String']>;
  account_id_gt?: Maybe<Scalars['String']>;
  account_id_gt_any?: Maybe<Scalars['String']>;
  account_id_gt_all?: Maybe<Scalars['String']>;
  account_id_gteq?: Maybe<Scalars['String']>;
  account_id_gteq_any?: Maybe<Scalars['String']>;
  account_id_gteq_all?: Maybe<Scalars['String']>;
  account_id_in?: Maybe<Scalars['String']>;
  account_id_in_any?: Maybe<Scalars['String']>;
  account_id_in_all?: Maybe<Scalars['String']>;
  account_id_not_in?: Maybe<Scalars['String']>;
  account_id_not_in_any?: Maybe<Scalars['String']>;
  account_id_not_in_all?: Maybe<Scalars['String']>;
  account_id_cont?: Maybe<Scalars['String']>;
  account_id_cont_any?: Maybe<Scalars['String']>;
  account_id_cont_all?: Maybe<Scalars['String']>;
  account_id_not_cont?: Maybe<Scalars['String']>;
  account_id_not_cont_any?: Maybe<Scalars['String']>;
  account_id_not_cont_all?: Maybe<Scalars['String']>;
  account_id_i_cont?: Maybe<Scalars['String']>;
  account_id_i_cont_any?: Maybe<Scalars['String']>;
  account_id_i_cont_all?: Maybe<Scalars['String']>;
  account_id_not_i_cont?: Maybe<Scalars['String']>;
  account_id_not_i_cont_any?: Maybe<Scalars['String']>;
  account_id_not_i_cont_all?: Maybe<Scalars['String']>;
  account_id_start?: Maybe<Scalars['String']>;
  account_id_start_any?: Maybe<Scalars['String']>;
  account_id_start_all?: Maybe<Scalars['String']>;
  account_id_not_start?: Maybe<Scalars['String']>;
  account_id_not_start_any?: Maybe<Scalars['String']>;
  account_id_not_start_all?: Maybe<Scalars['String']>;
  account_id_end?: Maybe<Scalars['String']>;
  account_id_end_any?: Maybe<Scalars['String']>;
  account_id_end_all?: Maybe<Scalars['String']>;
  account_id_not_end?: Maybe<Scalars['String']>;
  account_id_not_end_any?: Maybe<Scalars['String']>;
  account_id_not_end_all?: Maybe<Scalars['String']>;
  account_id_true?: Maybe<Scalars['String']>;
  account_id_not_true?: Maybe<Scalars['String']>;
  account_id_false?: Maybe<Scalars['String']>;
  account_id_not_false?: Maybe<Scalars['String']>;
  account_id_present?: Maybe<Scalars['String']>;
  account_id_blank?: Maybe<Scalars['String']>;
  account_id_null?: Maybe<Scalars['String']>;
  account_id_not_null?: Maybe<Scalars['String']>;
  account_id_count?: Maybe<Scalars['String']>;
  account_id_count_distinct?: Maybe<Scalars['String']>;
  account_id_sum?: Maybe<Scalars['String']>;
  account_id_avg?: Maybe<Scalars['String']>;
  account_id_min?: Maybe<Scalars['String']>;
  account_id_max?: Maybe<Scalars['String']>;
  region_id_eq?: Maybe<Scalars['String']>;
  region_id_eq_any?: Maybe<Scalars['String']>;
  region_id_eq_all?: Maybe<Scalars['String']>;
  region_id_not_eq?: Maybe<Scalars['String']>;
  region_id_not_eq_any?: Maybe<Scalars['String']>;
  region_id_not_eq_all?: Maybe<Scalars['String']>;
  region_id_matches?: Maybe<Scalars['String']>;
  region_id_matches_any?: Maybe<Scalars['String']>;
  region_id_matches_all?: Maybe<Scalars['String']>;
  region_id_does_not_match?: Maybe<Scalars['String']>;
  region_id_does_not_match_any?: Maybe<Scalars['String']>;
  region_id_does_not_match_all?: Maybe<Scalars['String']>;
  region_id_lt?: Maybe<Scalars['String']>;
  region_id_lt_any?: Maybe<Scalars['String']>;
  region_id_lt_all?: Maybe<Scalars['String']>;
  region_id_lteq?: Maybe<Scalars['String']>;
  region_id_lteq_any?: Maybe<Scalars['String']>;
  region_id_lteq_all?: Maybe<Scalars['String']>;
  region_id_gt?: Maybe<Scalars['String']>;
  region_id_gt_any?: Maybe<Scalars['String']>;
  region_id_gt_all?: Maybe<Scalars['String']>;
  region_id_gteq?: Maybe<Scalars['String']>;
  region_id_gteq_any?: Maybe<Scalars['String']>;
  region_id_gteq_all?: Maybe<Scalars['String']>;
  region_id_in?: Maybe<Scalars['String']>;
  region_id_in_any?: Maybe<Scalars['String']>;
  region_id_in_all?: Maybe<Scalars['String']>;
  region_id_not_in?: Maybe<Scalars['String']>;
  region_id_not_in_any?: Maybe<Scalars['String']>;
  region_id_not_in_all?: Maybe<Scalars['String']>;
  region_id_cont?: Maybe<Scalars['String']>;
  region_id_cont_any?: Maybe<Scalars['String']>;
  region_id_cont_all?: Maybe<Scalars['String']>;
  region_id_not_cont?: Maybe<Scalars['String']>;
  region_id_not_cont_any?: Maybe<Scalars['String']>;
  region_id_not_cont_all?: Maybe<Scalars['String']>;
  region_id_i_cont?: Maybe<Scalars['String']>;
  region_id_i_cont_any?: Maybe<Scalars['String']>;
  region_id_i_cont_all?: Maybe<Scalars['String']>;
  region_id_not_i_cont?: Maybe<Scalars['String']>;
  region_id_not_i_cont_any?: Maybe<Scalars['String']>;
  region_id_not_i_cont_all?: Maybe<Scalars['String']>;
  region_id_start?: Maybe<Scalars['String']>;
  region_id_start_any?: Maybe<Scalars['String']>;
  region_id_start_all?: Maybe<Scalars['String']>;
  region_id_not_start?: Maybe<Scalars['String']>;
  region_id_not_start_any?: Maybe<Scalars['String']>;
  region_id_not_start_all?: Maybe<Scalars['String']>;
  region_id_end?: Maybe<Scalars['String']>;
  region_id_end_any?: Maybe<Scalars['String']>;
  region_id_end_all?: Maybe<Scalars['String']>;
  region_id_not_end?: Maybe<Scalars['String']>;
  region_id_not_end_any?: Maybe<Scalars['String']>;
  region_id_not_end_all?: Maybe<Scalars['String']>;
  region_id_true?: Maybe<Scalars['String']>;
  region_id_not_true?: Maybe<Scalars['String']>;
  region_id_false?: Maybe<Scalars['String']>;
  region_id_not_false?: Maybe<Scalars['String']>;
  region_id_present?: Maybe<Scalars['String']>;
  region_id_blank?: Maybe<Scalars['String']>;
  region_id_null?: Maybe<Scalars['String']>;
  region_id_not_null?: Maybe<Scalars['String']>;
  region_id_count?: Maybe<Scalars['String']>;
  region_id_count_distinct?: Maybe<Scalars['String']>;
  region_id_sum?: Maybe<Scalars['String']>;
  region_id_avg?: Maybe<Scalars['String']>;
  region_id_min?: Maybe<Scalars['String']>;
  region_id_max?: Maybe<Scalars['String']>;
  location_id_eq?: Maybe<Scalars['String']>;
  location_id_eq_any?: Maybe<Scalars['String']>;
  location_id_eq_all?: Maybe<Scalars['String']>;
  location_id_not_eq?: Maybe<Scalars['String']>;
  location_id_not_eq_any?: Maybe<Scalars['String']>;
  location_id_not_eq_all?: Maybe<Scalars['String']>;
  location_id_matches?: Maybe<Scalars['String']>;
  location_id_matches_any?: Maybe<Scalars['String']>;
  location_id_matches_all?: Maybe<Scalars['String']>;
  location_id_does_not_match?: Maybe<Scalars['String']>;
  location_id_does_not_match_any?: Maybe<Scalars['String']>;
  location_id_does_not_match_all?: Maybe<Scalars['String']>;
  location_id_lt?: Maybe<Scalars['String']>;
  location_id_lt_any?: Maybe<Scalars['String']>;
  location_id_lt_all?: Maybe<Scalars['String']>;
  location_id_lteq?: Maybe<Scalars['String']>;
  location_id_lteq_any?: Maybe<Scalars['String']>;
  location_id_lteq_all?: Maybe<Scalars['String']>;
  location_id_gt?: Maybe<Scalars['String']>;
  location_id_gt_any?: Maybe<Scalars['String']>;
  location_id_gt_all?: Maybe<Scalars['String']>;
  location_id_gteq?: Maybe<Scalars['String']>;
  location_id_gteq_any?: Maybe<Scalars['String']>;
  location_id_gteq_all?: Maybe<Scalars['String']>;
  location_id_in?: Maybe<Scalars['String']>;
  location_id_in_any?: Maybe<Scalars['String']>;
  location_id_in_all?: Maybe<Scalars['String']>;
  location_id_not_in?: Maybe<Scalars['String']>;
  location_id_not_in_any?: Maybe<Scalars['String']>;
  location_id_not_in_all?: Maybe<Scalars['String']>;
  location_id_cont?: Maybe<Scalars['String']>;
  location_id_cont_any?: Maybe<Scalars['String']>;
  location_id_cont_all?: Maybe<Scalars['String']>;
  location_id_not_cont?: Maybe<Scalars['String']>;
  location_id_not_cont_any?: Maybe<Scalars['String']>;
  location_id_not_cont_all?: Maybe<Scalars['String']>;
  location_id_i_cont?: Maybe<Scalars['String']>;
  location_id_i_cont_any?: Maybe<Scalars['String']>;
  location_id_i_cont_all?: Maybe<Scalars['String']>;
  location_id_not_i_cont?: Maybe<Scalars['String']>;
  location_id_not_i_cont_any?: Maybe<Scalars['String']>;
  location_id_not_i_cont_all?: Maybe<Scalars['String']>;
  location_id_start?: Maybe<Scalars['String']>;
  location_id_start_any?: Maybe<Scalars['String']>;
  location_id_start_all?: Maybe<Scalars['String']>;
  location_id_not_start?: Maybe<Scalars['String']>;
  location_id_not_start_any?: Maybe<Scalars['String']>;
  location_id_not_start_all?: Maybe<Scalars['String']>;
  location_id_end?: Maybe<Scalars['String']>;
  location_id_end_any?: Maybe<Scalars['String']>;
  location_id_end_all?: Maybe<Scalars['String']>;
  location_id_not_end?: Maybe<Scalars['String']>;
  location_id_not_end_any?: Maybe<Scalars['String']>;
  location_id_not_end_all?: Maybe<Scalars['String']>;
  location_id_true?: Maybe<Scalars['String']>;
  location_id_not_true?: Maybe<Scalars['String']>;
  location_id_false?: Maybe<Scalars['String']>;
  location_id_not_false?: Maybe<Scalars['String']>;
  location_id_present?: Maybe<Scalars['String']>;
  location_id_blank?: Maybe<Scalars['String']>;
  location_id_null?: Maybe<Scalars['String']>;
  location_id_not_null?: Maybe<Scalars['String']>;
  location_id_count?: Maybe<Scalars['String']>;
  location_id_count_distinct?: Maybe<Scalars['String']>;
  location_id_sum?: Maybe<Scalars['String']>;
  location_id_avg?: Maybe<Scalars['String']>;
  location_id_min?: Maybe<Scalars['String']>;
  location_id_max?: Maybe<Scalars['String']>;
  response_rate_eq?: Maybe<Scalars['String']>;
  response_rate_eq_any?: Maybe<Scalars['String']>;
  response_rate_eq_all?: Maybe<Scalars['String']>;
  response_rate_not_eq?: Maybe<Scalars['String']>;
  response_rate_not_eq_any?: Maybe<Scalars['String']>;
  response_rate_not_eq_all?: Maybe<Scalars['String']>;
  response_rate_matches?: Maybe<Scalars['String']>;
  response_rate_matches_any?: Maybe<Scalars['String']>;
  response_rate_matches_all?: Maybe<Scalars['String']>;
  response_rate_does_not_match?: Maybe<Scalars['String']>;
  response_rate_does_not_match_any?: Maybe<Scalars['String']>;
  response_rate_does_not_match_all?: Maybe<Scalars['String']>;
  response_rate_lt?: Maybe<Scalars['String']>;
  response_rate_lt_any?: Maybe<Scalars['String']>;
  response_rate_lt_all?: Maybe<Scalars['String']>;
  response_rate_lteq?: Maybe<Scalars['String']>;
  response_rate_lteq_any?: Maybe<Scalars['String']>;
  response_rate_lteq_all?: Maybe<Scalars['String']>;
  response_rate_gt?: Maybe<Scalars['String']>;
  response_rate_gt_any?: Maybe<Scalars['String']>;
  response_rate_gt_all?: Maybe<Scalars['String']>;
  response_rate_gteq?: Maybe<Scalars['String']>;
  response_rate_gteq_any?: Maybe<Scalars['String']>;
  response_rate_gteq_all?: Maybe<Scalars['String']>;
  response_rate_in?: Maybe<Scalars['String']>;
  response_rate_in_any?: Maybe<Scalars['String']>;
  response_rate_in_all?: Maybe<Scalars['String']>;
  response_rate_not_in?: Maybe<Scalars['String']>;
  response_rate_not_in_any?: Maybe<Scalars['String']>;
  response_rate_not_in_all?: Maybe<Scalars['String']>;
  response_rate_cont?: Maybe<Scalars['String']>;
  response_rate_cont_any?: Maybe<Scalars['String']>;
  response_rate_cont_all?: Maybe<Scalars['String']>;
  response_rate_not_cont?: Maybe<Scalars['String']>;
  response_rate_not_cont_any?: Maybe<Scalars['String']>;
  response_rate_not_cont_all?: Maybe<Scalars['String']>;
  response_rate_i_cont?: Maybe<Scalars['String']>;
  response_rate_i_cont_any?: Maybe<Scalars['String']>;
  response_rate_i_cont_all?: Maybe<Scalars['String']>;
  response_rate_not_i_cont?: Maybe<Scalars['String']>;
  response_rate_not_i_cont_any?: Maybe<Scalars['String']>;
  response_rate_not_i_cont_all?: Maybe<Scalars['String']>;
  response_rate_start?: Maybe<Scalars['String']>;
  response_rate_start_any?: Maybe<Scalars['String']>;
  response_rate_start_all?: Maybe<Scalars['String']>;
  response_rate_not_start?: Maybe<Scalars['String']>;
  response_rate_not_start_any?: Maybe<Scalars['String']>;
  response_rate_not_start_all?: Maybe<Scalars['String']>;
  response_rate_end?: Maybe<Scalars['String']>;
  response_rate_end_any?: Maybe<Scalars['String']>;
  response_rate_end_all?: Maybe<Scalars['String']>;
  response_rate_not_end?: Maybe<Scalars['String']>;
  response_rate_not_end_any?: Maybe<Scalars['String']>;
  response_rate_not_end_all?: Maybe<Scalars['String']>;
  response_rate_true?: Maybe<Scalars['String']>;
  response_rate_not_true?: Maybe<Scalars['String']>;
  response_rate_false?: Maybe<Scalars['String']>;
  response_rate_not_false?: Maybe<Scalars['String']>;
  response_rate_present?: Maybe<Scalars['String']>;
  response_rate_blank?: Maybe<Scalars['String']>;
  response_rate_null?: Maybe<Scalars['String']>;
  response_rate_not_null?: Maybe<Scalars['String']>;
  response_rate_count?: Maybe<Scalars['String']>;
  response_rate_count_distinct?: Maybe<Scalars['String']>;
  response_rate_sum?: Maybe<Scalars['String']>;
  response_rate_avg?: Maybe<Scalars['String']>;
  response_rate_min?: Maybe<Scalars['String']>;
  response_rate_max?: Maybe<Scalars['String']>;
  full_name_eq?: Maybe<Scalars['String']>;
  full_name_eq_any?: Maybe<Scalars['String']>;
  full_name_eq_all?: Maybe<Scalars['String']>;
  full_name_not_eq?: Maybe<Scalars['String']>;
  full_name_not_eq_any?: Maybe<Scalars['String']>;
  full_name_not_eq_all?: Maybe<Scalars['String']>;
  full_name_matches?: Maybe<Scalars['String']>;
  full_name_matches_any?: Maybe<Scalars['String']>;
  full_name_matches_all?: Maybe<Scalars['String']>;
  full_name_does_not_match?: Maybe<Scalars['String']>;
  full_name_does_not_match_any?: Maybe<Scalars['String']>;
  full_name_does_not_match_all?: Maybe<Scalars['String']>;
  full_name_lt?: Maybe<Scalars['String']>;
  full_name_lt_any?: Maybe<Scalars['String']>;
  full_name_lt_all?: Maybe<Scalars['String']>;
  full_name_lteq?: Maybe<Scalars['String']>;
  full_name_lteq_any?: Maybe<Scalars['String']>;
  full_name_lteq_all?: Maybe<Scalars['String']>;
  full_name_gt?: Maybe<Scalars['String']>;
  full_name_gt_any?: Maybe<Scalars['String']>;
  full_name_gt_all?: Maybe<Scalars['String']>;
  full_name_gteq?: Maybe<Scalars['String']>;
  full_name_gteq_any?: Maybe<Scalars['String']>;
  full_name_gteq_all?: Maybe<Scalars['String']>;
  full_name_in?: Maybe<Scalars['String']>;
  full_name_in_any?: Maybe<Scalars['String']>;
  full_name_in_all?: Maybe<Scalars['String']>;
  full_name_not_in?: Maybe<Scalars['String']>;
  full_name_not_in_any?: Maybe<Scalars['String']>;
  full_name_not_in_all?: Maybe<Scalars['String']>;
  full_name_cont?: Maybe<Scalars['String']>;
  full_name_cont_any?: Maybe<Scalars['String']>;
  full_name_cont_all?: Maybe<Scalars['String']>;
  full_name_not_cont?: Maybe<Scalars['String']>;
  full_name_not_cont_any?: Maybe<Scalars['String']>;
  full_name_not_cont_all?: Maybe<Scalars['String']>;
  full_name_i_cont?: Maybe<Scalars['String']>;
  full_name_i_cont_any?: Maybe<Scalars['String']>;
  full_name_i_cont_all?: Maybe<Scalars['String']>;
  full_name_not_i_cont?: Maybe<Scalars['String']>;
  full_name_not_i_cont_any?: Maybe<Scalars['String']>;
  full_name_not_i_cont_all?: Maybe<Scalars['String']>;
  full_name_start?: Maybe<Scalars['String']>;
  full_name_start_any?: Maybe<Scalars['String']>;
  full_name_start_all?: Maybe<Scalars['String']>;
  full_name_not_start?: Maybe<Scalars['String']>;
  full_name_not_start_any?: Maybe<Scalars['String']>;
  full_name_not_start_all?: Maybe<Scalars['String']>;
  full_name_end?: Maybe<Scalars['String']>;
  full_name_end_any?: Maybe<Scalars['String']>;
  full_name_end_all?: Maybe<Scalars['String']>;
  full_name_not_end?: Maybe<Scalars['String']>;
  full_name_not_end_any?: Maybe<Scalars['String']>;
  full_name_not_end_all?: Maybe<Scalars['String']>;
  full_name_true?: Maybe<Scalars['String']>;
  full_name_not_true?: Maybe<Scalars['String']>;
  full_name_false?: Maybe<Scalars['String']>;
  full_name_not_false?: Maybe<Scalars['String']>;
  full_name_present?: Maybe<Scalars['String']>;
  full_name_blank?: Maybe<Scalars['String']>;
  full_name_null?: Maybe<Scalars['String']>;
  full_name_not_null?: Maybe<Scalars['String']>;
  full_name_count?: Maybe<Scalars['String']>;
  full_name_count_distinct?: Maybe<Scalars['String']>;
  full_name_sum?: Maybe<Scalars['String']>;
  full_name_avg?: Maybe<Scalars['String']>;
  full_name_min?: Maybe<Scalars['String']>;
  full_name_max?: Maybe<Scalars['String']>;
};

export enum IUserStatus {
  /** user is actively engaging in rhabit */
  Active = 'ACTIVE',
  /** user is no longer active in rhabit */
  Inactive = 'INACTIVE',
  /** user is active, but not onboarded */
  NotOnboarded = 'NOT_ONBOARDED'
}

export type IVacation = {
  __typename?: 'Vacation';
  endDate: Scalars['String'];
  id: Scalars['ID'];
  startDate: Scalars['String'];
  user: IUser;
};

/** Autogenerated input type of VerifyPhoneNumber */
export type IVerifyPhoneNumberInput = {
  phoneNumber: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type IVideoWidget = {
  __typename?: 'VideoWidget';
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  videoLink: Scalars['String'];
};

/** Objects that can be widgets */
export type IWidget = IBlogPostWidget | IDocumentWidget | ITipWidget | IVideoWidget;

export type IWorkdayAccount = {
  __typename?: 'WorkdayAccount';
  dailySync: Scalars['Boolean'];
  id: Scalars['ID'];
  importTeams: Scalars['Boolean'];
  lastImported?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  username: Scalars['String'];
};

export type IWorkItem = {
  __typename?: 'WorkItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  subject?: Maybe<IWorkItemSubject>;
  user: IUser;
};

/** Objects that can be subjects for work items */
export type IWorkItemSubject = IGroup | IHabitUser | IUser;
