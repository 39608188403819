import React, { useState } from "react";
import Carrot from "assets/carrot.png";
import Confetti from "react-dom-confetti";
import ReactCountUp from "react-countup";

import { Header, After } from "./styles";

const AfterAnimation = ({ name }: { name: string }) => {
  const [popped, setPopped] = useState(false);

  return (
    <After>
      <Confetti
        active={popped}
        config={{
          angle: 90,
          spread: 131,
          startVelocity: 20,
          elementCount: 40,
          decay: 0.95
        }}
      />
      <div style={{ marginBottom: "30px", display: "flex" }}>
        <span role="img" aria-label="party popper">
          🎉
        </span>
        <Header style={{ fontSize: "20px" }}> You&apos;re Awesome </Header>
        <span role="img" aria-label="party popper">
          🎉
        </span>
      </div>
      <Header style={{ marginBottom: "30px", fontSize: "20px" }}>
        Thank you for taking the time to give {name} the feedback they
        requested.
      </Header>
      <Header style={{ marginBottom: "60px", fontSize: "20px" }}>
        We’re giving you some bonus carrots for being a great teammate!
      </Header>
      <span style={{ fontSize: 48, color: "#4aa459" }}>
        +
        <ReactCountUp
          start={0}
          end={10}
          duration={1}
          onEnd={() => setPopped(true)}
          delay={1}
        />
        <img alt="carrot" src={Carrot} height={76} style={{ marginLeft: 6 }} />
      </span>
    </After>
  );
};

export default AfterAnimation;
