import profileStore from "./profileStore";
import proposalStore from "./proposalStore";
import organizationStore from "./organizationStore";
import goalStore from "./goalStore";
import objectiveStore from "./objectiveStore";
import feedStore from "./feedStore";
import tutorialStore from "./tutorialStore";

const stores = {
  proposalStore,
  profileStore,
  organizationStore,
  goalStore,
  objectiveStore,
  feedStore,
  tutorialStore
};

export default stores;
