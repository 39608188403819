import { Table } from "antd";
import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const StyledTable = styled(Table)`
  th {
    height: 150px !important;
    vertical-align: bottom;
    border-bottom: none !important;
  }

  .ant-spin-nested-loading > .ant-spin-container > .ant-table {
    border: none;
    .ant-table-body {
      border: 1px solid #e8e8e8;

      border-radius: 6px;
    }
  }
`;