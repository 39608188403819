import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";

const CoachModal = ({ visible, cancel }) => {
  const { t } = useTranslation();
  return (
    <Modal
      onCancel={cancel}
      title="Rhabit is even better with a Coach!"
      visible={visible}
      footer={null}
    >
      <p>{t("deep_dive.coaching_modal.first_paragraph")}</p>
      <p>{t("deep_dive.coaching_modal.second_paragraph")}</p>
      <p>{t("deep_dive.coaching_modal.third_paragraph")}</p>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://calendly.com/rhabit/coaching"
      >
        <Button type="primary" style={{ marginLeft: "33.33%" }}>
          {t("deep_dive.coaching_modal.call_to_action")}
        </Button>
      </a>
    </Modal>
  );
};

CoachModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired
};

export default CoachModal;
