/* eslint-disable */
import * as Types from '../../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type ICreateVacationMutationVariables = Types.Exact<{
  input: Types.ICreateVacationInput;
}>;


export type ICreateVacationMutation = (
  { __typename?: 'Mutation' }
  & { createVacation?: Types.Maybe<(
    { __typename?: 'Vacation' }
    & Pick<Types.IVacation, 'id'>
  )> }
);


export const CreateVacationDocument = gql`
    mutation createVacation($input: CreateVacationInput!) {
  createVacation(input: $input) {
    id
  }
}
    `;
export type ICreateVacationMutationFn = ApolloReactCommon.MutationFunction<ICreateVacationMutation, ICreateVacationMutationVariables>;

/**
 * __useCreateVacationMutation__
 *
 * To run a mutation, you first call `useCreateVacationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVacationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVacationMutation, { data, loading, error }] = useCreateVacationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVacationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICreateVacationMutation, ICreateVacationMutationVariables>) {
        return ApolloReactHooks.useMutation<ICreateVacationMutation, ICreateVacationMutationVariables>(CreateVacationDocument, baseOptions);
      }
export type CreateVacationMutationHookResult = ReturnType<typeof useCreateVacationMutation>;
export type CreateVacationMutationResult = ApolloReactCommon.MutationResult<ICreateVacationMutation>;
export type CreateVacationMutationOptions = ApolloReactCommon.BaseMutationOptions<ICreateVacationMutation, ICreateVacationMutationVariables>;