import { observable, action } from 'mobx';
import { Organization, SuperAdmin } from 'services/api';

class OrganizationStore {
  @observable organization = null;
  @observable errors = [];
  @action async fetchOrganization() {
    try {
      const { data } = await Organization.fetch();
      this.organization = data;
    } catch (e) {
      this.errors.push(e);
    }
    return this.organization;
  }

  @action async updateOrganization(params, id) {
    if (id) {
      const api = new SuperAdmin(id);
      try {
        const { data } = await api.updateOrganization(params);
        this.organization = data;
        return this.organization;
      } catch (e) {
        this.errors.push(e);
      }
    } else {
      try {
        const { data } = await Organization.update(params);
        this.organization = data;
        return this.organization;
      } catch (e) {
        this.errors.push(e);
      }
    }
    return this.organization;
  }

  @action.bound
  setOrganization(data) {
    this.organization = data;
  }

  @action addRole(role) {
    this.organization.roles.push(role);
  }
  @action updateRole(role) {
    const nextRoles = this.organization.roles.filter((r) => r.id !== role.id);
    nextRoles.push(role);
    this.organization.roles = nextRoles;
  }
  @action deleteRole(role) {
    this.organization.roles = this.organization.roles.filter(
      (r) => r.id !== role.id
    );
  }
}

export default new OrganizationStore();
