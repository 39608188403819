/* eslint-disable no-unused-vars */

/* eslint-disable no-debugger */
import React from "react";
import PropTypes from "prop-types";
import { Line } from "@nivo/line";
import { Tooltip } from "antd";
import moment from "moment";
import styled, { withTheme } from "styled-components";
import PercentageCircle from "components/PercentageCircle2";
import { SingleLineGraphTooltip } from "utils/nivo";
import useStores from "utils/useStores";
import { generateScores } from "utils/demoBehaviorScores";

const color = (score) => {
  if (score < 65) {
    return "#FF9331";
  }
  if (score < 80) {
    return "#FDCE60";
  }
  return "#78D36F";
};

const TrendGraph = ({ habit, theme }) => {
  // eslint-disable-next-line no-unused-vars
  const { profileStore } = useStores();
  let demoScores;
  const { demo } = profileStore.currentUser;
  const data = [
    {
      id: habit.id,
      description: habit.name,
      data: habit.trend
        .map((d) => ({
          x: moment(d.created_at).format("YYYY-MM-DD"),
          y: d.value
        }))
        .sort((a, b) => (moment(a.x) < moment(b.x) ? -1 : 1))
    }
  ];

  const yTicks = [0, 20, 40, 60, 80, 100];
  if (demo) {
    demoScores = generateScores(habit.score, habit.behaviors.length);
  }
  return (
    <PopoverWrapper>
      <BehaviorsContainer style={{ minWidth: "50px", paddingLeft: "12px" }}>
        {habit.behaviors.map((b) => {
          return (
            <Tooltip title={b.description} placement="left">
              <div>
                <PercentageCircle
                  percent={demo ? demoScores.splice(0, 1) : b.score}
                  strokeWidth={8}
                  color={color(b.score)}
                />
              </div>
            </Tooltip>
          );
        })}
      </BehaviorsContainer>
      <Line
        height={200}
        width={300}
        maxValue={100}
        data={data}
        curve="monotoneX" // colors="#1676B6"
        colors={theme.primary_color}
        margin={{ top: 25, right: 25, bottom: 25, left: 50 }}
        enableDots={false}
        enableGridY={false}
        enableGridX={false}
        enableArea
        xScale={{ type: "time", format: "%Y-%m-%d", precision: "day" }}
        yScale={{ type: "linear", stacked: false, min: 0, max: 100 }}
        axisBottom={{ tickSize: 0, format: "%b %d", tickValues: 3 }}
        axisLeft={{
          tickSize: 0,
          format: (t) => (yTicks.includes(t) ? `${t}%` : "")
        }}
        enableSlices="x"
        sliceTooltip={SingleLineGraphTooltip}
        tooltip={SingleLineGraphTooltip}
        markers={yTicks.map((t) => ({
          axis: "y",
          value: t,
          lineStyle: {
            stroke: `${!t || t === 100 ? "#a4a4a4" : "#e8e8e8"}`,
            strokeWidth: 1
          }
        }))}
      />
    </PopoverWrapper>
  );
};

TrendGraph.propTypes = {
  habit: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withTheme(TrendGraph);

// ResponsiveLine component takes an array (each element represents one line)
// Each element (line) has a key 'data' containing an array (each element represents a dot on the line)

const PopoverWrapper = styled.div`
  height: 200;
  width: 600;
  display: flex;
  align-items: center;
`;

const BehaviorsContainer = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;
