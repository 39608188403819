import React from "react";
import Box from "layout/Box";
import UserAvatar from "components/UserAvatar";
import styled from "styled-components";
import { Icon, Card as AntCard, Button } from "antd";
import { IFetchUserRelationshipsQuery } from "../queries/fetchUserRelationships.generated";
import media from "utils/style-utils";
import { useMediaQuery } from "react-responsive";

interface Props {
  user: IFetchUserRelationshipsQuery["profile"]["relationships"][0]["relatedUser"];
  selected: boolean;
  addRater: () => void;
  removeRater: () => void;
}

const Container = styled(Box)`
  transition: 0.2s ease-in-out;
  background: ${(props): string => (props.selected ? "#e8e8e8" : "white")};
  &:hover {
    background: #e8e8e8;
  }
`;

export const UserName = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: rgba(0; 0; 0; 0.8);
  text-align: center;
`;

export const Title = styled.div`
  text-align: center;
`;

const Banner = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props) => props.color};
  height: 60px;

  transition: 0.2s;
`;

const Card = styled(AntCard)`
  height: 275px;
  width: 200px;
  border: 1px solid #d9d9d9;
  position: relative;
  padding-top: 100px !important;
  transition: 0.2 ease-in-out;

  .ant-card-body {
    padding: 0 12px;
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  ${media.handheld`
		margin-botton: 36px;
	`}
`;

export const RaterCard: React.FC<Props> = ({
  user,
  selected,
  addRater,
  removeRater
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div
      style={{
        marginBottom: isMobile ? 36 : 12,
        marginLeft: 6,
        marginRight: 6,
        position: "relative",
        height: 275
      }}
    >
      <Card>
        <Banner color={selected ? "#00a74f" : "#e8e8e8"} />
        <UserAvatar
          avatar={user.avatar}
          name={user.name}
          size="large"
          bordered
          fontSize={28}
          lineHeight={70}
          style={{
            marginRight: 12,
            position: "absolute",
            width: 75,
            height: 75,
            top: 22.5,
            left: "calc(50% - 37.5px)"
          }}
        />
        <Box height="100%" display="flex" justifyContent="space-betwen">
          <Box display="flex" flexDirection="column" width="100%">
            <UserName>{user.name}</UserName>
            <Title>{user.title}</Title>
            <Box
              margin="auto 0"
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Button
                shape="circle"
                onClick={!selected ? addRater : (): null => null}
                icon={selected ? "check" : "plus"}
                size="large"
                type="primary"
                // style={{ margin: "auto 0", background: "#00a74f", color: "white" }}
              />
              {selected && (
                <Button
                  size="small"
                  onClick={removeRater}
                  icon="close"
                  type="danger"
                  shape="circle"
                  style={{ marginTop: 12 }}
                ></Button>
              )}
            </Box>
            {/* <Icon
              type="plus-circle"
              theme="filled"
              style={{ margin: "auto 0", fontSize: 48, color: "#00a74f" }}
            /> */}
          </Box>
        </Box>
      </Card>
      {selected && (
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            textAlign: "center",
            fontStyle: "italic"
          }}
        >
          selected
        </div>
      )}
    </div>
    // <Container
    //   padding={24}
    //   width={375}
    //   display="flex"
    //   cursor="pointer"
    //   position="relative"
    //   onClick={(): void => (selected ? removeRater() : addRater())}
    //   marginRight={24}
    //   marginBottom={24}
    //   border="1px solid #e8e8e8"
    //   borderRadius={6}
    //   alignItems="center"
    //   style={{
    //     whiteSpace: "nowrap",
    //     overflow: "hidden",
    //     textOverflow: "ellipsis"
    //   }}
    // >
    //   <UserAvatar name={user.name} avatar={user.avatar} size="large" />
    //   <Box display="flex" flexDirection="column" marginLeft={24}>
    //     <div
    //       style={{
    //         fontSize: 18,
    //         color: "rgba(0, 0, 0, 0.85)",
    //         whiteSpace: "nowrap",
    //         maxWidth: 240,
    //         overflow: "hidden",
    //         textOverflow: "ellipsis"
    //       }}
    //     >
    //       {user.name}
    //     </div>
    //     <div
    //       style={{
    //         maxWidth: 240,
    //         color: "rgba(0, 0, 0, 0.5)",
    //         whiteSpace: "nowrap",
    //         overflow: "hidden",
    //         textOverflow: "ellipsis"
    //       }}
    //     >
    //       {user.email}
    //     </div>
    //   </Box>
    //   {selected && (
    //     <Icon
    //       type="check-circle"
    //       style={{ color: "#40cc75", position: "absolute", bottom: 12, right: 12, fontSize: 24 }}
    //       theme="filled"
    //     />
    //   )}
    // </Container>
  );
};

export default RaterCard;
