import React from "react";

export default () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 -15 65 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.3781 0L43.7673 2.47429L46.6739 5.22736V5.24993H29V8.75007H46.6955L43.7711 11.5273L46.3781 14L52.4577 8.23715C53.1788 7.55518 53.1827 6.44639 52.4577 5.76285L46.3781 0Z"
        fill="currentColor"
      />
      <path
        d="M17.6219 14L20.2327 11.5257L17.3261 8.77264V8.75007L35 8.75007V5.24993L17.3045 5.24993L20.2289 2.47272L17.6219 5.74397e-07L11.5423 5.76285C10.8212 6.44482 10.8173 7.55361 11.5423 8.23715L17.6219 14Z"
        fill="currentColor"
      />
      <path
        d="M39.7715 73.1838H24.6684C24.3915 73.1838 24.165 73.4074 24.165 73.6807V75.6683C24.165 76.7677 25.0649 77.6559 26.1787 77.6559H38.2612C39.375 77.6559 40.2749 76.7677 40.2749 75.6683V73.6807C40.2749 73.4074 40.0484 73.1838 39.7715 73.1838ZM32.2199 22.0034C20.8234 22.0034 11.5791 31.1277 11.5791 42.3762C11.5791 49.9166 15.7324 56.5005 21.8995 60.0223V67.2211C21.8995 68.3205 22.7994 69.2087 23.9133 69.2087H40.5266C41.6405 69.2087 42.5404 68.3205 42.5404 67.2211V60.0223C48.7075 56.5005 52.8608 49.9166 52.8608 42.3762C52.8608 31.1277 43.6165 22.0034 32.2199 22.0034ZM40.2686 56.1527L38.0095 57.4446V64.7366H26.4304V57.4446L24.1713 56.1527C19.2187 53.3266 16.11 48.1216 16.11 42.3762C16.11 33.5935 23.3217 26.4755 32.2199 26.4755C41.1182 26.4755 48.3299 33.5935 48.3299 42.3762C48.3299 48.1216 45.2212 53.3266 40.2686 56.1527Z"
        fill="currentColor"
      />
    </svg>
  );
};
