/* eslint-disable */
import * as Types from '../../../shared/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type IFetchUserVacationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IFetchUserVacationsQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'User' }
    & Pick<Types.IUser, 'onVacation'>
    & { upcomingVacations: Array<(
      { __typename?: 'Vacation' }
      & Pick<Types.IVacation, 'startDate' | 'endDate' | 'id'>
    )> }
  ) }
);


export const FetchUserVacationsDocument = gql`
    query fetchUserVacations {
  profile {
    onVacation
    upcomingVacations {
      startDate
      endDate
      id
    }
  }
}
    `;

/**
 * __useFetchUserVacationsQuery__
 *
 * To run a query within a React component, call `useFetchUserVacationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserVacationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserVacationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchUserVacationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IFetchUserVacationsQuery, IFetchUserVacationsQueryVariables>) {
        return ApolloReactHooks.useQuery<IFetchUserVacationsQuery, IFetchUserVacationsQueryVariables>(FetchUserVacationsDocument, baseOptions);
      }
export function useFetchUserVacationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IFetchUserVacationsQuery, IFetchUserVacationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IFetchUserVacationsQuery, IFetchUserVacationsQueryVariables>(FetchUserVacationsDocument, baseOptions);
        }
export type FetchUserVacationsQueryHookResult = ReturnType<typeof useFetchUserVacationsQuery>;
export type FetchUserVacationsLazyQueryHookResult = ReturnType<typeof useFetchUserVacationsLazyQuery>;
export type FetchUserVacationsQueryResult = ApolloReactCommon.QueryResult<IFetchUserVacationsQuery, IFetchUserVacationsQueryVariables>;